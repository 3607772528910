import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { Divider } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import SimpleButton from "../../../SimpleButton";
import { uploadFileThumbnail } from "../../../../../api/api_uploadFile";
import checkGreen from "../../../../../assests/commonIcons/analytics/checkGreen.svg";
import "../../../../styles/commonStyles.css"

const UploadTemplateThumbnail = ({ thumbnailFile, templateid, videoRef,generateThumbnail, setgenerateThumbnail, uploadThumbnailClick, setuploadThumbnailClick, uploadThumbnailVideoClick, setuploadThumbnailVideoClick, CreateTemplate }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [generatingThumbnail, setGeneratingThumbnail] = useState(false);
  // const videoRef = useRef(null);
  // FOR THUMBNAIL
  const [selectedImage, setSelectedImage] = useState(null);
  const imageInputRef = useRef(null);

  const navigate = useNavigate();

  // State to determine which content to display (thumbnail or selected image)
  const [displayThumbnail, setDisplayThumbnail] = useState(false);

  // For the selected image base64
  const [selectedImageBase64, setSelectedImageBase64] = useState("");

  // const generateThumbnailHandler = async () => {
  //   setGeneratingThumbnail(true);
  //   try {
  //     // Ensure the video has loaded
  //     await videoRef.current.play();

  //     // Seek to the desired timestamp (1 second)
  //     videoRef.current.currentTime = 7;

  //     // Capture the frame as a thumbnail
  //     const canvas = document.createElement("canvas");
  //     canvas.width = videoRef.current.videoWidth;
  //     canvas.height = videoRef.current.videoHeight;
  //     const context = canvas.getContext("2d");
  //     context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
  //     console.log(context);

  //     // Convert the frame to a data URL
  //     const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
  //     setThumbnailUrl(thumbnailDataUrl);
  //     setDisplayThumbnail(true);
  //   } catch (error) {
  //     console.error("Error generating thumbnail:", error);
  //     toast.error("An error occurred while generating the thumbnail", {
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       
  //     });
  //   } finally {
  //     setGeneratingThumbnail(false);
  //   }
  // };

  const generateThumbnailHandler = useCallback(async () => {
    setGeneratingThumbnail(true);
    setgenerateThumbnail("YES");
    try {
      // If the video is not loaded, wait for it to be loaded
      if (!videoRef.current.readyState) {
        await new Promise(resolve => {
          videoRef.current.addEventListener('canplay', resolve, { once: true });
        });
      }

      // Ensure the video has loaded
      await videoRef.current.play();

      // Seek to the desired timestamp (1 second)
      videoRef.current.currentTime = 7;

      // Capture the frame as a thumbnail
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      console.log(context);

      // Convert the frame to a data URL
      const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
      setThumbnailUrl(thumbnailDataUrl);
      setDisplayThumbnail(true);
    } catch (error) {
      console.error("Error generating thumbnail:", error);
      // Check if the video is not loaded and display a different error message
      if (!videoRef.current.readyState) {
        toast.error("Video is not loaded. Please wait for the video to load before generating the thumbnail.", {
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          
        });
      } else {
        toast.error("An error occurred while generating the thumbnail", {
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          
        });
      }
    } finally {
      setGeneratingThumbnail(false);
    }
  },[videoRef, setgenerateThumbnail]);

  const cleanup = useCallback(() => {
    // Cleanup any ongoing asynchronous tasks
    // For example, clear any event listeners
    // Stop the video playback
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeEventListener('canplay', generateThumbnailHandler);
    }
  }, [generateThumbnailHandler, videoRef]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      // Set displayThumbnail to false to show the selected image
      setDisplayThumbnail(false);

      // Convert the selected image to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Set the base64 data in state
        setSelectedImageBase64(reader.result);
      };
    }
  };

  useEffect(() => {
    const handleCanPlay = () => generateThumbnailHandler();

    if (thumbnailFile) {
      videoRef.current.src = URL.createObjectURL(thumbnailFile);
      videoRef.current.addEventListener('canplay', handleCanPlay, { once: true });
    }
    return cleanup;
  }, [thumbnailFile, generateThumbnailHandler, cleanup, videoRef]);

  // ************* UPLOAD TEMPLATE THUMBNAIL MUTATION **************************
  const { mutate: uploadTemplateThumbnailMutation } = useMutation(
    (uploadThumbnail) => uploadFileThumbnail(uploadThumbnail),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          navigate(`${process.env.REACT_APP_BASE_PATH}/editor/createTemplate`);
          setuploadThumbnailVideoClick("YES");
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  // *************************** UPLOAD THUMBNAIL FUNCTION *************************

  const getUploadThumbnail = () => {
    if (displayThumbnail && thumbnailUrl) {
      // If the generated thumbnail is displayed, log its base64
      const uploadThumbnail = {
        templateId: templateid,
        thumbnail: thumbnailUrl,
        filetype: "thumbnail",
      };
      console.log("Upload Thumb Data:", uploadThumbnail);
      uploadTemplateThumbnailMutation(uploadThumbnail);
      setuploadThumbnailClick("YES");
    } else if (selectedImageBase64) {
      // If a selected image is displayed, log its base64
      const uploadThumbnail = {
        templateId: templateid,
        thumbnail: selectedImageBase64,
        filetype: "thumbnail",
      };
      console.log("Upload Thumb Data", uploadThumbnail);
      uploadTemplateThumbnailMutation(uploadThumbnail);
      setuploadThumbnailClick("YES");
    } else {
      console.log("No image is displayed.");
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-between items-center">
        <span className="mr-16">
          <video
            ref={videoRef}
            style={{ width: "200px" }} // Set the width to 200px
            controls // Enable video player controls
            preload="none"
            crossOrigin="anonymous"
          />
        </span>
        <span className="relative">
          {displayThumbnail && thumbnailUrl && (
            <>
            <img
              src={thumbnailUrl}
              alt="Thumbnail"
              style={{ width: "200px", height: "auto" }}
            />
            <span className="bg-[#7C58E1] text-[white] absolute top-1 right-1 rounded-md px-1">Thumbnail</span>
            </>
          )}

          {!displayThumbnail && selectedImage && (
            <div className="flex flex-col items-center">
              <img
                src={URL.createObjectURL(selectedImage)}
                alt={selectedImage.name}
                style={{ width: "200px", height: "auto" }}
              />
            </div>
          )}
        </span>
      </div>
      <div className="mt-2">
        {displayThumbnail && thumbnailUrl && (
          <div>
            <img
            className="inline-block"
              src={checkGreen} // Use checkGreen as the source for your checkmark
              alt="Thumbnail is generated"
              style={{ width: "20px", height: "20px" }} // Set the width and height according to your needs
            />
            <span className="ml-1 text-[#5AB005]">Thumbnail is generated</span>
          </div>
        )}

        {!displayThumbnail && selectedImage && (
          <span className="flex flex-col">
            <span>Image Name: {selectedImage.name}</span>
            <span>Image Size: {selectedImage.size}</span>
          </span>
        )}
      </div>
      <div className="flex justify-around mt-4">
        <span>
          <SimpleButton
            label="Generate Thumbnail"
            type="primary"
            onClick={generateThumbnailHandler}
            disabled={generatingThumbnail}
            size="default"
          />
        </span>
        <span className="ml-3">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            ref={imageInputRef}
            style={{ display: "none" }}
          />
          <SimpleButton
            label="Browse"
            onClick={() => imageInputRef.current.click()}
          />
        </span>
      </div>
      <div className="mt-6 flex flex-col w-full">
        <Divider className="divider-temp"/>
        <div className="mt-2 flex justify-end">
        <SimpleButton
          label="Upload"
          type="primary"
          onClick={()=>{getUploadThumbnail(); CreateTemplate();}}
          disabled={!thumbnailUrl && !selectedImage}
        />
        </div>
      </div>
    </div>
  );
};

export default UploadTemplateThumbnail;
