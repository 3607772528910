import React, { useEffect, useState } from "react";
import { Input, Modal, Switch } from "antd";
import { toast } from "react-toastify";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import mapBrandSearch from "../../../../assests/commonIcons/brandPageIcons/mapBrandSearch.svg";
import { getAllUserBrandPages } from "../../../../api/api_FetchData";
import { setBrandPage } from "../../../../api/api_updateData";

// USED IN Figma.js TO EDIT BRAND PAGE DETAILS

const MapBrandEditModal = (props, custregid) => {
  custregid = localStorage.getItem("sessionUserId");
  const sessionuserid = localStorage.getItem("sessionUserId");
  const [searchTerm, setSearchTerm] = useState("");

  const handleCancel = () => {
    props.setMapDialog(false);
  };

  const {
    data: userBrandData,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["ALLCUSTBRANDPAGES"],
    queryFn: () => getAllUserBrandPages(sessionuserid, custregid, "/brandpage"),
    staleTime: 1000 * 30, // after 30 sec cached data refresh
  });

  useEffect(() => {
    // Call the refetch function when the component mount
    refetch();
  }, [refetch]);

  const { mutate: setBrandPageMutation } = useMutation({
    mutationFn: (setBrandData) => setBrandPage(setBrandData),
    onSuccess: (data) => {
      if (data.STATUS === "1000") {
        toast.success(data.DESCRIPTION);
        // props.onRefreshSuccess();
        setTimeout(() => {
          props.setMapDialog(false); 
        }, 1000);
      } else {
        toast.error(data.DESCRIPTION);
      }
    },
  });

  //   -------HANDLE API CALL-----------
  const handleSetBrandCust = (brandId) => {
    const setBrandData = {
      templateid: props.templateid,
      sessionuserid: sessionuserid,
      brandpageid: brandId,
    };
  // console.log(brandId, "ssssssss");

    setBrandPageMutation(setBrandData);
  };

  //   ----------SEND BRANDID----------
  const sendBrandId = () => {
    // const brandId = enableBrandSwitch;
    const brandId = props.brandpageid;
    // console.log(brandId, "brandIDDDDD");
    handleSetBrandCust(brandId);
  };


  if (userBrandData && userBrandData.status === "1000" && isSuccess) {
    // Filter userBrandData based on searchTerm
    const filteredBrandData = userBrandData.data?.filter((brand) =>
      `${brand.BRANDPAGEID} ${brand.BRANDPAGENAME}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    return (
      <>
        <Modal
          className="MapBrandModal"
          // title="Basic Modal"
          open={props.mapdialog}
          // onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="">
            {/* ---------Head-------- */}
            <div className="flex flex-col px-4 pt-2 pb-2 bg-[#ECECEC]">
              <span className="text-[13px] font-normal text-[#787575]">
                TID: {props.templateid}
              </span>
              <span className="text-[14px] font-medium text-[#000000]">
                {props.templatename}
              </span>
            </div>
            {/* --------Content-------- */}
            <div className="bg-[#fcfcfc]">
              
              <div className="mt-3 mb-5 mx-4">
                <Input
                  className="w-[330px] h-[27px] rounded-[21px] text-[11px] text-[#7E7B7B]"
                  placeholder="search brand page.."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClearClick={() => {
                    setSearchTerm(""); // Clear the input value
                  }}
                  suffix={<img src={mapBrandSearch} alt="Search Icon" />}
                />
              </div>
              <div className="h-[290px] overflow-auto">
                {/* {userBrandData &&
                  userBrandData.data?.map((brand) => ( */}
                {filteredBrandData.length > 0 ? (
                  filteredBrandData.map((brand) => (
                    <div className="py-1" key={brand.BRANDPAGEID}>
                      <div className="flex justify-between px-4 pb-3">
                        <div className="flex flex-col">
                          <span className="text-[11px] font-normal text-[#7D7A7A]">
                            Brand ID: {brand.BRANDPAGEID}
                          </span>
                          <span className="text-[14px] font-normal text-[#000000]">
                            {brand.BRANDPAGENAME}
                            {/* {brand.BRANDPAGEOWNER} */}
                          </span>
                        </div>
                        <div className="flex items-center mapBrandSwitch">
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            // checked={enableBrandSwitch === brand.BRANDPAGEID }
                            checked={props.brandpageid === brand.BRANDPAGEID}
                            //   defaultChecked
                            onChange={(checked) => {
                              if (checked) {
                                if (
                                  props.templateid &&
                                  brand.BRANDPAGEID &&
                                  props.templateowner === brand.BRANDPAGEOWNER
                                ) {
                                  //   setEnableBrandSwitch(brand.BRANDPAGEID);
                                  props.setBrandSelected(brand.BRANDPAGEID);
                                  props.setBrandName(brand.BRANDPAGENAME);
                                } else {
                                  //   setEnableBrandSwitch("false");
                                  props.setBrandSelected(null);
                                  props.setBrandName(false);
                                }
                              } else {
                                // setEnableBrandSwitch("false");
                                props.setBrandSelected(null);
                                props.setBrandName(false);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            borderTop: "1px solid #B4B4B4",
                          }}
                        ></div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center items-center">
                    <span>No results found</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } 
  else {
    return null;
  }
};
export default MapBrandEditModal;
