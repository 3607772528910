import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../common/styles/userModule/LoginStyles.css";

//  COMPONENET / LIBRARY IMPORTS
import {
  Form,
  Input,
  Col,
  Row,
  Modal,
  Typography,
  Divider,
} from "antd";
import SimpleButton from "../../common/components/SimpleButton";
import PasswordInputBox from "../../common/components/PasswordInputBox";

// API IMPORT
import { createCustomer } from "../../api/api_updateData";
import { useMutation } from "react-query";

// ICONS / IMAGES IMPORT
// import newVspagyLogo from "../../assests/commonImages/new-vspagy-logo.svg";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import Banner from "../common/Banner";
import EmailOtpVerify from "./EmailOtpVerify";
import { toast } from "react-toastify";
import googleLogo from "../../assests/commonImages/google-logo.png";
import clevertap from "clevertap-web-sdk";

const Register = () => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isTermsLinkClicked, setIsTermsLinkClicked] = useState(false);
  const [isPrivacyLinkClicked, setIsPrivacyLinkClicked] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const utmParam = urlParams.get('utm');

  //calculates width of screen size
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth([window.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //*************************Terms and condition Modal********************/

  const { Text } = Typography;

  // State to control the visibility of the modal
  const [modalVisible, setModalVisible] = useState(false);

  // // Function to handle modal open
  // const openModal = () => {
  //   setModalVisible(true);
  // };

  // Function to handle modal close
  const closeModal = () => {
    setModalVisible(false);
  };

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const openTermsLink = () => {
    window.open("https://vspagy.com/en/terms.html", "_blank");
    setIsTermsLinkClicked(true);
  };

  const openPrivacyLink = () => {
    window.open("https://vspagy.com/en/privacy-policy.html", "_blank");
    setIsPrivacyLinkClicked(true);
  };

  const userAgent = navigator.userAgent;

  const Signup_Initiated = (custData) => {
    clevertap.event.push("Signup_Initiated", {
      NAME: custData.custname,
      Identity: custData.custregid,
      USER_ID: custData.custregid,
      Email: custData.custregid,
      Signup_Method: custData.regvia,
      Register_Button_Clicked: "Yes",
      Terms_Link_Clicked: isTermsLinkClicked ? "Yes" : "No",
      Privacy_Link_Clicked: isPrivacyLinkClicked ? "Yes" : "No",
      Agree_Checkbox_Checked: isTermsChecked ? "Yes" : "No",
      timestamp: new Date().getTime(),
      user_agent: userAgent,
      utm: utmParam || '',
    });
    console.log("SIGNUP_INITIATED EVENT CLICKED");
    console.log("NAME", custData.custname);
  };

  //******************************* CREATE CUSOTMER STARTS ******************************************
  const { mutate: createCustomerMutation } = useMutation(
    (custData) => createCustomer(custData),
    {
      onSuccess: (data, custData) => {
        // queryClient.invalidateQueries("");
        if (data.STATUS === "1000") {
          // const sessionUserId = email;
          // const sessionId = createSession(sessionUserId);
          // console.log(sessionId, "session ID");
          // console.log(sessionUserId, "session user ID");
          // localStorage.setItem("sessionId", sessionId); // Store sessionId in localStorage
          // localStorage.setItem("sessionUserId", sessionUserId); // Store sessionUserId in localStorage
          console.log(custData, "CUST DATA");
          Signup_Initiated(custData);
          toast.success(data.DESCRIPTION);
          setIsRegistered(true);
        } else {
          // Signup_Failed(custData);
          toast.error(data.DESCRIPTION);
        }
      },
      onError: (custData) => {
        // Signup_Failed(custData);
      },
    }
  );

  //  *************************** CREATE CUSOTMER STARTS ******************************************
  const sendRoute = `${process.env.REACT_APP_BASE_PATH}/login`;

  // ******************** LOGIN API CALLING AND MUTATION ENDS **************************

  const redirectToGoogle = () => {
    if (!navigator.onLine) {
      toast.error("No internet connection. Please check your network.");
      return;
    }

    var googleUrl = utmParam === null ? `${process.env.REACT_APP_BASE_PATH}/togoogle` : `${process.env.REACT_APP_BASE_PATH}/togoogle?utm=${utmParam}`;

    window.location.replace(googleUrl);
  };

  const handleAddCust = () => {
    // Convert the password to Base64 using btoa() function
    // const encryptPassword = window.btoa(password); // WILL BE USED IN FUTURE

    const custData = {
      custname: username,
      custregid: email,
      // password: encryptPassword,
      password: password,
      ownerregid: email,
      regvia: "email",
      utm: utmParam || 'NA',
    };
    // Signup_Initiated(custData);
    createCustomerMutation(custData);
    // console.log(custData, "CUST DATA");
  };

  const customerData = {
    name: username,
    email: email,
  };

  return (
    <div className="h-screen">
      <Row>
        {isRegistered ? (
          // <Col span={width > 750 ? 16 : 24}>
          <Col span={width > 1024 ? 16 : 24}>
            <EmailOtpVerify
              email={email}
              setIsRegistered={setIsRegistered}
              sendRoute={sendRoute}
              handleAddCust={handleAddCust}
              custData={customerData}
            />
          </Col>
        ) : (
          // <Col span={width > 750 ? 16 : 24}>
          <Col span={width > 1024 ? 16 : 24}>
            <div className="form-wrapper email-verify email-section">
              <div className="flex flex-col text-center">
                <div className="w-[200px] ml-auto mr-auto">
                  <img
                    src={newVspagyLogo}
                    alt="vspagyLogo"
                    className="w-full mb-4"
                  />
                </div>
                <span className="font-semibold text-[#151515] my-2 text-[1.5rem] select-none">
                  Create an account for FREE
                </span>
                <span className="text-base font-normal text-[#868686] mb-7 select-none">
                  No credit card required
                </span>
              </div>

              <div>
                <Form
                  form={form}
                  name="login-form"
                  initialValues={{ remember: true }}
                  // onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  layout="vertical"
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your name",
                      },
                      {
                        pattern: /^[A-Za-z\s]+$/, // Regular expression for letters and spaces only
                        message: "Please enter letters only",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      autoFocus
                      className="h-[44px] text-base"
                      placeholder="Your name"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      {
                        // pattern:
                        //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        type: 'email',
                        message: "Enter a valid email!",
                      },
                    ]}
                    dependencies={["password"]}
                  >
                    <Input
                      className="h-[44px] text-base"
                      placeholder="someone@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>

                  <PasswordInputBox
                    setValidPassword={setValidPassword}
                    password={password}
                    setPassword={setPassword}
                    placeholder={"Password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* ******************Terms of Service******************** */}
                  <div className="mt-4">
                    <span>
                      <label className="flex justify-start items-center">
                        <input
                          type="checkbox"
                          checked={isTermsChecked}
                          // onChange={(e) => setIsTermsChecked(e.target.checked)}
                          onChange={() => setIsTermsChecked(!isTermsChecked)}
                          className="mr-2 w-4 h-4"
                        />{" "}
                        <Text>
                          By signing up, you agree to our{" "}
                          <Typography.Link>
                            <span onClick={openTermsLink}>Terms of Use</span>{" "}
                            <span className="text-black">&</span>{" "}
                            <span onClick={openPrivacyLink}>
                              Privacy Policy
                            </span>
                          </Typography.Link>
                        </Text>
                      </label>
                    </span>
                    <Modal
                      width={1000}
                      open={modalVisible}
                      onCancel={closeModal}
                      footer={null}
                      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
                    >
                      <div className="m-8">
                        <p className="text-[28px] leading-10 text-center font-semibold mt-4 pt-2">
                          TERMS OF SERVICE AGREEMENT
                        </p>
                        <Divider style={{ background: "2px grey" }} />
                        <p className="text-[18px] leading-6 font-semibold  tracking-tight text-center mt-4">
                          PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT
                          CAREFULLY. BY ACCESSING OR USING OUR SITES AND OUR
                          SERVICES, YOU HEREBY AGREE TO BE BOUND BY THE TERMS
                          AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS
                          THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR
                          PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS
                          BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT
                          EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS,
                          THEN PLEASE DO NOT ACCESS OR USE OUR SITES OR OUR
                          SERVICES. THIS TERMS OF SERVICE AGREEMENT IS EFFECTIVE
                          AS OF ________________________.
                        </p>
                        <div className="leading-9 text-base">
                          <p>
                            ACCEPTANCE OF TERMS   The following Terms of Service
                            Agreement (the "TOS") is a legally binding agreement
                            that shall govern the relationship with our users
                            and others which may interact or interface with
                            ________________________, also known as
                            ________________________, located at
                            ________________________, ________________________,
                            ________________________ ________________________
                            and our subsidiaries and affiliates, in association
                            with the use of the ________________________
                            website, which includes ________________________,
                            (the "Site") and its Services, which shall be
                            defined below.   DESCRIPTION OF WEBSITE SERVICES
                            OFFERED   The Site is ________________________ which
                            has the following description:  
                            ________________________   Any and all visitors to
                            our site shall be deemed as "users" of the herein
                            contained Services provided for the purpose of this
                            TOS.   The user acknowledges and agrees that the
                            Services provided and made available through our
                            website and applications, which may include some
                            mobile applications and that those applications may
                            be made available on various social media networking
                            sites and numerous other platforms and downloadable
                            programs, are the sole property of
                            ________________________. At its discretion,
                            ________________________ may offer additional
                            website Services and/or products, or update, modify
                            or revise any current content and Services, and this
                            Agreement shall apply to any and all additional
                            Services and/or products and any and all updated,
                            modified or revised Services unless otherwise
                            stipulated. ________________________ does hereby
                            reserve the right to cancel and cease offering any
                            of the aforementioned Services and/or products. You,
                            as the end user acknowledge, accept and agree that
                            ________________________ shall not be held liable
                            for any such updates, modifications, revisions,
                            suspensions or discontinuance of any of our Services
                            and/or products. Your continued use of the Services
                            provided, after such posting of any updates,
                            changes, and/or modifications shall constitute your
                            acceptance of such updates, changes and/or
                            modifications, and as such, frequent review of this
                            Agreement and any and all applicable terms and
                            policies should be made by you to ensure you are
                            aware of all terms and policies currently in effect.
                            Should you not agree to the updated, revised or
                            modified terms, you must stop using the provided
                            Services forthwith.
                          </p>
                          <p> 1.Not spamming other users</p>
                          <p>2.Not using your site for illegal activities</p>
                          <p>
                            3. You can click on the links to Terms of Use and
                            Privacy Policy to view their details
                          </p>
                          <p>
                            4. Once complete, a verification link will be sent
                            to your email
                          </p>
                          <p>
                            5.Click on the verification link in your email to
                            complete the same and get started
                          </p>
                        </div>
                      </div>
                    </Modal>
                  </div>

                  <Form.Item shouldUpdate>
                    {() => (
                      <SimpleButton
                        label="Create Account"
                        type="primary"
                        size="large"
                        className="rounded w-full mt-4"
                        disabled={
                          form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length ||
                          !validPassword ||
                          Object.values(form.getFieldsValue(["email"])).filter(
                            Boolean
                          ).length !== 1 ||
                          !isTermsChecked
                        }
                        onClick={handleAddCust}
                      />
                    )}
                  </Form.Item>
                </Form>
              </div>
              <Divider className="!border-[#CFCFCF] !border-[0.5px]" plain>
                or
              </Divider>
              <div className="my-4">
                <span
                  className="flex justify-center items-center h-[44px] select-none cursor-pointer rounded-[4px] bg-FCFCFC border border-[#E3E3E3] border-solid"
                  onClick={redirectToGoogle}
                >
                  <span>
                    <img src={googleLogo} alt="google logo" />
                  </span>
                  <span className="text-base ml-3">Sign Up with Google</span>
                </span>
              </div>

              <div className="flex flex-col text-center text-base">
                <span className="text-black font-normal">
                  Already have an account?
                  <Link
                    to={`${process.env.REACT_APP_BASE_PATH}/login`}
                    className="ml-2 text-[#0C6CFF] font-medium "
                  >
                    Login
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        )}
        <Col className="max-[750px]:hidden" span={8}>
          <Banner />
        </Col>
      </Row>
    </div>
  );
};

export default Register;
