import React from "react";
import HorizontalChart from "../common/HorizontalChart";
import useWindowWidth from "../../common/hooks/useWindowWidth";

const AnalyticsOverview = ({ searchData, dashboardData }) => {
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  const parsedSearchData = JSON.parse(searchData);
  return (
    <>
      <div className="p-7 bg-white rounded-tl shadow mt-7">
        <div className="text-left text-neutral-900 text-base font-semibold  leading-snug mb-5 select-none">
          Analytics Overview
        </div>
        <div className={`flex ${isDesktop ? "" : "flex-wrap"} gap-6 justify-between`}>
          <div className="w-full flex flex-col select-none">
            <span className="flex flex-col items-center bg-[#F0EEFF] rounded-t-[4px] h-[8rem] pt-10">
              <span className="text-[14px] text-[#4B3C56] mb-2">
                Total Links Generated
              </span>
              <span className="text-[28px] text-[#232323] font-semibold">
                {parsedSearchData === null &&
                dashboardData &&
                dashboardData.length > 0
                  ? dashboardData[0]?.LINKREPORT?.TOTALLINKSGENERATED
                  : parsedSearchData?.[0]?.LINKREPORT?.TOTALLINKSGENERATED}
              </span>
            </span>
            <span className="flex bg-[#E5E3FF] rounded-b-[4px] h-[7rem] p-7">
              <span className="flex flex-col items-center w-1/2">
                <span className="text-[14px] text-[#6A5289] mb-2">
                  via projects (total 59 projects)
                </span>
                <span className="text-[24px] text-[#232323] font-semibold">
                  {parsedSearchData === null &&
                  dashboardData &&
                  dashboardData.length > 0
                    ? dashboardData[0]?.LINKREPORT?.VIAPROJECTS
                    : parsedSearchData?.[0]?.LINKREPORT?.VIAPROJECTS}
                </span>
              </span>
              <span className="flex flex-col items-center w-1/2">
                <span className="text-[14px] text-[#6A5289] mb-2">via API</span>
                <span className="text-[24px] text-[#232323] font-semibold">
                  {parsedSearchData === null &&
                  dashboardData &&
                  dashboardData.length > 0
                    ? dashboardData[0]?.LINKREPORT?.VIAAPI
                    : parsedSearchData?.[0]?.LINKREPORT?.VIAAPI}
                </span>
              </span>
            </span>
          </div>
          <div className="w-full flex flex-col select-none gap-6">
            <span className="flex h-[6.75rem]">
              <span className="flex flex-col w-1/2 items-start bg-[#FFEDD7] rounded-l-[4px] p-[1.625rem]">
                <span className="text-[#814D36] text-[14px] mb-2.5">
                  Total Open
                </span>
                <span className="text-[#232323] text-[24px] font-semibold">
                  {parsedSearchData === null &&
                  dashboardData &&
                  dashboardData.length > 0
                    ? dashboardData[0]?.LINKREPORT?.TOTALOPENLINKS
                    : parsedSearchData?.[0]?.LINKREPORT?.TOTALOPENLINKS}
                </span>
              </span>
              <span className="flex flex-col w-1/2 items-start bg-[#FFE7CB] rounded-r-[4px] p-[1.625rem]">
                <span className="text-[#814D36] text-[14px] mb-2.5">
                  Unique Open
                </span>
                <span className="text-[#232323] text-[24px] font-semibold">
                  {parsedSearchData === null &&
                  dashboardData &&
                  dashboardData.length > 0
                    ? dashboardData[0]?.LINKREPORT?.UNIQUEOPENLINKS
                    : parsedSearchData?.[0]?.LINKREPORT?.UNIQUEOPENLINKS}
                </span>
              </span>
            </span>
            <span className="flex h-[6.75rem]">
              <span className="flex flex-col w-1/2 items-start bg-[#E0FFE3] rounded-l-[4px] p-[1.625rem]">
                <span className="text-[#365E3A] text-[14px] mb-2.5">
                  Total Plays
                </span>
                <span className="text-[#232323] text-[24px] font-semibold">
                  {parsedSearchData === null &&
                  dashboardData &&
                  dashboardData.length > 0
                    ? dashboardData[0]?.LINKREPORT?.TOTALPLAYLINKS
                    : parsedSearchData?.[0]?.LINKREPORT?.TOTALPLAYLINKS}
                </span>
              </span>
              <span className="flex flex-col w-1/2 items-start bg-[#D0FFD5] rounded-r-[4px] p-[1.625rem]">
                <span className="text-[#365E3A] text-[14px] mb-2.5">
                  Unique Plays
                </span>
                <span className="text-[#232323] text-[24px] font-semibold">
                  {parsedSearchData === null &&
                  dashboardData &&
                  dashboardData.length > 0
                    ? dashboardData[0]?.LINKREPORT?.UNIQUEPLAYLINKS
                    : parsedSearchData?.[0]?.LINKREPORT?.UNIQUEPLAYLINKS}
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="p-7 bg-white rounded-tl shadow my-8">
        <div className="text-left text-neutral-900 text-base font-semibold  leading-snug mb-5 select-none">
          Project Performance
        </div>
        <div className={`flex ${isDesktop ? "" : "flex-col"} gap-10`}>
          <span className={`flex flex-col  ${isDesktop ? "w-1/3" : "w-full"} p-6 bg-[#E9FCF8]`}>
            <span className="text-[#2D584F] text-base font-medium">
              Total Play Record
            </span>
            <span className="text-[#4E4E4E] text-smm font-normal mt-1">
              Your templates have been viewed
            </span>
            <span className="text-[#2B2B2B] text-2xl font-semibold mt-5">
              {parsedSearchData === null &&
              dashboardData &&
              dashboardData.length > 0
                ? dashboardData[0]?.PROJECTPERFORMANCE?.TOTALPLAYEDRECORD
                : parsedSearchData?.[0]?.PROJECTPERFORMANCE?.TOTALPLAYEDRECORD}
            </span>
          </span>
          <span className={`flex flex-col  ${isDesktop ? "w-1/3" : "w-full"} p-6 bg-[#E9FCF8]`}>
            <span className="text-[#33555B] text-base font-medium">
              Heatmap Record
            </span>
            <span className="text-[#4E4E4E] text-smm font-normal mt-1">
              User hotspot clicks count
            </span>
            <span className="text-[#2B2B2B] text-2xl font-semibold mt-5">
              {parsedSearchData === null &&
              dashboardData &&
              dashboardData.length > 0
                ? dashboardData[0]?.PROJECTPERFORMANCE?.TOTALHEATMAPRECORD
                : parsedSearchData?.[0]?.PROJECTPERFORMANCE?.TOTALHEATMAPRECORD}
            </span>
          </span>
          <span className={`flex flex-col  ${isDesktop ? "w-1/3" : "w-full"} p-6 bg-[#E9FCF8]`}>
            <span className="text-[#384A5F] text-base font-medium">
              Video Data Record
            </span>
            <span className="text-[#4E4E4E] text-smm font-normal mt-1">
              Counts of completed interactive actions
            </span>
            <span className="text-[#2B2B2B] text-2xl font-semibold mt-5">
              {parsedSearchData === null &&
              dashboardData &&
              dashboardData.length > 0
                ? dashboardData[0]?.PROJECTPERFORMANCE?.TOTALDATARECORD
                : parsedSearchData?.[0]?.PROJECTPERFORMANCE?.TOTALDATARECORD}
            </span>
          </span>
        </div>
      </div>

      <div className="p-7 bg-white rounded-tl shadow mb-8">
        <div className="text-left text-neutral-900 text-base font-semibold  leading-snug mb-3 select-none">
          Play Duration
        </div>
        <div className={`flex  ${isDesktop ? "justify-between items-center" : "flex-col"}`}>
          <div className={` ${isDesktop ? "w-[40%]" : "w-full"}`}>
            <div className="flex flex-wrap w-full justify-between">
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#8CE89E] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    0-1 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.ZEROTO1SECONDS
                      : parsedSearchData?.[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.ZEROTO1SECONDS}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#53C9A3] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    1-10 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.ONETO10SECONDS
                      : parsedSearchData?.[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.ONETO10SECONDS}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#28A89F] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    10-30 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.TENTO20SECONDS
                      : parsedSearchData?.[0].PROJECTPERFORMANCE?.PLAYDURATION
                          ?.TENTO20SECONDS}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#1D8791] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    20-30 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.TWENTYTO30SECONDS
                      : parsedSearchData?.[0].PROJECTPERFORMANCE?.PLAYDURATION
                          ?.TWENTYTO30SECONDS}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#2A6778] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    30-40 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.THIRTYTO40SECONDS
                      : parsedSearchData?.[0].PROJECTPERFORMANCE?.PLAYDURATION
                          ?.THIRTYTO40SECONDS}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#2F4858] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    40-50 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.FORTYTO50SECONDS
                      : parsedSearchData?.[0].PROJECTPERFORMANCE?.PLAYDURATION
                          ?.FORTYTO50SECONDS}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#009AC4] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    50-60 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.FIFTYTO60SECONDS
                      : parsedSearchData?.[0].PROJECTPERFORMANCE?.PLAYDURATION
                          ?.FIFTYTO60SECONDS}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center mb-1">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#0A7BB5] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    {">"}60 Seconds
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.GREATERTHAN60
                      : parsedSearchData?.[0].PROJECTPERFORMANCE?.PLAYDURATION
                          ?.GREATERTHAN60}
                  </span>
                </div>
              </span>
              <span className="w-full md:w-1/2 flex items-center">
                <div className="w-1.5 h-[70%] mr-2.5 bg-[#4D5B96] rounded-[2px]" />
                <div className="py-4 flex flex-col">
                  <span className="text-[#5A5A5A] font-normal text-sm">
                    Full Video
                  </span>
                  <span className="text-[#292929] font-bold text-[22px] mt-1">
                    {parsedSearchData === null &&
                    dashboardData &&
                    dashboardData.length > 0
                      ? dashboardData[0]?.PROJECTPERFORMANCE?.PLAYDURATION
                          ?.FULLVIDEO
                      : parsedSearchData?.[0].PROJECTPERFORMANCE?.PLAYDURATION
                          ?.FULLVIDEO}
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className="">
            <HorizontalChart
              parsedSearchData={parsedSearchData}
              dashboardData={dashboardData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsOverview;
