import text from "../assets/text.svg";
import video from "../assets/video.svg";
import hotspot from "../assets/hotspot.svg";
import button from "../assets/button.svg";
import text1 from "../assets/text1.svg";
import optionsList from "../assets/optionsList.svg";
import leftAlign from "../assets/leftAlign.svg";
import rightAligh from "../assets/rightAlign.svg";
import topCenterAlign from "../assets/topCenterAlign.svg";
import bottomCenterAlign from "../assets/bottomCenterAlign.svg";
import bottomAlign from "../assets/bottomAlign.svg";
import topAlign from "../assets/topAlign.svg";
import textImg from "../assets/Text Input.svg";
import telImg from "../assets/Telephone.svg";
import dateImg from "../assets/Date.svg";
import textAreaImg from "../assets/text-area.svg";
import emailImg from "../assets/Email.svg";
import numericImg from "../assets/cubes 1.svg";
import passwordImg from "../assets/Group.svg";
import SampleImg from "../assets/SampleImage.jpg";
import rectangle from "../assets/rectangle 2.svg";
import circle from "../assets/circle 1.svg";
import radio from "../assets/radio.svg";
import checkbox from "../assets/checkbox.svg";
import LabelGif from "../assets/LabelGif.gif";
import TextGif from "../assets/TextInputGif.gif";
import HotspotGif from "../assets/HotspotGif.gif";
import OptionsGif from "../assets/OptionsGif.gif";
import LeftAlign from "../assets/left_Align.svg";
import RightAlign from "../assets/right_Align.svg"
import TopAlign from "../assets/top_Align.svg";
import BottomAlign from "../assets/bottom_Align.svg";
import VerticalCAlign from "../assets/verticalCenter.svg";
import HorizontalCAlign from "../assets/horizontalCenter.svg";

const layerAlign = [
 {id :1, icon : LeftAlign},
 {id :2, icon : HorizontalCAlign},
 {id :3, icon : RightAlign},
 {id :4, icon : VerticalCAlign},
 {id :5, icon : TopAlign},
 {id :6, icon : BottomAlign},
]

const sidebarData = [
  { id: 1, image: text, name: "Text Label",gif : LabelGif ,heading : "Text Labels",desc : "Drag and set the text labels to canvas with variable" },
  { id: 2, image: text1, name: "Text Input",gif  : TextGif,heading : "Text Input",desc : "Allows users to enter text input in video"},
  { id: 3, image: hotspot, name: "Hotspots",gif : HotspotGif,heading : "Hotspot",desc : "Draw a target over any part of image to add link" },
  { id: 4, image: video, name: "Assets",gif : LabelGif,heading : "Assets",desc : "Use video/images from your own assets or from our library."},
  { id: 5, image: button, name: "Buttons",gif  : LabelGif,heading:"Button",desc : "Add button interaction with color, label changes"},
  { id: 6, image: optionsList, name: "Options",gif : OptionsGif,heading : "Options",desc : "Set interactive options like radio, checkbox and dropdown" },
];

const fontFamily = [
{value: "'Acme', sans-serif", label: 'Acme'},
{value: "'Alata', sans-serif", label: 'Alata'},
{value: "'Anton', sans-serif", label: 'Anton'},
{value: "'Anuphan', sans-serif", label: 'Anu'},
{value: "'Arvo', serif", label: 'Arvn'},
{value: "'Baskervville', serif", label: 'Baskervville'},
{value: "'Bebas Neue', sans-serif", label: 'Bebas Neue'},
{value: "'Bellota', sans-serif", label: 'Bellota'},
{value: 'cursive', label: 'Cursive'},
{value: "'Dongle', sans-serif", label: 'Dongle'},
{value: "'Elsie', serif", label: 'Elsie'},
{value: "'Elsie Swash Caps', serif", label: 'Elsie Swash Caps'},
{value: "'Ephesis', cursive", label: 'Ephesis'},
{value: "'Erica One', sans-serif", label: 'Erica One'},
{value: "'Familjen Grotesk', sans-serif", label: 'Familjen Grotesk'},
{value: 'fantasy', label: 'Fantasy'},
{value: "'Fira Sans', sans-serif", label: 'Fira Sans'},
{value: "'Fugaz One', sans-serif", label: 'Fugaz'},
{value: "'Gayathri', sans-serif", label: 'Gayathri'},
{value: "'Golos Text', sans-serif", label: 'Golos Text'},
{value: "'Istok Web', sans-serif", label: 'Istok Web'},
{value: "'Kaushan Script', cursive", label: 'Kaushan Script'},
{value: "'Khand', sans-serif", label: 'Khand'},
{value: "'Lato', sans-serif", label: 'Lato'},
{value: "'Lilita One', sans-serif", label: 'Lilita One'},
{value: "'Lustria', sans-serif", label: 'Lustria'},
{value: "'Mali', cursive", label: 'Mali'},
{value: "'Miniver', cursive", label: 'Miniver'},
{value: 'monospace', label: 'Monospace'},
{value: "'Montserrat Alternates', sans-serif", label: 'Montserrat'},
{value: "'Nixie One', system-ui", label: 'Nixie One'},
{value: "'Noto Sans', sans-serif", label: 'Noto Sans'},
{value: "'Oleo Script', system-ui", label: 'Oleo Script'},
{value: "'Pacifico', cursive", label: 'Pacifico'},
{value: "'Parisienne', cursive", label: 'Parisienne'},
{value: "'Playfair Display', serif", label: 'Playfair Display'},
{value: "'Poppins', sans-serif", label: 'Poppins'},
{value: "'Sacramento', cursive", label: 'Sacramento'},
{value: 'sans-serif', label: 'Sans-Serif'},
{value: "'Sarabun', sans-serif", label: 'Sarab'},
{value: 'serif', label: 'Serif'},
{value: "'Sevillana', cursive", label: 'Sevillana'},
{value: "'Shadows Into Light Two', cursive", label: 'Shadows Into Light'},
{value: "'Sulphur Point', sans-serif", label: 'Sulphur Point'},
{value: "'Sumana', serif", label: 'Sumana'},
{value: 'system-ui', label: 'System-ui'},
{value: "'Taprom', system-ui", label: 'Taprom'},
{value: "'Tienne', serif", label: 'Tienne'},
{value: 'Times New Roman', label: 'Times New Roman'},
{value: 'ui-sans-serif', label: 'Ui-Sans-Serif'},
{value: 'ui-serif', label: 'Ui-Serif'},
{value: "'Urbanist', sans-serif", label: 'Urbanist'},
{value: "'Vollkorn', serif", label: 'Vollkorn'},
{value: "'Zilla Slab', serif", label: 'Zilla Slab'}
];

const fontStyle = [
  "lighter","normal","bold"
]

const labelData = [
  {
    id: 1,
    type: "label",
    name: "Add Title",
    size: "20",
    fontWeight: "bolder",
    fontStyle: "normal",
    height: 60,
  },
  {
    id: 2,
    type: "label",
    name: "Add Subtitle",
    size: "16",
    fontWeight: "bold",
    fontStyle: "normal",
    height: 60,
  },
  {
    id: 3,
    type: "label",
    subtype:"body",
    name: "Add Body Text",
    size: "13",
    fontWeight: "normal",
    fontStyle: "normal",
    height: 60,
  },
  {
    id: 4,
    type: "label",
    name: `"Add Quote"`,
    size: "13",
    fontWeight: "normal",
    fontStyle: "italic",
    height: 60,
  },
  {
    id: 5,
    type: "label",
    name: "Add multiline body paragraph text",
    size: "12",
    fontWeight: "normal",
    fontStyle: "normal",
    subtype: "multiline",
    height: 60,
  },
];
const textData = [
  {
    id: 1,
    type: "text",
    placeholder: "Text Input",
    subtype: "text",
    img: textImg,
    height: 42,
    width : 183
  },
  {
    id: 2,
    type: "text",
    placeholder: "Numeric Input",
    subtype: "number",
    img: numericImg,
    height: 42,
    width : 183
  },
  {
    id: 3,
    type: "text",
    placeholder: "Password",
    subtype: "password",
    img: passwordImg,
    height: 42,
    width : 183
  },
  {
    id: 4,
    type: "text",
    placeholder: "Email",
    subtype: "email",
    img: emailImg,
    height: 42,
    width : 183
  },
  {
    id: 5,
    type: "text",
    placeholder: "Telephone",
    subtype: "tel",
    img: telImg,
    height: 42,
    width : 183
  },
  {
    id: 6,
    type: "text",
    placeholder: "Date",
    subtype: "date",
    img: dateImg,
    height: 42,
    width : 183
  },
  {
    id: 7,
    type: "textarea",
    placeholder: "Multiline Text",
    subtype: "",
    img: textAreaImg,
    height: 42,
    width : 183
  },
];
const buttonData = [
  {
    id: 1,
    panelName: "Rectangle Buttons",
    data: [
      {
        id: 1,
        type: "button",
        placeholder: "RNext",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-arrow-right",
      },
      {
        id: 2,
        type: "button",
        placeholder: "LBack",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-arrow-left",
      },
      {
        id: 3,
        type: "button",
        placeholder: "SButton",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
      },
      {
        id: 4,
        type: "button",
        placeholder: "RTick",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-check",
      },
      {
        id: 5,
        type: "button",
        placeholder: "RDownload",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-arrow-down",
      },
      {
        id: 6,
        type: "button",
        placeholder: "RCall",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-phone",
      },
      {
        id: 7,
        type: "button",
        placeholder: "RCancel",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-xmark",
      },
      {
        id: 8,
        type: "button",
        placeholder: "RBuy",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: 0,
        borderColor: "#00D8BE",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-cart-shopping",
      },
    ],
  },
  {
    id: 2,
    panelName: "Rectangle Outlines",
    data: [
      {
        id: 1,
        type: "button",
        placeholder: "RNext",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-arrow-right",
      },
      {
        id: 2,
        type: "button",
        placeholder: "LBack",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-arrow-left",
      },
      {
        id: 3,
        type: "button",
        placeholder: "SButton",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
      },
      {
        id: 4,
        type: "button",
        placeholder: "LTick",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-check",
      },
      {
        id: 5,
        type: "button",
        placeholder: "LDownload",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-arrow-down",
      },
      {
        id: 6,
        type: "button",
        placeholder: "LCall",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-phone",
      },
      {
        id: 7,
        type: "button",
        placeholder: "LCancel",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-xmark",
      },
      {
        id: 8,
        type: "button",
        placeholder: "LBuy",
        backgroundColor: "#000000",
        color: "#ffffff",
        border: 2,
        borderColor: "#ffffff",
        borderRadius: 5,
        height: 33,
        width: 100,
        icon: "fa-solid fa-cart-shopping",
      },
    ],
  },
];

const hotspotData = [
  {
    id: 1,
    type: "hotspot",
    name: "Rectangle Hotspot",
    backgroundColor: "transparent",
    img: rectangle,
    border: 0,
    borderRadius: 5,
    height: 60,
    width: 60,
    text:"Should be used on rectangular shapes, segments, and contexts that you want to make clickable.",
  },
  {
    id: 2,
    type: "hotspot",
    name: "Circle Hotspot",
    backgroundColor: "transparent",
    img: circle,
    border: 0,
    borderRadius: 1000,
    height: 60,
    width: 60,
    text:"Use circular hotspot where you want to cover a larger area.",
  },
];

const optionData = [
  {
    id: 1,
    type: "radio",
    name: "Radio",
    img: radio,
    backgroundColor: "#00D8BE",
    height: 20,
    width: 20,
    text: "Use radio button where there are multiple options but you want user to select one.",
  },
  {
    id: 2,
    type: "checkbox",
    name: "Checkbox",
    img: checkbox,
    backgroundColor: "#00D8BE",
    height: 20,
    width: 20,
    text: "Use checkbox button when you want the user to select multiple options.",
  },
  {
    id: 3,
    type: "dropdown",
    name: "--Please Select--",
    size: "13",
    fontWeight: "normal",
    fontStyle: "normal",
    text: "If you have a list of options then use this.",
  },
];
const assetData = [
  {
    id: 1,
    panelName: "Images",
    data: [
      {
        id: 1,
        type: "image",
        payload: SampleImg,
        size: "20",
      },
    ],
  },
  {
    id: 2,
    panelName: "Videos",
    data: [
      {
        id: 1,
        type: "image",
        payload: SampleImg,
        size: "20",
      },
    ],
  },
  {
    id: 3,
    panelName: "Audios",
    data: [
      {
        id: 1,
        type: "image",
        payload: SampleImg,
        size: "20",
      },
    ],
  },
];
const rightDrawerType = [
  {
    id: 1,
    name: "Component",
  },
  {
    id: 2,
    name: "Design",
  },
  {
    id: 3,
    name: "Animation",
  },
  {
    id: 2,
    name: "Action",
  },
];

const assetType = [
  {
    id: 1,
    name: "Template Assets",
  },
  {
    id: 2,
    name: "Global Assets",
  }
];

const speedValues = [
  { id: 1, value: "1s", label: "slow" },
  { id: 2, value: ".5s", label: "Normal" },
  { id: 3, value: ".2s", label: "Fast" },
];
const EntryAnimation = [
  { name : "Attention Seeker",
  data :  [
  { id: 1, value: "bounce" },
  { id: 2, value: "flash" },
  { id: 3, value: "pulse" },
  { id: 4, value: "rubberBand" },
  { id: 5, value: "shakeX" },
  { id: 6, value: "shakeY" },
  { id: 7, value: "headShake" },
  { id: 8, value: "swing" },
  { id: 9, value: "tada" },
  { id: 10, value: "wobble" },
  { id: 12, value: "jello" },
  { id: 13, value: "heartBeat" },
 ]},
 {
  name : "Back Entrances",
  data : [
  { id: 1, value: "backInDown" },
  { id: 2, value: "backInLeft" },
  { id: 3, value: "backInRight" },
  { id: 4, value: "backInUp" },
  ]
 },
 {
  name : "Bouncing Entrances",
  data : [
  { id: 1, value: "bounceIn" },
  { id: 2, value: "bounceInDown" },
  { id: 3, value: "bounceInLeft" },
  { id: 4, value: "bounceInRight" },
  { id: 5, value: "bounceInUp" },
  ]
 },
 {
  name : "Fading Entrances",
  data : [
  { id: 1, value: "fadeIn" },
  { id: 2, value: "fadeInDown" },
  { id: 3, value: "fadeInDownBig" },
  { id: 4, value: "fadeInLeft" },
  { id: 5, value: "fadeInLeftBig" },
  { id: 6, value: "fadeInRight" },
  { id: 7, value: "fadeInRightBig" },
  { id: 8, value: "fadeInUp" },
  { id: 9, value: "fadeInUpBig" },
  { id: 10, value: "fadeInTopLeft" },
  { id: 12, value: "fadeInTopRight" },
  { id: 13, value: "fadeInBottomLeft" },
  { id: 14, value: "fadeInBottomRight" },
]
 },
 {
  name : "Flippers Entrances",
  data : [
    { id: 1, value: "flip" },
    { id: 2, value: "flipInX" },
    { id: 3, value: "flipInY" },
  ]
 },
 {
  name : "Light Speed Entrances",
  data : [
    { id: 1, value: "lightSpeedInRight" },
    { id: 2, value: "lightSpeedInLeft" },
  ]
 },
 {
  name : "Rotating Entrances",
  data : [
    { id: 1, value: "rotateIn" },
    { id: 2, value: "rotateInDownLeft" },
    { id: 3, value: "rotateInDownRight" },
    { id: 4, value: "rotateInUpLeft" },
    { id: 5, value: "rotateInUpRight" },
  ]
 },
 {
  name : "Special Entrances",
  data : [
    { id: 2, value: "jackInTheBox" },
    { id: 3, value: "rollIn" },
  ]
 },
 {
  name : "Zooming Entrances",
  data : [
    { id: 1, value: "zoomIn" },
    { id: 2, value: "zoomInDown" },
    { id: 3, value: "zoomInLeft" },
    { id: 4, value: "zoomInRight" },
    { id: 5, value: "zoomInUp" },
  ]
 },
 {
  name : "Sliding Entrances",
  data : [
    { id: 1, value: "slideInDown" },
    { id: 2, value: "slideInLeft" },
    { id: 3, value: "slideInRight" },
    { id: 4, value: "slideInUp" },
  ]
 }
]

const OutAnimation  = [
  { name : "Attention Seeker",
  data :  [
  { id: 1, value: "bounce" },
  { id: 2, value: "flash" },
  { id: 3, value: "pulse" },
  { id: 4, value: "rubberBand" },
  { id: 5, value: "shakeX" },
  { id: 6, value: "shakeY" },
  { id: 7, value: "headShake" },
  { id: 8, value: "swing" },
  { id: 9, value: "tada" },
  { id: 10, value: "wobble" },
  { id: 12, value: "jello" },
  { id: 13, value: "heartBeat" },
 ]},
 {
 name : "Back Exit",
 data : [
  { id: 1, value: "backOutDown" },
  { id: 2, value: "backOutLeft" },
  { id: 3, value: "backOutRight" },
  { id: 4, value: "backOutUp" },
]},
{
  name  : "Bouncing Exits",
  data  : [
    { id: 1, value: "bounceOut"},
    { id: 2, value: "bounceOutDown"},
    { id: 3, value: "bounceOutLeft"},
    { id: 4, value: "bounceOutRight"},
    { id: 5, value: "bounceOutUp"},
  ]
},
{
  name : "Fading Exits",
  data : [
    { id: 1, value: "fadeOut" },
    { id: 2, value: "fadeOutDown" },
    { id: 3, value: "fadeOutDownBig" },
    { id: 4, value: "fadeOutLeft" },
    { id: 5, value: "fadeOutLeftBig" },
    { id: 6, value: "fadeOutRight" },
    { id: 7, value: "fadeOutRightBig" },
    { id: 8, value: "fadeOutUp" },
    { id: 9, value: "fadeOutUpBig" },
    { id: 10, value: "fadeOutTopLeft" },
    { id: 12, value: "fadeOutTopRight" },
    { id: 13, value: "fadeOutBottomLeft" },
    { id: 14, value: "fadeOutBottomRight" },
  ]
},
{
  name : "Flippers",
  data  : [
    { id: 1, value: "flip" },
    { id: 2, value: "flipOutX" },
    { id: 3, value: "flipOutY" },
  ]
},
{
  name : "Light Speed",
  data  : [
    { id: 1, value: "lightSpeedOutRight" },
    { id: 2, value: "lightSpeedOutLeft" },
  ]
},
{
   name  : "Rotating Exits",
   data  : [
    { id: 1, value: "rotateOut" },
    { id: 2, value: "rotateOutDownLeft" },
    { id: 3, value: "rotateOutDownRight" },
    { id: 4, value: "rotateOutUpLeft" },
    { id: 5, value: "rotateOutUpRight" },
  ]
},
{
  name  : "Specials",
  data  : [
    { id: 1, value: "hinge" },
    { id: 4, value: "rollOut" },
  ]
},
{
  name : "Zooming Exits",
  data  : [
    { id: 1, value: "zoomOut" },
    { id: 2, value: "zoomOutDown" },
    { id: 3, value: "zoomOutLeft" },
    { id: 4, value: "zoomOutRight" },
    { id: 5, value: "zoomOutUp" },
  ]
},
{
  name : "Sliding Exits",
  data  : [
    { id: 1, value: "slideOutDown" },
    { id: 2, value: "slideOutLeft" },
    { id: 3, value: "slideOutRight" },
    { id: 4, value: "slideOutUp" },
  ]
}
]
const frequentlyUsed = [
  { id: 1, value: "fadeIn" },
  { id: 2, value: "fadeOut" },
  { id: 3, value: "zoomIn" },
];
const backEntrance = [
  { id: 1, value: "backInDown" },
  { id: 2, value: "backInLeft" },
  { id: 3, value: "backInRight" },
  { id: 4, value: "backInUp" },
];
const backExit = [
  { id: 1, value: "backOutDown" },
  { id: 2, value: "backOutLeft" },
  { id: 3, value: "backOutRight" },
  { id: 4, value: "backOutUp" },
];
const bouncingEntrances = [
  { id: 1, value: "bounceIn" },
  { id: 2, value: "bounceInDown" },
  { id: 3, value: "bounceInLeft" },
  { id: 4, value: "bounceInRight" },
  { id: 5, value: "bounceInUp" },
];
const bouncingExit = [
  { id: 1, value: "bounceOut" },
  { id: 2, value: "bounceOutDown" },
  { id: 3, value: "bounceOutLeft" },
  { id: 4, value: "bounceOutRight" },
  { id: 5, value: "bounceOutUp" },
];
const fadingEntrance = [
  { id: 1, value: "fadeIn" },
  { id: 2, value: "fadeInDown" },
  { id: 3, value: "fadeInDownBig" },
  { id: 4, value: "fadeInLeft" },
  { id: 5, value: "fadeInLeftBig" },
  { id: 6, value: "fadeInRight" },
  { id: 7, value: "fadeInRightBig" },
  { id: 8, value: "fadeInUp" },
  { id: 9, value: "fadeInUpBig" },
  { id: 10, value: "fadeInTopLeft" },
  { id: 12, value: "fadeInTopRight" },
  { id: 13, value: "fadeInBottomLeft" },
  { id: 14, value: "fadeInBottomRight" },
];
const fadingExit = [
  { id: 1, value: "fadeOut" },
  { id: 2, value: "fadeOutDown" },
  { id: 3, value: "fadeOutDownBig" },
  { id: 4, value: "fadeOutLeft" },
  { id: 5, value: "fadeOutLeftBig" },
  { id: 6, value: "fadeOutRight" },
  { id: 7, value: "fadeOutRightBig" },
  { id: 8, value: "fadeOutUp" },
  { id: 9, value: "fadeOutUpBig" },
  { id: 10, value: "fadeOutTopLeft" },
  { id: 12, value: "fadeOutTopRight" },
  { id: 13, value: "fadeOutBottomLeft" },
  { id: 14, value: "fadeOutBottomRight" },
];
const FlippersEntrance = [
  { id: 1, value: "flip" },
  { id: 2, value: "flipInX" },
  { id: 3, value: "flipInY" },
];
const FlippersExit = [
  { id: 1, value: "flip" },
  { id: 2, value: "flipOutX" },
  { id: 3, value: "flipOutY" },
];
const LightSpeedEntrance = [
  { id: 1, value: "lightSpeedInRight" },
  { id: 2, value: "lightSpeedInLeft" },
];
const LightSpeedExit = [
  { id: 1, value: "lightSpeedOutRight" },
  { id: 2, value: "lightSpeedOutLeft" },
];
const rotatingEntrance = [
  { id: 1, value: "rotateIn" },
  { id: 2, value: "rotateInDownLeft" },
  { id: 3, value: "rotateInDownRight" },
  { id: 4, value: "rotateInUpLeft" },
  { id: 5, value: "rotateInUpRight" },
];
const rotatingExit = [
  { id: 1, value: "rotateOut" },
  { id: 2, value: "rotateOutDownLeft" },
  { id: 3, value: "rotateOutDownRight" },
  { id: 4, value: "rotateOutUpLeft" },
  { id: 5, value: "rotateOutUpRight" },
];
const specialEntrance = [
  { id: 2, value: "jackInTheBox" },
  { id: 3, value: "rollIn" },
];
const specialExit = [
  { id: 1, value: "hinge" },
  { id: 4, value: "rollOut" },
];
const zoomingEntrance = [
  { id: 1, value: "zoomIn" },
  { id: 2, value: "zoomInDown" },
  { id: 3, value: "zoomInLeft" },
  { id: 4, value: "zoomInRight" },
  { id: 5, value: "zoomInUp" },
];
const zoomingExit = [
  { id: 1, value: "zoomOut" },
  { id: 2, value: "zoomOutDown" },
  { id: 3, value: "zoomOutLeft" },
  { id: 4, value: "zoomOutRight" },
  { id: 5, value: "zoomOutUp" },
];
const slidingEntrance = [
  { id: 1, value: "slideInDown" },
  { id: 2, value: "slideInLeft" },
  { id: 3, value: "slideInRight" },
  { id: 4, value: "slideInUp" },
];
const slidingExit = [
  { id: 1, value: "slideOutDown" },
  { id: 2, value: "slideOutLeft" },
  { id: 3, value: "slideOutRight" },
  { id: 4, value: "slideOutUp" },
];
const attentionSeeker = [
  { id: 1, value: "bounce" },
  { id: 2, value: "flash" },
  { id: 3, value: "pulse" },
  { id: 4, value: "rubberBand" },
  { id: 5, value: "shakeX" },
  { id: 6, value: "shakeY" },
  { id: 7, value: "headShake" },
  { id: 8, value: "swing" },
  { id: 9, value: "tada" },
  { id: 10, value: "wobble" },
  { id: 12, value: "jello" },
  { id: 13, value: "heartBeat" },
];
const alignItemData = [
  { id: 1, image: leftAlign },
  { id: 2, image: bottomCenterAlign },
  { id: 3, image: rightAligh },
  { id: 4, image: topCenterAlign },
  { id: 5, image: topAlign },
  { id: 6, image: bottomAlign },
];

const ActionOptions=[
  { value: 0, label: "None" },
  {
    value: 1,
    label: "Open Link / Go to URL",
  },
  {
    value: 2,
    label: "Seek video / Change video time",
  },
  {
    value: 3,
    label: "Store inputs & seek video",
  },
  {
    value: 4,
    label: "Store inputs & show response",
  },
  {
    value: 5,
    label: "Call a number",
  },
];

const FlipOptions =[
  { value: "N", label: "None" },
  { value: "H", label: "Horizontal" },
  { value: "V", label: "Vertical" },
];

const LinkOptions = [
  {
    value: 1,
    label: "Open link in new window",
  },
  {
    value: 0,
    label: "Open link in same window",
  },
];

const StoreOptions = [
  {
    value: 0,
    label: "Current Screen",
  },
  {
    value: 1,
    label: "All Previous",
  },
];

export {
  layerAlign,
  sidebarData,
  labelData,
  textData,
  buttonData,
  hotspotData,
  optionData,
  assetData,
  rightDrawerType,
  alignItemData,
  attentionSeeker,
  slidingExit,
  slidingEntrance,
  zoomingExit,
  zoomingEntrance,
  specialEntrance,
  specialExit,
  rotatingExit,
  rotatingEntrance,
  LightSpeedEntrance,
  LightSpeedExit,
  FlippersEntrance,
  FlippersExit,
  fadingExit,
  fadingEntrance,
  bouncingExit,
  bouncingEntrances,
  backExit,
  backEntrance,
  frequentlyUsed,
  speedValues,
  EntryAnimation,
  OutAnimation,
  assetType,
  fontFamily,
  fontStyle,
  ActionOptions,
  FlipOptions,
  LinkOptions,
  StoreOptions
};
