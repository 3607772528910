import React from 'react';
import { Table, message } from 'antd';
import downloadIcon from "../../assests/commonIcons/userModuleIcons/downloadIconBlue.svg";
import '../../common/styles/userModule/PurchaseStyles.css';

const PurchasedHistory = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const openMessage = () => {
    setTimeout(() => {
      messageApi.open({
        type: 'success',
        content: 'Your file is being downloaded',
        duration: 3,
        rtl: true,
        className: 'custom-class',
      });
    }, 1000);
  };

  const columns = [
    {
      title: 'Plan Name',
      dataIndex: 'planName',
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
   
    {
      title: 'Amonut',
      dataIndex: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Reports',
      dataIndex: 'reports',
    },
  ];
  const data = [
    {
      key: '1',
      planName: 'Free',
      invoice: 'INV-C-2022-219052',
      date: '23 Aug 2023',
      amount: '0$',
      status: 'Expired',
      reports: <span className='cursor-pointer' onClick={openMessage}><img src={downloadIcon} alt="downloadIcon" className="w-[20px]"/></span>,
    },
    {
      key: '2',
      planName: 'Professional',
      invoice: 'INV-C-2022-219052',
      date: '23 Aug 2023',
      amount: '40$',
      status: 'Expired',
      reports: <span className='cursor-pointer' onClick={openMessage}><img src={downloadIcon} alt="downloadIcon" className="w-[20px]"/></span>,
    },
    {
      key: '3',
      planName: 'Professional',
      invoice: 'INV-C-2022-219052',
      date: '23 Aug 2023',
      amount: '40$',
      status: 'Ongoing',
      reports: <span className='cursor-pointer' onClick={openMessage}><img src={downloadIcon} alt="downloadIcon" className="w-[20px]"/></span>,
    }
  ];





  return (
    <>
    {contextHolder}
    <div className='purchaseTable w-full'>
    <Table 
    // pagination can be apply in future
    pagination={false}
    columns={columns}
    dataSource={data} 
  />
  </div>
  </>
  )
}

export default PurchasedHistory