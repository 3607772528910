import { useState, createContext, useContext } from "react";

const CustomerInfoContext = createContext();

// Customer Information Hook to send customer information. (Used in index.js)
export const CustomerInfoProvider = ({ children }) => {
  const [customerInfo, setCustomerInfo] = useState(null);

  return (
    <CustomerInfoContext.Provider value={{ customerInfo, setCustomerInfo }}>
      {children}
    </CustomerInfoContext.Provider>
  );
};

export const useCustomerInfo = () => {
    const context = useContext(CustomerInfoContext);
    if (!context) {
      throw new Error('useCustomerInfo must be used within a CustomerInfoProvider');
    }
    return context;
  };