import React, { useEffect } from "react";
import "./ComponentDetail.css";
import { Button, Divider, Input, InputNumber, Modal, Popover, Switch, Tooltip } from "antd";
import { useState } from "react";
import DetailMenu from "./DetailMenu";
import TextArea from "antd/es/input/TextArea";
import alertcircle from "../../assets/alert-circle.svg";
import MultiInput from "../commonComponents/MultiInput";
import PopTick from "../../assets/PopTick.svg";
import cross from "../../assets/Cross.svg";
import { ActionOptions,LinkOptions,StoreOptions } from "../data.js";
const ComponentDetail = (props) => {
  const [open, setOpen] = useState(false);
  const [openWeb, setOpenWeb] = useState(false);
  const [hover, setHover] = useState(false);
  const [openFamily, setOpenFamily] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openStore, setOpenStore] = useState(false);
  function handleOpenStore() {
    setOpenStore(!openStore);
  }
  function handleOpenLink() {
    setOpenLink(!openLink);
  }
  function handleOpenFamily() {
    setOpenFamily(!openFamily);
  }
  const error = () => {
    const modal = Modal.error({
      title: "The Layer Name should be unique",
      content: "Please change the layer name and ensure that it is unique.",
      onOk: () => {
        document.getElementById("LNUQ").focus();
        props.setIsModalOpen(false);
        setOpen(false);
        Modal.destroyAll();
      },
    });
    return modal;
  };

  const Linkerror = () => {
    const modal = Modal.error({
      title: "Invalid URL",
      content: "Please provide the Correct web address. For Example : https://www.vspagy.com",
      onOk: () => {
        document.getElementById("LINKVALID").focus();
        props.setIsModalOpen(false);
        setOpenWeb(false);
        Modal.destroyAll();
      },
    });
    return modal;
  };

  return (
    <div
      className="overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
    scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] h-[84vh] w-full  font-sans "
    >
      {props.displayTextConfig && (
        <>
          <div>
            <div className="p-4 pt-2 pb-0 mt-1">
              <div className="font-medium text-left text-sm text-white">
                Text Configuration
              </div>
              <div className="flex flex-col mt-4">
                <div className="flex">
                  <label className="text-left font-normal text-xs items-start mb-2">
                    Layer Name
                  </label>
                </div>

                <Input
                  id="LNUQ"
                  placeholder="Enter Layer Name"
                  value={props.layerName}
                  disabled = {openWeb}
                  onClick={() => {
                    // props.fieldEnabled.current = true;
                    props.fieldEnabled.current = true;
                  }}
                  onBlur={() => {
                    if (props.layerName.trim() === "") {
                      props.setLayername(
                        props.type.toUpperCase() + "_" + props.itemSelected
                      );
                    }
                    for (let i = 0; i < props.canvaContent.length; i++) {
                      if (
                        props.canvaContent[i].id !== props.itemSelected &&
                        !props.isRight
                      ) {
                        if (
                          props.canvaContent[i].layername === props.layerName
                        ) {
                          console.log(
                            "Alert",
                            props.canvaContent[i].id,
                            props.itemSelected
                          );
                          if (!open) {
                            setOpen(true);
                            error();
                          }
                          props.setIsModalOpen(true);
                          break;
                        }
                      }
                    }
                    // props.fieldEnabled.current = false;
                    props.fieldEnabled.current = false;
                  }}
                  onChange={(e) => {
                    let val = e.target.value;
                    let flag = false;
                    for (let i = 0; i < props.canvaContent.length; i++) {
                      if (props.canvaContent[i].id !== props.itemSelected) {
                        if (props.canvaContent[i].layername === val) {
                          props.setIsRight(false);
                          flag = true;
                          break;
                        }
                      }
                    }
                    if (!flag) {
                      props.setIsRight(true);
                    }
                    if (props.type == "vimage") {
                      val = val.startsWith("vimg_") ? val : "vimg_";
                      props.setPayload(`{${val}}`);
                    } else if (props.type == "vVideo") {
                      val = val.startsWith("vvid_") ? val : "vvid_";
                      props.setPayload(`{${val}}`);
                    }
                    props.setLayername(val);
                  }}
                  className="h-9 w-full  editor-input"
                />
              </div>
              {(props.type === "text" || props.type === "textarea") && (
                <div className="flex flex-col mt-4">
                  <label className="text-left font-normal text-xs items-start mb-2">
                    Placeholder Text
                  </label>
                  <Input
                    value={props.placeHolder}
                    disabled={props.isModalOpen}
                    onClick={() => {
                      // props.fieldEnabled.current = true;
                      props.fieldEnabled.current = true;
                    }}
                    onChange={(e) => {
                      props.setPlaceholder(e.target.value);
                    }}
                    onBlur={() => {
                      // props.fieldEnabled.current = false;
                      props.fieldEnabled.current = false;
                    }}
                    placeholder="Enter Placeholder"
                    className="h-9 w-full  editor-input "
                  />
                </div>
              )}
              {(props.type === "label" ||
                props.type === "button" ||
                props.type === "radio" ||
                props.type === "checkbox" ||
                props.type === "dropdown") && (
                <div className="flex flex-col mt-4">
                  <div className="flex">
                    <label className="text-left font-normal text-xs items-start mb-2">
                      {props.type === "label"
                        ? "Label Name"
                        : props.type === "radio"
                        ? " Radio Name"
                        : props.type === "checkbox"
                        ? "CheckBox Name"
                        : props.type === "dropdown"
                        ? "Display Label Name"
                        : "Button Name"}
                    </label>
                    {(props.type === "label" || props.type === "radio") && (
                      <Tooltip
                        className="ml-2 "
                        title={
                          props.type === "label"
                            ? "To add personalized variables enter values in curly braces. For eg: {name}, {age}."
                            : "Ensure that when creating a group of options, the Radio Name remains same."
                        }
                        color="#2B2B2C"
                        trigger="click"
                        placement="leftBottom"
                        overlayStyle={{
                          boxShadow: "2px",
                          fontSize: "14px",
                        }}
                      >
                        <img
                          className="h-[18px] w-[18px] cursor-pointer"
                          src={alertcircle}
                          alt="info-icon"
                        />
                      </Tooltip>
                    )}
                  </div>
                  {props?.subtype !== "multiline" && (
                    <Input
                      value={props.name}
                      disabled={props.isModalOpen}
                      onClick={() => {
                        // props.fieldEnabled.current = true;
                        props.fieldEnabled.current = true;
                      }}
                      onChange={(e) => {
                        props.setName(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (props.name === "") {
                          if (props.type === "dropdown") {
                            props.setName("--Please Select--");
                          } else if (props.type === "radio") {
                            props.setName("Radio");
                          } else if (props.type === "checkbox") {
                            props.setName("Checkbox");
                          }
                        }
                        // props.fieldEnabled.current = false;
                        props.fieldEnabled.current = false;
                      }}
                      placeholder={
                        props.type === "label"
                          ? "Enter Label Value"
                          : props.type === "radio"
                          ? "Enter Radio Name"
                          : props.type === "checkbox"
                          ? "Enter Checkbox Name"
                          : props.type === "dropdown"
                          ? "Default Text"
                          : "Enter Button Name"
                      }
                      className="h-9 w-full  editor-input "
                    />
                  )}
                  {props?.subtype === "multiline" && (
                    <TextArea
                      value={props.name}
                      disabled={props.isModalOpen}
                      onClick={() => {
                        // props.fieldEnabled.current = true;
                        props.fieldEnabled.current = true;
                      }}
                      onChange={(e) => {
                        props.setName(e.target.value);
                      }}
                      onBlur={() => {
                        // props.fieldEnabled.current = false;
                        props.fieldEnabled.current = false;
                      }}
                      placeholder="Enter Multiline Text"
                      className="overflow-y-auto h-9 w-full bg-[#2D4966] editor-input"
                    />
                  )}
                </div>
              )}
              {(props.type === "button" || props.type === "hotspot") && (
                <div className="flex flex-col mt-4">
                  <label className="text-left font-normal text-sm items-start mb-2">
                    Set Action
                  </label>
                  <div className="relative">
                    {!props.isRight && (
                      <div className="z-10 absolute h-[25px] w-full  top-[-25px] left-0"></div>
                    )}
                    <div className="">
                      <div className="flex mt-2 action-select">
                        <Popover
                          placement="left"
                          arrow={false}
                          open={openFamily}
                          onOpenChange={handleOpenFamily}
                          content={
                            <div
                              className="bg-[#050506] w-[250px] h-[175px] absolute right-[20px] top-[-40px] "
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              }}
                              onMouseMove={() => {
                                setHover(true);
                              }}
                            >
                              <div className="flex justify-between p-3 py-2 pr-3">
                                <div className="text-[#fff] text-[14px] font-semibold">
                                  Select Action
                                </div>
                                <img
                                  className="cursor-pointer"
                                  src={cross}
                                  alt="cross"
                                  onClick={() => {
                                    setOpenFamily(false);
                                  }}
                                />
                              </div>
                              <Divider className="!m-0" />
                              <div
                                className="h-[150px] overflow-auto  scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                              >
                                {ActionOptions.map((item, index) => {
                                  return (
                                    <div
                                      className={`${
                                        props.action === item.value && !hover
                                          ? "bg-[#1D1D1F]"
                                          : ""
                                      } flex mt-1 py-1  w-full text-left cursor-pointer hover:bg-[#1D1D1F]`}
                                      onClick={() => {
                                        // props.setfontFamily(item.value);
                                        props.setAction(item.value);
                                        if (item.value === 0) {
                                          props.setActionsArray([]);
                                        } else if (item.value === 1) {
                                          props.setActionsArray([null, 0]);
                                        } else if (item.value === 2) {
                                          props.setActionsArray([null]);
                                        } else if (item.value === 3) {
                                          props.setActionsArray([""]);
                                        } 
                                      }}
                                    >
                                      <div className="pl-[5px] w-[40px] h-[20px]">
                                        {props.action === item.value && (
                                          <img
                                            className="w-[100%] h-[100%]"
                                            src={PopTick}
                                            alt="P"
                                          />
                                        )}
                                      </div>
                                      <div className="text-[#ffffff] pl-[10px]  text-[14px] ">
                                        {item.label}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          }
                          trigger="click"
                        >
                          <div
                            className=" cursor-pointer"
                            style={{
                              textAlign: "center",
                              height: "28px",
                            }}
                          >
                            <div
                              className="flex"
                              onClick={() => {
                                setHover(false);
                              }}
                            >
                              <div className=" text-[12px]">
                                {props.action === 0
                                  ? ActionOptions[0].label
                                  : props.action === 1
                                  ? ActionOptions[1].label
                                  : props.action === 2
                                  ? ActionOptions[2].label
                                  : props.action === 3
                                  ? ActionOptions[3].label
                                  : ActionOptions[4].label}
                              </div>
                              <div className="mt-[2px] ml-4">
                                <svg
                                  viewBox="64 64 896 896"
                                  focusable="false"
                                  data-icon="down"
                                  width="10px"
                                  height="10px"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </Popover>
                      </div>
                      <div className="">
                        {props.action === 0 && (
                          <div className="text-xs text-left text-[#c0bdbd]">
                            No action choosen.
                          </div>
                        )}
                        {props.action === 1 && (
                          <div className="text-left text-xs text-[#c0bdbd]">
                            <div>
                              This action will lead viewer to the provided
                              address/URL.
                            </div>
                            <Input
                              value={props.actionsArray[0]}
                              id="LINKVALID"
                              onClick={() => {
                                // props.setFieldEnabled(true);
                                props.fieldEnabled.current = true;
                              }}
                              disabled = {open}
                              onBlur={() => {
                                const urlRegex = /^(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/;
                                if (props.actionsArray[0] === null ||urlRegex.test(props.actionsArray[0]) || props.actionsArray[0].trim() === "") {
                                } else {
                                  if (!openWeb) {
                                    setOpenWeb(true);
                                    Linkerror();
                                  }
                                  props.setIsModalOpen(true);
                                }
                                // props.setFieldEnabled(false);
                                props.fieldEnabled.current = false;
                              }}
                              onChange={(e) => {
                                props.actionsArray[0] = e.target.value;
                                props.setActionsArray([...props.actionsArray]);
                                const urlRegex = /^(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/;
                                if (!urlRegex.test(e.target.value) && e.target.value.trim()!== "") {
                                  props.setIsRight(false);
                                  console.log(
                                    "UP",
                                    document.querySelector(
                                      ".ant-collapse-header"
                                    )
                                  );
                                } else {
                                  props.setIsRight(true);
                                }
                              }}
                              placeholder="Web address/URL"
                              className="h-9 mt-4 w-full  editor-input "
                            />
                            <div className="action-select">
                              <Popover
                                placement="left"
                                arrow={false}
                                open={openLink}
                                onOpenChange={handleOpenLink}
                                content={
                                  <div
                                    className={`bg-[#050506] w-[250px] h-[120px] absolute right-[20px] top-[-40px] `}
                                    style={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                    }}
                                  >
                                    <div className="flex justify-between p-3 py-2 pr-3">
                                <div className="text-[#fff] font-semibold">
                                  Select Redirect Action
                                </div>
                                <img
                                  className="cursor-pointer"
                                  src={cross}
                                  alt="cross"
                                  onClick={() => {
                                    setOpenLink(false);
                                  }}
                                />
                              </div>
                              <Divider className="!m-0" />
                                    <div
                                      className="mb-2"
                                    >
                                      {LinkOptions.map((item, index) => {
                                        return (
                                          <div
                                          className={`${
                                            props.actionsArray[1] === item.value && !hover
                                              ? "bg-[#1D1D1F]"
                                              : ""
                                          } flex mt-1 py-1  w-full text-left cursor-pointer hover:bg-[#1D1D1F]`}
                                            onClick={() => {
                                              props.actionsArray[1] =
                                                item.value;
                                              props.setActionsArray([
                                                ...props.actionsArray,
                                              ]);
                                              handleOpenLink();
                                            }}
                                          >
                                            <div className="pl-[5px] w-[30px] h-[20px]">
                                              {props.actionsArray[1] ===
                                                item.value && (
                                                <img
                                                  className="w-[100%] h-[100%]"
                                                  src={PopTick}
                                                  alt="P"
                                                />
                                              )}
                                            </div>
                                            <div className="text-[#ffffff] pl-[10px] text-[14px] ">
                                              {item.label}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                }
                                trigger="click"
                              >
                                <div
                                  className=" cursor-pointer"
                                  style={{
                                    textAlign: "center",
                                    height: "18px",
                                    marginTop: "15px"
                                  }}
                                >
                                  <div className={`flex mt-2`}>
                                    <div className="text-[#ffffff] text-[12px]">
                                      {props.actionsArray[1] === 0
                                        ? "Open link in same window"
                                        : "Open link in new window"}
                                    </div>
                                    <div className="mt-[5px] ml-4">
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="down"
                                        width="10px"
                                        height="10px"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </Popover>
                            </div>
                          </div>
                        )}
                        {props.action === 2 && (
                          <div className="">
                            <div className="text-left text-xs text-[#c0bdbd]">
                              This action will store the users inputs value.
                            </div>
                            {/* <div className="flex items-start  relative pt-4">
                              <div className="items-center rounded-sm  gap-2 flex justify-start mr-4">
                                <div className="w-[136px] text-left pt-1">
                                  Seek video to
                                </div>
                              </div>

                              <InputNumber
                                type="number"
                                min="0"
                                disabled={props.isModalOpen}
                                value={props.actionsArray[0]}
                                // onBlur = {()=>{
                                //   let arr  = [...props.actionsArray];
                                //   arr.pop();
                                //   arr.push(props.actionSeek)
                                //   props.setActionsArray(arr);
                                // }}
                                onClick={() => {
                                  // props.setFieldEnabled(true);
                                  props.fieldEnabled.current = true;
                                }}
                                onBlur={() => {
                                  // props.setFieldEnabled(false);
                                  props.fieldEnabled.current = false;
                                }}
                                onChange={(value) => {
                                  // props.setActionSeek(value);
                                  props.actionsArray[0] = value;
                                  props.setActionsArray([
                                    ...props.actionsArray,
                                  ]);
                                }}
                                className={`editor-inum w-[60px]`}
                              />
                              <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-5 right-4">
                                sec
                              </span>
                            </div> */}
                          </div>
                        )}
                        {props.action === 3 && (
                    <div className="text-left text-xs text-[#c0bdbd]">
                      <div>
                        This action will open phone application.
                      </div>
                      <InputNumber
                        value={props.actionsArray[0]}
                        controls = {false}
                        onClick={() => {
                          props.fieldEnabled.current = true;
                        }}
                        onBlur={() => {
                          props.fieldEnabled.current = false;
                        }}
                        onChange={(value) => {
                          props.actionsArray[0] = value;
                          props.setActionsArray([...props.actionsArray]);
                        }}
                        placeholder="Enter Mobile Number"
                        className="h-9 mt-4 w-full editor-inum "
                      />
                    </div>
                  )}
                        {/* {props.action === 3 && (
                          <div className="mt-5 ">
                            <div className="text-left text-sm text-[#c0bdbd]">
                              This action will store interactions & seek the
                              video to the timings mentioned below.
                            </div>
                            <div className="flex items-start  relative pt-4">
                              <div className="items-center rounded-sm  gap-2 flex justify-start mr-4">
                                <div className="w-[136px] text-left pt-1">
                                  Seek video to
                                </div>
                              </div>

                              <InputNumber
                                type="number"
                                min="0"
                                disabled={props.isModalOpen}
                                value={props.actionsArray[0]}
                                onBlur={() => {
                                  // props.setFieldEnabled(false);
                                  props.fieldEnabled.current = false;
                                }}
                                onClick={() => {
                                  // props.setFieldEnabled(true);
                                  props.fieldEnabled.current = true;
                                }}
                                onChange={(value) => {
                                  // props.setActionSeek(value);
                                  props.actionsArray[0] = value;
                                  props.setActionsArray([
                                    ...props.actionsArray,
                                  ]);
                                }}
                                className={`editor-inum w-[60px]`}
                              />
                              <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-5 right-4">
                                sec
                              </span>
                            </div>

                            <Popover
                              placement="left"
                              arrow={false}
                              open={openStore}
                              onOpenChange={handleOpenStore}
                              content={
                                <div
                                  className={`bg-[#1D1D1F] w-[150px] absolute ${
                                    props.actionsArray[1] === 0
                                      ? "top-[-20px]"
                                      : "top-[-50px]"
                                  } right-[-150px]`}
                                  style={{
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                  }}
                                >
                                  <div
                                    className=" overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2"
                                  >
                                    {StoreOptions.map((item, index) => {
                                      return (
                                        <div
                                          className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                                          onClick={() => {
                                            props.actionsArray[1] = item.value;
                                            props.setActionsArray([
                                              ...props.actionsArray,
                                            ]);
                                            handleOpenStore();
                                          }}
                                        >
                                          <div className="px-2 w-[30px] h-[20px]">
                                            {props.actionsArray[1] ===
                                              item.value && (
                                              <img
                                                className="w-[100%] h-[100%]"
                                                src={PopTick}
                                                alt="P"
                                              />
                                            )}
                                          </div>
                                          <div className="text-[#ffffff] text-[14px] ">
                                            {item.label}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              }
                              trigger="click"
                            >
                              <div
                                className="mx-4 cursor-pointer"
                                style={{
                                  textAlign: "center",
                                  height: "28px",
                                }}
                              >
                                <div className={`flex mt-5`}>
                                  <div className="ml-2 text-[14px]">
                                    {props.actionsArray[1] === 0
                                      ? "Current Screen"
                                      : "All Previous"}
                                  </div>
                                  <div className="mt-[7px] ml-4">
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="down"
                                      width="10px"
                                      height="10px"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        )}
                        {props.action === 4 && (
                          <div className="mt-5 action-select">
                            <div className="text-left text-sm text-[#c0bdbd]">
                              This action will store interactions & will show
                              the following response.
                            </div>
                            <div className="flex items-start  relative pt-4">
                              <div className="items-center rounded-sm  gap-2 flex justify-start mr-4">
                                <div className="w-[136px] text-left ">
                                  Seek after response message
                                </div>
                              </div>

                              <InputNumber
                                type="number"
                                defaultValue={1}
                                disabled={props.isModalOpen}
                                value={props.actionsArray[0]}
                                onBlur={() => {
                                  if (
                                    props.actionsArray[0] === null ||
                                    props.actionsArray[0] <= 0
                                  ) {
                                    props.actionsArray[0] = 1;
                                    props.setActionsArray([
                                      ...props.actionsArray,
                                    ]);
                                  }
                                  // props.setFieldEnabled(false);
                                  props.fieldEnabled.current = false;
                                }}
                                onClick={() => {
                                  // props.setFieldEnabled(true);
                                  props.fieldEnabled.current = true;
                                }}
                                onChange={(value) => {
                                  // props.setActionSeek(value);
                                  props.actionsArray[0] = value;
                                  props.setActionsArray([
                                    ...props.actionsArray,
                                  ]);
                                }}
                                className={`editor-inum w-[60px]   mt-1`}
                              />
                              <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-5 right-4">
                                sec
                              </span>
                            </div>
                            <Input
                              // value={props.responseMessage}
                              value={props.actionsArray[2]}
                              disabled={props.isModalOpen}
                              onBlur={() => {
                                // props.setFieldEnabled(false);
                                props.fieldEnabled.current = false;
                              }}
                              onClick={() => {
                                // props.setFieldEnabled(true);
                                props.fieldEnabled.current = true;
                              }}
                              onChange={(e) => {
                                // props.setResponseMessage(e.target.value);
                                props.actionsArray[2] = e.target.value;
                                props.setActionsArray([...props.actionsArray]);
                              }}
                              placeholder="Response Message"
                              className="h-9 mt-4 w-full  editor-input "
                            />
                            <Popover
                              placement="left"
                              arrow={false}
                              open={openStore}
                              onOpenChange={handleOpenStore}
                              content={
                                <div
                                  className={`bg-[#1D1D1F] w-[150px] absolute ${
                                    props.actionsArray[1] === 0
                                      ? "top-[-20px]"
                                      : "top-[-50px]"
                                  } right-[-150px]`}
                                  style={{
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                  }}
                                >
                                  <div
                                    className=" overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2"
                                  >
                                    {StoreOptions.map((item, index) => {
                                      return (
                                        <div
                                          className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                                          onClick={() => {
                                            props.actionsArray[1] = item.value;
                                            props.setActionsArray([
                                              ...props.actionsArray,
                                            ]);
                                            handleOpenStore();
                                          }}
                                        >
                                          <div className="px-2 w-[30px] h-[20px]">
                                            {props.actionsArray[1] ===
                                              item.value && (
                                              <img
                                                className="w-[100%] h-[100%]"
                                                src={PopTick}
                                                alt="P"
                                              />
                                            )}
                                          </div>
                                          <div className="text-[#ffffff] text-[14px] ">
                                            {item.label}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              }
                              trigger="click"
                            >
                              <div
                                className="mx-4 cursor-pointer"
                                style={{
                                  textAlign: "center",
                                  height: "28px",
                                }}
                              >
                                <div className={`flex mt-5`}>
                                  <div className="ml-2 text-[14px]">
                                    {props.actionsArray[1] === 0
                                      ? "Current Screen"
                                      : "All Previous"}
                                  </div>
                                  <div className="mt-[7px] ml-4">
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="down"
                                      width="10px"
                                      height="10px"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(props.type === "checkbox" || props.type === "radio") && (
                <>
                  <div className="flex flex-col mt-4">
                    <label className="text-left font-normal text-xs items-start mb-2">
                      {props.type === "radio"
                        ? "Radio Value"
                        : "CheckBox Value"}
                    </label>
                    <Input
                      value={props.eleValue}
                      disabled={props.isModalOpen}
                      onClick={() => {
                        // props.fieldEnabled.current = true;
                        props.fieldEnabled.current = true;
                      }}
                      onChange={(e) => {
                        props.setEleValue(e.target.value);
                      }}
                      onBlur={() => {
                        // props.fieldEnabled.current = false;
                        props.fieldEnabled.current = false;
                      }}
                      placeholder={
                        props.type === "radio"
                          ? "Enter Radio Value"
                          : "Enter CheckBox Value"
                      }
                      className="h-9 w-full  editor-input"
                    />
                  </div>
                  <div className="flex mt-4 editor-switch">
                    <div className="text-left font-normal text-xs items-start mr-6">
                      Default Checked
                    </div>
                    <Switch
                      size="small"
                      disabled={!props.isRight}
                      checked={props.defaultChecked}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => {
                        props.setDefaultChecked(checked);
                        let arr = props.canvaContent.map((item, index) => {
                          if (item.type === "radio") {
                            if (
                              item.id !== props.itemSelected &&
                              item.name === props.name
                            ) {
                              item.defaultchecked = false;
                            }
                          }
                          return item;
                        });
                        props.handleContent([...arr]);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {(props.type === "textarea" ||
              props.subtype === "text" ||
              props.subtype === "number" ||
              props.subtype === "tel") && (
              <div className="items-start  relative px-4 pt-4">
                <div className="items-center    rounded-sm   gap-2 flex justify-start  mb-2 editor-switch">
                  <Switch
                    size="small"
                    disabled={!props.isRight}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={props.cltoggle}
                    onChange={(checked) => props.setCltoggle(checked)}
                  />
                  <div className="text-sm">Character Limit</div>
                </div>

                <Input
                  type="number"
                  controls={false}
                  disabled={!props.cltoggle && props.isModalOpen}
                  value={props.characterLimit}
                  onClick={() => {
                    // props.fieldEnabled.current = true;
                    props.fieldEnabled.current = true;
                  }}
                  onChange={(e) => {
                    props.setCharacterLimit(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (props.characterLimit === "") {
                      props.setCharacterLimit(10000);
                    }
                    // props.fieldEnabled.current = false;
                    props.fieldEnabled.current = false;
                  }}
                  className={`h-9 ${
                    !props.characterLimit ? "border-none !bg-gray-500" : ""
                  }  mt-1 w-full  editor-input`}
                />
                <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-14 right-6">
                  characters
                </span>
              </div>
            )}

            {(props.type === "dropdown" ||
              props.type === "radio" ||
              props.type === "checkbox" ||
              props.type === "text") && (
              <div className="items-start px-4 mb-3 mt-4">
                <div className="items-center rounded-sm gap-2 flex justify-start mb-2 editor-switch">
                  <Switch
                    size="small"
                    disabled={!props.isRight}
                    checked={props.emtoggle}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      if (!checked) {
                        props.setErrorMessage("");
                      }
                      props.setEmtoggle(checked);
                    }}
                  />
                  <div className="text-sm">Input mandatory</div>
                </div>
                <Input
                  placeholder="Set error message "
                  disabled={!props.emtoggle && props.isModalOpen}
                  value={props.errorMessage}
                  onClick={() => {
                    props.fieldEnabled.current = true;
                  }}
                  onChange={(e) => {
                    props.setErrorMessage(e.target.value);
                  }}
                  onBlur={() => {
                    props.fieldEnabled.current = false;
                  }}
                  className="h-10  mt-1 w-full  editor-input "
                />
              </div>
            )}
          </div>
          {props.type === "dropdown" && (
            <div className="text-center">
              <MultiInput
                optionsArray={props.optionsArray}
                setOptionsArray={props.setOptionsArray}
                setShowDialog={props.setShowDialog}
                fieldEnabled = {props.fieldEnabled}
                PopButton={"Add Options"}
                setButton={"Set Options"}
                header={"Add Options"}
                desc={
                  "Tell us your list of options and its value. For eg: Name = India, Value = Asia"
                }
                col1={"Name"}
                col2={"Value"}
              />
            </div>
          )}
          <Divider className="!m-0 !mt-4" />
        </>
      )}
      <DetailMenu
        itemSelected={props.itemSelected}
        canvaContent={props.canvaContent}
        handleContent={props.handleContent}
        elementProperties={props.elementProperties}
        idx={props.idx}
        setIdx={props.setIdx}
        bgColor={props.bgColor}
        setbgColor={props.setbgColor}
        textColor={props.textColor}
        setTextColor={props.setTextColor}
        borderColor={props.borderColor}
        setBorderColor={props.setBorderColor}
        xcord={props.xcord}
        setXcord={props.setXcord}
        ycord={props.ycord}
        setYcord={props.setYcord}
        height={props.height}
        setHeight={props.setHeight}
        width={props.width}
        setWidth={props.setWidth}
        rotate={props.rotate}
        setRotate={props.setRotate}
        flip={props.flip}
        setFlip={props.setFlip}
        drawAreaWidth={props.drawAreaWidth}
        drawAreaHeight={props.drawAreaHeight}
        border={props.border}
        setBorder={props.setBorder}
        borderRadius={props.borderRadius}
        setBorderRadius={props.setBorderRadius}
        tlborderRadius={props.tlborderRadius}
        setTLBorderRadius={props.setTLBorderRadius}
        trborderRadius={props.trborderRadius}
        setTRBorderRadius={props.setTRBorderRadius}
        blborderRadius={props.blborderRadius}
        setBLBorderRadius={props.setBLBorderRadius}
        brborderRadius={props.brborderRadius}
        setBRBorderRadius={props.setBRBorderRadius}
        resizeRatio={props.resizeRatio}
        setResizeRatio={props.setResizeRatio}
        link={props.link}
        setLink={props.setLink}
        type={props.type}
        fieldEnabled={props.fieldEnabled}
        // setFieldEnabled={props.setFieldEnabled}
        isModalOpen={props.isModalOpen}
        setIsModalOpen={props.setIsModalOpen}
        isRight={props.isRight}
      />
    </div>
  );
};

export default ComponentDetail;
