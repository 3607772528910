import React from 'react'
import PlayReportHeader from './common/PlayReportHeader'
import PlayReportTable from './common/PlayReportTable'
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { getPlayReportData } from '../../api/api_FetchData';
import { Spin } from 'antd';
import useCurrentUrl from '../../common/functions/useCurrentUrl';
import { useNavigate } from 'react-router-dom';
import useSessionChecker from '../../common/functions/useSessionChecker';

const VideoPlayReport = ({sessionuserid}) => {
  sessionuserid = localStorage.getItem("sessionUserId");
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();
  const [isSpinning, setIsSpinning] = useState(false);
  const [playReportData, setPlayReportData] = useState(null);
  // const [pRTableData, setPRTableData] = useState(null);
  const [searchPRTableData, setSearchPRTableData] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);


  const handleSearchResult = (data) => {
    
    const pData = JSON.parse(data);
    console.log(pData, "PPPARSED DATA");
    setSearchPRTableData(pData[0].REPORTS);
    
  };

  const {refetch } = useQuery(
    ["dashboard", sessionuserid],
    () => 
    // getPlayReportData(sessionuserid, null, "/reports", window.location.pathname.substring(1) === "user/play-report" ? "PLAYREPORT" : "HEATMREPORT"),
    {
      // Extract the report type from the URL
      const path = window.location.pathname.substring(1);
      let reportType = "PLAYREPORT";

      if (path === "user/data-report") {
        reportType = "DATASTOREREPORT";
      } else if (path === "user/heatmap-report") {
        reportType = "HEATMREPORT";
      }

      return getPlayReportData(sessionuserid, null, "/reports", reportType);
    },
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        handleUpdateDashboard();

        const dashData = data.data;
        setPlayReportData(dashData);
        console.log(dashData, "DASH DATA");

        // setPRTableData(dashData[0].REPORTS);
        // debugger
      },
    }
  );

  // console.log(data, "PR DATA")
  // console.log(playReportData, "P DATA DATA")

  const getPageTitle = () => {
    const path = window.location.pathname.substring(1);
      let title = "Play Report";

      if (path === "user/data-report") {
        title = "Data Report";
      } else if (path === "user/heatmap-report") {
        title = "Heatmap Report";
      }

      setPageTitle(title);
  }

  useEffect(() => {
    refetch();
    handleUpdateDashboard();
    getPageTitle();
  }, [refetch]);

  const handleUpdateDashboard = () => {
    setIsSpinning(true);

    setTimeout(() => {
      setIsSpinning(false);
    }, 1200);
  };

  // if (isLoading) {
  //   return (
  //     <div className="m-auto">
  //       <span>
  //         <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
  //           <Spin tip="Loading..." />
  //         </div>
  //       </span>
  //     </div>
  //   );
  // }

  // if (isError) {
  //   return <div>Error loading data.</div>;
  // }


  return (
    <>
        <PlayReportHeader 
          playReportData={playReportData}
          onSearchResult={handleSearchResult}
          updateDashboard={handleUpdateDashboard}
          pageTitle={pageTitle}
        />
        <PlayReportTable
          // pRTableData={searchPRTableData === null && pRTableData ? pRTableData : searchPRTableData}
          pageTitle={pageTitle}
          pRTableData={searchPRTableData}
          // searchPRTableData={searchPRTableData}
        />
        {isSpinning && (
        <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
          <Spin tip="Uploading..." />
        </div>
      )}
    </>
  )
}

export default VideoPlayReport