
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
    // navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
  }, [navigate]);
  return null;
}
export default Home