import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { rightDrawerType } from "../data";
import html2canvas from "html2canvas";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
// import {handleUpload} from "../../utils/uploadAsset";
import {
  Divider,
  Input,
  InputNumber,
  Popover,
  Switch,
  Button,
  Checkbox,
  Radio,
  Tooltip,
  Modal,
  theme,
  ColorPicker,
} from "antd";
import { divObj } from "../../utils/helper";
import ComponentDetail from "./ComponentDetail";
import DesignDetail from "./DesignDetail";
import Action from "./Action";
import "./Action.css";
import Animation from "./Animation";
import time from "../../assets/Speed.svg";
import cross from "../../assets/Cross.svg";
import circlecross from "../../assets/CircleCancel.svg";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import layerOn from "../../assets/screenlayer_on.svg";
import layerOff from "../../assets/screenlayer_off.svg";
import { ReactComponent as Text } from "../../assets/text.svg";
import { ReactComponent as Video } from "../../assets/video.svg";
import { ReactComponent as Hotspot } from "../../assets/hotspot.svg";
import { ReactComponent as ButtonImg } from "../../assets/button.svg";
import { ReactComponent as Text1 } from "../../assets/text1.svg";
import { ReactComponent as Music } from "../../assets/Music.svg";
import { ReactComponent as OptionsList } from "../../assets/optionsList.svg";
import { ReactComponent as Lock } from "../../assets/closelock.svg";
import { ReactComponent as Unlock } from "../../assets/openlock.svg";
import { ReactComponent as VisON } from "../../assets/visOn.svg";
import { ReactComponent as VisOFF } from "../../assets/visOff.svg";
import { ReactComponent as Dots } from "../../assets/verticalDots.svg";
import { ReactComponent as Dash } from "../../assets/dash.svg";
import MultiInput from "../commonComponents/MultiInput";
import SimpleButton from "../../../saasWebsite/common/components/SimpleButton";
import { toast } from "react-toastify";
import TextArea from "antd/es/input/TextArea";
import idCreate from "../../utils/uuid";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
// import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScroll from "react-infinite-scroll-component";
import Collapse from "rc-collapse";
import { Panel } from "rc-dialog";
const RightSidebarDashboard = ({
  itemSelected,
  setItem,
  canvaContent,
  handleContent,
  elementProperties,
  deleted,
  setDeleted,
  handleDeleted,
  layerButton,
  setLayerButton,
  settingButton,
  setSettingButton,
  musicButton,
  timeSetting,
  setTimeSetting,
  undoRedoAction,
  isDivDropped,
  setIsDivDropped,
  templateDetails,
  setTemplateDetails,
  selectedSidebarItem,
  setSelectedSidebarItem,
  displayTextConfig,
  timeLine,
  setTimeLine,
  autoPlay,
  setAutoPlay,
  storeData,
  setStoreData,
  variableData,
  setVariableData,
  misData,
  setMisData,
  lastAnimation,
  setLastAnimation,
  seekLayer,
  setSeekLayer,
  setIndex,
  setRightClicked,
  templateInfo,
  pathName,
  totalTime,
  audioData,
  setAudioData,
  musicLoop,
  setMusicLoop,
  playbgmusic,
  setPlaybgmusic,
  musicData,
  setMusicData,
  globalmusicData,
  setGlobalMusicData,
  groupSelection,
  setGroupSelection,
  setAlign,
  alignElement,
  setAlignElement,
  templateName,
  setTemplateName,
  templateDescription,
  setTemplateDescription,
  imageBase64,
  setImageBase64,
  currentTime,
  resizeRatio,
  setResizeRatio,
  link,
  setLink,
  fieldEnabled,
  // setFieldEnabled,
  allLocks,
  setAllLocks,
  currLocks,
  setCurrLocks,
  payload,
  setPayload,
  showDialog,
  setShowDialog,
  isModalOpen,
  setIsModalOpen,
  isRight,
  setIsRight,
  svgCode,
  setSVGCode,
  sizing,
  vis,
  setSizing,
  dragging,
  setDragging,
  horizontalAlign,
  verticalAlign,
  setHorizontalAlign,
  setVerticalAlign,
  brandColor,
  setBrandColor,
  ogState,
  dispatch,
  imageData,
  setImageData,
  brandImage,
  setBrandImage,
  mapDialog,
  setMapDialog,
  customerOwner,
  brandSelected,
  brandName,
}) => {
  let [idx, setIdx] = useState(null);
  let [bgColor, setbgColor] = useState("#000000");
  let [tbgColor, setTbgColor] = useState("#000000");
  let [textColor, setTextColor] = useState("#000000");
  let [ttextColor, setTTextColor] = useState("#000000");
  let [borderColor, setBorderColor] = useState("transparent");
  let [tborderColor, setTBorderColor] = useState("transparent");
  let [xcord, setXcord] = useState("");
  let [ycord, setYcord] = useState("");
  let [height, setHeight] = useState("");
  let [width, setWidth] = useState("");
  let [rotate, setRotate] = useState("");
  let [flip, setFlip] = useState("N");
  let [opacity, setOpacity] = useState("");
  let [fontSize, setFontSize] = useState("");
  let [fontStyle, setFontStyle] = useState("");
  let [fontWeight, setFontWeight] = useState("");
  let [textAlign, setTextAlign] = useState("");
  let [textDecoration, setTextDecoration] = useState("");
  let [fontFamily, setfontFamily] = useState("");
  let [letterSpacing, setLetterSpacing] = useState("");
  let [lineHeight, setLineHeight] = useState("");
  let [border, setBorder] = useState("");
  let [borderRadius, setBorderRadius] = useState("");
  let [tlborderRadius, setTLBorderRadius] = useState(0);
  let [trborderRadius, setTRBorderRadius] = useState(0);
  let [blborderRadius, setBLBorderRadius] = useState(0);
  let [brborderRadius, setBRBorderRadius] = useState(0);
  let [layerName, setLayername] = useState("");
  let [placeHolder, setPlaceholder] = useState("");
  let [cltoggle, setCltoggle] = useState(false);
  let [emtoggle, setEmtoggle] = useState(false);
  let [characterLimit, setCharacterLimit] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [name, setName] = useState("");
  let [eleValue, setEleValue] = useState("");
  let [type, setType] = useState("");
  let [subType, setSubType] = useState("");
  let [startTime, setStartTime] = useState("");
  let [stopTime, setStopTime] = useState("");
  let [pauseToggle, setPauseToggle] = useState("");
  let [resumeToggle, setResumeToggle] = useState("");
  let [pauseTime, setPauseTime] = useState("");
  let [resumeIn, setResumeIn] = useState("");
  let [resumeTo, setResumeTo] = useState("");
  let [seekTo, setSeekTo] = useState("");
  let [optionsArray, setOptionsArray] = useState([""]);
  let [animationEntrySpeed, setAnimationEntrySpeed] = useState("");
  let [animationExitSpeed, setAnimationExitSpeed] = useState("");
  let [animationType, setAnimationType] = useState("");
  let [exitAnimation, setExitAnimation] = useState("");
  let [entryAnimation, setEntryAnimation] = useState("");
  let [timming, setTimming] = useState("");
  let [icon, setIcon] = useState("");
  let [zIndex, setZIndex] = useState(0);
  // let [payload, setPayload] = useState("");
  let [defaultChecked, setDefaultChecked] = useState(true);
  const drawArea = document.querySelector(".drawArea");
  const [drawAreaHeight, setDrawAreaHeight] = useState("");
  const [drawAreaWidth, setDrawAreaWidth] = useState("");
  const [isItemSelected, setIsItemSelected] = useState(false);
  // const [selectedSidebarItem, setSelectedSidebarItem] = useState(0);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [layerContent, setLayerContent] = useState(canvaContent);
  const [open, setOpen] = useState(false);
  const [openDD, setOpenDD] = useState(false);
  const [tempMis, setTempMis] = useState([]);
  const [action, setAction] = useState(0);
  const [descDisable, setDescDisable] = useState(true);
  const [nameDisable, setNameDisable] = useState(true);
  // const [actionSeek,setActionSeek] = useState("");
  // const [responseMessage, setResponseMessage] = useState("");
  // const [storeValues, setStoreValues] = useState("");
  // const [linkTo,setLinkTo] = useState("");
  // const [windowAction, setWindowAction] = useState("");
  const [actionsArray, setActionsArray] = useState([]);
  const audioInputRef = useRef(null);
  const [currLayer, setCurrLayer] = useState(false);
  let thumbnailRef = useRef(null);
  const [thumbnailType, setThumbnailType] = useState(0);
  const [id, setId] = useState(Date.now());
  const [modalOpen, setModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreTime, setHasMoreTime] = useState(true);
  const [content, setContent] = useState([]);
  const [inc, setInc] = useState(20);
  const [incTime, setIncTime] = useState(20);
  let [layerDisabled, setLayerDisabled] = useState(-1);
  let [infokey, setInfokey] = useState(1);
  let [titleDisable, setTitleDisable] = useState(true);
  let [ogDescDisable, setOgDescDisable] = useState(true);
  let titleRef = useRef(true);
  let ogDescRef = useRef(true);
  let nameRef = useRef(true);
  let tempDescRef = useRef(true);
  let backImageRef = useRef(null);
  let ogImageRef = useRef(null);
  const [activeKey1, setActiveKey1] = useState([]); // Default active key
  const [activeKey2, setActiveKey2] = useState([]); // Default active key

  const onChange1 = (key) => {
    console.log(key);
    setActiveKey1(key);
  };

  const onChange2 = (key) => {
    console.log(key);
    setActiveKey2(key);
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const handleOpenDD = () => {
    setOpenDD(null);
  };

  const mapBrandOpen = () => {
    setMapDialog(true);
  };

  const error = (id, title, content) => {
    Modal.error({
      title: title,
      content: content,
      onOk: () => {
        document.getElementById(id).focus();
        setIsModalOpen(false);
        setModalOpen(false);
        Modal.destroyAll();
      },
    });
  };

  const fetchMoreData = () => {
    if (content.length >= layerContent.length) {
      setHasMore(false);
      return;
    }
    setContent([...Array.from(layerContent.slice(0, inc + 20))]);
    setInc((prev) => prev + 20);
  };

  const fetchMoreTimeData = () => {
    if (timming.length >= canvaContent.length) {
      setHasMoreTime(false);
      return;
    }
    setTimming([...Array.from([...canvaContent].slice(0, incTime + 20))]);
    setIncTime((prev) => prev + 20);
  };

  // FETCHING API_BASE_URL FROM INDEX.HTML
  const API_BASE_URL = window.baseurl;

  function musicConfig(type){
    
  }

  const music = musicData.filter(item => item.checked).map((item, index) => {
    return {
      key: index + "",
      label: (
        <div className="flex justify-between cursor-pointer p-4 pb-3">
          <div className="flex">
            <div className="mr-8">
              <Music height="22px" width="22px" />
            </div>
            <div className="">{item.displayName}</div>
          </div>
          <div className="flex align-middle">
            {activeKey1.includes(index + "") ? (
              <MinusOutlined />
            ) : (
              <PlusOutlined />
            )}
          </div>
        </div>
      ),
      children: (
        <div className="mt-1 bg-[#282828]">
          <div className="px-4 py-1 text-left font-normal text-sm mt-[2px]">
            Set Play Time
          </div>
          <div className="px-4 py-1 text-left font-normal text-xs mt-[2px]">
            Set time to display the component
          </div>
          <div className="px-4 my-1 mr-1 mb-2  flex items-center ">
            <span className="text-sm mr-1 pt-[1.5px]">Start</span>
            <div className="">
            <InputNumber
              className="editor-inum !bg-[#282828]  !px-0  w-16 "
              // className="w-12 mb-2"
              // className="editor-input !px-0  w-16 mb-2"
              type="number"
              defaultValue={0}
              min={0}
              value={item.starttime}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d{1,3}).*$/, "$1$2.$3")
              }
              onStep={() => {
                fieldEnabled.current = true;
              }}
              onClick={() => {
                fieldEnabled.current = true;
              }}
              onBlur={() => {
                fieldEnabled.current = false;
              }}
              onChange={(value) => {
                let index = musicData.findIndex(
                  (v) => v.displayName === item.displayName
                );
                let arr = [...musicData];
                arr[index].starttime = value;
                setMusicData([...arr]);
                setShowDialog(true);
              }}
            />
            </div>
            {/* <div className="pt-1 px-2">/</div> */}
            <span className="text-sm ml-4 mr-1 pt-[1.5px]">Stop</span>
            <div className="">
            <InputNumber
              className="editor-inum !bg-[#282828]   !px-0  w-16 "
              //  className="w-12 mb-2"
              // className="editor-input !px-0  w-16 mb-2"
              type="number"
              defaultValue={1}
              value={item.stoptime}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d{1,3}).*$/, "$1$2.$3")
              }
              onClick={() => {
                fieldEnabled.current = true;
              }}
              onBlur={() => {
                fieldEnabled.current = false;
                // if (
                //   item.stoptime === null ||
                //   item.stoptime < 0 ||
                //   item.stoptime < item.starttime
                // ) {
                //   canvaContent[index].stoptime = item.starttime + 1;
                //   handleContent([...canvaContent]);
                // }
              }}
              onStep={() => {
                fieldEnabled.current = true;
              }}
              onChange={(value) => {
                let index = musicData.findIndex(
                  (v) => v.displayName === item.displayName
                );
                let arr = [...musicData];
                arr[index].stoptime = value;
                setMusicData([...arr]);
                setShowDialog(true);
              }}
            />
            </div>
          </div>
          <Divider className="!border-[#3C3C3C] !m-0" />
          <div
            className={`px-4 pb-2 flex flex-row-reverse justify-end mt-2 editor-switch`}
          >
            <div className={`text-left font-normal text-sm items-start  ml-6`}>
              Play audio on loop
            </div>
            <Switch
              size="small"
              disabled={!isRight}
              checked={
                item.defaultChecked === undefined ||
                item.defaultChecked === true
                  ? true
                  : false
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => {
                let arr = [...musicData];
                arr[index].defaultChecked = checked;
                setMusicData([...arr]);
              }}
            />
          </div>
        </div>
      ),
    }; 
  });
  const globalmusic = globalmusicData.filter(item => item.checked).map((item, index) => {
    return {
      key: index + "",
      label: (
        <div className="flex justify-between cursor-pointer p-4 pb-3">
          <div className="flex">
            <div className="mr-8">
              <Music height="22px" width="22px" />
            </div>
            <div className="">{item.name}</div>
          </div>
          <div className="flex align-middle">
            {activeKey2.includes(index + "") ? (
              <MinusOutlined />
            ) : (
              <PlusOutlined />
            )}
          </div>
        </div>
      ),
      children: (
        <div className="mt-1 bg-[#282828]">
          <div className="px-4 py-1 marker:text-left font-normal text-sm mt-[2px]">
            Set Play Time
          </div>
          <div className="px-4 py-1 text-left font-normal text-xs mt-[2px]">
            Set time to display the component
          </div>
          <div className="px-4 my-1 mr-1 mb-2  flex items-center ">
            <span className="text-sm mr-1 pt-[1.5px]">Start</span>
            <div className="">
            <InputNumber
              className="editor-inum !bg-[#282828]   !px-0  w-16 "
              // className="w-12 mb-2"
              // className="editor-input !px-0  w-16 mb-2"
              type="number"
              defaultValue={0}
              min={0}
              value={item.starttime}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d{1,3}).*$/, "$1$2.$3")
              }
              onStep={() => {
                fieldEnabled.current = true;
              }}
              onClick={() => {
                fieldEnabled.current = true;
              }}
              onBlur={() => {
                fieldEnabled.current = false;
              }}
              onChange={(value) => {
                let index = globalmusicData.findIndex(
                  (v) => v.name === item.name
                );
                let arr = [...globalmusicData];
                arr[index].starttime = value;
                setGlobalMusicData([...arr]);
                setShowDialog(true);
              }}
            />
            </div>
            {/* <div className="pt-1 px-2">/</div> */}
            <span className="text-sm ml-4 mr-1 pt-[1.5px]">Stop</span>
            <div className="">
            <InputNumber
              className="editor-inum !bg-[#282828]   !px-0  w-16 "
              //  className="w-12 mb-2"
              // className="editor-input !px-0  w-16 mb-2"
              type="number"
              defaultValue={1}
              value={item.stoptime}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d{1,3}).*$/, "$1$2.$3")
              }
              onClick={() => {
                fieldEnabled.current = true;
              }}
              onBlur={() => {
                fieldEnabled.current = false;
                // if (
                //   item.stoptime === null ||
                //   item.stoptime < 0 ||
                //   item.stoptime < item.starttime
                // ) {
                //   canvaContent[index].stoptime = item.starttime + 1;
                //   handleContent([...canvaContent]);
                // }
              }}
              onStep={() => {
                fieldEnabled.current = true;
              }}
              onChange={(value) => {
                let index = globalmusicData.findIndex(
                  (v) => v.name === item.name
                );
                let arr = [...globalmusicData];
                arr[index].stoptime = value;
                setGlobalMusicData([...arr]);
                setShowDialog(true);
              }}
            />
            </div>
          </div>
          <Divider className="!border-[#3C3C3C] !m-0" />
          <div
            className={`px-4 pb-2 flex flex-row-reverse justify-end mt-2 editor-switch`}
          >
            <div className={`text-left font-normal text-sm items-start  ml-6`}>
              Play audio on loop
            </div>
            <Switch
              size="small"
              disabled={!isRight}
              checked={
                item.defaultChecked === undefined ||
                item.defaultChecked === true
                  ? true
                  : false
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => {
                let arr = [...globalmusicData];
                arr[index].defaultChecked = checked;
                setGlobalMusicData([...arr]);
              }}
            />
          </div>
        </div>
      ),
    };
  });

  useEffect(() => {
    // Attach event listener for keydown event
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  function handleKeyPress(e) {
    function handleMotion(val, e) {
      if (e.keyCode == "38") {
        //up
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setYcord(ycord - val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].top = canvaContent[idx].top - val;
          }
          canvaContent[canvaContent.length - 1].top =
            canvaContent[canvaContent.length - 1].top - val;
          handleContent(canvaContent);
        }
      } else if (e.keyCode == "40") {
        //down
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setYcord(ycord + val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].top = canvaContent[idx].top + val;
          }
          canvaContent[canvaContent.length - 1].top += val;
          handleContent(canvaContent);
        }
      } else if (e.keyCode == "37") {
        //left
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setXcord(xcord - val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].left = canvaContent[idx].left - val;
          }
          canvaContent[canvaContent.length - 1].left =
            canvaContent[canvaContent.length - 1].left - val;
          handleContent(canvaContent);
        }
      } else if (e.keyCode == "39") {
        //right
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setXcord(xcord + val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].left = canvaContent[idx].left + val;
          }
          canvaContent[canvaContent.length - 1].left =
            canvaContent[canvaContent.length - 1].left + val;
          handleContent(canvaContent);
        }
      }
    }

    if (!fieldEnabled.current) {
      if (e.shiftKey) {
        handleMotion(10, e);
      } else {
        handleMotion(1, e);
      }
    }
  }

  useEffect(() => {
    if (!descDisable) {
      document.getElementById("td").focus();
      tempDescRef.current = true;
    }
    if (!nameDisable) {
      document.getElementById("tn").focus();
      nameRef.current = true;
    }
    if (!titleDisable) {
      document.getElementById("ot").focus();
      titleRef.current = true;
    }
    if (!ogDescDisable) {
      document.getElementById("od").focus();
      ogDescRef.current = true;
    }
    if (layerDisabled !== -1) {
      document.getElementById(layerDisabled + "L").focus();
    }
  }, [descDisable, nameDisable, titleDisable, ogDescDisable, layerDisabled]);

  useEffect(() => {
    if (horizontalAlign !== null) {
      setXcord(horizontalAlign);
      setHorizontalAlign(null);
    }
  }, [horizontalAlign]);
  useEffect(() => {
    if (verticalAlign !== null) {
      setYcord(verticalAlign);
      setVerticalAlign(null);
    }
  }, [verticalAlign]);

  useEffect(() => {
    if (imageBase64 !== null && imageBase64 !== "") {
      const uploadFileThumbnail = async () => {
        try {
          // debugger
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              action: pathName === "createTemplate" ? "TEMP" : "LIVE",
              templateid: templateInfo[0]?.TEMPLATEID,
              base64data: imageBase64,
              uploadtype: "thumbnail",
            }),
          };

          const response = await fetch(
            `${API_BASE_URL}/base64file`,
            requestOptions
          );

          console.log(response, "RESPONSE THUMB DATA API");
          const data = await response.json();
          console.log(data, "UPDATED THUMBNAIL DATA");
          if (data.STATUS === "1000") {
            toast.success(data.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
            setImageBase64(null);
            setId(idCreate());
            return data;
          } else {
            toast.error(data.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
            setImageBase64(null);
          }
        } catch (error) {
          toast.error(error, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
          setImageBase64(null);
        }
      };
      uploadFileThumbnail();
    }
  }, [imageBase64, pathName, templateInfo]);

  useEffect(() => {
    let arr = [...canvaContent];
    setLayerContent([...arr].sort((a, b) => b.zindex - a.zindex));
    if (content.length < canvaContent.length) {
      setHasMore(true);
    }
    setContent(
      Array.from([...arr].sort((a, b) => b.zindex - a.zindex).slice(0, inc))
    );
  }, [canvaContent]);

  useEffect(() => {
    let arr = [...canvaContent];
    if (timming.length < canvaContent.length) {
      setHasMoreTime(true);
    }
    setTimming(arr.slice(0, incTime));
  }, [canvaContent]);

  useEffect(() => {
    if (elementProperties.length === 0) {
      return;
    }
    setXcord(elementProperties.X);
    setYcord(elementProperties.Y);
    setHeight(elementProperties.height);
    setWidth(elementProperties.width);
    setRotate(elementProperties.rotate);
    setFlip(elementProperties.flip);
    setBorder(elementProperties.border);
    setBorderRadius(elementProperties.borderradius);
    setType(elementProperties.type);
    setSubType(elementProperties.subtype);
    setLayername(elementProperties.layername);
    setPlaceholder(elementProperties.placeholder);
    setCltoggle(elementProperties.cltoggle);
    setEmtoggle(elementProperties.emtoggle);
    setCharacterLimit(elementProperties.characterlimit);
    setErrorMessage(elementProperties.errormessage);
    setName(elementProperties.name);
  }, [elementProperties]);

  useEffect(
    function () {
      setLastAnimation("");
      if (itemSelected === null) {
        if (deleted) {
          setStates(
            "#000000",
            "",
            "",
            "",
            "",
            0,
            "#000000",
            "#ffffff",
            "N",
            0,
            "none",
            18,
            "Times New Roman",
            "none",
            "normal",
            "normal",
            "0",
            "1",
            "",
            "",
            "",
            "",
            false,
            false,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            false,
            false,
            "",
            "",
            "",
            "",
            [],
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            false,
            [],
            0,
            0,
            0,
            0,
            ""
          );
          handleDeleted();
          return;
        } else {
          return;
        }
      }
      let resProp =
        canvaContent.length === 0
          ? [{}]
          : canvaContent.find((obj, idx) => {
              if (obj.id === itemSelected) {
                setIdx(idx);
                return true;
              } else {
                return false;
              }
            });
      setTbgColor(resProp.backgroundColor);
      setTTextColor(resProp.textColor);
      setTBorderColor(resProp.borderColor);
      setStates(
        resProp.backgroundColor,
        resProp.height,
        resProp.width,
        resProp.left,
        resProp.top,
        resProp.rotate,
        resProp.textColor,
        resProp.borderColor,
        resProp.flip,
        resProp.opacity,
        resProp.textalign,
        resProp.fontsize,
        resProp.fontfamily,
        resProp.textdecoration,
        resProp.fontstyle,
        resProp.fontweight,
        resProp.letterspacing,
        resProp.lineheight,
        resProp.border,
        resProp.borderradius,
        resProp.layername,
        resProp.placeholder,
        resProp.cltoggle,
        resProp.emtoggle,
        resProp.characterlimit,
        resProp.errormessage,
        resProp.name,
        resProp.type,
        resProp.subtype,
        resProp.starttime,
        resProp.stoptime,
        resProp.pausetoggle,
        resProp.resumetoggle,
        resProp.pausetime,
        resProp.resumein,
        resProp.resumeto,
        resProp.seekto,
        resProp.optionsarray,
        resProp.animationentryspeed,
        resProp.animationexitspeed,
        resProp.animationtype,
        resProp.exitanimation,
        resProp.entryanimation,
        resProp.icon,
        resProp.value,
        resProp.zindex,
        resProp.payload,
        resProp.action,
        resProp.defaultchecked,
        resProp.actionsarray,
        resProp.tlborderradius,
        resProp.trborderradius,
        resProp.blborderradius,
        resProp.brborderradius,
        resProp.svgContent
      );
      if (link) {
        setResizeRatio(resProp.width / resProp.height);
      }
      setSelectedSidebarItem(0);
      setDrawAreaHeight(parseInt(window.getComputedStyle(drawArea).height));
      setDrawAreaWidth(parseInt(window.getComputedStyle(drawArea).width));
      if (itemSelected === "MultiLayer") {
      } else {
        setTemplateDetails(false);
        setGroupSelection(false);
        setAlign("");
        setAlignElement(new Set([]));
        if (canvaContent[canvaContent.length - 1].id === "MultiLayer") {
          handleContent(canvaContent.splice(canvaContent.length - 1, 1));
        }
      }
      setIsItemSelected(true);
    },
    [itemSelected]
  );

  function setStates(
    color,
    height,
    width,
    left,
    top,
    rotate,
    textColor,
    borderColor,
    flip,
    opacity,
    textalign,
    fontsize,
    fontfamily,
    textdecoration,
    fontstyle,
    fontweight,
    letterspacing,
    lineheight,
    border,
    borderradius,
    layername,
    placeholder,
    cltoggle,
    emtoggle,
    characterlimit,
    errormessage,
    name,
    type,
    subtype,
    starttime,
    stoptime,
    pausetoggle,
    resumetoggle,
    pausetime,
    resumein,
    resumeto,
    seekto,
    optionsarray,
    animationentryspeed,
    animationexitspeed,
    animationtype,
    exitanimation,
    entryanimation,
    icon,
    value,
    zindex,
    payload,
    action,
    defaultchecked,
    actionsarray,
    tlborderradius,
    trborderradius,
    blborderradius,
    brborderradius,
    svgContent
  ) {
    setbgColor(color);
    setHeight(parseFloat(height));
    setWidth(parseFloat(width));
    setXcord(parseFloat(left));
    setYcord(parseFloat(top));
    setRotate(parseFloat(rotate));
    setTextColor(textColor);
    setBorderColor(borderColor);
    setFlip(flip);
    setOpacity(opacity);
    setTextAlign(textalign);
    setFontSize(fontsize);
    setfontFamily(fontfamily);
    setTextDecoration(textdecoration);
    setFontStyle(fontstyle);
    setFontWeight(fontweight);
    setLetterSpacing(letterspacing);
    setLineHeight(lineheight);
    setBorder(border);
    setBorderRadius(borderradius);
    setLayername(layername);
    setPlaceholder(placeholder);
    setCltoggle(cltoggle);
    setEmtoggle(emtoggle);
    setCharacterLimit(characterlimit);
    setErrorMessage(errormessage);
    setName(name);
    setType(type);
    setSubType(subtype);
    setStartTime(starttime);
    setStopTime(stoptime);
    setPauseToggle(pausetoggle);
    setResumeToggle(resumetoggle);
    setPauseTime(pausetime);
    setResumeIn(resumein);
    setResumeTo(resumeto);
    setSeekTo(seekto);
    setOptionsArray(optionsarray);
    setAnimationEntrySpeed(animationentryspeed);
    setAnimationExitSpeed(animationexitspeed);
    setAnimationType(animationtype);
    setEntryAnimation(entryanimation);
    setExitAnimation(exitanimation);
    setIcon(icon);
    setEleValue(value);
    setZIndex(zindex);
    setPayload(payload);
    setAction(action);
    setDefaultChecked(defaultchecked);
    setActionsArray(actionsarray);
    setTLBorderRadius(tlborderradius);
    setTRBorderRadius(trborderradius);
    setBLBorderRadius(blborderradius);
    setBRBorderRadius(brborderradius);
    setSVGCode(svgContent);
  }

  useEffect(
    function () {
      if (idx == null || itemSelected == null) {
        return;
      }
      let id = canvaContent[idx].id;
      let draggable = canvaContent[idx].draggable;
      let visibility = canvaContent[idx].visibility;
      let newObj = new divObj(
        xcord,
        ycord,
        height,
        width,
        tbgColor,
        rotate,
        id,
        zIndex,
        border,
        startTime,
        stopTime,
        visibility,
        ttextColor,
        tborderColor,
        flip,
        opacity,
        textAlign,
        fontSize,
        fontFamily,
        textDecoration,
        fontStyle,
        fontWeight,
        letterSpacing,
        lineHeight,

        borderRadius,
        layerName,
        cltoggle,
        emtoggle,
        placeHolder,
        characterLimit,
        errorMessage,
        name,
        type,
        subType,
        pauseToggle,
        resumeToggle,
        pauseTime,
        resumeIn,
        resumeTo,
        seekTo,
        optionsArray,
        animationEntrySpeed,
        animationExitSpeed,
        animationType,
        exitAnimation,
        entryAnimation,
        icon,
        eleValue,
        payload,
        draggable,
        action,
        defaultChecked,
        actionsArray,
        tlborderRadius,
        trborderRadius,
        blborderRadius,
        brborderRadius,
        svgCode
      );
      canvaContent[idx] = { ...newObj };
      handleContent(canvaContent);
      if (!isItemSelected) {
        undoRedoAction();
      }
      if (isDivDropped) {
        if (type === "image") {
          let loader = document.getElementById(`loader${itemSelected}`);
          if (loader !== null && loader !== undefined)
            loader.style.display = "grid";
        }
        undoRedoAction();
      }
      setIsItemSelected(false);
      setIsDivDropped(false);
    },
    [
      xcord,
      ycord,
      bgColor,
      height,
      width,
      rotate,
      textColor,
      borderColor,
      flip,
      opacity,
      textAlign,
      fontSize,
      fontFamily,
      textDecoration,
      fontStyle,
      letterSpacing,
      lineHeight,
      fontWeight,
      border,
      borderRadius,
      layerName,
      placeHolder,
      characterLimit,
      errorMessage,
      name,
      type,
      subType,
      emtoggle,
      cltoggle,
      startTime,
      stopTime,
      pauseToggle,
      resumeToggle,
      pauseTime,
      resumeIn,
      resumeTo,
      seekTo,
      optionsArray,
      animationEntrySpeed,
      animationExitSpeed,
      animationType,
      exitAnimation,
      entryAnimation,
      icon,
      eleValue,
      zIndex,
      payload,
      action,
      defaultChecked,
      actionsArray,
      tlborderRadius,
      trborderRadius,
      blborderRadius,
      brborderRadius,
      svgCode,
    ]
  );
  useEffect(
    function () {
      if (idx == null || itemSelected == null) {
        return;
      }
      let id = canvaContent[idx].id;
      let draggable = canvaContent[idx].draggable;
      let visibility = canvaContent[idx].visibility;
      let newObj = new divObj(
        xcord,
        ycord,
        height,
        width,
        tbgColor,
        rotate,
        id,
        zIndex,
        border,
        // "1px solid",
        startTime,
        stopTime,
        visibility,
        ttextColor,
        tborderColor,
        flip,
        opacity,
        textAlign,
        fontSize,
        fontFamily,
        textDecoration,
        fontStyle,
        fontWeight,
        letterSpacing,
        lineHeight,
        borderRadius,
        layerName,
        cltoggle,
        emtoggle,
        placeHolder,
        characterLimit,
        errorMessage,
        name,
        type,
        subType,
        pauseToggle,
        resumeToggle,
        pauseTime,
        resumeIn,
        resumeTo,
        seekTo,
        optionsArray,
        animationEntrySpeed,
        animationExitSpeed,
        animationType,
        exitAnimation,
        entryAnimation,
        icon,
        eleValue,
        payload,
        draggable,
        action,
        defaultChecked,
        actionsArray,
        tlborderRadius,
        trborderRadius,
        blborderRadius,
        brborderRadius,
        svgCode
      );
      canvaContent[idx] = { ...newObj };
      handleContent(canvaContent);
      setIsItemSelected(false);
      setIsDivDropped(false);
    },
    [tbgColor, ttextColor, tborderColor]
  );
  useEffect(() => {
    setOpen(false);
    // setOpenHiddenVariable(false);
  }, [settingButton]);
  // console.log(canvaContent);
  const handleUpload = async (e, type, actionType) => {
    const file = e.target.files[0];
    if (type === "image") {
      if (file === undefined || !file.type.startsWith("image/")) {
        toast.error("Not an Image File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    } else if (type === "video") {
      if (file === undefined || !file.type.startsWith("video/")) {
        toast.error("Not a video File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    } else if (type === "audio") {
      if (file === undefined || !file.type.startsWith("audio/")) {
        toast.error("Not an audio File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("templateid", templateInfo[0]?.TEMPLATEID);
    const pathSegments = window.location.pathname.split("/");
    const urlString = pathSegments[pathSegments.length - 1];
    let action = urlString === "createTemplate" ? "temp" : "live";
    formData.append("action", action);
    formData.append("uploadtype", "content");

    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.STATUS === "1000") {
            let receivedData = JSON.parse(response.DATA);
            console.log("Image/Video/Audio", receivedData);
            let obj = {
              name: receivedData[0].FILENAME,
              path: receivedData[0].FILEPATH,
            };
            if (type === "audio") {
              let arr = [{ ...obj }];
              setAudioData([...arr]);
            } else if (type === "image") {
              let arr = [...imageData];
              arr.push({ ...obj });
              setImageData([...arr]);
              if (actionType === "brand") {
                setBrandImage(obj.path + "" + obj.name);
              } else if (actionType === "ogtag") {
                dispatch({
                  type: "imageSet",
                  payload: obj.path + "" + obj.name,
                });
              }
            }
            toast.success(response.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.dismiss();
            toast.error(response.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
            // setProgressbar(0);
          }
        } else {
          // toast.dismiss();
          // toast.error("Failed to upload the file.", {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: false,
          //   draggable: false,
          //   progress: undefined,
          //   theme: "dark",
          // });
          // setProgressbar(0);
        }
      }
    };
    xhr.onerror = () => {
      console.error("Error during upload");
      toast.dismiss();
      toast.error("Upload failed", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
      });
      // setProgressbar(0);
    };
    // xhr.open("POST", "http://180.151.195.62:8070/mconnectsaas/upload?", true);
    xhr.open("POST", `${API_BASE_URL}/upload`, true);
    xhr.send(formData);
  };

  const capture = () => {
    // Get the target element to capture
    setImageBase64(() => "");
    const videoEl = document.querySelector(".vjs-tech");
    let canvasEl = document.getElementById("canvas");
    canvasEl.width = parseInt(window.getComputedStyle(videoEl).width);
    canvasEl.height = parseInt(window.getComputedStyle(videoEl).height);
    const context = canvasEl.getContext("2d");
    context.drawImage(videoEl, 0, 0, canvasEl.width, canvasEl.height);

    var dataURL = canvasEl.toDataURL("image/png");

    var image = document.createElement("img");
    image.classList.add("downloaded-image");
    image.src = dataURL;
    image.style.width = "100%";
    image.style.height = "100%";
    document.getElementById("drawArea").appendChild(image);

    const style = document.createElement("style");
    document.head.appendChild(style);
    style.sheet?.insertRule(
      "body > div:last-child img { display: inline-block; }"
    );
    const elementToCapture = document.getElementById("drawArea");
    // const elementToCapture = document.querySelector(".dumb");

    // Use html2canvas to capture a screenshot
    html2canvas(elementToCapture, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      // Create an image from the canvas
      const screenshot = new Image();
      screenshot.style.width = "100%";
      screenshot.style.height = "100%";
      screenshot.src = canvas.toDataURL("image/png");
      setImageBase64(canvas.toDataURL("image/png"));
      elementToCapture.removeChild(image);
      style.remove();
    });
  };

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (type === "image") {
      if (file === undefined || !file.type.startsWith("image/")) {
        toast.error("Not an Image File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    }
    if (file) {
      // Convert the selected image to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Set the base64 data in state
        setImageBase64(reader.result);
      };
    }
  };

  console.log("LayerContent", layerContent);
  console.log("My Result", templateInfo);

  const items = [
    {
      key: "1",
      label: "Template Info",
      children: (
        <div className="mt-2 mb-2 font-sans leading-5 ">
          <Divider className=" !m-0" />
          <div className="mt-2">
            <div className="ml-2 text-[14px] mr-2">
              <div className="bg-[#4B4C4C] rounded-full w-[50px] h-[22px] text-center">
                {totalTime}
              </div>
            </div>
            <div className="flex justify-between">
              <Input
                id="tn"
                disabled={nameDisable}
                value={templateName}
                placeholder="Provide Template Name"
                onClick={() => {
                  fieldEnabled.current = true;
                  nameRef.current = true;
                }}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                  setShowDialog(true);
                }}
                onBlur={() => {
                  nameRef.current = false;
                  setNameDisable(true);
                  fieldEnabled.current = false;
                  setTimeout(() => {
                    nameRef.current = true;
                  }, 500);
                }}
                className={`${
                  nameDisable
                    ? "!cursor-default border-none !p-0"
                    : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
                } !resize-none overflow-y-auto   h-9 w-[280px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
              />
              <div className="h-[30px] w-[30px] mt-3 mr-2 rounded-sm bg-[#3F3F3F] flex justify-center align-middle">
                <Pencil
                  className="mt-1 h-[18px] w-[18px] cursor-pointer"
                  fill={nameDisable ? "#EDEDED" : "#1C68EB"}
                  onClick={() => {
                    if (nameRef.current) {
                      setNameDisable(!nameDisable);
                    }
                    fieldEnabled.current = true;
                  }}
                />
              </div>
            </div>
            {templateInfo.length > 0 && (
              <div className="mt-2 h-[150px] bg-[#1A1A20] ml-2 mr-2">
                <img
                  className="h-full w-full object-contain"
                  src={
                    pathName === "createTemplate"
                      ? `${templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${templateInfo[0]?.TEMPLATEID}/SOURCE/thumbnail.png?${id}`
                      : `${templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${templateInfo[0]?.TEMPLATEID}/SOURCE/thumbnail.png?${id}`
                  }
                  alt="thumbnail"
                />
              </div>
            )}
            <div className="mt-3 ml-2 mr-2">
              <div className="text-[14px] mb-2">Update Thumbnail</div>
              <div className="flex">
                <div className="flex mr-4">
                  <input
                    className="mt-1"
                    value={0}
                    name="thumnail"
                    checked={thumbnailType === 0}
                    type="radio"
                    onChange={() => {
                      setThumbnailType(0);
                    }}
                    style={{
                      accentColor: "#1C68EB",
                    }}
                  />
                  <div className="ml-2  text-[13px] mt-[2px]">Use Scene</div>
                </div>
                <div className="flex">
                  <input
                    className="mt-1"
                    value={1}
                    name="thumnail"
                    checked={thumbnailType === 1}
                    type="radio"
                    onChange={() => {
                      setThumbnailType(1);
                    }}
                    style={{
                      accentColor: "#1C68EB",
                    }}
                  />
                  <div className="ml-2 text-[13px] mt-[2px]">Browse</div>
                </div>
              </div>
              <div className="mt-1">
                <div className="text-[12px] text-[#BABABA]">
                  {thumbnailType === 0
                    ? "Select frame in video on real time and set frame as thumbnail"
                    : "Browse and Upload your own Thumbnail"}
                </div>
                <div className="mt-2">
                  {thumbnailType === 0 ? (
                    <SimpleButton
                      className="bg-[#056DE8] border-none text-[#ffffff] rounded-[2px]"
                      label="Set Thumbnail"
                      onClick={() => {
                        capture();
                        setShowDialog(true);
                      }}
                    />
                  ) : (
                    <div className="flex">
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        onChange={(e) => {
                          handleImageUpload(e, "image");
                          setShowDialog(true);
                        }}
                        ref={thumbnailRef}
                        style={{ display: "none" }}
                      />
                      <SimpleButton
                        className="bg-[#056DE8] border-none text-[#ffffff] rounded-[2px]"
                        label="Browse"
                        onClick={() => thumbnailRef.current.click()}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div className="ml-2 mr-2 flex justify-between">
              <div className="text-[14px]">Description</div>
              <div className="h-[30px] w-[30px] rounded-sm bg-[#3F3F3F] flex justify-center align-middle">
                <Pencil
                  className="mt-1 h-[18px] w-[18px] cursor-pointer"
                  fill={descDisable ? "#EDEDED" : "#1C68EB"}
                  onClick={() => {
                    if (tempDescRef.current) {
                      setDescDisable(!descDisable);
                    }
                    fieldEnabled.current = true;
                  }}
                />
              </div>
            </div>
            <TextArea
              id="td"
              autoSize={true}
              value={templateDescription}
              disabled={descDisable}
              onClick={() => {
                fieldEnabled.current = true;
                tempDescRef.current = true;
              }}
              placeholder="Provide the Template Description"
              onChange={(e) => {
                setTemplateDescription(e.target.value);
                setShowDialog(true);
              }}
              onBlur={() => {
                setDescDisable(true);
                tempDescRef.current = false;
                fieldEnabled.current = false;
                setTimeout(() => {
                  tempDescRef.current = true;
                }, 500);
              }}
              className={`${
                descDisable
                  ? "!cursor-default border-none !p-0"
                  : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
              } !resize-none overflow-y-auto   h-9 w-[280px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
            />
            <Divider />
            <div
              style={{
                display: "none",
              }}
            >
              <canvas id="canvas"></canvas>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Brand Setting",
      children: (
        <div className="mt-2 mb-2 font-sans leading-5 ">
          <Divider className=" !m-0" />
          <div className="mt-2">
            <div className="ml-2  mr-2">
              <div className="text-[14px]">Page Appearance</div>
              <div className="text-[#DDDDDD] text-[11px]">
                Helps to configure the brand page appearance with the video
                template.
              </div>
              <div className="flex w-[90%] align-middle mt-2 justify-between">
                <div className="text-[13px] pt-1 ">Background Color</div>
                <div className="w-[28%] flex">
                  <ColorPicker
                    className="fill_color p-0"
                    format="hex"
                    arrow={false}
                    value={brandColor}
                    style={{
                      borderWidth: "2px",
                    }}
                    onChange={(e, value, hex) => {
                      setBrandColor(value);
                    }}
                  />
                  <div className="text-[12px] w-[10%] pt-2 leading-4 ml-1">
                    {brandColor?.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className="flex align-middle my-2 justify-between">
                <div className="text-[13px] pt-1 ">Background Image</div>
                <div className="flex">
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    onChange={(e) => {
                      handleUpload(e, "image", "brand");
                      setShowDialog(true);
                    }}
                    ref={backImageRef}
                    style={{ display: "none" }}
                  />
                  <SimpleButton
                    className="bg-[#42434369] border-1 border-solid border-[#376BF1] text-[12px] h-[25px] w-[95px] p-0  text-[#1D66F4] rounded-[50px]"
                    label="Upload Image"
                    onClick={() => backImageRef.current.click()}
                  />
                </div>
              </div>
              {brandImage !== "" && (
                <div className="flex justify-between items-center">
                  <div className="mb-2 h-[90px] w-[90px] bg-[#1A1A20] ml-2 mr-2">
                    <img
                      className="h-full w-full object-contain"
                      src={brandImage}
                      alt="thumbnail"
                    />
                  </div>
                  <SimpleButton
                    className="bg-[#42434369] border-1 border-solid border-[#376BF1] text-[#1D66F4] text-[12px] h-[25px] w-[95px] p-0 rounded-[50px]"
                    label="Remove"
                    onClick={() => setBrandImage("")}
                  />
                </div>
              )}
            </div>
            <Divider className=" !m-0" />
            <div className="ml-2 mt-2  mr-2">
              <div className="text-[14px]">OG Settings</div>
              <div className="text-[#DDDDDD] text-[11px]">
                OG settings helps you to add image, title and description about
                your video when shared to your customers.
              </div>
              <div className="mt-2">
                <div className="text-[13px] pt-1 ">OG Title</div>
                <div className="flex justify-between">
                  <Input
                    id="ot"
                    disabled={titleDisable}
                    value={ogState.title}
                    placeholder="Provide the OG Title"
                    onClick={() => {
                      fieldEnabled.current = true;
                      titleRef.current = true;
                    }}
                    onChange={(e) => {
                      dispatch({
                        type: "titleChanged",
                        payload: e.target.value,
                      });
                      setShowDialog(true);
                    }}
                    onBlur={() => {
                      setTitleDisable(true);
                      titleRef.current = false;
                      fieldEnabled.current = false;
                      setTimeout(() => {
                        titleRef.current = true;
                      }, 500);
                    }}
                    className={`${
                      titleDisable
                        ? "!cursor-default border-none !p-0"
                        : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
                    } !resize-none overflow-y-auto   h-9 w-[280px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
                  />
                  <div className="h-[30px] w-[30px] mt-3 mr-2 rounded-sm bg-[#3F3F3F] flex justify-center align-middle">
                    <Pencil
                      className="mt-1 h-[18px] w-[18px] cursor-pointer"
                      fill={titleDisable ? "#EDEDED" : "#1C68EB"}
                      onClick={() => {
                        if (titleRef.current) {
                          setTitleDisable(!titleDisable);
                        }
                        fieldEnabled.current = true;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="text-[13px] pt-1 ">OG Description</div>
                <div className="flex justify-between">
                  <Input
                    id="od"
                    disabled={ogDescDisable}
                    value={ogState.description}
                    placeholder="Provide the OG Description"
                    onClick={() => {
                      fieldEnabled.current = true;
                    }}
                    onChange={(e) => {
                      dispatch({
                        type: "descriptionChanged",
                        payload: e.target.value,
                      });
                      setShowDialog(true);
                    }}
                    onBlur={() => {
                      setOgDescDisable(true);
                      ogDescRef.current = false;
                      setTimeout(() => {
                        ogDescRef.current = true;
                      }, 500);
                      fieldEnabled.current = false;
                    }}
                    className={`${
                      ogDescDisable
                        ? "!cursor-default border-none !p-0"
                        : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
                    } !resize-none overflow-y-auto   h-9 w-[280px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
                  />
                  <div className="h-[30px] w-[30px] mt-3 mr-2 rounded-sm bg-[#3F3F3F] flex justify-center align-middle">
                    <Pencil
                      className="mt-1 h-[18px] w-[18px] cursor-pointer"
                      fill={ogDescDisable ? "#EDEDED" : "#1C68EB"}
                      onClick={() => {
                        if (ogDescRef.current) {
                          setOgDescDisable(!ogDescDisable);
                        }
                        fieldEnabled.current = true;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-2 mt-2  mr-2">
              <div className="text-[14px]">OG Image</div>
              <div className="flex justify-between my-2">
                <div className="text-[#DDDDDD] text-[11px] pt-1">
                  Upload image with 600(x)x600(h)
                </div>
                <div className="flex">
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    onChange={(e) => {
                      handleUpload(e, "image", "ogtag");
                      setShowDialog(true);
                    }}
                    ref={ogImageRef}
                    style={{ display: "none" }}
                  />
                  <SimpleButton
                    className="bg-[#42434369] border-1 border-solid border-[#376BF1] text-[#1D66F4] text-[12px] h-[25px] w-[95px] p-0 rounded-[50px]"
                    label="Upload Image"
                    onClick={() => ogImageRef.current.click()}
                  />
                </div>
              </div>
            </div>
            {ogState.image !== "" && (
              <div className="flex justify-between items-center mx-2">
                <div className="mb-2 h-[90px] w-[90px] bg-[#1A1A20] ml-2 mr-2">
                  <img
                    className="h-full w-full object-contain"
                    src={ogState.image}
                    alt="thumbnail"
                  />
                </div>
                <SimpleButton
                  className="bg-[#42434369] border-1 border-solid border-[#376BF1] text-[#1D66F4] text-[12px] h-[25px] w-[95px] p-0 rounded-[50px]"
                  label="Remove"
                  onClick={() =>
                    dispatch({
                      type: "imageSet",
                      payload: "",
                    })
                  }
                />
              </div>
            )}

            <Divider />
            {customerOwner !== "customeradmin@vspagy.com" ? (
              <div className="ml-2 mt-2  mr-2">
                <div className="text-[14px]">Brand Content</div>
                <div className="text-[#DDDDDD] text-[11px]">
                  Choose brand content you want to associate with this template.
                </div>
                <div className="flex cursor-pointer" onClick={mapBrandOpen}>
                  <div className="text-[13px] pl-[5px] py-[3px]  text-left">
                    {brandSelected === "" ||
                    brandSelected === null ||
                    brandSelected === undefined
                      ? "--Select Brand Page--"
                      : brandSelected == false
                      ? "--Select Brand Page--"
                      : brandName}
                  </div>
                  <div className="mt-[8px] ml-4">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="down"
                      width="10px"
                      height="10px"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div
      className={`w-[300px] h-[calc(100vh-64px)] ${
        layerButton || settingButton || musicButton
          ? ""
          : `overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
      scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]`
      } relative text-[#FFFFFF] bg-[#050506] font-sans right-drawer ${
        !vis ? "open" : ""
      }`}
      onClick={() => {
        setRightClicked("");
      }}
    >
      {!layerButton && !settingButton && !templateDetails && !musicButton && (
        <div>
          <div className="flex gap-3 justify-center items-center  mt-5">
            {rightDrawerType.map((item, index) =>
              type !== "hotspot" ? (
                <div
                  onClick={() => setSelectedSidebarItem(index)}
                  className={`${
                    selectedSidebarItem === index
                      ? "text-sm text-[#38c3ff] font-semibold border-solid border-t-0 border-l-0 border-r-0 !border-b-1 z-10 pb-1 !border-b-[#38c3ff] cursor-pointer"
                      : "text-[#DEDEDE]  text-sm font-normal pb-1 cursor-pointer"
                  }`}
                  key={index}
                >
                  {item.name}
                </div>
              ) : (
                (index === 0 || index === 3) && (
                  <div
                    onClick={() => setSelectedSidebarItem(index)}
                    className={`${
                      selectedSidebarItem === index
                        ? "text-sm text-[#38c3ff] font-semibold border-solid border-t-0 border-l-0 border-r-0 border-b-2 z-10 pb-1 !border-b-[#38c3ff] cursor-pointer"
                        : "text-[#DEDEDE]  text-sm font-normal pb-1 cursor-pointer"
                    }`}
                    key={index}
                  >
                    {item.name}
                  </div>
                )
              )
            )}
          </div>
          {/* <Divider className=" absolute top-[44.2px] !m-0 " /> */}
          {selectedSidebarItem === 0 && (
            <ComponentDetail
              itemSelected={itemSelected}
              canvaContent={canvaContent}
              handleContent={handleContent}
              elementProperties={elementProperties}
              idx={idx}
              setIdx={setIdx}
              bgColor={bgColor}
              setbgColor={setbgColor}
              textColor={textColor}
              setTextColor={setTextColor}
              borderColor={borderColor}
              setBorderColor={setBorderColor}
              xcord={xcord}
              setXcord={setXcord}
              ycord={ycord}
              setYcord={setYcord}
              height={height}
              setHeight={setHeight}
              width={width}
              setWidth={setWidth}
              rotate={rotate}
              setRotate={setRotate}
              flip={flip}
              setFlip={setFlip}
              type={type}
              subtype={subType}
              drawAreaWidth={drawAreaWidth}
              drawAreaHeight={drawAreaHeight}
              border={border}
              setBorder={setBorder}
              borderRadius={borderRadius}
              setBorderRadius={setBorderRadius}
              layerName={layerName}
              setLayername={setLayername}
              placeHolder={placeHolder}
              setPlaceholder={setPlaceholder}
              cltoggle={cltoggle}
              setCltoggle={setCltoggle}
              emtoggle={emtoggle}
              setEmtoggle={setEmtoggle}
              characterLimit={characterLimit}
              setCharacterLimit={setCharacterLimit}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              name={name}
              setName={setName}
              eleValue={eleValue}
              setEleValue={setEleValue}
              optionsArray={optionsArray}
              setOptionsArray={setOptionsArray}
              displayTextConfig={displayTextConfig}
              defaultChecked={defaultChecked}
              setDefaultChecked={setDefaultChecked}
              setPayload={setPayload}
              tlborderRadius={tlborderRadius}
              setTLBorderRadius={setTLBorderRadius}
              trborderRadius={trborderRadius}
              setTRBorderRadius={setTRBorderRadius}
              blborderRadius={blborderRadius}
              setBLBorderRadius={setBLBorderRadius}
              brborderRadius={brborderRadius}
              setBRBorderRadius={setBRBorderRadius}
              resizeRatio={resizeRatio}
              setResizeRatio={setResizeRatio}
              link={link}
              setLink={setLink}
              fieldEnabled={fieldEnabled}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isRight={isRight}
              setIsRight={setIsRight}
              showDialog={showDialog}
              setShowDialog={setShowDialog}
            />
          )}
          {selectedSidebarItem === 1 && (
            <DesignDetail
              bgColor={bgColor}
              setbgColor={setbgColor}
              tbgColor={tbgColor}
              setTbgColor={setTbgColor}
              textColor={textColor}
              setTextColor={setTextColor}
              ttextColor={ttextColor}
              setTTextColor={setTTextColor}
              borderColor={borderColor}
              setBorderColor={setBorderColor}
              tborderColor={tborderColor}
              setTBorderColor={setTBorderColor}
              itemSelected={itemSelected}
              opacity={opacity}
              setOpacity={setOpacity}
              type={type}
              textAlign={textAlign}
              setTextAlign={setTextAlign}
              fontSize={fontSize}
              setFontSize={setFontSize}
              fontFamily={fontFamily}
              setfontFamily={setfontFamily}
              textDecoration={textDecoration}
              setTextDecoration={setTextDecoration}
              fontStyle={fontStyle}
              setFontStyle={setFontStyle}
              letterSpacing={letterSpacing}
              setLetterSpacing={setLetterSpacing}
              lineHeight={lineHeight}
              setLineHeight={setLineHeight}
              fontWeight={fontWeight}
              setFontWeight={setFontWeight}
              fieldEnabled={fieldEnabled}
              xcord={xcord}
              setXcord={setXcord}
              height={height}
              width={width}
              setWidth={setWidth}
              border={border}
              setBorder={setBorder}
              // setFieldEnabled={setFieldEnabled}
            />
          )}
          {selectedSidebarItem === 2 && (
            <Animation
              animationEntrySpeed={animationEntrySpeed}
              setAnimationEntrySpeed={setAnimationEntrySpeed}
              animationExitSpeed={animationExitSpeed}
              setAnimationExitSpeed={setAnimationExitSpeed}
              exitAnimation={exitAnimation}
              setExitAnimation={setExitAnimation}
              entryAnimation={entryAnimation}
              setEntryAnimation={setEntryAnimation}
              lastAnimation={lastAnimation}
              setLastAnimation={setLastAnimation}
              fieldEnabled={fieldEnabled}
              // setFieldEnabled={setFieldEnabled}
            />
          )}
          {selectedSidebarItem === 3 && (
            <Action
              type={type}
              startTime={startTime}
              setStartTime={setStartTime}
              stopTime={stopTime}
              setStopTime={setStopTime}
              pauseTime={pauseTime}
              pauseToggle={pauseToggle}
              setPauseToggle={setPauseToggle}
              resumeToggle={resumeToggle}
              setResumeToggle={setResumeToggle}
              setPauseTime={setPauseTime}
              resumeIn={resumeIn}
              setResumeIn={setResumeIn}
              resumeTo={resumeTo}
              setResumeTo={setResumeTo}
              seekTo={seekTo}
              setSeekTo={setSeekTo}
              action={action}
              setAction={setAction}
              actionsArray={actionsArray}
              setActionsArray={setActionsArray}
              fieldEnabled={fieldEnabled}
              // setFieldEnabled={setFieldEnabled}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isRight={isRight}
              setIsRight={setIsRight}
            />
          )}
        </div>
      )}
      {musicButton && (
        <div className="overflow-auto  font-sans">
          <div className="mt-6 mb-2 ">
            <div className="flex justify-between font-sans">
              <div className="ml-3 text-left font-semibold">
                Set Music timings (sec)
              </div>
            </div>
          </div>
          <Divider className=" !m-0" />

          <div
            className="h-[calc(100vh-117px)]  overflow-auto
      scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] font-sans"
          >
            {/* {musicData?.map((item, index) => {
              if (item.checked) {
                return (
                  <div key={index}>
                    <div className="flex justify-between">
                      <div className="flex pl-2">
                        <div className="pt-4 pl-2">
                          <Music height="22px" width="22px" />
                        </div>
                        <div
                          className={`ml-2 pl-4 py-3 pt-4 text-left w-[115px] overflow-hidden h-[50px]`}
                        >
                          {item.displayName}
                        </div>
                      </div>
                      <div className="mt-3 mr-1  flex ">
                        <InputNumber
                          className="timer0 !bg-[#050506] !border-none !shadow-none  !px-0  w-14 "
                          // className="w-12 mb-2"
                          // className="editor-input !px-0  w-16 mb-2"
                          type="number"
                          defaultValue={0}
                          min={0}
                          value={item.starttime}
                          formatter={(value) =>
                            `${value}`.replace(
                              /^(-)*(\d+)\.(\d{1,3}).*$/,
                              "$1$2.$3"
                            )
                          }
                          onStep={() => {
                            fieldEnabled.current = true;
                          }}
                          onClick={() => {
                            fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            fieldEnabled.current = false;
                          }}
                          onChange={(value) => {
                            let index = musicData.findIndex(
                              (v) => v.displayName === item.displayName
                            );
                            let arr = [...musicData];
                            arr[index].starttime = value;
                            setMusicData([...arr]);
                            setShowDialog(true);
                          }}
                        />
                        <div className="pt-1 px-2">/</div>
                        <InputNumber
                          className="timer !bg-[#050506] !shadow-none border-none  !px-0  w-14 "
                          //  className="w-12 mb-2"
                          // className="editor-input !px-0  w-16 mb-2"
                          type="number"
                          defaultValue={1}
                          value={item.stoptime}
                          formatter={(value) =>
                            `${value}`.replace(
                              /^(-)*(\d+)\.(\d{1,3}).*$/,
                              "$1$2.$3"
                            )
                          }
                          onClick={() => {
                            fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            fieldEnabled.current = false;
                            // if (
                            //   item.stoptime === null ||
                            //   item.stoptime < 0 ||
                            //   item.stoptime < item.starttime
                            // ) {
                            //   canvaContent[index].stoptime = item.starttime + 1;
                            //   handleContent([...canvaContent]);
                            // }
                          }}
                          onStep={() => {
                            fieldEnabled.current = true;
                          }}
                          onChange={(value) => {
                            let index = musicData.findIndex(
                              (v) => v.displayName === item.displayName
                            );
                            let arr = [...musicData];
                            arr[index].stoptime = value;
                            setMusicData([...arr]);
                            setShowDialog(true);
                          }}
                        />
                      </div>
                    </div>
                    <Divider className=" !m-0" />
                  </div>
                );
              } else {
                return null;
              }
            })}
            {globalmusicData?.map((item, index) => {
              if (item.checked) {
                return (
                  <div key={index}>
                    <div className="flex justify-between">
                      <div className="flex pl-2">
                        <div className="pt-4 pl-2">
                          <Music height="22px" width="22px" />
                        </div>
                        <div
                          className={`ml-2 pl-4 py-3 pt-4 text-left w-[115px] overflow-hidden h-[50px]`}
                        >
                          {item.name}
                        </div>
                      </div>
                      <div className="mt-3 mr-1  flex ">
                        <InputNumber
                          className="timer0 !bg-[#050506] !border-none !shadow-none  !px-0  w-14 "
                          // className="w-12 mb-2"
                          // className="editor-input !px-0  w-16 mb-2"
                          type="number"
                          defaultValue={0}
                          min={0}
                          value={item.starttime}
                          formatter={(value) =>
                            `${value}`.replace(
                              /^(-)*(\d+)\.(\d{1,3}).*$/,
                              "$1$2.$3"
                            )
                          }
                          onStep={() => {
                            fieldEnabled.current = true;
                          }}
                          onClick={() => {
                            fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            fieldEnabled.current = false;
                          }}
                          onChange={(value) => {
                            let index = globalmusicData.findIndex(
                              (v) => v.name === item.name
                            );
                            let arr = [...globalmusicData];
                            arr[index].starttime = value;
                            setGlobalMusicData([...arr]);
                            setShowDialog(true);
                          }}
                        />
                        <div className="pt-1 px-2">/</div>
                        <InputNumber
                          className="timer !bg-[#050506] !shadow-none border-none  !px-0  w-14 "
                          //  className="w-12 mb-2"
                          // className="editor-input !px-0  w-16 mb-2"
                          type="number"
                          defaultValue={1}
                          value={item.stoptime}
                          formatter={(value) =>
                            `${value}`.replace(
                              /^(-)*(\d+)\.(\d{1,3}).*$/,
                              "$1$2.$3"
                            )
                          }
                          onClick={() => {
                            fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            fieldEnabled.current = false;
                            // if (
                            //   item.stoptime === null ||
                            //   item.stoptime < 0 ||
                            //   item.stoptime < item.starttime
                            // ) {
                            //   canvaContent[index].stoptime = item.starttime + 1;
                            //   handleContent([...canvaContent]);
                            // }
                          }}
                          onStep={() => {
                            fieldEnabled.current = true;
                          }}
                          onChange={(value) => {
                            let index = globalmusicData.findIndex(
                              (v) => v.name === item.name
                            );
                            let arr = [...globalmusicData];
                            arr[index].stoptime = value;
                            setGlobalMusicData([...arr]);
                            setShowDialog(true);
                          }}
                        />
                      </div>
                    </div>
                    <Divider className=" !m-0" />
                  </div>
                );
              } else {
                return null;
              }
            })} */}
            <Collapse
              className="xyz"
              items={music}
              activeKey={activeKey1}
              onChange={onChange1}
              expandIconPosition={"start"}
              destroyInactivePanel={true}
            />
            <Collapse
              className="xyz"
              items={globalmusic}
              activeKey={activeKey2}
              onChange={onChange2}
              expandIconPosition={"start"}
              destroyInactivePanel={true}
            />
          </div>
        </div>
      )}
      {layerButton && (
        <div className="font-sans">
          <div className="mt-6 mb-2 ">
            {!timeSetting && (
              <div className="flex justify-between">
                <div className="ml-3 text-left font-semibold">
                  {currLayer
                    ? layerContent.filter((item, index) => {
                        if (
                          currentTime >= item.starttime &&
                          currentTime <= item.stoptime
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }).length
                    : canvaContent.length}{" "}
                  {canvaContent.length > 1 ? "Layers" : "Layer"}
                </div>
                <div className="mt-1 mr-4 flex ">
                  <Tooltip
                    className=""
                    title="Current layers"
                    color="#2B2B2C"
                    placement="top"
                    overlayStyle={{
                      boxShadow: "2px",
                    }}
                  >
                    <img
                      className="mr-2 h-[14px] w-[14px] mt-[2px] cursor-pointer"
                      src={currLayer ? layerOn : layerOff}
                      alt="time"
                      onClick={() => {
                        if (currLayer) {
                          let flag = canvaContent[0]?.draggable;
                          for (let i = 0; i < canvaContent.length; i++) {
                            if (canvaContent[i].draggable !== flag) {
                              setAllLocks({ currState: 0, prevState: -1 });
                              break;
                            }
                            if (
                              i === canvaContent.length - 1 &&
                              canvaContent[canvaContent.length - 1]
                                .draggable === flag
                            ) {
                              if (flag) {
                                setAllLocks({ currState: -1, prevState: 0 });
                              } else {
                                setAllLocks({ currState: 1, prevState: 0 });
                              }
                            }
                          }
                        } else {
                          let flag = true;
                          let count = 0;
                          canvaContent.forEach((item, index) => {
                            if (
                              currentTime >= item.starttime &&
                              currentTime <= item.stoptime
                            ) {
                              count++;
                              flag = canvaContent[index].draggable;
                            }
                          });

                          for (let i = 0; i < canvaContent.length; i++) {
                            if (
                              currentTime >= canvaContent[i].starttime &&
                              currentTime <= canvaContent[i].stoptime
                            ) {
                              count--;
                              if (canvaContent[i].draggable !== flag) {
                                setCurrLocks({ currState: 0, prevState: -1 });
                                break;
                              }
                              if (
                                count === 0 &&
                                canvaContent[i].draggable === flag
                              ) {
                                if (flag) {
                                  setCurrLocks({ currState: -1, prevState: 0 });
                                } else {
                                  setCurrLocks({ currState: 1, prevState: 0 });
                                }
                              }
                            }
                          }
                        }
                        setCurrLayer(!currLayer);
                        // setTimeContent([...canvaContent]);
                      }}
                    />
                  </Tooltip>
                  {!currLayer ? (
                    <Tooltip
                      className=""
                      title="Lock/UnLock Layers"
                      color="#2B2B2C"
                      placement="topLeft"
                      overlayStyle={{
                        boxShadow: "2px",
                      }}
                    >
                      <div
                        className="mr-3 ml-1 h-[14px] w-[14px] mt-[2px]"
                        onClick={() => {
                          if (allLocks.currState === 0) {
                            let arr = canvaContent.map((item, index) => {
                              let obj = { ...item };
                              if (allLocks.prevState === -1) {
                                obj.draggable = true;
                              } else {
                                obj.draggable = false;
                                setItem(null);
                                // setTemplateDescription(true);
                              }
                              return obj;
                            });
                            handleContent([...arr]);
                            setAllLocks(
                              allLocks.prevState === -1
                                ? { currState: -1, prevState: 0 }
                                : { currState: 1, prevState: 0 }
                            );
                          } else if (allLocks.currState === -1) {
                            let arr = canvaContent.map((item, index) => {
                              let obj = { ...item };
                              obj.draggable = false;
                              return obj;
                            });
                            handleContent([...arr]);
                            setItem(null);
                            // setTemplateDescription(true);
                            setAllLocks({ currState: 1, prevState: -1 });
                          } else {
                            let arr = canvaContent.map((item, index) => {
                              let obj = { ...item };
                              obj.draggable = true;
                              return obj;
                            });
                            handleContent([...arr]);
                            setAllLocks({ currState: -1, prevState: 1 });
                          }
                        }}
                      >
                        {allLocks.currState === -1 ? (
                          <Unlock fill={"#ffffff"} />
                        ) : allLocks.currState === 0 ? (
                          <Dash fill={"#ffffff"} />
                        ) : (
                          <Lock fill={"#ffffff"} />
                        )}
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      className=""
                      title="Lock/UnLock Layers"
                      color="#2B2B2C"
                      placement="topLeft"
                      overlayStyle={{
                        boxShadow: "2px",
                      }}
                    >
                      <div
                        className="mr-3 ml-1 h-[14px] w-[14px] mt-[2px]"
                        onClick={() => {
                          if (currLocks.currState === 0) {
                            let arr = canvaContent.map((item, index) => {
                              let obj = { ...item };
                              if (
                                currentTime >= item.starttime &&
                                currentTime <= item.stoptime
                              ) {
                                if (currLocks.prevState === -1) {
                                  obj.draggable = true;
                                } else {
                                  obj.draggable = false;
                                  setItem(null);
                                  // setTemplateDescription(true);
                                }
                              }
                              return obj;
                            });
                            handleContent([...arr]);
                            setCurrLocks(
                              currLocks.prevState === -1
                                ? { currState: -1, prevState: 0 }
                                : { currState: 1, prevState: 0 }
                            );
                          } else if (currLocks.currState === -1) {
                            let arr = canvaContent.map((item, index) => {
                              let obj = { ...item };
                              if (
                                currentTime >= item.starttime &&
                                currentTime <= item.stoptime
                              ) {
                                obj.draggable = false;
                              }
                              return obj;
                            });
                            handleContent([...arr]);
                            setItem(null);
                            // setTemplateDescription(true);
                            setCurrLocks({ currState: 1, prevState: -1 });
                          } else {
                            let arr = canvaContent.map((item, index) => {
                              let obj = { ...item };
                              if (
                                currentTime >= item.starttime &&
                                currentTime <= item.stoptime
                              ) {
                                obj.draggable = true;
                              }
                              return obj;
                            });
                            handleContent([...arr]);
                            setCurrLocks({ currState: -1, prevState: 1 });
                          }
                        }}
                      >
                        {currLocks.currState === -1 ? (
                          <Unlock fill={"#ffffff"} />
                        ) : currLocks.currState === 0 ? (
                          <Dash fill={"#ffffff"} />
                        ) : (
                          <Lock fill={"#ffffff"} />
                        )}
                      </div>
                    </Tooltip>
                  )}
                  <Tooltip
                    className=""
                    title="Layer Timing"
                    color="#2B2B2C"
                    placement="topLeft"
                    overlayStyle={{
                      boxShadow: "2px",
                    }}
                  >
                    <img
                      src={time}
                      alt="time"
                      onClick={() => {
                        setTimeSetting(!timeSetting);
                        // setTimeContent([...canvaContent]);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            {timeSetting && (
              <div className="flex justify-between font-sans">
                <div className="ml-3 text-left font-semibold">
                  Set Layer timings (sec)
                </div>

                <div className=" flex mt-1 mr-5 cursor-pointer">
                  <img
                    src={cross}
                    alt="cross"
                    onClick={() => {
                      setTimeSetting(!timeSetting);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <Divider className=" !m-0" />
          {!timeSetting && !currLayer && (
            <div className=" font-sans">
              <InfiniteScroll
                dataLength={content.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={window.innerHeight - 150}
              >
                {content.map((item, index) => (
                  <div
                    key={index}
                    className="cursor-move"
                    style={{
                      // display : currLayer && currentTime >= item.starttime &&
                      // currentTime <= item.stoptime?"block":"none",
                      // display : settingButton?"display":"none",
                      border:
                        itemSelected === item.id ? "1px solid white" : "none",
                      borderImage:
                        item.id === itemSelected
                          ? "linear-gradient(to right, #A6FFCB, #12D8FA, #1FA2FF)  1 / 1 / 0 stretch"
                          : "none",
                    }}
                    draggable
                    onDragStart={(e) => {
                      dragItem.current = item.id;
                    }}
                    onDragEnter={(e) => {
                      dragOverItem.current = item.id;
                    }}
                    onDragEnd={() => {
                      if (
                        dragOverItem.current === null ||
                        dragItem.current === null ||
                        dragOverItem.current === dragItem.current
                      ) {
                        return;
                      }
                      const findIndexesByIds = (array, id1, id2) => {
                        const index1 = array.findIndex((obj) => obj.id === id1);
                        const index2 = array.findIndex((obj) => obj.id === id2);
                        return [index1, index2];
                      };
                      const [index1, index2] = findIndexesByIds(
                        canvaContent,
                        dragItem.current,
                        dragOverItem.current
                      );
                      if (dragItem.current === itemSelected) {
                        setZIndex(canvaContent[index2].zindex);
                      }
                      if (dragOverItem.current === itemSelected) {
                        setZIndex(canvaContent[index1].zindex);
                      }
                      let z1 = canvaContent[index1].zindex;
                      canvaContent[index1].zindex = canvaContent[index2].zindex;
                      canvaContent[index2].zindex = z1;
                      dragItem.current = null;
                      dragOverItem.current = null;
                      // setLayerContent([..._canvaContent]);
                      handleContent(canvaContent);
                      undoRedoAction();
                    }}
                  >
                    <div className="flex justify-between font-sans h-[50px]">
                      <div className="flex pl-2">
                        <div className="pt-4 pl-2 mr-2">
                          {item.type === "text" ? (
                            <Text1 height="22px" width="22px" />
                          ) : item.type === "label" ? (
                            <Text height="22px" width="22px" />
                          ) : item.type === "assets" ? (
                            <Video height="22px" width="22px" />
                          ) : item.type === "hotspot" ? (
                            <Hotspot height="22px" width="22px" />
                          ) : item.type === "button" ? (
                            <ButtonImg height="22px" width="22px" />
                          ) : (
                            <OptionsList height="22px" width="22px" />
                          )}
                        </div>

                        <div
                          className="text-[13px] truncate"
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            className="w-[140px]"
                            onDoubleClick={() => {
                              if (modalOpen) {
                                return;
                              }
                              setLayerDisabled(item.id);
                              fieldEnabled.current = true;
                            }}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "100%",
                              zIndex: 10000,
                              display:
                                layerDisabled !== item.id ? "block" : "none",
                            }}
                          ></div>
                          <Input
                            id={item.id + "L"}
                            disabled={layerDisabled !== item.id}
                            autoFocus="true"
                            value={item.layername}
                            onClick={() => {
                              fieldEnabled.current = true;
                            }}
                            onChange={(e) => {
                              if (itemSelected === item.id) {
                                setLayername(e.target.value);
                              } else {
                                let idx = canvaContent.findIndex(
                                  (obj) => obj.id === item.id
                                );
                                let flag = false;
                                for (let i = 0; i < canvaContent.length; i++) {
                                  if (canvaContent[i].id !== item.id) {
                                    if (
                                      canvaContent[i].layername ===
                                      e.target.value
                                    ) {
                                      setIsRight(false);
                                      flag = true;
                                      break;
                                    }
                                  }
                                }
                                if (!flag) {
                                  setIsRight(true);
                                }
                                canvaContent[idx].layername = e.target.value;
                                handleContent([...canvaContent]);
                              }
                            }}
                            onBlur={() => {
                              setLayerDisabled(-1);
                              let idx = canvaContent.findIndex(
                                (obj) => obj.id === item.id
                              );
                              if (
                                item.layername === undefined ||
                                item.layername.trim() === ""
                              ) {
                                canvaContent[idx].layername =
                                  item.type + "_" + item.id;
                                handleContent([...canvaContent]);
                                setIsRight(true);
                              }
                              for (let i = 0; i < canvaContent.length; i++) {
                                if (canvaContent[i].id !== item.id) {
                                  if (
                                    canvaContent[i].layername ===
                                    canvaContent[idx].layername
                                  ) {
                                    if (!modalOpen) {
                                      setModalOpen(true);
                                      error(
                                        item.id + "L",
                                        "The Layer Name should be unique.",
                                        "Please change the layer name and ensure that it is unique."
                                      );
                                    }
                                    setIsModalOpen(true);
                                    setLayerDisabled(item.id);
                                    break;
                                  }
                                }
                              }
                              fieldEnabled.current = false;
                            }}
                            className={`${
                              layerDisabled !== item.id
                                ? "!cursor-default border-none !p-0"
                                : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
                            } !resize-none overflow-y-auto scrollbar w-[140px]  h-9 text-[13px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mt-4 mr-3 cursor-pointer">
                          <div
                            height="19px"
                            width="20px"
                            onClick={() => {
                              canvaContent[
                                canvaContent.findIndex(
                                  (obj) => obj.id === item.id
                                )
                              ].visibility =
                                !canvaContent[
                                  canvaContent.findIndex(
                                    (obj) => obj.id === item.id
                                  )
                                ].visibility;
                              handleContent([...canvaContent]);
                              if (itemSelected === item.id) {
                                setItem(null);
                              }
                            }}
                          >
                            {item.visibility ? (
                              <VisON fill="#ffffff" />
                            ) : (
                              <VisOFF fill="#ffffff" />
                            )}
                          </div>
                        </div>
                        <div className="mt-[17px] mr-3 ">
                          <div
                            height="19px"
                            width="20px"
                            onClick={() => {
                              let idx = canvaContent.findIndex(
                                (obj) => obj.id === item.id
                              );
                              if (!canvaContent[idx].visibility) {
                                return;
                              }
                              canvaContent[idx].draggable =
                                !canvaContent[idx].draggable;
                              let flag = canvaContent[0]?.draggable;
                              for (let i = 0; i < canvaContent.length; i++) {
                                if (allLocks.currState === -1) {
                                  if (canvaContent[i].draggable !== flag) {
                                    setAllLocks({
                                      currState: 0,
                                      prevState: -1,
                                    });
                                    break;
                                  }
                                } else if (allLocks.currState === 1) {
                                  if (canvaContent[i].draggable !== flag) {
                                    setAllLocks({ currState: 0, prevState: 1 });
                                    break;
                                  }
                                } else {
                                  if (canvaContent[i].draggable !== flag) {
                                    break;
                                  }
                                  if (
                                    i === canvaContent.length - 1 &&
                                    canvaContent[canvaContent.length - 1]
                                      .draggable === flag
                                  ) {
                                    setAllLocks(
                                      flag
                                        ? { currState: -1, prevState: 0 }
                                        : { currState: 1, prevState: 0 }
                                    );
                                  }
                                }
                              }
                              handleContent([...canvaContent]);
                              if (itemSelected === item.id) {
                                setItem(null);
                              }
                            }}
                          >
                            {item.draggable ? (
                              <Unlock
                                cursor={"pointer"}
                                fill={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            ) : (
                              <Lock
                                cursor={"pointer"}
                                fill={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="template-popover mt-3 mr-4">
                          <Popover
                            placement="bottomLeft"
                            arrow={false}
                            open={openDD === item.id}
                            onOpenChange={handleOpenDD}
                            content={
                              <div key={index} className="">
                                <p
                                  onClick={() => {
                                    setOpenDD(null);
                                    if (item.draggable) {
                                      setItem(item.id);
                                      setTemplateDetails(false);
                                    } else {
                                      setTemplateDetails(true);
                                    }
                                    setLayerButton(!layerButton);
                                    if (
                                      currentTime >= item.starttime &&
                                      currentTime <= item.stoptime
                                    ) {
                                    } else {
                                      setSeekLayer(item.starttime);
                                    }
                                  }}
                                  className=" text-[13px]  px-6 py-2 rounded-sm cursor-pointer  text-left font-semibold"
                                >
                                  Edit Layer
                                </p>
                                <p
                                  onClick={() => {
                                    let layerID = layerContent[index].id;
                                    let idx = canvaContent.findIndex(
                                      (item) => item.id === layerID
                                    );
                                    canvaContent.splice(idx, 1);
                                    setDeleted(true);
                                    undoRedoAction();
                                    setItem(null);
                                    setIndex(null);
                                    handleContent([...canvaContent]);
                                  }}
                                  className=" px-6 text-[13px] py-2 rounded-sm cursor-pointer text-left font-semibold"
                                >
                                  Remove Layer
                                </p>
                              </div>
                            }
                            trigger="click"
                          >
                            <div
                              className="cursor-pointer pt-1"
                              onClick={() => {
                                if (
                                  !canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ].visibility
                                ) {
                                  return;
                                }
                                setOpenDD(layerContent[index].id);
                              }}
                            >
                              <Dots
                                stroke={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                    <Divider className=" !m-0" />
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
          {!timeSetting && currLayer && (
            <div
              className="h-[calc(100vh-117px)]  overflow-auto
            scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] font-sans"
            >
              {layerContent
                .filter((item, index) => {
                  return (
                    currentTime >= item.starttime &&
                    currentTime <= item.stoptime
                  );
                })
                .map((item, index) => (
                  <div
                    key={index}
                    className="cursor-move"
                    style={{
                      border:
                        itemSelected === item.id ? "1px solid white" : "none",
                      borderImage:
                        item.id === itemSelected
                          ? "linear-gradient(to right, #A6FFCB, #12D8FA, #1FA2FF)  1 / 1 / 0 stretch"
                          : "none",
                    }}
                    draggable
                    onDragStart={(e) => {
                      dragItem.current = item.id;
                    }}
                    onDragEnter={(e) => {
                      dragOverItem.current = item.id;
                    }}
                    onDragEnd={() => {
                      if (
                        dragOverItem.current === null ||
                        dragItem.current === null ||
                        dragOverItem.current === dragItem.current
                      ) {
                        return;
                      }
                      const findIndexesByIds = (array, id1, id2) => {
                        const index1 = array.findIndex((obj) => obj.id === id1);
                        const index2 = array.findIndex((obj) => obj.id === id2);
                        return [index1, index2];
                      };
                      const [index1, index2] = findIndexesByIds(
                        canvaContent,
                        dragItem.current,
                        dragOverItem.current
                      );
                      if (dragItem.current === itemSelected) {
                        setZIndex(canvaContent[index2].zindex);
                      }
                      if (dragOverItem.current === itemSelected) {
                        setZIndex(canvaContent[index1].zindex);
                      }
                      let z1 = canvaContent[index1].zindex;
                      canvaContent[index1].zindex = canvaContent[index2].zindex;
                      canvaContent[index2].zindex = z1;
                      dragItem.current = null;
                      dragOverItem.current = null;
                      // setLayerContent([..._canvaContent]);
                      handleContent(canvaContent);
                      undoRedoAction();
                    }}
                  >
                    <div className="flex justify-between font-sans h-[50px]">
                      <div className="flex pl-2">
                        <div className="pt-4 pl-2 mr-2">
                          {item.type === "text" ? (
                            <Text1 height="22px" width="22px" />
                          ) : item.type === "label" ? (
                            <Text height="22px" width="22px" />
                          ) : item.type === "assets" ? (
                            <Video height="22px" width="22px" />
                          ) : item.type === "hotspot" ? (
                            <Hotspot height="22px" width="22px" />
                          ) : item.type === "button" ? (
                            <ButtonImg height="22px" width="22px" />
                          ) : (
                            <OptionsList height="22px" width="22px" />
                          )}
                        </div>
                        <div
                          className="text-[13px] truncate"
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            className="w-[140px]"
                            onDoubleClick={() => {
                              if (modalOpen) {
                                return;
                              }
                              setLayerDisabled(item.id);
                              fieldEnabled.current = true;
                              document.getElementById(item.id + "L").focus();
                            }}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "100%",
                              zIndex: 10000,
                              display:
                                layerDisabled !== item.id ? "block" : "none",
                            }}
                          ></div>
                          <Input
                            id={item.id + "L"}
                            disabled={layerDisabled !== item.id}
                            autoFocus="true"
                            value={item.layername}
                            onClick={() => {
                              fieldEnabled.current = true;
                            }}
                            onChange={(e) => {
                              let idx = canvaContent.findIndex(
                                (obj) => obj.id === item.id
                              );
                              let flag = false;
                              for (let i = 0; i < canvaContent.length; i++) {
                                if (canvaContent[i].id !== item.id) {
                                  if (
                                    canvaContent[i].layername === e.target.value
                                  ) {
                                    setIsRight(false);
                                    flag = true;
                                    break;
                                  }
                                }
                              }
                              if (!flag) {
                                setIsRight(true);
                              }
                              canvaContent[idx].layername = e.target.value;
                              handleContent([...canvaContent]);
                            }}
                            onBlur={() => {
                              setLayerDisabled(-1);
                              let idx = canvaContent.findIndex(
                                (obj) => obj.id === item.id
                              );
                              if (
                                item.layername === undefined ||
                                item.layername.trim() === ""
                              ) {
                                canvaContent[idx].layername =
                                  item.type + "_" + item.id;
                                handleContent([...canvaContent]);
                                setIsRight(true);
                              }
                              for (let i = 0; i < canvaContent.length; i++) {
                                if (canvaContent[i].id !== item.id) {
                                  if (
                                    canvaContent[i].layername ===
                                    canvaContent[idx].layername
                                  ) {
                                    if (!modalOpen) {
                                      setModalOpen(true);
                                      error(
                                        item.id + "L",
                                        "The Layer Name should be unique.",
                                        "Please change the layer name and ensure that it is unique."
                                      );
                                    }
                                    setIsModalOpen(true);
                                    setLayerDisabled(item.id);
                                    break;
                                  }
                                }
                              }
                              fieldEnabled.current = false;
                            }}
                            className={`${
                              layerDisabled !== item.id
                                ? "!cursor-default border-none !p-0"
                                : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
                            } !resize-none overflow-y-auto scrollbar w-[140px]  h-9 text-[13px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mt-4 mr-3 cursor-pointer">
                          <div
                            height="19px"
                            width="20px"
                            onClick={() => {
                              canvaContent[
                                canvaContent.findIndex(
                                  (obj) => obj.id === item.id
                                )
                              ].visibility =
                                !canvaContent[
                                  canvaContent.findIndex(
                                    (obj) => obj.id === item.id
                                  )
                                ].visibility;
                              handleContent([...canvaContent]);
                              if (itemSelected === item.id) {
                                setItem(null);
                              }
                            }}
                          >
                            {item.visibility ? (
                              <VisON fill="#ffffff" />
                            ) : (
                              <VisOFF fill="#ffffff" />
                            )}
                          </div>
                        </div>
                        <div className="mt-[17px] mr-3 cursor-pointer">
                          <div
                            height="19px"
                            width="20px"
                            onClick={() => {
                              let idx = canvaContent.findIndex(
                                (obj) => obj.id === item.id
                              );
                              if (!canvaContent[idx].visibility) {
                                return;
                              }
                              canvaContent[idx].draggable =
                                !canvaContent[idx].draggable;
                              let flag = true;
                              let count = 0;
                              canvaContent.forEach((item, index) => {
                                if (
                                  currentTime >= item.starttime &&
                                  currentTime <= item.stoptime
                                ) {
                                  count++;
                                  flag = canvaContent[index].draggable;
                                }
                              });
                              for (let i = 0; i < canvaContent.length; i++) {
                                if (
                                  currentTime >= canvaContent[i].starttime &&
                                  currentTime <= canvaContent[i].stoptime
                                ) {
                                  count--;
                                  if (currLocks.currState === -1) {
                                    if (canvaContent[i].draggable !== flag) {
                                      setCurrLocks({
                                        currState: 0,
                                        prevState: -1,
                                      });
                                      break;
                                    }
                                  } else if (currLocks.currState === 1) {
                                    if (canvaContent[i].draggable !== flag) {
                                      setCurrLocks({
                                        currState: 0,
                                        prevState: 1,
                                      });
                                      break;
                                    }
                                  } else {
                                    if (canvaContent[i].draggable !== flag) {
                                      break;
                                    }
                                    if (
                                      count === 0 &&
                                      canvaContent[i].draggable === flag
                                    ) {
                                      setCurrLocks(
                                        flag
                                          ? { currState: -1, prevState: 0 }
                                          : { currState: 1, prevState: 0 }
                                      );
                                    }
                                  }
                                }
                              }
                              handleContent([...canvaContent]);
                              if (itemSelected === item.id) {
                                setItem(null);
                              }
                            }}
                          >
                            {item.draggable ? (
                              <Unlock
                                fill={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            ) : (
                              <Lock
                                fill={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="template-popover mt-3 mr-4">
                          <Popover
                            placement="bottomLeft"
                            arrow={false}
                            open={openDD === item.id}
                            onOpenChange={handleOpenDD}
                            content={
                              <div key={index} className="">
                                <p
                                  onClick={() => {
                                    setOpenDD(null);
                                    if (item.draggable) {
                                      setItem(item.id);
                                      setTemplateDetails(false);
                                    } else {
                                      setTemplateDetails(true);
                                    }
                                    setLayerButton(!layerButton);
                                    if (
                                      currentTime >= item.starttime &&
                                      currentTime <= item.stoptime
                                    ) {
                                    } else {
                                      setSeekLayer(item.starttime);
                                    }
                                  }}
                                  className=" text-[13px]  px-6 py-2 rounded-sm cursor-pointer  text-left font-semibold"
                                >
                                  Edit Layer
                                </p>
                                <p
                                  onClick={() => {
                                    let layerID = item.id;
                                    let idx = canvaContent.findIndex(
                                      (item) => item.id === layerID
                                    );
                                    canvaContent.splice(idx, 1);
                                    setDeleted(true);
                                    undoRedoAction();
                                    setItem(null);
                                    setIndex(null);
                                    handleContent(canvaContent);
                                  }}
                                  className=" px-6 text-[13px] py-2 rounded-sm cursor-pointer text-left font-semibold"
                                >
                                  Remove Layer
                                </p>
                              </div>
                            }
                            trigger="click"
                          >
                            <div
                              className="cursor-pointer pt-1"
                              onClick={() => {
                                if (
                                  !canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ].visibility
                                ) {
                                  return;
                                }
                                setOpenDD(item.id);
                              }}
                            >
                              <Dots
                                stroke={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                    <Divider className=" !m-0" />
                  </div>
                ))}
            </div>
          )}
          {timeSetting && (
            <div className=" font-sans">
              <InfiniteScroll
                dataLength={timming.length}
                next={fetchMoreTimeData}
                hasMore={hasMoreTime}
                height={window.innerHeight - 120}
              >
                {timming.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      border:
                        itemSelected === item.id ? "1px solid white" : "none",
                      borderImage:
                        item.id === itemSelected
                          ? "linear-gradient(to right, #A6FFCB, #12D8FA, #1FA2FF)  1 / 1 / 0 stretch"
                          : "none",
                    }}
                  >
                    <div className="flex justify-between">
                      <div className="flex pl-2">
                        <div className="pt-4 pl-2">
                          {item.type === "text" ? (
                            <Text1 height="22px" width="22px" />
                          ) : item.type === "label" ? (
                            <Text height="22px" width="22px" />
                          ) : item.type === "assets" ? (
                            <Video height="22px" width="22px" />
                          ) : item.type === "hotspot" ? (
                            <Hotspot height="22px" width="22px" />
                          ) : item.type === "button" ? (
                            <ButtonImg height="22px" width="22px" />
                          ) : (
                            <OptionsList height="22px" width="22px" />
                          )}
                        </div>
                        <div
                          className={`ml-2 pl-4 py-3 pt-[17px] text-[13px] truncate text-left w-[115px] overflow-hidden h-[50px]`}
                        >
                          {item.layername === undefined ||
                          item.layername.trim() === ""
                            ? item.type + "_" + item.id
                            : item.layername}
                        </div>
                      </div>
                      <div className="mt-3 mr-1  flex ">
                        <InputNumber
                          className="timer0 !bg-[#050506] !border-none !shadow-none  !px-0  w-14 "
                          // className="w-12 mb-2"
                          // className="editor-input !px-0  w-16 mb-2"
                          type="number"
                          defaultValue={0}
                          min={0}
                          value={item.starttime}
                          formatter={(value) =>
                            `${value}`.replace(
                              /^(-)*(\d+)\.(\d{1,3}).*$/,
                              "$1$2.$3"
                            )
                          }
                          onClick={() => {
                            fieldEnabled.current = true;
                          }}
                          onStep={() => {
                            fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            if (item.starttime === null) {
                              canvaContent[index].starttime = 0;
                              handleContent([...canvaContent]);
                            } else if (item.starttime > item.stoptime) {
                              canvaContent[index].stoptime =
                                canvaContent[index].starttime + 1;
                              handleContent([...canvaContent]);
                            }
                            fieldEnabled.current = false;
                          }}
                          onChange={(value) => {
                            canvaContent[index].starttime = value;
                            handleContent([...canvaContent]);
                          }}
                        />
                        <div className="pt-1 px-2">/</div>
                        <InputNumber
                          className="timer !bg-[#050506] !shadow-none border-none  !px-0  w-14 "
                          //  className="w-12 mb-2"
                          // className="editor-input !px-0  w-16 mb-2"
                          type="number"
                          defaultValue={1}
                          value={item.stoptime}
                          formatter={(value) =>
                            `${value}`.replace(
                              /^(-)*(\d+)\.(\d{1,3}).*$/,
                              "$1$2.$3"
                            )
                          }
                          onClick={() => {
                            fieldEnabled.current = true;
                          }}
                          onStep={() => {
                            fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            if (
                              item.stoptime === null ||
                              item.stoptime < 0 ||
                              item.stoptime < item.starttime
                            ) {
                              canvaContent[index].stoptime = item.starttime + 1;
                              handleContent([...canvaContent]);
                            }
                            fieldEnabled.current = false;
                          }}
                          onChange={(value) => {
                            canvaContent[index].stoptime = value;
                            handleContent([...canvaContent]);
                          }}
                        />
                      </div>
                    </div>
                    <Divider className=" !m-0" />
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
      )}
      {settingButton && (
        <div className="mt-6 mb-2 font-sans leading-5">
          <div>
            <div className="ml-3 text-left font-semibold mb-1 leading-6">
              Global Settings
            </div>
            <Divider className=" !m-0" />
          </div>
          <div
            className={`h-[calc(100vh-117px)]  overflow-auto
      scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]`}
          >
            <div className="ml-3 mr-2">
              <div className="flex justify-between mt-3">
                <div className="text-left font-normal text-[14px]">
                  Video Timeline
                </div>
                <div className="flex editor-switch">
                  <Switch
                    size="small"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={timeLine}
                    onChange={(checked) => {
                      setTimeLine(checked);
                      setShowDialog(true);
                    }}
                  />
                </div>
              </div>
              <div className="text-[13px] text-left mt-2 mb-3">
                Disable timeline will not allow viewers to drag the timeline
                although it is visible.
              </div>
            </div>
            <Divider className=" !m-0" />
            <div className="ml-3 mr-2">
              <div className="flex justify-between mt-3 editor-switch">
                <div className="text-left font-normal text-[14px]">
                  Video Auto Play
                </div>
                <div className="flex">
                  <Switch
                    size="small"
                    checked={autoPlay}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      setAutoPlay(checked);
                      setShowDialog(true);
                    }}
                  />
                </div>
              </div>
              <div className="text-[13px] text-left mt-2 mb-3">
                Auto play will enable video to restart automatically
              </div>
            </div>
            <Divider className=" !m-0" />
            <div className="ml-3 mr-2">
              <div className="flex justify-between mt-3">
                <div className="text-left font-normal text-[14px]">
                  Data Storage
                </div>
                <div className="flex editor-switch">
                  <Switch
                    size="small"
                    checked={storeData}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      setStoreData(checked);
                      setShowDialog(true);
                    }}
                  />
                </div>
              </div>
              <div className="text-[13px] text-left mt-2 mb-3">
                Store all user actions and input provided by the user in the
                video
              </div>
            </div>
            <Divider className=" !m-0" />
            {/* <div className="ml-3 mr-2">
              <div className="flex justify-between my-3 editor-switch">
                <div className="text-left font-normal text-[14px]">
                  Background Music
                </div>
                <Switch
                  size="small"
                  checked={playbgmusic}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => {
                    setPlaybgmusic(checked);
                    setShowDialog(true);
                  }}
                />
              </div>
              {audioData?.length > 0 && (
                //<div className="mt-2 mb-3">
                <div className="audio-player-container">
                  <div className="audio-controls">
                    <audio
                      className="audio-element"
                      controls
                      src={
                        pathName === "createTemplate"
                          ? `${templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${templateInfo[0]?.TEMPLATEID}/CONTENT/${audioData[0].name}`
                          : `${templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${templateInfo[0]?.TEMPLATEID}/CONTENT/${audioData[0].name}`
                      }
                    ></audio>
                  </div>
                </div>
              )}
              <div className="flex">
                <input
                  type="file"
                  accept="audio/*"
                  onChange={(e) => {
                    handleUpload(e, "audio");
                    setShowDialog(true);
                  }}
                  ref={audioInputRef}
                  style={{ display: "none" }}
                />
                <SimpleButton
                  className="bg-[#056DE8] border-none text-[#ffffff] rounded-none"
                  label="Browse"
                  onClick={() => audioInputRef.current.click()}
                />
              </div>
              <div className="flex my-2">
                <div className="mt-[2px]">
                  <input
                    type="checkbox"
                    style={{
                      height: "18px",
                      width: "18px",
                      accentColor: "black",
                    }}
                    checked={musicLoop}
                    onChange={(e) => {
                      setMusicLoop(e.target.checked);
                      setShowDialog(true);
                      console.log(e.target.checked);
                    }}
                  />
                </div>
                <div className="ml-2">Play audio in loop</div>
              </div>
            </div>
            <Divider className=" !m-0" /> */}
            <div className="ml-3 mr-2 text-left">
              <div className="mt-3">
                <div className="font-normal text-[14px]">
                  Add Hidden Variable
                </div>
              </div>
              <div className="text-[13px] mt-2 mb-2">
                Store all user actions and input provided by the user in the
                video
              </div>
              <div className="mb-2 text-[14px]">name, age, phone number</div>
              <MultiInput
                optionsArray={variableData}
                setOptionsArray={setVariableData}
                PopButton={"Add Hidden Variables"}
                setShowDialog={setShowDialog}
                setButton={"Add Variables"}
                header={"Add Hidden Variables"}
                desc={
                  "Add multiple hidden variables in curly braces {} separated by comma"
                }
                col1={"Variable"}
                col2={"Column_Name"}
                fieldEnabled={fieldEnabled}
              />
            </div>
            <Divider className="!m-0" />
            <div className="ml-3 mr-2 text-left">
              <div className="mt-3">
                <div className="font-normal text-[14px]">Report Columns</div>
              </div>
              <div className="text-[13px] mt-2 mb-2">
                Configure columns as per need required in the MIS report in the
                video
              </div>

              <Popover
                placement="leftBottom"
                arrow={false}
                open={open && settingButton}
                onOpenChange={handleOpenChange}
                content={
                  <div
                    className="bg-[#1e1e1e] w-[323px] absolute right-[8px] bottom-[0px] overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]   @apply {tempMis.length>0 ? 'custom-height-400' : 'custom-height-200'}"
                  >
                    <div className="flex justify-between p-4">
                      <div className="text-[#fff] font-semibold">
                        MIS COLUMNS
                      </div>
                      <img
                        className="cursor-pointer"
                        src={circlecross}
                        alt="cross"
                        onClick={hide}
                      />
                    </div>
                    {tempMis.length > 0 ? (
                      <>
                        <Divider className=" !m-0" />
                        <div className="text-[#DFDFDF] text-[13px] p-3">
                          Check to add columns to be displayed in MIS report
                          with column name for all available variables.
                        </div>
                        <div className="flex ml-8 p-3 text-[#fff] font-semibold">
                          <div className="mr-16">MIS</div>
                          <div className="ml-3">Column Name</div>
                        </div>
                        <div className="pl-3 h-[150px] overflow-y-auto ">
                          {tempMis.map((obj, index) => (
                            <div className="flex mb-2" key={index}>
                              <Checkbox
                                className="ml-9 mr-20"
                                checked={obj.checked}
                                onChange={(e) => {
                                  let updatedData = [...tempMis];
                                  updatedData[index].checked = e.target.checked;
                                  setTempMis([...updatedData]);
                                }}
                              />
                              <div className="text-[#fff]">{obj.layerName}</div>
                            </div>
                          ))}
                        </div>
                        <div className=" w-full">
                          <div className="flex justify-center">
                            <Button
                              className="bg-[#0958d9] text-[#fff] border-none  mt-2 mb-2  w-[150px]"
                              onClick={() => {
                                setMisData([...tempMis]);
                                setShowDialog(true);
                                hide();
                              }}
                            >
                              Set MIS Columns
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className=" text-[14px] text-center text-[#B1B1B1] h-[50px] ">
                        No fields available in the video for MIS
                      </div>
                    )}
                  </div>
                }
                trigger="click"
              >
                <div
                  className="cursor-pointer text-[#5A9CFF] mb-3 text-[13px] "
                  onClick={() => {
                    let data = canvaContent
                      .filter((layer, index) => {
                        if (layer.type === "video" || layer.type === "image") {
                          return false;
                        }
                        return true;
                      })
                      .map((layer, index) => {
                        if (layer.type === "label") {
                          const inputString = layer.name;
                          const regex = /{([^}]+)}/g;
                          const matches = inputString.match(regex);
                          console.log(matches);
                          const match =
                            matches !== null
                              ? matches[0].slice(1, -1).trim() !== ""
                                ? true
                                : false
                              : false;
                          console.log(match);
                          if (match) {
                            let obj = {
                              layerName: layer.layername,
                              checked: true,
                              value: "",
                              type: "video",
                              variableName: matches,
                            };
                            return obj;
                          }
                        } else {
                          let obj = {
                            layerName:
                              layer.type === "radio"
                                ? layer.name
                                : layer.layername,
                            checked: true,
                            value: "",
                            type: "video",
                            variableName: null,
                          };
                          return obj;
                        }
                      });
                    let hiddenVariableData = variableData.map(
                      (variable, index) => {
                        let obj = {
                          layerName: variable.Column_Name,
                          checked: true,
                          value: "",
                          type: "hidden",
                          variableName: variable.Variable,
                        };
                        return obj;
                      }
                    );
                    data = data.filter((item) => item !== undefined);
                    data = [...data, ...hiddenVariableData];
                    const uniqueArray = [
                      ...new Set(data.map(JSON.stringify)),
                    ].map(JSON.parse);
                    // if (misData.length !== 0) {
                    //   const commonObjects = misData.filter((obj1) => {
                    //     const obj2 = uniqueArray.find(
                    //       (obj2) => obj2.layerName === obj1.layerName
                    //     );
                    //     return obj2 && obj1.checked !== obj2.checked;
                    //   });

                    //   commonObjects.forEach((obj) => {
                    //     const matchingObject = uniqueArray.find(
                    //       (obj2) => obj2.layerName === obj.layerName
                    //     );
                    //     matchingObject.checked = obj.checked;
                    //   });
                    // }
                    // localStorage.setItem("misData", JSON.stringify(uniqueArray));
                    if (misData.length !== 0) {
                      // Create a map of uniqueArray objects by layerName for faster access
                      const uniqueMap = new Map(
                        uniqueArray.map((obj) => [obj.layerName, obj])
                      );

                      // Iterate through misData
                      misData.forEach((obj1) => {
                        const obj2 = uniqueMap.get(obj1.layerName);
                        if (obj2 && obj1.checked !== obj2.checked) {
                          obj2.checked = obj1.checked; // Update the checked property
                        }
                      });
                    }
                    setTempMis(uniqueArray);
                  }}
                >
                  Add columns
                </div>
              </Popover>
            </div>
          </div>
        </div>
      )}
      {templateDetails && !layerButton && !settingButton && !musicButton && (
        <Collapse
          className="tempInfo"
          bordered="true"
          destroyInactivePanel="true"
          accordion
          activeKey={infokey}
          expandIconPosition="end"
          onChange={(keys) => {
            setInfokey(keys.length > 0 ? [keys[keys.length - 1]] : []);
            console.log("ALert", keys);
          }}
          items={items}
        />
      )}
    </div>
  );
};

export default RightSidebarDashboard;
