import React from "react";
import videojs from "video.js";
import "../Styling/VideoJS.css";
import "video.js/dist/video-js.css";
import idCreate from "../utils/uuid.js";
export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  function gcd(a, b) {
    if (b === 0) {
      return a;
    } else {
      return gcd(b, a % b);
    }
  }
  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if(props.templateInfo[0]===undefined){
      return;
    }
    let vidsrc = 
      props.pathName === "createTemplate"
        ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/SOURCE/video.mp4?id=${idCreate()}`
        : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/SOURCE/video.mp4?id=${idCreate()}`;

    let thumbnail = 
    props.pathName === "createTemplate"
        ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/SOURCE/thumbnail.png`
        : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/SOURCE/thumbnail.png`;
   
    let seeked = false;
    const options = {
      autoplay: false,
      controls: true,
      muted: true,
      //   responsive: true,
      fluid: true,
      fill: true,
      preferFullWindow: true,
      crossOrigin : "anonymous",

      controlBar: {
        fullscreenToggle: false,
        pictureInPictureToggle: false,
        currentTime: true,
        subsCapsButton: true,
        volumePanel: {
          inline: true,
          vertical: false,
        },
      },
      userActions: {
        click: false,
        doubleClick: false,
      },
      hotkeys: {
        muteKey: function (event) {},
        playPauseKey: function (event) {},
        fullscreenKey: function (event) {
          return event.which === 86;
        },
      },
      sources: [
        {
          src:
            vidsrc,
          type: "video/mp4",
        },
      ],
      // poster : thumbnail
    };
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      videoElement.crossOrigin = "anonymous";

      // videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");

        // onReady && onReady(player);
        playerRef.current = player;
        player.addClass("editor-player");
        player.getChild("vjs-play-control",{clickHandler:function(){
          console.log("Hi Boy !");
        }})
        console.log(player.controlBar);
        player.on("play", () => {
          // videojs.log("Play State");
          // props.bgMusic.current.play();
          props.setPlay(false);
          props.setDeleted(true);
          props.handleItem(null);
          props.setTemplateDetails(true);
        });
        player.on("pause", () => {
          props.setPlay(true);
        });
        player.on("loadedmetadata", () => {
          let GCD = gcd(player.videoWidth(), player.videoHeight());
          //   props.setAspectRatio((player.videoWidth()/GCD)+":"+(player.videoHeight()/GCD));

          props.setRatio(player.videoWidth() / player.videoHeight());
          props.setEditorLoader(false);
          let minutes = Math.floor(player.duration() / 60);
          let remainingSeconds = Math.round(player.duration() % 60);

          let formattedMinutes = String(minutes).padStart(2, "0");
          let formattedSeconds = String(remainingSeconds).padStart(2, "0");

          let result = `${formattedMinutes}:${formattedSeconds}`;

          props.setTotalTime(result);
        });
        player.on("waiting", () => {
        });
        player.on('volumechange', () => {
        //   if(props.bgMusic!==null && props.bgMusic.current!==null){
            
        //     props.bgMusic.current.volume = player.volume();
        //     if(player.muted()){
        //     props.bgMusic.current.muted = true;
        //   }
        //   else{
        //    props.bgMusic.current.muted = false;
        //   }
          
        // }
        var videoes = document.querySelectorAll(".inVideo");
        for (let i = 0; i < videoes.length; i++) {
          videoes[i].volume = player.volume();
          if(player.muted()){
            videoes[i].muted = true;
          }
          else{
           videoes[i].muted = false;
          }
        }
        var audioes = document.querySelectorAll(".inAudio");
        for (let i = 0; i < audioes.length; i++) {
          audioes[i].volume = player.volume();
          if(player.muted()){
            audioes[i].muted = true;
          }
          else{
           audioes[i].muted = false;
          }
        }
        });
        player.on("seeked", () => {
          props.setRightClicked("");
          
          
          // var videoes = document.querySelectorAll(".inVideo");
          // for (let i = 0; i < videoes.length; i++) {
          //   let exit = videoes[i].attributes.etime.nodeValue;
          //   let entry = videoes[i].attributes.stime.nodeValue;
          //   if (player.currentTime() >= entry && player.currentTime() <= exit) {
          //     videoes[i].currentTime = player.currentTime() - entry;
          //     if (player.paused()) {
          //       videoes[i].pause();
          //     }
          //   } else if (player.currentTime() <= entry) {
          //     videoes[i].pause();
          //     videoes[i].currentTime = 0;
          //   } else {
          //     videoes[i].pause();
          //     // videoes[i].currentTime = 0;
          //   }
          // }
          // var audioes = document.querySelectorAll(".inAudio");
          // for (let i = 0; i < audioes.length; i++) {
          //   let exit = audioes[i].attributes.etime.nodeValue;
          //   let entry = audioes[i].attributes.stime.nodeValue;
          //   console.log(exit,entry,player.currentTime());
          //   console.log("Result....................");
          //   if (player.currentTime() >= entry && player.currentTime() <= exit) {
          //     console.log("Result....................",player.paused());
          //     seeked = true;
          //   } 
          // }
        });
        player.on("dispose", () => {
          // videojs.log("player will dispose");
        });
        player.on("timeupdate", () => {
          // videojs.log(player.videoHeight(), player.videoWidth())
          player
            .getChild("ControlBar")
            .getChild("remainingTimeDisplay")
            .el().innerHTML = player.currentTime().toFixed(2);
          
          // if(props.bgMusic.current!==null){
          //   if(player.muted()){
          //     props.bgMusic.current.muted = true;
          //   }
          //   else{
          //     props.bgMusic.current.muted =  false;
          //   }
          // }
          // var videoes = document.querySelectorAll(".inVideo");
          
          // for (let i = 0; i < videoes.length; i++) {
          //   let exit = videoes[i].attributes.etime.nodeValue;
          //   let entry = videoes[i].attributes.stime.nodeValue;
          //   if (player.currentTime() >= entry && player.currentTime() <= exit) {
          //     if (videoes[i].paused && !player.paused()) videoes[i].play();
          //     if(player.muted()){
          //       videoes[i].muted=  true;
          //     }
          //     else{
          //       videoes[i].muted = false;
          //     }
          //   } else {
          //     videoes[i].pause();
          //     // videoes[i].currentTime = 0;
          //   }
          // }
          // var audioes = document.querySelectorAll(".inAudio");
          // for (let i = 0; i < audioes.length; i++) {
          //   let exit = audioes[i].attributes.etime.nodeValue;
          //   let entry = audioes[i].attributes.stime.nodeValue;
          //   console.log(exit,entry,player.currentTime());
          //   if (player.currentTime() >= entry && player.currentTime() <= exit) {
          //     // if (audioes[i].paused && (seeked && !player.paused())){
          //     //   audioes[i].play();
          //     //   seeked = false;
          //     // } 

          //     if(seeked){
          //       if(!player.paused()){
          //         audioes[i].play();
          //         if(player.muted()){
          //           audioes[i].muted=  true;
          //         }
          //         else{
          //           audioes[i].muted = false;
          //         }
          //       }
          //       else{
          //         audioes[i].pause();
          //       }
          //       seeked = false;
          //     }
          //     else{
          //       if(!player.paused()){
          //         audioes[i].play();
          //         if(player.muted()){
          //           audioes[i].muted=  true;
          //         }
          //         else{
          //           audioes[i].muted = false;
          //         }
          //       }
          //     }
          
          //   }
          //   else {
          //     audioes[i].pause();
          //   }
          // }

          // if(props.bgMusic!==null && props.bgMusic.current!==null){
          //   if(player.paused()){
          //       props.bgMusic.current.pause();
          //   }
          //   else{
          //      props.bgMusic.current.play().then(() => {
          //       // Audio playback successful
          //     })
          //     .catch((error) => {
          //       console.log('Error playing Bgaudio:', error.message);
          //     });;
          //      if(player.muted()){
          //       props.bgMusic.current.muted = true;
          //      }
          //     else{
          //      props.bgMusic.current.muted = false;
          //      }
          //   }
            
            
            
          // }
        var audioes = document.querySelectorAll(".inAudio");
          for (let i = 0; i < audioes.length; i++) {
            let exit  = audioes[i].attributes.etime.nodeValue;
            let entry = audioes[i].attributes.stime.nodeValue;
            console.log(exit,entry,player.currentTime());
            
            if (player.currentTime() >= entry && player.currentTime() <= exit) {
                if(player.paused()){
                  audioes[i].pause();
                }
                else{
                  audioes[i].play().then(() => {
                    // Audio playback successful
                  })
                  .catch((error) => {
                    console.log('Error playing audio:', error.message);
                  });;
                  if(player.muted()){
                   audioes[i].muted = true;
                }
                 else{
                  audioes[i].muted = false;
                 }
                }
            }
            else {
              audioes[i].currentTime  = 0;
              audioes[i].pause();
            }
          }
          var videoes = document.querySelectorAll(".inVideo");
          for (let i = 0; i < videoes.length; i++) {
            let exit  = videoes[i].attributes.etime.nodeValue;
            let entry = videoes[i].attributes.stime.nodeValue;
            console.log(exit,entry,player.currentTime());
            if (player.currentTime() >= entry && player.currentTime() <= exit) {
               if(player.paused()){
                  videoes[i].pause();
                }
                else{
                  try{
                  videoes[i].play();
                  if(player.muted()){
                    videoes[i].muted = true;
                  }
                  else{
                    videoes[i].muted = false;
                   }
                  }
                  catch{
                    
                  }
                }
            }
            else {
              videoes[i].currentTime  = 0;
              videoes[i].pause();
            }
          }
          props.setSeeked(true);
        });
        // videojs.log(player.videoHeight(), player.videoWidth())
      }));
      player.on("timeupdate", () => {
        props.setCurrentTime(player.currentTime().toFixed(4));
      });
      // player.on("mouseenter",()=>{
      //   player.controlBar.show();
      //   console.log("Wanted",player.controlBar);
      // });
      player.on("replay",()=>{
        // if(props.bgMusic.current!==null){
        //   props.bgMusic.current.currentTime = 0;
        //   // props.bgMusic.current.play();
        // }
      });
      player.on("ended",()=>{
        // if(props.bgMusic.current!==null){
        //   props.bgMusic.current.pause();
        // }
        var audioes = document.querySelectorAll(".inAudio");
          for (let i = 0; i < audioes.length; i++) {
            audioes[i].currentTime = 0;
            audioes[i].pause();
          }
        });
        var videoes = document.querySelectorAll(".inVideo");
        for (let i = 0; i < videoes.length; i++) {
          videoes[i].currentTime = 0;
          videoes[i].pause();
        }
      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      // console.log(player);
      player.autoplay(options.autoplay);
      player.src(options.sources);
      player.breakpoints({
        medium: 500,
      });
    }
  }, [videoRef, props.templateInfo,props.tempVideo]);

  //   React.useEffect(()=>{
  //     const player = playerRef.current;
  //     console.log(player.getChild("ControlBar").getChild("progressControl").el());
  //     player.getChild("ControlBar").getChild("progressControl").el().classList.toggle("showTimeline");
  //   },[props.timeline])

  //   React.useEffect(()=>{
  //     const player = playerRef.current;
  //     player
  //         .getChild("ControlBar")
  //         .getChild("currentTimeDisplay")
  //         .el().classList.toggle("show");

  //       player
  //         .getChild("ControlBar")
  //         .getChild("durationDisplay")
  //         .el().classList.toggle("show");

  //       player
  //         .getChild("ControlBar")
  //         .getChild("timeDivider")
  //         .el().classList.toggle("show");
  //   },[props.timeduration])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => {
    if (props.seekLayer !== null) {
      playerRef.current.currentTime(Number(props.seekLayer));
      props.setSeekLayer(null);
    }
  }, [props.seekLayer]);
  // console.log("Height : ", props.height);
  return (
    <div
      data-vjs-player
      crossOrigin="anonymous"
      key = "videomplayer"
      id="videomplayer"
      className="vjs-layout-medium"
      style={{
        // width : aspectRatio==="9:16"?"300px": aspectRatio==="1:1"?"500px":"600px",
        width:
          props.ratio === ""
            ? "600px"
            : props.ratio < 1
            ? "300px"
            : props.ratio === 1
            ? "500px"
            : "600px",
            position: "absolute",
            top : "40px",
      }}
      onMouseEnter={()=>{
        console.log("Enter Hi");
        const player = playerRef.current;
        if(player!== undefined)
        player.controlBar.show();
        // .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar
      }}
    >
      <div
        ref={videoRef}
        crossOrigin="anonymous"
        
      />
    </div>
  );
};
export default VideoJS;
