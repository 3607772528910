import { Select } from "antd";
import '../../common/styles/commonStyles.css'
const handleChange = (value, props) => {
  if (props.type === "industry") props.setIndustry(value);
  else if (props.type === "role") props.setRole(value);
  else if (props.type === "concern") props.setConcern(value);
  else if (props.type === "country") {
    props.setUserCountry(value);
  } else if (props.type === "state") {
    props.setUserState(value);
  } else {
    props.setUserCity(value);
  }
};

// This selector is used in OrganizationOnboarding.js and Support.js Pages
const GlobalSelecter = (props) => {
  
  return (
    <div className="global-selector">
      <Select
      placeholder=""
        disabled={!props.show}
        value={props.value}
        className="w-20"
        showSearch={true}
        style={{
          width: 350,
          // marginTop: 12,
          height: 40,
        }}
        onChange={(e) => handleChange(e, props)}
        options={props.data}
      />
    </div>
  );
};
export default GlobalSelecter;
