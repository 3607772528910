import React, { useState } from "react";
import arrowLeftBlack from "../../assests/commonIcons/common/arrowLeftBlack.svg";
import arrowLeftWhite from "../../assests/commonIcons/common/arrowLeftWhite.svg";

const AnimationButtonIcon = ({ label, onClick, image }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="flex items-center justify-start cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <button
        className={`rounded-full border-none p-1 transition-all duration-300 cursor-pointer ${
          isHovered ? "bg-[#1B5CCB]" : "bg-[#DAE8F2]"
        }`}
      >
        <div className="flex items-center">
          <img height="18" src={isHovered ? arrowLeftWhite : arrowLeftBlack} alt="back" />
        </div>
      </button>
      {/* <span className="text-black ml-2 text-sm">{label}</span> */}
      <span className={`${isHovered ? "text-[#1B5CCB]" : "text-[#3E4C62]"} ml-2 text-smm`}>
        {label}
      </span>
    </div>
  );
};

export default AnimationButtonIcon;
