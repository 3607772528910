import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// LIBRARY IMPORT **************
import { toast } from "react-toastify";
import { Col, Row, Form, Input, Spin, Divider } from "antd";

// COMPONENT/FUNCTION/IMAGE/CSS IMPORT **************
import { createSession } from "../../common/functions/Session";
import SimpleButton from "../../common/components/SimpleButton";
import Banner from "../common/Banner";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-logo.svg";
import "../../common/styles/userModule/LoginStyles.css";

// API AND REACT QUERY **************
import { useMutation } from "react-query";
import { loginCustomer } from "../../api/api_updateData";
import googleLogo from "../../assests/commonImages/google-logo.png";

const Logout = ({ forgotPasswordClicked, setForgotPasswordClicked }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // ********** STATES DEFINED ***************
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");

  const handleForgotPasswordClick = () => {
    // console.log(forgotPasswordClicked, "FP CLICKED IN LOGIn");
    setForgotPasswordClicked(true);
  };

  useEffect(() => {
    if (forgotPasswordClicked) {
      // Redirect to login page if the user click "Forgot Password"
      navigate(`${process.env.REACT_APP_BASE_PATH}/forgot-password`);
    } else {
      navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
    }
  }, [forgotPasswordClicked, navigate]);

  //calculates width of screen size
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth([window.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  /**
   * The code includes functions for handling form validation, toggling password visibility, and
   * updating the validPassword state based on the length of the password.
   * @param errorInfo - An object that contains information about the failed form validation, such as
   * the fields that failed validation and the error messages associated with them.
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateEmailAndPassword = () => {
    form.validateFields(["email"]);
  };

  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };

  const validPassword = password.length >= 8;

  // ******************** LOGIN API CALLING AND MUTATION STARTS **************************
  /**
   * The `handleLogin` function is responsible for handling the login process, including making an API
   * call to login the customer, storing session data in localStorage, and displaying success or error
   * messages.
   */
  const handleLogin = () => {
    setIsLoading(true);
    const custDataLogin = {
      custregid: email,
      password: password,
    };
    loginCustomerMutation(custDataLogin);
  };

  const { mutate: loginCustomerMutation } = useMutation(
    (custDataLogin) => loginCustomer(custDataLogin),
    {
      onSuccess: (custDataLogin) => {
        // debugger
        if (custDataLogin.STATUS === "1000") {
          const custData = JSON.parse(custDataLogin.DATA);
          console.log(custData, "CUST DATA");
          // debugger
          const sessionUserId = custData[0].CUSTOMERREGID;
          const sessionId = createSession(sessionUserId);
          localStorage.setItem("sessionId", sessionId);
          localStorage.setItem("sessionUserId", sessionUserId);
          setTimeout(() => {
            navigate(`${process.env.REACT_APP_BASE_PATH}/global-template`);
          }, 2000);
        } else if (custDataLogin.STATUS === "2000") {
          const custData = JSON.parse(custDataLogin.DATA);
          console.log(custData, "CUST DATA");
          toast.success(custDataLogin.DESCRIPTION);
          setTimeout(() => {
            navigate(`${process.env.REACT_APP_BASE_PATH}/getting-started-1`);
          }, 2000);
        } else {
          toast.error(custDataLogin.DESCRIPTION);
          setIsLoading(false);
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );
  // ******************** LOGIN API CALLING AND MUTATION ENDS **************************

  const redirectToGoogle = () => {
    var googleUrl = `${process.env.REACT_APP_BASE_PATH}/togoogle`;

    window.location.replace(googleUrl);
  };
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <div className="h-screen">
          <Row>
            {/* <Col span={width > 750 ? 16 : 24}> */}
            <Col span={width > 1024 ? 16 : 24}>
              <div className="form-wrapper email-verify">
                <div className="w-full">
                  <div className=" mb-8 ">
                    <div className="w-[200px] ml-auto mr-auto">
                      <img
                        src={newVspagyLogo}
                        alt="vspagyLogo"
                        className="w-full mb-4"
                      />
                    </div>
                    <div className="font-semibold text-center mt-2 text-[#151515]  text-[26px] select-none">
                      Login to Continue
                    </div>
                  </div>
                </div>

                <div>
                  <Form
                    form={form}
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                  >
                    <Form.Item
                      className="mb-8"
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email" },
                        {
                          pattern:
                            /^[A-Za-z0-9]+(\.[A-Za-z0-9]+)*@([A-Za-z]+\.)+[A-Za-z]{2,}$/, // Custom pattern for emails with only dots allowed
                          message: "Enter a valid email!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        className="h-[44px]  text-base"
                        placeholder="Email Id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      className="mb-8"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password",
                        },
                      ]}
                    >
                      <Input.Password
                        iconRender={(visible) =>
                          visible ? (
                            <span onClick={togglePasswordVisibility}>Hide</span>
                          ) : (
                            <span onClick={togglePasswordVisibility}>Show</span>
                          )
                        }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        visibilityToggle
                        className="cursor-pointer h-[44px] text-base"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <div className="flex justify-between mb-6">
                      {/* CHECKBOX CAN BE USED IN FUTURE */}

                      {/* <div className="flex gap-2 justify-center items-center">
                        <input type="checkbox" className=" h-4 w-4" />
                        <label className="text-sm font-normal text-[#000000]">
                          Keep me logged in
                        </label>
                      </div> */}

                      <div
                        onClick={handleForgotPasswordClick}
                        className="cursor-pointer hover:underline hover:font-medium text-[#1C68EB] text-sm font-normal"
                      >
                        Forgot Password
                      </div>
                    </div>
                    <Form.Item shouldUpdate>
                      {() => (
                        <SimpleButton
                          label="Submit"
                          type="primary"
                          htmlType="submit"
                          size="large"
                          className="!rounded-[4px] !text-[#ffffff] w-full"
                          disabled={!validPassword}
                          onClick={() => {
                            handleLogin();
                            validateEmailAndPassword();
                          }}
                        />
                      )}
                    </Form.Item>
                  </Form>
                </div>

                {/* ****************** TO BE USED IN FUTURE ******************* */}
                <Divider className="!border-[#CFCFCF] !border-[0.5px]" plain>
                  or
                </Divider>
                <div className="my-6">
                  <span
                    className="flex justify-center items-center h-[44px] select-none cursor-pointer rounded-[4px] bg-FCFCFC border border-[#E3E3E3] border-solid"
                    onClick={redirectToGoogle}
                  >
                    <span>
                      <img src={googleLogo} alt="google logo" />
                    </span>
                    <span className="text-base ml-3">Sign In with Google</span>
                  </span>
                </div>
                <div className="flex flex-col text-center text-base">
                  <span className="text-black font-normal">
                    Don't have an account?
                    <Link
                      to={`${process.env.REACT_APP_BASE_PATH}/signup`}
                      className="ml-2 text-[#155AD1] font-semibold "
                    >
                      Signup
                    </Link>
                  </span>
                </div>
              </div>
            </Col>

            <Col className="max-[750px]:hidden" span={8}>
              <Banner />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Logout;
