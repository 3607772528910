import { ReactComponent as Dashboard } from "../assests/commonIcons/sidebarIcons/dashboard.svg";
import { ReactComponent as TemplateLibraryImg } from "../assests/commonIcons/sidebarIcons/templateLibraryNew.svg";
import { ReactComponent as MyTemplatesImg } from "../assests/commonIcons/sidebarIcons/myTemplatesNew.svg";
import { ReactComponent as AssetImg } from "../assests/commonIcons/sidebarIcons/myAssets.svg";
import { ReactComponent as AnalyticImg } from "../assests/commonIcons/sidebarIcons/analytics.svg";
import { ReactComponent as BrandImg } from "../assests/commonIcons/sidebarIcons/icon-brand-page.svg";

const SidebarData = [
  {
    id: 1,
    title: "Dashboard",
    // path: `${process.env.REACT_APP_BASE_PATH}/dashboard`,
    path: "/user/dashboard",
    payload: <Dashboard />,
  },
  {
    id: 2,
    title: "Template Library",
    // path: "template-library",
    path: `${process.env.REACT_APP_BASE_PATH}/global-template`,
    payload: <TemplateLibraryImg />,
  },
  {
    id: 3,
    title: "My Templates",
    // path: "my-templates",
    path: `${process.env.REACT_APP_BASE_PATH}/user-template`,
    payload: <MyTemplatesImg />,
  },
  {
    id: 4,
    title: "Assets",
    // path: "assets",
    path: `${process.env.REACT_APP_BASE_PATH}/my-assets`,
    payload: <AssetImg />,
  },
  {
    id: 5,
    title: "Brand Content",
    // path: "assets",
    path: `${process.env.REACT_APP_BASE_PATH}/brand-page`,
    payload: <BrandImg />,
  },
  {
    id: 6,
    title: "Analytics",
    // path: "play-report",
    path: `${process.env.REACT_APP_BASE_PATH}/play-report`,
    payload: <AnalyticImg />,
  },
  // {
  //   id: 6,
  //   title: "Upload Assets",
  //   // path: "upload-assets",
  //   path: `${process.env.REACT_APP_BASE_PATH}/upload-assets`,
  //   payload: <DashboardImg />,
  // },
  // {
  //   id: 7,
  //   title: "My Assets",
  //   // path: "upload-assets",
  //   path: `${process.env.REACT_APP_BASE_PATH}/my-assets`,
  //   payload: <DashboardImg />,
  // },
  // {
  //   id: 7,
  //   title: "Global Template",
  //   // path: "template-library",
  //   path: `${process.env.REACT_APP_BASE_PATH}/global-template`,
  //   payload: <TemplateLibraryImg />,
  // },
  // {
  //   id: 8,
  //   title: "User Templates",
  //   // path: "my-templates",
  //   path: `${process.env.REACT_APP_BASE_PATH}/user-template`,
  //   payload: <MyTemplatesImg />,
  // },
];

export default SidebarData;
