import { Button, Divider, Input, Popover } from "antd";
import React from "react";
import { useState } from "react";
import plus from "../../assets/plus.svg";
import minus from "../../assets/minus.svg";
import circlecross from "../../assets/CircleCancel.svg";
import { useEffect } from "react";
function MultiInput(props) {
  const [openDD, setOpenDD] = useState(false);
  const [hiddenInputs, setHiddenInputs] = useState(props.optionsArray);
  const hidePop = () => {
    setOpenDD(false);
  };
  const handleOpenDD = (newOpen) => {
    setOpenDD(newOpen);
  };
  const handleName = (idx, value) => {
    let updatedInputs = [...hiddenInputs];
    updatedInputs[idx][props.col1] = value;
    setHiddenInputs([...updatedInputs]);
  };
  const handleValue = (idx, value) => {
    let updatedInputs = [...hiddenInputs];
    updatedInputs[idx][props.col2] = value;
    setHiddenInputs([...updatedInputs]);
  };
  const handleAddInput = () => {
    if (
      hiddenInputs[hiddenInputs.length - 1][props.col1] === "" ||
      hiddenInputs[hiddenInputs.length - 1][props.col2] === ""
    ) {
      return;
    }
    const newInput = {};
    newInput[props.col1] = "";
    newInput[props.col2] = "";
    setHiddenInputs([...hiddenInputs, newInput]);
  };
  const handleRemoveInput = (idx) => {
    if (hiddenInputs.length !== 1) {
      let updatedInputs = [...hiddenInputs];
      updatedInputs.splice(idx, 1);
      setHiddenInputs(updatedInputs);
    }
  };
  useEffect(() => {
    if (props.optionsArray!==undefined && props.optionsArray.length === 0) {
      const newInput = {};
    newInput[props.col1] = "";
    newInput[props.col2] = "";
      setHiddenInputs([{...newInput}]);
    } else {
      setHiddenInputs(props.optionsArray);
    }
  }, [props.optionsArray]);
  return (
    <Popover
      placement="leftTop"
      arrow={false}
      open={openDD}
      onOpenChange={handleOpenDD}
      content={
        <div className="bg-[#191919] w-[323px] h-[323px] absolute right-[8px] bottom-[-75px] overflow-auto  scrollbar-w-[2px] scrollbar-thumb-rounded-full
        scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]" style={{
          boxShadow : "rgba(0, 0, 0, 0.35) 0px 5px 15px"
        }}>
          <div className="flex justify-between p-4 pr-3">
            <div className="text-[#fff] font-semibold">{props.header}</div>
            <img
              className="cursor-pointer"
              src={circlecross}
              alt="cross"
              onClick={hidePop}
            />
          </div>
          <Divider  className="!m-0"/>
          <div className="text-[#DFDFDF] text-[13px] p-4">
            {props.desc}
          </div>
          <div className="flex p-4 pt-0 text-[#fff]  font-semibold">
            <div className="w-24 mr-10"> {props.col1}</div>
            <div className="w-24 mr-3"> {props.col2}</div>
          </div>
          <div className="pl-4 h-[100px] overflow-y-auto">
            {hiddenInputs !== undefined &&
              hiddenInputs.map((input, index) => (
                <div className="flex mb-2" key={input.index}>
                  <Input
                    className="w-24 mr-10 editor-input"
                    value={input[props.col1]}
                    onClick = {()=>{
                      props.fieldEnabled.current = true;
                    }}
                    onBlur ={()=>{
                      props.fieldEnabled.current = false;
                    }}
                    onChange={(e) => handleName(index, e.target.value)}
                  />
                  <Input
                    className="w-24 mr-3 editor-input"
                    value={input[props.col2]}
                    onClick = {()=>{
                      props.fieldEnabled.current = true;
                    }}
                    onBlur ={()=>{
                      props.fieldEnabled.current = false;
                    }}
                    onChange={(e) => handleValue(index, e.target.value)}
                  />
                  <img
                    className="cursor-pointer"
                    src={plus}
                    alt="plus"
                    onClick={handleAddInput}
                    style={{ marginLeft: 8 }}
                  />
                  {hiddenInputs.length > 1 && (
                    <img
                      className="cursor-pointer"
                      src={minus}
                      alt="minus"
                      onClick={() => handleRemoveInput(index)}
                      style={{ marginLeft: 8 }}
                    />
                  )}
                </div>
              ))}
          </div>
          <div className="absolute bottom-4 w-full">
            <div className="flex justify-center ">
              <Button
                className="bg-[#0958d9] text-[#fff] border-none  mt-4  w-[150px]"
                onClick={() => {
                  let updatedInputs = hiddenInputs.filter((input, index) => {
                    if (input[props.col1] !== "" && input[props.col2] !== "") {
                      return true;
                    }
                  });
                  props.setOptionsArray(updatedInputs);
                  props?.setShowDialog(true);
                  hidePop();
                }}
              >
                {props.setButton}
              </Button>
            </div>
          </div>
        </div>
      }
      trigger="click"
    >
      <div className="cursor-pointer text-[#5A9CFF] my-4 text-[13px]">
      {props.PopButton}
      </div>
    </Popover>
  );
}

export default MultiInput;
