import React from 'react'

const Info = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
      return null;
    }
  
    return (
      <div className="modal">
        <div className="modal-overlay"></div>
        <div className="modal-content">{children}</div>
      </div>
    );
  };

export default Info;