import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Tooltip } from "antd";

//  COMPONENTS IMPORT
import ProjectModal from "../../common/components/ProjectModal";
import SimpleButton from "../../common/components/SimpleButton";
import AnimationButtonIcon from "../../common/components/AnimationButtonIcon";

// IMAGES/SVGs
// import rocket from "../../assests/commonIcons/rocket.svg";
import newAPIIcon from "../../assests/commonIcons/templateSelectedIcons/newAPIIcon.svg";
import newStartProjectIcon from "../../assests/commonIcons/templateSelectedIcons/newStartProjectIcon.svg";
// import adaptive from "../../assests/commonIcons/templateIcons/adaptive.png";
import customizeTemplateGIF from "../../assests/commonIcons/templateIcons/customizeTemplateGIF.gif";
// import api from "../../assests/commonIcons/api.svg";
// import info from "../../assests/commonIcons/info.svg";
import infoNew from "../../assests/commonIcons/templateSelectedIcons/infoNew.svg";
import a9_16 from "../../assests/commonIcons/templateSelectedIcons/9_16.svg";
import a16_9 from "../../assests/commonIcons/templateSelectedIcons/16_9.svg";
import a1_1 from "../../assests/commonIcons/templateSelectedIcons/1_1.svg";
import useSessionChecker from "../../common/functions/useSessionChecker";
import NoData403 from "../../userModule/common/NoData403";
import { useEffect } from "react";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import GhostButton from "../../common/components/GhostButton";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import MobileResModal from "../../common/components/Modal/MobileResModal";

const TemplateSelected = ({ custregid }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();
  const [url, setUrl] = useState("");
  const [projectModalVisible, setProjectModalVisible] = useState(false); // state of project modal
  const [modalTitle, setModalTitle] = useState(""); // state to manage title in project modal
  const [modalId, setModalId] = useState("");
  const [bgColor, setBgColor] = useState(""); // State to manage background color in project modal
  const [borderColor, setBorderColor] = useState(""); // State to manage border color in proejct modal

  const [isProjectHovered, setIsProjectHovered] = useState(false);
  const [isApiHovered, setIsApiHovered] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isMediumScreen = windowWidth >= 400;
  const isLargeScreen = windowWidth >= 700;
  const [mobileResModal, setMobileResModal] = useState(false);

  custregid = localStorage.getItem("sessionUserId");


  const {
    templateid,
    templatename,
    templatedesc,
    thumbnail,
    credits,
    newtemplateid,
    templateAspectRatio,
    previewLink,
    prevLinkStatus,
  } = location.state || {};

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, []);

  if (templateid === undefined) {
    return (
      <>
        <NoData403 />
      </>
    );
  }

  const goToMyTemplatePage = () => {
    navigate(`${process.env.REACT_APP_BASE_PATH}/user-template`);
  };

  // const getTemplateNameStyle = (aspectRatio) => {
  //   if (aspectRatio === "16:9") {
  //     return "h-[200px]";
  //   } else if (aspectRatio === "9:16") {
  //     return "h-[200px]";
  //   } else if (aspectRatio === "1:1") {
  //     return "h-[200px]";
  //   } else {
  //     return "h-[200px]";
  //   }
  // };

  const openPreviewLink = () => {
    window.open(previewLink, "_blank");
  };

  return (
    <>
      <div className={`h-[calc(100vh_-_3.75rem)] ${isDesktop ? "px-16" : "px-8"} overflow-auto bg-[#F7FAFC] flex flex-col `}>
        <div className="flex justify-start mt-10">
          <span>
            <AnimationButtonIcon
              onClick={() => navigate(-1)}
              label="Back to Templates"
            />
          </span>
        </div>

        {/* <div className="mt-10"> */}
        <div className={`flex ${isLargeScreen ? "gap-20" : "flex-col gap-8"}  justify-between mt-8`}>
          <div className={` ${isLargeScreen ? "w-[40%]" : "w-full"}`}>
            <div className="flex justify-start items-center mb-3">
              <img
                className=""
                src={
                  templateAspectRatio === "9:16"
                    ? a9_16
                    : templateAspectRatio === "16:9"
                    ? a16_9
                    : templateAspectRatio === "1:1"
                    ? a1_1
                    : ""
                }
                alt="sdf"
              />
              <span className="text-smm text-[#201818] ml-2">
                {templateAspectRatio}
              </span>
            </div>
            <div className="font-semibold text-[22px]">{templatename}</div>
            <div className="text-[#5A5A5A] text-smm font-normal pt-2 leading-[1.10rem]">
              {templatedesc}
            </div>
            <div className={`mt-5`}>
              <span className="flex justify-start items-center">
                <span className="flex justify-start items-center text-[#283B4C] font-medium text-xs mb-2">
                  {credits} Credits
                  <span className="ml-1.5">
                    <Tooltip
                      placement={isMobile ? "topRight" : "right"}
                      title={`Using this video template will deduct ${credits} credits from your account.`}
                    >
                      <img src={infoNew} alt="Info" />
                    </Tooltip>
                  </span>
                </span>
                {/* {previewLink !== null && prevLinkStatus === "1000" && (
                  <span
                    className="ml-6 flex justify-start items-center text-[#283B4C] font-medium text-base mb-2 cursor-pointer"
                    onClick={openPreviewLink}
                  >
                    Preview Template
                  </span>
                )} */}
              </span>
              <span
                className="w-full h-[250px] inline-block bg-contain bg-no-repeat mb-1"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_SOURCE_TEMPLATE_BUCKET_BASE_URL}TEMPLATE/${templateid}/SOURCE/${thumbnail})`,
                }}
              >
                {/* Your content */}
              </span>
              {previewLink !== null && prevLinkStatus === "1000" && (
                <>
                  <span className="text-xs text-[#5A5A5A]">
                    * Watch actual working video template with complete features
                  </span>
                  <span className="flex items-center my-3">
                    <GhostButton
                      key="logout"
                      onClick={() => {
                        openPreviewLink();
                      }}
                      label="Full Video Template"
                      className="white-bg-btn mr-6 text-blue-500 font-inter text-sm font-normal leading-5 rounded-md border border-blue-500"
                    />
                  </span>
                </>
              )}
            </div>
          </div>
          <div className={` ${isLargeScreen ? "w-[60%]" : "w-full"}`}>
            <div className={`flex ${isMediumScreen ? "justify-between" : "flex-wrap"} gap-5 `}>
              <div
                className={` ${isMediumScreen ? "w-1/2" : "w-[320px]"} box rounded cursor-pointer h-full ${
                  isProjectHovered ? "bg-[#F6F8FF]" : "bg-white"
                }`}
                onMouseEnter={() => setIsProjectHovered(true)}
                onMouseLeave={() => setIsProjectHovered(false)}
                onClick={isMobile ? ()=>setMobileResModal(true) : () => {
                  setProjectModalVisible(true);
                  setUrl(
                    `${process.env.REACT_APP_BASE_PATH}/start-project/${newtemplateid}`
                  );
                  setModalTitle("Start Project");
                  setModalId("1");
                  setBgColor("#EFFFE5");
                  setBorderColor("#CCF2B5");
                }}
              >
                <div className="flex justify-start px-4 py-2 items-center">
                  <img
                    className="w-5 h-5"
                    src={newStartProjectIcon}
                    alt="api"
                  />
                  <span className="text-base text-[#1B1B1B] font-semibold ml-2">
                    Start a Project
                  </span>
                </div>
                <Divider className="!border-solid !border-[1px] !border-[#DBDADA] !m-0" />
                <div className="text-[#0F0F0F] text-smm px-4 pt-2 pb-[28px] leading-[1.10rem]">
                  Use this template as it is and run campaign directly to your
                  customer base
                </div>
                <Divider className="!border-solid !border-[1px] !border-[#B1C2ED] !m-0" />
                <div
                  className={`text-center text-sm py-2 font-semibold rounded-b-[4px] ${
                    isProjectHovered
                      ? "bg-[#5F8CFF] text-white"
                      : "bg-[#F5F8FF] text-[#3944A0]"
                  }`}
                >
                  Start a Project
                </div>
              </div>

              <div
                className={`flex flex-col justify-between ${isMediumScreen ? "w-1/2" : "w-[320px]"}  box rounded cursor-pointer ${
                  isApiHovered ? "bg-[#FFF7F8]" : "bg-white"
                }`}
                onMouseEnter={() => setIsApiHovered(true)}
                onMouseLeave={() => setIsApiHovered(false)}
                onClick={() => {
                  setProjectModalVisible(true);
                  setUrl(
                    `${process.env.REACT_APP_BASE_PATH}/start-project/${newtemplateid}`
                  );
                  setModalTitle("Get API");
                  setModalId("2");
                  setBgColor("#EFFFE5");
                  setBorderColor("#CCF2B5");
                }}
              >
                <div className="flex justify-start px-4 py-2 items-center">
                  <img className="w-6 h-6" src={newAPIIcon} alt="api" />
                  <span className="text-base text-[#1B1B1B] font-semibold ml-2">
                    Use API
                  </span>
                </div>
                <Divider className="!border-solid !border-[1px] !border-[#DBDADA] !m-0" />
                <div className="text-[#0F0F0F] text-smm px-4 pt-2 pb-[28px] leading-[1.10rem]">
                  Get a ready-to-use API for integration into your websites,
                  apps, or existing platforms
                </div>

                <div>
                  <Divider className="!border-solid !border-[1px] !border-[#FFBBCB] !m-0" />
                  <div
                    className={`text-center text-sm py-2 font-semibold rounded-b-[4px] ${
                      isApiHovered
                        ? "bg-[#DC6F83] text-white"
                        : "bg-[#FFF7F8] text-[#5E1229]"
                    }`}
                  >
                    Get API
                  </div>
                </div>
              </div>
            </div>
            <div className={`${isMediumScreen ? "w-full  " : "w-[320px]"} ${isDesktop ? "justify-between" : "flex-col"}  flex items-center px-3  mt-9  pt-5 pb-4 customization_box`}>
              <span className={`w-fit ${isMediumScreen}`}>
                <img
                  src={customizeTemplateGIF}
                  alt="adaptive"
                  className={`h-[145px] mr-8  ${isDesktop ? "" : "hidden"} w-[166px]`}
                />
              </span>
              <div className={` ${isDesktop ? "w-3/4" : "w-[90%]"}`}>
                <div className="font-semibold text-base pb-[8px] text-[#282727]">
                  Customization to next level
                </div>
                <div className="font-normal text-smm pb-[10px] text-[#000000] leading-[1.10rem]">
                  VSPAGY empowers you to customize the base template as per your
                  requirement.
                </div>
                <div className={`flex w-full ${isLargeScreen ? "justify-between" : "flex-col"}  text-[#414141] mt-2 font-normal text-[13px]`}>
                  <span className="flex flex-col w-1/2">
                    <span className="mt-1">- Add custom CTAs</span>
                    <span className="mt-1">- Color customization</span>
                    <span className="mt-1">- Shape interactivity</span>
                  </span>
                  <span className={`flex flex-col ${isLargeScreen ? "w-1/2" : ""} `}>
                    <span className="mt-1">- Personalize your media</span>
                    <span className="mt-1">- Numerous actions</span>
                  </span>
                </div>
                <SimpleButton
                  onClick={isMobile ? ()=>setMobileResModal(true) : () => {
                    // setInputModal(true);
                    setProjectModalVisible(true);
                    //   setUrl("/template-library");
                    setModalTitle("Customize Template"); // set the title in project modal
                    setModalId("3");
                    setBgColor("#FFF8E5"); // Set the background color in project modal
                    setBorderColor("#F2CBB5"); // Set the border color modal
                  }}
                  className="bg-[#1C68EB] hover:bg-gradient-to-r hover:to-[#0943A6] hover:from-[#1C68EB] rounded-3xl float-right mt-4 text-white hover:!text-white font-normal text-sm"
                  label="Start Customization"
                ></SimpleButton>
              </div>
            </div>
            <div className="flex items-center float-right gap-2 mt-4 mb-10">
              {/* <img src={info} alt="alert" className="h-5 w-5" /> */}
              <div className="text-[13px] font-normal text-black">
                You can always find your customized templates in{" "}
                <span
                  className="text-[#0042C1] cursor-pointer"
                  onClick={goToMyTemplatePage}
                >
                  My Templates
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <ProjectModal
          modalVisible={projectModalVisible}
          setModalVisible={setProjectModalVisible}
          title={modalTitle}
          id={modalId}
          bgColor={bgColor}
          borderColor={borderColor}
          credits={credits}
          url={url}
        />
        {isMobile && (
          <MobileResModal
            mobileResModal={mobileResModal}
            setMobileResModal={setMobileResModal}
          />
        )}
      </div>
    </>
  );
};

export default TemplateSelected;
