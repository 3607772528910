import React, { useCallback, useEffect, useState } from "react";
import { Modal, Skeleton, Spin, Tooltip } from "antd";
import "../../common/styles/commonStyles.css";
import SimpleButton from "../../common/components/SimpleButton";
import { useNavigate } from "react-router-dom";
import clevertap from "clevertap-web-sdk";
import useWindowWidth from "../../common/hooks/useWindowWidth";

const TemplateCard = ({ template, custregid }) => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [previewFinalLink, setPreviewFinalLink] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  const prevLink = template.TEMPLATEPREVIEWLINK || null;
  const prevLinkStatus = template.TEMPLATEPREVIEWLINKSTATUS || null;
  const templateData = template.TEMPLATEDATA || null;

  const abc = () => {
    console.log("2 INSIDE  abc ");
    if (prevLink !== null) {
      const baseURL = "https://vsp.gy/";
      const newPrevLink = "https://app.vspagy.com/user/link?id=";

      if (prevLink.startsWith(baseURL)) {
        // Extract the endpoint by removing the base prevLink
        const endpoint = prevLink.slice(baseURL.length);
        const finalLink = newPrevLink + endpoint;
        console.log(finalLink, "ENDPOINT");
        setPreviewFinalLink(finalLink);
        return finalLink;
      } else {
        // If the URL doesn't start with the base URL, return null or handle as needed
        return null;
      }
    }
  };

  const [tempPattern, setTempPattern] = useState("");

  const [isLoading, setIsLoading] = useState(true); // State for loading state
  const userAgent = navigator.userAgent;
  custregid = localStorage.getItem("sessionUserId");

  // const templatePattern = template?.TEMPLATEPATTERN || {};

  const aspectRatio = tryParseJSON(template.TEMPLATEDATA) || {};

  function tryParseJSON(jsonString) {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      console.error("Invalid JSON data:", jsonString);
      return {};
    }
  }

  useEffect(() => {
    const templatePattern = template?.TEMPLATEPATTERN || {};
    getTemplatePattern(templatePattern);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [template]);

  const [shouldRunChangeWrapperCSS, setShouldRunChangeWrapperCSS] =
    useState(false);

  const templateAspectRatio = aspectRatio.templateData?.aspect || "";

  const changeWrapperCSS = useCallback((templateAspectRatio) => {
    console.log("3 Inside Wrapper css");

    console.log(templateAspectRatio, "4 Inside RATIO Wrapper css");
    if (templateAspectRatio === "16:9") {
      console.log("INSIDE Rendering aspect ratio: 16:9");
    } else if (templateAspectRatio === "9:16") {
      console.log("INSIDE Rendering aspect ratio: 9:16");
    } else if (templateAspectRatio === "1:1") {
      console.log("INSIDE Rendering aspect ratio: 1:1");
    } else {
      console.log("INSIDE Rendering default aspect ratio");
    }

    const iframe = document.getElementById("preview-iframe-id");
    if (!iframe) {
      console.error("Wrapper Iframe element not found.");
      return;
    }

    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDocument) {
      console.error("Wrapper Iframe document not accessible.");
      return;
    }

    const wrapperElement = iframeDocument.querySelector(".wrapper");
    if (!wrapperElement) {
      console.error("Wrapper div not found inside iframe content.");
      return;
    }

    // Remove the 'vjs-control-bar' class from the div with class '.vjs-control-bar'
    const controlBarElement = iframeDocument.querySelector(".vjs-control-bar");
    if (controlBarElement) {
      controlBarElement.classList.remove("vjs-control-bar");
    }

    if (templateAspectRatio === "16:9") {
      console.log("Rendering aspect ratio: 16:9");
      wrapperElement.style.width = "100%"; // Adjust width for 16:9 aspect ratio
    } else if (templateAspectRatio === "9:16") {
      console.log("Rendering aspect ratio: 9:16");
      wrapperElement.style.width = "196px"; // Adjust width for 9:16 aspect ratio
    } else if (templateAspectRatio === "1:1") {
      console.log("Rendering aspect ratio: 1:1");
      wrapperElement.style.width = "73%"; // Adjust width for 1:1 aspect ratio
    } else {
      console.log("Rendering default aspect ratio");
      wrapperElement.style.width = "100%"; // Default width
    }
  }, []);

  useEffect(() => {
    if (shouldRunChangeWrapperCSS) {
      changeWrapperCSS(templateAspectRatio);
      setShouldRunChangeWrapperCSS(false);
    }
  }, [shouldRunChangeWrapperCSS, templateAspectRatio, changeWrapperCSS]);

  // Add a state to track whether iframe content has loaded
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
    // Run changeWrapperCSS when iframe content has loaded
    setShouldRunChangeWrapperCSS(true);
  };

  const showModal = (aspectRatio) => {
    setModalVisible(true);
    console.log("1 INSIDE Modal and Open");
    console.log(aspectRatio, "5 INSIDE Modal and Open");
    abc();
    setIsSpinning(true);
  };

  const hideModal = () => {
    setModalVisible(false);
    // Modal.destroyAll();
  };

  const Templates_Viewed = (templateDetail) => {
    clevertap.event.push("Templates_Viewed", {
      USER_ID: custregid,
      Email: custregid,
      TemplateName: templateDetail.templatename,
      TemplateID: templateDetail.templateid,
      Template: "Global",
      UseCaseName: templateDetail.templateUseCase,
      CategoryName: templateDetail.templateCategory,
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Templates_Viewed EVENT CLICKED");
  };

  const navigateToAnotherRoute = () => {
    navigate(
      `${process.env.REACT_APP_BASE_PATH}/template-selected/${template.TEMPLATEID}`,
      {
        state: {
          templateid: template.TEMPLATEID,
          templatename: template.TEMPLATENAME,
          templatedesc: template.TEMPLATEDESC,
          thumbnail: template.TEMPLATETHUMBNAIL,
          credits: template.TEMPLATECREDITS,
          templateAspectRatio: templateAspectRatio,
          templateUseCase: template.TEMPLATEUSECASE,
          templateCategory: template.TEMPLATECATEGORY,
          previewLink: prevLink,
          prevLinkStatus: prevLinkStatus,
          templateData: templateData,
        },
      }
    );
    const templateDetail = {
      templateid: template.TEMPLATEID,
      templatename: template.TEMPLATENAME,
      templateUseCase: template.TEMPLATEUSECASE,
      templateCategory: template.TEMPLATECATEGORY,
    };
    Templates_Viewed(templateDetail);
  };

  const getTemplateNameStyle = (aspectRatio) => {
    if (aspectRatio === "16:9") {
      return "w-[22rem]";
    } else if (aspectRatio === "9:16") {
      return "w-[7rem]";
    } else if (aspectRatio === "1:1") {
      return "w-[12rem]";
    } else {
      return "w-[22rem]";
    }
  };

  const mobGetTemplateNameStyle = (aspectRatio) => {
    if (aspectRatio === "16:9") {
      return "w-[15rem]";
    } else if (aspectRatio === "9:16") {
      return "w-[5rem]";
    } else if (aspectRatio === "1:1") {
      return "w-[8rem]";
    } else {
      return "w-[15rem]";
    }
  };

  const getTemplateImageStyle = (aspectRatio) => {
    if (aspectRatio === "16:9") {
      return "!w-[355px]";
    } else if (aspectRatio === "9:16") {
      return "!w-[115px]";
    } else if (aspectRatio === "1:1") {
      return "!w-[200px]";
    } else {
      return "!w-[355px]";
    }
  };

  const getTemplatePattern = (templatePattern) => {
    if (templatePattern === "PERSONALIZED") {
      return setTempPattern("Personalised");
    } else if (templatePattern === "RAW") {
      return setTempPattern("Raw");
    } else if (templatePattern === "INTERACTIVE") {
      return setTempPattern("Interactive");
    } else if (templatePattern === "PI") {
      return setTempPattern("Personalised & Interactive");
    } else {
      return setTempPattern("NA");
    }
  };

  const openPreviewLink = () => {
    window.open(prevLink, "_blank");
  };

  return (
    <>
      <div className="max-w-min p-1 rounded-sm transition duration-300 transform">
        <div
          className="cursor-pointer relative"
          onMouseEnter={() => {
            if (prevLink !== null) {
              setIsHovered(true);
            }
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onClick={() => {
            if (prevLink !== null && prevLinkStatus === "1000") {
              showModal();
            } else {
              navigateToAnotherRoute();
            }
          }}
        >
          {isLoading ? (
            <Skeleton.Image
              active
              className={`${
                isMobile ? "!h-[130px]" : "!h-[200px]"
              } ${getTemplateImageStyle(templateAspectRatio)}`}
            />
          ) : (
            <>
              <img
                alt="Thumbnail"
                src={
                  template.TEMPLATETHUMBNAIL
                    ? `${process.env.REACT_APP_SOURCE_TEMPLATE_BUCKET_BASE_URL}TEMPLATE/${template.TEMPLATEID}/SOURCE/${template.TEMPLATETHUMBNAIL}`
                    : `https://placehold.co/230x230`
                }
                // className="h-[200px] rounded-md"
                className={` ${
                  isMobile ? "h-[130px]" : "h-[200px]"
                } rounded-md`}
              />
            </>
          )}
          {prevLink !== null && prevLinkStatus === "1000" && isHovered && (
            <div className="absolute inset-0 bg-gray-700 opacity-0 hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
              <span
                className="relative flex items-center justify-center overflow-hidden group bg-[#E16023] hover:bg-gradient-to-r hover:from-[#E16023] hover:to-[#d5632e] rounded-[50px] px-3 py-1 cursor-pointer transition-all ease-out duration-300"
                onClick={showModal}
              >
                <span className="absolute right-0 w-8 h-24 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-20 rotate-12 group-hover:-translate-x-40 ease"></span>
                <span className="text-sm font-medium text-white relative select-none">
                  Preview
                </span>
              </span>
            </div>
          )}
          {template.TEMPLATETYPE === "PRO" ? (
            <div className="absolute top-2 right-2 bg-[#7C58E1] text-white text-xs px-2 py-1 rounded-[10px]">
              {template.TEMPLATETYPE}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="cursor-pointer mt-2" onClick={navigateToAnotherRoute}>
          {isLoading ? (
            <div>
              <Skeleton active paragraph={{ rows: 2 }} className="!h-[4px]" />
            </div>
          ) : (
            <>
              <Tooltip placement="topLeft" title={isMobile ? "" : template.TEMPLATENAME}>
                {" "}
                <div
                  className={`font-normal text-black text-sm whitespace-nowrap overflow-hidden text-ellipsis ${
                    isDesktop
                      ? getTemplateNameStyle(templateAspectRatio)
                      : mobGetTemplateNameStyle(templateAspectRatio)
                  }`}
                >
                  {template.TEMPLATENAME}
                </div>
              </Tooltip>
              <span className="text-[11px] text-[#6B6B6B]">{tempPattern}</span>
            </>
          )}
        </div>
        <Modal
          className="global-template-preview-modal overflow-auto  scrollbar-w-[2px] scrollbar-thumb-rounded-full
    scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
          open={modalVisible}
          onCancel={hideModal}
          footer={null}
          width={isDesktop ? 1000 : 500}
          destroyOnClose={true}
        >
          <div className={`flex ${isDesktop ? "" : "flex-col"}`}>
            <div className={` ${isDesktop ? "w-1/2" : "w-full"} preview-container`}>
              <iframe
                crossOrigin="anonymous"
                id="preview-iframe-id"
                className="preview-iframe"
                title="Video Preview"
                width="100%"
                height={ isDesktop ? 350 : 300}
                src={previewFinalLink}
                frameBorder="0"
                allowFullScreen
                scrolling="no"
                onLoad={handleIframeLoad} // Listen for iframe load event
              />
            </div>
            <div
              className={` ${isDesktop ? "w-1/2 px-8" : "w-full px-3"} flex flex-col  h-[300px]`}
              // style={{ justifyContent: "space-between" }}
            >
              <div className="flex flex-col space-y-4">
                <span className="text-2xl mt-4 text-black font-medium">
                  {template.TEMPLATENAME}
                </span>
                <span className="text-sm text-[#6d6d6d] font-normal mt-2">
                  {template.TEMPLATEDESC}
                </span>
              </div>
              <div className={`flex justify-between items-center mt-6 ${isDesktop ? "" : "w-[280px]"}`}>
                <SimpleButton
                  type="primary"
                  className="bg-[#1C68EB] hover:bg-gradient-to-r hover:to-[#0943A6] hover:from-[#1C68EB] rounded-3xl text-white hover:!text-white font-normal text-sm md:px-6 px-3 md:mr-2"
                  label="Preview Template"
                  onClick={openPreviewLink}
                />
                <SimpleButton
                  type="primary"
                  className="bg-[#1C68EB] hover:bg-gradient-to-r hover:to-[#0943A6] hover:from-[#1C68EB] rounded-3xl text-white hover:!text-white font-normal text-sm md:px-6 px-3"
                  label="Use Template"
                  onClick={navigateToAnotherRoute}
                />
              </div>
            </div>
          </div>
          {isSpinning &&
            !iframeLoaded && ( // Show spinner only if iframe hasn't loaded
              <div className="overlay">
                <span className="text-center absolute inset-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2 z-[10000000]">
                  <Spin size="large" />
                </span>
              </div>
            )}
        </Modal>
      </div>
    </>
  );
};

export default TemplateCard;
