import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal} from "antd";
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

function useConfirmExit(confirmExit, when) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}
export function usePrompt(message, when,flag,setFlag) {
    
    useEffect(() => {
      if (when) {
        Modal.destroyAll();
        window.onbeforeunload = function (e) {
          return message;
        };
      }

      return () => {
        window.onbeforeunload = null;
      };
    }, [message, when]);
  
    const confirmExit = useCallback((e) => {
      const confirm = window.confirm(message);
      return confirm;
    }, [message]);
    useConfirmExit(confirmExit, when);
  }