import React from "react";

// THIS PAGE WILL DISPLAY WHEN ANY USER IS TRYING TO LOGIN FROM GOOGLE
const SSOLogin = () => {
  return (
    <>
      <div>please wait</div>
      <br />
    </>
  );
};

export default SSOLogin;
