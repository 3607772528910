import clevertap from "clevertap-web-sdk";
import React from "react";

const CategoryFilter = ({ categories, selectedCategory, onSelectCategory, sessionuserid }) => {

  sessionuserid = localStorage.getItem("sessionUserId");
  const userAgent = navigator.userAgent;

  const TemplateCategorySelected = (category) => {
    clevertap.event.push("Template_Category_Selected", {
      "USER_ID": sessionuserid,
      "Email": sessionuserid,
      "CategoryName": category,
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Template_Category_Selected EVENT CLICKED");
  };

  return (
    <div className="flex gap-1 items-center w-[500px]">
      <span
        className={`text-sm font-medium text-[#141C37] pr-2 py-2 rounded-[4px] cursor-pointer hover:text-[#E16023] select-none ${
          selectedCategory === "ALL"
            ? " text-[#E16023] select-none font-semibold"
            : ""
        }`}
        onClick={() => onSelectCategory("ALL")}
      >
        ALL
      </span>

      {categories.map((category) => (
        <span
          key={category}
          className={`text-sm font-medium text-[#141C37] px-2 py-2 rounded-[4px] cursor-pointer hover:text-[#E16023] select-none whitespace-nowrap ${
            selectedCategory === category
              ? " text-[#E16023] select-none font-semibold"
              : ""
          }`}
          onClick={() => {onSelectCategory(category); TemplateCategorySelected(category);}}
        >
          {category}
        </span>
      ))}
    </div>
  );
};

export default CategoryFilter;
