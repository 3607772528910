import React from "react";
import CreditProduct from "./common/CreditProduct";
import useWindowWidth from "../../common/hooks/useWindowWidth";

const CreditReport = () => {
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  return (
    <>
      <div className={` ${isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"} h-[calc(100vh_-_3.75rem)] flex flex-col overflow-x-hidden`}>
        <div className={`bg-[#F7FAFC] ${isDesktop ? "px-11 pt-9" : "px-7 pt-7"}  pb-4 flex flex-col`}>
          <span className={` ${isDesktop ? "text-[22px]" : "text-[20px]"}  font-semibold`}>Analytics</span>
        </div>
        <CreditProduct />
      </div>
    </>
  );
};

export default CreditReport;
