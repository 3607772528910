import React from 'react'

const NewTemplate = () => {
  return (
    <div>
        <div className="fixed bottom-4 right-4 bg-white text-black rounded-full p-3 shadow-md select-none">
        <span className="font-bold">New Template</span>
      </div>
    </div>
  )
}

export default NewTemplate