import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SeeAllTemplateCard from "./SeeAllTemplateCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CategoryFilter from "../common/CategoryFilter";
import { useQuery } from "react-query";
import { getAllGlobalTemplate } from "../../api/api_FetchData";
import useSessionChecker from "../../common/functions/useSessionChecker";
import InputBox from "../../common/components/InputBox";
// import chevronRight from "../../assests/commonIcons/templateIcons/chevronRight.svg";
import rightChevron from "../../assests/commonIcons/templateIcons/rightChevron.svg";
import chevronLeft from "../../assests/commonIcons/templateIcons/chevronLeft.svg";
// import Search from "antd/es/input/Search";
// import { SearchOutlined } from "@ant-design/icons";
import { gsap } from "gsap";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import { Select } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

const SeeAllTemplatesPage = ({ sessionuserid }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  sessionuserid = localStorage.getItem("sessionUserId");
  const { useCase } = location.state;

  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [, setscrolEnd] = useState(false);

  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);

  const [showSuffixIcon, setShowSuffixIcon] = useState(false);
  const [prefixActive, setPrefixActive] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  const [showInputBox, setShowInputBox] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (scrollX + shift <= 0) {
      setLeftArrowDisabled(true);
      setRightArrowDisabled(false);
    } else if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setLeftArrowDisabled(false);
      setRightArrowDisabled(true);
    } else {
      setLeftArrowDisabled(false);
      setRightArrowDisabled(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.1 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.1 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const { data, refetch } = useQuery(
    ["users", sessionuserid],
    () => getAllGlobalTemplate(sessionuserid, null, "/template"),
    { staleTime: 10 * 60 * 1000 }
  );

  useEffect(() => {
    // Call the refetch function when the component mounts
    const fetchData = async () => {
      await refetch();
      // After refetching, you can safely access data
      // const allTemplateUseCases = Array.from(
      //   new Set(data?.data?.map((template) => template.TEMPLATEUSECASE) || [])
      // );
      // setSelectedType(allTemplateUseCases);
      // console.log(allTemplateUseCases, "TEMPLATE USE CASE");
      setTemplateData(data?.data);
    };

    fetchData();
  }, [refetch, data]);

  useEffect(() => {
    // Filter templates based on the selected use case
    const templatesForSelectedUseCase = templateData?.filter(
      (template) => template.TEMPLATEUSECASE === useCase
    );
    console.log(
      templatesForSelectedUseCase,
      "Templates for the selected use case"
    );

    const uniqueCategories = Array.from(
      new Set(
        templatesForSelectedUseCase?.map(
          (template) => template.TEMPLATECATEGORY
        )
      )
    );
    console.log(uniqueCategories, "UNIQUE CATEGORY FOR SELECTED USE CASE");
    setCategories(uniqueCategories);

    // Filter templates based on the selected category within the selected use case
    const filtered = templatesForSelectedUseCase?.filter((template) => {
      return (
        // selectedCategory === "ALL" ||
        // template.TEMPLATECATEGORY === selectedCategory
        (selectedCategory === "ALL" ||
          template.TEMPLATECATEGORY === selectedCategory) &&
        (searchQuery === "" ||
          template.TEMPLATENAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          ) ||
          template.TEMPLATEDESC.toLowerCase().includes(
            searchQuery.toLowerCase()
          ) ||
          template.TEMPLATECATEGORY.toLowerCase().includes(
            searchQuery.toLowerCase()
          ) ||
          template.TEMPLATEUSECASE.toLowerCase().includes(
            searchQuery.toLowerCase()
          ))
      );
    });

    setFilteredTemplates(filtered);
  }, [selectedCategory, useCase, templateData, searchQuery]);

  // Calculate the number of categories
  const numCategories = categories.length;
  // console.log(numCategories, "NUMCAT")

  // Set the left and right arrow disabled states based on the number of categories
  // useEffect(() => {
  //   setLeftArrowDisabled(scrollX <= 0);
  //   setRightArrowDisabled(scrollX + scrl.current.offsetWidth >= scrl.current.scrollWidth);
  // }, [scrollX, scrl.current.offsetWidth, scrl.current.scrollWidth]);

  const goToPage = () => {
    navigate(`${process.env.REACT_APP_BASE_PATH}/global-template`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleInputBox = () => {
    setShowInputBox(!showInputBox);
  };

  return (
    <>
      <div className="h-[calc(100vh_-_3.75rem)] flex flex-col overflow-y-hidden">
        {isDesktop && (
          <div className="pr-8 pl-4 bg-[#F7FAFC] border-b border-t-0 border-r-0 border-l-0 border-solid border-[#E6E9EB] shadow-md h-[57px] flex items-center justify-between">
            <span className=" flex items-center ">
              {leftArrowDisabled ? (
                <span
                  className="mr-1 select-none"
                  style={{ pointerEvents: "none", visibility: "hidden" }}
                >
                  <img src={chevronLeft} alt="left-icon" />
                </span>
              ) : (
                numCategories > 4 && (
                  <span
                    className="mr-1 select-none cursor-pointer"
                    onClick={() => slide(-50)}
                    onMouseEnter={(e) => anim(e)}
                    onMouseLeave={(e) => anim2(e)}
                  >
                    <img src={chevronLeft} alt="left-icon" />
                  </span>
                )
              )}
              <span className="ul h-[60px]" ref={scrl} onScroll={scrollCheck}>
                <CategoryFilter
                  categories={categories}
                  selectedCategory={selectedCategory}
                  onSelectCategory={setSelectedCategory}
                />
              </span>
              {rightArrowDisabled ? (
                <span
                  className="ml-1 select-none"
                  style={{ pointerEvents: "none", visibility: "hidden" }}
                >
                  <img src={rightChevron} alt="right-icon" />
                </span>
              ) : (
                numCategories > 4 && (
                  <span
                    className="ml-1 select-none cursor-pointer"
                    onClick={() => slide(50)}
                    onMouseEnter={(e) => anim(e)}
                    onMouseLeave={(e) => anim2(e)}
                  >
                    <img src={rightChevron} alt="right-icon" />
                  </span>
                )
              )}
            </span>
            <span className="see-all-search">
              {/* <Search
              prefix={<SearchOutlined style={{ color: "#a0a0a0" }} />}
              className="see-all-search"
              placeholder="Search Templates ....."
              allowClear
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                width: 304,
                height: 32,
              }}
            /> */}
              <InputBox
                searchIconClass="px-1 h-[15px] w-[23px]"
                xIconClass="p-[1px]"
                className="h-[32px] w-[304px] text-[13px] rounded-3xl global-search"
                placeholder="Search from hundreds of templates..."
                value={searchQuery}
                onChange={handleSearchChange}
                onClearClick={() => {
                  setSearchQuery(""); // Clear the input value
                }}
                showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                prefixActive={prefixActive} // Pass prefixActive prop
                setShowSuffixIcon={setShowSuffixIcon}
                setPrefixActive={setPrefixActive}
              />
            </span>
          </div>
        )}

        {isMobile && (
          <div className="pr-8 pl-4 bg-[#F7FAFC] border-b border-t-0 border-r-0 border-l-0 border-solid border-[#E6E9EB] shadow-md h-[40px] flex items-center justify-between">
            <div>
              <Select
                defaultValue={selectedCategory}
                style={{ minWidth: 55, width: "auto" }}
                dropdownStyle={{ minWidth: " max-content" }}
                onChange={setSelectedCategory}
                className="templateCategorySelect"
              >
                <Option value="ALL">ALL</Option>
                {categories.map((category) => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex">
              <div className={` ${showInputBox ? "" : "showInputBox"}`}>
                <InputBox
                  searchIconClass={`px-[1px] h-[12px] w-[15px] ${
                    showInputBox ? "" : "hidden"
                  }`}
                  xIconClass=""
                  className={` h-[28px] text-[12px] rounded-l-3xl global-search  ${
                    showInputBox ? "w-48 " : "w-0 bg-transparent border-none "
                  }`}
                  placeholder="Search from hundreds of templates..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onClearClick={() => {
                    setSearchQuery(""); // Clear the input value
                  }}
                  showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                  prefixActive={prefixActive} // Pass prefixActive prop
                  setShowSuffixIcon={setShowSuffixIcon}
                  setPrefixActive={setPrefixActive}
                />
              </div>
              <div
                className={`bg-[#0943A6] w-[28px] h-[28px] cursor-pointer  ${
                  showInputBox ? "rounded-r-3xl" : "rounded-full"
                } flex items-center justify-center z-10`}
                onClick={toggleInputBox}
              >
                {showInputBox === false ? (
                  <SearchOutlined style={{ color: "white" }} />
                ) : (
                  <CloseOutlined style={{ color: "white" }} />
                )}
              </div>
            </div>
          </div>
        )}

        <div className={`${isDesktop ? "px-10" : "px-7"} py-8 bg-[#F7FAFC] overflow-auto  border-[#DADDEF]`}>
          <div className="flex flex-col mb-6">
            <div className="flex items-center">
              <span
                className="text-[#4D4D4D] text-sm cursor-pointer hover:text-[#1C68EB]"
                onClick={goToPage}
              >
                Template Library
              </span>
              <span className="mx-1">
                <img src={rightChevron} alt="right-icon" />
              </span>
              <span className="text-[#4D4D4D] text-sm">{useCase}</span>
            </div>
            <span className={` ${isDesktop ? "text-[22px]" : "text-[20px]"} pt-7 font-semibold`}>{useCase}</span>
          </div>
          {filteredTemplates?.length === 0 ? (
            <p>No templates found for this use case.</p>
          ) : (
            <>
              <div className="">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4, 900: 4 }}
                >
                  <Masonry>
                    {filteredTemplates?.map((template) => (
                      <>
                        <SeeAllTemplateCard
                          key={template.TEMPLATEID}
                          template={template}
                          style={{ width: "100%", borderRadius: "8px" }}
                        />
                      </>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SeeAllTemplatesPage;
