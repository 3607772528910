import React from "react";
import ProductType from "./ProductType";
import useWindowWidth from "../common/hooks/useWindowWidth";

const Dashboard = () => {
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  return (
    <div
      className={` ${
        isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"
      } h-[calc(100vh_-_3.75rem)] flex flex-col overflow-x-hidden`}
    >
      <div className={`bg-[#F7FAFC]  ${isDesktop ? "px-11 pt-9 pb-4" : "px-8 pt-7 pb-4 md:pb-2"}   flex flex-col`}>
        <span
          className={`${
            isDesktop ? "text-[22px] font-semibold" : "text-[20px] font-medium"
          } `}
        >
          Summary Dashboard
        </span>
      </div>
      <ProductType />
    </div>
  );
};

export default Dashboard;
