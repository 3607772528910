import React, { useState } from "react";
import { Form, Input, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
// import logoimage from "../../assests/commonImages/new-vspagy-logo.svg";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import industryOnboarding from "../../assests/commonImages/userOnboarding/getting-started-2.png";
import LoadingBar from "react-top-loading-bar";
import "../../common/styles/userModule/UserOnboarding.css";
import { useForm } from "antd/lib/form/Form";
import SimpleButton from "../../common/components/SimpleButton";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { updateCustomerIndustry } from "../../api/api_updateData";
import { toast } from "react-toastify";
import useSessionChecker from "../../common/functions/useSessionChecker";

export default function IndustryOnboarding() {
  const navigate = useNavigate();
  useSessionChecker(navigate);

  const sessionuserid = localStorage.getItem("sessionUserId");

  const values = [
    { id: 1, text: "Insurance" },
    { id: 2, text: "Banking" },
    { id: 3, text: "Real Estate" },
    { id: 4, text: "Fintech" },
    { id: 5, text: "Retail" },
    { id: 6, text: "Automobile" },
    { id: 7, text: "Agency" },
    { id: 8, text: "Education" },
    { id: 9, text: "Healthcare" },
    { id: 10, text: "Hospitality" },
    { id: 11, text: "Others" },
  ];

  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [nextDisabled, setNextDisabled] = useState(true);
  const [industryName, setIndustryName] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = useForm();

  function handleFinish(value) {
    // navigate(`${process.env.REACT_APP_BASE_PATH}/getting-started-3`);
    if (selectedIndustry) {
      const finalIndustryName =
        selectedIndustry === "Others" ? industryName : selectedIndustry;
      console.log("Selected Industry:", finalIndustryName);
      // Navigate to the next page here
      handleUpdateIndustry(finalIndustryName);
    }
  }

  function handleIndustryClick(text) {
    setSelectedIndustry(text);
    setInputVisible(text === "Others");
    setNextDisabled(text === "Others");
  }

  function handleIndustryNameChange(value) {
    setIndustryName(value);
    setNextDisabled(!value);
  }

  const handleUpdateIndustry = (industryname) => {
    setIsLoading(true);

    const custIndustryData = {
      custregid: sessionuserid,
      industry: industryname,
    };
    // console.log(custIndustryData, "IN DATA");
    updateCustomerIndustryMutation(custIndustryData);
  };

  const { mutate: updateCustomerIndustryMutation } = useMutation(
    (custIndustryData) => updateCustomerIndustry(custIndustryData),
    {
      onSuccess: (custIndustryData) => {
        if (custIndustryData.STATUS === "1000") {
          // const custData = JSON.parse(custIndustryData.DATA);
          // console.log(custData, "CUST DATA");
          // toast.success(custIndustryData.DESCRIPTION, {
          //             //
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   
          // });
          setTimeout(() => {
            navigate(`${process.env.REACT_APP_BASE_PATH}/getting-started-3`);
          }, 2000); // Navigate after 2 seconds
        } else {
          toast.error(custIndustryData.DESCRIPTION);
          setIsLoading(false);
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className="flex h-[68px] items-center justify-between">
            <img
              src={newVspagyLogo}
              alt="VPSAGY logo"
              className="logo-img w-[132px] snap-center ml-2"
            />
            <div className="flex w-[140px] justify-between">
              <Link
                to={`${process.env.REACT_APP_BASE_PATH}/getting-started-1`}
                className="no-underline"
              >
                <p className="back-link flex items-center font-normal text-[#706c6c] text-base hover:text-black hover:underline ">
                  {" "}
                  <LeftOutlined />
                  <span>Back</span>
                </p>
              </Link>
              <span className="flex items-center pr-4">
                <Link
                  to={`${process.env.REACT_APP_BASE_PATH}/getting-started-3`}
                  className="no-underline"
                >
                  <p className="skip-link flex items-center font-medium text-base text-secondary-blue hover:text-primary-blue hover:underline">
                    <span>Skip </span>
                    <RightOutlined />
                  </p>
                </Link>
              </span>
            </div>
          </div>
          <div className="pt-6 m-auto text-center">
            <div className="mt-10 sm:px-20 flex flex-col">
              <img
                src={industryOnboarding}
                alt="industryOnboarding"
                className="block m-auto w-[250px] my-3"
              />
              <span
                className="select-none font-medium sm:!text-[28px] max-sm:text-[22px]"
                style={{
                  fontFamily: "inter",
                }}
              >
                Tell us about your{" "}
                <span className="select-none fontl text-[#0943A6] font-normal sm:text-[25px] max-sm:text-[19px]">
                  industry
                </span>
              </span>
              <span className="sm:w-[500px] md:w-[600px] lg:w-[700px] mt-4 mx-auto text-[#727171] select-none sm:text-base max-sm:text-sm max-sm:px-[50px]">
                Our years of expertise with client dealing and servicing has
                enabled us to curate a legion of use-cases. Your industry
                details will aid us to fetch use-cases that might be relevant to
                you. A filtered search especially tailored for your needs.
              </span>
            </div>
            <div className="industry-details p-5 mt-4 m-auto">
              <ul className="mb-[10px] flex justify-center flex-wrap list-none max-sm:ml-[-35px]">
                {values.map((val, index) => (
                  <li
                    className={`${
                      selectedIndustry === val.text
                        ? "bg-[#DEE6FF] select-none font-medium"
                        : "bg-[#FBFBFB] hover:bg-[#DEE6FF] select-none"
                    }`}
                    onClick={() => handleIndustryClick(val.text)}
                    key={index}
                  >
                    {val.text}
                  </li>
                ))}
              </ul>
              <Form onFinish={handleFinish}>
                {inputVisible && (
                  <Form.Item name="Industry Name">
                    <Input
                      className="sm:w-[400px] max-sm:w-[270px] mt-8 shadow-md industryInput"
                      size="large"
                      placeholder="Enter Industry Name"
                      value={industryName}
                      onChange={(e) => handleIndustryNameChange(e.target.value)}
                    />
                  </Form.Item>
                )}
                <Form.Item form={form} name="myForm">
                  <SimpleButton
                    disabled={nextDisabled}
                    size="large"
                    htmlType="submit"
                    className={`h-11 !rounded-[5px] mt-8 sm:w-[400px] max-sm:w-[270px] my-3`}
                    label="Next"
                    type="primary"
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="w-full">
            <div className="onboardLoader">
              <LoadingBar
                progress={99.9}
                width={100}
                height={3}
                loaderSpeed={0}
                color="#C62134"
              />
            </div>
            <div className="onboardLoader2">
              <LoadingBar
                progress={99.9}
                width={100}
                height={3}
                color="#0943A6"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
