import React from "react";
import { ResponsivePie } from "@nivo/pie";

const PieChart = ({ data, colors, labelColor }) => {

  return (
    <>
      {data.length === 0 ? (
        <div className="flex justify-center items-center text-red-500">
          No data found
        </div>
      ) : (
        <div style={{ height: "256px" }}>
          <ResponsivePie
            data={data}
            margin={{ top: 20, right: 60, bottom: 30, left: 60 }}
            innerRadius={0.5}
            padAngle={0.7}
            colors={colors} // Use custom colors
            cornerRadius={2}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLinkLabelsStraightLength={9}
            // enableArcLinkLabels={false}
            startAngle={60}
            endAngle={-300}
            // arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            arcLabelsTextColor={labelColor}
          />
        </div>
      )}
    </>
  );
};

export default PieChart;
