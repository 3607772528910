import React from "react";
import { useEffect } from "react";
import { createSession } from "../../common/functions/Session";
import { useNavigate } from "react-router-dom";
import clevertap from "clevertap-web-sdk";
import { useCallback } from "react";
import { Spin } from "antd";

const FromGoogle = () => {
  const navigate = useNavigate();
  const base64Data = window.data;

  const userAgent = navigator.userAgent;

  const base64Decoded = window.atob(base64Data);
  console.log(base64Decoded, "BASE 64");

  // Get the STATUS value from the decoded data
  const parseData = JSON.parse(base64Decoded);
  const newData = JSON.parse(parseData.DATA);

  // debugger;
  console.log(newData, "PARSED DATA");

  const Login_Success = useCallback(
    (customerData) => {
      clevertap.event.push("Login_Success", {
        Name: customerData.customerName,
        Identity: customerData.customerId,
        Email: customerData.customerEmail,
        USER_ID: customerData.customerEmail,
        Login_Method: customerData.customerRegvia,
        FirstTime_User_Login: customerData.firstTime_User_Login,
        Onboarding_Screen_Viewed: customerData.onboarding_Screen_Viewed,
        Timestamp: customerData.timeStamp,
        timestamp: new Date().getTime(),
        user_agent: userAgent,
        // location: location,
      });

      clevertap.onUserLogin.push({
        Site: {
          Name: customerData.customerName,
          Identity: customerData.customerId,
          Email: customerData.customerEmail,
          USER_ID: customerData.customerEmail,
        },
      });
      console.log("Login_Success EVENT CLICKED");
    },
    [userAgent]
  );

  useEffect(() => {
    // Function to navigate based on the STATUS value
    const navigateBasedOnStatus = () => {
      const sessionUserId = newData[0].CUSTOMERREGID;
      const sessionId = createSession(sessionUserId);
      switch (parseData.STATUS) {
        case "1000":
          localStorage.setItem("sessionId", sessionId);
          localStorage.setItem("sessionUserId", sessionUserId);
          console.log(sessionUserId, "SESSSION USER ID");

          const customerData = {
            customerEmail: newData[0].CUSTOMERREGID,
            customerRegvia: newData[0].CUSTOMERREGVIA,
            firstTime_User_Login: "NO",
            onboarding_Screen_Viewed: "NO",
            timeStamp: newData[0].CUSTOMERLASTLOGIN,
            customerName: newData[0].CUSTOMERNAME,
            customerId: newData[0].CUSTOMERID,
          };
          Login_Success(customerData);

          navigate(`${process.env.REACT_APP_BASE_PATH}/global-template`);
          break;
        case "2000":
          localStorage.setItem("sessionId", sessionId);
          localStorage.setItem("sessionUserId", sessionUserId);
          console.log(sessionUserId, "SESSSION USER ID");
          localStorage.setItem("startingModalShow", "yes");

          const customerDataSignup = {
            customerEmail: newData[0].CUSTOMERREGID,
            customerRegvia: newData[0].CUSTOMERREGVIA,
            firstTime_User_Login: "YES",
            onboarding_Screen_Viewed: "YES",
            timeStamp: newData[0].CUSTOMERLASTLOGIN,
            customerName: newData[0].CUSTOMERNAME,
            customerId: newData[0].CUSTOMERID,
          };
          Login_Success(customerDataSignup);

          navigate(`${process.env.REACT_APP_BASE_PATH}/getting-started-1`);
          break;
        case "-1001":
          navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
          break;
        default:
          // Handle other STATUS values or errors
          break;
      }
    };

    // Call the navigateBasedOnStatus function to perform navigation
    navigateBasedOnStatus();

    // This useEffect will only run once after the component is mounted
  }, [navigate, parseData, newData, Login_Success]);

  return (
    <>
      {/* <div className="flex flex-col">
        <div>FromGoogle</div>
        <h1>Global Variable Value:</h1>
        <p>{base64Data}</p>
      </div> */}
      <div className="m-auto">
        <span>
          <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
            <Spin tip="Loading..." />
          </div>
        </span>
      </div>
    </>
  );
};

export default FromGoogle;
