import React, { useState } from "react";
import { useMutation } from "react-query";
import { Input, Modal, Spin } from "antd";
import { toast } from "react-toastify";
import { copyTemplate } from "../../../../api/api_updateData";
import SimpleButton from "../../../components/SimpleButton";
import cloneImage from "../../../../assests/commonImages/templateImages/cloneImage.png";
import cross from "../../../../assests/commonIcons/cross.svg";
import "../../../styles/commonStyles.css"
import useWindowWidth from "../../../hooks/useWindowWidth";

const CloneDialog = (props) => {
  const { TextArea } = Input;
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const sessionuserid = localStorage.getItem("sessionUserId");
  const [showSpinner, setShowSpinner] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;
  

  const handleTemplateNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100 && /^[A-Za-z0-9- ]*$/.test(value)) {
      setTemplateName(value);
    }
  };

  const handleTemplateDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 500 && /^[A-Za-z0-9- \s]*$/.test(value)) {
      setTemplateDescription(value);
    }
  };

  //******************************* CREATE PROJECT STARTS ******************************************
  const { mutate: cloneTemplateMutation } = useMutation(
    (cloneTemplateData) => copyTemplate(cloneTemplateData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
          setShowSpinner(false); // Show loading spinner
          props.setDialog(false);
          props.onRefreshSuccess();
        } else {
          toast.error(data.DESCRIPTION);
          setShowSpinner(false);
        }
      },
    }
  );

  const cloneTemplate = () => {
    setShowSpinner(true);
    const cloneData = {
      templateid: props.templateid,
      sessionuserid: sessionuserid,
      templatename: templateName,
      templatedesc: templateDescription,
    };
    console.log(cloneData, "P DATA");
    cloneTemplateMutation(cloneData);
  };

  if (showSpinner) {
    return (
      <>
        <div
          className="overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0, 0, 0,0.5)",
            opacity: 1,
            zIndex: 1000,
            cursor: "wait",
          }}
        ></div>
        <span className="text-center top-1/2 left-1/2 absolute z-[10000000] cursor-wait">
          <Spin size="large" />
        </span>
      </>
    );
  }

  return (
    <div
      className={`clone-dialog-container ${
        showSpinner ? "blur-background" : ""
      }`}
    >
      <Modal
        className="clone-Modal"
        bodyStyle={{ padding: "0px" }}
        maskClosable={false}
        width={720}
        closable={false}
        onCancel={() => props.setDialog(false)}
        centered
        open={props.dialog}
      >
        <div className="  pt-0 font-medium text-lg text-[#000000] ">
          <div className="flex gap-2 justify-between">
            <div className="ml-8 mt-6 ">
              <h1 className=" text-2xl font-bold">
                Clone “{props.templatename}”
              </h1>
              <p className="text-[#5E5C5C] break-words max-w-[820px] text-[13px] desc font-normal ">
                Cloning allows you to create a copy of your saved template. Give
                a new name and description (if any)
              </p>
              <div className="mt-4">
                <label className="text-sm font-medium text-[#3A3A3A]">
                  Name
                </label>
                <br />
                <Input
                  className="mt-2"
                  type="text"
                  value={templateName}
                  onChange={handleTemplateNameChange}
                />
              </div>
              <div className="mt-5">
                <label className="text-sm font-medium text-[#3A3A3A]">
                  Template Description
                </label>
                <br />
                <TextArea
                  rows={4}
                  style={{ height: 44, resize: "none" }}
                  value={templateDescription}
                  onChange={handleTemplateDescriptionChange}
                  className="mt-2 h-2 rounded-[4px] scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full
                scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                />
              </div>
              <div className=" mt-12">
                <SimpleButton
                  key="cancel"
                  type="primary"
                  onClick={cloneTemplate}
                  label="Save Templates"
                  className="items-end"
                  disabled={
                    templateName.length < 4 || templateDescription.length < 4
                  }
                />
              </div>
            </div>
            <div className="relative h-full">
              <img
                className={`h-[400px] w-[320px] ${isLargeScreen ? "" : "hidden"} `}
                src={cloneImage}
                alt="find"
              />
              <img
                onClick={() => props.setDialog(false)}
                className={` ${isLargeScreen ? "absolute top-5 cursor-pointer right-5" : ""}`}
                src={cross}
                alt="x"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CloneDialog;
