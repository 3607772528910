import React, { useState } from "react";
import {
  Collapse,
  Divider,
  InputNumber,
  Input,
  Select,
  Switch,
  theme,
  Tooltip,
  Popover,
  Alert,
  Modal
} from "antd";
import minus from "../../assets/minus.svg";
import plus from "../../assets/plus.svg";
import alertcircle from "../../assets/alert-circle.svg";
import PopTick from "../../assets/PopTick.svg";
import cross from "../../assets/Cross.svg";
import { ActionOptions,LinkOptions,StoreOptions } from "../data.js";
import "./Action.css";
const Action = (props) => {
  
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const { Panel } = Collapse;
  const [hover, setHover] = useState(false);
  const [openFamily, setOpenFamily] = useState(false);
  function handleOpenFamily() {
    setOpenFamily(!openFamily);
  }
  const [openLink, setOpenLink] = useState(false);
  function handleOpenLink() {
    setOpenLink(!openLink);
  }
  const [openStore, setOpenStore] = useState(false);
  const [open,setOpen] = useState(false);
  function handleOpenStore() {
    setOpenStore(!openStore);
  }
  const error = () => {
    const modal  = Modal.error({
      title: 'Invalid URL',
      content: 'Please provide the Correct web address. For Example : https://www.vspagy.com',
      onOk: () =>{
        document.getElementById("LINKVALID").focus();
        props.setIsModalOpen(false);
        setOpen(false);
        Modal.destroyAll();
      }
    });
    return modal;
  }  
  return (
    <div
      className="mt-1 h-[84vh] overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
    scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] font-sans"
    >
      <div>
        <Collapse
          className="editor-collapse"
          expandIconPosition="end"
          style={{
            color: "white",
          }}
          bordered={false}
          defaultActiveKey={["4", "5"]}
          expandIcon={({ isActive }) => (
            <img src={isActive ? minus : plus} alt="open" />
          )}
        >
          <Panel
            className="px-4 mt-2"
            header="Display"
            key="4"
            style={panelStyle}
          >
            <div className="relative">
            {!props.isRight && <div className="z-10 absolute h-[25px] w-full top-[-25px] left-0">
            </div>}
            <div className="pt-3 ">
              <div className="flex">
                <div className="text-left">
                  Set time to display the component
                </div>
                <Tooltip
                  className="ml-2 mt-[2px]"
                  title="Present this layer within the specified time frame."
                  color="#2B2B2C"
                  trigger="click"
                  placement="leftBottom"
                  overlayStyle={{
                    boxShadow: "2px",
                  }}
                >
                  <img
                    className="h-[18px] w-[18px] cursor-pointer"
                    src={alertcircle}
                    alt="info-icon"
                  />
                </Tooltip>
              </div>
              <div className="flex justify-between ">
                <div className="flex h-10 gap-5 mt-2">
                  <div className="flex gap-4 items-center">
                    <div>Start</div>
                    <div>
                      <InputNumber
                        disabled = {props.isModalOpen}
                        readOnly = {!props.isRight}
                        className="editor-inum !px-0  w-16 "
                        type="number"
                        min="0"
                        defaultValue={0}
                        value={props.startTime}
                        formatter={(value) =>
                          `${value}`.replace(
                            /^(-)*(\d+)\.(\d{1,3}).*$/,
                            "$1$2.$3"
                          )
                        }
                        onClick={() => {
                          // props.setFieldEnabled(true);
                          props.fieldEnabled.current = true;
                        }}
                        onStep={()=>{
                          
                          props.fieldEnabled.current = true;
                        }}
                        onBlur={() => {
                          // props.setFieldEnabled(false);
                          props.fieldEnabled.current = false;
                        }}
                        onChange={(value) => {
                          if (value === null) {
                            props.setStartTime(0);
                          } else if (value > props.stopTime) {
                            props.setStartTime(value);
                            props.setStopTime(value + 1);
                          } else {
                            props.setStartTime(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex h-10 gap-5 mt-2">
                  <div className="flex  gap-4 items-center">
                    <div>End</div>
                    <div>
                      <InputNumber
                        className="editor-inum !px-0  w-16 "
                        disabled = {props.isModalOpen}
                        readOnly = {!props.isRight}
                        type="number"
                        min={props.startTime}
                        defaultValue={1}
                        value={props.stopTime}
                        formatter={(value) =>
                          `${value}`.replace(
                            /^(-)*(\d+)\.(\d{1,3}).*$/,
                            "$1$2.$3"
                          )
                        }
                        onClick={() => {
                          // props.setFieldEnabled(true);
                          props.fieldEnabled.current = true;
                        }}
                        onStep={()=>{
                          props.fieldEnabled.current = true;
                        }}
                        onBlur={() => {
                          if (
                            props.stopTime === null ||
                            props.stopTime < props.startTime
                          ) {
                            props.setStopTime(props.startTime + 1);
                          }
                          // props.setFieldEnabled(false);
                          props.fieldEnabled.current = false;
                        }}
                        onChange={(value) => {
                          props.setStopTime(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {(props.type === "button" || props.type === "hotspot") && (
                <div className="mt-4">
                  <div className="flex ">
                    <div className="text-left">
                      <span className="font-medium">Pause</span> video at this
                      layer
                    </div>
                    <Tooltip
                      className="ml-2 mt-[2px]"
                      title="Mention the time at which the video will be paused"
                      color="#2B2B2C"
                      trigger="click"
                      placement="leftBottom"
                      overlayStyle={{
                        boxShadow: "2px",
                      }}
                    >
                      <img
                        className="h-[18px] w-[18px] cursor-pointer"
                        src={alertcircle}
                        alt="info-icon"
                      />
                    </Tooltip>
                  </div>
                  <div className="flex  mt-2">
                    <div className="mx-3 mt-[1px]">
                      <Switch
                        size="small"
                        disabled = {!props.isRight}
                        checked={props.pauseToggle}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(checked) => {
                          props.setPauseToggle(checked);
                          if (props.pauseToggle) {
                            props.setResumeToggle(false);
                            props.setPauseTime(null);
                            props.setResumeIn(null);
                            props.setResumeTo(null);
                          } else {
                            props.setPauseTime(
                              (props.stopTime + props.startTime) / 2
                            );
                          }
                        }}
                      />
                    </div>

                    <InputNumber
                      type="number"
                      // min={props.startTime === undefined ? 0 : props.startTime}
                      disabled={!props.pauseToggle || props.isModalOpen}
                      readOnly = {!props.isRight}
                      value={props.pauseTime}
                      formatter={(value) =>
                        `${value}`.replace(
                          /^(-)*(\d+)\.(\d{1,3}).*$/,
                          "$1$2.$3"
                        )
                      }
                      onClick={() => {
                        // props.setFieldEnabled(true);
                        props.fieldEnabled.current = true;
                      }}
                      onStep={()=>{
                        props.fieldEnabled.current = true;
                      }}
                      onBlur={() => {
                        if (
                          props.pauseTime < props.startTime ||
                          props.pauseTime > props.stopTime
                        ) {
                          props.setPauseTime(props.stopTime);
                        }
                        // props.setFieldEnabled(false);
                        props.fieldEnabled.current = false;
                      }}
                      onChange={(value) => {
                        props.setPauseTime(value);
                      }}
                      className={`ml-[102px] w-[60px] editor-inum`}
                    />
                    <span className="text-[13PX] font-normal text-[#EEEEEE] mt-1 ml-3">
                      sec
                    </span>
                  </div>
                  {props.pauseToggle && (
                    <>
                      <div className="mt-2 text-left flex">
                        <div>
                          If<span className="font-medium"> no action</span> ,
                          resume video in
                        </div>
                        <Tooltip
                          className="ml-2 mt-[2px]"
                          title="Specify the seconds after which the video will automatically resume if no action is taken."
                          color="#2B2B2C"
                          trigger="click"
                          placement="leftBottom"
                          overlayStyle={{
                            boxShadow: "2px",
                          }}
                        >
                          <img
                            className="h-[18px] w-[18px] cursor-pointer"
                            src={alertcircle}
                            alt="info-icon"
                          />
                        </Tooltip>
                      </div>

                      <div className="flex mt-2">
                        <div className="mx-3 mt-[1px]">
                          <Switch
                            size="small"
                            disabled = {!props.isRight}
                            checked={props.resumeToggle}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(checked) => {
                              props.setResumeToggle(checked);
                              if (props.resumeToggle) {
                                props.setResumeIn(null);
                                props.setResumeTo(null);
                              } else {
                                props.setResumeTo(Math.ceil(props.pauseTime));
                              }
                            }}
                          />
                        </div>
                        <InputNumber
                          type="number"
                          min="0"
                          disabled={!props.resumeToggle || props.isModalOpen}
                          readOnly = {!props.isRight}
                          value={props.resumeIn}
                          onClick={() => {
                            // props.setFieldEnabled(true);
                            props.fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            // props.setFieldEnabled(false);
                            props.fieldEnabled.current = false;
                          }}
                          onStep={()=>{
                            props.fieldEnabled.current = true;
                          }}
                          onChange={(value) => {
                            props.setResumeIn(value);
                          }}
                          className={`ml-[102px] w-[60px] editor-inum`}
                        />
                        <span className="text-[13PX] font-normal text-[#EEEEEE] mt-1 ml-3">
                          sec
                        </span>
                      </div>
                    </>
                  )}
                  {props.resumeToggle && props.pauseToggle && (
                    <div className="flex mt-2">
                      <div className="text-left pt-1">
                        <div className="w-[136px] text-left">Seek video to</div>
                      </div>

                      <InputNumber
                        type="number"
                        min="0"
                        value={props.resumeTo}
                        disabled = {props.isModalOpen}
                        readOnly = {!props.isRight}
                        onClick={() => {
                          // props.setFieldEnabled(true);
                          props.fieldEnabled.current = true;
                        }}
                        onStep={()=>{
                          props.fieldEnabled.current = true;
                        }}
                        onBlur={() => {
                          // props.setFieldEnabled(false);
                          props.fieldEnabled.current = false;
                        }}
                        onChange={(value) => {
                          props.setResumeTo(value);
                        }}
                        className={`ml-8 w-[60px] editor-inum`}
                      />
                      <span className="text-[13PX] font-normal text-[#EEEEEE] mt-1 ml-3">
                        sec
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            </div>
          </Panel>
          {(props.type === "button" || props.type === "hotspot") && (
            <Divider className="!m-0 !px-1  border-[#2b2b2c]" />
          )}
          {(props.type === "button" || props.type === "hotspot") && (
            <Panel
              header="Set Action"
              className="mt-4 mb-3 px-4"
              key="5"
              style={panelStyle}
            > 
              <div className="relative">
            {!props.isRight && <div className="z-10 absolute h-[25px] w-full  top-[-25px] left-0">
            </div>}
              <div className="pt-3 ">
                <div className="flex mt-2 action-select">
                  
                  <Popover
                    placement="left"
                    arrow={false}
                    open={openFamily}
                    onOpenChange={handleOpenFamily}
                    content={
                      <div
                        className="bg-[#050506] w-[250px] h-[220px] absolute right-[20px] top-[-40px] "
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                        onMouseMove={() => {
                          setHover(true);
                        }}
                      >
                        <div className="flex justify-between p-3 py-2 pr-3">
                          <div className="text-[#fff] font-semibold">
                            Select Action
                          </div>
                          <img
                            className="cursor-pointer"
                            src={cross}
                            alt="cross"
                            onClick={() => {
                              setOpenFamily(false);
                            }}
                          />
                        </div>
                        <Divider className="!m-0" />
                        <div
                          className="h-[180px] overflow-auto  scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                        >
                          {ActionOptions.map((item, index) => {
                            return (
                              <div
                                className={`${
                                  props.action === item.value && !hover
                                    ? "bg-[#1D1D1F]"
                                    : ""
                                } flex mt-1 py-1  w-full text-left cursor-pointer hover:bg-[#1D1D1F]`}
                                onClick={() => {
                                  // props.setfontFamily(item.value);
                                  props.setAction(item.value);
                                  if (item.value === 0) {
                                    props.setActionsArray([]);
                                  } else if (item.value === 1) {
                                    props.setActionsArray([null, 0]);
                                  } else if (item.value === 2) {
                                    props.setActionsArray([null]);
                                  } else if (item.value === 3) {
                                    props.setActionsArray([null, 0]);
                                  } else if (item.value === 4){
                                    props.setActionsArray([null, 0, ""]);
                                  }
                                  else{
                                    props.setActionsArray([""]);
                                  }
                                }}
                              >
                                <div className="pl-[5px] w-[40px] h-[20px]">
                                  {props.action === item.value && (
                                    <img
                                      className="w-[100%] h-[100%]"
                                      src={PopTick}
                                      alt="P"
                                    />
                                  )}
                                </div>
                                <div className="text-[#ffffff] pl-[10px]  text-[14px] ">
                                  {item.label}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div
                      className=" cursor-pointer"
                      style={{
                        textAlign: "center",
                        height: "28px",
                      }}
                    >
                      <div
                        className="flex"
                        onClick={() => {
                          setHover(false);
                        }}
                      >
                        <div className=" text-[14px]">
                            {props.action===0?ActionOptions[0].label:props.action===1?ActionOptions[1].label:props.action===2?ActionOptions[2].label:props.action===3?ActionOptions[3].label:props.action===4?ActionOptions[4].label:ActionOptions[5].label}
                        </div>
                        <div className="mt-[7px] ml-4">
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="down"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Popover>
                </div>
                <div className="mt-2">
                  {props.action === 0 && (
                    <div className="text-sm text-left text-[#c0bdbd] mt-5">
                      No action choosen.
                    </div>
                  )}
                  {props.action === 1 && (
                    <div className="text-left mt-5 text-[#c0bdbd]">
                      <div>
                        This action will lead viewer to the provided
                        address/URL.
                      </div>
                      <Input
                        value={props.actionsArray[0]}
                        id = "LINKVALID"
                        onClick={() => {
                          // props.setFieldEnabled(true);
                          props.fieldEnabled.current = true;
                        }}
                        onBlur={() => {
                          // const urlRegex = /^(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/;
                          // if(props.actionsArray[0] === null || urlRegex.test(props.actionsArray[0]) || props.actionsArray[0].trim() === "") {
                            
                          // }
                          // else {
                          //   if(!open){
                          //     setOpen(true);
                          //     error();
                          //   }
                          //   props.setIsModalOpen(true);
                          // }
                          // props.fieldEnabled.current = false;
                        }}
                        onChange={(e) => {
                          props.actionsArray[0] = e.target.value;
                          props.setActionsArray([...props.actionsArray]);
                          // const urlRegex = /^(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/;
                          // if(!urlRegex.test(e.target.value) && e.target.value.trim()!== "") {
                          //   props.setIsRight(false);
                          //   console.log("UP",document.querySelector(".ant-collapse-header"));
                          // }
                          // else{
                          //   props.setIsRight(true);
                          // }
                        }}
                        placeholder="Web address/URL"
                        className="h-9 mt-4 w-full  editor-input "
                      />
                      <div className="flex justify-center action-select">
                        
                        <Popover
                    placement="left"
                    arrow={false}
                    open={openLink}
                    onOpenChange={handleOpenLink}
                    content={
                      <div
                        className={`bg-[#1D1D1F] w-[200px] absolute ${props.actionsArray[1] ===0?"top-[-35px]":"top-[-5px]"} right-[-200px]`}
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        <div className=" overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2">
                        {
                          LinkOptions.map((item,index)=>{
                             return (
                              <div className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                               onClick={()=>{
                                props.actionsArray[1] = item.value;
                                props.setActionsArray([...props.actionsArray]);
                                handleOpenLink();
                               }}
                              > 
                                <div className="px-2 w-[30px] h-[20px]">
                                  {props.actionsArray[1] === item.value && <img className="w-[100%] h-[100%]" src={PopTick} alt="P"/> }
                                </div>
                                <div className="text-[#ffffff] text-[14px] ">
                                  {item.label}
                                </div>
                              </div>
                             )
                          })
                        }
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div className="mx-4 cursor-pointer"
                    style={{
                      textAlign : "center",
                      height : "28px"
                    }}
                    >
                     <div className={`flex mt-5`}>
                       <div className="ml-2 text-[14px]">
                       {props.actionsArray[1]===0?"Open link in same window":"Open link in new window"}
                       </div>
                       <div className="mt-[7px] ml-4">
                       <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="10px" height="10px" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                       </div>
                     </div>
                    </div>
                  </Popover>
                      </div>
                    </div>
                  )}
                  {props.action === 2 && (
                    <div className="mt-5 ">
                      <div className="text-left text-sm text-[#c0bdbd]">
                        This action will seek the video to the timings mentioned
                        below.
                      </div>
                      <div className="flex items-start  relative pt-4">
                        <div className="items-center rounded-sm  gap-2 flex justify-start mr-4">
                          <div className="w-[136px] text-left pt-1">
                            Seek video to
                          </div>
                        </div>

                        <InputNumber
                          type="number"
                          min="0"
                          disabled = {props.isModalOpen}
                          readOnly = {!props.isRight}
                          value={props.actionsArray[0]}
                          // onBlur = {()=>{
                          //   let arr  = [...props.actionsArray];
                          //   arr.pop();
                          //   arr.push(props.actionSeek)
                          //   props.setActionsArray(arr);
                          // }}
                          onClick={() => {
                            // props.setFieldEnabled(true);
                            props.fieldEnabled.current = true;
                          }}
                          onStep={()=>{
                            props.fieldEnabled.current = true;
                          }}
                          onBlur={() => {
                            // props.setFieldEnabled(false);
                            props.fieldEnabled.current = false;
                          }}
                          onChange={(value) => {
                            // props.setActionSeek(value);
                            props.actionsArray[0] = value;
                            props.setActionsArray([...props.actionsArray]);
                          }}
                          className={`editor-inum w-[60px]`}
                        />
                        <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-5 right-4">
                          sec
                        </span>
                      </div>
                    </div>
                  )}
                  {props.action === 3 && (
                    <div className="mt-5 ">
                      <div className="text-left text-sm text-[#c0bdbd]">
                        This action will store interactions & seek the video to
                        the timings mentioned below.
                      </div>
                      <div className="flex items-start  relative pt-4">
                        <div className="items-center rounded-sm  gap-2 flex justify-start mr-4">
                          <div className="w-[136px] text-left pt-1">
                            Seek video to
                          </div>
                        </div>

                        <InputNumber
                          type="number"
                          min="0"
                          disabled = {props.isModalOpen}
                          readOnly = {!props.isRight}
                          value={props.actionsArray[0]}
                          onBlur={() => {
                            // props.setFieldEnabled(false);
                            props.fieldEnabled.current = false;
                          }}
                          onClick={() => {
                            // props.setFieldEnabled(true);
                            props.fieldEnabled.current = true;
                          }}
                          onStep={()=>{
                            props.fieldEnabled.current = true;
                          }}
                          onChange={(value) => {
                            // props.setActionSeek(value);
                            props.actionsArray[0] = value;
                            props.setActionsArray([...props.actionsArray]);
                          }}
                          className={`editor-inum w-[60px]`}
                        />
                        <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-5 right-4">
                          sec
                        </span>
                      </div>
                      
                      <Popover
                    placement="left"
                    arrow={false}
                    open={openStore}
                    onOpenChange={handleOpenStore}
                    content={
                      <div
                        className={`bg-[#1D1D1F] w-[150px] absolute ${props.actionsArray[1] ===0?"top-[-20px]":"top-[-50px]"} right-[-150px]`}
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        <div className=" overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2">
                        {
                          StoreOptions.map((item,index)=>{
                             return (
                              <div className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                               onClick={()=>{
                                props.actionsArray[1] = item.value;
                                props.setActionsArray([...props.actionsArray]);
                                handleOpenStore();
                               }}
                              > 
                                <div className="px-2 w-[30px] h-[20px]">
                                  {props.actionsArray[1] === item.value && <img className="w-[100%] h-[100%]" src={PopTick} alt="P"/> }
                                </div>
                                <div className="text-[#ffffff] text-[14px] ">
                                  {item.label}
                                </div>
                              </div>
                             )
                          })
                        }
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div className="mx-4 cursor-pointer"
                    style={{
                      textAlign : "center",
                      height : "28px"
                    }}
                    >
                     <div className={`flex mt-5`}>
                       <div className="ml-2 text-[14px]">
                       {props.actionsArray[1]===0?"Current Screen":"All Previous"}
                       </div>
                       <div className="mt-[7px] ml-4">
                       <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="10px" height="10px" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                       </div>
                     </div>
                    </div>
                  </Popover>
                    </div>
                  )}
                  {props.action === 4 && (
                    <div className="mt-5 action-select">
                      <div className="text-left text-sm text-[#c0bdbd]">
                        This action will store interactions & will show the
                        following response.
                      </div>
                      <div className="flex items-start  relative pt-4">
                        <div className="items-center rounded-sm  gap-2 flex justify-start mr-4">
                          <div className="w-[136px] text-left ">
                            Seek after response message
                          </div>
                        </div>

                        <InputNumber
                          type="number"
                          defaultValue={1}
                          disabled = {props.isModalOpen}
                          readOnly = {!props.isRight}
                          value={props.actionsArray[0]}
                          onBlur={() => {
                            if (
                              props.actionsArray[0] === null ||
                              props.actionsArray[0] <= 0
                            ) {
                              props.actionsArray[0] = 1;
                              props.setActionsArray([...props.actionsArray]);
                            }
                            // props.setFieldEnabled(false);
                            props.fieldEnabled.current = false;
                          }}
                          onStep={()=>{
                            props.fieldEnabled.current = true;
                          }}
                          onClick={() => {
                            // props.setFieldEnabled(true);
                            props.fieldEnabled.current = true;
                          }}
                          onChange={(value) => {
                            // props.setActionSeek(value);
                            props.actionsArray[0] = value;
                            props.setActionsArray([...props.actionsArray]);
                          }}
                          className={`editor-inum w-[60px]   mt-1`}
                        />
                        <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-5 right-4">
                          sec
                        </span>
                      </div>
                      <Input
                        // value={props.responseMessage}
                        value={props.actionsArray[2]}
                        disabled = {props.isModalOpen}
                        readOnly = {!props.isRight}
                        onBlur={() => {
                          // props.setFieldEnabled(false);
                          props.fieldEnabled.current = false;
                        }}
                        onClick={() => {
                          // props.setFieldEnabled(true);
                          props.fieldEnabled.current = true;
                        }}
                        onChange={(e) => {
                          // props.setResponseMessage(e.target.value);
                          props.actionsArray[2] = e.target.value;
                          props.setActionsArray([...props.actionsArray]);
                        }}
                        placeholder="Response Message"
                        className="h-9 mt-4 w-full  editor-input "
                      />
                      <Popover
                    placement="left"
                    arrow={false}
                    open={openStore}
                    onOpenChange={handleOpenStore}
                    content={
                      <div
                        className={`bg-[#1D1D1F] w-[150px] absolute ${props.actionsArray[1] ===0?"top-[-20px]":"top-[-50px]"} right-[-150px]`}
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        <div className=" overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2">
                        {
                          StoreOptions.map((item,index)=>{
                             return (
                              <div className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                               onClick={()=>{
                                props.actionsArray[1] = item.value;
                                props.setActionsArray([...props.actionsArray]);
                                handleOpenStore();
                               }}
                              > 
                                <div className="px-2 w-[30px] h-[20px]">
                                  {props.actionsArray[1] === item.value && <img className="w-[100%] h-[100%]" src={PopTick} alt="P"/> }
                                </div>
                                <div className="text-[#ffffff] text-[14px] ">
                                  {item.label}
                                </div>
                              </div>
                             )
                          })
                        }
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div className="mx-4 cursor-pointer"
                    style={{
                      textAlign : "center",
                      height : "28px"
                    }}
                    >
                     <div className={`flex mt-5`}>
                       <div className="ml-2 text-[14px]">
                       {props.actionsArray[1]===0?"Current Screen":"All Previous"}
                       </div>
                       <div className="mt-[7px] ml-4">
                       <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="10px" height="10px" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                       </div>
                     </div>
                    </div>
                  </Popover>
                    </div>
                  )}
                  {props.action === 5 && (
                    <div className="text-left mt-5 text-[#c0bdbd]">
                      <div>
                        This action will open phone application.
                      </div>
                      <InputNumber
                        value={props.actionsArray[0]}
                        controls = {false}
                        onClick={() => {
                          props.fieldEnabled.current = true;
                        }}
                        onBlur={() => {
                          props.fieldEnabled.current = false;
                        }}
                        onChange={(value) => {
                          props.actionsArray[0] = value;
                          props.setActionsArray([...props.actionsArray]);
                        }}
                        placeholder="Enter Mobile Number"
                        className="h-9 mt-4 w-full editor-inum "
                      />
                    </div>
                  )}
                </div>
              </div>
              </div>
            </Panel>
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default Action;
