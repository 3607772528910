import React, { useCallback, useEffect, useState } from "react";
import { Skeleton } from "antd";
import "../../common/styles/commonStyles.css";
import { useNavigate } from "react-router-dom";
import clevertap from "clevertap-web-sdk";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import ShowPreviewVideoModal from "../../common/components/Modal/template/ShowPreviewVideoModal";

const SeeAllTemplateCard = ({ template, custregid }) => {
  const navigate = useNavigate();
  const userAgent = navigator.userAgent;
  custregid = localStorage.getItem("sessionUserId");
  const [isLoading, setIsLoading] = useState(true);
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFinalLink, setPreviewFinalLink] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const prevLink = template.TEMPLATEPREVIEWLINK || null;
  const prevLinkStatus = template.TEMPLATEPREVIEWLINKSTATUS || null;



  const abc = () => {
    console.log("2 INSIDE  abc ");
    if (prevLink !== null) {
      const baseURL = "https://vsp.gy/";
      const newPrevLink = "https://app.vspagy.com/user/link?id=";

      if (prevLink.startsWith(baseURL)) {
        // Extract the endpoint by removing the base prevLink
        const endpoint = prevLink.slice(baseURL.length);
        const finalLink = newPrevLink + endpoint;
        console.log(finalLink, "ENDPOINT");
        setPreviewFinalLink(finalLink);
        return finalLink;
      } else {
        // If the URL doesn't start with the base URL, return null or handle as needed
        return null;
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const openPreviewModal = () => {
    abc();
    setPreviewModal(true);
    setIsSpinning(true);
  };

  const [shouldRunChangeWrapperCSS, setShouldRunChangeWrapperCSS] =
    useState(false);

    const aspectRatio = JSON.parse(template.TEMPLATEDATA);
  const templateAspectRatio = aspectRatio.templateData?.aspect || "";

  const changeWrapperCSS = useCallback((templateAspectRatio) => {
    console.log("3 Inside Wrapper css");

    console.log(templateAspectRatio, "4 Inside RATIO Wrapper css");
    if (templateAspectRatio === "16:9") {
      console.log("INSIDE Rendering aspect ratio: 16:9");
    } else if (templateAspectRatio === "9:16") {
      console.log("INSIDE Rendering aspect ratio: 9:16");
    } else if (templateAspectRatio === "1:1") {
      console.log("INSIDE Rendering aspect ratio: 1:1");
    } else {
      console.log("INSIDE Rendering default aspect ratio");
    }

    const iframe = document.getElementById("preview-iframe-id");
    if (!iframe) {
      console.error("Wrapper Iframe element not found.");
      return;
    }

    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDocument) {
      console.error("Wrapper Iframe document not accessible.");
      return;
    }

    const wrapperElement = iframeDocument.querySelector(".wrapper");
    if (!wrapperElement) {
      console.error("Wrapper div not found inside iframe content.");
      return;
    }

    // Remove the 'vjs-control-bar' class from the div with class '.vjs-control-bar'
    const controlBarElement = iframeDocument.querySelector(".vjs-control-bar");
    if (controlBarElement) {
      controlBarElement.classList.remove("vjs-control-bar");
    }

    if (templateAspectRatio === "16:9") {
      console.log("Rendering aspect ratio: 16:9");
      wrapperElement.style.width = "100%"; // Adjust width for 16:9 aspect ratio
    } else if (templateAspectRatio === "9:16") {
      console.log("Rendering aspect ratio: 9:16");
      wrapperElement.style.width = "196px"; // Adjust width for 9:16 aspect ratio
    } else if (templateAspectRatio === "1:1") {
      console.log("Rendering aspect ratio: 1:1");
      wrapperElement.style.width = "73%"; // Adjust width for 1:1 aspect ratio
    } else {
      console.log("Rendering default aspect ratio");
      wrapperElement.style.width = "100%"; // Default width
    }
  }, []);

  useEffect(() => {
    if (shouldRunChangeWrapperCSS) {
      changeWrapperCSS(templateAspectRatio);
      setShouldRunChangeWrapperCSS(false);
    }
  }, [shouldRunChangeWrapperCSS, templateAspectRatio, changeWrapperCSS]);

  // Add a state to track whether iframe content has loaded
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
    // Run changeWrapperCSS when iframe content has loaded
    setShouldRunChangeWrapperCSS(true);
  };

  

  const Templates_Viewed = (templateDetail) => {
    clevertap.event.push("Templates_Viewed", {
      USER_ID: custregid,
      Email: custregid,
      TemplateName: templateDetail.templatename,
      TemplateID: templateDetail.templateid,
      Template: "Global",
      UseCaseName: templateDetail.templateUseCase,
      CategoryName: templateDetail.templateCategory,
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Templates_Viewed EVENT CLICKED");
  };

  const navigateToAnotherRoute = () => {
    navigate(
      `${process.env.REACT_APP_BASE_PATH}/template-selected/${template.TEMPLATEID}`,
      {
        state: {
          templateid: template.TEMPLATEID,
          templatename: template.TEMPLATENAME,
          templatedesc: template.TEMPLATEDESC,
          thumbnail: template.TEMPLATETHUMBNAIL,
          credits: template.TEMPLATECREDITS,
          templateAspectRatio: templateAspectRatio,
          templateUseCase: template.TEMPLATEUSECASE,
          templateCategory: template.TEMPLATECATEGORY,
        },
      }
    );
    const templateDetail = {
      templateid: template.TEMPLATEID,
      templatename: template.TEMPLATENAME,
      templateUseCase: template.TEMPLATEUSECASE,
      templateCategory: template.TEMPLATECATEGORY,
    };
    console.log(templateDetail, "T DETAILS");
    Templates_Viewed(templateDetail);
  };

  return (
    <>
      <div
        className="p-3 hover:shadow-sm rounded-sm transition duration-300 transform"
        onMouseEnter={() => {
          if (prevLink !== null) {
            setIsHovered(true);
          }
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          if (prevLink !== null && prevLinkStatus === "1000") {
            openPreviewModal();
          } else {
            navigateToAnotherRoute();
          }
        }}
      >
        <div className="cursor-pointer relative">
          {isLoading ? (
            <Skeleton.Image active className="!w-full" />
          ) : (
            <img
              alt="Thumbnail"
              src={
                template.TEMPLATETHUMBNAIL
                  ? `${process.env.REACT_APP_SOURCE_TEMPLATE_BUCKET_BASE_URL}TEMPLATE/${template.TEMPLATEID}/SOURCE/${template.TEMPLATETHUMBNAIL}`
                  : `https://placehold.co/230x230`
              }
              style={{ width: "100%", borderRadius: "8px" }}
            />
          )}
          {prevLink !== null && prevLinkStatus === '1000' && isHovered && (
            <div className="absolute inset-0 bg-gray-700 opacity-0 hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
              <span
                className="relative flex items-center justify-center overflow-hidden group bg-[#E16023] hover:bg-gradient-to-r hover:from-[#E16023] hover:to-[#d5632e] rounded-[50px] px-3 py-1 cursor-pointer transition-all ease-out duration-300"
                onClick={openPreviewModal}
              >
                <span className="absolute right-0 w-8 h-24 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-20 rotate-12 group-hover:-translate-x-40 ease"></span>
                <span className="text-sm font-medium text-white relative select-none">
                  Preview
                </span>
              </span>
            </div>
          )}
          {template.TEMPLATETYPE === "PRO" ? (
            <div className="absolute top-2 right-2 bg-[#7C58E1] text-white text-xs px-2 py-1 rounded-[10px]">
              {template.TEMPLATETYPE}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="cursor-pointer mt-2" onClick={navigateToAnotherRoute}>
          {isLoading ? (
            <div>
              <Skeleton active paragraph={{ rows: 2 }} className="!h-[4px]" />
            </div>
          ) : (
            <span className="flex justify-between items-center">
              {/* <div className="font-normal text-[#3D3C3C] pb-1 text-xs">
                {template.TEMPLATEPATTERN}
              </div> */}
              <div className={`font-normal text-black pb-1 ${isDesktop ? "text-sm" : "text-xs"}`}>
                {template.TEMPLATENAME}
              </div>
              {/* <div className="text-[10px] text-[#6B6B6B]">
                {template.TEMPLATECATEGORY} | {template.TEMPLATEUSECASE}
              </div> */}
              <div className={` ${isDesktop ? "text-[11px]" : "text-[10px]"} font-semibold text-[#6B6B6B]`}>
                {templateAspectRatio}
              </div>
            </span>
          )}
        </div>
      </div>
      {previewModal && (
        <ShowPreviewVideoModal
          dialog={previewModal}
          setDialog={setPreviewModal}
          templatename={template.TEMPLATENAME}
          templatedesc={template.TEMPLATEDESC}
          previewfinallink={previewFinalLink}
          iframeload={handleIframeLoad}
          spinning={isSpinning}
          iframeloaded={iframeLoaded}
        />
      )}
    </>
  );
};

export default SeeAllTemplateCard;
