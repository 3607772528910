import './App.css';
import 'typeface-inter';
import "antd/dist/reset.css";
import MainRoutes from './saasWebsite/routes/MainRoutes'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Zoom } from 'react-toastify';
import 'animate.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
function App() {
  const location = useLocation();

  useEffect(() => {
    const route = location.pathname;
    console.log("route",route);
    if (route !== '/user/editor/updateTemplate' && (!route.includes('/user/ivideo-preview'))) {
      console.log("route", route);
      localStorage.removeItem('previewData');
    }
  }, [location]);
  return (
    <>
      {<MainRoutes />}
      <ToastContainer
        autoClose={2500}
        transition={Zoom}
        hideProgressBar
        position="top-center"
        pauseOnFocusLoss
        pauseOnHover
        closeOnClick
        draggable
        theme="light"
        // bodyClassName={() => "flex items-center text-xs h-[52px] m-0"}
      />
    </>
  );
}

export default App;
