import React from "react";
import { Table } from "antd";
import { useState } from "react";
import InputBox from "../../../common/components/InputBox";
import useWindowWidth from "../../../common/hooks/useWindowWidth";

const CreditTable = ({ creditDataList, tableHeading }) => {
  console.log(creditDataList, "CREDIT DATA LIST");

  // const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuffixIcon, setShowSuffixIcon] = useState(false);
  const [prefixActive, setPrefixActive] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;

  // console.log(creditDataList, "C DATA");

  // const columns = Object.keys(creditDataList[0])?.map((key) => ({
  //   title: key,
  //   dataIndex: key,
  //   key: key,
  // }));
  const columns =
    creditDataList && creditDataList.length > 0
      ? Object.keys(creditDataList[0]).map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
        }))
      : [];

  // const filteredData = creditDataList.filter((item) =>
  //   Object.values(item).some((value) =>
  //     value.toString().toLowerCase().includes(searchText.toLowerCase())
  //   )
  // );
  const filteredData = creditDataList
    ? creditDataList.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : [];

    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
      // console.log(e.target.value, "SEARCH TEXT");
    };

  if (!creditDataList || creditDataList.length === 0) {
    return (
      <>
        <div className="bg-white p-3">
          <div className="flex justify-between items-center mb-6">
            <div className={`flex ${isLargeScreen ? "items-center" : "flex-col"} `}>
              <span className={` ${isLargeScreen ? "text-[20px]" : "text-[17px]"} font-semibold text-black`}>
                Credits Report for {tableHeading}
              </span>
              <InputBox
                searchIconClass="px-1 h-[15px] w-[23px]"
                xIconClass="p-[1px]"
                className={` ${isLargeScreen ? "w-80 ml-3" : "w-full mt-2"} h-9 text-[13px] rounded-3xl global-search`}
                placeholder="Search from hundreds of templates..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClearClick={() => {
                  setSearchTerm(""); // Clear the input value
                }}
                showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                prefixActive={prefixActive} // Pass prefixActive prop
                setShowSuffixIcon={setShowSuffixIcon}
                setPrefixActive={setPrefixActive}
              />
            </div>
            {/* <span>Download</span> */}
          </div>
          <Table
            dataSource={[]}
            columns={[]}
            rowKey="ID"
            // scroll={{ x: columns.length <= 6 ? 500 : 1900 }}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="bg-white p-3">
          <div className="flex justify-between items-center mb-6">
            <div className={`flex ${isLargeScreen ? "items-center" : "flex-col"} `}>
              <span className={` ${isLargeScreen ? "text-[20px]" : "text-[17px]"} font-semibold text-black`}>
                Credits Report for {tableHeading}
              </span>
              <InputBox
                searchIconClass="px-1 h-[15px] w-[23px]"
                xIconClass="p-[1px]"
                className={` ${isLargeScreen ? "w-80 ml-3" : "w-full mt-2"} h-9 text-[13px] rounded-3xl global-search`}
                placeholder="Search from hundreds of templates..."
                value={searchTerm}
                // onChange={(e) => setSearchTerm(e.target.value)}
                onChange={handleSearch}
                onClearClick={() => {
                  setSearchTerm(""); // Clear the input value
                }}
                showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                prefixActive={prefixActive} // Pass prefixActive prop
                setShowSuffixIcon={setShowSuffixIcon}
                setPrefixActive={setPrefixActive}
              />
            </div>
            {/* <span>Download</span> */}
          </div>
          <Table
            dataSource={filteredData ? filteredData : ""}
            columns={columns}
            rowKey="ID"
            scroll={{ x: columns.length <= 6 ? "w-full" : isDesktop ? 1900 : 1000 }}
          />
        </div>
      </>
    );
  }

  // return (
  //   <>
  //     <span className="search-bar">
  //       <Input.Search
  //         placeholder="Search..."
  //         onChange={handleSearch}
  //         onSearch={handleSearch}
  //         value={searchText}
  //         style={{ marginBottom: 16, width: 250 }}
  //         enterButton={false}
  //       />
  //     </span>
  //     <Table
  //       dataSource={filteredData ? filteredData : ''}
  //       columns={columns}
  //       rowKey="ID"
  //       scroll={{ x: columns.length <= 6 ? 500 : 1900 }}
  //     />
  //   </>
  // );
};

export default CreditTable;
