import React from 'react';
import PlayReportMain from './playreport/common/PlayReportMain'
import useWindowWidth from '../common/hooks/useWindowWidth';

const PlayReport = () => {

  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  return (
    <div className={` ${isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"} h-[calc(100vh_-_3.75rem)] flex flex-col overflow-x-hidden`}>
        <div className={`bg-[#F7FAFC] ${isDesktop ? "px-11 pt-9" : "px-7 pt-7"}  pb-4 flex flex-col`}>
        <span className="text-[13px] font-normal mb-2">/ Analytics</span>
          <span className={` ${isDesktop ? "text-[22px]" : "text-[20px]"}  font-semibold`}>Heatmap Report</span>
        </div>
        <PlayReportMain />
      </div>
  )
}

export default PlayReport