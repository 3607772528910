import React from "react";
import { useNavigate } from "react-router-dom";
import notFound from "../../assests/commonImages/not-found-404.png";
import SimpleButton from "../../common/components/SimpleButton";

// This page is responsible for displaying ERROR for invalid URLs (Only used in MainRoutes.js)
const NotFound = ({previewCheck}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#FBFBFB] flex flex-col justify-center items-center h-[calc(100vh_-_60px)]">
      <div>
        <img src={notFound} alt="404 page not found" />
      </div>
      <div className="flex flex-col justify-center items-center">
        <span className="text-[#333333] text-xl font-semibold">
         {previewCheck && <span>No Video Preview Found</span>}
         {!previewCheck && <span>Page Not Found</span>} 
        </span>
        <span className="text-base text-[#7A7A7A] mt-2">
         {previewCheck && <span>Either no working template present or you haven't saved the template</span>}
         {!previewCheck && <span>We’re not able to find what you are looking for.</span>}
          
        </span>
      </div>
      {!previewCheck && <div className="mt-4">
        <SimpleButton
          label="Go Back"
          size="large"
          type="primary"
          onClick={() => navigate(-1)}
        />
      </div>}
    </div>
  );
};

export default NotFound;
