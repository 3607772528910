import { DatePicker, Select, Space } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useRef } from 'react';
import SimpleButton from '../../../common/components/SimpleButton';
import { getDashboardProjectData, getPlayReportDataSearch } from '../../../api/api_updateData';
import useWindowWidth from '../../../common/hooks/useWindowWidth';


const { RangePicker } = DatePicker;
const { Option } = Select;

dayjs.extend(customParseFormat);

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf("day");
};

const PlayReportHeader = ({playReportData,
  sessionuserid,
  onSearchResult,
  updateDashboard, pageTitle}) => {

  const prData = playReportData;
  sessionuserid = localStorage.getItem("sessionUserId");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedDays, setSelectedDays] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [customDateVisible, setCustomDateVisible] = useState(false);
  const [isActive, setIsActive] = useState("#D9D9D9");
  const selectDateDivRef = useRef(null);
  const [projectOptions, setProjectOptions] = useState([
    // { value: "all", label: "All Projects" },
  ]);
  const [isProjectOptionsDisabled, setIsProjectOptionsDisabled] =
    useState(false);

     // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  const handleDivClick = () => {
    setIsActive("#4095FE");
  };

  function handleDateChange(date, dateString) {
    // console.log("Selected Date:", dateString);
    setSelectedDays("");
    const fromDate = dateString[0];
    const toDate = dateString[1];
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
  }

  //   DAYS SELECTED FROM DATE RANGE
  const handleSelectChange = (value) => {
    if (value === "date") {
      setCustomDateVisible(true);
      // console.log(value, "DATE");
    } else {
      setCustomDateVisible(false);
      //   console.log(value, "DATE")
      const days = value;
      setSelectedDays(days);
      setSelectedFromDate("");
      setSelectedToDate("");
    }
  };

  const handleOutsideClick = (e) => {
    if (
      selectDateDivRef.current &&
      !selectDateDivRef.current.contains(e.target)
    ) {
      setIsActive("#D9D9D9");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const { mutate: getTempProjectDataMutation } = useMutation(
    (tempProjectData) => getDashboardProjectData(tempProjectData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          const projectData = data.DATA;
          updateProjectOptions(projectData);
          setIsProjectOptionsDisabled(false);
          updateDashboard();
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const handleDashboardProject = (value) => {
    const tempProjectData = {
      templateid: value,
      sessionuserid: sessionuserid,
    };

    getTempProjectDataMutation(tempProjectData);
  };

  
  const templateOptions = pageTitle === "Data Report" ?
  [
    ...(prData && prData.length > 0
      ? prData[0].TEMPLATES.map((template) => ({
          value: template.TEMPLATEID,
          label: template.TEMPLATENAME,
        }))
      : []),
  ] :
  [
    { value: "all", label: "All Templates" },
    ...(prData && prData.length > 0
      ? prData[0].TEMPLATES.map((template) => ({
          value: template.TEMPLATEID,
          label: template.TEMPLATENAME,
        }))
      : []),
  ];

  const handleTemplateChange = (value) => {
    setSelectedTemplateId(value);
    if (value !== "all") {
      handleDashboardProject(value);
      
    } else if (value === "all") {
      setIsProjectOptionsDisabled(false);
      const updatedOptions = [
        { value: "all", label: "All Projects" }
      ];
      setProjectOptions(updatedOptions);
    }
  };

  const filterOptionTemplate = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const { mutate: getDashDataSearch } = useMutation(
    (searchData) => getPlayReportDataSearch(searchData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          //   console.log(data.DATA, "SEARCH DATA");
          const searchData = data.DATA;
          onSearchResult(searchData);
          updateDashboard();
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const updateProjectOptions = (projectData) => {
    // debugger
    const parsedData = JSON.parse(projectData);
    // console.log("Project Data:", parsedData);
    const updatedOptions = [
      { value: "all", label: "All Projects" },
      ...parsedData.map((project) => ({
        value: project.PROJECTID,
        label: project.PROJECTNAME,
      })),
    ];
    setProjectOptions(updatedOptions);
    // setIsProjectOptionsDisabled(false);
  };

  const handleProjectChange = (value) => {
    setSelectedProjectId(value);
  };

  const filterOptionProject = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // WHEN SEARCH RESULT BUTTON IS CLICKED THIS FUNCTION WILL RUN
  const handleSearchResult = () => {
    if(selectedTemplateId === null || selectedProjectId === null) {
      toast.error("Please select filter");
    } else {
      const searchData = {
        templateid: selectedTemplateId,
        projectid: selectedProjectId,
        sessionuserid: sessionuserid,
        days: selectedDays,
        fromdate: selectedFromDate,
        todate: selectedToDate,
      };
      // console.log("SEARCH DATA JSON:", searchData);
      getDashDataSearch(searchData);
    }
    
  };

  return (
    <>
      <div className={` ${isDesktop ? "h-[80px]" : "h-[100px] pb-4"} w-full flex items-center`}>
        <div className="flex justify-between w-[100%]">
          <div className="">
            <Space size={12} align="center" className="dashboard-dropdown flex flex-wrap">
              <Select
                placeholder="Select Templates"
                showSearch
                filterOption={filterOptionTemplate}
                style={{
                  width: 150,
                  borderRadius: "4px",
                }}
                onChange={handleTemplateChange}
                options={templateOptions}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
              <Select
                // defaultValue="All Projects"
                placeholder="Select Projects"
                showSearch
                filterOption={filterOptionProject}
                style={{
                  width: 150,
                  borderRadius: "4px",
                }}
                onChange={handleProjectChange}
                options={projectOptions}
                disabled={isProjectOptionsDisabled}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
              <div
                style={{
                  position: "relative",
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: isActive,
                }}
                className="selectDateDiv"
                onClick={handleDivClick}
                ref={selectDateDivRef}
              >
                <Select
                  defaultValue="7"
                  style={{
                    width: isDesktop ? 285 : 210,
                    height: 31,
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  bordered={false}
                  onChange={handleSelectChange}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  <Option value="0">Today</Option>
                  <Option value="1">Yesterday</Option>
                  <Option value="7">Last 7 Days</Option>
                  <Option value="30" Select>
                    Last 30 Days
                  </Option>
                  <Option value="90">Last 90 Days</Option>
                  <Option value="date">Custom Date</Option>
                </Select>
                <span>
                  {customDateVisible && (
                    <RangePicker
                      className="RangeDate cursor-pointer"
                      onChange={handleDateChange}
                      bordered={false}
                      popupClassName={`${isMobile ? "popupCalendar " : ""}`}
                      style={{
                        width: isDesktop ? 260 : 185,
                        height: 30,
                        position: "absolute",
                        left: 0,
                        top: 0,
                      }}
                      disabledDate={disabledDate}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    />
                  )}
                </span>
              </div>
              <span>
                <SimpleButton
                  label={isDesktop ? "Search Results" : "Search"}
                  type="primary"
                  onClick={handleSearchResult}
                />
              </span>
            </Space>
          </div>
          {/* ********* TO BE USED IN FUTURE ********** */}
          {/* <div style={{ borderRadius: "4px" }}>
            <Select
              defaultValue="Download"
              style={{
                width: 150,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "pdf",
                  label: "pdf",
                },
                {
                  value: "xml",
                  label: "xml",
                },
                {
                  value: "csv",
                  label: "scv",
                },
              ]}
            />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default PlayReportHeader