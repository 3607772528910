import { useEffect } from "react";

// Custom hook to be use to check whether the user is in session or not.
const useSessionChecker = (navigate) => {
  useEffect(() => {
    const checkSessionUserId = () => {
      const sessionuserid = localStorage.getItem("sessionUserId");
      if (!sessionuserid) {
        navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
      }
    };

    checkSessionUserId(); // Check for session ID initially

    const intervalId = setInterval(() => {
      checkSessionUserId(); // Check for session ID within the interval
      console.log("session check")
    }, 5000);

    return () => clearInterval(intervalId); // Clean up the interval when the component unmounts
  }, [navigate]);
};

export default useSessionChecker;
