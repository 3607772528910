import React from "react";
import { Modal } from "antd";
import desktopComputer from "../../../assests/ResponsiveIcons/desktopComputer.png";
import SimpleButton from "../SimpleButton";

const MobileResModal = (props) => {
  const handleCancel = () => {
    props.setMobileResModal(false);
  };
  return (
    <>
      <Modal
        open={props.mobileResModal}
        footer={null}
        width={400}
        closable={false}
      >
        <div className="flex justify-center flex-col items-center my-5 mt-5 mb-3">
          <img src={desktopComputer} alt="computer" />
          <p className="text-[18px] font-medium text-[#1F1F1F] mt-3 text-center">
            This feature is available on Desktop
          </p>
          <p className="text-[14px] font-normal text-[#757575] mt-1 text-center px-3">
            To fully explore the potential of the VSPAGY platform, you need to
            access it through a desktop.
          </p>
          <SimpleButton
            className="bg-[#056DE8] text-[16px] border-none text-[#ffffff] rounded-[4px] px-6 h-[40px] mt-5"
            label="Alright!"
            onClick={handleCancel}
          />
        </div>
      </Modal>
    </>
  );
};
export default MobileResModal;
