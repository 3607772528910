export function divObj(startX,startY,height,width,color,rotate,id,zindex,border,starttime,stoptime,visibility,textColor,borderColor,flip,opacity,textalign,fontsize,fontfamily,textdecoration,fontstyle,fontweight,letterspacing,lineheight,borderradius,layername,cltoggle,emtoggle,placeholder,characterlimit,errormessage,name,type,subtype,pausetoggle,resumetoggle,pausetime,resumein,resumeto,seekto,optionsarray,animationentryspeed,animationexitspeed,animationtype,exitanimation,entryanimation,icon,value,payload,draggable,action,defaultchecked,actionsarray,tlborderradius,trborderradius,blborderradius,brborderradius,svgContent){
    this.id = id
    this.left = startX;
    this.top = startY;
    this.height = height;
    this.width = width;
    this.backgroundColor = color;
    this.rotate = rotate;
    this.zindex = zindex;
    this.textColor = textColor;
    this.borderColor = borderColor;
    this.flip = flip;
    this.opacity = opacity;
    this.textalign = textalign;
    this.fontsize = fontsize;
    this.fontfamily = fontfamily;
    this.textdecoration = textdecoration;
    this.fontstyle = fontstyle;
    this.fontweight = fontweight;
    this.letterspacing = letterspacing;
    this.lineheight = lineheight;
    this.border = border;
    this.borderradius = borderradius;
    this.layername = layername;
    this.cltoggle = cltoggle;
    this.emtoggle = emtoggle;
    this.placeholder = placeholder;
    this.characterlimit = characterlimit;
    this.errormessage = errormessage;
    this.name  =  name;
    this.type = type;
    this.subtype = subtype;
    this.starttime = starttime;
    this.stoptime = stoptime;
    this.pausetoggle = pausetoggle;
    this.resumetoggle = resumetoggle;
    this.pausetime  = pausetime;
    this.resumein = resumein;
    this.resumeto = resumeto;
    this.seekto = seekto;
    this.optionsarray = optionsarray;
    this.animationentryspeed = animationentryspeed;
    this.animationexitspeed = animationexitspeed;
    this.animationtype = animationtype;
    this.exitanimation = exitanimation;
    this.entryanimation = entryanimation;
    this.icon = icon;
    this.value = value;
    this.payload = payload;
    this.draggable = draggable;
    this.visibility = visibility;
    this.action = action;
    this.defaultchecked = defaultchecked;
    this.actionsarray = actionsarray;
    this.tlborderradius = tlborderradius;
    this.trborderradius = trborderradius;
    this.blborderradius = blborderradius;
    this.brborderradius = brborderradius;
    this.svgContent = svgContent;
}