import React, { useState } from "react";
import { Divider, Input, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router";
import TextArea from "antd/es/input/TextArea";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SimpleButton from "../../SimpleButton";
import {
  createBrandPageClone,
  createBrandPageTemp,
} from "../../../../api/api_updateData";

const CreateBrandPageModal = (props, custregid) => {
  // const sessionuserid = localStorage.getItem("sessionUserId");
  custregid = localStorage.getItem("sessionUserId");
  const sessionuserid = localStorage.getItem("sessionUserId");
  const navigate = useNavigate();

  const [brandPageName, setBrandPageName] = useState("");
  const [brandPageDesc, setBrandPageDesc] = useState("");
  const [isNameTooltipVisible, setIsNameTooltipVisible] = useState(false);

  const handleBrandNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      setBrandPageName(value);
      setIsNameTooltipVisible(value.length < 4);
    }
  };

  const handleBrandNameClick = () => {
    setIsNameTooltipVisible(brandPageName.length < 4);
  };

  const handleBrandNameBlur = () => {
    setIsNameTooltipVisible(false);
  };

  const handleTemplateDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 500) {
      setBrandPageDesc(value);
    } else {
    }
  };

  const { mutate: brandPageTempMutation } = useMutation({
    mutationFn: (brandData) => createBrandPageTemp(brandData),
    onSuccess: (data) => {
      const parsedData = JSON.parse(data.DATA);
      if (data.STATUS === "1000") {
        // toast.success(data.DESCRIPTION);
        // navigate(`${process.env.REACT_APP_BASE_PATH}/brand-demo`, {
        //   state: {
        //     brandUser: parsedData,
        //   },
        // });

        // Add SESSIONUSERID to each object in the DATA array
        const newDataArray = parsedData.map((item, index) => ({
          ...item,
          SESSIONUSERID: sessionuserid,
          BRANDPAGETHUMBNAIL: null,
        }));

        /// Create a new object with the updated DATA property
        const createUserBrandData = {
          ...data,
          DATA: newDataArray,
        };

        // console.log(updateUserBrandData.DATA, "local storage brand data")
        // Store the updated data in localStorage
        localStorage.setItem(
          "createBrandTemplateData",
          JSON.stringify(createUserBrandData)
        );
        navigate(`${process.env.REACT_APP_BASE_PATH}/editor/createBrandTemplate`);
      } else {
        toast.error(data.DESCRIPTION);
      }
    },
  });

  const { mutate: brandPageCloneMutation } = useMutation({
    mutationFn: (cloneBrandData) => createBrandPageClone(cloneBrandData),
    onSuccess: (data) => {
      // const parsedData = JSON.parse(data.DATA);
      if (data.STATUS === "1000") {
        toast.success(data.DESCRIPTION);
        // navigate(`${process.env.REACT_APP_BASE_PATH}/brand-demo`, {
        //   state: {
        //     brandUser: parsedData,
        //   },
        // });
        props.refreshBrandCard();
      } else {
        toast.error(data.DESCRIPTION);
      }
    },
  });

  const handleBrandPageClone = () => {
    const sanitizedDescription = brandPageDesc.replace(/\n/g, " ");
    const cloneBrandData = {
      sessionuserid: sessionuserid,
      brandpageid: props.brandpageid,
      brandpagename: brandPageName,
      brandpagedesc: sanitizedDescription,
    };
    brandPageCloneMutation(cloneBrandData);
  };

  const handleSaveTemplate = () => {
    // Remove newline characters from templateDescription
    const sanitizedDescription = brandPageDesc.replace(/\n/g, " ");
    // Create the Brand data
    const brandData = {
      custregid: custregid,
      brandname: brandPageName,
      branddesc: sanitizedDescription,
    };

    // console.log(brandData, "BRAND DATA");
    brandPageTempMutation(brandData);
  };

  const handleSaveChange = () => {
    if (props.brandpageid) {
      handleBrandPageClone();

      props.setDialog(false);
    } else {
      handleSaveTemplate();
    }
  };

  return (
    <>
      <Modal
        className="create-brand-modal"
        maskClosable={false}
        onCancel={() => {
          props.setDialog(false);
        }}
        centered
        open={props.dialog}
        width={600}
        footer={null}
      >
        <div className="text-lg font-bold text-black text-center mb-8">
          {props.title}
        </div>
        <div className="flex flex-col">
          <div className="text-left">
            <label className="text-[#2F2F2F] font-Inter text-sm font-medium ">
              Brand Page Name
            </label>
            <Tooltip
              open={isNameTooltipVisible} // Show tooltip only when the length is less than 4
              title="Minimum 4 characters required"
              placement="topRight"
            >
              <Input
                className="mt-2 h-[40px]"
                type="text"
                value={brandPageName}
                onChange={handleBrandNameChange}
                onClick={handleBrandNameClick}
                onBlur={handleBrandNameBlur}
              />
            </Tooltip>
          </div>
          <div className="py-4 text-left">
            <span className="flex justify-between items-center">
              <label className="text-[#2F2F2F] font-Inter text-sm font-medium ">
                Brand Page Description
              </label>
              <span className="font-normal text-[#707070] text-[11px]">
                500 Characters limit
              </span>
            </span>
            <TextArea
              autoSize={{
                maxRows: 2,
              }}
              value={brandPageDesc}
              onChange={handleTemplateDescriptionChange}
              className="mt-2 !h-[40px] rounded-[4px] scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full
                scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] overflow-y-auto"
            />
          </div>

          <span className="text-end pt-7">
            <Divider className="divider-temp" />
            <SimpleButton
              label="Save Brand Page"
              type="primary"
              htmlType="submit"
              size="default"
              // onClick={handleSaveTemplate}
              onClick={handleSaveChange}
              disabled={!brandPageName || !brandPageDesc}
              className="!rounded-[4px] w-fit !px mt-4"
            />
          </span>
        </div>
      </Modal>
    </>
  );
};

export default CreateBrandPageModal;
