import { Badge, Divider, Popover, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

// IMPORT IMAGES/SVG
import Threedots from "../../assests/commonIcons/threedots.svg";
import rightArrowIcon from "../../assests/commonIcons/rightArrowIcon.svg";
// import api from "../../assests/commonIcons/api.svg";
// import rocket from "../../assests/commonIcons/rocket.svg";
import newAPIIcon from "../../assests/commonIcons/templateSelectedIcons/newAPIIcon.svg";
import previewIcon from "../../assests/commonIcons/templateSelectedIcons/previewIcon.svg";
import newStartProjectIcon from "../../assests/commonIcons/templateSelectedIcons/newStartProjectIcon.svg";
import CloneDialog from "../../common/components/Modal/template/CloneDialog";
import ApiDialog from "../../common/components/Modal/template/ApiDialog";
import MyTemplateDrawer from "../../common/components/Modal/template/MyTemplateDrawer";
import CloseDialog from "../../common/components/CloseDialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import {
  getApiLink,
  editTemplateApi,
  getProjectDetails,
  totalGeneratedLinks,
} from "../../api/api_updateData";
import EditTemplateModal from "../../common/components/Modal/EditTemplateModal";
import { SyncOutlined } from "@ant-design/icons";
import PreviewLinkGenerateModal from "../../common/components/Modal/PreviewLink/PreviewLinkGenerateModal";
import MapBrandPageModal from "../../common/components/Modal/brand/MapBrandPageModal";
import GeneratePreviewLinkModal from "../../common/components/Modal/PreviewLink/GeneratePreviewLinkModal";
// import PrevLinkGenerate from "../../common/components/Modal/PrevLinkGenerate";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import MobileResModal from "../../common/components/Modal/MobileResModal";
import { CheckOutlined } from "@ant-design/icons";

// import Skeleton from 'react-loading-skeleton'

const UserTemplateCard = ({ template, onRefreshSuccess, customerOwner }) => {
  const sessionuserid = localStorage.getItem("sessionUserId");

  console.log(template, "TP");

  const navigate = useNavigate("");
  const [dialog, setDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiDialog, setApiDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // State for loading state
  const [cloneDialog, setCloneDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false); // FOR DELETE MODAL
  const [editTempModal, setEditTempModal] = useState(false); // FOR EDIT TEMPLATE MODAL
  const [prevLGModal, setPrevGLModal] = useState(false); // FOR PREV LINK GEN MODAL NOT IN USE
  const [prevLinkModal, setPrevLinkModal] = useState(false); // FOR PREV LINK GEN MODAL
  const [totalGL, setTotalGL] = useState(null);
  const [spinIcon, setSpinIcon] = useState(false);
  const [mapDialog, setMapDialog] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  const [mobileResModal, setMobileResModal] = useState(false);
  const [apiLink, setAPILink] = useState("");

  const navigateToAnotherRoute = () => {
    navigate(
      // `${process.env.REACT_APP_BASE_PATH}/start-project/${template.TEMPLATEID}`,
      `${process.env.REACT_APP_BASE_PATH}/go-to-project/${template.TEMPLATEID}`,
      {
        state: {
          ptid: template.TEMPLATEID,
        },
      }
    );
    // console.log("CLicked");
  };

  const navigateToProjectModule = () => {
    navigate(
      `${process.env.REACT_APP_BASE_PATH}/go-to-project/${template.TEMPLATEID}`,
      {
        state: {
          ptid: template.TEMPLATEID,
          linkprojectname: "PREVIEW",
        },
      }
    );
  };

  //when user opens the popover
  const handleOpenChange = (newOpen) => {
    setCloneDialog(newOpen);
  };
  //when user closes the popover
  const hidePopover = () => {
    setDialog(true);
    setCloneDialog(false);
  };

  const mapBrandOpen = () => {
    setMapDialog(true);
    setCloneDialog(false);
  };

  //when user clicks delete template button
  // const handleDeleteDialog = () => {
  //   setCloneDialog(false);
  //   setDeleteDialog(true);
  // };

  // *********************************** GET API LINK CODE *****************************
  const [apiLinkGenerated, setApiLinkGenerated] = useState(null);
  const [projectDetailData, setProjectDetailData] = useState(null);
  //******************************* CREATE PROJECT STARTS ******************************************
  const { mutate: getApiLinkMutation } = useMutation(
    (getApiData) => getApiLink(getApiData),
    {
      onSuccess: (data, variables) => {
        // debugger
        if (data.STATUS === "1000") {
          const dataStatus = data.STATUS;
          const apiLink = JSON.parse(data.DATA)[0].api;
          // setApiDialog(true);
          if (variables.source === "A") {
            getApiLinkFunction(apiLink, dataStatus);
            setApiDialog(true);
          } else if (variables.source === "B") {
            setAPILink(apiLink);
            setPrevLinkModal(true);
          }
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const getApiFunction = () => {
    const getApiData = {
      templateid: template.TEMPLATEID,
      sessionuserid: sessionuserid,
      source: "A",
    };
    // console.log(getApiData, "P DATA");
    getApiLinkMutation(getApiData);
  };

  const getApiLinkFunction = (apiLink, dataStatus) => {
    // debugger
    if (dataStatus === "1000") {
      const apiLinkGenerated = apiLink;
      // console.log(apiLinkGenerated, "API LINK GENE")
      setApiLinkGenerated(apiLinkGenerated);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Turn off loading after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  const { mutate: getTemplateProjectMutation } = useMutation(
    (getPData) => getProjectDetails(getPData),
    {
      onSuccess: (data) => {
        const parsedData = JSON.parse(data.DATA);
        // debugger
        if (data.STATUS === "1000") {
          const projectData = parsedData;
          // console.log(projectData, "P DATA")
          const projectStatus = data.STATUS;
          // console.log(projectStatus, "P DATA STATUS")
          getProjectDetailFunction(projectData, projectStatus);
          setOpen(true);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const getTemplateProjectDetails = () => {
    const getPData = {
      templateid: template.TEMPLATEID,
      sessionuserid: sessionuserid,
    };
    // console.log(getPData, "P DATA");
    getTemplateProjectMutation(getPData);
  };

  const getProjectDetailFunction = (projectData, projectStatus) => {
    // debugger
    if (projectStatus === "1000") {
      const projectDetailData = projectData;
      setProjectDetailData(projectDetailData);
    }
  };

  const { mutate: getTotalGeneratedLinksMutate } = useMutation(
    (getTGLData) => totalGeneratedLinks(getTGLData),
    {
      onSuccess: (data) => {
        const parsedData = JSON.parse(data.DATA);
        // debugger
        if (data.STATUS === "1000") {
          // console.log(parsedData, "TGL DATA");
          // debugger
          const tgl = parseInt(parsedData[0].TOTALGENERATEDLINKS);
          // console.log(tgl);
          setTotalGL(tgl);
          setSpinIcon(false);
          toast.success(data.DESCRIPTION);
          // const projectData = parsedData;
          // // console.log(projectData, "P DATA")
          // const projectStatus = data.STATUS;
          // // console.log(projectStatus, "P DATA STATUS")
          // getProjectDetailFunction(projectData, projectStatus);
          // setOpen(true);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const getTotalGeneratedLinks = () => {
    setSpinIcon(true);
    const getTGLData = {
      templateid: template.TEMPLATEID,
      sessionuserid: sessionuserid,
    };
    // console.log(getTGLData, "P DATA");
    getTotalGeneratedLinksMutate(getTGLData);
  };

  // MUTATION FOR EDIT TEMPLATE
  const { mutate: editTemplateMutation } = useMutation(
    (editTemplateData) => editTemplateApi(editTemplateData),
    {
      onSuccess: (data) => {
        // debugger
        const parsedData = JSON.parse(data.DATA);
        if (data.STATUS === "1000") {
          // console.log(data)
          // Add SESSIONUSERID to each object in the DATA array
          const newDataArray = parsedData.map((item, index) => ({
            ...item,
            SESSIONUSERID: sessionuserid,
          }));

          /// Create a new object with the updated DATA property
          const updateTemplateData = {
            ...data,
            DATA: newDataArray,
          };
          // console.log(updateTemplateData, "UPDATE")
          // Store the updated data in localStorage
          localStorage.setItem(
            "updateTemplateData",
            JSON.stringify(updateTemplateData.DATA)
          );
          navigate(`${process.env.REACT_APP_BASE_PATH}/editor/updateTemplate`);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const editTemplateModalOpen = () => {
    setEditTempModal(true);
  };

  const prevLGModalFunction = () => {
    const getApiData = {
      templateid: template.TEMPLATEID,
      sessionuserid: sessionuserid,
      source: "B",
    };
    // console.log(getApiData, "P DATA");
    getApiLinkMutation(getApiData);
    // setPrevGLModal(true); // Old one Using project
    setPrevLinkModal(true); // New on which will generate Link using Variables
  };

  const editTemplate = () => {
    const editTemplateData = {
      templateid: template.TEMPLATEID,
      sessionuserid: sessionuserid,
    };
    editTemplateMutation(editTemplateData);
  };

  return (
    <>
      {isLoading ? (
        <div className="pt-3 md:w-[360px] w-[318px] h-[230px] shadow-card hover:shadow-card-hover rounded-5 transition delay-100 duration-300 ease-in-out">
          <div className="animate-pulse flex flex-col">
            <div className="flex gap-10 justify-between items-start px-3">
              <div className="rounded-[5px] bg-slate-200 h-[88px] w-[88px]"></div>
              <div>
                <>
                  <div className="h-2.5 w-[150px] bg-slate-200 rounded-5"></div>
                  <div className="h-2.5 w-[150px] bg-slate-200 rounded-5 mt-3"></div>
                  <div className="h-2.5 w-[150px] bg-slate-200 rounded-5 mt-3"></div>
                  <div className="h-2.5 w-[150px] bg-slate-200 rounded-5 mt-3"></div>
                </>
              </div>
              <div className="flex justify-between w-[14px] items-center">
                <div className="h-[3px] w-[3px] bg-slate-200 rounded-5"></div>
                <div className="h-[3px] w-[3px] bg-slate-200 rounded-5"></div>
                <div className="h-[3px] w-[3px] bg-slate-200 rounded-5"></div>
              </div>
            </div>
            <Divider className="!border-[#D9D9D9] border-[1px]" />
            <div className="my-2 flex justify-between px-3">
              <div className="flex items-center">
                <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                <div className="rounded-5 bg-slate-200 h-2.5 w-[120px] ml-2"></div>
              </div>
              <div className="flex items-center">
                <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                <div className="rounded-5 bg-slate-200 h-2.5 w-[120px] ml-2"></div>
              </div>
            </div>
            <Divider className="!border-[#D9D9D9] border-[1px]" />
            <div className="my-2 flex justify-between items-center px-3">
              <div className="flex items-center">
                <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                <div className="rounded-5 bg-slate-200 h-2.5 w-[120px] ml-2"></div>
              </div>
              <div className="rounded-5 bg-slate-200 h-4 w-1"></div>
            </div>
            <div className="mt-1 px-1 pb-3 flex justify-between items-center">
              <div className="rounded-5 bg-slate-200 h-2.5 w-[132px] ml-2"></div>
              {customerOwner === "customeradmin@vspagy.com" ? (
                <>
                  <div className="flex w-28 justify-between items-center">
                    <div className="rounded-[5px] bg-slate-200 h-8 w-8"></div>
                    <div className="rounded-[5px] bg-slate-200 h-8 w-8"></div>
                    <div className="rounded-[5px] bg-slate-200 h-8 w-8"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex w-[5.2rem] justify-between items-center">
                    <div className="rounded-[5px] bg-slate-200 h-8 w-8"></div>
                    <div className="rounded-[5px] bg-slate-200 h-8 w-8"></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        // <Badge.Ribbon text="Global">
        <div className="relative pt-3 md:w-[360px] w-[318px] h-fit shadow-card cardtempCss bg-white rounded-5 transition delay-100 duration-300 ease-in-out">
          {/* hover:drop-shadow-cardtemp-hover  {due to this css popover hide} */}
          {customerOwner === "customeradmin@vspagy.com" ? (
            <>
              {template.ISGLOBAL === "true" ? (
                <Badge.Ribbon
                  className="text-[11px] font-semibold"
                  text="Global"
                  color="purple"
                  placement="start"
                ></Badge.Ribbon>
              ) : (
                <></>
              )}
              {template.TEMPLATEPREVIEWLINK !== null ? (
                <Badge.Ribbon
                  className="text-[11px] font-semibold mt-8"
                  text="Link"
                  color="magenta"
                  placement="start"
                ></Badge.Ribbon>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {/* {template.TEMPLATEPREVIEWLINK} */}
          <div className="">
            <div className="flex justify-between px-3">
              <div className="flex gap-5 cursor-pointer items-center">
                <img
                  className="w-[88px] h-[88px] rounded"
                  src={
                    template.TEMPLATETHUMBNAIL
                      ? `${
                          process.env.REACT_APP_SOURCE_TEMPLATE_BUCKET_BASE_URL
                        }TEMPLATE/${template.TEMPLATEID}/SOURCE/${
                          template.TEMPLATETHUMBNAIL
                        }?${Date.now()}`
                      : `https://placehold.co/230x230`
                  }
                  alt="template"
                  loading="lazy"
                />
                <div onClick={getTemplateProjectDetails}>
                  <>
                    <div className="text-[#6d6d6d] text-xs font-normal">
                      TID: <span>{template.TEMPLATEID}</span>
                    </div>
                    <div className="tetxt-sm font-semibold text-[15px] mt-[2px]">
                      {template.TEMPLATENAME}
                    </div>
                    <div className="text-[#6d6d6d] text-xs font-normal mt-[6px]">
                      Last modified {template.TEMPLATEUPDATEDON}
                    </div>
                    <div className="text-[#6d6d6d] text-xs font-normal mt-[2px]">
                      By {template.TEMPLATEOWNER}
                    </div>
                  </>
                </div>
              </div>
              <div className="template-popover-user-card">
                <Popover
                  open={cloneDialog}
                  onOpenChange={handleOpenChange}
                  placement="right"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  content={
                    <div className="flex flex-col justify-start w-[100px] rounded-5 px-[2px] py-[2px]">
                      <span
                        onClick={hidePopover}
                        className="clone text-[13px] pl-[5px] py-[3px] cursor-pointer  text-left"
                      >
                        Clone
                      </span>
                      {/* {customerOwner === "customeradmin@vspagy.com" ? ( */}
                        {/* <></> */}
                      {/* ) : ( */}
                        <>
                          <span
                            onClick={mapBrandOpen}
                            className="clone text-[13px] pl-[5px] py-[3px] cursor-pointer  text-left"
                          >
                            Map Brand
                          </span>
                        </>
                      {/* )} */}
                    </div>
                  }
                  trigger="click"
                  showArrow={hidePopover}
                >
                  <img
                    className="cursor-pointer pt-1"
                    src={Threedots}
                    alt="Threedots"
                  />
                </Popover>
              </div>
            </div>
            <div className="">
              <Divider className="!border-[#D9D9D9] border-[1px]" />
              <div
                className=" px-3 cursor-pointer py-1"
                onClick={getTemplateProjectDetails}
              >
                <div className="grid grid-cols-2 items-center">
                  <div className="flex gap-2 ">
                    <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                    <div className="flex gap-2 text-xs">
                      <div className="text-[#454545] font-medium ">
                        Total Projects:
                      </div>
                      <div className="text-[#1F1F1F] font-medium">
                        {template.TEMPLATETOTALPROJECTS}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 ">
                    <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                    <div className="flex gap-2 text-xs">
                      <div className="text-[#454545] font-medium ">
                        Active Projects:
                      </div>
                      <div className="text-[#1F1F1F] font-medium">
                        {template.TEMPLATEACTIVEPROJECTS}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="!border-[#D9D9D9] border-[1px]" />
              <div className=" px-3">
                <div>
                  <div className="flex gap-2  py-1">
                    <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#03E85F]"></div>
                    <div className="flex justify-between items-center w-full pr-2">
                      <div className="flex items-center gap-2 text-xs ">
                        <div className="text-[#454545] font-medium ">
                          Total Generated Links:
                        </div>
                        <div className="text-[#1F1F1F] font-medium">
                          {totalGL === null
                            ? template.TOTALGENERATEDLINKS
                            : totalGL}
                        </div>
                        <Tooltip
                          title={
                            isMobile ? "" : "Refresh Total Generated Links"
                          }
                          trigger="hover"
                        >
                          <span
                            className="flex items-center cursor-pointer"
                            onClick={getTotalGeneratedLinks}
                          >
                            <SyncOutlined spin={spinIcon} />
                          </span>
                        </Tooltip>
                      </div>
                      <div
                        className="flex cursor-pointer"
                        onClick={getTemplateProjectDetails}
                      >
                        <img src={rightArrowIcon} alt="next" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#E7EFFF] mt-2 flex justify-between items-center  p-2 py-1">
            {template.TOTALGENERATEDLINKS === 0 ? (
              <div
                className="text-[#0008CF] text-xs font-semibold cursor-pointer hover:scale-105 transition ease-in-out delay-105 duration-300"
                onClick={
                  isMobile ? () => setMobileResModal(true) : editTemplate
                }
              >
                EDIT TEMPLATE
              </div>
            ) : (
              <div
                className="text-[#0008CF] text-xs font-semibold cursor-pointer hover:scale-105 transition ease-in-out delay-105 duration-300"
                onClick={
                  isMobile
                    ? () => setMobileResModal(true)
                    : editTemplateModalOpen
                }
              >
                EDIT TEMPLATE
              </div>
            )}

            <div className="flex gap-2 items-center">
              {customerOwner === "customeradmin@vspagy.com" ? (
                <>
                  <Tooltip
                    placement="bottom"
                    title={isMobile ? "" : "Generate Preview"}
                  >
                    <img
                      onClick={() => {
                        prevLGModalFunction();
                      }}
                      className="h-8 w-8 p-1 cursor-pointer hover:scale-105 transition ease-in-out delay-150 duration-300"
                      src={previewIcon}
                      alt="generate preview"
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <></>
                </>
              )}
              <Tooltip placement="bottom" title={isMobile ? "" : "Get API"}>
                <img
                  onClick={() => {
                    getApiFunction();
                  }}
                  className="h-8 w-8 p-1 cursor-pointer hover:scale-105 transition ease-in-out delay-150 duration-300"
                  src={newAPIIcon}
                  alt="api"
                />
              </Tooltip>
              <Tooltip placement="bottom" title={isMobile ? "" : "New Project"}>
                <img
                  className="h-[26px] w-[26px] p-1 cursor-pointer hover:scale-105 transition ease-in-out delay-150 duration-300"
                  src={newStartProjectIcon}
                  alt="rocket"
                  onClick={
                    isMobile
                      ? () => setMobileResModal(true)
                      : navigateToAnotherRoute
                  }
                />
              </Tooltip>
            </div>
          </div>
        </div>
        // </Badge.Ribbon>
      )}

      {/* <CloneDialog
        dialog={dialog}
        setDialog={setDialog}
        templateid={template.TEMPLATEID}
        templatename={template.TEMPLATENAME}
        onRefreshSuccess={onRefreshSuccess}
      /> */}
      {mapDialog && (
        <MapBrandPageModal
          mapdialog={mapDialog}
          setMapDialog={setMapDialog}
          templateid={template.TEMPLATEID}
          templatename={template.TEMPLATENAME}
          templateowner={template.TEMPLATEOWNER}
          brandpageid={template.BRANDPAGEID}
          onRefreshSuccess={onRefreshSuccess}
        />
      )}
      {dialog && (
        <CloneDialog
          dialog={dialog}
          setDialog={setDialog}
          templateid={template.TEMPLATEID}
          templatename={template.TEMPLATENAME}
          onRefreshSuccess={onRefreshSuccess}
        />
      )}
      {deleteDialog && (
        <CloseDialog
          dialog={deleteDialog}
          description="Deleting this template will have an effect on your API links or projects. Do you still want to delete?"
          title="Are you sure you want to delete this?"
          setDialog={setDeleteDialog}
        />
      )}
      {editTempModal && (
        <EditTemplateModal
          dialog={editTempModal}
          description="Modified template will be reflected in the previously generated links."
          title="This template is linked to a project"
          setDialog={setEditTempModal}
          editTemplate={editTemplate}
        />
      )}
      {/* BELOW MODAL IS OLD ONE WHICH USES PROJECT TO CREATE PREVIEW LINK */}
      {prevLGModal && (
        <PreviewLinkGenerateModal
          dialog={prevLGModal}
          setDialog={setPrevGLModal}
          templatename={template.TEMPLATENAME}
          templateprevlink={template.TEMPLATEPREVIEWLINK}
          navigateToProjectModule={navigateToProjectModule}
          // editTemplate={editTemplate}
        />
      )}
      {/* BELOW MODAL IS NEW ONE FROM WHICH PREVIEW LINK CAN BE GENERATED ONLY USING VARIABLES OF THAT TEMPLATE */}
      {prevLinkModal && (
        <GeneratePreviewLinkModal
          dialog={prevLinkModal}
          setDialog={setPrevLinkModal}
          templateid={template.TEMPLATEID}
          templatename={template.TEMPLATENAME}
          templateprevlink={template.TEMPLATEPREVIEWLINK}
          variable={template.TEMPLATEVARIABLES}
          apiLink={apiLink}
        />
      )}
      {apiDialog && (
        <ApiDialog
          templatename={template.TEMPLATENAME}
          dialog={apiDialog}
          setDialog={setApiDialog}
          apiLinkGenerated={apiLinkGenerated}
        />
      )}
      {open && (
        <MyTemplateDrawer
          templateid={template.TEMPLATEID}
          templatename={template.TEMPLATENAME}
          templatetotalgeneratedlink={template.TOTALGENERATEDLINKS}
          templatetotalprojects={template.TEMPLATETOTALPROJECTS}
          templateactiveprojects={template.TEMPLATEACTIVEPROJECTS}
          projectTotalOpen={template.TOTALOPEN}
          projectTotalPlayed={template.TOTALPLAYED}
          projectUniqueOpen={template.UNIQUEOPEN}
          projectUniquePlayed={template.UNIQUEPLAYED}
          setOpen={setOpen}
          open={open}
          projectDetailData={projectDetailData}
        />
      )}
      {isMobile && (
        <MobileResModal
          mobileResModal={mobileResModal}
          setMobileResModal={setMobileResModal}
        />
      )}
    </>
  );
};

export default UserTemplateCard;
