import { Modal } from "antd";
import React from "react";
import SimpleButton from "../../../common/components/SimpleButton";
import StartingModalImg from "../../../assests/commonImages/userOnboarding/startingModalImg.jpg";
import "../../../common/styles/commonStyles.css";
import useWindowWidth from "../../../common/hooks/useWindowWidth";

const StartingModal = (props) => {
  const showModal = () => {
    props.setShowStartingModal(false);
    localStorage.removeItem("startingModalShow");
  };

  const customername = localStorage.getItem("customerName");
  const { isMobile, isDesktop } = useWindowWidth();

  return (
    <>
      <Modal
        width={isDesktop ? 404 : 307}
        open={props.openModal}
        closeIcon={null}
        className="StartingModal"
      >
        <div>
          <div className="relative">
            <img
              width={isDesktop ? 404 : 307}
              height={isDesktop ? 404 : 370}
              src={StartingModalImg}
              alt="congrats gif"
            />
          </div>
          <div
            className={` ${
              isDesktop ? "w-[404px] h-[340px]" : "w-[307px] h-[320px]"
            }  absolute top-10 flex flex-col justify-between`}
          >
            <div
              className={` ${
                isDesktop ? "text-[20px]" : "text-[18px]"
              } text-[#FFF9F9] font-bold flex justify-center`}
            >
              Your Free Trial Starts Today!
            </div>
            <div className="flex flex-col">
              <div className="text-[16px] text-[#262525] font-semibold flex justify-center">
                Welcome to VSPAGY, {customername}!
              </div>
              <div className={`${isDesktop ? "" : "text-center"} flex justify-center `}>
                <span className="text-[12px] text-[#262525] font-semibold">
                  Enjoy our{" "}
                  <span className="text-[#2884C6] font-bold">
                    Free Starter Plan{" "}
                  </span>
                  with{" "}
                  <span className="text-[#2884C6] font-bold">
                    2000 credits{" "}
                  </span>
                  at no cost.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-1">
          <span className="text-[#424141] text-[12px] font-semibold flex justify-center">
            Explore for Free during BETA
          </span>
          <span className="text-[#363434] text-[11px] font-normal text-center pt-1 px-8">
            {/* A deduction of <span className="font-bold">10 credits</span> has
            been made for prebuilt templates, and
            <span className="font-bold"> 1 credit </span> per link for
            personalized videos. */}
            Premium template use leads to credit deductions at defined rates,
            with <span className="font-bold"> 1 credit </span> per personalized
            link generation.
          </span>
        </div>
        <div className="flex justify-center mt-4">
          <SimpleButton
            className="mb-4"
            label="GOT IT!"
            type="primary"
            onClick={showModal}
            // onClick={() => {
            //   props.setShowStartingModal(false);
            //   localStorage.setItem("startingModalShow", "no");
            // }}
          />
        </div>
      </Modal>
    </>
  );
};

export default StartingModal;
