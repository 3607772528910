import React, { useEffect } from "react";
import "./ComponentDetail.css";
import { Button, Divider, Input, Modal, Popover, Switch, Tooltip } from "antd";
import { useState } from "react";
import DetailMenu from "./DetailMenu";
import TextArea from "antd/es/input/TextArea";
import alertcircle from "../../assets/alert-circle.svg";
import MultiInput from "../commonComponents/MultiInput";
import { getByTitle } from "@testing-library/dom";
const ComponentDetail = (props) => {
  const [open,setOpen] = useState(false);
  const error = (tit,msg) => {
    const modal =  Modal.error({
      title: tit,
      content: msg,
      onOk: () =>{
        document.getElementById("LNUQ").focus();
        props.setIsModalOpen(false);
        setOpen(false);
        Modal.destroyAll();
      }
    });
    return modal;
  };
  return (
    <div
      className="overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
    scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] h-[84vh] w-full  font-sans "
    >
      {props.displayTextConfig ? (
        <>
          <div>
            <div className="p-4 pb-0 mt-1">
              <div className="font-medium text-left text-sm text-white">
                Text Configuration
              </div>
              <div className="flex flex-col mt-4">
                <div className="flex">
                  <label className="text-left font-normal text-xs items-start mb-2">
                    Layer Name
                  </label>
                </div>

                <Input
                  id="LNUQ"
                  placeholder="Enter Layer Name"
                  value={props.layerName}
                  onClick = {()=>{
                    // props.fieldEnabled.current = true;
                    props.fieldEnabled.current = true;
                  }}
                  onBlur={() => {
                    for(let i=0; i<props.canvaContent.length;i++){
                      if(props.canvaContent[i].id !== props.itemSelected && !props.isRight){
                        if(props.canvaContent[i].layername === props.layerName){
                          console.log("Alert",props.canvaContent[i].id,props.itemSelected);
                          if(!open){
                           setOpen(true);
                           error("The Layer Name should be unique","Please change the layer name and ensure that it is unique.");
                          }
                          props.setIsModalOpen(true);
                          break;
                        }
                      }
                    }
                    if(props.layerName.trim()===""){
                      if(!open){
                        setOpen(true);
                        error("Provide the Layer Name","Layer Name cannot be blank.");
                       }
                       props.setIsModalOpen(true);
                    }
                    // props.fieldEnabled.current = false;
                    props.fieldEnabled.current = false;
                  }}
                  onChange={(e) => {
                    let val = e.target.value;
                    let flag = false;
                    for(let i=0; i<props.canvaContent.length;i++){
                      if(props.canvaContent[i].id !== props.itemSelected){
                        if(props.canvaContent[i].layername === val){
                          props.setIsRight(false);
                          flag = true;
                          break;
                        }
                      }
                    }
                    if (val === "") {
                      props.setIsRight(false);
                      flag =  true;
                    }
                    if(!flag){
                      props.setIsRight(true);
                    }
                    if (props.type == "vimage") {
                      val = val.startsWith("vimg_") ? val : "vimg_";
                      props.setPayload(`{${val}}`);
                    } else if (props.type == "vVideo") {
                      val = val.startsWith("vvid_") ? val : "vvid_";
                      props.setPayload(`{${val}}`);
                    }
                    props.setLayername(val);
                  }}
                  className="h-9 w-full  editor-input"
                />
              </div>
              {(props.type === "text" || props.type === "textarea") && (
                <div className="flex flex-col mt-4">
                  <label className="text-left font-normal text-xs items-start mb-2">
                    Placeholder Text
                  </label>
                  <Input
                    value={props.placeHolder}
                    disabled = {props.isModalOpen}
                    onClick = {()=>{
                      // props.fieldEnabled.current = true;
                      props.fieldEnabled.current = true;
                    }}
                    onChange={(e) => {
                      props.setPlaceholder(e.target.value);
                    }}
                    onBlur = {
                      ()=>{
                        // props.fieldEnabled.current = false;
                        props.fieldEnabled.current = false;
                      }
                    }
                    placeholder="Enter Placeholder"
                    className="h-9 w-full  editor-input "
                  />
                </div>
              )}
              {(props.type === "label" ||
                props.type === "button" ||
                props.type === "radio" ||
                props.type === "checkbox" ||
                props.type === "dropdown") && (
                <div className="flex flex-col mt-4">
                  <div className="flex">
                    <label className="text-left font-normal text-xs items-start mb-2">
                      {props.type === "label"
                        ? "Label Name"
                        : props.type === "radio"
                        ? " Radio Name"
                        : props.type === "checkbox"
                        ? "CheckBox Name"
                        : props.type === "dropdown"
                        ? "Display Label Name"
                        : "Button Name"}
                    </label>
                    {(props.type === "label" || props.type === "radio") && <Tooltip
                      className="ml-2 "
                      title={props.type==="label"?"To add personalized variables enter values in curly braces. For eg: {name}, {age}.":"Ensure that when creating a group of options, the Radio Name remains same."}
                      color="#2B2B2C"
                      trigger="click"
                      placement="leftBottom"
                      overlayStyle={{
                        boxShadow: "2px",
                        fontSize: "14px",
                      }}
                    >
                      <img
                        className="h-[18px] w-[18px] cursor-pointer"
                        src={alertcircle}
                        alt="info-icon"
                      />
                    </Tooltip>}
                  </div>
                  {props?.subtype !== "multiline" && (
                    <Input
                      value={props.name}
                      disabled = {props.isModalOpen}
                      onClick = {()=>{
                        // props.fieldEnabled.current = true;
                        props.fieldEnabled.current = true;
                      }}
                      onChange={(e) => {
                        props.setName(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (props.name === "") {
                          if (props.type === "dropdown") {
                            props.setName("--Please Select--");
                          } else if (props.type === "radio") {
                            props.setName("Radio");
                          } else if (props.type === "checkbox") {
                            props.setName("Checkbox");
                          }
                        }
                        // props.fieldEnabled.current = false;
                        props.fieldEnabled.current = false;
                      }}
                      placeholder={
                        props.type === "label"
                          ? "Enter Label Value"
                          : props.type === "radio"
                          ? "Enter Radio Name"
                          : props.type === "checkbox"
                          ? "Enter Checkbox Name"
                          : props.type === "dropdown"
                          ? "Default Text"
                          : "Enter Button Name"
                      }
                      className="h-9 w-full  editor-input "
                    />
                  )}
                  {props?.subtype === "multiline" && (
                    <TextArea
                      value={props.name}
                      disabled = {props.isModalOpen}
                      onClick = {()=>{
                        // props.fieldEnabled.current = true;
                        props.fieldEnabled.current = true;
                      }}
                      onChange={(e) => {
                        props.setName(e.target.value);
                      }}
                      onBlur={()=>{
                        // props.fieldEnabled.current = false;
                        props.fieldEnabled.current = false;
                      }}
                      placeholder="Enter Multiline Text"
                      className="overflow-y-auto h-9 w-full bg-[#2D4966] editor-input"
                    />
                  )}
                </div>
              )}
              {(props.type === "checkbox" || props.type === "radio"|| props.type === "video" || props.type==="vVideo") && (
                <>
                  {props.type!=="video" || props.type!=="vVideo" && <div className="flex flex-col mt-4">
                    <label className="text-left font-normal text-xs items-start mb-2">
                      {props.type === "radio"
                        ? "Radio Value"
                        : "CheckBox Value"}
                    </label>
                    <Input
                      value={props.eleValue}
                      disabled = {props.isModalOpen}
                      onClick = {()=>{
                        // props.fieldEnabled.current = true;
                        props.fieldEnabled.current = true;
                      }}
                      onChange={(e) => {
                        props.setEleValue(e.target.value);
                      }}
                      onBlur = {
                        ()=>{
                          // props.fieldEnabled.current = false;
                          props.fieldEnabled.current = false;
                        }
                      }
                      placeholder={
                        props.type === "radio"
                          ? "Enter Radio Value"
                          : "Enter CheckBox Value"
                      }
                      className="h-9 w-full  editor-input"
                    />
                  </div>}
                  <div className={`flex ${props.type==="video"||props.type==="vVideo"?"flex-row-reverse justify-end":""} mt-4 editor-switch`}>
                    <div className={`text-left font-normal text-xs items-start ${props.type==="video"||props.type==="vVideo"}? ml-6 :mr-6`}>
                    {props.type==="video"||props.type==="vVideo"?"Play video on loop":"Default Checked"}
                    </div>
                    <Switch
                      size="small"
                      disabled = {!props.isRight}
                      checked={props.defaultChecked===undefined||props.defaultChecked===true?true:false}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(checked) => {
                        props.setDefaultChecked(checked);
                        if(props.type === "radio"){
                         let arr = props.canvaContent.map((item, index) => {
                          if (item.type === "radio") {
                            if (
                              item.id !== props.itemSelected &&
                              item.name === props.name
                            ) {
                              item.defaultchecked = false;
                            }
                          }
                          return item;
                         });
                         props.handleContent([...arr]);
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {(props.type === "textarea" ||
              props.subtype === "text" ||
              props.subtype === "number" ||
              props.subtype === "tel") && (
              <div className="items-start  relative px-4 pt-4">
                <div className="items-center    rounded-sm   gap-2 flex justify-start  mb-2 editor-switch">
                  <Switch
                    size="small"
                    disabled = {!props.isRight}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={props.cltoggle}
                    onChange={(checked) => props.setCltoggle(checked)}
                  />
                  <div className="text-sm">Character Limit</div>
                </div>

                <Input
                  type="number"
                  controls={false}
                  disabled={!props.cltoggle && props.isModalOpen}
                  value={props.characterLimit}
                  onClick = {()=>{
                    // props.fieldEnabled.current = true;
                    props.fieldEnabled.current = true;
                  }}
                  onChange={(e) => {
                    props.setCharacterLimit(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (props.characterLimit === "") {
                      props.setCharacterLimit(10000);
                    }
                    // props.fieldEnabled.current = false;
                    props.fieldEnabled.current = false;
                  }}
                  className={`h-9 ${
                    !props.characterLimit ? "border-none !bg-gray-500" : ""
                  }  mt-1 w-full  editor-input`}
                />
                <span className="absolute text-[13PX] font-normal text-[#EEEEEE] top-14 right-6">
                  characters
                </span>
              </div>
            )}

            {(props.type === "dropdown" ||
              props.type === "radio" ||
              props.type === "checkbox" ||
              props.type === "text" ||
              props.type === "textarea") && (
              <div className="items-start px-4 mb-3 mt-4">
                <div className="items-center rounded-sm gap-2 flex justify-start mb-2 editor-switch">
                  <Switch
                    size="small"
                    disabled = {!props.isRight}
                    checked={props.emtoggle}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      if (!checked) {
                        props.setErrorMessage("");
                      }
                      props.setEmtoggle(checked);
                    }}
                  />
                  <div className="text-sm">Input mandatory</div>
                </div>
                <Input
                  placeholder="Set error message "
                  disabled={!props.emtoggle && props.isModalOpen}
                  value={props.errorMessage}
                  onClick = {()=>{
                    props.fieldEnabled.current = true;
                  }}
                  onChange={(e) => {
                    props.setErrorMessage(e.target.value);
                  }}
                  onBlur={()=>{
                    props.fieldEnabled.current = false;
                  }}
                  className="h-10  mt-1 w-full  editor-input "
                />
              </div>
            )}
          </div>
          {props.type === "dropdown" && (
            <div className="text-center">
              <MultiInput
                optionsArray={props.optionsArray}
                setOptionsArray={props.setOptionsArray}
                setShowDialog = {props.setShowDialog}
                PopButton={"Add Options"}
                setButton={"Set Options"}
                header={"Add Options"}
                desc={
                  "Tell us your list of options and its value. For eg: Name = India, Value = Asia"
                }
                col1={"Name"}
                col2={"Value"}
                fieldEnabled = {props.fieldEnabled}
              />
            </div>
          )}
          <Divider className="!m-0 !mt-4" />
        </>
      ) : null}
      <DetailMenu
        itemSelected={props.itemSelected}
        canvaContent={props.canvaContent}
        handleContent={props.handleContent}
        elementProperties={props.elementProperties}
        idx={props.idx}
        setIdx={props.setIdx}
        bgColor={props.bgColor}
        setbgColor={props.setbgColor}
        textColor={props.textColor}
        setTextColor={props.setTextColor}
        borderColor={props.borderColor}
        setBorderColor={props.setBorderColor}
        xcord={props.xcord}
        setXcord={props.setXcord}
        ycord={props.ycord}
        setYcord={props.setYcord}
        height={props.height}
        setHeight={props.setHeight}
        width={props.width}
        setWidth={props.setWidth}
        rotate={props.rotate}
        setRotate={props.setRotate}
        flip={props.flip}
        setFlip={props.setFlip}
        drawAreaWidth={props.drawAreaWidth}
        drawAreaHeight={props.drawAreaHeight}
        border={props.border}
        setBorder={props.setBorder}
        borderRadius={props.borderRadius}
        setBorderRadius={props.setBorderRadius}
        tlborderRadius={props.tlborderRadius}
        setTLBorderRadius={props.setTLBorderRadius}
        trborderRadius={props.trborderRadius}
        setTRBorderRadius={props.setTRBorderRadius}
        blborderRadius={props.blborderRadius}
        setBLBorderRadius={props.setBLBorderRadius}
        brborderRadius={props.brborderRadius}
        setBRBorderRadius={props.setBRBorderRadius}
        resizeRatio={props.resizeRatio}
        setResizeRatio={props.setResizeRatio}
        link={props.link}
        setLink={props.setLink}
        type={props.type}
        fieldEnabled={props.fieldEnabled}
        // setFieldEnabled={props.setFieldEnabled}
        isModalOpen = {props.isModalOpen}
        setIsModalOpen = {props.setIsModalOpen}
        isRight = {props.isRight}
      />
    </div>
  );
};

export default ComponentDetail;
