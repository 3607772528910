import { toast } from "react-toastify";

// THIS FILE CONTAINS API CALLING
// - updateMobile, - updateCustomerIndustry, - updateCustomerCompanyRole, - loginCustomer, - forgetPassword
// - validateEmailOtp, - createCustomer, - changePassword, - setNewPassword, - validateForgotPwOtp, - updateCustomer
// - getApiLink, - logoutUserApi, - copyTemplate,

const API_BASE_URL = window.baseurl;

// ***************** CENTRALIZED API CALLING ONLY FOR UPDATING DATA ON SERVER *************************
const updateApiData = async (endpoint, data) => {
  if (!navigator.onLine) {
    toast.error("No internet connection. Please check your network.");
    return;
  }
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    // const response = await fetch(`${API_BASE_URL}${endpoint}`, requestOptions);
    const currentTime = new Date().getTime();
    const response = await fetch(
      `${API_BASE_URL}${endpoint}?t=${currentTime}`,
      requestOptions
    );
    const responseData = await response.json();
    if (response.ok) {
      // console.log(responseData, "R DARA")
      return responseData;
    } else {
      throw new Error(responseData.DESCRIPTION);
    }
  } catch (error) {
    // console.error(error);
    throw toast.error("Something went wrong!! Please try later.");
  }
};

// *******************************************************************************************************************
// ***************************************** ONBOARDING APIS STARTS **************************************************
// *******************************************************************************************************************

// BELOW FUNCTION IS CALLED FROM PersonOnboarding.js WHICH IS UPDATING CUSTOMER MOBILE ON SERVER
export const updateMobile = async (custMobileData) => {
  const endpoint = "/customer";
  const data = {
    action: "UPDATEMOBILE",
    custregid: custMobileData.custregid,
    mobile: custMobileData.mobile,
  };
  return updateApiData(endpoint, data);
};

// BELOW FUNCTION IS CALLED FROM IndustryOnboarding.js WHICH IS UPDATING CUSTOMER IDUSTRY ON SERVER
export const updateCustomerIndustry = async (custIndustryData) => {
  const endpoint = "/customer";
  const data = {
    action: "UPDATEINDUSTRY",
    custregid: custIndustryData.custregid,
    industry: custIndustryData.industry,
  };
  return updateApiData(endpoint, data);
};

// BELOW FUNCTION IS CALLED FROM OrganisationOnboarding.js WHICH IS UPDATING CUSTOMER ROLE ON SERVER
export const updateCustomerCompanyRole = async (custCompanyData) => {
  const endpoint = "/customer";
  const data = {
    action: "UPDATECOMPANYANDROLE",
    custregid: custCompanyData.custregid,
    company: custCompanyData.orgname,
    custprofile: custCompanyData.role,
  };
  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// ***************************************** ONBOARDING APIS ENDS **************************************************
// *******************************************************************************************************************

// *******************************************************************************************************************
// ***************************************** USER APIS STARTS **************************************************
// *******************************************************************************************************************
// LOGIN, SIGNUP, LOGOUT, FORGOT

// Register.js
export const createCustomer = async (custData) => {
  const endpoint = "/customer";
  const data = {
    action: "CREATECUSTOMER",
    custregid: custData.custregid,
    custname: custData.custname,
    password: custData.password,
    ownerregid: custData.ownerregid,
    regvia: custData.regvia,
    utm: custData.utm,
  };
  return updateApiData(endpoint, data);
};

// Login.js & Logout.js
export const loginCustomer = async (custDataLogin) => {
  // console.log("INSIDE CUSTLOGIN")
  const endpoint = "/customer";
  const data = {
    action: "CUSTOMERLOGIN",
    custregid: custDataLogin.custregid,
    password: custDataLogin.password,
  };
  return updateApiData(endpoint, data);
};

// ForgotPassword.js & ForgotPwOtpVerify.js
export const forgetPassword = async (custData) => {
  const endpoint = "/customer";
  const data = {
    action: "FORGETPASSWORD",
    custregid: custData.custregid,
  };
  return updateApiData(endpoint, data);
};

// ForgotPwOtpVerify.js
export const validateForgotPwOtp = async (emailVerifyData) => {
  const endpoint = "/customer";
  const data = {
    action: "VALIDATEEMAILOTP",
    custregid: emailVerifyData.custregid,
    emailotp: emailVerifyData.emailotp,
  };
  return updateApiData(endpoint, data);
};

// ChangePassword.js
export const changePassword = async (passwordData) => {
  const endpoint = "/customer";
  const data = {
    action: "CHANGEPASSWORD",
    custregid: passwordData.custregid,
    oldpassword: passwordData.oldpassword,
    newpassword: passwordData.newpassword,
    updatedby: passwordData.updatedby,
  };
  return updateApiData(endpoint, data);
};

// ResetPassowrd.js
export const setNewPassword = async (passwordData) => {
  const endpoint = "/customer";
  const data = {
    action: "SETPASSWORD",
    custregid: passwordData.custregid,
    password: passwordData.password,
    sessionuserid: passwordData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// Logout.js
export const logoutUserApi = async (logoutData) => {
  const endpoint = "/logout";
  const data = {
    sessionuserid: logoutData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// ******************************************** USER APIS ENDS *******************************************************
// *******************************************************************************************************************

// *******************************************************************************************************************
// *************************************** USER PROFILE APIS STARTS **************************************************
// *******************************************************************************************************************

// EmailOtpVerify.js
export const validateEmailOtp = async (emailVerifyData) => {
  const endpoint = "/customer";
  const data = {
    action: "VALIDATEEMAILOTPTEMP",
    custregid: emailVerifyData.custregid,
    emailotp: emailVerifyData.emailotp,
  };
  return updateApiData(endpoint, data);
};

// Profile.js || OtpModalEmail.js
export const verifyComEmail = async (emailData) => {
  const endpoint = "/customer";
  const data = {
    action: "VERIFYCOMEMAIL",
    custregid: emailData.custregid,
    email: emailData.email,
  };
  return updateApiData(endpoint, data);
};

// OtpModalEmail.js
export const validateComEmailOtp = async (emailVerifyData) => {
  const endpoint = "/customer";
  const data = {
    action: "VALIDATECOMEMAILOTP",
    custregid: emailVerifyData.custregid,
    email: emailVerifyData.email,
    emailotp: emailVerifyData.emailotp,
  };
  return updateApiData(endpoint, data);
};

// Profile.js
export const updateCustomer = async (profileDataUpdate) => {
  const endpoint = "/customer";
  const data = {
    action: "UPDATECUSTOMER",
    custregid: profileDataUpdate.custregid,
    custname: profileDataUpdate.custname,
    mobile: profileDataUpdate.mobile,
    email: profileDataUpdate.email,
    industry: profileDataUpdate.industry,
    custprofile: profileDataUpdate.role,
    company: profileDataUpdate.organization,
    custaddress: profileDataUpdate.custaddress,
    custtaxno: profileDataUpdate.custtaxno,
    custgstin: profileDataUpdate.custgstin,
    countryid: profileDataUpdate.country,
    currencyid: profileDataUpdate.currencyid,
    state: profileDataUpdate.state,
    city: profileDataUpdate.city,
    zipcode: profileDataUpdate.zipcode,
    updatedby: profileDataUpdate.updatedby,
  };
  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// *************************************** USER PROFILE APIS ENDS ****************************************************
// *******************************************************************************************************************

// *******************************************************************************************************************
// *************************************** USER TEMPLATE APIS STARTS *************************************************
// *******************************************************************************************************************

// UserTemplateCard.js (This API is used to get the API link for the template)
export const getApiLink = async (getApiData) => {
  const endpoint = "/project";
  const data = {
    action: "GETAPI",
    templateid: getApiData.templateid,
    sessionuserid: getApiData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// ProjectModal.js || CloneDialog.js
export const copyTemplate = async (cloneTemplateData) => {
  const endpoint = "/template";
  const data = {
    action: "CREATETEMPLATECLONE",
    templateid: cloneTemplateData.templateid,
    sessionuserid: cloneTemplateData.sessionuserid,
    templatename: cloneTemplateData.templatename,
    templatedesc: cloneTemplateData.templatedesc,
  };
  return updateApiData(endpoint, data);
};

// ProjectModal.js || CloneDialog.js
export const createTemplateTemp = async (tempData) => {
  const endpoint = "/template";
  const data = {
    action: "CREATETEMPLATETEMP",
    custregid: tempData.custregid,
    templatename: tempData.templatename,
    templatedesc: tempData.templatedesc,
  };
  return updateApiData(endpoint, data);
};

// ProjectModal.js || UserTemplateCard.js (Use Edit the Template which will re-route to Editor to Edit the User/Global Template)
export const editTemplateApi = async (editTemplateData) => {
  const endpoint = "/template";
  const data = {
    action: "GETTEMPLATEDETAIL",
    templateid: editTemplateData.templateid,
    sessionuserid: editTemplateData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// UserTemplateCard.js (Used to get the Total Generated Links for the Particual Template)
export const totalGeneratedLinks = async (getTGLData) => {
  const endpoint = "/template";
  const data = {
    action: "GETTOTALGENERATEDLINKS",
    sessionuserid: getTGLData.sessionuserid,
    templateid: getTGLData.templateid,
  };
  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// *************************************** USER TEMPLATE APIS ENDS ***************************************************
// *******************************************************************************************************************

// *******************************************************************************************************************
// ******************************************** BRAND APIS STARTS ****************************************************
// *******************************************************************************************************************

// CreateBrandPageModal.js
export const createBrandPageTemp = async (brandData) => {
  const endpoint = "/brandpage";
  const data = {
    action: "CREATEBRANDPAGETEMP",
    custregid: brandData.custregid,
    brandpagename: brandData.brandname,
    brandpagedesc: brandData.branddesc,
  };
  return updateApiData(endpoint, data);
};

// BrandDemo.js
// export const saveBrandPage = async (saveBrandData) => {
//   const endpoint = "/brandpage";
//   const data = {
//     action: "SAVEBRANDPAGE",
//     sessionuserid: saveBrandData.sessionuserid,
//     productid: saveBrandData.productid,
//     brandpagename: saveBrandData.brandname,
//     brandpagedesc: saveBrandData.branddesc,
//     brandpagedata: saveBrandData.brandpagedata,
//     brandpagevariables: saveBrandData.brandpagevariables,
//   };
//   return updateApiData(endpoint, data);
// };

// Figma.jsx & MapBrandEditModal.js & MapBrandPageModal.js
export const setBrandPage = async (setBrandData) => {
  const endpoint = "/template";
  const data = {
    action: "SETBRANDPAGE",
    templateid: setBrandData.templateid,
    sessionuserid: setBrandData.sessionuserid,
    brandpageid: setBrandData.brandpageid,
  };
  return updateApiData(endpoint, data);
};

// UserBrandCard.js
export const getBrandPageDetail = async (getBrandData) => {
  const endpoint = "/brandpage";
  const data = {
    action: "GETBRANDPAGEDETAIL",
    sessionuserid: getBrandData.sessionuserid,
    brandpageid: getBrandData.brandpageid,
  };
  return updateApiData(endpoint, data);
};

// CreateBrandPageModal.js
export const createBrandPageClone = async (cloneBrandData) => {
  const endpoint = "/brandpage";
  const data = {
    action: "CREATEBRANDPAGECLONE",
    sessionuserid: cloneBrandData.sessionuserid,
    brandpageid: cloneBrandData.brandpageid,
    brandpagename: cloneBrandData.brandpagename,
    brandpagedesc: cloneBrandData.brandpagedesc,
  };
  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// ********************************************** BRAND APIS ENDS ****************************************************
// *******************************************************************************************************************

// *******************************************************************************************************************
// ******************************************** PROJECT APIS STARTS **************************************************
// *******************************************************************************************************************

// GoToProject.js
export const downloadZipFileApi = async (projectData) => {
  const endpoint = "/project";
  const data = {
    action: "DOWNLOADMETAZIPFILE",
    templateid: projectData.templateid,
    projectname: projectData.projectname,
    sessionuserid: projectData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// MyTemplateDrawer.js (DOWNLOAD PROJECT LINK API)
export const downloadProjectLinkApi = async (projectLinkData) => {
  const endpoint = "/project";
  const data = {
    action: "DOWNLOADLINKSDATA",
    projectid: projectLinkData.projectid,
    sessionuserid: projectLinkData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// MyTemplateDrawer.js (DOWNLOAD PROJECT REPORT API)
export const downloadProjectReportApi = async (projectLinkData) => {
  const endpoint = "/project";
  const data = {
    action: "DOWNLOADLINKSREPORT",
    // action: "DOWNLOADLINKSDATA",
    projectid: projectLinkData.projectid,
    sessionuserid: projectLinkData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// MyTemplateDrawer.js (DOWNLOAD PROJECT REPORT API)
export const downloadProjectErrorReportApi = async (projectLinkData) => {
  const endpoint = "/project";
  const data = {
    action: "DOWNLOADERRORLINKSREPORT",
    // action: "DOWNLOADLINKSDATA",
    projectid: projectLinkData.projectid,
    sessionuserid: projectLinkData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// UserTemplateCard.js
export const getProjectDetails = async (getApiData) => {
  const endpoint = "/project";
  const data = {
    action: "GETALLPROJECTS",
    sessionuserid: getApiData.sessionuserid,
    templateid: getApiData.templateid,
  };
  return updateApiData(endpoint, data);
};

// GoToProject.js (GO_TO_PROJECT FOR GENERATING LINKS AFTER PROJECT FILE IS UPLOADED)
export const generateLinkApi = async (linkData) => {
  const endpoint = "/project";
  const data = {
    action: "GENERATELINKS",
    templateid: linkData.templateid,
    projectname: linkData.projectname,
    systemfilename: linkData.systemfilename,
    uploadfilename: linkData.uploadfilename,
    sessionuserid: linkData.sessionuserid,
  };
  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// ******************************************** PROJECT APIS ENDS ****************************************************
// *******************************************************************************************************************

// *******************************************************************************************************************
// ******************************************** DASHBOARD APIS STARTS ************************************************
// *******************************************************************************************************************

// PlayReportHeader.js (GO_TO_PROJECT FOR GENERATING LINKS AFTER PROJECT FILE IS UPLOADED)
export const getDashboardProjectData = async (tempProjectData) => {
  const endpoint = "/project";
  const data = {
    action: "GETTEMPLATEPROJECTS",
    templateid: tempProjectData.templateid,
    sessionuserid: tempProjectData.sessionuserid,
  };

  if (tempProjectData.templateid !== "all") {
    data.templateid = tempProjectData.templateid;
  }

  return updateApiData(endpoint, data);
};

// HeaderDashboard.js
export const getDashboardDataSearch = async (searchData) => {
  const endpoint = "/dashboarddata";
  const data = {
    action: "GETDASHBOARDDATA",
    sessionuserid: searchData.sessionuserid,
  };

  // Only include templateid in data if it's not 'all'
  if (searchData.templateid !== "all" && searchData.templateid !== null) {
    data.templateid = searchData.templateid;
  }

  if (searchData.projectid !== "all" && searchData.projectid !== null) {
    data.projectid = searchData.projectid;
  }

  if (searchData.days !== null && searchData.days !== "") {
    data.days = searchData.days;
  }

  if (
    (searchData.fromdate && searchData.todate) !== null &&
    (searchData.fromdate && searchData.todate) !== ""
  ) {
    data.fromdate = searchData.fromdate;
    data.todate = searchData.todate;
  }

  // console.log(data, "SEARCH API DATA")

  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// ******************************************** DASHBOARD APIS ENDS **************************************************
// *******************************************************************************************************************

// *******************************************************************************************************************
// ******************************************** REPORTS APIS STARTS **************************************************
// *******************************************************************************************************************

// PlayReportHeader.js (PLAY REPORT API CALLING ON SEARCH)
export const getPlayReportDataSearch = async (searchData) => {
  const endpoint = "/reports";
  const path = window.location.pathname.substring(1);
  const action =
    path === "user/play-report"
      ? "PLAYREPORT"
      : path === "user/data-report"
      ? "DATASTOREREPORT"
      : path === "user/heatmap-report"
      ? "HEATMREPORT"
      : "PLAYREPORT"; // Default to "PLAYREPORT" if none of the conditions match

  const data = {
    action,
    sessionuserid: searchData.sessionuserid,
  };

  // Only include templateid in data if it's not 'all'
  if (searchData.templateid !== "all" && searchData.templateid !== null) {
    data.templateid = searchData.templateid;
  }

  if (searchData.projectid !== "all" && searchData.projectid !== null) {
    data.projectid = searchData.projectid;
  }

  if (searchData.days !== null && searchData.days !== "") {
    data.days = searchData.days;
  }

  if (
    (searchData.fromdate && searchData.todate) !== null &&
    (searchData.fromdate && searchData.todate) !== ""
  ) {
    data.fromdate = searchData.fromdate;
    data.todate = searchData.todate;
  }

  // console.log(data, "SEARCH API DATA");

  return updateApiData(endpoint, data);
};

// VideoCreditReport.js & CreditHeader.js (CREDIT REPORT)
export const getCreditReportToday = async (sessionuserid) => {
  const endpoint = "/reports";
  const data = {
    action: "CREDITPREPORT",
    category: "Template",
    subcategory: "New",
    days: "7",
    sessionuserid: sessionuserid,
  };
  return updateApiData(endpoint, data);
};

//  CreditHeader.js
export const getCreditReportDataSearch = async (searchData) => {
  const endpoint = "/reports";
  const data = {
    action: "CREDITPREPORT",
    category: searchData.category,
    subcategory: searchData.selectedType,
    sessionuserid: searchData.sessionuserid,
  };

  if (searchData.days !== null && searchData.days !== "") {
    data.days = searchData.days;
  }

  // if (searchData.days !== null && searchData.days !== '') {
  //   data.days = searchData.days;
  // }

  if (
    (searchData.fromdate && searchData.todate) !== null &&
    (searchData.fromdate && searchData.todate) !== ""
  ) {
    data.fromdate = searchData.fromdate;
    data.todate = searchData.todate;
  }

  // console.log(data, "CREDIT SEARCH API DATA")

  return updateApiData(endpoint, data);
};

// *******************************************************************************************************************
// ************************************************ REPORTS APIS ENDS ************************************************
// *******************************************************************************************************************



export const generatePreviewLink = async (queryString, apikey) => {
  if (!navigator.onLine) {
    toast.error("No internet connection. Please check your network.");
    return;
  }

  try {
    const apiKey = apikey;
    const currentTime = new Date().getTime();
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        apikey: apiKey,
        ...Object.fromEntries(new URLSearchParams(queryString)), // Convert query string to object
        t: currentTime,
        action: "previewapi",
      }),
    };

    const response = await fetch(`${API_BASE_URL}/project/previewapi?apikey=${apiKey}&${queryString}`, requestOptions);
    // const response = await fetch(`${API_BASE_URL}/project`, requestOptions);
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.DESCRIPTION);
    }
  } catch (error) {
    toast.error("Something went wrong!! Please try later.");
    throw error; // Rethrow the error to handle it in the caller
  }
};


// GET

// export const generatePreviewLink = async (queryString) => {
//   if (!navigator.onLine) {
//     toast.error("No internet connection. Please check your network.");
//     return;
//   }

//   try {
//     const apiKey = "qewgbdg32rwerfdeqw";
//     const currentTime = new Date().getTime();
//     const response = await fetch(
//       `${API_BASE_URL}/user/project/previewapi?apikey=${apiKey}&${queryString}&t=${currentTime}`
//     );
//     const responseData = await response.json();
//     if (response.ok) {
//       return responseData;
//     } else {
//       throw new Error(responseData.DESCRIPTION);
//     }
//   } catch (error) {
//     toast.error("Something went wrong!! Please try later.");
//     throw error; // Rethrow the error to handle it in the caller
//   }
// };
