import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// MAIN LAYOUT COMPONENTS
import Home from "../../Home";
import Layout from "../mainLayout/templates/Layout";

// USER MODULE COMPONENTS
import UserLayout from "../mainLayout/userModule/UserLayout"; // USER PROFILE LAYOUT
import Profile from "../userModule/profile/Profile";
import Support from "../userModule/profile/Support";
import Teammates from "../userModule/profile/Teammates";
import ChangePassword from "../userModule/profile/ChangePassword";
import PurchasedHistory from "../userModule/profile/PurchasedHistory";
import BillingPlans from "../userModule/profile/BillingPlans";
import Subscription from "../userModule/profile/Subscription";

// USER SIGNUP/LOGIN COMPONENTS
import Login from "../userModule/login/Login";
import Register from "../userModule/register/Register";
import ForgotPassword from "../userModule/forgotPassword/ForgotPassword";
import ResetPassword from "../userModule/forgotPassword/ResetPassword";

// ONBOARDING COMPONENTS
import PersonOnboarding from "../userModule/onboarding/PersonOnboarding";
import IndustryOnboarding from "../userModule/onboarding/IndustryOnboarding";
import OrganisationOnboarding from "../userModule/onboarding/OrganisationOnboarding";

// MAIN PAGE (DASHBOARD) COMPONENTS
import Dashboard from "../Dashboard/Dashboard"; // DASHBOARD

// ANALYTICS COMPONENTS
import DataReport from "../analyticsModule/DataReport";
import HeatmapReport from "../analyticsModule/HeatmapReport";
import PlayReport from "../analyticsModule/playreport/PlayReport";

import LayoutWithNavbar from "../mainLayout/LayoutWithNavbar"; // NORMAL LAYOUT WITH NAVBAR

// RESOURCES COMPONENTS (++++++ TO BE USED IN FUTURE +++++++++)
// import KnowledgeBase from "../Resources/KnowledgeBase";
// import ProductDemo from "../Resources/ProductDemo";
// import SupportNew from "../Resources/SupportNew";

// EXTRA COMPONENTS
import NotFound from "../userModule/common/NotFound";
import TemplateLayout from "../newTemplate/globalTemplate/TemplateLayout";
import TemplateSelected from "../newTemplate/common/TemplateSelected";
import Logout from "../userModule/logout/Logout";
import UserTemplateLayout from "../newTemplate/userTemplate/UserTemplateLayout";
import AssetsLayout from "../myAssets/AssetsLayout";
import BrandLayout from "../brandPage/BrandLayout";

import "react-international-phone/style.css";

// PL_EDITOR COMPONENT IMPORT

import Figma from "../../plEditor/Components/Figma";
import Stigma from "../../ilEditor/Components/Stigma";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MainVP from "../../videoPlayer/MainVP";
import GoToProject from "../newTemplate/common/GoToProject";
import SSOLogin from "../userModule/login/SSOLogin";
import FromGoogle from "../userModule/login/FromGoogle";
import SeeAllTemplatesPage from "../newTemplate/globalTemplate/SeeAllTemplatesPage";
import CreditReport from "../analyticsModule/credit/CreditReport";
import SubmitRequest from "../userModule/profile/SubmitRequest";
import { Helmet } from "react-helmet";
import Preview from "../../videoPlayer/Preview";
import InFrame from "../../videoPlayer/InFrame";

library.add(
  faArrowRight,
  faArrowLeft,
  faCheck,
  faArrowDown,
  faCartShopping,
  faPhone,
  faXmark
);

const MainRoutes = () => {
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [resetPasswordClicked, setResetPasswordClicked] = useState(false);

  return (
    <>
      <Routes>
        {/* Common routes */}
        <Route path="*" element={<NotFound />} />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/`}
          element={<Home />}
        />

        {/* Routes within Layout */}
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/dashboard`}
            element={
              <>
                <Helmet>
                  <title>
                    VSPAGY Personalized Video Solution - Interactive Summary
                    Dashboard
                  </title>
                  <meta
                    name="description"
                    content="Elevate engagement with VSPAGY’s personalized video solution. Explore an interactive summary dashboard for enhanced insights and impactful user experiences."
                  />
                </Helmet>
                <Dashboard />
              </>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/global-template`}
            element={
              <>
                <Helmet>
                  <title>
                    Welcome to VSPAGY | Your Personalized and Interactive Video
                    Journey Begins Here
                  </title>
                  <meta
                    name="description"
                    content="Unlock your potential with our diverse templates. Discover endless possibilities and make your projects shine. Welcome to VSPAGY - where inspiration meets innovation."
                  />
                </Helmet>
                <TemplateLayout />
              </>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/user-template`}
            element={
              <>
                <Helmet>
                  <title>
                    Access Your Saved Personalized and Interactive Video
                    Templates | VSPAGY
                  </title>
                  <meta
                    name="description"
                    content="Unlock your saved creativity now effortlessly. Access and enhance your personalized and interactive video templates content creation with ease."
                  />
                </Helmet>
                <UserTemplateLayout />
              </>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/data-report`}
            element={<DataReport />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/heatmap-report`}
            element={<HeatmapReport />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/credit-report`}
            element={<CreditReport />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/play-report`}
            element={<PlayReport />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/my-assets`}
            element={
              <>
                <Helmet>
                  <title>
                    Efficient and Easy Media Management for Template Editing |
                    VSPAGY
                  </title>
                  <meta
                    name="description"
                    content="Unlock your creativity effortlessly here. Access and enhance your personalized and interactive video templates, making content creation even more easy."
                  />
                </Helmet>
                <AssetsLayout />
              </>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/brand-page`}
            element={<BrandLayout />}
          />
        </Route>

        {/* Routes within LayoutWithNavbar */}
        <Route element={<LayoutWithNavbar />}>
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/template-selected/:templateid`}
            element={<TemplateSelected />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/go-to-project/:ptid`}
            element={<GoToProject />}
          />

          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/global-template/see-all/:usecase`}
            element={<SeeAllTemplatesPage />}
          />
          {/* <Route
            path={`${process.env.REACT_APP_BASE_PATH}/knowledge-base`}
            element={<KnowledgeBase />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/product-demo`}
            element={<ProductDemo />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/support-new`}
            element={<SupportNew />}
          /> */}
        </Route>

        {/* Routes within UserLayout */}
        <Route element={<UserLayout />}>
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/profile`}
            element={<Profile />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/change-password`}
            element={<ChangePassword />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/subscription`}
            element={<Subscription />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/support`}
            element={<Support />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/submit-request`}
            element={<SubmitRequest />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/teammates`}
            element={<Teammates />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/billing-plans`}
            element={<BillingPlans />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_PATH}/purchased-history`}
            element={<PurchasedHistory />}
          />
        </Route>

        <Route path={`${process.env.REACT_APP_BASE_PATH}/editor`}>
          <Route index element={<NotFound />} />
          <Route exact path="createTemplate" element={<Figma />} />
          <Route path="updateTemplate" element={<Figma />} />
        </Route>

        {/* ROUTES FOR BRAND PAGE EDITOR */}
        <Route path={`${process.env.REACT_APP_BASE_PATH}/editor`}>
          <Route index element={<NotFound />} />
          <Route exact path="createBrandTemplate" element={<Stigma />} />
          <Route path="updateBrandTemplate" element={<Stigma />} />
        </Route>

        {/* Individual Routes */}
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/login`}
          element={
            <>
              <Helmet>
                <title>
                  VSPAGY Login | Secure Access to Personalized and Interactive
                  Video Experience
                </title>
                <meta
                  name="description"
                  content="Seamlessly access your account with a tailored and user-friendly experience. Sign in and experience the future of personalized and interactive video solutions."
                />
              </Helmet>
              <Login
                forgotPasswordClicked={forgotPasswordClicked}
                setForgotPasswordClicked={setForgotPasswordClicked}
                resetPasswordClicked={resetPasswordClicked}
                setResetPasswordClicked={setResetPasswordClicked}
              />
            </>
          }
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/logout`}
          element={<Logout />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/forgot-password`}
          element={
            forgotPasswordClicked ? (
              <ForgotPassword
                setForgotPasswordClicked={setForgotPasswordClicked}
              />
            ) : (
              <Navigate to={`${process.env.REACT_APP_BASE_PATH}/login`} />
            )
          }
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/reset-password`}
          element={<ResetPassword />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/signup`}
          element={
            <>
              <Helmet>
                <title>
                  Create a Free Account on VSPAGY for Personalized and
                  Interactive Solutions
                </title>
                <meta
                  name="description"
                  content=" Create a free account on VSPAGY for Personalized and Interactive Video Solutions. Join us now to unlock a tailored video experience like never before!"
                />
              </Helmet>
              <Register />
            </>
          }
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/getting-started-1`}
          element={<PersonOnboarding />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/getting-started-2`}
          element={<IndustryOnboarding />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/getting-started-3`}
          element={<OrganisationOnboarding />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/togoogle`}
          element={<SSOLogin />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/fromgoogle`}
          element={<FromGoogle />}
        />

        {/* VIDEO PLAYER ROUTES */}
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/video-player`}
          element={<MainVP />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/video-preview`}
          element={<Preview />}
        />
        <Route
          path={`${process.env.REACT_APP_BASE_PATH}/ivideo-preview/:as`}
          element={<InFrame/>}
        />
      </Routes>
    </>
  );
};

export default MainRoutes;
