import React,{useState} from "react";
import {
  Alert,
  Collapse,
  ColorPicker,
  Divider,
  InputNumber,
  Popover,
  Select,
  Slider,
  theme,
} from "antd";
import { Avatar, Segmented, Space } from "antd";
import minus from "../../assets/minus.svg";
import plus from "../../assets/plus.svg";
import textLeftAlign from "../../assets/textLeftAlign.svg";
import underlineText from "../../assets/underlineText.svg";
import textRightAlign from "../../assets/textRightAlign.svg";
import textCenterAlign from "../../assets/textCenterAlign.svg";
import textStrikeThrough from "../../assets/textStrikeThrough.svg";
import italicText from "../../assets/Italic.svg";
import cross from "../../assets/Cross.svg";
import PopTick from "../../assets/PopTick.svg";
import { fontFamily , fontStyle} from "../data.js";
import "./designDetail.css";
const DesignDetail = (props) => {
  const { token } = theme.useToken();
  const [hover,setHover] = useState(false);
  const [openFamily,setOpenFamily] = useState(false);
  const [openStyle,setOpenStyle] = useState(false);
  const panelStyle = {
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  function handleOpenStyle(){
    setOpenStyle(!openStyle);
  }
  function handleOpenFamily(){
    setOpenFamily(!openFamily);
  }
  const { Panel } = Collapse;
  function rgbToHex(r, g, b,a) {
    // Ensure that r, g, and b are within the valid range (0 to 255)
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));
    a = Math.max(0, Math.min(1, a));
    // Convert RGB to HEX
    const toHex = (value) => {
      const hex = value.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
  
    const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}${Math.round(a * 255).toString(16)}`;
  
    return hex;
  }

  function getFontFamily(fontFamily){
    // fontFamily.split(",")[0].includes("'")?fontFamily.split(",")[0].split("'")[1]:fontFamily.split(",")[0]
    let ans ;
    if(fontFamily.split(",")[0].includes("'")){
      ans = fontFamily.split(",")[0].split("'")[1]
    }
    else{
      ans =  fontFamily.split(",")[0];
    }
    if(ans == "Sarabun"){
     ans = "Sarab";
    }
    else if(ans  == "Anuphan"){
      ans =  "Anu";
    }
    else if(ans == "Arvo"){
      ans = "Arvn";
    }
    return ans;
  }

  return (
    <div className="mt-1 font-sans">
      <div>
        <Collapse
        className="editor-collapse"
          expandIconPosition="end"
          style={{
            //  padding: "15px",
            background: "#050506",
            color: "white",
          }}
          bordered={false}
          defaultActiveKey={["4", "5","6"]}
          expandIcon={({ isActive }) => (
            <img src={isActive ? minus : plus} alt="open" />
          )}
        >
          {props.type !== "checkbox" &&
            props.type !== "radio" &&
            props.type !== "image" &&
            props.type !== "video" &&
            props.type !== "vimage" &&
            props.type !== "vVideo" &&
            props.type!=="svg" &&
             (
              <Panel
                className="mb-2 p-3"
                header="Text"
                key="4"
                style={panelStyle}
              >
                <div className="pt-3 ">
                  <div className="design-selector flex  gap-2 items-center mb-2">
                    <div className="text-xs font-normal ">Font</div>
                    
                  <Popover
                    placement="left"
                    arrow={false}
                    open={openFamily}
                    onOpenChange={handleOpenFamily}
                    content={
                      <div
                        className="bg-[#050506] w-[250px] h-[443px] absolute right-[60px] top-[-40px] "
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                        onMouseMove={()=>{
                          setHover(true);
                        }}
                      >
                        <div className="flex justify-between p-3 py-2 pr-3">
                          <div className="text-[#fff] font-semibold">
                            Select Font Family
                          </div>
                          <img
                            className="cursor-pointer"
                            src={cross}
                            alt="cross"
                            onClick={()=>{
                              setOpenFamily(false);
                            }}
                          />
                        </div>
                        <Divider className="!m-0" />
                        <div className="h-[400px] overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                      
                     >
                        {
                          fontFamily.map((item,index)=>{
                             return (
                              <div className={`${props.fontFamily === item.value && !hover?"bg-[#1D1D1F]":""} flex mt-1 py-1  w-full text-left cursor-pointer hover:bg-[#1D1D1F]`}
                               style = {{
                                fontFamily: item.value,
                               }}
                               
                               onClick={()=>{
                                props.setfontFamily(item.value);
                               }}
                              > 
                                <div className="pl-[20px] w-[40px] h-[20px]">
                                  {props.fontFamily === item.value && <img className="w-[100%] h-[100%]" src={PopTick} alt="P"/> }
                                </div>
                                <div className="text-[#ffffff] pl-[30px] pr-[50px] text-[14px] ">
                                  {item.label}
                                </div>
                              </div>
                             )
                          })
                        }

                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div className="mx-4 cursor-pointer"
                    style={{
                      textAlign : "center",
                      height : "28px"
                    }}
                    >
                     <div className="flex mt-[3px]"
                     
                     onClick={
                      ()=>{
                        setHover(false);
                      }
                     }>
                       <div className="ml-2 ">
                         {getFontFamily(props.fontFamily)}
                         {/* {props.fontFamily.split(",")[0].includes("'")?props.fontFamily.split(",")[0].split("'")[1]:props.fontFamily.split(",")[0]} */}
                       </div>
                       <div className="mt-[7px] ml-4">
                       <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="10px" height="10px" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                       </div>
                     </div>
                    </div>
                  </Popover>
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="flex h-10 gap-2 items-center">
                      <h4 className="text-xs font-normal">Size</h4>
                      <InputNumber
                        className="editor-inum ml-1 !px-0  w-15 "
                        type="number"
                        min="10"
                        
                        bordered={true}
                        value={Math.trunc(props.fontSize)}
                        onClick={
                          ()=>{
                            props.fieldEnabled.current = true;
                          }
                        }
                        onStep={()=>{
                          props.fieldEnabled.current = true;
                        }}
                        onBlur = {()=>{
                          if(props.fontSize===null){
                            props.setFontSize(10);
                          }
                          props.fieldEnabled.current = false;
                        }}
                        onChange={(value) => {
                          props.setFontSize(Math.trunc(value));
                        }}
                      />
                    </div>
                    <div className="design-selector flex  gap-2 items-center">
                      <div className="text-xs font-normal pb-[2px]">Style</div>
                      
                      <Popover
                    placement="left"
                    arrow={false}
                    open={openStyle}
                    onOpenChange={handleOpenStyle}
                    content={
                      <div
                        className={`bg-[#1D1D1F] w-[100px] absolute ${props.fontWeight==="lighter"?"top-[-20px]":props.fontWeight==="normal"?"top-[-50px]":"top-[-80px]"} right-[-88px]`}
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        <div className=" overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2">
                        {
                          fontStyle.map((item,index)=>{
                             return (
                              <div className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                               onClick={()=>{
                                props.setFontWeight(item);
                                handleOpenStyle();
                               }}
                              > 
                                <div className="px-2 w-[30px] h-[20px]">
                                  {props.fontWeight === item && <img className="w-[100%] h-[100%]" src={PopTick} alt="P"/> }
                                </div>
                                <div className="text-[#ffffff] text-[14px] ">
                                  {item.charAt(0).toUpperCase() + item.slice(1)}
                                </div>
                              </div>
                             )
                          })
                        }
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div className="mx-4 cursor-pointer"
                    style={{
                      textAlign : "center",
                      height : "28px"
                    }}
                    >
                     <div className="flex mt-[2px]">
                       <div className="ml-2 text-[13px]">
                         {props.fontWeight.charAt(0).toUpperCase() + props.fontWeight.slice(1)}
                       </div>
                       <div className="mt-[7px] ml-4">
                       <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="10px" height="10px" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                       </div>
                     </div>
                    </div>
                  </Popover>
                    </div>
                    <div className="flex h-10  gap-1 items-center">
                      <h4 className="text-xs font-normal">Letter Spacing</h4>
                      <InputNumber
                        className="editor-inum ml-1 !px-0  w-10 "
                        type="number"
                        min="0"
                        bordered={true}
                        value={Math.trunc(props.letterSpacing)}
                        onClick={
                          ()=>{
                            props.fieldEnabled.current = true;
                          }
                        }
                        onStep={()=>{
                          props.fieldEnabled.current = true;
                        }}
                        onBlur = {()=>{
                          if(props.letterSpacing===null){
                            props.setLetterSpacing(0);
                          }
                          props.fieldEnabled.current = false;
                        }}
                        onChange={(value) => {
                          props.setLetterSpacing(Math.trunc(value));
                        }}
                      />
                    </div>
                    
                  </div>
                  <div className="mt-3 flex justify-between h-10">
                    <Space direction="vertical">
                      <Segmented
                        style={{
                          backgroundColor: "#050506",
                          height: "10px",
                        }}
                        size="small"
                        value={props.textAlign}
                        onChange={(value) => {
                          props.setTextAlign(value);
                        }}
                        options={[
                          {
                            label: (
                              <div
                                style={{
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                }}
                              >
                                <Avatar
                                  src={textLeftAlign}
                                  style={{
                                    borderRadius: "0px",
                                    paddingRight: "10px",
                                  }}
                                />
                              </div>
                            ),
                            value: "left",
                          },
                          {
                            label: (
                              <div
                                style={{
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                }}
                              >
                                <Avatar
                                  src={textCenterAlign}
                                  style={{
                                    borderRadius: "0px",
                                    paddingRight: "10px",
                                  }}
                                />
                              </div>
                            ),
                            value: "center",
                          },
                          {
                            label: (
                              <div
                                style={{
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                }}
                              >
                                <Avatar
                                  src={textRightAlign}
                                  style={{
                                    borderRadius: "0px",
                                    paddingRight: "10px",
                                  }}
                                />
                              </div>
                            ),
                            value: "right",
                          },
                        ]}
                      />
                    </Space>
                    <div className="flex gap-1 h-full">
                      <div
                        className={`${
                          props?.textDecoration.includes("underline")
                            ? "bg-[#1C68EB] rounded-[4px]"
                            : ""
                        } p-2 h-[40px]`}
                      >
                        <img
                          className="cursor-pointer pt-1"
                          onClick={() => {
                            props.textDecoration.includes("underline")
                              ? props.textDecoration.includes("line-through")
                                ? props.setTextDecoration("line-through")
                                : props.setTextDecoration("none")
                              : props.textDecoration.includes("line-through")
                              ? props.setTextDecoration(
                                  "underline line-through"
                                )
                              : props.setTextDecoration("underline");
                            // setUnderlineTextField(!underlineTextField)
                          }}
                          src={underlineText}
                          alt=""
                        />
                      </div>
                      <div
                        className={`${
                          props?.textDecoration.includes("line-through")
                            ? "bg-[#1C68EB] rounded-[4px]"
                            : ""
                        } p-2`}
                      >
                        <img
                          className="cursor-pointer pt-1"
                          onClick={() => {
                            props.textDecoration.includes("line-through")
                              ? props.textDecoration.includes("underline")
                                ? props.setTextDecoration("underline")
                                : props.setTextDecoration("none")
                              : props.textDecoration.includes("underline")
                              ? props.setTextDecoration(
                                  "underline line-through"
                                )
                              : props.setTextDecoration("line-through");
                          }}
                          src={textStrikeThrough}
                          alt=""
                        />
                      </div>
                      <div
                        className={`${
                          props?.fontStyle === "italic"
                            ? "bg-[#1C68EB] rounded-[4px]"
                            : ""
                        } p-2`}
                      >
                        <img
                          className="cursor-pointer pt-1"
                          onClick={() => {
                            props.fontStyle === "italic"
                              ? props.setFontStyle("normal")
                              : props.setFontStyle("italic");
                          }}
                          src={italicText}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            )}
          {props.type !== "checkbox" &&
            props.type !== "radio" &&
            props.type !== "image" &&
            props.type !== "video" &&
            props.type !== "vimage" &&
            props.type !== "vVideo" &&
            props.type !== "svg" && (
              <Divider className="!m-0  !bg-[#2b2b2c]" />
            )}
          <Panel header="Color" className="mb-2 p-3" key="5" style={panelStyle}>
            <div className="pt-3">
              {props.type !== "image" && props.type !== "video" &&
                props.type !== "vimage" &&
                props.type !== "vVideo" && (
                <div className="bg_menu flex gap-2 items-center">
                  <h4 className="w-28 text-left text-[14px]">Fill</h4>

                  <ColorPicker
                    className="fill_color"
                    format="hex"
                    arrow={false}
                    value={props.tbgColor}
                    style={{
                      borderWidth: "2px",
                    }}
                    
                    onChange={(e, value, hex) => {
                      if (props.itemSelected != null) props.setTbgColor(value);
                    }}
                    onChangeComplete={(e)=>{
                      let r = Math.round(e.metaColor.r);
                      let g = Math.round(e.metaColor.g);
                      let b = Math.round(e.metaColor.b);
                      let a = e.metaColor.a;
                      let dr = rgbToHex(r,g,b,a);
                      if (props.itemSelected != null) props.setbgColor(dr);
                    }}
                  />
                  <div className="text-[12px] leading-4 ml-1">
                    {props?.tbgColor?.toUpperCase()}
                  </div>
                </div>
              )}
              {props.type !== "checkbox" &&
                props.type !== "radio" &&
                props.type !== "image" &&
                props.type !== "video" && 
            props.type !== "vimage" &&
            props.type !== "vVideo" && 
            props.type!=="svg" &&  (
                  <div className="bg_menu flex gap-2 items-center">
                    <h4 className="w-28 text-left text-[14px]">Text</h4>

                    <ColorPicker
                      className="fill_color"
                      format="hex"
                      arrow={false}
                      value={props.ttextColor}
                      style={{
                        borderWidth: "2px",
                      }}
                      onChange={(e, value, hex) => {
                        if (props.itemSelected != null)
                          props.setTTextColor(value);
                      }}
                      onChangeComplete={(e)=>{
                        let r = Math.round(e.metaColor.r);
                        let g = Math.round(e.metaColor.g);
                        let b = Math.round(e.metaColor.b);
                        let a = e.metaColor.a;
                        let dr = rgbToHex(r,g,b,a);
                        if (props.itemSelected != null) props.setTextColor(dr);
                      }}
                    />
                    <div className="text-[12px] leading-4 ml-1">
                      {props?.ttextColor?.toUpperCase()}
                    </div>
                  </div>
                )}
              {props.type !== "checkbox" && props.type !== "radio" && (
                <div className="bg_menu flex gap-2 items-center ">
                  <h4 className="w-28 text-left text-[14px]">Border</h4>

                  <ColorPicker
                    className="fill_color"
                    format="hex"
                    arrow={false}
                    value={props.tborderColor}
                    style={{
                      borderWidth: "2px",
                    }}
                    onChange={(e, value, hex) => {
                      if (props.itemSelected != null)
                        props.setTBorderColor(value);
                    }}
                    onChangeComplete={(e)=>{
                      let r = Math.round(e.metaColor.r);
                      let g = Math.round(e.metaColor.g);
                      let b = Math.round(e.metaColor.b);
                      let a = e.metaColor.a;
                      let dr = rgbToHex(r,g,b,a);
                      if (props.itemSelected != null) props.setBorderColor(dr);
                    }}
                  />
                  <div className="text-[12px] leading-4 ml-1">
                    {props?.tborderColor?.toUpperCase()}
                  </div>
                </div>
              )}
            </div>
          </Panel>
          <Divider className="!m-0 !bg-[#2b2b2c]" />
          <Panel header="Opacity" className=" p-3" key="6" style={panelStyle}>
            <div className="flex     slider  w-full justify-between ">
              <div className="w-full">
                <div className="text-start mt-5">Layer Opacity</div>
                <div className="flex w-full items-center ">
                  <Slider
                    min={0.1}
                    tooltip={{ open: false }}
                    className="w-44 m-0 mt-1"
                    max={1}
                    step={0.01}
                    onChange={(value) => props.setOpacity(value)}
                    value={props.opacity}
                  />
                  <div className="ml-10">{props.opacity}</div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default DesignDetail;
