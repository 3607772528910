import { toast } from "react-toastify";

export const baseVideo= async (templateData) => {

    // FETCHING API_BASE_URL FROM INDEX.HTML
    const API_BASE_URL = window.baseurl;
    
    try {
        // debugger
      let action =  "UPDATEBASEVIDEO";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
        action : action,
        sessionuserid : templateData.sessionuserid,
        templateid : templateData.tempID,
        assetid : templateData.assetID,
        assettype : templateData.assetType
       }),
      };
  
      const response = await fetch(
        // "http://180.151.195.62:8070/mconnectsaas/template?",
        `${API_BASE_URL}/template`,
        requestOptions
      );
      if (!response.ok) {
        toast.dismiss();
        toast.error("Connection Problem", {
          theme: "dark",
        });
        // loader(false);
        throw new Error('Network response was not ok');
      }
      // console.log(response, "RESPONSE UPDATED DATA API");
      const data = await response.json();
      console.log(data, "Response Data");
      if (data.STATUS === "1000") {
        toast.dismiss();
        toast.success(data.DESCRIPTION, {
          theme: "dark",
        });
        // loader(false);
        return data;
      } else {
        toast.dismiss();
        toast.error(data.DESCRIPTION, {
          theme: "dark",
        });
        // loader(false);
        return data
      }
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(error.message, {
        theme: "dark",
      });
    //   loader(false);
      throw new Error(error.message);
    }
  };