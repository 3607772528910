import { Input, Popover } from "antd";
import React from "react";
import { useState } from "react";
import x from "../../assests/commonIcons/common/x.svg"
import check from "../../assests/commonIcons/check.svg";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const PasswordInputBox = ({ password, setPassword, setValidPassword, placeholder, setPlaceholder }) => {
  const [passwordHidden, setPasswordHidden] = useState(false);
  //   const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const [showPasswordError, setShowPasswordError] = useState(false);
  const validatePasswordPolicy = (password) => {
    const policy = {
      minLength: 8,
      maxLength: 21,
      hasSpecialChar: false,
      hasNumber: false,
      hasLowerCase: false,
      hasUpperCase: false,
    };

    for (let i = 0; i < password.length; i++) {
      const char = password.charAt(i);

      if (i >= policy.minLength && i <= policy.maxLength) {
        // policy.minLength = 0;
        policy.minLength = 7;
        policy.maxLength=21;
      }

      if (char.match(/[!@#$%^&*(),.?":{}|<>]/)) {
        policy.hasSpecialChar = true;
      }

      if (char.match(/[0-9]/)) {
        policy.hasNumber = true;
      }

      if (char.match(/[a-z]/)) {
        policy.hasLowerCase = true;
      }

      if (char.match(/[A-Z]/)) {
        policy.hasUpperCase = true;
      }
    }

    if (password.length >= policy.minLength && password.length <= policy.maxLength) {
      policy.minLength = 0;
    }

    return policy;
  };

  const arePoliciesMet = (policy) => {
    return (
      policy.minLength === 0 &&
      policy.hasSpecialChar &&
      policy.hasNumber &&
      policy.hasLowerCase &&
      policy.hasUpperCase
    );
  };
  const inputChanged = (e) => {
    setPassword(e.target.value);
    setShowPasswordError(true);
    let isPolicytrue = arePoliciesMet(validatePasswordPolicy(e.target.value));
    isPolicytrue ? setOpen(false) : setOpen(true);
    setValidPassword(isPolicytrue);
  };
  //   useEffect(() => {
  //     setValidPassword(arePoliciesMet(passwordPolicy));
  //   }, [inputChanged]);
  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };

  const passwordPolicy = validatePasswordPolicy(password);
  const content = (
    <div className=" max-w-[150px] p-2">
      <div className="text-[13px] w-[150px] font-normal text-[#888888]  mt-1 ">
        {
          <>
            <div className="flex flex-col">
              <div className="flex items-center">
                <img
                  src={password.length >= 8 && password.length <=21 ? check : x}
                  alt={password.length >= 8 && password.length <=21 ? "check" : "x"}
                />
                <span className={password.length >= 8  && password.length <=21 ? "text-green-500 ml-0.5" : "ml-0.5"}>
                  8-21 characters
                </span>
              </div>
              

              <div className="flex items-center">
                <img
                  src={passwordPolicy.hasNumber ? check : x}
                  alt={passwordPolicy.hasNumber ? "check" : "x"}
                />
                <span
                  className={passwordPolicy.hasNumber ? "text-green-500 ml-0.5" : "ml-0.5"}
                >
                  number
                </span>
              </div>
              <div className="flex items-center">
                <img
                  src={passwordPolicy.hasUpperCase ? check : x}
                  alt={passwordPolicy.hasUpperCase ? "check" : "x"}
                />
                <span
                  className={
                    passwordPolicy.hasUpperCase ? "text-green-500 ml-0.5" : "ml-0.5"
                  }
                >
                  uppercase
                </span>
              </div>
              <div className="flex items-center">
                <img
                  src={passwordPolicy.hasLowerCase ? check : x}
                  alt={passwordPolicy.hasLowerCase ? "check" : "x"}
                />
                <span
                  className={
                    passwordPolicy.hasLowerCase ? "text-green-500 ml-0.5" : "ml-0.5"
                  }
                >
                  lowercase{" "}
                </span>
              </div>
              <div className="flex items-center">
                <img
                  src={passwordPolicy.hasSpecialChar ? check : x}
                  alt={passwordPolicy.hasSpecialChar ? "check" : "x"}
                />
                <span
                  className={
                    passwordPolicy.hasSpecialChar ? "text-green-500 ml-0.5" : "ml-0.5"
                  }
                >
                  1 special character
                </span>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
  return (
    <>
      <Popover
        arrow={false}
        content={content}
        open={open}
        // open={true}
        onOpenChange={handleOpenChange}
        trigger="change"
        rootClassName={{ padding: "1px" }}
        placement="rightTop"
      >
        <div className="">
          <Input.Password
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone onClick={togglePasswordVisibility} />
              ) : (
                <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
              )
            }
            onBlur = {()=>{
              setOpen(false);
              
            }}
            value={password}
            onChange={inputChanged}
            visibilityToggle
            className={`cursor-pointer h-[44px]  text-base ${
              showPasswordError && !arePoliciesMet(passwordPolicy)
                ? "!border-[#ff4d4f] hover:!border-[#ff4d4f]"
                : ""
            }`}
            placeholder={placeholder}
          />
        </div>
      </Popover>
      {/* {showPasswordError && !arePoliciesMet(passwordPolicy) && (
        <span className="!text-[#ff4d4f]">Incorrect Password</span>
      )} */}
      {showPasswordError && !arePoliciesMet(passwordPolicy) && (
        <span className="text-red-500">
          {password.length > 21
            ? "Password must be less than 21 characters"
            : "Incorrect Password"}
        </span>
      )}
    </>
  );
};

export default PasswordInputBox;