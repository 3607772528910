import React from "react";
import { useState } from "react";
import ImageDashboard from "./ImageDashboard";
import GifDashboard from "./GifDashboard";
import VideoDashboard from "./videoDashboard/VideoDashboard";
import useWindowWidth from "../common/hooks/useWindowWidth";

const ProductType = () => {
  // const [activeTab, setActiveTab] = useState("videos");
  const [activeTab, ] = useState("videos");

  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  return (
    <div>
      <div className="">
        <div className={`flex space-x-4 ${isDesktop ? "px-11" : "px-8"}`}>
          {/* <button
            className={`${
              activeTab === "videos"
                ? "bg-transparent text-[#C62134] text-base font-medium border-b-4 border-solid border-[#C62134]"
                : "text-black bg-transparent text-base font-normal border-none"
            } py-2 px-2 border-t-0 border-r-0 border-l-0`}
            onClick={() => handleTabClick("videos")}
          >
            Videos
          </button> */}
          {/* <button
            className={`${
              activeTab === "images"
                ? "bg-transparent text-[#C62134] text-base font-medium border-b-4 border-solid border-[#C62134]"
                : "text-black bg-transparent text-base font-normal border-none"
            } py-2 px-2 border-t-0 border-r-0 border-l-0`}
            onClick={() => handleTabClick("images")}
          >
            Images
          </button>
          <button
            className={`${
              activeTab === "gifs" ? "bg-transparent text-[#C62134] text-base font-medium border-b-4 border-solid border-[#C62134]" : "text-black bg-transparent text-base font-normal border-none"
            } py-2 px-2 border-t-0 border-r-0 border-l-0`}
            onClick={() => handleTabClick("gifs")}
          >
            GIFs
          </button> */}
        </div>
        <div className={` ${isDesktop ? "px-11" : "px-8"}`}>
          {/* Content for the selected tab */}
          {activeTab === "videos" && <VideoDashboard />}
          {activeTab === "images" && <ImageDashboard />}
          {activeTab === "gifs" && <GifDashboard />}
        </div>
      </div>
    </div>
  );
};

export default ProductType;
