import React from "react";
import { Form, Input, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { roleIndustry } from "../profile/data";
// import logoimage from "../../assests/commonImages/new-vspagy-logo.svg";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import LoadingBar from "react-top-loading-bar";
import organisationOnboarding from "../../assests/commonImages/userOnboarding//getting-started-3.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import "../../common/styles/userModule/UserOnboarding.css";
import GlobalSelecter from "../common/GlobalSelecter";
import { useState } from "react";
import SimpleButton from "../../common/components/SimpleButton";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { updateCustomerCompanyRole } from "../../api/api_updateData";
import useSessionChecker from "../../common/functions/useSessionChecker";

export default function OrganisationOnboarding() {
  const navigate = useNavigate();
  useSessionChecker(navigate);

  const sessionuserid = localStorage.getItem("sessionUserId");

  const [orgName, setOrgName] = useState("");
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const navigateHome = () => {
  //   // 👇️ navigate to /
  //   navigate(`${process.env.REACT_APP_BASE_PATH}/dashboard`);
  // };

  const handleUpdateCompany = () => {
    setIsLoading(true);
    const custCompanyData = {
      custregid: sessionuserid,
      orgname: orgName,
      role: role,
    };
    // console.log(custCompanyData, "ORG DATA");
    updateCustomerCompanyRoleMutation(custCompanyData);
  };

  const { mutate: updateCustomerCompanyRoleMutation } = useMutation(
    (custCompanyData) => updateCustomerCompanyRole(custCompanyData),
    {
      onSuccess: (custCompanyData) => {
        if (custCompanyData.STATUS === "1000") {
          // const custData = JSON.parse(custCompanyData.DATA);
          // console.log(custData, "CUST DATA");
          // toast.success(custCompanyData.DESCRIPTION, {
          //             //
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   
          // });
          setTimeout(() => {
            navigate(
              `${process.env.REACT_APP_BASE_PATH}/global-template`
              // { state: { from: "OrgOnboarding" }, }
            );
          }, 2000); // Navigate after 2 seconds
        } else {
          toast.error(custCompanyData.DESCRIPTION);
          setIsLoading(false);
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  const goToGlobalTemplate = () => {
    // localStorage.setItem("startingModalShow", "yes");
    navigate(`${process.env.REACT_APP_BASE_PATH}/global-template`, {
      state: { from: "OrgOnboarding" },
    });
  };

  const [form] = Form.useForm();
  const isButtonDisabled = orgName === "" || role === "";
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="flex h-[68px] items-center justify-between">
            <img
              src={newVspagyLogo}
              alt="VPSAGY logo"
              className="logo-img w-[132px] snap-center ml-2"
            />
            <div className="flex w-[140px] justify-between">
              <Link
                to={`${process.env.REACT_APP_BASE_PATH}/getting-started-2`}
                className="no-underline"
              >
                <p className="back-link flex items-center font-normal text-[#706c6c] text-base hover:text-black hover:underline ">
                  {" "}
                  <LeftOutlined />
                  <span>Back</span>
                </p>
              </Link>
              <span className="flex items-center pr-4">
                <span
                  onClick={goToGlobalTemplate}
                  className="no-underline cursor-pointer"
                >
                  <p className="skip-link flex items-center font-medium text-base text-secondary-blue hover:text-primary-blue hover:underline">
                    <span>Skip</span>
                    <RightOutlined />
                  </p>
                </span>
              </span>
            </div>
          </div>
          <div className="pt-6 m-auto text-center">
            <div className="mt-10 sm:px-20 flex flex-col">
              <img
                src={organisationOnboarding}
                alt="organisationOnboarding"
                className="block m-auto w-[250px] my-3"
              />
              <span
                className="select-none font-medium sm:!text-[28px] max-sm:text-[22px]"
                style={{
                  fontFamily: "inter",
                }}
              >
                <span className="max-sm:w-[100%] inline-block">Lastly,</span> details about your{" "}
                <span className="text-[#E16023] fontl font-normal sm:text-[25px] max-sm:text-[19px]">
                  organization
                </span>
              </span>
              <span className="sm:w-[500px] md:w-[600px] lg:w-[700px] text-center mt-4 mx-auto text-[#727171] select-none sm:text-base max-sm:text-sm max-sm:px-[50px]">
                Tell us about the kind of organization you are working in. This
                helps us to identify the demand or necessity of different
                professional sectors.
              </span>
            </div>
            <Form form={form} layout="vertical">
              <div className="industry-details py-3 m-auto mt-6 sm:w-[25rem] max-sm:w-[270px]">
                <Form.Item
                  className="text-[#131313] font-medium text-sm mb-8 select-none"
                  label="Organization Name"
                  name="organizationName"
                >
                  <Input
                    className="px-3 h-10 rounded-[4px]"
                    onChange={(e) => setOrgName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Role"
                  className="ant-d-select-org sm:w-[25rem] max-sm:[270px] text-[#131313] font-medium text-sm sm:mr-24 mb-8 select-none"
                  name="role"
                >
                  <GlobalSelecter
                    setRole={setRole}
                    type="role"
                    value={role}
                    show={true}
                    data={roleIndustry}
                  />
                </Form.Item>
                <Form.Item>
                  <SimpleButton
                    label="Let's Get Started"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="!rounded-[4px] w-full !px-6 "
                    onClick={handleUpdateCompany}
                    disabled={isButtonDisabled}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="onboardLoader">
            <LoadingBar
              progress={99.9}
              width={100}
              height={3}
              loaderSpeed={0}
              color="#C62134"
            />
          </div>
          <div className="onboardLoader2">
            <LoadingBar
              progress={99.9}
              width={100}
              height={3}
              loaderSpeed={0}
              color="#0943A6"
            />
          </div>
          <div className="onboardLoader3">
            <LoadingBar
              progress={99.9}
              width={100}
              height={3}
              color="#FF5E12"
            />
          </div>
        </>
      )}
    </>
  );
}
