import React, { useEffect, useRef, useState } from "react";
import { Modal, Spin, Steps } from "antd";
import TemplateDetail from "./TemplateDetail";
import UploadTemplateThumbnail from "./UploadTemplateThumbnail";
import UploadTemplateVideo from "./UploadTemplateVideo";
import clevertap from "clevertap-web-sdk";
// import Input from "rc-input";

const CreateTemplateModal = ({ modal, setModal, sessionuserid }) => {
  sessionuserid = localStorage.getItem("sessionUserId");

  const userAgent = navigator.userAgent;
  const [current, setCurrent] = useState(0);
  const [templateid, setTemplateId] = useState(null); // Initialize templateid as null
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [saveTemplateClicked, setSaveTemplateClicked] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [handlebrowseClick, sethandlebrowseClick] = useState("NO");
  const [handlevideoUpload, sethandlevideoUpload] = useState("No");
  const [generateThumbnail, setgenerateThumbnail] = useState(null);
  const [uploadThumbnailClick, setuploadThumbnailClick] = useState(null);
  const [uploadThumbnailVideoClick, setuploadThumbnailVideoClick] =
    useState(null);

  // Use a ref to hold the video element
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);

  const cleanup = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      // You may also want to reset the video source
      videoRef.current.src = "";
    }
    setModal(false);
    CreateTemplate();
    setTemplateName("");
    setTemplateDescription("");
    window.location.reload();
  };

  const resetCurrentStep = () => {
    setCurrent(0); // Reset the current step to 0
  };

  // This useEffect listens for changes in the modal's visibility and resets the current step when the modal is opened.
  useEffect(() => {
    if (modal) {
      resetCurrentStep();
      handleUpdateDashboard();
    }
  }, [modal]);

  const handleUpdateDashboard = () => {
    setIsSpinning(true);

    setTimeout(() => {
      setIsSpinning(false);
    }, 1200);
  };

  const handleTemplateDetailComplete = (templateId) => {
    setTemplateId(templateId);
    // Call this function when the TemplateDetail step is completed
    CreateTemplate();
    // console.log("CLICKED SAVE TEMPLATE")
    next(); // Advance to the next step (Upload)
  };

  const handleVideoUpload = (selectedFile) => {
    // console.log("CLICKED UPLOAD VIDEO")
    sethandlevideoUpload("YES");
    CreateTemplate();
    // Call this function when the TemplateDetail step is completed
    next(); // Advance to the next step (Upload)
    setThumbnailFile(selectedFile);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
    sethandlebrowseClick("YES");
    // console.log("browse video clicked");
  };

  const CreateTemplate = () => {
    clevertap.event.push("CreateTemplate", {
      // templateDetailData,
      USER_ID: sessionuserid,
      Email: sessionuserid,
      Screen_1: "Template-Details",
      CreateTemplate_Clicked: "YES",
      TemplateName_Entered: templateName
        ? templateName !== ""
          ? templateName
          : "NO"
        : "NO",
      TemplateName_Description: templateDescription ? "YES" : "NO",
      SaveTemplate_Clicked:
        saveTemplateClicked === "YES"
          ? "YES"
          : saveTemplateClicked === null
          ? "NO"
          : "NO",
      TemplateID: templateid,
      // "TemplateType":"",
      // "Template":"",
      Screen_2: "Upload-Template-Video",
      UploadMedia_Clicked:
        saveTemplateClicked === "YES"
          ? "YES"
          : saveTemplateClicked === null
          ? "NO"
          : "NO",
      Video_Browsed_Clicked: handlebrowseClick === "YES" ? "YES" : "NO",
      Upload_Video_Clicked: fileInputRef === null ? "NO" : "YES",
      Video_Uploading: handlevideoUpload === "YES" ? "YES" : "NO",

      Screen_3: "Upload-Template-Thumbnail",
      Generate_Thumbnail_Clicked: handlevideoUpload === "YES" ? "YES" : "NO",
      Generate_Thumbnail:
        generateThumbnail === "YES"
          ? "YES"
          : generateThumbnail === null
          ? "NO"
          : "NO",
      Upload_clicked:
        uploadThumbnailClick === "YES"
          ? "YES"
          : uploadThumbnailClick === null
          ? "NO"
          : "NO",
      Upload_video:
        uploadThumbnailVideoClick === "YES"
          ? "YES"
          : uploadThumbnailVideoClick === null
          ? "NO"
          : "NO",
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    setSaveTemplateClicked(null);
    setgenerateThumbnail(null);
    setuploadThumbnailClick(null);
    setuploadThumbnailVideoClick(null);
    console.log("CreateTemplate EVENT CLICKED");
  };

  const steps = [
    {
      title: "Template",
      description: "Add template details",
      content: (
        <TemplateDetail
          onComplete={handleTemplateDetailComplete}
          templateName={templateName}
          templateDescription={templateDescription}
          setTemplateName={setTemplateName}
          setTemplateDescription={setTemplateDescription}
          saveTemplateClicked={saveTemplateClicked}
          setSaveTemplateClicked={setSaveTemplateClicked}
          handleUpdateDashboard={handleUpdateDashboard}
        />
      ),
    },
    {
      title: "Upload",
      description: "Upload media",
      content: (
        <UploadTemplateVideo
          onUploadVideo={handleVideoUpload}
          templateid={templateid}
          setThumbnailFile={setThumbnailFile}
          fileInputRef={fileInputRef}
          handleBrowseClick={handleBrowseClick}
          handleUpdateDashboard={handleUpdateDashboard}
        />
      ),
      // content: "Second-content",
    },
    {
      title: "Set Thumbnail",
      description: "Set video thumbnail",
      content: (
        <UploadTemplateThumbnail
          thumbnailFile={thumbnailFile}
          templateid={templateid}
          videoRef={videoRef}
          generateThumbnail={generateThumbnail}
          setgenerateThumbnail={setgenerateThumbnail}
          uploadThumbnailClick={uploadThumbnailClick}
          setuploadThumbnailClick={setuploadThumbnailClick}
          uploadThumbnailVideoClick={uploadThumbnailVideoClick}
          setuploadThumbnailVideoClick={setuploadThumbnailVideoClick}
          CreateTemplate={CreateTemplate}
        />
      ),
      // content: "Last-content",
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  // THE BELOW CODE IS CODE FOR STEPS FROM ANT DESIGN WHICH IS
  // USED ON BUTTON FOR NEXT AND PREV PAGE (STEP)

  // const prev = () => {
  //   setCurrent(current - 1);
  // };

  // const items = steps.map((item) => ({
  //   key: item.title,
  //   title: item.title,
  //   description: item.description,
  // }));

  const contentStyle = {
    // lineHeight: "260px",
    textAlign: "center",
    // color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    // borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 32,
  };

  const getTitleClass = (index) => {
    return current === index ? "bold-title" : "";
  };

  const getDescriptionClass = (index) => {
    return current === index ? "bold-description" : "";
  };

  // const printName = () => {
  //   console.log(templateName, "TEMPLATE NAME ON CLOSE");
  // }

  // console.log(fileInputRef, "SAVE TEMPLATE CLICKED FROM T MODAL");

  return (
    <>
      <style>
        {`
          .bold-title {
            font-weight: 600;
          }
          .bold-description {
            font-weight: 400;
          }
        `}
      </style>

      <Modal
        className="create-template-modal"
        maskClosable={false}
        width={600}
        // onCancel={() => props.setModal(false)}
        onCancel={() => {
          // printName();
          cleanup();
        }}
        centered
        open={modal}
        footer={null}
      >
        {/* <Spin spinning={isSpinning} tip="Uploading..."> */}
        <div className="text-lg font-bold text-black text-center mb-8">
          Start Creating 
        </div>
        {/* <Steps progressDot current={current} items={items} /> */}
        <Steps
          progressDot
          current={current}
          items={steps.map((item, index) => ({
            key: item.title,
            title: <span className={getTitleClass(index)}>{item.title}</span>,
            description: (
              <span className={getDescriptionClass(index)}>
                {item.description}
              </span>
            ),
          }))}
        />
        <div style={contentStyle}>
          {steps[current].content}
          {/* {React.cloneElement(steps[current].content, { videoRef })} */}
        </div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {/* {current < steps.length - 1 && !loading && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )} */}
          {/* {current === steps.length - 1 && (
            <Button
              type="primary"
            //   onClick={() => message.success("Processing complete!")}
              onClick={() => props.setModal(false)}
            >
              Done
            </Button>
          )} */}
          {/* {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )} */}
        </div>
        {/* </Spin> */}
        {isSpinning && (
          <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
            <Spin tip="Uploading..." />
          </div>
        )}
      </Modal>
    </>
  );
};

export default CreateTemplateModal;
