import React from "react";
import { profileSidebarData } from "../data";
import { NavLink } from "react-router-dom";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// Initialize Swiper core
SwiperCore.use([Navigation]);

const ProfileSidebar = () => {
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  return (
    <>
      {isDesktop && (
        <div className="w-[152px] mr-[108px]">
          <div>
            <ul className="list-none w-max pl-0">
              {profileSidebarData.map((item, index) => {
                return (
                  <li className="text-sm mb-6" key={index}>
                    <NavLink
                      to={item.path}
                      className={({ isActive }) =>
                        [
                          "no-underline hover:text-[#1C1C1D] hover:font-medium",
                          isActive
                            ? "text-[#1C1C1D] font-semibold"
                            : "text-[#3C3C3C]",
                        ].join(" ")
                      }
                    >
                      <span>{item.title}</span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="mb-7">
          <Swiper
            style={{
              "--swiper-navigation-color": "#D3D3D3",
              "--swiper-navigation-size": "15px",
              // "--swiper--button-disabled-display": "none",
              // width: "660px",
              height: "47px",
              paddingTop: "13px",
            }}
            className="textSlider"
            modules={[Navigation]}
            spaceBetween={15}
            // loop={true}
            slidesPerView={"auto"}
            navigation
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
          >
            {profileSidebarData.map((item, index) => (
              <SwiperSlide key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    [
                      "hover:text-[#1C1C1D] hover:font-medium",
                      isActive
                        ? "text-[#1C1C1D] font-semibold underline underline-offset-[10px] decoration-2 decoration-[#C62134]"
                        : "text-[#3C3C3C]",
                    ].join(" ")
                  }
                >
                  <span>{item.title}</span>
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default ProfileSidebar;
