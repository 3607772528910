const profileSidebarData = [
    {
        id: 1,
        title: "Profile Details",
        path: `${process.env.REACT_APP_BASE_PATH}/profile`,
        description: "User ID"
    },
    {
        id: 2,
        title: "Change Password",
        // path: "change-password"
        path: `${process.env.REACT_APP_BASE_PATH}/change-password`,
        description: "Always try to set a strong password."
    },
    {
      id: 3,
      title: "Submit Request",
      // path: "change-password"
      path: `${process.env.REACT_APP_BASE_PATH}/submit-request`,
      description: "Have any queries or concerns? We have got your back."
  },
    // {
    //     id: 3,
    //     title: "Billing and Plans",
    //     // path: "billing-plans",
    //     path: `${process.env.REACT_APP_BASE_PATH}/billing-plans`
    // },
    // {
    //     id: 4,
    //     title: "Purchased History",
    //     // path: "purchased-history",
    //     path: `${process.env.REACT_APP_BASE_PATH}/purchased-history`
    // },
    // {
    //     id: 5,
    //     title: "Teammates",
    //     // path: "teammates"
    //     path: `${process.env.REACT_APP_BASE_PATH}/teammates`
    // },
    // {
    //     id: 6,
    //     title: "Subscription",
    //     path: `${process.env.REACT_APP_BASE_PATH}/subscription`,
    //     description: "You can view your ongoing subscription plan and upgrade whenever you want to."
    // },
    {
        id: 7,
        title: "Support & Feedback",
        // path: "support"
        path: `${process.env.REACT_APP_BASE_PATH}/support`,
        description: "We welcome your feedback! Your input is highly valuable to us."
    },
];
const industryName = [
  {
    value: "Insurance",
    label: "Insurance",
  },
  {
    value: "Banking",
    label: "Banking",
  },
  {
    value: "Hospital",
    label: "Hospital",
  },
];
const roleIndustry = [
  {
    value: "Product Manager",
    label: "Product Manager",
  },
  {
    value: "Creative Head",
    label: "Creative Head",
  },
  {
    value: "Marketing Manager",
    label: "Marketing Manager",
  },
  {
    value: "Project Manager",
    label: "Project Manager",
  },
  {
    value: "Service Delivery",
    label: "Service Delivery",
  },
];

const concernData = [
  {
    value: "Credit Related",
    label: "Credit Related",
  },
  {
    value: "Account Related",
    label: "Account Related",
  },
  {
    value: "Plan Related",
    label: "Plan Related",
  },
  {
    value: "Others",
    label: "Others",
  },
];

export let membersData = [
  {
    name : "Arun Jain",
    email : "arun@vspagy.com",
    date : "May 12,2023",
    credits  : 500,
    member  : true
  },
  {
    name : "Sunny",
    email : "sunny@vspagy.com",
    date : "May 12,2023",
    // credits  : 500,
    member  : false
  },
  {
    name : "Sarab",
    email : "sarab@vspagy.com",
    date : "May 12,2023",
    credits  : 400,
    member  : true
  },
  {
    name : "Rajesh",
    email : "rajesh@vspagy.com",
    date : "May 12,2023",
    // credits  : 500,
    member  : false
  }
]


export { profileSidebarData, roleIndustry, industryName, concernData };

