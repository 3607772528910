import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "./VideoJsPrev.css";
import "video.js/dist/video-js.css";
import "animate.css";
import ReplayIcon from "./assets/replay.svg";
import Warning from "./assets/warning.svg";
import { ReactComponent as Cross } from "./assets/cross.svg";
import Info from "./Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import * as animationData from "../saasWebsite/assests/VspagyLoader2.json";
import idCreate from "../plEditor/utils/uuid";

export const VideoJSPrev = (props) => {
  const pvideoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  // const { options, onReady} = props;
  const [currentTime, setCurrentTime] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const [playerSeeked, setPlayerSeeked] = useState(0);
  const [actionPerform, setActionPerform] = useState(false);
  const [timer, setTimer] = useState(0);
  const [responseTimer, setResponseTimer] = useState(null);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [domCheck, setDomCheck] = useState(false);
  const [call, setCall] = useState([]);
  const bgMusic = useRef(null);
  const runAfterUpdate = useRunAfterUpdate();
  // let count = 0;
  // let clicked = props.autoplay?"play":"pause";
  //const [prevTime,setPrevTime] = useState(-1);
  const system = useRef(false);
  const closeModal = () => {
    setDisplayMessage(false);
  };

  useEffect(() => {
    // console.log(props.videodata);
    let seeked = false;
    let prevTime = -1;
    let options = {
      autoplay: props.autoplay,
      controls: true,
      muted: props.autoplay ? true : false,
      responsive: true,
      preload: "auto",
      fluid: true,
      playsinline: true,
      crossOrigin: "anonymous",
      preferFullWindow: true,

      controlBar: {
        fullscreenToggle: false,
        pictureInPictureToggle: false,
        currentTime: true,
        subsCapsButton: true,
        volumePanel: {
          inline: true,
          vertical: true,
        },
      },
      userActions: {
        click: false,
        doubleClick: false,
      },
      hotkeys: {
        muteKey: function (event) {},
        playPauseKey: function (event) {},
        fullscreenKey: function (event) {
          return event.which === 86;
        },
      },

      sources: [
        {
          src: `${props.videodata}${
            props.videodata.includes("?") ? "&" : "?"
          }t2=${props.id}`,
          type: "video/mp4",
        },
      ],
      //poster : props.thumbnail
    };
    // console.log("load", props.viData);
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");

      pvideoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");

        // onReady && onReady(player);
        playerRef.current = player;

        // var fullScreenButton = player
        //   .getChild("ControlBar")
        //   .addChild("button", {
        //     clickHandler: function () {
        //       props.toggleFullScreen();
        //     },
        //   });
        // let buttonDom = fullScreenButton.el();
        // buttonDom.id = "custom_full_screen_button";
        // buttonDom.innerHTML =
        //   '<span class="vjs-icon-placeholder"  style="font-family: VideoJS;font-weight: normal;cursor: pointer;font-style: normal;font-size: 12px;"><i class="fa-solid fa-expand"></i></span>';

        // You can handle player events here, for example:

        player
          .getChild("ControlBar")
          .getChild("remainingTimeDisplay")
          .el()
          .classList.toggle("hide");

        if (props.autoplay) {
          player.removeChild("BigPlayButton");
        }

        // player.controlBar.playToggle
        //   .el()
        //   .addEventListener("click", function () {
        //     console.log("ABC", player.controlBar.playToggle.el().classList[3]);
        //     if (
        //       player.controlBar.playToggle.el().classList[3] === "vjs-playing"
        //     ) {
        //       clicked = "pause";
        //     } else {
        //       clicked = "play";
        //     }
        //   });

        player.on("play", () => {
          videojs.log("Play State");
          setShowReplayButton(false);
          // videoElement.click();
          videojs.log("After");
          // if (bgMusic !== null && bgMusic.current !== null) {
          //   bgMusic.current.play();
          // }
          // console.log("BG", bgMusic.current);
          // console.log("PPP", count, clicked);
        });
        player.on("pause", () => {
          videojs.log("Pause State");
          // if(bgMusic!==undefined && bgMusic.current!==null && clicked==="pause"){
          //   bgMusic.current.pause();
          // }
          console.log("BG", bgMusic.current);
        });
        player.on("loadedmetadata", () => {
          //player.play();
          props.setEditorLoader(false);
          setVideoHeight(pvideoRef.current.scrollHeight);
          setVideoWidth(pvideoRef.current.scrollWidth);
        });
        player.on("waiting", () => {
          videojs.log("player is waiting");
          videojs.log(player.videoHeight(), player.videoWidth());
        });

        player.on("dispose", () => {
          videojs.log("player will dispose");
        });
      }));
      player.on("seeked", () => {
        setPlayerSeeked(player.currentTime());
        setShowReplayButton(false);
      });
      player.on("timeupdate", () => {
        const player = playerRef.current;
        setCurrentTime(player.currentTime());
        // props.postMessage(player.currentTime(), "*");

        if (bgMusic !== null && bgMusic.current !== null) {
          if (player.paused() && system.current === false) {
            bgMusic.current.pause();
          } else {
            bgMusic.current
              .play()
              .then(() => {
                // Audio playback successful
              })
              .catch((error) => {
                console.log("Error playing Bgaudio:", error.message);
              });
            if (player.muted()) {
              bgMusic.current.muted = true;
            } else {
              bgMusic.current.muted = false;
            }
          }
        }
        var audioes = document.querySelectorAll(".inAudioP");
        for (let i = 0; i < audioes.length; i++) {
          let exit = audioes[i].attributes.etime.nodeValue;
          let entry = audioes[i].attributes.stime.nodeValue;
          console.log(exit, entry, player.currentTime());

          if (player.currentTime() >= entry && player.currentTime() <= exit) {
            if (player.paused() && system.current === false) {
              audioes[i].pause();
            } else {
              audioes[i]
                .play()
                .then(() => {
                  // Audio playback successful
                })
                .catch((error) => {
                  console.log("Error playing audio:", error.message);
                });
              if (player.muted()) {
                audioes[i].muted = true;
              } else {
                audioes[i].muted = false;
              }
            }
          } else {
            audioes[i].currentTime = 0;
            audioes[i].pause();
          }
        }
        var videoes = document.querySelectorAll(".inVideoP");
        for (let i = 0; i < videoes.length; i++) {
          let exit = videoes[i].attributes.etime.nodeValue;
          let entry = videoes[i].attributes.stime.nodeValue;
          console.log(exit, entry, player.currentTime());
          if (player.currentTime() >= entry && player.currentTime() <= exit) {
            if (player.paused() && system.current === false) {
              videoes[i].pause();
            } else {
              try {
                videoes[i].play();
                if (player.muted()) {
                  videoes[i].muted = true;
                } else {
                  videoes[i].muted = false;
                }
              } catch {}
            }
          } else {
            videoes[i].currentTime = 0;
            videoes[i].pause();
          }
        }
        if (
          Math.floor(player.currentTime()) % 10 === 0 &&
          Math.floor(player.currentTime()) !== Math.floor(prevTime)
        ) {
          console.log("Hit", prevTime);
          setCall([
            "VIDEOPLAYDURATION",
            Math.round(player.currentTime()),
            "duration",
          ]);
          prevTime = player.currentTime();
        }
      });
      player.on("volumechange", () => {
        if (bgMusic !== null && bgMusic.current !== null) {
          bgMusic.current.volume = player.volume();
          if (player.muted()) {
            bgMusic.current.muted = true;
          } else {
            bgMusic.current.muted = false;
          }
        }
        var videoes = document.querySelectorAll(".inVideoP");
        for (let i = 0; i < videoes.length; i++) {
          videoes[i].volume = player.volume();
          if (player.muted()) {
            videoes[i].muted = true;
          } else {
            videoes[i].muted = false;
          }
        }
        var audioes = document.querySelectorAll(".inAudioP");
        for (let i = 0; i < audioes.length; i++) {
          audioes[i].volume = player.volume();
          if (player.muted()) {
            audioes[i].muted = true;
          } else {
            audioes[i].muted = false;
          }
        }
      });

      player.on("userinactive", () => {});

      player.on("replay", () => {
        if (bgMusic !== null && bgMusic.current !== null) {
          bgMusic.current.currentTime = 0;
          // props.bgMusic.current.play();
        }
      });
      player.on("ended", () => {
        setShowReplayButton(true);
        if (bgMusic !== null && bgMusic.current !== null) {
          bgMusic.current.pause();
        }
        var audioes = document.querySelectorAll(".inAudioP");
        for (let i = 0; i < audioes.length; i++) {
          audioes[i].currentTime = 0;
          audioes[i].pause();
        }
        var videoes = document.querySelectorAll(".inVideoP");
        for (let i = 0; i < videoes.length; i++) {
          videoes[i].currentTime = 0;
          videoes[i].pause();
        }

        prevTime = 10000;
      });

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      // console.log(player);
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [pvideoRef]);

  function getInputSelection(el) {
    var start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;

    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");

        // Create a working TextRange that lives only in the input
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());

        // Check if the start and end of the selection are at the very end
        // of the input, since moveStart/moveEnd doesn't return what we want
        // in those cases
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end,
    };
  }

  function handlePause() {
    const player = playerRef.current;
    if (
      props.currIndex < props.pauseData.length &&
      Math.floor(player.currentTime()) ===
        Math.floor(props.pauseData[props.currIndex].pausetime) &&
      Math.ceil(player.currentTime()) ===
        Math.ceil(props.pauseData[props.currIndex].pausetime)
    ) {
      system.current = true;
      player.pause();
      setTimer(props.pauseData[props.currIndex].resumein);
      console.log("Before", props.currIndex);
      props.setCurrIndex(props.currIndex + 1);
      player.controlBar.playToggle.disable();
      player.controlBar.progressControl.disable();
    }
  }
  const handleReplay = () => {
    const player = playerRef.current;
    setShowReplayButton(false);
    player.currentTime(0);
    system.current = false;
    player.play();
  };

  function storeValues(obj) {
    let layers = props.layerContent.filter((layer) => {
      if (
        obj.actionsarray[1] === 0 &&
        ((Math.round(layer.starttime) >= currentTime - 1 &&
          Math.round(layer.stoptime) <= currentTime + 1) ||
          (Math.round(layer.starttime) <= currentTime - 1 &&
            Math.round(layer.stoptime) >= currentTime - 1) ||
          (Math.round(layer.starttime) <= currentTime + 1 &&
            Math.round(layer.stoptime) >= currentTime + 1) ||
          (Math.round(layer.starttime) < currentTime - 1 &&
            Math.round(layer.stoptime) > currentTime + 1))
      ) {
        return true;
      } else if (
        obj.actionsarray[1] === 1 &&
        ((Math.round(layer.starttime) >= 0 &&
          Math.round(layer.stoptime) <= currentTime + 1) ||
          Math.round(layer.starttime) < currentTime + 1)
      ) {
        return true;
      }
      return false;
    });
    console.log(layers);
    let arr = [];
    for (let i = 0; i < layers.length; i++) {
      if (
        layers[i].type === "text" ||
        layers[i].type === "textarea" ||
        layers[i].type === "dropdown"
      ) {
        console.log(layers[i].value);
        arr.push({ [layers[i].layername]: layers[i].value });
      } else if (layers[i].type === "radio" || layers[i].type === "checkbox") {
        if (layers[i].defaultchecked) {
          console.log(layers[i].value);
          if (layers[i].type === "radio") {
            arr.push({ [layers[i].name]: layers[i].value });
          } else {
            arr.push({ [layers[i].layername]: layers[i].value });
          }
        }
      }
    }
  }

  function actionHandler(obj) {
    const player = playerRef.current;
    if (obj.action === 0) {
      setActionPerform(true);
    } else if (obj.action === 1) {
      if (obj.actionsarray[0] !== undefined) {
        var createlink = window.parent.document.createElement("a");
        createlink.target = obj.actionsarray[1] === 0 ? "_self" : "_blank";
        createlink.href = obj.actionsarray[0];
        console.log(createlink);
        createlink.click();
        createlink.remove();
      }
    } else if (obj.action === 2) {
      if (obj.actionsarray[0] !== null) {
        player.currentTime(obj.actionsarray[0]);
      } else {
        system.current = false;
        player.play();
        player.controlBar.playToggle.enable();
        player.controlBar.progressControl.enable();
      }
    } else if (obj.action === 3) {
      if (validateInputs(obj)) {
        storeValues(obj);
        if (obj.actionsarray[0] !== null) {
          player.currentTime(obj.actionsarray[0]);
        } else {
          system.current = false;
          player.play();
          player.controlBar.playToggle.enable();
          player.controlBar.progressControl.enable();
        }
      }
    } else if (obj.action === 4) {
      if (validateInputs(obj)) {
        storeValues(obj);
        // player.currentTime(obj.actionsarray[0]);
        if (obj.actionsarray[2] !== null) {
          setDisplayMessage(true);
          setErrorMessage(obj.actionsarray[2]);
          setResponseTimer(
            obj.actionsarray[0] === null ? 0 : obj.actionsarray[0]
          );
        } else {
          system.current = false;
          player.play();
          player.controlBar.playToggle.enable();
          player.controlBar.progressControl.enable();
        }
      }
    } else if (obj.action === 5) {
      if (obj.actionsarray[0] !== "" || obj.actionsarray[0] !== undefined) {
        var createlink = window.parent.document.createElement("a");
        createlink.target = "_self";
        createlink.href = "tel:" + obj.actionsarray[0];
        console.log(createlink);
        createlink.click();
        createlink.remove();
      }
    }
  }
  function handleCurrency(value, index, currIndex) {
    if (value.length === 3) {
      if (value.length < props.layerContent[index].prevLength) {
        console.log(currIndex);
        if (currIndex !== 0 && currIndex !== value.length + 1) {
          currIndex -= 1;
        }
      }
    }
    if (value.length > 3 && value.length % 2 === 0) {
      let i = 1;
      let j = 0;
      console.log(currIndex);
      while (i <= value.length - 3) {
        value = value.substring(0, i) + "," + value.substring(i);
        if (i + 1 <= currIndex) {
          j = i + 1;
        }
        // if (
        //   value.length > props.layerContent[index].prevLength &&
        //   currIndex + 1 >= value.length
        // ) {
        //   currIndex += 1;
        // }

        i += 3;
      }
      if (
        value.length > props.layerContent[index].prevLength &&
        value.split(",").length - 1 >= props.layerContent[index].commas
      ) {
        if (j !== 0 && currIndex >= j) {
          currIndex += 1;
        }
      }
    } else if (value.length > 3 && value.length % 2 !== 0) {
      let i = 2;
      console.log(currIndex);
      while (i <= value.length - 2) {
        value = value.substring(0, i) + "," + value.substring(i);

        // if (
        //   value.length > props.layerContent[index].prevLength &&
        //   currIndex + 1 >= value.length
        // ) {
        //   currIndex += 1;
        // }

        i += 3;
      }
      if (value.length < props.layerContent[index].prevLength) {
        if (currIndex !== 0 && currIndex !== value.length) {
          currIndex -= 1;
        }
      }
    }
    return { value, currIndex };
  }
  function handleGlobalCurrency(value, index, currIndex) {
    if (value.length === 3) {
      if (value.length < props.layerContent[index].prevLength) {
        if (currIndex !== 0 && currIndex !== value.length) {
          currIndex -= 1;
        }
      }
    }

    if (value.length > 3) {
      if (value.length % 3 === 0) {
        let i = 3;
        while (i < value.length) {
          value = value.substring(0, i) + "," + value.substring(i);
          // console.log(value.length,props.layerContent[index].prevLength,currIndex);
          // console.log("commaIndex",i);
          // console.log("Check --->",currIndex,value.length)
          i += 4;
        }
        if (value.length < props.layerContent[index].prevLength) {
          if (currIndex !== 0 && currIndex !== value.length) {
            currIndex -= 1;
          }
        }
      } else if (value.length % 3 === 1) {
        let i = 1;
        let j = 0;
        while (i <= value.length - 3) {
          value = value.substring(0, i) + "," + value.substring(i);
          if (i + 1 <= currIndex) {
            j = i + 1;
          }
          // console.log("commaIndex",i);
          // console.log("Check --->",currIndex,value.length)
          i += 4;
        }
        console.log(
          value.split(",").length - 1,
          props.layerContent[index].commas
        );
        if (
          value.length > props.layerContent[index].prevLength &&
          value.split(",").length - 1 >= props.layerContent[index].commas
        ) {
          if (j !== 0 && currIndex >= j) {
            currIndex += 1;
          }
        }
      } else if (value.length % 3 === 2) {
        let i = 2;
        while (i <= value.length - 3) {
          value = value.substring(0, i) + "," + value.substring(i);
          // console.log("commaIndex",i);
          // console.log("Check --->",currIndex,value.length)
          i += 4;
        }
      }
    }
    // console.log(value);
    return { value, currIndex };
  }
  function handleInput(e, subtype, index, characterlimit) {
    const inputValue = e.target.value;
    if (subtype === "email") {
      props.layerContent[index].value = inputValue;
      props.setLayerContent([...props.layerContent]);
    } else if (subtype === "text" || subtype === "password") {
      const alphaValue = inputValue.substring(0, characterlimit);
      props.layerContent[index].value = alphaValue;
      props.setLayerContent([...props.layerContent]);
    } else if (subtype === "alphabet") {
      const alphaValue = inputValue
        .replace(/[^a-zA-Z]/g, "")
        .substring(0, characterlimit);
      props.layerContent[index].value = alphaValue;
      props.setLayerContent([...props.layerContent]);
    } else if (subtype === "number" || subtype === "tel") {
      // Match only numbers
      const numericValue = inputValue
        .replace(/[^0-9]/g, "")
        .substring(0, characterlimit);
      props.layerContent[index].value = numericValue;
      props.setLayerContent([...props.layerContent]);
    } else if (subtype === "alphanumeric") {
      const alphaNumericValue = inputValue
        .replace(/[^a-zA-Z0-9]/g, "")
        .substring(0, characterlimit);
      props.layerContent[index].value = alphaNumericValue;
      props.setLayerContent([...props.layerContent]);
    } else if (subtype === "date") {
      let numericValue = inputValue.replace(/[^0-9]/g, "");
      if (numericValue.length > 8) {
        return;
      }
      let val = e.target.selectionStart;
      if (numericValue.length > 6) {
        numericValue =
          numericValue.substring(0, 4) + "-" + numericValue.substring(4);
        // if (
        //   numericValue.length > props.layerContent[index].prevLength &&
        //   val + 1 >= numericValue.length
        // ) {
        //   val += 1;
        // }
        numericValue =
          numericValue.substring(0, 7) + "-" + numericValue.substring(7);
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          (val === 5 || val === 8)
        ) {
          val += 1;
        }
      } else if (numericValue.length > 4) {
        numericValue =
          numericValue.substring(0, 4) + "-" + numericValue.substring(4);
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          val + 1 >= numericValue.length
        ) {
          val += 1;
        }
      }
      props.layerContent[index].value = numericValue;
      props.layerContent[index].prevLength = numericValue.length;
      props.setLayerContent([...props.layerContent]);
      // console.log(e.target.selectionStart);

      runAfterUpdate(() => {
        e.target.selectionStart = val;
        e.target.selectionEnd = val;
      });
      // e.target.setSelectionRange(e.target.selectionStart,e.target.selectionStart);
    } else if (subtype === "time") {
      let numericValue = inputValue.replace(/[^0-9]/g, "");
      if (numericValue.length > 4) {
        return;
      }
      let val = e.target.selectionStart;
      if (numericValue.length > 2) {
        numericValue =
          numericValue.substring(0, 2) + ":" + numericValue.substring(2);

        if (
          numericValue.length > props.layerContent[index].prevLength &&
          val === 3
        ) {
          val += 1;
        }
      }
      props.layerContent[index].value = numericValue;
      props.layerContent[index].prevLength = numericValue.length;
      props.setLayerContent([...props.layerContent]);
      runAfterUpdate(() => {
        e.target.selectionStart = val;
        e.target.selectionEnd = val;
      });
    } else if (subtype === "datetime") {
      let numericValue = inputValue.replace(/[^0-9]/g, "");
      console.log(numericValue.length);
      if (numericValue.length > 12) {
        return;
      }
      let val = e.target.selectionStart;
      if (numericValue.length > 10) {
        numericValue =
          numericValue.substring(0, 4) + "-" + numericValue.substring(4);
        numericValue =
          numericValue.substring(0, 7) + "-" + numericValue.substring(7);

        numericValue =
          numericValue.substring(0, 10) + " " + numericValue.substring(10);
        numericValue =
          numericValue.substring(0, 13) + ":" + numericValue.substring(13);
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          (val === 5 || val === 8)
        ) {
          val += 1;
        }
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          val === 11
        ) {
          val += 1;
        }
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          val === 14
        ) {
          val += 1;
        }
      } else if (numericValue.length > 8) {
        numericValue =
          numericValue.substring(0, 4) + "-" + numericValue.substring(4);

        numericValue =
          numericValue.substring(0, 7) + "-" + numericValue.substring(7);
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          (val === 5 || val === 8)
        ) {
          val += 1;
        }
        numericValue =
          numericValue.substring(0, 10) + " " + numericValue.substring(10);
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          val === 11
        ) {
          val += 1;
        }
      } else if (numericValue.length > 6) {
        numericValue =
          numericValue.substring(0, 4) + "-" + numericValue.substring(4);

        numericValue =
          numericValue.substring(0, 7) + "-" + numericValue.substring(7);

        if (
          numericValue.length > props.layerContent[index].prevLength &&
          (val === 5 || val === 8)
        ) {
          val += 1;
        }
      } else if (numericValue.length > 4) {
        numericValue =
          numericValue.substring(0, 4) + "-" + numericValue.substring(4);
        if (
          numericValue.length > props.layerContent[index].prevLength &&
          val + 1 >= numericValue.length
        ) {
          val += 1;
        }
      }
      props.layerContent[index].value = numericValue;
      props.layerContent[index].prevLength = numericValue.length;
      props.setLayerContent([...props.layerContent]);

      runAfterUpdate(() => {
        e.target.selectionStart = val;
        e.target.selectionEnd = val;
      });
    } else if (subtype === "currency") {
      let value = inputValue.replace(/[^0-9]/g, "");

      let currIndex = e.target.selectionStart;
      // let obj = handleGlobalCurrency(value,index,currIndex);
      let obj = handleCurrency(value, index, currIndex);
      props.layerContent[index].value = obj.value;
      props.layerContent[index].prevLength = obj.value.length;
      props.layerContent[index].commas = obj.value.split.length - 1;
      // console.log("Commas",obj.value.split(",").length -1);
      props.setLayerContent([...props.layerContent]);
      console.log(obj.currIndex);
      runAfterUpdate(() => {
        e.target.selectionStart = obj.currIndex;
        e.target.selectionEnd = obj.currIndex;
      });
    }
  }

  function validateInputs() {
    let layers = props.layerContent.filter((layer) => {
      console.log(
        currentTime,
        Math.round(layer.starttime),
        Math.round(layer.stoptime),
        currentTime - 1,
        currentTime + 1
      );
      if (
        (Math.round(layer.starttime) >= currentTime - 1 &&
          Math.round(layer.stoptime) <= currentTime + 1) ||
        (Math.round(layer.starttime) <= currentTime - 1 &&
          Math.round(layer.stoptime) >= currentTime - 1) ||
        (Math.round(layer.starttime) <= currentTime + 1 &&
          Math.round(layer.stoptime) >= currentTime + 1) ||
        (Math.round(layer.starttime) < currentTime - 1 &&
          Math.round(layer.stoptime) > currentTime + 1)
      ) {
        return true;
      }
      return false;
    });
    console.log(layers);
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === "text") {
        if (layers[i].emtoggle === true) {
          if (layers[i].value === "") {
            setErrorMessage(layers[i].errormessage);
            setDisplayMessage(true);
            return false;
          } else if (layers[i].subtype === "email") {
            const emailPattern =
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(layers[i].value)) {
              setErrorMessage("Invalid email address");
              setDisplayMessage(true);
              return false;
            }
          } else if (layers[i].subtype === "date") {
            if (layers[i].value.length < 10) {
              setErrorMessage("Date is Incomplete");
              setDisplayMessage(true);
              return false;
            }
            const date = new Date(
              layers[i].value.substring(0, 4),
              layers[i].value.substring(5, 7) - 1,
              layers[i].value.substring(8, 10)
            );

            if (date.getDate() !== Number(layers[i].value.substring(8, 10))) {
              setErrorMessage("Invalid Date");
              setDisplayMessage(true);
              return false;
            } else if (
              date.getMonth() !==
              layers[i].value.substring(5, 7) - 1
            ) {
              console.log(date.getMonth(), layers[i].value.substring(5, 7) - 1);
              setErrorMessage("Invalid Month");
              setDisplayMessage(true);
              return false;
            } else if (
              date.getFullYear() !== Number(layers[i].value.substring(0, 4))
            ) {
              console.log(date.getFullYear(), layers[i].value.substring(0, 4));
              setErrorMessage("Invalid Year");
              setDisplayMessage(true);
              return false;
            }
          } else if (layers[i].subtype === "time") {
            if (layers[i].value.length < 5) {
              setErrorMessage("Time is Incomplete");
              setDisplayMessage(true);
              return false;
            } else if (layers[i].value.substring(0, 2) > 23) {
              setErrorMessage("Invalid Hours");
              setDisplayMessage(true);
              return false;
            } else if (layers[i].value.substring(3, 5) > 59) {
              setErrorMessage("Invalid Minutes");
              setDisplayMessage(true);
              return false;
            }
          } else if (layers[i].subtype === "datetime") {
            if (layers[i].value.length < 16) {
              setErrorMessage("Date-Time is Incomplete");
              setDisplayMessage(true);
              return false;
            }
            const date = new Date(
              layers[i].value.substring(0, 4),
              layers[i].value.substring(5, 7) - 1,
              layers[i].value.substring(8, 10)
            );

            if (date.getDate() !== Number(layers[i].value.substring(8, 10))) {
              setErrorMessage("Invalid Date");
              setDisplayMessage(true);
              return false;
            } else if (
              date.getMonth() !==
              layers[i].value.substring(5, 7) - 1
            ) {
              console.log(date.getMonth(), layers[i].value.substring(5, 7) - 1);
              setErrorMessage("Invalid Month");
              setDisplayMessage(true);
              return false;
            } else if (
              date.getFullYear() !== Number(layers[i].value.substring(0, 4))
            ) {
              console.log(date.getFullYear(), layers[i].value.substring(0, 4));
              setErrorMessage("Invalid Year");
              setDisplayMessage(true);
              return false;
            } else if (layers[i].value.substring(11, 13) > 23) {
              setErrorMessage("Invalid Hours");
              setDisplayMessage(true);
              return false;
            } else if (layers[i].value.substring(14, 16) > 59) {
              setErrorMessage("Invalid Minutes");
              setDisplayMessage(true);
              return false;
            }
          }
        }
      } else if (layers[i].type === "dropdown") {
        if (layers[i].emtoggle === true) {
          if (layers[i].value === "" || Number(layers[i].value) === -1) {
            setErrorMessage(layers[i].errormessage);
            setDisplayMessage(true);
            return false;
          }
        }
      } else if (layers[i].type === "radio" || layers[i].type === "checkbox") {
        console.log(layers[i].type);
        if (layers[i].emtoggle === true && layers[i].defaultchecked === false) {
          let check = false;
          let name = layers[i].name;
          for (let j = 0; j < layers.length; j++) {
            if (
              j !== i &&
              layers[j].name === name &&
              layers[j].defaultchecked === true
            ) {
              check = true;
              break;
            }
          }
          console.log("Check", check);
          if (!check) {
            setErrorMessage(layers[i].errormessage);
            setDisplayMessage(true);
            return false;
          }
        }
      }
    }
    return true;
  }

  useEffect(() => {
    const player = playerRef.current;
    // console.log(player.getChild("ControlBar").getChild("progressControl").el());
    if (!props.timeline)
      player
        .getChild("ControlBar")
        .getChild("progressControl")
        .el()
        .classList.toggle("showTimeline");
  }, [props.timeline]);

  useEffect(() => {
    const player = playerRef.current;
    player.on("timeupdate", handlePause);
    /*player
      .getChild("ControlBar")
      .getChild("currentTimeDisplay")
      .el()
      .classList.toggle("show");

    player
      .getChild("ControlBar")
      .getChild("durationDisplay")
      .el()
      .classList.toggle("show");

    player
      .getChild("ControlBar")
      .getChild("timeDivider")
      .el()
      .classList.toggle("show");*/
    return () => {
      player.off("timeupdate", handlePause);
    };
  }, [props.timeduration, props.pauseData, props.currIndex]);

  useEffect(() => {
    const player = playerRef.current;
    for (let i = 0; i < props.pauseData.length; i++) {
      if (props.pauseData[i].pausetime >= playerSeeked) {
        props.setCurrIndex(i);
        break;
      }
    }
    setTimer(null);
    setDisplayMessage(false);
    system.current = false;
    player.play();
    player.controlBar.playToggle.enable();
    player.controlBar.progressControl.enable();
  }, [playerSeeked]);

  useEffect(() => {
    if (timer === null) {
      return;
    }
    // console.log("Wanted -----------------------------> ",timer);
    const player = playerRef.current;
    const timeout = setTimeout(() => {
      system.current = false;
      player.play();
      player.controlBar.playToggle.enable();
      player.controlBar.progressControl.enable();
      console.log("After", props.currIndex);
      setDisplayMessage(false);
      player.currentTime(props.pauseData[props.currIndex - 1]?.resumeto);
      setTimer(null);
    }, timer * 1000);
    const intervalId = setInterval(() => {
      setTimer((prevTime) => prevTime - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
      clearInterval(intervalId);
    };
  }, [timer]);

  useEffect(() => {
    if (responseTimer === null) {
      return;
    }
    console.log("Wanted -----------------------------> ", timer);
    const player = playerRef.current;
    const timeout = setTimeout(() => {
      system.current = false;
      player.play();
      player.controlBar.playToggle.enable();
      player.controlBar.progressControl.enable();
      console.log("After", props.currIndex);
      setDisplayMessage(false);
      setResponseTimer(null);
    }, responseTimer * 1000);
    // const intervalId = setInterval(() => {
    //   setResponseTimer((prevTime) => prevTime - 1);
    // }, 1000);
    return () => {
      clearTimeout(timeout);
      // clearInterval(intervalId);
    };
  }, [responseTimer]);

  useEffect(() => {
    const player = playerRef.current;
    if (actionPerform) {
      setTimer(null);
      system.current = false;
      player.play();
      player.controlBar.playToggle.enable();
      player.controlBar.progressControl.enable();
    }
    setActionPerform(false);
  }, [actionPerform]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;
    // console.log(playerRef.current.scrollHeight);
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  //   useEffect(() => {
  //     const images = document.querySelectorAll("img.textarea");
  //     const videos = document.querySelectorAll(".inVideoP");
  //     const audios = document.querySelectorAll(".inAudioP");
  //     // const player = playerRef.current;
  //     const totalResources = images.length + videos.length + audios.length;
  //     console.log("DATA", totalResources);
  //     console.log("asset", images, videos);
  //     let resourcesLoadedCount = 0;
  //     let cachedResourcesCount = 0;
  //     const resourceLoadHandler = () => {
  //       resourcesLoadedCount++;
  //       console.log("asset", domCheck);
  //       console.log("DATA", cachedResourcesCount, resourcesLoadedCount);
  //       if (resourcesLoadedCount + cachedResourcesCount >= totalResources - 1) {
  //         console.log("asset", domCheck);
  //         //clearTimeout(timer);
  //         setDomCheck(true);
  //         //player.play();
  //       }
  //     };

  //     images.forEach((image) => {
  //       if (image.complete) {
  //         // The image is cached
  //         cachedResourcesCount++;
  //         console.log("DATA", cachedResourcesCount, resourcesLoadedCount);
  //       } else {
  //         image.addEventListener("load", resourceLoadHandler);
  //       }
  //     });

  //     videos.forEach((video) => {
  //       if (video.readyState === 4) {
  //         // The image is cached
  //         cachedResourcesCount++;
  //         console.log("DATA", cachedResourcesCount, resourcesLoadedCount);
  //       } else {
  //         video.addEventListener("loadeddata", resourceLoadHandler);
  //       }
  //     });
  //     audios.forEach((audio) => {
  //       if (audio.readyState === 4) {
  //         // The audio is cached
  //         cachedResourcesCount++;
  //       } else {
  //         // Add 'loadeddata' event listeners to uncached audios
  //         audio.addEventListener("loadeddata", resourceLoadHandler);
  //       }
  //     });

  //     const timer = setTimeout(() => {
  //       setDomCheck(true);
  //     }, 10000);

  //     return () => {
  //       // Remove event listeners to prevent memory leaks
  //       images.forEach((image) => {
  //         image.removeEventListener("load", resourceLoadHandler);
  //       });

  //       videos.forEach((video) => {
  //         video.removeEventListener("loadeddata", resourceLoadHandler);
  //       });

  //       audios.forEach((audio) => {
  //         audio.removeEventListener("loadeddata", resourceLoadHandler);
  //       });

  //       clearTimeout(timer);
  //     };
  //   }, []);

  useEffect(() => {
    let loadedMedia = 0;
    const handleLoadedMedia = () => {
      loadedMedia++;
      if (
        loadedMedia ===
        videoURLs.length + imageURLs.length + audioURLs.length
      ) {
        setDomCheck(true); // Set videosLoaded state to true when all videos are loaded
      }
    };

    const videoURLs = [];
    const imageURLs = [];
    const audioURLs = [];
    const videos = document.querySelectorAll(".textarea.inVideoP");
    const images = document.querySelectorAll("img.textarea");
    const audio = document.querySelectorAll(".inAudioP");
    console.log(images);
    console.log("VV", videos);

    images.forEach((item) => {
      imageURLs.push(item.src);
      console.log("Alert", item, item.src, item.currentSrc);
    });

    videos.forEach((item) => {
      videoURLs.push(item.src);
      console.log(item.src);
    });

    audio.forEach((item) => {
      audioURLs.push(item.src);
      console.log(item.src);
    });

    imageURLs.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = handleLoadedMedia;
    });

    videoURLs.forEach((url) => {
      const video = document.createElement("video");
      video.src = url;
      video.preload = "auto"; // Preload video
      video.setAttribute("data-type", "preload");
      video.style.display = "none";

      video.addEventListener("loadeddata", handleLoadedMedia);

      // Append video to DOM to trigger loading
      document.body.appendChild(video);
    });

    audioURLs.forEach((url) => {
      const audio = new Audio(url);
      audio.preload = "auto";
      audio.addEventListener("canplaythrough", handleLoadedMedia);
      audio.load();
    });

    // Clean up event listeners when component unmounts
    return () => {
      videoURLs.forEach((url) => {
        const video = document.querySelector('[data-type="preload"]');
        console.log("Clean", video, video.parentNode);
        if (video) {
          video.removeEventListener("loadeddata", handleLoadedMedia);
          document.body.removeChild(video);
        }
      });
      audioURLs.forEach((url) => {
        const audio = document.querySelector(`audio[src="${url}"]`);
        if (audio) {
          audio.removeEventListener("canplaythrough", handleLoadedMedia);
          audio.load();
        }
      });
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {/* {!domCheck && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position : 'relative'
          }}
        >
          
        <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position : "absolute"
          }}
        >
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
      
      
        <div style = {{
            width : "100%",
            height : "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 990,
            position : "absolute"
        }}>
           <div
            key={-3}
            className="poster"
            id={-3}
            style={{
              userSelect: "none",
              width: props.aspectvalue === ""
          ? "600px"
          : props.aspectvalue < 1
          ? "300px"
          : props.aspectvalue === 1
          ? "500px"
          : "600px",
          height: props.aspectvalue === ""
          ? "0px"
          : props.aspectvalue < 1
          ? 300 / props.aspectvalue
          : props.aspectvalue === 1
          ? 500 / props.aspectvalue
          : 600 / props.aspectvalue,
              opacity: 1,
              transformStyle: "preserve-3d",
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
              }}
              src={props.thumbnail}
              alt="P"
            />
          </div></div>
        </div>
      )} */}

      <div
        data-vjs-player
        id="video-container"
        onMouseEnter={() => {
          const player = playerRef.current;
          if (player !== null && player.current !== null) {
            player.controlBar.el().style.opacity = 1;
          }
        }}
        onMouseLeave={() => {
          const player = playerRef.current;
          if (player !== null && player.current !== null) {
            if (!player.paused()) player.controlBar.el().style.opacity = 0;
          }
        }}
        style={{
          width: "100%",
          height: "100%",
          // width: props.aspectvalue === ""
          // ? "600px"
          // : props.aspectvalue < 1
          // ? "300px"
          // : props.aspectvalue === 1
          // ? "500px"
          // : "600px",
          // height: props.aspectvalue === ""
          // ? "0px"
          // : props.aspectvalue < 1
          // ? 300 / props.aspectvalue
          // : props.aspectvalue === 1
          // ? 500 / props.aspectvalue
          // : 600 / props.aspectvalue,
          position: "relative",
          overflow: "hidden",
          display: !domCheck ? "none" : "block",
        }}
      >
        <div ref={pvideoRef} className="prevideo-player">
          {showReplayButton && (
            <div className="preplay-button" onClick={handleReplay}>
              <div className="preplay-content">
                <img
                  style={{
                    height: "20px",
                  }}
                  src={ReplayIcon}
                  alt="Replay"
                />
              </div>
            </div>
          )}
          <div
            key={-2}
            className="itemdummy"
            id={-2}
            style={{
              userSelect: "none",
              position: "absolute",
              left: "0px",
              top: "0px",
              width: "100%",
              height: "100%",
              opacity: 0,
              zIndex: 1,
              transformStyle: "preserve-3d",
              display: "block",
            }}
          ></div>
          {!props.autoplay && (
            <div
              key={-3}
              className="poster"
              id={-3}
              style={{
                userSelect: "none",
                position: "absolute",
                left: "0px",
                top: "0px",
                width: "100%",
                height: "100%",
                opacity: 1,
                zIndex: 1000,
                transformStyle: "preserve-3d",
                display: currentTime === 0 ? "block" : "none",
              }}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                }}
                src={props.thumbnail}
                alt="P"
              />
            </div>
          )}
          {/* {props.layerContent.map(function (obj, index) {
            return (
              <div
                key={obj.id}
                className="item"
                id={obj.id}
                style={{
                  position: "absolute",
                  left: obj.left + "px",
                  top: obj.top + "px",
                  width:
                    obj.type === "text"
                      ? obj.subtype === "password"
                        ? (5 * obj.width) / 6 + "px"
                        : obj.width + "px"
                      : obj.width + "px",
                  height: obj.height + "px",
                  // left: (obj.left/props.tempWidth)*videoWidth + "px",
                  // top : (obj.top/props.tempHeight)*videoHeight+ "px",
                  // width: (obj.width/props.tempWidth)*videoWidth + "px",
                  // height: (obj.height/props.tempHeight)*videoHeight + "px",
                  transform: `rotate(${obj.rotate}deg) scale(1.001)`,
                  opacity: obj.opacity,
                  zIndex: obj?.zindex,
                  // zIndex : currentTime >= obj.starttime && currentTime <= obj.stoptime?obj.zindex:-100,
                  border:
                    obj.border === "1px dashed blue" ? obj.border : "none",

                  transformStyle: "preserve-3d",
                  // animation: `${obj.lastanimation}`,
                  animation:
                    currentTime > obj.starttime &&
                    currentTime < (obj.starttime + obj.stoptime) / 2
                      ? obj.entryanimation + " " + obj.animationentryspeed
                      : currentTime > obj.stoptime - 0.5 &&
                        currentTime < obj.stoptime
                      ? obj.exitanimation + " " + obj.animationexitspeed
                      : "",
                  // display: "flex",
                  display:
                    currentTime >= obj.starttime && currentTime <= obj.stoptime
                      ? "flex"
                      : "none",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {obj.type === "label" && (
                  <label
                    className="textarea "
                    placeholder={obj?.placeholder}
                    style={{
                      whiteSpace:
                        obj?.subtype !== "multiline" ? "pre" : "pre-wrap",
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      // display : "flex",
                      // visibility : "hidden",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      // fontSize:  Math.min((obj?.fontsize/props.tempHeight)*videoHeight,(obj?.fontsize/props.tempWidth)*videoWidth ) + "px",
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize * 0.9 + "px"
                          : obj?.fontsize * 0.9 + "px",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "px",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      background : obj.backgroundColor,
                      borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                      userSelect: "none",
                      // animation: props.lastAnimation,
                    }}
                  >
                    {obj?.subtype !== "multiline"
                      ? obj?.name
                      : obj.name.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                  </label>
                )}
                {obj.type === "text" && (
                  <>
                    <input
                      type={obj.subtype === "password" ? "password" : ""}
                      value={props.layerContent[index].value}
                      className="textarea"
                      placeholder={obj?.placeholder}
                      // onKeyDown={(event) => {
                      //   // console.log(event.target.selectionStart,event.target.selectionEnd);
                      //   // console.log(event.target.selectionEnd);
                      //   let obj = getInputSelection(event.target);
                      //   console.log(obj.start, obj.end);
                      //   if (
                      //     (event.keyCode >= 48 && event.keyCode <= 57) || // Numbers
                      //     (event.keyCode >= 65 && event.keyCode <= 90) || // Uppercase letters
                      //     (event.keyCode >= 97 && event.keyCode <= 122) || // Lowercase letters
                      //     event.keyCode === 32
                      //   ) {
                      //     props.layerContent[index].maskedvalue =
                      //       // props.layerContent[index].maskedvalue + event.key;
                      //       props.layerContent[index].maskedvalue.slice(
                      //         0,
                      //         obj.start
                      //       ) +
                      //       event.key+
                      //       props.layerContent[index].maskedvalue.slice(obj.end)
                      //     props.setLayerContent([...props.layerContent]);
                      //   } else if (event.keyCode === 8) {

                      //     if (event.target.selectionStart === 0) {
                      //       return;
                      //     }
                      //     if (obj.start === obj.end) {
                      //       obj.start = obj.start - 1;
                      //     }
                      //     props.layerContent[index].maskedvalue =
                      //       props.layerContent[index].maskedvalue.slice(
                      //         0,
                      //         obj.start
                      //       ) +
                      //       props.layerContent[index].maskedvalue.slice(obj.end);
                      //     props.setLayerContent([...props.layerContent]);
                      //     event.target.setSelectionRange(0,0);
                      //     console.log(event.target.selectionStart);
                      //   }
                      // }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleInput(e, obj.subtype, index, obj.characterlimit);
                      }}
                      style={{
                        userSelect:
                          obj.id === props.itemSelected ? "text" : "none",
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: "10px",
                        color: obj?.textColor,
                        display:
                          currentTime >= obj.starttime &&
                          currentTime <= obj.stoptime
                            ? "flex"
                            : "none", //  css for text label
                        alignItems: "center", //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: "hidden", // css for text label
                        // borderColor: obj?.borderColor,
                        transformStyle: "preserve-3d",
                        transform: `rotateX(${
                          obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                        }deg) scale(1.001)`,
                        textDecoration: obj?.textdecoration,
                        textAlign: obj?.textalign,
                        fontFamily: obj?.fontfamily,
                        fontSize:
                          props.aspectvalue >= 1
                            ? obj?.fontsize * 0.9 + "px"
                            : obj?.fontsize * 0.9 + "px",
                        fontStyle: obj?.fontstyle,
                        fontWeight: obj?.fontweight,
                        letterSpacing: obj?.letterspacing + "px",
                        // Math.min(
                        //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                        //   (obj?.letterspacing / props.tempWidth) * videoWidth
                        // ) + "px",
                        // lineHeight:
                        //   Math.min(
                        //     (obj?.lineheight / props.tempHeight) * videoHeight,
                        //     (obj?.lineheight / props.tempWidth) * videoWidth
                        //   ) + "px",
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius + "px"
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        background : obj.backgroundColor,
                        borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                        borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                      }}
                    ></input>

                  </>
                )}
                {obj.type === "textarea" && (
                  <textarea
                    className="textarea"
                    onSelectStart={(e) => {
                      e.preventDefault();
                    }}
                    onChange={(e) => {
                      props.layerContent[index].value = e.target.value;
                      props.setLayerContent([...props.layerContent]);
                      console.log(props.layerContent[index]);
                    }}
                    placeholder={obj?.placeholder}
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize * 0.9 + "px"
                          : obj?.fontsize * 0.9 + "px",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "px",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      background : obj.backgroundColor,
                      borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  ></textarea>
                )}
                {obj.type === "hotspot" && (
                  <button
                    className="textarea"
                    onClick={() => {
                      console.log(
                        "-----------------------------++++++++++++++",
                        obj.actionsarray[0]
                      );
                      // validateInputs();
                      actionHandler(obj);
                    }}
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      cursor: "pointer",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  ></button>
                )}
                {obj.type === "svg" && (
                  <div
                    id={obj.id + "svg"}
                    dangerouslySetInnerHTML={{ __html: obj.svgContent }}
                    style={{
                      width: "100%",
                      height: "100%",
                      fill: obj.backgroundColor,
                      stroke: obj.borderColor,
                      strokeWidth: obj.border,
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) `,
                    }}
                  ></div>
                )}
                {obj.type === "image" && obj.subtype !== "globalasset" && (
                  <img
                    alt="img"
                    src={
                      obj.payload.includes("http")
                        ? `${obj.payload}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`
                        : `${props.assetaddress}/CONTENT/${obj.payload}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`
                    }
                    // crossOrigin="anonymous"
                    // src={props.assetaddress + "/CONTENT/" + obj.payload}
                    className="textarea"
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                      objectFit: "fill",
                    }}
                  />
                )}
                {obj.type === "image" && obj.subtype === "globalasset" && (
                  <img
                    alt="img"
                    // crossOrigin="anonymous"
                    lt="media"
                    dp={
                      props.currentTime >= obj.starttime &&
                      props.currentTime <= obj.stoptime
                        ? "flex"
                        : "none"
                    }
                    src={`${obj.payload}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`}
                    // ${obj.payload.includes('?') ? '&' : '?'}t2=${props.id}`}
                    className="textarea"
                    draggable={
                      props.play &&
                      obj.draggable &&
                      !props.alignElement.has(index)
                    }
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none",
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                      cursor: props.groupSelection
                        ? "default"
                        : obj.draggable
                        ? "move"
                        : "not-allowed",
                      objectFit: "fill",
                    }}
                    // crossOrigin="anonymous"
                  />
                )}
                {obj.type === "vimage" && (
                  <img
                    alt="img"
                    src={obj.payload}
                    className="textarea"
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  />
                )}
                {obj.type === "video" &&
                  obj.subtype !== "globalasset" && (
                    <video
                      key={obj.id + "v"}
                      src={
                        obj.payload.includes("http")
                          ? `${obj.payload}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`
                          : `${props.assetaddress}/CONTENT/${obj.payload}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`
                      }
                      // src={props.assetaddress + "/CONTENT/" + obj.payload}
                      sTime={obj.starttime}
                      eTime={obj.stoptime}
                      autoplay
                      playsInline
                      webkit-playsInline
                      muted
                      loop
                      className="textarea inVideoP"
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: "10px",
                        color: obj?.textColor,
                        display:
                          currentTime >= obj.starttime &&
                          currentTime <= obj.stoptime
                            ? "flex"
                            : "none", //  css for text label
                        alignItems: "center", //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: "hidden", // css for text label
                        // borderColor: obj?.borderColor,
                        resize: "none",
                        transformStyle: "preserve-3d",
                        transform: `rotateX(${
                          obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                        }deg) scale(1.001)`,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius + "px"
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                        borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                        objectFit: "fill",
                      }}
                    />
                  )}
                {obj.type === "video" &&
                  obj.subtype === "globalasset"  && (
                    <video
                      key={obj.id + "v"}
                      lt="media"
                      dp={
                        props.currentTime >= obj.starttime &&
                        props.currentTime <= obj.stoptime
                          ? "flex"
                          : "none"
                      }
                      sTime={obj.starttime}
                      eTime={obj.stoptime}
                      draggable={
                        props.play &&
                        obj.draggable &&
                        !props.alignElement.has(index)
                      }
                      src={`${obj.payload}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`}
                      className="textarea inVideoP"
                      // controls
                      autoPlay
                      muted
                      loop
                      playsInline
                      webkit-playsInline
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: "10px",
                        color: obj?.textColor,
                        display:
                          currentTime >= obj.starttime &&
                          currentTime <= obj.stoptime
                            ? "flex"
                            : "none",
                        alignItems: "center", //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: "hidden", // css for text label
                        // borderColor: obj?.borderColor,
                        resize: "none",
                        transformStyle: "preserve-3d",
                        transform: `rotateX(${
                          obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                        }deg) scale(1.001)`,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius + "px"
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                        borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                        cursor: props.groupSelection
                          ? "default"
                          : obj.draggable
                          ? "move"
                          : "not-allowed",
                        objectFit: "fill",
                      }}
                    />
                  )}
                {obj.type === "vVideo"  && (
                    <video
                      key={obj.id + "v"}
                      src={obj.payload}
                      sTime={obj.starttime}
                      eTime={obj.stoptime}
                      autoplay
                      muted
                      playsInline
                      webkit-playsInline
                      loop
                      className="textarea inVideoP"
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: "10px",
                        color: obj?.textColor,
                        display:
                          currentTime >= obj.starttime &&
                          currentTime <= obj.stoptime
                            ? "flex"
                            : "none", //  css for text label
                        alignItems: "center", //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: "hidden", // css for text label
                        // borderColor: obj?.borderColor,
                        resize: "none",
                        transformStyle: "preserve-3d",
                        transform: `rotateX(${
                          obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                        }deg) scale(1.001)`,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius + "px"
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                        borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                      }}
                    />
                  )}

                {(obj.type === "radio" || obj.type === "checkbox") && (
                  <input
                    type={obj?.type}
                    name={obj?.name}
                    autoComplete="off"
                    className="textarea"
                    placeholder={obj?.placeholder}
                    defaultChecked={obj?.defaultchecked}
                    onChange={(e) => {
                      // if (e.target.checked) {
                      props.layerContent[index].defaultchecked =
                        e.target.checked;
                      // }
                      if (obj.type === "radio") {
                        for (let i = 0; i < props.layerContent.length; i++) {
                          if (
                            props.layerContent[i].name === obj.name &&
                            i !== index
                          ) {
                            props.layerContent[i].defaultchecked = false;
                          }
                        }
                      }
                      props.setLayerContent([...props.layerContent]);
                    }}
                    style={{
                      userSelect:
                        obj.id === props.itemSelected ? "text" : "none",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none",
                      accentColor: obj.backgroundColor,
                      height: "100%",
                      width: "100%",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                    }}
                  ></input>
                )}
                {obj.type === "dropdown" && (
                  <select
                    className="textarea"
                    placeholder={obj?.placeholder}
                    autoComplete="off"
                    // disabled
                    onChange={(e) => {
                      props.layerContent[index].value = e.target.value;
                      props.setLayerContent([...props.layerContent]);
                    }}
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      userSelect: "none",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize * 0.9 + "px"
                          : obj?.fontsize * 0.9 + "px",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "px",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      background : obj.backgroundColor,
                      borderWidth : `${obj.border}px`,
                        borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  >
                    <option key={-1} value={-1}>
                      {obj.name}
                    </option>
                    {obj.optionsarray.map((item, index) => (
                      <option key={index} value={item.Value}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                )}
                {obj.type === "button" && (
                  <button
                    className="textarea"
                    placeholder={obj?.placeholder}
                    onClick={() => {
                      console.log(
                        "-----------------------------++++++++++++++",
                        obj.actionsarray[0]
                      );
                      // validateInputs();
                      actionHandler(obj);
                    }}
                    style={{
                      userSelect: "none",
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      cursor: "pointer",
                      color: obj?.textColor,
                      whiteSpace: "pre",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize * 0.9 + "px"
                          : obj?.fontsize * 0.9 + "px",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "px",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "px"
                          : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                      border: `${obj.border}px solid ${obj.borderColor}`,
                      background : obj.backgroundColor,
                      borderWidth : `${obj.border}px`,
                      borderStyle : "solid",
                      borderImage : `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  >
                    {obj.placeholder.charAt(0) === "L" && (
                      <div
                        style={{
                          margin: "6px",
                          fill: obj.textColor,
                        }}
                      >
                        <FontAwesomeIcon icon={obj.icon} />
                      </div>
                    )}
                    {obj?.name === undefined || obj.name === "" ? "" : obj.name}
                    {obj.placeholder.charAt(0) === "R" && (
                      <div
                        style={{
                          margin: "6px",
                          fill: obj.textColor,
                        }}
                      >
                        <FontAwesomeIcon icon={obj.icon} />
                      </div>
                    )}
                  </button>
                )}
              </div>
            );
          })} */}
          {props.layerContent.map(function (obj, index) {
            return (
              <div
                key={obj.id}
                className="item"
                id={obj.id}
                style={{
                  position: "absolute",
                  left: obj.left + "%",
                  top: obj.top + "%",
                  width:
                    // obj.type === "text"
                    //   ? obj.subtype === "password"
                    //     ? (5 * obj.width) / 6 + "vw"
                    //     : obj.width + "vw"
                    //   :
                    obj.width + "vw",
                  height: obj.height + "vw",
                  // left: (obj.left/props.tempWidth)*videoWidth + "px",
                  // top : (obj.top/props.tempHeight)*videoHeight+ "px",
                  // width: (obj.width/props.tempWidth)*videoWidth + "px",
                  // height: (obj.height/props.tempHeight)*videoHeight + "px",
                  transform: `rotate(${obj.rotate}deg) scale(1.001)`,
                  opacity: obj.opacity,
                  zIndex: obj?.zindex,
                  // zIndex : currentTime >= obj.starttime && currentTime <= obj.stoptime?obj.zindex:-100,
                  border:
                    obj.border === "1px dashed blue" ? obj.border : "none",

                  transformStyle: "preserve-3d",
                  // animation: `${obj.lastanimation}`,
                  animation:
                    currentTime > obj.starttime &&
                    currentTime < (obj.starttime + obj.stoptime) / 2
                      ? obj.entryanimation + " " + obj.animationentryspeed
                      : currentTime > obj.stoptime - 0.5 &&
                        currentTime < obj.stoptime
                      ? obj.exitanimation + " " + obj.animationexitspeed
                      : "",
                  // display: "flex",
                  display: obj.visibility
                    ? currentTime >= obj.starttime &&
                      currentTime <= obj.stoptime
                      ? "flex"
                      : "none"
                    : "none",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {obj.type === "label" && (
                  <label
                    className="textarea "
                    placeholder={obj?.placeholder}
                    style={{
                      whiteSpace:
                        obj?.subtype !== "multiline" ? "pre" : "pre-wrap",
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      // display : "flex",
                      // visibility : "hidden",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      // fontSize:  Math.min((obj?.fontsize/props.tempHeight)*videoHeight,(obj?.fontsize/props.tempWidth)*videoWidth ) + "px",
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize + "vw"
                          : obj?.fontsize * 0.9 + "vh",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "vw",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      background: obj.backgroundColor,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                      userSelect: "none",
                      // animation: props.lastAnimation,
                    }}
                  >
                    {obj?.subtype !== "multiline"
                      ? obj?.name
                      : obj.name.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                  </label>
                )}
                {obj.type === "text" && (
                  <>
                    <input
                      type={obj.subtype === "password" ? "password" : ""}
                      value={props.layerContent[index].value}
                      className="textarea"
                      placeholder={obj?.placeholder}
                      // onKeyDown={(event) => {
                      //   // console.log(event.target.selectionStart,event.target.selectionEnd);
                      //   // console.log(event.target.selectionEnd);
                      //   let obj = getInputSelection(event.target);
                      //   console.log(obj.start, obj.end);
                      //   if (
                      //     (event.keyCode >= 48 && event.keyCode <= 57) || // Numbers
                      //     (event.keyCode >= 65 && event.keyCode <= 90) || // Uppercase letters
                      //     (event.keyCode >= 97 && event.keyCode <= 122) || // Lowercase letters
                      //     event.keyCode === 32
                      //   ) {
                      //     props.layerContent[index].maskedvalue =
                      //       // props.layerContent[index].maskedvalue + event.key;
                      //       props.layerContent[index].maskedvalue.slice(
                      //         0,
                      //         obj.start
                      //       ) +
                      //       event.key+
                      //       props.layerContent[index].maskedvalue.slice(obj.end)
                      //     props.setLayerContent([...props.layerContent]);
                      //   } else if (event.keyCode === 8) {

                      //     if (event.target.selectionStart === 0) {
                      //       return;
                      //     }
                      //     if (obj.start === obj.end) {
                      //       obj.start = obj.start - 1;
                      //     }
                      //     props.layerContent[index].maskedvalue =
                      //       props.layerContent[index].maskedvalue.slice(
                      //         0,
                      //         obj.start
                      //       ) +
                      //       props.layerContent[index].maskedvalue.slice(obj.end);
                      //     props.setLayerContent([...props.layerContent]);
                      //     event.target.setSelectionRange(0,0);
                      //     console.log(event.target.selectionStart);
                      //   }
                      // }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleInput(e, obj.subtype, index, obj.characterlimit);
                      }}
                      style={{
                        userSelect:
                          obj.id === props.itemSelected ? "text" : "none",
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: "10px",
                        color: obj?.textColor,
                        display:
                          currentTime >= obj.starttime &&
                          currentTime <= obj.stoptime
                            ? "flex"
                            : "none", //  css for text label
                        alignItems: "center", //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: "hidden", // css for text label
                        // borderColor: obj?.borderColor,
                        transformStyle: "preserve-3d",
                        transform: `rotateX(${
                          obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                        }deg) scale(1.001)`,
                        textDecoration: obj?.textdecoration,
                        textAlign: obj?.textalign,
                        fontFamily: obj?.fontfamily,
                        fontSize:
                          props.aspectvalue >= 1
                            ? obj?.fontsize + "vw"
                            : obj?.fontsize * 0.9 + "vh",
                        fontStyle: obj?.fontstyle,
                        fontWeight: obj?.fontweight,
                        letterSpacing: obj?.letterspacing + "vw",
                        // Math.min(
                        //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                        //   (obj?.letterspacing / props.tempWidth) * videoWidth
                        // ) + "px",
                        // lineHeight:
                        //   Math.min(
                        //     (obj?.lineheight / props.tempHeight) * videoHeight,
                        //     (obj?.lineheight / props.tempWidth) * videoWidth
                        //   ) + "px",
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius + "vw"
                            : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                        border: `${obj.border}vw solid ${obj.borderColor}`,
                        background: obj.backgroundColor,
                        borderWidth: `${obj.border}vw`,
                        borderStyle: "solid",
                        borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                      }}
                    ></input>
                  </>
                )}
                {obj.type === "textarea" && (
                  <textarea
                    className="textarea"
                    onSelectStart={(e) => {
                      e.preventDefault();
                    }}
                    onChange={(e) => {
                      props.layerContent[index].value = e.target.value;
                      props.setLayerContent([...props.layerContent]);
                      console.log(props.layerContent[index]);
                    }}
                    placeholder={obj?.placeholder}
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize + "vw"
                          : obj?.fontsize * 0.9 + "vh",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "vw",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      background: obj.backgroundColor,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  ></textarea>
                )}
                {obj.type === "hotspot" && (
                  <button
                    className="textarea"
                    onClick={() => {
                      console.log(
                        "-----------------------------++++++++++++++",
                        obj.actionsarray[0]
                      );
                      // validateInputs();
                      actionHandler(obj);
                    }}
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      cursor: "pointer",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  ></button>
                )}
                {obj.type === "svg" && (
                  <div
                    id={obj.id + "svg"}
                    dangerouslySetInnerHTML={{ __html: obj.svgContent }}
                    style={{
                      width: "100%",
                      height: "100%",
                      fill: obj.backgroundColor,
                      stroke: obj.borderColor,
                      strokeWidth: obj.border,
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) `,
                    }}
                  ></div>
                )}
                {obj.type === "image" && obj.subtype !== "globalasset" && (
                  <img
                    alt="img"
                    src={
                      obj.payload.includes("http")
                        ? obj.payload.includes("CUSTOMERASSETS")? `${obj.payload}${
                            obj.payload.includes("?") ? "&" : "?"
                          }id=${props.id}`: `${obj.payload.replace(/TEMP\/[^/]+/, `TEMPLATE/${props.templateId}`)}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`
                        : `${props.assetaddress}/CONTENT/${obj.payload}${
                            obj.payload.includes("?") ? "&" : "?"
                          }id=${props.id}` 
                    }
                    // src={props.assetaddress + "/CONTENT/" + obj.payload}
                    className="textarea"
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                      objectFit: "fill",
                    }}
                  />
                )}
                {obj.type === "image" && obj.subtype === "globalasset" && (
                  <img
                    alt="img"
                    // crossOrigin="anonymous"
                    lt="media"
                    dp={
                      props.currentTime >= obj.starttime &&
                      props.currentTime <= obj.stoptime
                        ? "flex"
                        : "none"
                    }
                    src={`${obj.payload}${
                      obj.payload.includes("?") ? "&" : "?"
                    }id=${props.id}`}
                    className="textarea"
                    draggable={
                      props.play &&
                      obj.draggable &&
                      !props.alignElement.has(index)
                    }
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none",
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                      cursor: props.groupSelection
                        ? "default"
                        : obj.draggable
                        ? "move"
                        : "not-allowed",
                      objectFit: "fill",
                    }}
                    // crossOrigin="anonymous"
                  />
                )}
                {obj.type === "vimage" && (
                  <img
                    alt="img"
                    src={obj.payload}
                    className="textarea"
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  />
                )}
                {obj.type === "video" && obj.subtype !== "globalasset" && (
                  <video
                    key={obj.id + "v"}
                    src={
                      obj.payload.includes("http")
                        ? obj.payload.includes("CUSTOMERASSETS")? `${obj.payload}${
                          obj.payload.includes("?") ? "&" : "?"
                        }id=${props.id}`: `${obj.payload.replace(/TEMP\/[^/]+/, `TEMPLATE/${props.templateId}`)}${obj.payload.includes('?') ? '&' : '?'}id=${props.id}`
                      
                        : `${props.assetaddress}/CONTENT/${obj.payload}${
                            obj.payload.includes("?") ? "&" : "?"
                          }id=${props.id}`
                    }
                    // src={props.assetaddress + "/CONTENT/" + obj.payload}
                    sTime={obj.starttime}
                    eTime={obj.stoptime}
                    autoplay
                    playsInline
                    webkit-playsInline
                    muted
                    loop={obj.defaultchecked==undefined || obj.defaultchecked?true:false}
                    className="textarea inVideoP"
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                      objectFit: "fill",
                    }}
                  />
                )}
                {obj.type === "video" && obj.subtype === "globalasset" && (
                  <video
                    key={obj.id + "v"}
                    lt="media"
                    dp={
                      props.currentTime >= obj.starttime &&
                      props.currentTime <= obj.stoptime
                        ? "flex"
                        : "none"
                    }
                    sTime={obj.starttime}
                    eTime={obj.stoptime}
                    draggable={
                      props.play &&
                      obj.draggable &&
                      !props.alignElement.has(index)
                    }
                    src={`${obj.payload}${
                      obj.payload.includes("?") ? "&" : "?"
                    }id=${props.id}`}
                    className="textarea inVideoP"
                    // controls
                    autoPlay
                    muted
                    loop={obj.defaultchecked==undefined || obj.defaultchecked?true:false}
                    playsInline
                    webkit-playsInline
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none",
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                      cursor: props.groupSelection
                        ? "default"
                        : obj.draggable
                        ? "move"
                        : "not-allowed",
                      objectFit: "fill",
                    }}
                  />
                )}
                {obj.type === "vVideo" && (
                  <video
                    key={obj.id + "v"}
                    src={obj.payload}
                    sTime={obj.starttime}
                    eTime={obj.stoptime}
                    autoplay
                    muted
                    playsInline
                    webkit-playsInline
                    loop ={obj.defaultchecked==undefined || obj.defaultchecked?true:false}
                    className="textarea inVideoP"
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      resize: "none",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  />
                )}

                {(obj.type === "radio" || obj.type === "checkbox") && (
                  <input
                    type={obj?.type}
                    name={obj?.name}
                    autoComplete="off"
                    className="textarea"
                    placeholder={obj?.placeholder}
                    defaultChecked={obj?.defaultchecked}
                    onChange={(e) => {
                      // if (e.target.checked) {
                      props.layerContent[index].defaultchecked =
                        e.target.checked;
                      // }
                      if (obj.type === "radio") {
                        for (let i = 0; i < props.layerContent.length; i++) {
                          if (
                            props.layerContent[i].name === obj.name &&
                            i !== index
                          ) {
                            props.layerContent[i].defaultchecked = false;
                          }
                        }
                      }
                      props.setLayerContent([...props.layerContent]);
                    }}
                    style={{
                      userSelect:
                        obj.id === props.itemSelected ? "text" : "none",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none",
                      accentColor: obj.backgroundColor,
                      height: "100%",
                      width: "100%",
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                    }}
                  ></input>
                )}
                {obj.type === "dropdown" && (
                  <select
                    className="textarea"
                    placeholder={obj?.placeholder}
                    autoComplete="off"
                    // disabled
                    onChange={(e) => {
                      props.layerContent[index].value = e.target.value;
                      props.setLayerContent([...props.layerContent]);
                    }}
                    style={{
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      userSelect: "none",
                      color: obj?.textColor,
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize + "vw"
                          : obj?.fontsize * 0.9 + "vh",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "vw",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      background: obj.backgroundColor,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  >
                    <option key={-1} value={-1}>
                      {obj.name}
                    </option>
                    {obj.optionsarray.map((item, index) => (
                      <option key={index} value={item.Value}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                )}
                {obj.type === "button" && (
                  <button
                    className="textarea"
                    placeholder={obj?.placeholder}
                    onClick={() => {
                      console.log(
                        "-----------------------------++++++++++++++",
                        obj.actionsarray[0]
                      );
                      // validateInputs();
                      actionHandler(obj);
                    }}
                    style={{
                      userSelect: "none",
                      backgroundColor: obj.backgroundColor,
                      // paddingInline: "10px",
                      cursor: "pointer",
                      color: obj?.textColor,
                      whiteSpace: "pre",
                      display:
                        currentTime >= obj.starttime &&
                        currentTime <= obj.stoptime
                          ? "flex"
                          : "none", //  css for text label
                      alignItems: "center", //  css for text label
                      justifyContent: obj?.textalign, //  css for text label
                      overflow: "hidden", // css for text label
                      // borderColor: obj?.borderColor,
                      transformStyle: "preserve-3d",
                      transform: `rotateX(${
                        obj.flip === "N" ? 0 : obj.flip === "V" ? 180 : 0
                      }deg) rotateY(${
                        obj.flip === "N" ? 0 : obj.flip === "H" ? 180 : 0
                      }deg) scale(1.001)`,
                      textDecoration: obj?.textdecoration,
                      textAlign: obj?.textalign,
                      fontFamily: obj?.fontfamily,
                      fontSize:
                        props.aspectvalue >= 1
                          ? obj?.fontsize + "vw"
                          : obj?.fontsize * 0.9 + "vh",
                      fontStyle: obj?.fontstyle,
                      fontWeight: obj?.fontweight,
                      letterSpacing: obj?.letterspacing + "vw",
                      // Math.min(
                      //   (obj?.letterspacing / props.tempHeight) * videoHeight,
                      //   (obj?.letterspacing / props.tempWidth) * videoWidth
                      // ) + "px",
                      // lineHeight:
                      //   Math.min(
                      //     (obj?.lineheight / props.tempHeight) * videoHeight,
                      //     (obj?.lineheight / props.tempWidth) * videoWidth
                      //   ) + "px",
                      borderRadius:
                        obj.tlborderradius === undefined
                          ? obj?.borderradius + "vw"
                          : `${obj?.tlborderradius}vw ${obj?.trborderradius}vw ${obj?.blborderradius}vw ${obj?.brborderradius}vw`,
                      border: `${obj.border}vw solid ${obj.borderColor}`,
                      background: obj.backgroundColor,
                      borderWidth: `${obj.border}vw`,
                      borderStyle: "solid",
                      borderImage: `${obj.borderColor}  1 / 1 / 0 round`,
                    }}
                  >
                    {obj.placeholder.charAt(0) === "L" && (
                      <div
                        style={{
                          margin: "6px",
                          fill: obj.textColor,
                        }}
                      >
                        <FontAwesomeIcon icon={obj.icon} />
                      </div>
                    )}
                    {obj?.name === undefined || obj.name === "" ? "" : obj.name}
                    {obj.placeholder.charAt(0) === "R" && (
                      <div
                        style={{
                          margin: "6px",
                          fill: obj.textColor,
                        }}
                      >
                        <FontAwesomeIcon icon={obj.icon} />
                      </div>
                    )}
                  </button>
                )}
              </div>
            );
          })}
          {props.audioData?.length > 0 && props.playbgmusic && (
            <audio
              //   className="inAudioP"
              ref={bgMusic}
              autoPlay
              muted
              loop={props.musicLoop}
              src={`${props.assetaddress}/CONTENT/${props.audioData[0].name}`}
            ></audio>
          )}
          {props?.musicData?.map((item, index) => {
            if (item.checked) {
              return (
                <audio
                  autoPlay
                  muted
                  loop={item.defaultchecked==undefined || item.defaultchecked?true:false}
                  className="inAudioP"
                  stime={item.starttime}
                  etime={item.stoptime}
                  src={`${props.assetaddress}/CONTENT/${props.musicData[index].name}`}
                ></audio>
              );
            } else {
              return null;
            }
          })}
          {props?.globalmusicData?.map((item, index) => {
            if (item.checked) {
              return (
                <audio
                  autoPlay
                  muted
                  loop={item.defaultchecked==undefined || item.defaultchecked?true:false}
                  className="inAudioP"
                  stime={item.starttime}
                  etime={item.stoptime}
                  src={`${item.ASSETSBASEPATH}/${item.ASSETSSYSTEMFILENAME}`}
                ></audio>
              );
            } else {
              return null;
            }
          })}
          {playerRef.current !== null && playerRef.current.paused() && (
            <div className="pmodal">
              <Info isOpen={displayMessage} onClose={closeModal}>
                <div className="pmodal-container">
                  {responseTimer === null && (
                    <Cross
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        stroke: "black",
                        width: props.aspectvalue >= 1 ? "10px" : "20px",
                        height: props.aspectvalue >= 1 ? "10px" : "20px",
                      }}
                      onClick={closeModal}
                    />
                  )}
                  <div className="pmodal-description">
                    <img
                      style={{
                        width: props.aspectvalue >= 1 ? "20px" : "30px",
                      }}
                      className="presponseImg"
                      src={Warning}
                      alt="warning"
                    />
                    <p
                      className="content"
                      style={{
                        fontSize: props.aspectvalue >= 1 ? "12px" : "14px",
                        wordBreak: "break-all",
                      }}
                    >
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </Info>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function useRunAfterUpdate() {
  const afterPaintRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = (fn) => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

export default VideoJSPrev;
