import { toast } from "react-toastify";

const API_BASE_URL = window.baseurl;
console.log(API_BASE_URL, "BASE URL");

// const API_BASE_URL = process.env.REACT_APP_BASE_URL;

// ***************** CENTRALIZED API CALLING ONLY FOR UPDATING DATA ON SERVER *************************
const uploadFile = async (endpoint, data, customHeaders) => {
  if (!navigator.onLine) {
    toast.error("No internet connection. Please check your network.");
    return;
  }
  try {
    const requestOptions = {
      method: "POST",
      body: data,
    };

    // If the endpoint is "/base64file," add custom headers
    if (endpoint === "/base64file") {
      requestOptions.headers = {
        "Content-Type": "application/json",
        ...customHeaders,
      };
    }

    const currentTime = new Date().getTime();
    const response = await fetch(`${API_BASE_URL}${endpoint}?t=${currentTime}`, requestOptions);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.DESCRIPTION);
    }
  } catch (error) {
    console.error(error);
    throw toast.error("Something went wrong!! Please try later.");
  }
};

export const uploadFileThumbnail = async (uploadThumbnail) => {
  if (!navigator.onLine) {
    toast.error("No internet connection. Please check your network.");
    return;
  }
  const endpoint = "/base64file";
  const uploadData = {
    action: "TEMP",
    templateid: uploadThumbnail.templateId,
    base64data: uploadThumbnail.thumbnail,
    uploadtype: uploadThumbnail.filetype,
  };
  const customHeaders = {
    // Add any custom headers specific to "/base64file" here
  };

  const data = JSON.stringify(uploadData);
  return uploadFile(endpoint, data, customHeaders);
};

export const zipFileUploadApi = async (zipData) => {
  const endpoint = "/uploadprjzip";
  const formData = new FormData();
  formData.append("file", zipData.zipfile);
  formData.append("templateid", zipData.templateid);
  formData.append("sessionuserid", zipData.sessionuserid);

  const data = formData;
  return uploadFile(endpoint, data);
};
