import React from 'react'

function ExitAnimationContent(props) {
    return (
      <div className="flex gap-2 mt-3 flex-wrap font-sans">
      {props.animationData.map((item, index) => (
                <div key={index}>
                  <div
                    className={`px-3 pt-[4px] pb-[6px] rounded-3xl cursor-pointer ${
                      props.exitAnimation === item.value
                      ? "bg-[#1C68EB] text-white border-solid border-2 border-[#1c68EB]"
                      : "text-white border-solid border-[#2b2b2c] border-2"
                    }`}
                    onClick={() =>{
                    if(item.value !== props.exitAnimation){
                      props.setExitAnimation(item.value)
                      props.setLastAnimation(item.value + " " + props.animationExitSpeed)
                    }
                    else{
                      props.setExitAnimation("")
                      props.setLastAnimation("")
                    }
                  }}
                  >{item.value}</div>
                </div>
              ))}
      </div>
    )
  }

export default ExitAnimationContent