import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { rightDrawerType } from "../data";
import html2canvas from "html2canvas";
import {
  Divider,
  Input,
  Popover,
  Modal,
  ColorPicker,
  InputNumber,
} from "antd";
import { divObj } from "../../utils/helper";
import ComponentDetail from "./ComponentDetail";
import DesignDetail from "./DesignDetail";
import "./Action.css";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { ReactComponent as Text } from "../../assets/text.svg";
import { ReactComponent as Video } from "../../assets/video.svg";
import { ReactComponent as Hotspot } from "../../assets/hotspot.svg";
import { ReactComponent as ButtonImg } from "../../assets/button.svg";
import { ReactComponent as Text1 } from "../../assets/text1.svg";
import { ReactComponent as OptionsList } from "../../assets/optionsList.svg";
import { ReactComponent as Lock } from "../../assets/closelock.svg";
import { ReactComponent as Unlock } from "../../assets/openlock.svg";
import { ReactComponent as VisON } from "../../assets/visOn.svg";
import { ReactComponent as VisOFF } from "../../assets/visOff.svg";
import { ReactComponent as Dots } from "../../assets/verticalDots.svg";
import frame from "../../assets/Frame.png";
import SimpleButton from "../../../saasWebsite/common/components/SimpleButton";
import { toast } from "react-toastify";
import TextArea from "antd/es/input/TextArea";
import idCreate from "../../utils/uuid";
import InfiniteScroll from "react-infinite-scroll-component";
const RightSidebarDashboard = ({
  itemSelected,
  setItem,
  canvaContent,
  handleContent,
  elementProperties,
  deleted,
  setDeleted,
  handleDeleted,
  layerButton,
  setLayerButton,
  settingButton,
  musicButton,
  timeSetting,
  undoRedoAction,
  isDivDropped,
  setIsDivDropped,
  templateDetails,
  setTemplateDetails,
  selectedSidebarItem,
  setSelectedSidebarItem,
  displayTextConfig,
  setLastAnimation,
  setSeekLayer,
  setIndex,
  setRightClicked,
  templateInfo,
  pathName,
  setAudioData,
  groupSelection,
  setGroupSelection,
  setAlign,
  alignElement,
  setAlignElement,
  templateName,
  setTemplateName,
  templateDescription,
  setTemplateDescription,
  imageBase64,
  setImageBase64,
  currentTime,
  resizeRatio,
  setResizeRatio,
  link,
  setLink,
  fieldEnabled,
  allLocks,
  setAllLocks,
  payload,
  setPayload,
  showDialog,
  setShowDialog,
  isModalOpen,
  setIsModalOpen,
  isRight,
  setIsRight,
  svgCode,
  setSVGCode,
  vis,
  dragging,
  setDragging,
  horizontalAlign,
  verticalAlign,
  setHorizontalAlign,
  setVerticalAlign,
  baseColor,
  setBaseColor,
  baseHeight,
  setBaseHeight
}) => {
  let [idx, setIdx] = useState(null);
  let [bgColor, setbgColor] = useState("#000000");
  let [tbgColor, setTbgColor] = useState("#000000");
  let [textColor, setTextColor] = useState("#000000");
  let [ttextColor, setTTextColor] = useState("#000000");
  let [borderColor, setBorderColor] = useState("transparent");
  let [tborderColor, setTBorderColor] = useState("transparent");
  let [xcord, setXcord] = useState("");
  let [ycord, setYcord] = useState("");
  let [height, setHeight] = useState("");
  let [width, setWidth] = useState("");
  let [rotate, setRotate] = useState("");
  let [flip, setFlip] = useState("N");
  let [opacity, setOpacity] = useState("");
  let [fontSize, setFontSize] = useState("");
  let [fontStyle, setFontStyle] = useState("");
  let [fontWeight, setFontWeight] = useState("");
  let [textAlign, setTextAlign] = useState("");
  let [textDecoration, setTextDecoration] = useState("");
  let [fontFamily, setfontFamily] = useState("");
  let [letterSpacing, setLetterSpacing] = useState("");
  let [lineHeight, setLineHeight] = useState("");
  let [border, setBorder] = useState("");
  let [borderRadius, setBorderRadius] = useState("");
  let [tlborderRadius, setTLBorderRadius] = useState(0);
  let [trborderRadius, setTRBorderRadius] = useState(0);
  let [blborderRadius, setBLBorderRadius] = useState(0);
  let [brborderRadius, setBRBorderRadius] = useState(0);
  let [layerName, setLayername] = useState("");
  let [placeHolder, setPlaceholder] = useState("");
  let [cltoggle, setCltoggle] = useState(false);
  let [emtoggle, setEmtoggle] = useState(false);
  let [characterLimit, setCharacterLimit] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [name, setName] = useState("");
  let [eleValue, setEleValue] = useState("");
  let [type, setType] = useState("");
  let [subType, setSubType] = useState("");
  let [startTime, setStartTime] = useState("");
  let [stopTime, setStopTime] = useState("");
  let [pauseToggle, setPauseToggle] = useState("");
  let [resumeToggle, setResumeToggle] = useState("");
  let [pauseTime, setPauseTime] = useState("");
  let [resumeIn, setResumeIn] = useState("");
  let [resumeTo, setResumeTo] = useState("");
  let [seekTo, setSeekTo] = useState("");
  let [optionsArray, setOptionsArray] = useState([""]);
  let [animationEntrySpeed, setAnimationEntrySpeed] = useState("");
  let [animationExitSpeed, setAnimationExitSpeed] = useState("");
  let [animationType, setAnimationType] = useState("");
  let [exitAnimation, setExitAnimation] = useState("");
  let [entryAnimation, setEntryAnimation] = useState("");
  let [timming, setTimming] = useState("");
  let [icon, setIcon] = useState("");
  let [zIndex, setZIndex] = useState(0);
  let [defaultChecked, setDefaultChecked] = useState(true);
  const drawArea = document.querySelector(".drawArea");
  const [drawAreaHeight, setDrawAreaHeight] = useState("");
  const [drawAreaWidth, setDrawAreaWidth] = useState("");
  const [isItemSelected, setIsItemSelected] = useState(false);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [layerContent, setLayerContent] = useState(canvaContent);
  const [open, setOpen] = useState(false);
  const [openDD, setOpenDD] = useState(false);
  const [tempMis, setTempMis] = useState([]);
  const [action, setAction] = useState(0);
  const [descDisable, setDescDisable] = useState(true);
  const [nameDisable, setNameDisable] = useState(true);
  const [actionsArray, setActionsArray] = useState([]);
  const audioInputRef = useRef(null);
  const [currLayer, setCurrLayer] = useState(false);
  let thumbnailRef = useRef(null);
  const [thumbnailType, setThumbnailType] = useState(0);
  const [id, setId] = useState(Date.now());
  const [modalOpen, setModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreTime, setHasMoreTime] = useState(true);
  const [content, setContent] = useState([]);
  const [inc, setInc] = useState(20);
  const [incTime, setIncTime] = useState(20);
  let [layerDisabled, setLayerDisabled] = useState(-1);
  let nameRef = useRef(true);
  let tempDescRef = useRef(true);
  const [thumbCreate, setThumbCreate] =  useState(0);
  const [Source,setSource] =  useState();
  console.log(baseColor);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const handleOpenDD = () => {
    setOpenDD(null);
  };

  const error = (id, title, content) => {
    Modal.error({
      title: title,
      content: content,
      onOk: () => {
        document.getElementById(id).focus();
        setIsModalOpen(false);
        setModalOpen(false);
        Modal.destroyAll();
      },
    });
  };

  const fetchMoreData = () => {
    if (content.length >= layerContent.length) {
      setHasMore(false);
      return;
    }
    setContent([...Array.from(layerContent.slice(0, inc + 20))]);
    setInc((prev) => prev + 20);
  };

  const fetchMoreTimeData = () => {
    if (timming.length >= canvaContent.length) {
      setHasMoreTime(false);
      return;
    }
    setTimming([...Array.from([...canvaContent].slice(0, incTime + 20))]);
    setIncTime((prev) => prev + 20);
  };

  // FETCHING API_BASE_URL FROM INDEX.HTML
  const API_BASE_URL = window.baseurl;

  useEffect(() => {
    // Attach event listener for keydown event
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  function handleKeyPress(e) {
    function handleMotion(val, e) {
      if (e.keyCode == "38") {
        //up
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setYcord(ycord - val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].top = canvaContent[idx].top - val;
          }
          canvaContent[canvaContent.length - 1].top =
            canvaContent[canvaContent.length - 1].top - val;
          handleContent(canvaContent);
        }
      } else if (e.keyCode == "40") {
        //down
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setYcord(ycord + val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].top = canvaContent[idx].top + val;
          }
          canvaContent[canvaContent.length - 1].top += val;
          handleContent(canvaContent);
        }
      } else if (e.keyCode == "37") {
        //left
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setXcord(xcord - val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].left = canvaContent[idx].left - val;
          }
          canvaContent[canvaContent.length - 1].left =
            canvaContent[canvaContent.length - 1].left - val;
          handleContent(canvaContent);
        }
      } else if (e.keyCode == "39") {
        //right
        e.preventDefault();
        if (itemSelected != null && !groupSelection) {
          setXcord(xcord + val);
        } else if (groupSelection) {
          for (const idx of alignElement) {
            canvaContent[idx].left = canvaContent[idx].left + val;
          }
          canvaContent[canvaContent.length - 1].left =
            canvaContent[canvaContent.length - 1].left + val;
          handleContent(canvaContent);
        }
      }
    }

    if (!fieldEnabled.current) {
      if (e.shiftKey) {
        handleMotion(10, e);
      } else {
        handleMotion(1, e);
      }
    }
  }

  useEffect(() => {
    if (!descDisable) {
      document.getElementById("td").focus();
    }
    if (!nameDisable) {
      document.getElementById("tn").focus();
    }
    if (layerDisabled !== -1) {
      document.getElementById(layerDisabled + "L").focus();
    }
  }, [descDisable, nameDisable, layerDisabled]);

  useEffect(() => {
    if (horizontalAlign !== null) {
      setXcord(horizontalAlign);
      setHorizontalAlign(null);
    }
  }, [horizontalAlign]);
  useEffect(() => {
    if (verticalAlign !== null) {
      setYcord(verticalAlign);
      setVerticalAlign(null);
    }
  }, [verticalAlign]);

  useEffect(() => {
    if (imageBase64 !== null && imageBase64 !== "") {
      const uploadFileThumbnail = async () => {
        try {
          // debugger
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              action: pathName === "createBrandTemplate" ? "TEMP" : "LIVE",
              brandpageid: templateInfo[0]?.BRANDPAGEID,
              base64data: imageBase64,
              uploadtype: "thumbnail",
            }),
          };

          const response = await fetch(
            `${API_BASE_URL}/brandbase64file`,
            requestOptions
          );

          console.log(response, "RESPONSE THUMB DATA API");
          const data = await response.json();
          console.log(data, "UPDATED THUMBNAIL DATA");
          if (data.STATUS === "1000") {
            toast.success(data.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
            setImageBase64(null);
            setId(idCreate());
            setThumbCreate(thumbCreate+1);
            return data;
          } else {
            toast.error(data.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
            setImageBase64(null);
          }
        } catch (error) {
          toast.error(error, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
          setImageBase64(null);
        }
      };
      uploadFileThumbnail();
    }
  }, [imageBase64, pathName, templateInfo]);

  useEffect(() => {
    let arr = [...canvaContent];
    setLayerContent([...arr].sort((a, b) => b.zindex - a.zindex));
    if (content.length < canvaContent.length) {
      setHasMore(true);
    }
    setContent(
      Array.from([...arr].sort((a, b) => b.zindex - a.zindex).slice(0, inc))
    );
  }, [canvaContent]);

  useEffect(() => {
    let arr = [...canvaContent];
    if (timming.length < canvaContent.length) {
      setHasMoreTime(true);
    }
    setTimming(arr.slice(0, incTime));
  }, [canvaContent]);

  useEffect(() => {
    if (elementProperties.length === 0) {
      return;
    }
    setXcord(elementProperties.X);
    setYcord(elementProperties.Y);
    setHeight(elementProperties.height);
    setWidth(elementProperties.width);
    setRotate(elementProperties.rotate);
    setFlip(elementProperties.flip);
    setBorder(elementProperties.border);
    setBorderRadius(elementProperties.borderradius);
    setType(elementProperties.type);
    setSubType(elementProperties.subtype);
    setLayername(elementProperties.layername);
    setPlaceholder(elementProperties.placeholder);
    setCltoggle(elementProperties.cltoggle);
    setEmtoggle(elementProperties.emtoggle);
    setCharacterLimit(elementProperties.characterlimit);
    setErrorMessage(elementProperties.errormessage);
    setName(elementProperties.name);
    setAction(elementProperties.action);
    setActionsArray(elementProperties.actionsarray);
  }, [elementProperties]);

  useEffect(
    function () {
      setLastAnimation("");
      if (itemSelected === null) {
        if (deleted) {
          setStates(
            "#000000",
            "",
            "",
            "",
            "",
            0,
            "#000000",
            "#ffffff",
            "N",
            0,
            "none",
            18,
            "Times New Roman",
            "none",
            "normal",
            "normal",
            "0",
            "1",
            "",
            "",
            "",
            "",
            false,
            false,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            false,
            false,
            "",
            "",
            "",
            "",
            [],
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            false,
            [],
            0,
            0,
            0,
            0,
            ""
          );
          handleDeleted();
          return;
        } else {
          return;
        }
      }
      let resProp =
        canvaContent.length === 0
          ? [{}]
          : canvaContent.find((obj, idx) => {
              if (obj.id === itemSelected) {
                setIdx(idx);
                return true;
              } else {
                return false;
              }
            });
      setTbgColor(resProp.backgroundColor);
      setTTextColor(resProp.textColor);
      setTBorderColor(resProp.borderColor);
      setStates(
        resProp.backgroundColor,
        resProp.height,
        resProp.width,
        resProp.left,
        resProp.top,
        resProp.rotate,
        resProp.textColor,
        resProp.borderColor,
        resProp.flip,
        resProp.opacity,
        resProp.textalign,
        resProp.fontsize,
        resProp.fontfamily,
        resProp.textdecoration,
        resProp.fontstyle,
        resProp.fontweight,
        resProp.letterspacing,
        resProp.lineheight,
        resProp.border,
        resProp.borderradius,
        resProp.layername,
        resProp.placeholder,
        resProp.cltoggle,
        resProp.emtoggle,
        resProp.characterlimit,
        resProp.errormessage,
        resProp.name,
        resProp.type,
        resProp.subtype,
        resProp.starttime,
        resProp.stoptime,
        resProp.pausetoggle,
        resProp.resumetoggle,
        resProp.pausetime,
        resProp.resumein,
        resProp.resumeto,
        resProp.seekto,
        resProp.optionsarray,
        resProp.animationentryspeed,
        resProp.animationexitspeed,
        resProp.animationtype,
        resProp.exitanimation,
        resProp.entryanimation,
        resProp.icon,
        resProp.value,
        resProp.zindex,
        resProp.payload,
        resProp.action,
        resProp.defaultchecked,
        resProp.actionsarray,
        resProp.tlborderradius,
        resProp.trborderradius,
        resProp.blborderradius,
        resProp.brborderradius,
        resProp.svgContent
      );
      if (link) {
        setResizeRatio(resProp.width / resProp.height);
      }
      setSelectedSidebarItem(0);
      setDrawAreaHeight(parseInt(window.getComputedStyle(drawArea).height));
      setDrawAreaWidth(parseInt(window.getComputedStyle(drawArea).width));
      if (itemSelected === "MultiLayer") {
      } else {
        setTemplateDetails(false);
        setGroupSelection(false);
        setAlign("");
        setAlignElement(new Set([]));
        if (canvaContent[canvaContent.length - 1].id === "MultiLayer") {
          handleContent(canvaContent.splice(canvaContent.length - 1, 1));
        }
      }
      setIsItemSelected(true);
    },
    [itemSelected]
  );

  function setStates(
    color,
    height,
    width,
    left,
    top,
    rotate,
    textColor,
    borderColor,
    flip,
    opacity,
    textalign,
    fontsize,
    fontfamily,
    textdecoration,
    fontstyle,
    fontweight,
    letterspacing,
    lineheight,
    border,
    borderradius,
    layername,
    placeholder,
    cltoggle,
    emtoggle,
    characterlimit,
    errormessage,
    name,
    type,
    subtype,
    starttime,
    stoptime,
    pausetoggle,
    resumetoggle,
    pausetime,
    resumein,
    resumeto,
    seekto,
    optionsarray,
    animationentryspeed,
    animationexitspeed,
    animationtype,
    exitanimation,
    entryanimation,
    icon,
    value,
    zindex,
    payload,
    action,
    defaultchecked,
    actionsarray,
    tlborderradius,
    trborderradius,
    blborderradius,
    brborderradius,
    svgContent
  ) {
    setbgColor(color);
    setHeight(parseFloat(height));
    setWidth(parseFloat(width));
    setXcord(parseFloat(left));
    setYcord(parseFloat(top));
    setRotate(parseFloat(rotate));
    setTextColor(textColor);
    setBorderColor(borderColor);
    setFlip(flip);
    setOpacity(opacity);
    setTextAlign(textalign);
    setFontSize(fontsize);
    setfontFamily(fontfamily);
    setTextDecoration(textdecoration);
    setFontStyle(fontstyle);
    setFontWeight(fontweight);
    setLetterSpacing(letterspacing);
    setLineHeight(lineheight);
    setBorder(border);
    setBorderRadius(borderradius);
    setLayername(layername);
    setPlaceholder(placeholder);
    setCltoggle(cltoggle);
    setEmtoggle(emtoggle);
    setCharacterLimit(characterlimit);
    setErrorMessage(errormessage);
    setName(name);
    setType(type);
    setSubType(subtype);
    setStartTime(starttime);
    setStopTime(stoptime);
    setPauseToggle(pausetoggle);
    setResumeToggle(resumetoggle);
    setPauseTime(pausetime);
    setResumeIn(resumein);
    setResumeTo(resumeto);
    setSeekTo(seekto);
    setOptionsArray(optionsarray);
    setAnimationEntrySpeed(animationentryspeed);
    setAnimationExitSpeed(animationexitspeed);
    setAnimationType(animationtype);
    setEntryAnimation(entryanimation);
    setExitAnimation(exitanimation);
    setIcon(icon);
    setEleValue(value);
    setZIndex(zindex);
    setPayload(payload);
    setAction(action);
    setDefaultChecked(defaultchecked);
    setActionsArray(actionsarray);
    setTLBorderRadius(tlborderradius);
    setTRBorderRadius(trborderradius);
    setBLBorderRadius(blborderradius);
    setBRBorderRadius(brborderradius);
    setSVGCode(svgContent);
  }

  useEffect(
    function () {
      if (idx == null || itemSelected == null) {
        return;
      }
      let id = canvaContent[idx].id;
      let draggable = canvaContent[idx].draggable;
      let visibility = canvaContent[idx].visibility;
      let newObj = new divObj(
        xcord,
        ycord,
        height,
        width,
        bgColor,
        rotate,
        id,
        zIndex,
        border,
        startTime,
        stopTime,
        visibility,
        textColor,
        borderColor,
        flip,
        opacity,
        textAlign,
        fontSize,
        fontFamily,
        textDecoration,
        fontStyle,
        fontWeight,
        letterSpacing,
        lineHeight,

        borderRadius,
        layerName,
        cltoggle,
        emtoggle,
        placeHolder,
        characterLimit,
        errorMessage,
        name,
        type,
        subType,
        pauseToggle,
        resumeToggle,
        pauseTime,
        resumeIn,
        resumeTo,
        seekTo,
        optionsArray,
        animationEntrySpeed,
        animationExitSpeed,
        animationType,
        exitAnimation,
        entryAnimation,
        icon,
        eleValue,
        payload,
        draggable,
        action,
        defaultChecked,
        actionsArray,
        tlborderRadius,
        trborderRadius,
        blborderRadius,
        brborderRadius,
        svgCode
      );
      canvaContent[idx] = { ...newObj };
      handleContent(canvaContent);
      if (!isItemSelected) {
        undoRedoAction();
      }
      if (isDivDropped) {
        if (type === "image") {
          let loader = document.getElementById(`loader${itemSelected}`);
          if (loader !== null && loader !== undefined)
            loader.style.display = "grid";
        }
        undoRedoAction();
      }
      setIsItemSelected(false);
      setIsDivDropped(false);
    },
    [
      bgColor,
      height,
      width,
      rotate,
      textColor,
      borderColor,
      flip,
      opacity,
      textAlign,
      fontSize,
      fontFamily,
      textDecoration,
      fontStyle,
      letterSpacing,
      lineHeight,
      fontWeight,
      border,
      borderRadius,
      layerName,
      placeHolder,
      characterLimit,
      errorMessage,
      name,
      type,
      subType,
      emtoggle,
      cltoggle,
      startTime,
      stopTime,
      pauseToggle,
      resumeToggle,
      pauseTime,
      resumeIn,
      resumeTo,
      seekTo,
      optionsArray,
      animationEntrySpeed,
      animationExitSpeed,
      animationType,
      exitAnimation,
      entryAnimation,
      icon,
      eleValue,
      zIndex,
      payload,
      action,
      defaultChecked,
      actionsArray,
      tlborderRadius,
      trborderRadius,
      blborderRadius,
      brborderRadius,
      svgCode,
    ]
  );
  useEffect(
    function () {
      if (idx == null || itemSelected == null) {
        return;
      }
      let id = canvaContent[idx].id;
      let draggable = canvaContent[idx].draggable;
      let visibility = canvaContent[idx].visibility;
      let newObj = new divObj(
        xcord,
        ycord,
        height,
        width,
        tbgColor,
        rotate,
        id,
        zIndex,
        border,
        // "1px solid",
        startTime,
        stopTime,
        visibility,
        ttextColor,
        tborderColor,
        flip,
        opacity,
        textAlign,
        fontSize,
        fontFamily,
        textDecoration,
        fontStyle,
        fontWeight,
        letterSpacing,
        lineHeight,

        borderRadius,
        layerName,
        cltoggle,
        emtoggle,
        placeHolder,
        characterLimit,
        errorMessage,
        name,
        type,
        subType,
        pauseToggle,
        resumeToggle,
        pauseTime,
        resumeIn,
        resumeTo,
        seekTo,
        optionsArray,
        animationEntrySpeed,
        animationExitSpeed,
        animationType,
        exitAnimation,
        entryAnimation,
        icon,
        eleValue,
        payload,
        draggable,
        action,
        // actionSeek,
        // responseMessage,
        // storeValues,
        // linkTo,
        // windowAction,
        defaultChecked,
        actionsArray,
        tlborderRadius,
        trborderRadius,
        blborderRadius,
        brborderRadius,
        svgCode
      );
      canvaContent[idx] = { ...newObj };
      handleContent(canvaContent);
      // setbgColor(tbgColor);
      // setTextColor(ttextColor);
      // setBorderColor(tborderColor);
      if (!dragging) {
        undoRedoAction();
        setDragging(true);
      }
      setIsItemSelected(false);
      setIsDivDropped(false);
    },
    [tbgColor, ttextColor, tborderColor, xcord, ycord, dragging]
  );
  useEffect(() => {
    setOpen(false);
    // setOpenHiddenVariable(false);
  }, [settingButton]);
  // console.log(canvaContent);
  const handleUpload = async (e, type) => {
    const file = e.target.files[0];
    if (type === "image") {
      if (!file.type.startsWith("image/")) {
        toast.error("Not an Image File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    } else if (type === "video") {
      if (!file.type.startsWith("video/")) {
        toast.error("Not a video File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    } else if (type === "audio") {
      if (!file.type.startsWith("audio/")) {
        toast.error("Not an audio File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("templateid", templateInfo[0]?.TEMPLATEID);
    const pathSegments = window.location.pathname.split("/");
    const urlString = pathSegments[pathSegments.length - 1];
    let action = urlString === "createTemplate" ? "temp" : "live";
    formData.append("action", action);
    formData.append("uploadtype", "content");

    const xhr = new XMLHttpRequest();
    // xhr.upload.addEventListener("progress", (e) => {
    //   if (e.lengthComputable) {
    //     const progress = (e.loaded / e.total) * 100;
    //     setProgressbar(progress);
    //   }
    // });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.STATUS === "1000") {
            let receivedData = JSON.parse(response.DATA);
            console.log("Image/Video/Audio", receivedData);
            let obj = {
              name: receivedData[0].FILENAME,
              path: receivedData[0].FILEPATH,
            };
            let arr = [{ ...obj }];
            setAudioData([...arr]);
            toast.success(response.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.dismiss();
            toast.error(response.DESCRIPTION, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
            // setProgressbar(0);
          }
        } else {
          // toast.dismiss();
          // toast.error("Failed to upload the file.", {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: false,
          //   draggable: false,
          //   progress: undefined,
          //   theme: "dark",
          // });
          // setProgressbar(0);
        }
      }
    };
    xhr.onerror = () => {
      console.error("Error during upload");
      toast.dismiss();
      toast.error("Upload failed", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
      });
      // setProgressbar(0);
    };
    // xhr.open("POST", "http://180.151.195.62:8070/mconnectsaas/upload?", true);
    xhr.open("POST", `${API_BASE_URL}/upload`, true);
    xhr.send(formData);
  };

  const capture = () => {
    // Get the target element to capture

    const style = document.createElement("style");
    document.head.appendChild(style);
    style.sheet?.insertRule(
      "body > div:last-child img { display: inline-block; }"
    );
    const elementToCapture = document.getElementById("drawArea");

    setImageBase64("");
    // Use html2canvas to capture a screenshot
    html2canvas(elementToCapture, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      // Create an image from the canvas
      const screenshot = new Image();
      screenshot.style.width = "100%";
      screenshot.style.height = "100%";
      screenshot.src = canvas.toDataURL("image/png");
      setImageBase64(canvas.toDataURL("image/png"));
      style.remove();
    });
  };

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (type === "image") {
      if (!file.type.startsWith("image/")) {
        toast.error("Not an Image File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    }
    if (file) {
      // Convert the selected image to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Set the base64 data in state
        setImageBase64(reader.result);
      };
    }
  };

  console.log("LayerContent", layerContent);
  console.log("My Result", templateInfo);
  return (
    <div
      className={`w-[300px] h-[calc(100vh-64px)] ${
        layerButton || settingButton || musicButton
          ? ""
          : `overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
      scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]`
      } relative text-[#FFFFFF] bg-[#050506] font-sans right-drawer ${
        !vis ? "open" : ""
      }`}
      onClick={() => {
        setRightClicked("");
      }}
    >
      {!layerButton && !musicButton && !templateDetails && (
        <div>
          <div className="flex gap-3 justify-center items-center mt-2">
          {rightDrawerType.map((item, index) =>
              type !== "hotspot" ? (
                <div
                  onClick={() => setSelectedSidebarItem(index)}
                  className={`${
                    selectedSidebarItem === index
                      ? "text-sm text-[#38c3ff] font-semibold border-solid border-t-0 border-l-0 border-r-0 !border-b-1 z-10 pb-1 !border-b-[#38c3ff] cursor-pointer"
                      : "text-[#DEDEDE]  text-sm font-normal pb-1 cursor-pointer"
                  }`}
                  key={index}
                >
                  {item.name}
                </div>
              ) : (
                (index === 0) && (
                  <div
                    onClick={() => setSelectedSidebarItem(index)}
                    className={`${
                      selectedSidebarItem === index
                        ? "text-sm text-[#38c3ff] font-semibold border-solid border-t-0 border-l-0 border-r-0 border-b-2 z-10 pb-1 !border-b-[#38c3ff] cursor-pointer"
                        : "text-[#DEDEDE]  text-sm font-normal pb-1 cursor-pointer"
                    }`}
                    key={index}
                  >
                    {item.name}
                  </div>
                )
              )
            )}
          </div>
          {/* <Divider className=" absolute top-[44.2px] !m-0 " /> */}
          {selectedSidebarItem === 0 && (
            <ComponentDetail
              itemSelected={itemSelected}
              canvaContent={canvaContent}
              handleContent={handleContent}
              elementProperties={elementProperties}
              idx={idx}
              setIdx={setIdx}
              bgColor={bgColor}
              setbgColor={setbgColor}
              textColor={textColor}
              setTextColor={setTextColor}
              borderColor={borderColor}
              setBorderColor={setBorderColor}
              xcord={xcord}
              setXcord={setXcord}
              ycord={ycord}
              setYcord={setYcord}
              height={height}
              setHeight={setHeight}
              width={width}
              setWidth={setWidth}
              rotate={rotate}
              setRotate={setRotate}
              flip={flip}
              setFlip={setFlip}
              type={type}
              subtype={subType}
              drawAreaWidth={drawAreaWidth}
              drawAreaHeight={drawAreaHeight}
              border={border}
              setBorder={setBorder}
              borderRadius={borderRadius}
              setBorderRadius={setBorderRadius}
              layerName={layerName}
              setLayername={setLayername}
              placeHolder={placeHolder}
              setPlaceholder={setPlaceholder}
              cltoggle={cltoggle}
              setCltoggle={setCltoggle}
              emtoggle={emtoggle}
              setEmtoggle={setEmtoggle}
              characterLimit={characterLimit}
              setCharacterLimit={setCharacterLimit}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              name={name}
              setName={setName}
              eleValue={eleValue}
              setEleValue={setEleValue}
              optionsArray={optionsArray}
              setOptionsArray={setOptionsArray}
              displayTextConfig={displayTextConfig}
              defaultChecked={defaultChecked}
              setDefaultChecked={setDefaultChecked}
              setPayload={setPayload}
              tlborderRadius={tlborderRadius}
              setTLBorderRadius={setTLBorderRadius}
              trborderRadius={trborderRadius}
              setTRBorderRadius={setTRBorderRadius}
              blborderRadius={blborderRadius}
              setBLBorderRadius={setBLBorderRadius}
              brborderRadius={brborderRadius}
              setBRBorderRadius={setBRBorderRadius}
              resizeRatio={resizeRatio}
              setResizeRatio={setResizeRatio}
              link={link}
              setLink={setLink}
              fieldEnabled={fieldEnabled}
              // setFieldEnabled={setFieldEnabled}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isRight={isRight}
              setIsRight={setIsRight}
              showDialog={showDialog}
              setShowDialog={setShowDialog}
              action = {action}
              setAction={setAction}
              actionsArray={actionsArray}
              setActionsArray={setActionsArray}
            />
          )}
          {selectedSidebarItem === 1 && (
            <DesignDetail
              bgColor={bgColor}
              setbgColor={setbgColor}
              tbgColor={tbgColor}
              setTbgColor={setTbgColor}
              textColor={textColor}
              setTextColor={setTextColor}
              ttextColor={ttextColor}
              setTTextColor={setTTextColor}
              borderColor={borderColor}
              setBorderColor={setBorderColor}
              tborderColor={tborderColor}
              setTBorderColor={setTBorderColor}
              itemSelected={itemSelected}
              opacity={opacity}
              setOpacity={setOpacity}
              type={type}
              textAlign={textAlign}
              setTextAlign={setTextAlign}
              fontSize={fontSize}
              setFontSize={setFontSize}
              fontFamily={fontFamily}
              setfontFamily={setfontFamily}
              textDecoration={textDecoration}
              setTextDecoration={setTextDecoration}
              fontStyle={fontStyle}
              setFontStyle={setFontStyle}
              letterSpacing={letterSpacing}
              setLetterSpacing={setLetterSpacing}
              lineHeight={lineHeight}
              setLineHeight={setLineHeight}
              fontWeight={fontWeight}
              setFontWeight={setFontWeight}
              fieldEnabled={fieldEnabled}
              // setFieldEnabled={setFieldEnabled}
            />
          )}
        </div>
      )}
      {layerButton && (
        <div className="font-sans">
          {!timeSetting && !currLayer && (
            <div className=" font-sans">
              <InfiniteScroll
                dataLength={content.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={window.innerHeight - 100}
                loader={<h4>Loading...</h4>}
              >
                {content.map((item, index) => (
                  <div
                    key={index}
                    className="cursor-move"
                    style={{
                      // display : currLayer && currentTime >= item.starttime &&
                      // currentTime <= item.stoptime?"block":"none",
                      // display : settingButton?"display":"none",
                      border:
                        itemSelected === item.id ? "1px solid white" : "none",
                      borderImage:
                        item.id === itemSelected
                          ? "linear-gradient(to right, #A6FFCB, #12D8FA, #1FA2FF)  1 / 1 / 0 stretch"
                          : "none",
                    }}
                    draggable
                    onDragStart={(e) => {
                      dragItem.current = item.id;
                    }}
                    onDragEnter={(e) => {
                      dragOverItem.current = item.id;
                    }}
                    onDragEnd={() => {
                      if (
                        dragOverItem.current === null ||
                        dragItem.current === null ||
                        dragOverItem.current === dragItem.current
                      ) {
                        return;
                      }
                      const findIndexesByIds = (array, id1, id2) => {
                        const index1 = array.findIndex((obj) => obj.id === id1);
                        const index2 = array.findIndex((obj) => obj.id === id2);
                        return [index1, index2];
                      };
                      const [index1, index2] = findIndexesByIds(
                        canvaContent,
                        dragItem.current,
                        dragOverItem.current
                      );
                      if (dragItem.current === itemSelected) {
                        setZIndex(canvaContent[index2].zindex);
                      }
                      if (dragOverItem.current === itemSelected) {
                        setZIndex(canvaContent[index1].zindex);
                      }
                      let z1 = canvaContent[index1].zindex;
                      canvaContent[index1].zindex = canvaContent[index2].zindex;
                      canvaContent[index2].zindex = z1;
                      dragItem.current = null;
                      dragOverItem.current = null;
                      // setLayerContent([..._canvaContent]);
                      handleContent(canvaContent);
                      undoRedoAction();
                    }}
                  >
                    <div className="flex justify-between font-sans h-[50px]">
                      <div className="flex pl-2">
                        <div className="pt-4 pl-2 mr-2">
                          {item.type === "text" ? (
                            <Text1 height="22px" width="22px" />
                          ) : item.type === "label" ? (
                            <Text height="22px" width="22px" />
                          ) : item.type === "assets" ? (
                            <Video height="22px" width="22px" />
                          ) : item.type === "hotspot" ? (
                            <Hotspot height="22px" width="22px" />
                          ) : item.type === "button" ? (
                            <ButtonImg height="22px" width="22px" />
                          ) : (
                            <OptionsList height="22px" width="22px" />
                          )}
                        </div>

                        <div
                          className="text-[13px] truncate"
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            className="w-[140px]"
                            onDoubleClick={() => {
                              if (modalOpen) {
                                return;
                              }
                              setLayerDisabled(item.id);
                              fieldEnabled.current = true;
                            }}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "100%",
                              zIndex: 10000,
                              display:
                                layerDisabled !== item.id ? "block" : "none",
                            }}
                          ></div>
                          <Input
                            id={item.id + "L"}
                            disabled={layerDisabled !== item.id}
                            autoFocus="true"
                            value={item.layername}
                            onClick={() => {
                              fieldEnabled.current = true;
                            }}
                            onChange={(e) => {
                              if (itemSelected === item.id) {
                                setLayername(e.target.value);
                              } else {
                                let idx = canvaContent.findIndex(
                                  (obj) => obj.id === item.id
                                );
                                let flag = false;
                                for (let i = 0; i < canvaContent.length; i++) {
                                  if (canvaContent[i].id !== item.id) {
                                    if (
                                      canvaContent[i].layername ===
                                      e.target.value
                                    ) {
                                      setIsRight(false);
                                      flag = true;
                                      break;
                                    }
                                  }
                                }
                                if (!flag) {
                                  setIsRight(true);
                                }
                                canvaContent[idx].layername = e.target.value;
                                handleContent([...canvaContent]);
                              }
                            }}
                            onBlur={() => {
                              setLayerDisabled(-1);
                              let idx = canvaContent.findIndex(
                                (obj) => obj.id === item.id
                              );
                              if (
                                item.layername === undefined ||
                                item.layername.trim() === ""
                              ) {
                                canvaContent[idx].layername =
                                  item.type + "_" + item.id;
                                handleContent([...canvaContent]);
                                setIsRight(true);
                              }
                              for (let i = 0; i < canvaContent.length; i++) {
                                if (canvaContent[i].id !== item.id) {
                                  if (
                                    canvaContent[i].layername ===
                                    canvaContent[idx].layername
                                  ) {
                                    if (!modalOpen) {
                                      setModalOpen(true);
                                      error(
                                        item.id + "L",
                                        "The Layer Name should be unique.",
                                        "Please change the layer name and ensure that it is unique."
                                      );
                                    }
                                    setIsModalOpen(true);
                                    setLayerDisabled(item.id);
                                    break;
                                  }
                                }
                              }
                              fieldEnabled.current = false;
                            }}
                            className={`${
                              layerDisabled !== item.id
                                ? "!cursor-default border-none !p-0"
                                : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
                            } !resize-none overflow-y-auto scrollbar w-[140px]  h-9 text-[13px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mt-4 mr-3 cursor-pointer">
                          <div
                            height="19px"
                            width="20px"
                            onClick={() => {
                              canvaContent[
                                canvaContent.findIndex(
                                  (obj) => obj.id === item.id
                                )
                              ].visibility =
                                !canvaContent[
                                  canvaContent.findIndex(
                                    (obj) => obj.id === item.id
                                  )
                                ].visibility;
                              handleContent([...canvaContent]);
                              if (itemSelected === item.id) {
                                setItem(null);
                              }
                            }}
                          >
                            {item.visibility ? (
                              <VisON fill="#ffffff" />
                            ) : (
                              <VisOFF fill="#ffffff" />
                            )}
                          </div>
                        </div>
                        <div className="mt-[17px] mr-3 ">
                          <div
                            height="19px"
                            width="20px"
                            onClick={() => {
                              let idx = canvaContent.findIndex(
                                (obj) => obj.id === item.id
                              );
                              if (!canvaContent[idx].visibility) {
                                return;
                              }
                              canvaContent[idx].draggable =
                                !canvaContent[idx].draggable;
                              let flag = canvaContent[0]?.draggable;
                              for (let i = 0; i < canvaContent.length; i++) {
                                if (allLocks.currState === -1) {
                                  if (canvaContent[i].draggable !== flag) {
                                    setAllLocks({
                                      currState: 0,
                                      prevState: -1,
                                    });
                                    break;
                                  }
                                } else if (allLocks.currState === 1) {
                                  if (canvaContent[i].draggable !== flag) {
                                    setAllLocks({ currState: 0, prevState: 1 });
                                    break;
                                  }
                                } else {
                                  if (canvaContent[i].draggable !== flag) {
                                    break;
                                  }
                                  if (
                                    i === canvaContent.length - 1 &&
                                    canvaContent[canvaContent.length - 1]
                                      .draggable === flag
                                  ) {
                                    setAllLocks(
                                      flag
                                        ? { currState: -1, prevState: 0 }
                                        : { currState: 1, prevState: 0 }
                                    );
                                  }
                                }
                              }
                              handleContent([...canvaContent]);
                              if (itemSelected === item.id) {
                                setItem(null);
                              }
                            }}
                          >
                            {item.draggable ? (
                              <Unlock
                                cursor={"pointer"}
                                fill={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            ) : (
                              <Lock
                                cursor={"pointer"}
                                fill={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="template-popover mt-3 mr-4">
                          <Popover
                            placement="bottomLeft"
                            arrow={false}
                            open={openDD === item.id}
                            onOpenChange={handleOpenDD}
                            content={
                              <div key={index} className="">
                                <p
                                  onClick={() => {
                                    setOpenDD(null);
                                    if (item.draggable) {
                                      setItem(item.id);
                                      setTemplateDetails(false);
                                    } else {
                                      setTemplateDetails(true);
                                    }
                                    setLayerButton(!layerButton);
                                    if (
                                      currentTime >= item.starttime &&
                                      currentTime <= item.stoptime
                                    ) {
                                    } else {
                                      setSeekLayer(item.starttime);
                                    }
                                  }}
                                  className=" text-[13px]  px-6 py-2 rounded-sm cursor-pointer  text-left font-semibold"
                                >
                                  Edit Layer
                                </p>
                                <p
                                  onClick={() => {
                                    let layerID = layerContent[index].id;
                                    let idx = canvaContent.findIndex(
                                      (item) => item.id === layerID
                                    );
                                    canvaContent.splice(idx, 1);
                                    setDeleted(true);
                                    undoRedoAction();
                                    setItem(null);
                                    setIndex(null);
                                    handleContent([...canvaContent]);
                                  }}
                                  className=" px-6 text-[13px] py-2 rounded-sm cursor-pointer text-left font-semibold"
                                >
                                  Remove Layer
                                </p>
                              </div>
                            }
                            trigger="click"
                          >
                            <div
                              className="cursor-pointer pt-1"
                              onClick={() => {
                                if (
                                  !canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ].visibility
                                ) {
                                  return;
                                }
                                setOpenDD(layerContent[index].id);
                              }}
                            >
                              <Dots
                                stroke={
                                  canvaContent[
                                    canvaContent.findIndex(
                                      (obj) => obj.id === item.id
                                    )
                                  ]?.visibility
                                    ? "#ffffff"
                                    : "#999"
                                }
                              />
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                    <Divider className=" !m-0" />
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
      )}
      {templateDetails && !layerButton && !settingButton && !musicButton && (
        <div className="mt-6 mb-2 font-sans leading-5 ">
          <div className="ml-2 mr-2 mb-2">Template Info</div>
          <Divider className=" !m-0" />
          <div className="mt-2">
            <div className="flex justify-between">
              <Input
                id="tn"
                disabled={nameDisable}
                value={templateName}
                onClick={() => {
                  fieldEnabled.current = true;
                  nameRef.current = true;
                }}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                  setShowDialog(true);
                }}
                onBlur={() => {
                  nameRef.current = false;
                  setNameDisable(true);
                  fieldEnabled.current = false;
                  setTimeout(() => {
                    nameRef.current = true;
                  }, 500);
                }}
                className={`${
                  nameDisable
                    ? "!cursor-default border-none !p-0"
                    : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
                } !resize-none overflow-y-auto   h-9 w-[280px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
              />
              <div className="h-[30px] w-[30px] mt-3 mr-2 rounded-sm bg-[#3F3F3F] flex justify-center align-middle">
                <Pencil
                  className="mt-1 h-[18px] w-[18px] cursor-pointer"
                  fill={nameDisable ? "#EDEDED" : "#1C68EB"}
                  onClick={() => {
                    if (nameRef.current) {
                      setNameDisable(!nameDisable);
                    }
                    fieldEnabled.current = true;
                  }}
                />
              </div>
            </div>
            {
             thumbCreate === 0 &&  (
              <div className="mt-2 h-[150px] bg-[#1A1A20] ml-2 mr-2">
                <img
                  className="h-full w-full object-contain"
                  src={frame}
                  alt="thumbnail"
                />
              </div>
            ) 
            }
            {thumbCreate>0 && templateInfo.length > 0 && (
              <div className="mt-2 h-[150px] bg-[#1A1A20] ml-2 mr-2">
                <img
                  className="h-full w-full object-contain"
                  src={
                    pathName === "createBrandTemplate"
                      ? `${templateInfo[0]?.BRANDPAGEBASEPATH}TEMP/${templateInfo[0]?.BRANDPAGEID}/SOURCE/thumbnail.png?${id}`
                      : `${templateInfo[0]?.BRANDPAGEBASEPATH}BRANDPAGE/${templateInfo[0]?.BRANDPAGEID}/SOURCE/thumbnail.png?${id}`
                  }
                  alt="thumbnail"
                />
              </div>
            )}
            <div className="mt-3 ml-2 mr-2">
              <div className="text-[14px] mb-2">Update Thumbnail</div>
              <div className="flex">
                <div className="flex mr-4">
                  <input
                    className="mt-1 cursor-pointer"
                    value={0}
                    name="thumnail"
                    checked={thumbnailType === 0}
                    type="radio"
                    onChange={() => {
                      setThumbnailType(0);
                    }}
                    style={{
                      accentColor: "#1C68EB",
                    }}
                  />
                  <div className="ml-2  text-[13px] mt-[2px]">Use Scene</div>
                </div>
                <div className="flex">
                  <input
                    className="mt-1 cursor-pointer"
                    value={1}
                    name="thumnail"
                    checked={thumbnailType === 1}
                    type="radio"
                    onChange={() => {
                      setThumbnailType(1);
                    }}
                    style={{
                      accentColor: "#1C68EB",
                    }}
                  />
                  <div className="ml-2 text-[13px] mt-[2px]">Browse</div>
                </div>
              </div>
              <div className="mt-1">
                <div className="text-[12px] text-[#BABABA]">
                  {thumbnailType === 0
                    ? "Select frame in video on real time and set frame as thumbnail"
                    : "Browse and Upload your own Thumbnail"}
                </div>
                <div className="mt-2">
                  {thumbnailType === 0 ? (
                    <SimpleButton
                      className="bg-[#056DE8] border-none text-[#ffffff] rounded-none"
                      label="Set Thumbnail"
                      onClick={() => {
                        capture();
                        setShowDialog(true);
                      }}
                    />
                  ) : (
                    <div className="flex">
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        onChange={(e) => {
                          handleImageUpload(e, "image");
                          setShowDialog(true);
                        }}
                        ref={thumbnailRef}
                        style={{ display: "none" }}
                      />
                      <SimpleButton
                        className="bg-[#056DE8] border-none text-[#ffffff] rounded-none"
                        label="Browse"
                        onClick={() => thumbnailRef.current.click()}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div className="ml-2 mr-2 flex justify-between">
              <div className="text-[14px]">Description</div>
              <div className="h-[30px] w-[30px] rounded-sm bg-[#3F3F3F] flex justify-center align-middle">
                <Pencil
                  className="mt-1 h-[18px] w-[18px] cursor-pointer"
                  fill={descDisable ? "#EDEDED" : "#1C68EB"}
                  onClick={() => {
                    if (tempDescRef.current) {
                      setDescDisable(!descDisable);
                    }
                    fieldEnabled.current = true;
                  }}
                />
              </div>
            </div>
            <TextArea
              id="td"
              autoSize={true}
              value={templateDescription}
              disabled={descDisable}
              onClick={() => {
                fieldEnabled.current = true;
                tempDescRef.current = true;
              }}
              onChange={(e) => {
                setTemplateDescription(e.target.value);
                setShowDialog(true);
              }}
              onBlur={() => {
                tempDescRef.current = false;
                setDescDisable(true);
                fieldEnabled.current = false;
                setTimeout(() => {
                  tempDescRef.current = true;
                }, 500);
              }}
              className={`${
                descDisable
                  ? "!cursor-default border-none !p-0"
                  : "!cursor-text !bg-[#19191a] border-[#dce2ea]"
              } !resize-none overflow-y-auto   h-9 w-[280px]  !text-[#EBE9E9] !font-sans  rounded-none  mr-2 ml-2 mt-2`}
            />
            <Divider />
            <div className="ml-2 mr-2 flex justify-between">
              <div className="text-[14px]">Base Styling</div>
            </div>
            <div className="ml-2 mr-2 mt-2 bg_menu flex gap-2 items-center">
              <h6 className="w-28 text-left text-[14px]">Base Color</h6>

              <ColorPicker
                className="fill_color"
                format="hex"
                arrow={false}
                value={baseColor}
                style={{
                  borderWidth: "2px",
                }}
                onChange={(e, value, hex) => {
                  setBaseColor(value);
                }}
              />
              <div className="text-[12px] leading-4 ml-1">
                {baseColor.toUpperCase()}
              </div>
            </div>
            <div className="ml-2 mr-2 mt-2 flex gap-2 items-center">
            <div className="text-sm mr-[74px]">Height</div>
            <InputNumber
              className="editor-inum !px-0  w-16 "
              disabled = {isModalOpen}
              readOnly = {!isRight}
              type="number"
              step={1}
              max = {600}
              value={baseHeight}
              onClick={
                ()=>{
                  fieldEnabled.current = true;
                }
              }
              onStep={()=>{
                fieldEnabled.current = true;
              }}
              onBlur={() => {
                if (baseHeight === null || baseHeight < 0) {
                  setBaseHeight(100);
                }
                fieldEnabled.current = false;
              }}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3")
              }
              
              onChange={(value) => {
                  setBaseHeight(value);
              }}
            />
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightSidebarDashboard;
