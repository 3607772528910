//********************* SESSION AND LOCAL STORAGE STARTS *********************************
export const createSession = (sessionUserId) => {
  const sessionId = generateSessionId(); // Generate a unique session ID
  sessionStorage.setItem("sessionId", sessionId);
  sessionStorage.setItem("sessionUserId", sessionUserId);
  // console.log(sessionUserId, "SESSION USER ID")
  return sessionId;
};

const generateSessionId = () => {
  // Generate a unique session ID using any preferred method
  // You can use libraries like uuid to generate a unique ID
  // Here, for simplicity, using a timestamp as the session ID
  return Date.now().toString();
};
//********************* SESSION AND LOCAL STORAGE ENDS *********************************
