import React from 'react';
import { Button } from 'antd'

const GhostButton = ({ type, onClick, size, disabled, htmlType, label, className }) => {
  return (
    <>
        <Button 
            type={type}
            onClick={onClick}
            size={size}
            disabled={disabled}
            htmlType={htmlType}
            className={className}
        >
            {label}
        </Button>
    </>
  )
}

export default GhostButton