import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import Banner from "../common/Banner";
// import newVspagyLogo from "../../assests/commonImages/new-vspagy-logo.svg";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import SimpleButton from "../../common/components/SimpleButton";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PasswordInputBox from "../../common/components/PasswordInputBox";
import { setNewPassword } from "../../api/api_updateData";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

const ResetPassword = () => {

  const [passwordHidden, setPasswordHidden] = useState(true);
  const [validPassword, setValidPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const sessionuserid = localStorage.getItem("sessionUserId");

  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // **************** FOR FUTURE USE WHEN VALIDATE PW WILL APPLY
  // const validatePasswordAndNewPassword = () => {
  //   form.validateFields(["password", "confirm-password"]);
  // };
  const [width, setWidth] = useState(window.innerWidth);

  //calculates width of screen size
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth([window.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Use this useEffect to check if the sendRoute is "/reset-password" before rendering the component
  // useEffect(() => {
  //   if (sendRoute !== `${process.env.REACT_APP_BASE_PATH}/reset-password`) {
  //     navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
  //   } else {
  //     navigate(`${process.env.REACT_APP_BASE_PATH}/reset-password`);
  //   }
  // }, [sendRoute, navigate]);

  const { mutate: setNewPasswordMutation } = useMutation(
    (passwordData) => setNewPassword(passwordData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
          clearSession();
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const handleSetPassword = () => {
    // Convert the password to Base64 using btoa() function
    // const encryptPassword = window.btoa(password); // WILL BE USED IN FUTURE
    const passwordData = {
      custregid: sessionuserid,
      // password: encryptPassword,
      password: confirmPassword,
      sessionuserid: sessionuserid,
    };
    setNewPasswordMutation(passwordData);
    console.log(passwordData, "Change PW");
  };

  //  USED TO CLEAR SESSION WHEN SET PASSWORD FUNCTION CALLED
  const clearSession = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate(`${process.env.REACT_APP_BASE_PATH}/login`); // Redirect to login page after logout
  };

  return (
    <div>
      <div className="h-screen">
        <Row>
          {" "}
          {/* <Col span={width > 750 ? 16 : 24}> */}
          <Col span={width > 1024 ? 16 : 24}>
            <div className="form-wrapper reset-password">
              <div className="w-full">
                <div className="mb-8">
                  <div className="w-[200px] ml-auto mr-auto">
                    <img
                      src={newVspagyLogo}
                      alt="vspagyLogo"
                      className="w-full mb-4"
                    />
                  </div>
                  <div className="font-semibold text-center mt-2 w-full text-[#151515]  text-[26px] select-none">
                    Reset your password
                  </div>
                </div>
              </div>
              <Form
                form={form}
                name="login-form"
                initialValues={{ remember: true }}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <PasswordInputBox
                  setValidPassword={setValidPassword}
                  password={password}
                  setPassword={setPassword}
                  placeholder={'Password'}
                />
                <Form.Item name="confirm-password" className="mb-0 mt-7">
                  <Input.Password
                    iconRender={(visible) =>
                      visible ? (
                        <span onClick={togglePasswordVisibility}>Hide</span>
                      ) : (
                        <span onClick={togglePasswordVisibility}>Show</span>
                      )
                    }
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setShowError(true);
                    }}
                    visibilityToggle
                    className="cursor-pointer h-[44px] text-base"
                    placeholder="Confirm Password"
                  />
                </Form.Item>
                {showError && !confirmPassword && (
                  <span className="text-[#ff4d4f]">Enter confirm password</span>
                )}
                {showError &&
                  confirmPassword &&
                  confirmPassword !== password && (
                    <span className="text-[#ff4d4f]">
                      Password do not match
                    </span>
                  )}
                <Form.Item className="mt-5" shouldUpdate>
                  {() => (
                    <SimpleButton
                      onClick={ () => { 
                        // validatePasswordAndNewPassword();
                        handleSetPassword();}}
                      label="Change Password"
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="rounded w-full mt-3"
                      disabled={!validPassword || password !== confirmPassword}
                      // onClick={validatePasswordAndNewPassword}
                    />
                  )}
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col className="max-[750px]:hidden" span={8}>
            <Banner />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetPassword;
