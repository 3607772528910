import { Modal, QRCode, Tooltip } from 'antd';
import React, { useState } from 'react';
import vspagyLogo from "../../../../assests/vspagy-favicon.png";
import copy from "../../../../assests/commonIcons/copy.svg";

const PreviewLinkGenerateModal = (props) => {
    const link = props.templateprevlink;
  // const link = "www.app.vspagy.com/asdsadsd";
  const [isCopied, setIsCopied] = useState(false);

  // Function to copy the API link to the clipboard
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setIsCopied(true);
  };

  const revertCopy = () => {
    setIsCopied(false);
  };

  const newPreviewLink = () => {
    props.navigateToProjectModule();
  };
  return (
    <>
      <Modal
        className="generate-preview-link-modal"
        maskClosable={false}
        onCancel={() => {
          props.setDialog(false);
          revertCopy();
        }}
        centered
        open={props.dialog}
        width={470}
      >
        <div>
          <div className="mx-4">
            <span className="font-medium text-[#000000] text-xl">
              Preview Link
            </span>
          </div>
          <div className="mx-4 py-1 gap-2 flex justify-between">
            <span className="flex flex-col justify-between w-full">
              <span className="flex w-full mt-2">
                <Tooltip
                  title="Copy link"
                  placement="rightBottom"
                  trigger="hover"
                >
                  <span
                    className="flex items-center justify-between cursor-pointer"
                    onClick={copyToClipboard}
                  >
                    <span className="text-[#0755DB] text-sm font-normal whitespace-nowrap overflow-hidden overflow-ellipsis select-none">
                      {link}
                    </span>
                    {isCopied ? (
                      <>
                        <span className="ml-2 text-[#0755dba3] text-sm font-normal">
                          Copied.
                        </span>
                      </>
                    ) : (
                      <>
                        <img
                          className="ml-2 cursor-pointer"
                          src={copy}
                          alt="copy"
                        />
                      </>
                    )}
                  </span>
                </Tooltip>
              </span>
              <span className="flex flex-col justify-between">
                <span className="text-smm text-[#242424]">
                  If the template is redesigned or any changes have been made
                  then
                </span>
                <span
                  className="mt-2 flex items-center justify-start cursor-pointer text-[#0755DB] font-medium text-sm"
                  onClick={newPreviewLink}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_16687_22856)">
                        <path
                          d="M8.3335 10.833C8.69137 11.3114 9.14796 11.7073 9.67229 11.9938C10.1966 12.2802 10.7764 12.4506 11.3724 12.4933C11.9683 12.5359 12.5665 12.45 13.1263 12.2411C13.6861 12.0323 14.1944 11.7055 14.6168 11.283L17.1168 8.78298C17.8758 7.99714 18.2958 6.94463 18.2863 5.85214C18.2768 4.75965 17.8386 3.7146 17.0661 2.94207C16.2935 2.16953 15.2485 1.73133 14.156 1.72184C13.0635 1.71234 12.011 2.13232 11.2252 2.89131L9.79183 4.31631"
                          stroke="#0755DB"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.6668 9.16702C11.309 8.68858 10.8524 8.2927 10.328 8.00623C9.80371 7.71977 9.22391 7.54942 8.62796 7.50674C8.03201 7.46406 7.43384 7.55004 6.87405 7.75887C6.31425 7.96769 5.8059 8.29446 5.3835 8.71702L2.8835 11.217C2.12451 12.0029 1.70453 13.0554 1.71402 14.1479C1.72352 15.2403 2.16172 16.2854 2.93426 17.0579C3.70679 17.8305 4.75184 18.2687 5.84433 18.2782C6.93681 18.2877 7.98932 17.8677 8.77517 17.1087L10.2002 15.6837"
                          stroke="#0755DB"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_16687_22856">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span className="ml-1">Generate New Link</span>
                </span>
              </span>
            </span>
            <span className="preview-qr flex flex-col">
              <QRCode
                errorLevel="H"
                value={link}
                size="132"
                icon={vspagyLogo}
              />
              <span className="text-[11px] text-[#242424] text-center">
                Scan the code to test
              </span>
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PreviewLinkGenerateModal