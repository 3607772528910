import React, { useState } from "react";
import { Form, Input, Spin } from "antd";
import SimpleButton from "../../common/components/SimpleButton";
import PasswordInputBox from "../../common/components/PasswordInputBox";
import { changePassword } from "../../api/api_updateData";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useSessionChecker from "../../common/functions/useSessionChecker";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import useCurrentUrl from "../../common/functions/useCurrentUrl";

const ChangePassword = () => {
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  const [validPassword, setValidPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  // const [isSubmitted, setIsSubmitted] = useState(false); // state to track successful submission
  const [showSpinner, setShowSpinner] = useState(false);

  const sessionuserid = localStorage.getItem("sessionUserId");

  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };

  const { mutate: changePasswordMutation } = useMutation(
    (passwordData) => changePassword(passwordData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          setShowSpinner(true);
          // Remove blur effect after 2 seconds
          setTimeout(()=>{
            setShowSpinner(false);
            setCurrentPassword("");
            setPassword("");
            setConfirmPassword("");
            setShowError(false);
            toast.success(data.DESCRIPTION);
          },2000)
          

        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  if (showSpinner) {
    return (<>
      <div
          className="overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: "rgb(0, 0, 0,0.5)",
            opacity : 1,
            zIndex: 1000,
            cursor: 'wait',
          }}
        ></div>
        <span className="text-center top-1/2 left-1/2 absolute z-[10000000]"><Spin size="large"/></span>
    </>);
  }

  const handleSetPassword = () => {
    // debugger
    // Convert the password to Base64 using btoa() function
    // const encryptPassword = window.btoa(password); // WILL BE USED IN FUTURE
    const passwordData = {
      custregid: sessionuserid,
      // password: encryptPassword,
      oldpassword: currentPassword,
      newpassword: confirmPassword,
      updatedby: sessionuserid,
    };
    changePasswordMutation(passwordData);
    console.log(passwordData, "Change PW");
  };

  return (
    <div className="flex flex-col mb-8">
      <Form>
        <Form.Item>
          <div className="font-medium mb-2 text-sm text-[#131313]">
            Current Password
          </div>
          <Input.Password
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone onClick={togglePasswordVisibility} />
              ) : (
                <EyeInvisibleOutlined
                  onClick={togglePasswordVisibility}
                />
              )
            }
            visibilityToggle
            className="cursor-pointer md:w-96  h-[40px] text-base !rounded-[4px]"
          />
        </Form.Item>
        <div className="!mb-2 font-medium !text-sm text-[#131313]">
          New Password
        </div>
        <div className="md:w-96">
        <PasswordInputBox
          setValidPassword={setValidPassword}
          password={password}
          setPassword={setPassword}
          placeholder={placeholder}
          setPlaceholder={setPlaceholder}
        />
        </div>
        
        <div className="!mb-2 mt-6  font-medium text-sm text-[#131313]">
          Confirm New Password
        </div>
        <Form.Item name="confirmPassword" className="mb-0">
          <Input.Password
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone onClick={togglePasswordVisibility} />
              ) : (
                <EyeInvisibleOutlined
                  onClick={togglePasswordVisibility}
                />
              )
            }
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setShowError(true);
            }}
            visibilityToggle
            className="cursor-pointer md:w-96 h-[40px] text-base !rounded-[4px]"
          />
        </Form.Item>
        {showError && !confirmPassword && (
          <span className="text-[#ff4d4f]">Enter confirm password</span>
        )}
        {showError && confirmPassword && confirmPassword !== password && (
          <span className="text-[#ff4d4f]">Password do not match</span>
        )}
        <div className="flex md:w-96 justify-end space-x-4 mt-12">
          <SimpleButton
            size="large"
            htmlType="submit"
            label="Save Changes"
            className="!rounded-[4px] bg-secondary-blue hover:bg-primary-blue"
            type="primary"
            onClick={() => {
              handleSetPassword();
            }}
            disabled={
              password !== confirmPassword || !validPassword || !currentPassword
            }
          />
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;