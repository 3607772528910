import React from "react";
import Navbar from "../Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import ProfileHeader from "../../userModule/profile/profileLayout/ProfileHeader";
import ProfileSidebar from "../../userModule/profile/profileLayout/ProfileSidebar";
import { useQuery } from "react-query";
import { getCustomerInfo } from "../../api/api_FetchData";
import { Spin } from "antd";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import useSessionChecker from "../../common/functions/useSessionChecker";
import useWindowWidth from "../../common/hooks/useWindowWidth";

const UserLayout = ({custregid}) => {
  custregid = localStorage.getItem("sessionUserId");
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();


  const {
    data: custData,
    isLoading,
    isError,
  } = useQuery(
    ["customerinfo", custregid],
    () => getCustomerInfo(null, custregid, "/customer"),
    {
      staleTime: 10 * 60 * 1000,
    }
  );

  // Conditional rendering based on loading and error states
  if (isLoading) {
    // Show a loading spinner or message while data is loading
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  if (isError) {
    // Show an error message if there is an error fetching data
    return <div>Error loading data.</div>;
  }
  
  if (custData.STATUS === "-1001") {
    // Show an error message if there is an error fetching data
    return <div>Error loading data.</div>;
  }
  const customername = custData.data[0].CUSTOMERNAME;
  

  return (
    <div>
      <div>
        <Navbar />
        <div className={`bg-[#F7FAFC] h-[calc(100vh_-_60px)] ${isDesktop ? "pr-[32px] pl-[232px]" : "px-6"} pt-9   scrollbar scrollbar-w-[7px] scrollbar-thumb-rounded-full scrollbar-thumb-[#CCCCCC] scrollbar-track-[#EFEFFA] overflow-y-scroll`}>
          <ProfileHeader customername={customername} />
          <div className={`${isDesktop ? "flex mt-16" : "mt-7"}`}>
            <ProfileSidebar />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
