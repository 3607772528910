import React, { useEffect, useState } from "react";
import InputBox from "../../common/components/InputBox";

// COMPONENT IMPORT
import noTemplateSvg from "../../assests/commonImages/noTemplateSvg.svg";
// API IMPORT
import { useQuery } from "react-query";
import { getAllUserTemplate, getCustomerInfo } from "../../api/api_FetchData";
import UserTemplateCard from "./UserTemplateCard";
import { Select, Spin } from "antd";
import CreateTemplateModal from "../../common/components/Modal/template/createTemplate/CreateTemplateModal";
import "../../common/styles/commonStyles.css";
import useSessionChecker from "../../common/functions/useSessionChecker";
import { useNavigate } from "react-router-dom";
import { useCustomerInfo } from "../../common/functions/useCustomerInfo";

import noAssetImage from "../../assests/commonIcons/assetImages/noAssetSvg.svg";
import SimpleButton from "../../common/components/SimpleButton";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import { toast } from "react-toastify";
import useWindowWidth from "../../common/hooks/useWindowWidth";

const UserTemplateLayout = ({ sessionuserid, custregid }) => {
  custregid = localStorage.getItem("sessionUserId");
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  sessionuserid = localStorage.getItem("sessionUserId");

  const [searchTerm, setSearchTerm] = useState("");
  const [sortingOption, setSortingOption] = useState("Last Modified"); // State to hold sorting option
  const [createTempModal, setCreateTempModal] = useState(false);
  const [showSuffixIcon, setShowSuffixIcon] = useState(false);
  const [prefixActive, setPrefixActive] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;

  const { data, isLoading, isError, refetch } = useQuery(
    ["userTemplate", sessionuserid],
    () => getAllUserTemplate(sessionuserid, null, "/template"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        // console.log(data.status, "DATA");
        if (data.status === "-2001") {
          sessionStorage.clear();
          localStorage.clear();
          navigate(`${process.env.REACT_APP_BASE_PATH}/logout`);
          toast.error(data.description);
        }
        // debugger
      },
    }
  );

  const { customerInfo, setCustomerInfo } = useCustomerInfo();

  // debugger

  const { refetch: custRefetch } = useQuery(
    ["customerinfo", custregid],
    () => getCustomerInfo(null, custregid, "/customer"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (fetchedData) => {
        setCustomerInfo(fetchedData);
      },
    }
  );

  const customerOwner = customerInfo?.data[0].CUSTOMEROWNER || "";
  // console.log(customerOwner, "customerOwner");

  useEffect(() => {
    // Call the refetch function when the component mount
    refetch();
    custRefetch();
  }, [refetch, custRefetch]);

  const handleRefresh = () => {
    setIsSpinning(true); // Show the spinner

    setTimeout(() => {
      setIsSpinning(false); // Hide the spinner after 1 second
    }, 1200);

    refetch(); // Refetch the data after a successful upload
    custRefetch();
  };

  if (isLoading) {
    return (
      <div className="m-auto">
        <span>
          <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
            <Spin tip="Loading..." />
          </div>
        </span>
      </div>
    );
  }

  if (isError) {
    return <div>Error loading data.</div>;
  }

  // Now, you can safely access the data
  const userTemplates = data?.data || [];
  const totalTemplateCount = userTemplates.length;

  const navigateToTemplate = () => {
    // debugger
    navigate(`${process.env.REACT_APP_BASE_PATH}/global-template`);
  };

  // Define a variable to check if there are matching templates
  const hasMatchingTemplates = userTemplates.some((template) => {
    if (
      template &&
      template.TEMPLATENAME !== undefined &&
      template.TEMPLATEOWNER !== undefined
    ) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return (
        (template.TEMPLATENAME &&
          template.TEMPLATENAME.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (template.TEMPLATEOWNER &&
          template.TEMPLATEOWNER.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (template.TEMPLATEID &&
          template.TEMPLATEID.toLowerCase().includes(lowerCaseSearchTerm))
      );
    }
    return false;
  });

  return (
    <>
      {data.status === "1000" && userTemplates[0] === null ? (
        <span className="text-lg text-red-500">Error loading data.</span>
      ) : (
        <></>
      )}
      <div
        className={`${
          isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"
        }  h-[calc(100vh_-_3.75rem)] flex flex-col overflow-y-hidden`}
      >
        {isLargeScreen ? (
          <>
            <div className="w-full h-[6rem] bg-[#F7FAFC] ">
              <div className="pt-9 pl-11 pr-8 pb-4">
                <div className="flex gap-5 justify-between">
                  <div className="text-[22px] font-semibold">My Templates</div>
                  <div className="flex gap-6 items-center">
                    <div className="template-select">
                    {customerOwner === "customeradmin@vspagy.com" ? (
                    <Select
                    defaultValue="Last Modified"
                    bordered={false}
                    style={{ width: 130 }}
                    value={sortingOption}
                    onChange={(value) => setSortingOption(value)}
                    options={[
                      { value: "Last Modified", label: "Last Modified" },
                      { value: "Name Ascending", label: "A-Z" },
                      { value: "Name Descending", label: "Z-A" },
                      { value: "Global", label: "Global" },
                      { value: "Non-Global", label: "Non-Global" },
                    ]}
                  />
                  ) : (
                    <Select
                    defaultValue="Last Modified"
                    bordered={false}
                    style={{ width: 130 }}
                    value={sortingOption}
                    onChange={(value) => setSortingOption(value)}
                    options={[
                      { value: "Last Modified", label: "Last Modified" },
                      { value: "Name Ascending", label: "A-Z" },
                      { value: "Name Descending", label: "Z-A" },
                    ]}
                  />
                  )}
                    </div>
                    <InputBox
                      searchIconClass="px-1 h-[15px] w-[23px]"
                      xIconClass="p-[1px]"
                      className="h-9 w-72 rounded-3xl global-search"
                      placeholder="Search templates..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onClearClick={() => {
                        setSearchTerm(""); // Clear the input value
                      }}
                      showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                      prefixActive={prefixActive} // Pass prefixActive prop
                      setShowSuffixIcon={setShowSuffixIcon}
                      setPrefixActive={setPrefixActive}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full h-[10rem] bg-[#F7FAFC] ">
              <div className="pt-7 pb-4">
                <div className="flex flex-col  pl-8 pr-6">
                  <div className="text-[20px] font-medium ml-2">
                    My Templates
                  </div>
                  <div className="mt-3">
                    <InputBox
                      searchIconClass="px-1 h-[15px] w-[23px]"
                      xIconClass="p-[1px]"
                      className={`h-9 ${
                        windowWidth > 500 ? "w-80" : "w-full"
                      } rounded-3xl global-search`}
                      placeholder="Search templates..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onClearClick={() => {
                        setSearchTerm(""); // Clear the input value
                      }}
                      showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                      prefixActive={prefixActive} // Pass prefixActive prop
                      setShowSuffixIcon={setShowSuffixIcon}
                      setPrefixActive={setPrefixActive}
                    />
                  </div>
                </div>
                <div className="mt-4 pl-8 pr-6" style={{ borderTop: "1px solid #D9D9D9", borderBottom: "1px solid #D9D9D9" }}>
                  <div className="template-select pt-1">
                  {customerOwner === "customeradmin@vspagy.com" ? (
                    <Select
                    defaultValue="Last Modified"
                    bordered={false}
                    style={{ width: 130 }}
                    value={sortingOption}
                    onChange={(value) => setSortingOption(value)}
                    options={[
                      { value: "Last Modified", label: "Last Modified" },
                      { value: "Name Ascending", label: "A-Z" },
                      { value: "Name Descending", label: "Z-A" },
                      { value: "Global", label: "Global" },
                      { value: "Non-Global", label: "Non-Global" },
                    ]}
                  />
                  ) : (
                    <Select
                    defaultValue="Last Modified"
                    bordered={false}
                    style={{ width: 130 }}
                    value={sortingOption}
                    onChange={(value) => setSortingOption(value)}
                    options={[
                      { value: "Last Modified", label: "Last Modified" },
                      { value: "Name Ascending", label: "A-Z" },
                      { value: "Name Descending", label: "Z-A" },
                    ]}
                  />
                  )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <>
          <div
            className={`w-full h-[100%] overflow-auto bg-[#F7FAFC] scrollbar-w-[4px] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] border-[#DADDEF] ${
              isLargeScreen ? "pl-11 pr-8" : "pl-7 pr-6 pb-[55px]"
            } pr-8 pb-8 pt-6 flex flex-wrap gap-4`}
          >
            <div className="flex flex-wrap gap-10 w-full">
              {userTemplates.length === 0 ? (
                <div
                  className={`${
                    isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"
                  } flex flex-col items-center pt-12`}
                >
                  <span className="text-center flex justify-center">
                    <img src={noTemplateSvg} alt="template svg" className={` ${isLargeScreen ? "" : "w-[120px]"}`} />
                  </span>
                  <span className={`${isLargeScreen ? "text-xl" : "text-[17px]"} text-[#4B4B4B] font-medium pt-4`}>
                    No templates found yet!
                  </span>
                  <span className={`text-[#7A7A7A] ${isLargeScreen ? "text-[15px] w-[480px]" : "text-[12px]"} font-normal  leading-5 text-center pt-1`}>
                    It’s time to create your first Template. Choose the template
                    you need from template library or create one yourself from
                    scratch. Ready get set goooo!
                  </span>
                  <span className="pt-4">
                    <SimpleButton
                      type="default"
                      label="Browse Template Library"
                      className="rounded-[4px] bg-transparent border-[#1C68EB] text-[#1C68EB] hover:bg-gradient-to-r from-[#f6f9f6] to-[#F2F7FF] hover:shadow-md hover:shadow-[#155fff14]"
                      onClick={navigateToTemplate}
                    />
                  </span>
                </div>
              ) : !hasMatchingTemplates ? (
                <div
                  className={`${
                    isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"
                  } flex flex-col items-center pt-20`}
                >
                  <span className="text-center flex justify-center">
                    <img src={noAssetImage} alt="no asset svg" className={` ${isLargeScreen ? "" : "w-[120px]"}`}/>
                  </span>
                  <span className={`${isLargeScreen ? "text-xl" : "text-[17px]"} text-[#4B4B4B] font-medium pt-4`}>
                    No matching templates found
                  </span>
                </div>
              ) : (
                data.data
                  ?.filter((template) => {
                    if (
                      template &&
                      template.TEMPLATENAME !== undefined &&
                      template.TEMPLATEOWNER !== undefined
                    ) {
                      const lowerCaseSearchTerm = searchTerm.toLowerCase();
                      return (
                        (template.TEMPLATENAME &&
                          template.TEMPLATENAME.toLowerCase().includes(
                            lowerCaseSearchTerm
                          )) ||
                        (template.TEMPLATEOWNER &&
                          template.TEMPLATEOWNER.toLowerCase().includes(
                            lowerCaseSearchTerm
                          )) ||
                        (template.TEMPLATEID &&
                          template.TEMPLATEID.toLowerCase().includes(
                            lowerCaseSearchTerm
                          ))
                      );
                    }
                    return false;
                  })
                  .sort((a, b) => {
                    if (sortingOption === "Last Modified") {
                      return (
                        b.TEMPLATEUPDATEDON?.localeCompare(
                          a.TEMPLATEUPDATEDON
                        ) || 0
                      );
                    } else if (sortingOption === "Name Ascending") {
                      return a.TEMPLATENAME?.localeCompare(b.TEMPLATENAME) || 0;
                    } else if (sortingOption === "Name Descending") {
                      return b.TEMPLATENAME?.localeCompare(a.TEMPLATENAME) || 0;
                    } else if (sortingOption === "Global") {
                      return b.ISGLOBAL?.localeCompare(a.ISGLOBAL) || 0;
                    } else if (sortingOption === "Non-Global") {
                      return a.ISGLOBAL?.localeCompare(b.ISGLOBAL) || 0;
                    }

                    return 0;
                  })
                  .map((template) => (
                    <UserTemplateCard
                      key={template.TEMPLATEID}
                      template={template}
                      onRefreshSuccess={handleRefresh}
                      customerOwner={customerOwner}
                    />
                  ))
              )}
            </div>
          </div>

          <div className="fixed bottom-4 right-4 bg-white text-black rounded-full px-3 py-2 shadow-md select-none">
            <span className="text-smm font-bold">
              Total Templates: {totalTemplateCount}
            </span>
          </div>
        </>
        {isSpinning && (
          <>
            <div
              className="overlay"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(0, 0, 0,0.5)",
                opacity: 1,
                zIndex: 1000,
                cursor: "wait",
              }}
            ></div>
            <span className="text-center top-1/2 left-1/2 absolute z-[10000000] cursor-wait">
              <Spin size="large" />
            </span>
          </>
        )}
      </div>
      {/* <div className="fixed bottom-4 right-4 bg-white text-black rounded-full px-3 py-2 shadow-md select-none">
        <span className="text-smm font-bold">
          Total Templates: {totalTemplateCount}
        </span>
      </div> */}
      <CreateTemplateModal
        modal={createTempModal}
        setModal={setCreateTempModal}
      />
    </>
  );
};

export default UserTemplateLayout;
