import { Modal } from "antd";
import React, { useState } from "react";
import SimpleButtonIcon from "../../common/components/SimpleButtonIcon";
import PlusCircles from "../../assests/commonIcons/userModuleIcons/plus-circle.svg";
import Minus from "../../assests/commonIcons/userModuleIcons/minus.svg";
import { ReactComponent as PlusImg } from "../../assests/commonIcons/userModuleIcons/plus-circle.svg";
import { ReactComponent as CancelImg } from "../../assests/commonIcons/userModuleIcons/x-circle.svg";
import "../../common/styles/userModule/Teammates.css";
const TeammatesDialog = (props) => {
  const [sameEmail, setsameEmail] = React.useState([]);
  const [, setName] = useState([]);
  const [, setEmail] = useState([]);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [inputValue, setInputValue] = useState([
    { id: 0, name: "", email: "", member: false  },
  ]);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const validateName = (value) => {
    const isValid = value.length >= 3;
    setIsValidName(isValid);
  };

  const validateEmail = (value) => {
    const isValid = emailRegex.test(value);
    setIsValidEmail(isValid);
  };
   
  const handleValidation = (arr)=>{
    setIsValidEmail(true);
    setIsValidName(true);
    arr.forEach((item, index) => {
      if(item.name.length <= 3){
        setIsValidName(false);
      }
      if(emailRegex.test(item.email)===false){
        setIsValidEmail(false);
      }
    });
  }

  const checkEmailSimilar = (value,id)=>{
    let check = true;
    inputValue.forEach((obj, index) => {
      if(obj.email === value&& index!==id ){
        sameEmail.push(id)
        setsameEmail([...sameEmail]);
        setIsValidEmail(false);
        check = false;
      }
    })
    props.member.forEach((obj,index)=>{
      if(obj.email === value){
        sameEmail.push(id);
        setsameEmail([...sameEmail]);
        setIsValidEmail(false);
        check = false;
      }
    })
    if(check){
      let arr = sameEmail.filter((v)=>v!==id);
      setsameEmail([...arr]);
    }
  }

  const handleNameChange = (event, index) => {
    const value = event.target.value;
    setName((inputValue[index].name = value));
    validateName(value,index);
  };

  const handleEmailChange = (event, index) => {
    const value = event.target.value;
    setEmail((inputValue[index].email = value));
    validateEmail(value,index);
    checkEmailSimilar(value,index);
  };
  console.log(sameEmail);

  return (
    <>
      <br />
      <Modal
        className="InviteModal"
        width={700}
        maskClosable={false}
        onCancel={() => {props.setDialog(false)
          setInputValue([{
            id: 0,
            name: "",
            email: "",
            member: false,
          }])
          setIsValidEmail(false);
          setIsValidName(false);
          setsameEmail([]);
        }}
        centered
        open={props.dialog}
      >
        <div className="pl-[25px]">
          <div className=" text-[#000000] text-[28px]  font-bold">
            Who's on your team?
          </div>
          <div className=" text-[#757575] font-normal text-[15px] mt-2">
            Team allows multiple people to work with you without having
            additional plans or charges
          </div>
          <div className="text-[#3A3A3A] mb-4 font-medium text-sm mt-1">
            Invite Members
          </div>
          <div>
            {inputValue.map((obj, index) => {
              return (
                <>
                <div className="mb-[14px] flex">
                  <input
                    type="email"
                    value={obj.email}
                    onChange={(e) => {
                      handleEmailChange(e, index);
                    }}
                    placeholder="Email ID"
                    autocomplete="off"
                    disabled = {sameEmail.length===0?false:(sameEmail.includes(index)?false:true)}
                    onFocus={(event) => event.target.classList.add("focused")}
                    onBlur={(event) => {event.target.classList.remove("focused")
                  }
                  }
                    style={{
                      paddingLeft: "8px",
                      width: "245px",
                      height: "40px",
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      backgroundColor: "#FFFFFF",
                      color: "#000000",
                      marginRight: "18px",
                      cursor : sameEmail.length===0?"text":(sameEmail.includes(index)?"text":"not-allowed")
                    }}
                  />
                  <input
                    type="text"
                    value={obj.name}
                    onChange={(e) => {
                      handleNameChange(e, index);
                    }}
                    placeholder="Name"
                    onFocus={(event) => event.target.classList.add("focused")}
                    onBlur={(event) => event.target.classList.remove("focused")}
                    autocomplete="off"
                    style={{
                      paddingLeft: "8px",
                      width: "245px",
                      height: "40px",
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      backgroundColor: "#FFFFFF",
                      color: "#000000",
                      marginRight: "18px",
                    }}
                  />
                  <img
                    src={Minus}
                    alt="icon"
                    className={
                      inputValue.length === 1
                        ? "hidden"
                        : "block cursor-pointer"
                    }
                    onClick={() => {
                      let sarr  = [];
                      // for (let i = 0; i < sameEmail.length; i++){
                      //   if(sameEmail[i]<index){
                      //     sarr.push(sameEmail[i]);
                      //   }
                      //   else if(sameEmail[i]>index){
                      //     sarr.push(sameEmail[i] -1);
                      //   }
                      // }
                      setsameEmail([...sarr]);
                      let arr = [...inputValue];
                      arr.splice(index, 1);
                      setInputValue([...arr]);
                      handleValidation(arr)
                    }}
                  />
                </div>
                <div
                className={sameEmail.includes(index) ? "flex mt-[12px] mb-[20px] ml-[2px]" : "hidden"}
              >
                {React.cloneElement(<CancelImg />, {
                  fill: isValidEmail && isValidName ? "#1560E1" : "#9C9C9C",
                })}
                <div className={"text-[#E31616] font-normal text-[12px] ml-[8px]"}>
                  This email is already in your team
                </div>
              </div>
              </>
              );
            })}
          </div>
          {/* <div
            className={sameEmail ? "flex mt-[12px] mb-[20px] ml-[2px]" : "hidden"}
          >
            {React.cloneElement(<CancelImg />, {
              fill: isValidEmail && isValidName ? "#1560E1" : "#9C9C9C",
            })}
            <div className={"text-[#E31616] font-normal text-[12px] ml-[8px]"}>
              This email is already in another team
            </div>
          </div> */}
          <div className="flex mt-4">
            {React.cloneElement(
              <PlusImg
                onClick={() => {
                  if (isValidEmail && isValidName && sameEmail.length===0) {
                    let arr = [...inputValue];
                    arr.push({
                      id: inputValue.length + 1,
                      name: "",
                      email: "",
                      member: false,
                    });
                    setInputValue([...arr]);
                    setIsValidEmail(false);
                    setIsValidName(false);
                  }
                }}
              />,
              {
                fill: isValidEmail && isValidName && sameEmail.length===0 ? "#1560E1" : "#9C9C9C",
                cursor: isValidEmail && isValidName && sameEmail.length===0 ? "pointer" : "normal",
              }
            )}
            <div
              className={
                isValidEmail && isValidName && sameEmail.length===0
                  ? "text-[#1C68EB] font-normal text-sm ml-[8px]"
                  : "text-[#9C9C9C] font-normal text-sm ml-[8px]"
              }
            >
              Add more member
            </div>
          </div>

          <div className="flex gap-8 mb-4 justify-center">
            <SimpleButtonIcon
              onClick={() => {
                let arr = [...props.member, ...inputValue];
                props.setMember(arr);
                props.setDialog(false);
                setInputValue([{
                  id: 0,
                  name: "",
                  email: "",
                  member: false,
                }])
                setIsValidEmail(false);
                setIsValidName(false);
              }}
              label={"Invite"}
			  type={"primary"}
              image={PlusCircles}
              className={"text-[#FFFFFF] font-semibold"}
              buttonclassName={
                isValidEmail && isValidName && sameEmail.length===0
                  ? "!bg-[#1C68EB] mt-[40px] ml-[500px]"
                  : "!bg-[#e9e2e2] mt-[40px] ml-[500px]"
              }
              disabled={isValidEmail && isValidName && sameEmail.length===0 ? false : true}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default TeammatesDialog;
