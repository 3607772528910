import React from "react";

const CustomTable = ({ header, data, searchData }) => {
  return (
    <div className="border border-gray-300 overflow-x-auto">
      <div className="text-[#252750] text-[15px] font-medium pb-3 border-t-4">
        {header}
      </div>
      <table className="min-w-full">
        <thead>
          <tr className="bg-[#F6F6FF]">
            <th className="px-6 py-2.5 sm:px-4 sm:py-2 text-left text-[#3D3D40] bg-[#EAEBFF] text-sm font-medium">
              Label
            </th>
            <th className="px-6 py-2.5 sm:px-4 sm:py-2 text-left text-[#3D3D40] bg-[#EAEBFF] text-sm font-medium">
              Counts
            </th>
          </tr>
        </thead>
        <tbody>
          {(searchData === null && data && data.length > 0
            ? data
            : searchData
          )?.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-[#F9F9F9]" : "bg-[#F1F1F1]"}
            >
              <td className="px-6 py-2.5 sm:px-4 sm:py-2 text-black text-sm font-normal">
                {item.LABEL || item.CITY}
              </td>
              <td className="px-6 py-2.5 sm:px-4 sm:py-2 text-black text-sm font-normal">
                {item.total}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
