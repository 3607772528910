import React, { useEffect } from "react";
import { Button, Drawer, Space } from "antd";
import "../common/styles/commonStyles.css";
import compass from "../assests/commonIcons/HelpDrawerCompass.svg";
import helpIcon from "../assests/commonIcons/helpIcon.svg";
import groupIcon from "../assests/commonIcons/GroupIconHelpDrawer.svg";
import img1 from "../assests/commonImages/helpDrawerImg1.png";
import img2 from "../assests/commonImages/helpDrawerImg2.png";
import img3 from "../assests/commonImages/helpDrawerImg3.png"
import resize from "../assests/commonIcons/resizeHelpDrawer.svg";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

const HelpNavbar = ({ customername, visible, onClose }) => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const OPEN_HELPDESK_URL = window.helpdeskurl;
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const drawerElement = document.querySelector(".ant-drawer-wrapper-body");
      const drawerIcon = document.querySelector(".helpIconNav");

      // Check if the drawer is open and the click is outside the drawer
      if (visible && drawerElement &&  drawerIcon && !drawerIcon.contains(event.target) && !drawerElement.contains(event.target)) {
        setSelectedTopic(null);
        onClose();
      }
      
    };

    // Add event listener when the drawer is open
    if (visible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when the component unmounts or the drawer closes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visible, onClose]);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const handleBack = () => {
    setSelectedTopic(null);
  };

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.realIndex);
  };

  const slideYouTubeVideo1 = () => {
    window.open("https://youtu.be/iFExnDpMNJ4?si=Adf-9fK1tvHG5FhK", "_blank");
  };

  const slideYouTubeVideo2 = () => {
    window.open("https://youtu.be/bwqPbWeSoIE?si=lKXfvQZtEptRLZC5", "_blank");
  };

  const slideYouTubeVideo3 = () => {
    window.open("https://www.youtube.com/watch?v=AXSgVx6mfhY", "_blank");
  };

  const topicYouTubeVideo = () => {
    window.open("https://www.youtube.com/@VSPAGY", "_blank");
  };

  const handleOpenHelpPage = () => {
    // Specify the URL you want to open in a new tab
    const url = `${OPEN_HELPDESK_URL}/helppage`;

    // Open the link in a new tab
    window.open(url, "_blank");
  };

  return (
    <>
      <div>
        <Drawer
          title={
            selectedTopic ? (
              <Space>
                <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={handleBack}
                  style={{
                    border: "none",
                    background: "transparent",
                    position: "relative",
                    left: "-24px",
                    top: "-2px",
                    boxShadow: "none",
                  }}
                />
                <span className="relative left-[-23px]">Help</span>
              </Space>
            ) : (
              "Help"
            )
          }
          placement="right"
          open={visible}
          headerStyle={{ borderBottom: "1px solid #CCCACA" }}
          onClose={() => {
            setSelectedTopic(null);
            onClose();
          }}
          rootClassName="HelpDrawer top-[60px]"
          mask={false} // Disable the background overlay
          maskClosable={true} // A
        >
          {selectedTopic ? (
            // ----------Topics Different pages -------------------
            <div>
              {selectedTopic === "Getting Started" && (
                // ----------- Getting started ----------------------
                <div className="pt-4 pb-[20px] px-5 flex flex-col">
                  <span className="font-[700] text-[20px]">
                    Getting Started
                  </span>
                  <div className=" pt-4 pl-3">
                    <div className="flex flex-col">
                      <span className="font-[700] text-[16px]">
                        What is VSPAGY?
                      </span>
                      <span className="pt-2 leading-[20px]">
                        VSPAGY platform, designed to enhance your engagement
                        with customers and create unique, tailored experiences.
                        This innovative platform empowers users to generate
                        personalized links for their audience in just a few
                        simple steps.
                      </span>
                      <span className="pt-5">
                        <ul style={{ listStyleType: "square" }}>
                          <li>Template Creation</li>
                          <li className="pt-1">Media Integration</li>
                          <li className="pt-1">File Upload/API</li>
                          <li className="pt-1">Link Generation</li>
                          <li className="pt-1">Performance Monitoring</li>
                        </ul>
                      </span>
                    </div>
                    <div className="flex flex-col pt-3">
                      <span className="font-[700] text-[16px]">
                        Making most of VSPAGY
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Personalization and Interactivity stands as a potent
                        tool in the realm of marketing, and it's no wonder that
                        three out of four marketers are integrating some form of
                        personalization into their campaigns. The reason is
                        simple—it delivers results!
                      </span>
                      <span className="pt-5 leading-[20px]">
                        Most marketers are already employing basic
                        personalization like "Hi {customername}" in their
                        communications. However, this fundamental form of
                        personalization has become not only widespread but also
                        the standard. As a result, this basic level of
                        personalization no longer holds the charm of being
                        'special' or distinct. This is where VSPAGY comes into
                        play...
                      </span>
                      <span className="pt-5 leading-[20px]">
                        VSPAGY enables you to craft hyper-personalized images,
                        GIFs and videos for each and every contact in your
                        list, even if that list runs into the millions!
                      </span>
                    </div>
                    <div className="pt-5 flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Ready to keep Learning?
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Find detailed how-to articles and learning tutorials in
                        our Help Center (hey, you're already here!).
                      </span>
                    </div>
                    {/* <div
                      className="mt-5 flex text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                      onClick={handleOpenHelpPage}
                    >
                      <span className="">View in our Help Center</span>
                      <span className="ml-1">
                        <img src={resize} width={13} height={13} alt="resize" />
                      </span>
                    </div> */}
                    <div className="flex">
                      <div
                        className="mt-3 flex items-center text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                        onClick={handleOpenHelpPage}
                      >
                        <span className="">View in our Help Center</span>
                        <span className="ml-1">
                          <img
                            src={resize}
                            width={13}
                            height={13}
                            alt="resize"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTopic === "How to use templates" && (
                // ----------- How to use templates ----------------------
                <div className="pt-4 pb-[20px] px-5 flex flex-col">
                  <span className="font-[700] text-[20px]">
                    How to use Templates
                  </span>
                  <div className=" pt-4 pl-3">
                    <div className="flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Template Intro
                      </span>
                      <span className="pt-2 leading-[20px]">
                        A template is a pre-designed and pre-formatted document.
                        We bring you an extensive library of video templates.
                        They are highly interactive and personalizable. Below
                        are the properties of the template:
                      </span>
                      <span className="pt-3 leading-[20px]">
                        <span className="font-medium">(i)</span> Templates are
                        pre-made layouts that save both time and resources by
                        eliminating the need for brainstorming and content
                        creation from scratch.
                      </span>
                      <span className="pt-2 leading-[20px]">
                        <span className="font-medium">(ii)</span> Layers of
                        interactivity are added to raw videos to convert it into
                        a template. By 'raw videos,' we mean simple videos
                        without any layers.
                      </span>
                      <span className="pt-2 leading-[20px]">
                        <span className="font-medium">(iii)</span> A template
                        can be customized or used as it is. Templates are always
                        ready to use.
                      </span>
                      <span className="pt-2 leading-[20px]">
                        <span className="font-medium">(iv)</span> You have the
                        flexibility to make multiple edits to a template and
                        save numerous versions of it.
                      </span>
                    </div>
                    <div className="flex flex-col pt-5">
                      <span className="font-[700] text-[16px]">
                        How to use templates
                      </span>
                      <span className="pt-2 leading-[20px]">
                        There are multiple ways in which a template can be used:
                      </span>
                      <div className="pt-3 leading-[20px]">
                        <span className="font-[600] text-[16px]">
                          Customize template:{" "}
                        </span>
                        Templates are pre-designed and can be used as it is but you
                        can edit them in our powerful interactive editor. The
                        editor has tools like hotspots, buttons and input boxes
                        which will enable you to add layers of interaction.
                      </div>
                      <div className="pt-3 leading-[20px]">
                        <span className="font-[600] text-[16px]">
                          Start a project:{" "}
                        </span>
                        You can either customize or use a template to start a
                        project. When we create a project, we ask the users to
                        upload their customer data to implement personalization
                        and then further generate links.
                      </div>
                      <div className="pt-3 leading-[20px]">
                        <span className="font-[600] text-[16px]">
                          Get API:{" "}
                        </span>
                        Get an API link directly or after customizing the
                        template. Integrate it into your websites and platforms.
                      </div>
                    </div>
                    <div className="pt-5 flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Ready to keep Learning?
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Find detailed how-to articles and learning tutorials in
                        our Help Center (hey, you're already here!).
                      </span>
                    </div>
                    <div className="flex">
                      <div
                        className="mt-3 flex items-center text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                        onClick={handleOpenHelpPage}
                      >
                        <span className="">View in our Help Center</span>
                        <span className="ml-1">
                          <img
                            src={resize}
                            width={13}
                            height={13}
                            alt="resize"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTopic === "Personalize and Interactive editor" && (
                // -----------  Personalize and Interactive editor ----------------------
                <div className="pt-4 pb-[20px] px-5 flex flex-col">
                  <span className="font-[700] text-[20px]">
                    Personalize and Interactive editor
                  </span>
                  <div className=" pt-4 pl-3">
                    <div className="flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Introduction of an editor
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Introducing all-new VSPAGY Personalized and Interactive
                        Video Editor – a game changer in how you connect with
                        your audience. Our product knowledge base has expanded
                        to include this innovative tool, which allows you to
                        create videos that truly resonate. With the power of
                        personalization, you can now craft videos tailored to
                        each viewer, incorporating their name, company
                        specific and more.
                      </span>
                      <span className="pt-3 leading-[20px]">
                        Take it a step further with interactivity, enabling
                        viewers to engage directly with your content. This tool
                        opens doors to better engagement and impact,
                        transforming the way you share information and leaving a
                        lasting impression on your audience. Dive into our
                        knowledge base to master the art of crafting these
                        dynamic videos and revolutionize your communication
                        strategies.
                      </span>
                    </div>
                    <div className="flex flex-col pt-3">
                      <span className="pt-2 leading-[20px]">
                        When you access the video editor, you can see the
                        following options in the screen:
                      </span>
                      <div className="pt-3 leading-[20px]">
                        <span className="font-[600] text-[16px]">
                          Controls:{" "}
                        </span>
                        The left panel offers a selection of personalized and
                        interactive elements that can be used to enhance the
                        interactivity and engagement of your video.
                      </div>
                      <div className="pt-3 leading-[20px]">
                        <span className="font-[600] text-[16px]">
                          Preset Elements:{" "}
                        </span>
                        By clicking on the main controls in the left panel, you
                        will access a set of pre-defined controls that
                        streamline and expedite your tasks.
                      </div>
                      <div className="pt-3 leading-[20px]">
                        <span className="font-[600] text-[16px]">
                          Workspace:{" "}
                        </span>
                        This serves as the primary workspace area where your
                        media assets will be loaded. Within this space, you can
                        drag and organize the controls as needed. This is where
                        you'll also preview the design, animations and other
                        features.
                      </div>
                      <div className="pt-3 leading-[20px]">
                        <span className="font-[600] text-[16px]">
                          Component:{" "}
                        </span>
                        The Component section assists in incorporating component
                        configurations, applying styles and configuring other
                        settings.
                      </div>
                    </div>
                    <div className="flex flex-col pt-5">
                      <span className="font-[700] text-[16px]">
                        Editor Elements
                      </span>
                      <span className="pt-2 leading-[20px]">
                        These diverse element options grant you the freedom to
                        design your videos in line with your business
                        requirements. When you pick a specific element, it
                        unlocks a variety of predefined options. These options
                        can then be easily dragged onto the main canvas to serve
                        various purposes.
                      </span>
                      <span>
                        <ul>
                          <li>
                            <div className="pt-3 leading-[20px]">
                              <span className="font-medium">Text: </span>
                              The left panel offers a selection of personalized
                              and interactive elements that can be used to
                              enhance the interactivity and engagement of your
                              video.
                            </div>
                          </li>
                          <li>
                            <div className="pt-3 leading-[20px]">
                              <span className="font-medium">Text Input: </span>
                              Clicking on the text input, will add a text input
                              control to the canvas. You can add text input
                              attributes and validations in the property that
                              appear on the right.
                            </div>
                          </li>
                          <li>
                            <div className="pt-3 leading-[20px]">
                              <span className="font-medium">Hotspot: </span>
                              Clicking on the hotspot control will add a
                              hotspot of various shapes to the canvas. You can
                              add hotspot attributes and validations in the
                              property panel that appear on the right.
                            </div>
                          </li>
                          <li>
                            <div className="pt-3 leading-[20px]">
                              <span className="font-medium">Media: </span>
                              Clicking on the media control, will add media
                              elements like images and videos to the canvas.
                            </div>
                          </li>
                          <li>
                            <div className="pt-3 leading-[20px]">
                              <span className="font-medium">Buttons: </span>
                              Clicking on the button control will add button of
                              various patterns to the canvas. You can edit button
                              attributes from its properties that appear on the
                              right.
                            </div>
                          </li>
                          <li>
                            <div className="pt-3 leading-[20px]">
                              <span className="font-medium">Options: </span>
                              Clicking on the option control will add options
                              like radio, checkboxes and list options to the
                              canvas. You can edit options attributes from its
                              properties that appear on the right.
                            </div>
                          </li>
                        </ul>
                      </span>
                    </div>
                    <div className="pt-5 flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Ready to keep Learning?
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Find detailed how-to articles and learning tutorials in
                        our Help Center (hey, you're already here!).
                      </span>
                    </div>
                    <div className="flex">
                      <div
                        className="mt-3 flex items-center text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                        onClick={handleOpenHelpPage}
                      >
                        <span className="">View in our Help Center</span>
                        <span className="ml-1">
                          <img
                            src={resize}
                            width={13}
                            height={13}
                            alt="resize"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTopic === "Project creation" && (
                // -----------  Project creation ----------------------
                <div className="pt-4 pb-[20px] px-5 flex flex-col">
                  <span className="font-[700] text-[20px]">
                    Project creation
                  </span>
                  <div className=" pt-4 pl-3">
                    <div className="flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Project Overview
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Creating a project involves taking a template and
                        constructing a campaign around it. When a specific
                        template is designed for a particular task and audience,
                        we create a project dedicated to sharing it with the
                        intended audience. A project enables you to upload data
                        for bulk personalization of that template. Our powerful
                        personalization engine will match the data with
                        parameters and you'll also be able to monitor the
                        analytics and performance of your project.
                      </span>
                      <span className="pt-5 leading-[20px]">
                        Each project is unique in nature. While a template can
                        be associated with multiple projects, each project
                        remains distinct and unrelated. There are 2 ways in
                        which a project can be created:
                      </span>
                      <span className="pt-3 leading-[20px]">
                        <span className="font-medium">(i)</span>
                        <span className="font-[600] text-[16px] pl-1">
                          Manual creation:{" "}
                        </span>
                        You will have to upload data manually with the help of sample
                        files and upload it again to initiate personalization
                        and further generate unique links for every customer.
                        Follow these steps to create a project manually.
                      </span>
                      <span className="pt-2 leading-[20px]">
                        <span className="font-medium">(ii)</span>
                        <span className="font-[600] text-[16px] pl-1">
                          API Link:{" "}
                        </span>{" "}
                        You can get API Link of any template and integrate it
                        directly with your own platform or website.
                      </span>
                    </div>
                    <div className="pt-5 flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Ready to keep Learning?
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Find detailed how-to articles and learning tutorials in
                        our Help Center (hey, you're already here!).
                      </span>
                    </div>
                    <div className="flex">
                      <div
                        className="mt-3 flex items-center text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                        onClick={handleOpenHelpPage}
                      >
                        <span className="">View in our Help Center</span>
                        <span className="ml-1">
                          <img
                            src={resize}
                            width={13}
                            height={13}
                            alt="resize"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTopic === "Personalized link generation" && (
                // ----------- Personalized link generation ----------------------
                <div className="pt-4 pb-[20px] px-5 flex flex-col">
                  <span className="font-[700] text-[20px]">
                    Personalized link generation
                  </span>
                  <div className=" pt-3 pl-3">
                    <div className="flex flex-col">
                      <span className="pt-2 leading-[20px]">
                        Now, more than ever, your customers rely on you to
                        address their individual context. Utilize VSPAGY’s
                        personalized videos and video marketing automation to
                        effectively connect with their emotions and thoughts,
                        ultimately boosting engagement, loyalty, and sales.
                      </span>
                      <span className="pt-4 leading-[20px]">
                        Each project is unique in nature. While a template can
                        be associated with multiple projects, each project
                        remains distinct and unrelated. There are 2 ways in
                        which a project can be created:
                      </span>
                      <span className="pt-3 leading-[20px]">
                        <span className="font-medium">(i)</span>
                        <span className="font-[600] text-[16px] pl-1">
                          Manual creation:{" "}
                        </span>
                        You will have to upload data manually with the help of sample
                        files and upload it again to initiate personalization
                        and further generate unique links for every customer.
                        Follow these steps to create a project manually.
                      </span>
                      <span className="pt-2 leading-[20px]">
                        <span className="font-medium">(ii)</span>
                        <span className="font-[600] text-[16px] pl-1">
                          API Link:{" "}
                        </span>
                        You can get API Link of any template and integrate it
                        directly with your own platform or website.
                      </span>
                    </div>
                    <div className="pt-5 flex flex-col">
                      <span className="font-[700] text-[16px]">
                        Ready to keep learning?
                      </span>
                      <span className="pt-2 leading-[20px]">
                        Find detailed how-to articles and learning tutorials in
                        our Help Center (hey, you're already here!).
                      </span>
                    </div>
                    <div className="flex">
                      <div
                        className="mt-3 flex items-center text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                        onClick={handleOpenHelpPage}
                      >
                        <span className="">View in our Help Center</span>
                        <span className="ml-1">
                          <img
                            src={resize}
                            width={13}
                            height={13}
                            alt="resize"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTopic === "Reports and analytics" && (
                // -----------  Reports and analytics ----------------------
                <div className="pt-4 pb-[20px] px-5 flex flex-col">
                  <span className="font-[700] text-[20px]">
                    Reports and analytics
                  </span>
                  <div className=" pt-3 pl-3">
                    <div className="flex flex-col">
                      <span className="pt-2 leading-[20px]">
                        With VSPAGY Video Analytics Dashboard get comprehensive
                        and data driven video analytics, track video engagement,
                        learn more about your audience, and turn views into
                        results.VSPAGY offers a range of reports that assist
                        businesses in making decisions by analyzing user
                        behavior across various parameters.
                      </span>
                      <div className="pt-4 flex flex-col">
                        <span className="font-[700] text-[16px]">
                          Report Types
                        </span>
                        <span className="pt-5">
                          <ul style={{ listStyleType: "square" }}>
                            <li>
                              <div className="flex flex-col">
                                <span className="font-medium">Play Report</span>
                                <span className="pt-1 leading-[20px]">
                                  You will receive the playback duration and
                                  time stamp of the template. This will reveal
                                  the user's pattern of interest.
                                </span>
                              </div>
                            </li>
                            <li className="pt-3">
                              <div className="flex flex-col">
                                <span className="font-medium">
                                  Heatmap Report
                                </span>
                                <span className="pt-1 leading-[20px]">
                                  Effortlessly monitor all actionable items such
                                  as buttons and hotspots. The report promptly
                                  records all customer interactions with
                                  different action elements within the video.
                                </span>
                              </div>
                            </li>
                            <li className="pt-3">
                              <div className="flex flex-col">
                                <span className="font-medium">Data Report</span>
                                <span className="pt-1 leading-[20px]">
                                  Efficiently gather all the information entered
                                  by users in forms or other fields. The report
                                  showcases user-specific data along with
                                  values, timestamps, and other valuable
                                  details.
                                </span>
                              </div>
                            </li>
                            <li className="pt-3">
                              <div className="flex flex-col">
                                <span className="font-medium">
                                  Credit Report
                                </span>
                                <span className="pt-1 leading-[20px]">
                                  VSPAGY monitors every credit utilized from the
                                  user's account, keeping a record of
                                  activity-specific credit expenditures and
                                  delivering transparent reports to users.
                                </span>
                              </div>
                            </li>
                          </ul>
                        </span>
                      </div>
                      <div className="pt-5 flex flex-col">
                        <span className="font-[700] text-[16px]">
                          Dynamic Dashboard
                        </span>
                        <span className="pt-2 leading-[20px]">
                          VSPAGY provides Dashboard to summarize everything at
                          one place. Data visualization, real-time monitoring,
                          performance measurement, and actionable insights all
                          at one place. Optimize your video content, enhance
                          user engagement, and make informed decisions based on
                          our comprehensive data analysis.
                        </span>
                      </div>
                      <div className="pt-5 flex flex-col">
                        <span className="font-[700] text-[16px]">
                          Ready to keep Learning?
                        </span>
                        <span className="pt-2 leading-[20px]">
                          Find detailed how-to articles and learning tutorials
                          in our Help Center (hey, you're already here!).
                        </span>
                      </div>
                      <div className="flex">
                      <div
                        className="mt-3 flex items-center text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                        onClick={handleOpenHelpPage}
                      >
                        <span className="">View in our Help Center</span>
                        <span className="ml-1">
                          <img
                            src={resize}
                            width={13}
                            height={13}
                            alt="resize"
                          />
                        </span>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Add more cases for other topics if needed */}
            </div>
          ) : (
            <div className="relative">
              <div className="pr-6 pl-8 h-[calc(100vh-147px)] overflow-x-hidden overflow-y-auto">
                <div className="flex justify-between  pt-3">
                  <span className="flex items-center ">
                    <img src={compass} alt="compass" />
                    <span className="text-[#666262] text-xs font-normal ml-1">
                      Discover
                    </span>
                  </span>
                  <span className="text-[#666262] text-xs font-normal">
                    {activeSlide + 1}/3
                  </span>
                </div>
                {/* ------ slider -------- */}
                <div className="">
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "black",
                      "--swiper-navigation-size": "12px",
                    }}
                    className="helpDrawerSlider select-none"
                    modules={[Navigation]}
                    spaceBetween={10}
                    //   speed={10}
                    loop={true}
                    slidesPerView={"auto"}
                    navigation={true}
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <div className="mt-4 pt-2 border-solid flex flex-col border-[1px] border-[#CCCACA] rounded-lg w-[270px] h-[190px]">
                        <span className="text-[#000] text-sm text-center items-center">
                          VSPAGY transforms personalization
                        </span>
                        <span className="text-[#514F4F] text-[11px] font-normal relative left-7">
                          Learn what it is?
                        </span>

                        <span className="border-solid border-[1px] border-[#CCCACA] mt-2"></span>
                        <span>
                          <img
                            src={img1}
                            width="100%"
                            height={139}
                            alt="img1"
                            style={{
                              borderBottomRightRadius: "8px",
                              borderBottomLeftRadius: "8px",
                              cursor: "pointer",
                            }}
                            onClick={slideYouTubeVideo1}
                          />
                        </span>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="mt-4 pt-2 border-solid flex flex-col border-[1px] border-[#CCCACA] rounded-lg w-[270px] h-[190px]">
                        <span className="text-[#000] text-sm relative left-5">
                          Quick Project Start Guide
                        </span>
                        <span className="text-[#514F4F] text-[11px] font-normal relative left-5">
                          Learn how to create a project and generate links
                        </span>
                        <span className="border-solid border-[1px] border-[#CCCACA] mt-2"></span>
                        <span>
                          <img
                            src={img2}
                            width="100%"
                            height={139}
                            alt="img2"
                            style={{
                              borderBottomRightRadius: "8px",
                              borderBottomLeftRadius: "8px",
                              cursor: "pointer",
                            }}
                            onClick={slideYouTubeVideo2}
                          />
                        </span>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="mt-4 pt-2 border-solid flex flex-col border-[1px] border-[#CCCACA] rounded-lg w-[270px] h-[190px]">
                        <span className="text-[#000] text-sm relative left-8">
                        Template Library
                        </span>
                        <span className="text-[#514F4F] text-[11px] font-normal relative left-8">
                        Learn how to work with system templates
                        </span>

                        <span className="border-solid border-[1px] border-[#CCCACA] mt-2"></span>
                        <span>
                          <img
                            src={img3}
                            width="100%"
                            height={139}
                            alt="img3"
                            style={{
                              borderBottomRightRadius: "8px",
                              borderBottomLeftRadius: "8px",
                              cursor: "pointer",
                            }}
                            onClick={slideYouTubeVideo3}
                          />
                        </span>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                {/* ------- Topics Header ----------- */}
                <div className="flex pt-10">
                  <span className="flex">
                    <img
                      src={helpIcon}
                      width={10}
                      alt="Help and support icon"
                    />
                    <span className="text-[#666262] text-xs font-normal ml-1">
                      Explore topics here
                    </span>
                  </span>
                </div>
                {/* -------- All Topics ------------ */}
                <div className="relative left-4 pt-5">
                  <div className="text-[#0C4BC4] text-[13px] font-normal">
                    <span className="flex">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleTopicClick("Getting Started")}
                      >
                        Getting started
                      </span>
                      <img src={groupIcon} alt="group icon" className="pl-3" />
                    </span>
                    {/* -------------- */}
                    <span className="flex pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleTopicClick("How to use templates")}
                      >
                        How to use templates
                      </span>
                      <img src={groupIcon} alt="group icon" className="pl-3" />
                    </span>
                    {/* --------------- */}
                    <span className="flex pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handleTopicClick("Personalize and Interactive editor")
                        }
                      >
                        Personalize and Interactive editor
                      </span>
                      <img src={groupIcon} alt="group icon" className="pl-3" />
                    </span>
                    {/* --------------- */}
                    <span className="flex pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleTopicClick("Project creation")}
                      >
                        Project creation
                      </span>
                      <img src={groupIcon} alt="group icon" className="pl-3" />
                    </span>
                    {/* --------------- */}
                    <span className="flex pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handleTopicClick("Personalized link generation")
                        }
                      >
                        Personalized link generation
                      </span>
                      <img src={groupIcon} alt="group icon" className="pl-3" />
                    </span>
                    {/* --------------- */}
                    <span className="flex pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handleTopicClick("Reports and analytics")
                        }
                      >
                        Reports and analytics
                      </span>
                      <img src={groupIcon} alt="group icon" className="pl-3" />
                    </span>
                    {/* --------------- */}
                    <span className="flex pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={topicYouTubeVideo}
                      >
                        Youtube videos
                      </span>
                      <img src={groupIcon} alt="group icon" className="pl-3" />
                    </span>
                  </div>
                </div>
                {/* ------------------------ */}
                <div className="relative left-5 pt-5 mb-3">
                  <div className="w-[95px] h-[32px] bg-[#182B8E] rounded-[5px] flex justify-center items-center cursor-pointer">
                    <span className="text-[#F5F8FF] text-[13px] font-normal">
                      All Topics
                    </span>
                  </div>
                </div>
              </div>
              {/* ------Support click------------ */}
              {/* <div className="fixed bottom-0 right-0 mt-14 flex justify-end">
                <div className="w-[310px] h-[42px] bg-[#E7E6E6] relative flex items-center">
                  <div
                    className="w-[95px] h-[25px] bg-[#F8F6F6] border-solid border-[1px] border-[#A3A1A1] rounded-[3px] flex justify-center items-center absolute right-3 cursor-pointer"
                    onClick={() => {
                      navigate(`${process.env.REACT_APP_BASE_PATH}/support`);
                    }}
                  >
                    <span className="text-[#000] text-[14px] font-normal">
                      Support
                    </span>
                  </div>
                </div>
              </div> */}
              {/* --------Explore Here Center----------------------- */}
              <div className="flex" style={{ borderTop: "1px solid #CCCACA" }}>
                <div
                  className="mt-3 ml-9 flex items-center text-blue-600 cursor-pointer text-blue hover:underline hover:underline-offset-2"
                  onClick={handleOpenHelpPage}
                >
                  <span className="">Explore Help Center</span>
                  <span className="ml-1">
                    <img src={resize} width={13} height={13} alt="resize" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </Drawer>
      </div>
    </>
  );
};

export default HelpNavbar;
