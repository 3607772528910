
// FUNCITON TO CHECK EMAIL VALIDATATIONS (Used in TeammatesDialog.js)
export const validateEmail = (value) => {
  if (typeof value !== "undefined" && value != null && value !== "") {
    const emailcheck =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailcheck.test(String(value).toLowerCase());
  }
  return false;
};

// FUNCTION WHICH ACCEPTS ONLY NUMBERS (Used in Profile.js)
export const validateNumber = (value) => {
  if (typeof value !== "undefined" && value != null && value !== "") {
    const numberCheck = /^[0-9]+$/;
    return numberCheck.test(value);
  }
  return false;
};

// FUNCTION WHICH ACCEPTS ONLY NUMBERS (Used in Profile.js)
export const validateAlphaNumeric = (value) => {
  if (typeof value !== "undefined" && value != null && value !== "") {
    const alphanumericCheck = /^[a-zA-Z0-9]+$/;
    return alphanumericCheck.test(value);
  }
  return false;
};