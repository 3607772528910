import clevertap from "clevertap-web-sdk";
import { useCallback } from "react";
import { useEffect } from "react";


// Only used for Clevertap to push the events
const useCurrentUrl = () => {
 const custregid = localStorage.getItem("sessionUserId");

  const userAgent = navigator.userAgent;

  const USER_JOURNEY = useCallback(
    (capturedString) => {
      const eventUrl = capturedString;
      clevertap.event.push(`userjourney_${eventUrl}`, {
        USER_ID: custregid,
        Email: custregid,
        timestamp: new Date().getTime(),
        user_agent: userAgent,
      });
    },
    [custregid, userAgent]
  );
  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;

    const startIndex = currentURL.indexOf("user/") + "user/".length;
    const endIndex = currentURL.indexOf("/", startIndex);
    const capturedString =
      endIndex !== -1
        ? currentURL.substring(startIndex, endIndex)
        : currentURL.substring(startIndex);

    // console.log("Current URL:", capturedString);
    USER_JOURNEY(capturedString);
  }, [USER_JOURNEY]);
};

export default useCurrentUrl;
