import { Col, Form, Input, Row } from "antd";
import React from "react";
import Banner from "../common/Banner";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import SimpleButton from "../../common/components/SimpleButton";
import { forgetPassword } from "../../api/api_updateData";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ForgotPwOtpVerify from "./ForgotPwOtpVerify";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import clevertap from "clevertap-web-sdk";
import { Helmet } from "react-helmet";

const ForgotPassword = ({ setForgotPasswordClicked }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isRegistered, setIsRegistered] = useState(false); // New state variable to handle registration status
  const userAgent = navigator.userAgent;

  // ************ FOR FUTURE USE WHEN IT WILL APPLICABLE
  // const validateEmailAndPassword = () => {
  //   form.validateFields(["email"]);
  // };
  // console.log(forgotPasswordClicked, "FP IN FP")

  const [width, setWidth] = useState(window.innerWidth);

  //calculates width of screen size
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth([window.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //******************************* CREATE CUSOTMER STARTS ******************************************
  const { mutate: forgetPasswordMutation } = useMutation(
    (custData) => forgetPassword(custData),
    {
      onSuccess: (data) => {
        // debugger
        // queryClient.invalidateQueries("");
        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
          // navigate("/email-verify");
          setIsRegistered(true);
          setForgotPasswordClicked(true);
          console.log(data.STATUS, "STATUS");
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  //  *************************** CREATE CUSOTMER STARTS ******************************************
  const sendRoute = `${process.env.REACT_APP_BASE_PATH}/reset-password`;
  // console.log(sendRoute, "SEND ROUTE FROM FPW");

  const Forgot_Password_Screen = (custData) => {
    clevertap.event.push("Forgot_Password_Screen", {
      Email: custData.custregid,
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Forgot_Password_Screen EVENT CLICKED");
  };

  const handleForgetPassword = () => {
    // Convert the password to Base64 using btoa() function
    // const encryptPassword = window.btoa(password); // WILL BE USED IN FUTURE
    const custData = {
      custregid: email,
    };
    forgetPasswordMutation(custData);
    Forgot_Password_Screen(custData);
    // console.log(custData, "CUST DATA");
  };

  const backToLogin = () => {
    setForgotPasswordClicked(false);
    navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
  };
  return (
    <div className="h-screen">
      <Helmet>
        <title>
          Reset Your Password - Secure Access to Your VSPAGY Account
        </title>
        <meta
          name="description"
          content="Regain access to your VSPAGY account by resetting your password securely. Follow the simple steps to use your account. Get started now!"
        />
      </Helmet>
      <Row>
        {isRegistered ? (
          <Col span={width > 1024 ? 16 : 24}>
            {/* <EmailOtpVerify email={email} setIsRegistered={setIsRegistered} sendRoute={sendRoute} /> */}
            <ForgotPwOtpVerify
              setForgotPasswordClicked={setForgotPasswordClicked}
              email={email}
              setIsRegistered={setIsRegistered}
              sendRoute={sendRoute}
            />
          </Col>
        ) : (
          // <Col span={width > 750 ? 16 : 24}>
          <Col span={width > 1024 ? 16 : 24}>
            <div className="form-wrapper forgot-password">
              <div className="w-full">
                <div className="mb-8">
                  <div className="w-[200px] ml-auto mr-auto">
                    <img
                      src={newVspagyLogo}
                      alt="vspagyLogo"
                      className="w-full mb-4"
                    />
                  </div>
                  <div className="font-semibold text-center mt-2 w-full text-[#151515]  text-[26px] select-none">
                    It’s okay. We all forget sometimes!
                  </div>
                </div>
                <div className="text-[#868686] mb-7 text-center font-normal text-base">
                  Enter your email address and we’ll send you the link for
                  resetting your password.
                </div>
              </div>
              <Form
                form={form}
                name="login-form"
                initialValues={{ remember: true }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item
                  className="mb-4"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your email" },
                    // { type: "email", message: "Enter valid email" },
                    {
                      // pattern: /^[A-Za-z0-9]+(\.[A-Za-z0-9]+)*@([A-Za-z]+\.)+[A-Za-z]{2,}$/, // Custom pattern for emails with only dots allowed
                      type: "email",
                      message: "Enter a valid email!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    className="h-[44px]  text-base"
                    placeholder="Email Id"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <SimpleButton
                      onClick={handleForgetPassword}
                      label="Submit"
                      type="primary"
                      // htmlType="submit"
                      size="large"
                      className="rounded w-full mt-3"
                      // disabled={
                      //   form
                      //     .getFieldsError()
                      //     .filter(({ errors }) => errors.length).length ||
                      //   !form.isFieldsTouched(["email"]) ||
                      //   Object.values(form.getFieldsValue(["email"])).filter(
                      //     Boolean
                      //   ).length !== 1
                      // }
                      disabled={
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length ||
                        Object.values(form.getFieldsValue(["email"])).filter(
                          Boolean
                        ).length !== 1
                      }
                      // onClick={validateEmailAndPassword}
                    />
                  )}
                </Form.Item>
              </Form>
              <div
                onClick={backToLogin}
                // onClick={() => navigate(-1)}
                className=" hover:underline hover:font-medium hover:underline-offset-2 text-[#1C68EB] text-base font-normal cursor-pointer text-center"
              >
                Back to Login
              </div>
            </div>
          </Col>
        )}
        <Col className="max-[750px]:hidden" span={8}>
          <Banner />
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
