import React, { useEffect, useState } from "react";
// import ideaIcon from "../assests/commonIcons/brandPageIcons/idea1.svg";
import codesand from "../assests/commonIcons/brandPageIcons/codesandbox.svg";
import UserBrandCard from "./UserBrandCard";
import GlobalBrandCard from "./GlobalBrandCard";
import { Button, Spin } from "antd";
import CreateBrandPageModal from "../common/components/Modal/brand/CreateBrandPageModal";
import { useQuery } from "react-query";
import { getAllGlobalBrandPages } from "../api/api_FetchData";
// import noAssetImage from "../../assests/commonIcons/assetImages/noAssetSvg.svg";
import noAssetImage from "../assests/commonIcons/assetImages/noAssetSvg.svg";
import useWindowWidth from "../common/hooks/useWindowWidth";
import MobileResModal from "../common/components/Modal/MobileResModal";

const BrandLayout = ({ sessionuserid }) => {
  sessionuserid = localStorage.getItem("sessionUserId");
  const [showUserBrand, setShowUserBrand] = useState(false);
  const [brandModal, setBrandModal] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;
  const [mobileResModal, setMobileResModal] = useState(false);

  const { data: brandData, refetch } = useQuery(
    ["globalBrandPage", sessionuserid],
    () => getAllGlobalBrandPages(sessionuserid, null, "/brandpage"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        console.log(data, "BRAND DATA FILTER");
        // if(data.status === "-2001"){
        //   sessionStorage.clear();
        //   localStorage.clear();
        //   // debugger
        //   navigate(`${process.env.REACT_APP_BASE_PATH}/logout`);
        //   toast.error(data.description);
        //   // debugger
        // }
      },
    }
  );
  console.log(brandData, "B DATA");

  const handleTabClick = (tab) => {
    setShowUserBrand(tab === "My Brand Content");
  };

  const brandPageModalFunction = () => {
    setBrandModal(true);
  };

  useEffect(() => {
    // Call the refetch function when the component mount
    refetch();
    setIsSpinning(true); // Show the spinner

    setTimeout(() => {
      setIsSpinning(false); // Hide the spinner after 1 second
    }, 1200);
  }, [refetch]);

  return (
    <>
      <div className={` h-[calc(100vh_-_3.75rem)] ${isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"} overflow-y-auto overflow-x-hidden`}>
        <div className={` ${isLargeScreen ? "ml-10 mr-8 my-8" : "ml-7 mr-6 my-7"} `}>
          {/* -----------header----------------- */}
          <div className={`${isLargeScreen ? "flex justify-between" : "flex flex-col"}`}>
            <span className={` ${isLargeScreen ? "text-2xl font-semibold" : "text-[20px] font-medium"} text-[#000] `}>
              Brand Content
            </span>
            <Button
              type="primary"
              className={`flex rounded ${isLargeScreen ? "" : "w-[200px] mt-3"}`}
              onClick={isMobile ? ()=>setMobileResModal(true) :brandPageModalFunction}
            >
              <img src={codesand} alt="codesandIcon" className="pt-1 mr-1" />
              Add Brand Content
            </Button>
          </div>
          {/* ----------banner idea------------ */}
          <div className={` ${isLargeScreen ? "mt-8 mr-28" : "mt-6"}`}>
            <div className="text-[#6C6B6B] text-[12px] font-medium  leading-5">
              <p className="flex">
                The brand page serves as an engaging platform for your video,
                where you can include campaign details or incorporate form
                fields to gather customer information. Utilize predefined page
                layouts or customize your own in alignment with your brand
                guidelines.
              </p>
            </div>
          </div>
          {/* -----------toggle----------- */}
          <div className={`w-full ${isDesktop ? "" : "flex justify-center"}`}>
          <div className={` ${isLargeScreen ? "w-[387px]" : "w-[320px]"}  h-[49px] mt-8 border-solid border-[1px] border-[#D9D6D6] rounded bg-white`}>
            <div className={`w-full h-full flex justify-between ${isLargeScreen ? "text-[13px] font-normal" : "text-[11px] font-medium"}   px-[2px] py-[2px]`}>
              <div
                className={`w-[50%] h-[100%] flex justify-center items-center rounded cursor-pointer ${
                  showUserBrand
                    ? "bg-[#E5ECF4] text-[#161617]"
                    : "text-[#636466]"
                }`}
                onClick={() => handleTabClick("My Brand Content")} 
              >
                My Brand Content
              </div>
              <div
                className={`w-[50%] h-[100%] flex justify-center items-center rounded cursor-pointer ${
                  !showUserBrand
                    ? "bg-[#E5ECF4] text-[#161617]"
                    : "text-[#636466]"
                }`}
                onClick={() => handleTabClick("Brand Content Templates")}
              >
                Brand Content Templates
              </div>
            </div>
          </div>
          </div>
          {/* ----------brand content page--------------------- */}
          {showUserBrand ? (
            <div className="w-full mt-10">
              <div className="">
                <UserBrandCard />
              </div>
            </div>
          ) : (
            <>
              {brandData && brandData.data && brandData.status === "1000" ? (
                <div className="w-full flex flex-wrap gap-x-12 gap-y-16 mt-10">
                  {brandData.data.map((brandItem) => (
                    <GlobalBrandCard
                      key={brandItem.BRANDPAGEID}
                      brandData={brandItem}
                    />
                  ))}
                </div>
              ) : (
                <>
                  <div className={` ${isDesktop ? "w-[calc(100vw-316px)]" : "w-full"} flex flex-col items-center pt-20`}>
                    <span className="text-center flex justify-center">
                      <img src={noAssetImage} alt="no asset svg" className={` ${isLargeScreen ? "" : "w-[120px]"} `}/>
                    </span>
                    <span className={` ${isLargeScreen ? "text-xl" : "text-[17px]"} text-[#4B4B4B]  font-medium pt-4`}>
                      No brand pages found
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {isSpinning && (
          <>
            <div
              className="overlay opacity-50 bg-gray-100"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                // backgroundColor: "rgb(0, 0, 0,0.5)",
                zIndex: 1000,
                cursor: "wait",
              }}
            ></div>
            <span className="text-center top-1/2 left-1/2 absolute z-[10000000] cursor-wait">
              <Spin size="large" />
            </span>
          </>
        )}
      </div>
      {brandModal && (
        <CreateBrandPageModal
          dialog={brandModal}
          setDialog={setBrandModal}
          title={"Start Creating New Brand Page"}
          spin={setIsSpinning}
        />
      )}
      {isMobile && (
        <MobileResModal
          mobileResModal={mobileResModal}
          setMobileResModal={setMobileResModal}
        />
      )}
    </>
  );
};

export default BrandLayout;