import React from 'react';
import '../Styling/Canva.css';
import { message } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { divObj } from '../utils/helper';
import idCreate from '../utils/uuid';
import vImage from '../assets/variableImg.svg';
import vVideo from '../assets/variableVideo.svg';
// import Oval from '../assets/oval.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';

function Canva(props) {
  const _ = require('lodash');
  const navigate = useNavigate();
  // const [dragDiv, setDragdiv] = useState(null);
  const [deltaX, setDeltaX] = useState(0);
  const [deltaY, setDeltaY] = useState(0);
  // const parentdivRef = useRef(null);
  const [sizeControl, setsizeControl] = useState(false);

  // const [rightClicked, setRightClicked] = useState('');
  // let [index, setIndex] = useState(null);
  //const [ratio, setRatio] = useState('');
  // const [props.currentTime, setCurrentTime] = React.useState(0);
  const [isMessageVisible, setMessageVisibility] = React.useState(false);
  const bgMusic = useRef(null);
  const [seeked, setSeeked] = useState(false);
  // const [drag,setDrag] =  useState(false);
  const drag = useRef(false);
  const MessageDisplay = () => {
    // Display the message only if it's not currently visible
    if (!isMessageVisible) {
      message.warning('Layer is in Locked State 🔒', 0.8);
      setMessageVisibility(true);

      // Hide the message after a certain duration (optional)
      setTimeout(() => {
        setMessageVisibility(false);
      }, 800); // Change the duration (in milliseconds) to suit your needs
    }
  };

  useEffect(() => {
    // Attach event listener for keydown event
    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  });

  useEffect(() => {
    props.setGroupSelection(false);
    props.setAlign('');
    props.setMaxCorners(null);
    props.setAlignElement(new Set([]));
    if (
      props.canvaContent.length > 0 &&
      props.canvaContent[props.canvaContent.length - 1].id === 'MultiLayer'
    ) {
      props.canvaContent.pop();
      props.handleContent(props.canvaContent);
    }
  }, [seeked]);

  useEffect(() => {
    if (seeked) {
      let flag = true;
      let count = 0;
      props.canvaContent.forEach((item, index) => {
        if (
          props.currentTime >= item.starttime &&
          props.currentTime <= item.stoptime
        ) {
          count++;
          flag = props.canvaContent[index].draggable;
        }
      });
      for (let i = 0; i < props.canvaContent.length; i++) {
        if (
          props.currentTime >= props.canvaContent[i].starttime &&
          props.currentTime <= props.canvaContent[i].stoptime
        ) {
          console.log(
            'Required',
            flag,
            count,
            props.canvaContent[i].draggable,
            props.canvaContent[i].layername
          );
          count--;
          if (props.canvaContent[i].draggable !== flag) {
            props.setCurrLocks({ currState: 0, prevState: -1 });
            break;
          }

          if (count === 0 && props.canvaContent[i].draggable === flag) {
            if (flag) {
              props.setCurrLocks({ currState: -1, prevState: 0 });
            } else {
              props.setCurrLocks({ currState: 1, prevState: 0 });
            }
          }
        }
      }
    }
    setSeeked(false);
  }, [seeked]);

  function parentDivDetails() {
    const parentdiv = props.parentdivRef.current;
    const parentdivRect = parentdiv.getBoundingClientRect();
    const { offsetWidth, offsetHeight } = props.parentdivRef.current;
    return { parentdiv, parentdivRect, offsetWidth, offsetHeight };
  }

  const handleDivDrag = (event) => {
    event.preventDefault();
  };

  const handleDivDrop = (event) => {
    // on Dropping element  from toolBar to Editor
    try {
      if (!event.dataTransfer.getData('text/plain')) {
        return;
      }
      const dataObj = JSON.parse(event.dataTransfer.getData('text/plain'));
      console.log(dataObj);
      if (
        props.canvaContent.length > 0 &&
        props.canvaContent[props.canvaContent.length - 1].id === 'MultiLayer'
      ) {
        props.canvaContent.splice(props.canvaContent.length - 1, 1);
        props.setMaxCorners(null);
      }
      const { offsetWidth, offsetHeight } = props.parentdivRef.current;
      let target = document.getElementById('drawArea').getBoundingClientRect();
      let X = event.clientX - target.left;
      let Y = event.clientY - target.top;
      if (dataObj.type === 'image') {
        if (
          X < 0 ||
          Y < 0 ||
          X + dataObj.width > offsetWidth ||
          Y + dataObj.height > offsetHeight
        ) {
          return;
        }
      }
      if (dataObj.type === 'image') {
        dataObj.left = X;
        dataObj.top = Y;
      } else {
        dataObj.left = X - props.dropX;
        dataObj.top = Y - props.dropY;
      }

      if (
        dataObj.type === 'image' ||
        dataObj.type === 'video' ||
        dataObj.type === 'radio' ||
        dataObj.type === 'checkbox'
      ) {
        props.setLink(true);
        props.setResizeRatio(dataObj.width / dataObj.height);
      } else if (dataObj.type === 'svg') {
        props.setLink(false);
        props.setResizeRatio(null);
      }
      // dataObj.left = X - props.dropX;
      // dataObj.top = Y - props.dropY;
      dataObj.starttime = parseFloat(props.currentTime);
      dataObj.stoptime = parseFloat(props.currentTime) + 1;
      dataObj.id = props.handleId();
      dataObj.layername =
        dataObj.type == 'vimage'
          ? 'vimg_' + dataObj.type.toUpperCase() + '_' + dataObj.id
          : dataObj.type == 'vVideo'
          ? 'vvid_' + dataObj.type.toUpperCase() + '_' + dataObj.id
          : dataObj.type.toUpperCase() + '_' + dataObj.id;
      if (dataObj.type == 'vimage' || dataObj.type == 'vVideo') {
        dataObj.payload = `{${dataObj.layername}}`;
      }
      dataObj.zindex = props.handleZ();
      props.canvaContent.push(dataObj);
      props.handleContent(props.canvaContent);
      props.setIsDivDropped(true);
      props.handleItem(dataObj.id);
      props.setTemplateDetails(false);
      props.setSettingButton(false);
      props.setLayerButton(false);
      props.setRightClicked('');
      if (dataObj.type === 'svg') {
        fetch(dataObj.payload)
          .then((response) => response.text())
          .then((data) => {
            console.log('SVG', data);
            props.setSVGCode(data);
          })
          .catch((error) => console.error('Error fetching SVG:', error));
      }
    } catch {}
  };

  function handleDragStart(e, id, type, payload) {
    // Dragging the element inside the editor
    // e.preventDefault();
    try {
      let index = props.canvaContent.findIndex(function (obj) {
        // if (e.target.id === '') {
        //   return obj.id === e.target.parentElement.id;
        // } else {
        return obj.id === id;
      });
      if (!props.play) {
        e.preventDefault();
        return;
      }
      /****************************  Logic if we want to drag while text is Selected   *****************************/

      props.setRightClicked('');
      props.setDragdiv(id);
      props.setTemplateDetails(false);
      props.setSettingButton(false);
      props.setLayerButton(false);
      props.setIndex(index);

      props.setSelectedSidebarItem(0);
      props.setDisplayTextConfig(false);
      const { parentdivRect } = parentDivDetails();
      let cx = e.clientX - parentdivRect.left;
      let cy = e.clientY - parentdivRect.top;
      console.log(props.canvaContent[index]);
      let angleinDegree = parseInt(props.canvaContent[index].rotate);
      let angleInRadians = (angleinDegree * Math.PI) / 180;
      let cosAngle = Math.cos(angleInRadians);
      let sinAngle = Math.sin(angleInRadians);
      let X;
      let Y;
      if (angleinDegree < 45 || (angleinDegree > 290 && angleinDegree <= 360)) {
        X =
          props.canvaContent[index].left +
          props.canvaContent[index].width / 2 +
          (props.canvaContent[index].width / 2) * cosAngle -
          (props.canvaContent[index].height / 2) * sinAngle;
        Y =
          props.canvaContent[index].top +
          props.canvaContent[index].height / 2 +
          (props.canvaContent[index].width / 2) * sinAngle +
          (props.canvaContent[index].height / 2) * cosAngle;

        // console.log(X, cx);
        // console.log(Y, cy);
      } else if (angleinDegree >= 45 && angleinDegree < 105) {
        X =
          props.canvaContent[index].left +
          props.canvaContent[index].width / 2 +
          (props.canvaContent[index].width / 2) * cosAngle +
          (props.canvaContent[index].height / 2) * sinAngle;
        Y =
          props.canvaContent[index].top +
          props.canvaContent[index].height / 2 +
          (props.canvaContent[index].width / 2) * sinAngle -
          (props.canvaContent[index].height / 2) * cosAngle;
        // console.log(X, cx);
        // console.log(Y, cy);
      } else if (angleinDegree >= 105 && angleinDegree < 200) {
        X =
          props.canvaContent[index].left +
          props.canvaContent[index].width / 2 -
          (props.canvaContent[index].width / 2) * cosAngle +
          (props.canvaContent[index].height / 2) * sinAngle;
        Y =
          props.canvaContent[index].top +
          props.canvaContent[index].height / 2 -
          (props.canvaContent[index].width / 2) * sinAngle -
          (props.canvaContent[index].height / 2) * cosAngle;
        // console.log(X, cx);
        // console.log(Y, cy);
      } else if (angleinDegree >= 200 && angleinDegree <= 290) {
        X =
          props.canvaContent[index].left +
          props.canvaContent[index].width / 2 -
          (props.canvaContent[index].width / 2) * cosAngle -
          (props.canvaContent[index].height / 2) * sinAngle;
        Y =
          props.canvaContent[index].top +
          props.canvaContent[index].height / 2 -
          (props.canvaContent[index].width / 2) * sinAngle +
          (props.canvaContent[index].height / 2) * cosAngle;
        // console.log(X, cx);
        // console.log(Y, cy);
      }

      if (cx - 12 <= X && cx + 12 >= X && cy - 12 <= Y && cy + 12 >= Y) {
        setsizeControl(true);
        // props.setPrevOpacity(props.canvaContent[index].opacity);
        props.canvaContent[index].opacity = 0;
        let id = idCreate();
        let newDiv = new divObj(
          props.canvaContent[index].left,
          props.canvaContent[index].top,
          props.canvaContent[index].height,
          props.canvaContent[index].width,
          'white',
          props.canvaContent[index].rotate,
          id,
          // 100,
          props.canvaContent[index].zindex - 1,
          '1px dashed blue',
          props.canvaContent[index].starttime,
          props.canvaContent[index].stoptime,
          props.canvaContent[index].visibility
          // props.canvaContent[index].textColor,
          // props.canvaContent[index].borderColor,
          // props.canvaContent[index].flip,
          // 0
        );
        props.canvaContent.push(newDiv);
        props.handleContent(props.canvaContent);
      }
      // if(type==='image'){
      //   const img = new Image();
      //   img.src = props.pathName === 'createBrandTemplate'
      //     ? `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMP/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${payload}?t=1`
      //     : `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMPLATE/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${payload}?t=1`;
      //     e.dataTransfer.setDragImage(img, 0, 0);
      // }

      // else{
      // if (type === 'image') {
      //   const img = new Image();
      //   img.src =
      //     props.pathName === 'createBrandTemplate'
      //       ? `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMP/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${payload}?t=0`
      //       : `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMPLATE/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${payload}?t=0`;

      //   img.style.width = '100%';
      //   img.style.height = '100%';
      //   img.style.opacity = 100;

      //   const dragPreview = document.createElement('div');

      //   dragPreview.style.width = props.canvaContent[index].width + 'px';
      //   dragPreview.style.height = props.canvaContent[index].height + 'px';
      //   dragPreview.style.border = '5px solid green';
      //   dragPreview.style.position = 'absolute';
      //   dragPreview.style.opacity = 1;
      //   dragPreview.style.cursor = 'pointer';
      //   dragPreview.style.backgroundColor = '#F2FFE900';
      //   dragPreview.style.boxShadow = 'rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px';
      //   dragPreview.style.borderImage =
      //     'linear-gradient(to bottom, #00DBDE, #FC00FE)  1 / 1 / 0 stretch';
      //   dragPreview.appendChild(img);
      //   document.body.appendChild(dragPreview);

      //   e.dataTransfer.setDragImage(dragPreview, cx -props.canvaContent[index].left, cy-props.canvaContent[index].top);
      //   setTimeout(() => {
      //     document.body.removeChild(dragPreview);
      //   }, 0);
      // }

      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', '');
      setDeltaX(
        e.clientX - parentdivRect.left - props.canvaContent[index].left
      );
      setDeltaY(e.clientY - parentdivRect.top - props.canvaContent[index].top);
      // e.preventDefault();
      props.handleItem(null);
      // e.preventDefault();
      // }
    } catch {}
  }

  function handleDragOver(e) {
    e.dataTransfer.dropEffect = 'move';
    e.preventDefault();
  }

  function handleDragEnter(e) {
    e.preventDefault();
  }

  function handleDragging(e, id) {
    try {
      let index = props.canvaContent.findIndex(function (obj) {
        // if (e.target.id === '') {
        //   return obj.id === e.target.parentElement.id;
        // } else {
        return obj.id === id;
        // }
      });
      const { parentdivRect, offsetHeight, offsetWidth } = parentDivDetails();
      // console.log(index);
      let currDiv = props.canvaContent[props.canvaContent.length - 1];
      let X = e.clientX - parentdivRect.left;
      let Y = e.clientY - parentdivRect.top;
      if (X >= offsetWidth) {
        X = offsetWidth;
      }

      if (Y >= offsetHeight) {
        Y = offsetHeight;
      }

      if (sizeControl) {
        let finalWidth = X - currDiv.left;
        let finalHeight = Y - currDiv.top;

        if (props.resizeRatio !== null) {
          if (finalWidth / props.resizeRatio + currDiv.top >= offsetHeight) {
            currDiv.height = offsetHeight - currDiv.top;
            currDiv.width = currDiv.height * props.resizeRatio;
          } else {
            currDiv.height = finalWidth / props.resizeRatio;
            currDiv.width = finalWidth;
          }
        } else {
          // if (finalWidth <= 10) {
          //   finalWidth = 10;
          // }
          // if (finalHeight <= 10) {
          //   finalHeight = 10;
          // }
          currDiv.height = finalHeight;
          currDiv.width = finalWidth;
        }
        props.handleContent(props.canvaContent);
        props.elementProperties.width = currDiv.width;
        props.elementProperties.height = currDiv.height;
        props.elementProperties.X = currDiv.left;
        props.elementProperties.Y = currDiv.top;
      } else {
        // e.preventDefault();
        // if (
        //   X - deltaX + props.canvaContent[props.index].width <= offsetWidth &&
        //   Y - deltaY + props.canvaContent[props.index].height <= offsetHeight
        // ) {
        props.elementProperties.X = X - deltaX;
        props.elementProperties.Y = Y - deltaY;
        props.elementProperties.width = props.canvaContent[props.index].width;
        props.elementProperties.height = props.canvaContent[props.index].height;
        // }
      }
      props.elementProperties.rotate = props.canvaContent[props.index].rotate;
      props.elementProperties.flip = props.canvaContent[props.index].flip;
      props.elementProperties.border = props.canvaContent[props.index].border;
      props.elementProperties.borderradius =
        props.canvaContent[props.index].borderradius;

      // props.canvaContent[props.index].border = 10;
      // props.canvaContent[props.index].borderColor = 'black';
      // props.handleContent([...props.canvaContent]);
      props.handleProperties(props.elementProperties);
    } catch {}
  }

  function calculateBorder(height, width) {
    if (height > width) {
      return width / 2;
    } else {
      return height / 2;
    }
  }

  function handleDragEnd(e) {
    // Dropping the element of editor to any desired coordinates
    try {
      const { parentdivRect, offsetHeight, offsetWidth } = parentDivDetails();

      if (sizeControl) {
        let currDiv = props.canvaContent[props.index];
        let X = e.clientX - parentdivRect.left;
        let Y = e.clientY - parentdivRect.top;

        if (X >= offsetWidth) {
          X = offsetWidth;
        }

        if (Y >= offsetHeight) {
          Y = offsetHeight;
        }

        let finalWidth = X - currDiv.left;
        let finalHeight = Y - currDiv.top;
        let fw = finalWidth;
        // let fh = finalHeight;
        if (props.resizeRatio !== null) {
          finalHeight = fw / props.resizeRatio;
          finalWidth = fw;

          if (finalHeight + currDiv.top >= offsetHeight) {
            finalHeight = offsetHeight - currDiv.top;
            finalWidth = finalHeight * props.resizeRatio;
          }
          if (finalWidth < 10) {
            finalWidth = 20;
            finalHeight = 20 / props.resizeRatio;
          }
        } else {
          if (finalWidth <= 10) {
            finalWidth = 10;
          }
          if (finalHeight <= 10) {
            finalHeight = 10;
          }
          if (
            props.canvaContent[props.index].type === 'radio' ||
            props.canvaContent[props.index].type === 'checkbox'
          ) {
            finalWidth = Math.min(finalHeight, finalWidth);
            finalHeight = Math.min(finalHeight, finalWidth);
          }
        }

        if (
          props.canvaContent[props.index].border >=
            props.canvaContent[props.index].width / 2 ||
          props.canvaContent[props.index].border >=
            props.canvaContent[props.index].height / 2
        ) {
          props.canvaContent[props.index].border = calculateBorder(
            finalHeight,
            finalWidth
          );
        }
        props.canvaContent[props.index].width = finalWidth;
        props.canvaContent[props.index].height = finalHeight;
        // props.canvaContent[props.index].opacity = props.prevOpacity;
        props.elementProperties.width = finalWidth;
        props.elementProperties.height = finalHeight;
        props.canvaContent.splice(props.canvaContent.length - 1, 1);
        props.handleProperties(props.elementProperties);
        props.handleContent(props.canvaContent);
        props.undoRedoAction();
        setsizeControl(false);
        props.handleItem(props.dragDiv);
        props.setDisplayTextConfig(true);
        props.setDragdiv(null);
        return;
      }

      let dropCordinateX = e.clientX - parentdivRect.left - deltaX;
      let dropCordinateY = e.clientY - parentdivRect.top - deltaY;
      let dropCordinateXEnd =
        parseInt(dropCordinateX) +
        parseInt(props.canvaContent[props.index].width);
      let dropCordinateYEnd =
        parseInt(dropCordinateY) +
        parseInt(props.canvaContent[props.index].height);

      // if (dropCordinateX < 0 && dropCordinateY < 0) {
      //   props.canvaContent[props.index].left = 0;
      //   props.canvaContent[props.index].top = 0;
      // } else if (dropCordinateX < 0 && dropCordinateYEnd > offsetHeight) {
      //   props.canvaContent[props.index].left = 0;
      //   props.canvaContent[props.index].top =
      //     offsetHeight - props.canvaContent[props.index].height;
      // } else if (dropCordinateXEnd > offsetWidth && dropCordinateY < 0) {
      //   props.canvaContent[props.index].left =
      //     offsetWidth - props.canvaContent[props.index].width;
      //   props.canvaContent[props.index].top = 0;
      // } else if (
      //   dropCordinateXEnd > offsetWidth &&
      //   dropCordinateYEnd > offsetHeight
      // ) {
      //   props.canvaContent[props.index].left =
      //     offsetWidth - props.canvaContent[props.index].width;
      //   props.canvaContent[props.index].top =
      //     offsetHeight - props.canvaContent[props.index].height;
      // } else if (dropCordinateX < 0) {
      //   props.canvaContent[props.index].left = 0;
      //   props.canvaContent[props.index].top = dropCordinateY;
      // } else if (dropCordinateXEnd > offsetWidth) {
      //   props.canvaContent[props.index].left =
      //     offsetWidth - props.canvaContent[props.index].width;
      //   props.canvaContent[props.index].top = dropCordinateY;
      // } else if (dropCordinateY < 0) {
      //   props.canvaContent[props.index].left = dropCordinateX;
      //   props.canvaContent[props.index].top = 0;
      // } else if (dropCordinateYEnd > offsetHeight) {
      //   props.canvaContent[props.index].left = dropCordinateX;
      //   props.canvaContent[props.index].top =
      //     offsetHeight - props.canvaContent[props.index].height;
      // } else {
      //   props.canvaContent[props.index].left = dropCordinateX;
      //   props.canvaContent[props.index].top = dropCordinateY;
      // }

      props.canvaContent[props.index].left = dropCordinateX;
      props.canvaContent[props.index].top = dropCordinateY;

      props.handleContent(props.canvaContent);
      props.undoRedoAction();
      props.handleItem(props.dragDiv);
      props.setDisplayTextConfig(true);
      props.setDragdiv(null);
    } catch {}
  }

  function undoAction() {
    if (props.contentIndex <= 0) {
      return;
    } else {
      console.log(
        'FF',
        _.cloneDeep(props.contentArray[props.contentIndex - 1])
      );
      props.setSeekLayer(props.contentArray[props.contentIndex - 1].timing);
      props.handleContent(
        _.cloneDeep(props.contentArray[props.contentIndex - 1].layers)
      );

      props.setContentIndex(props.contentIndex - 1);
      props.setDeleted(true);
      props.setIndex(null);
      props.handleItem(null);
      props.setRightClicked('');
      props.setTemplateDetails(true);
      props.setMaxCorners(null);
      props.setGroupSelection(false);
      props.setAlignElement(new Set([]));
    }
  }
  function redoAction() {
    if (props.contentIndex === props.contentArray.length - 1) {
      return;
    } else {
      props.setSeekLayer(props.contentArray[props.contentIndex + 1].timing);
      props.handleContent(
        _.cloneDeep(props.contentArray[props.contentIndex + 1].layers)
      );
      props.setContentIndex(props.contentIndex + 1);
      props.setDeleted(true);
      props.setIndex(null);
      props.handleItem(null);
      props.setRightClicked('');
      props.setTemplateDetails(true);
      props.setMaxCorners(null);
      props.setGroupSelection(false);
      props.setAlignElement(new Set([]));
    }
  }
  function deleteDiv() {
    if (
      props.canvaContent.length > 0 &&
      props.canvaContent[props.canvaContent.length - 1].id === 'MultiLayer'
    ) {
      let arr = props.canvaContent.filter(
        (item, idx) => !props.alignElement.has(idx)
      );
      arr.splice(arr.length - 1, 1);
      props.setGroupSelection(false);
      props.setAlign('');
      props.setMaxCorners(null);
      props.setAlignElement(new Set([]));
      props.setDeleted(true);
      props.handleItem(null);
      props.handleContent(arr);
      console.log('POP', arr);
    } else if (props.itemSelected !== null) {
      for (let i = props.canvaContent.length - 1; i >= 0; i--) {
        if (props.canvaContent[i].id === props.itemSelected) {
          props.canvaContent.splice(i, 1);
        }
      }
      props.handleContent(props.canvaContent);
    }
    props.undoRedoAction();
    props.setDeleted(true);
    props.handleItem(null);
    props.setIsRight(true);
  }
  function handleKeyPress(e) {
    if (props.isRight) {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key === 'z' || e.key === 'Z') &&
        !props.fieldEnabled.current
      ) {
        if (e.shiftKey) {
          redoAction();
        } else {
          undoAction();
        }
      } else if (
        e.ctrlKey &&
        (e.key === 'y' || e.key === 'Y') &&
        !props.fieldEnabled.current
      ) {
        redoAction();
      } else if (e.key === 'Delete') {
        e.preventDefault();
        deleteDiv();
        props.setIndex(null);
        props.setTemplateDetails(true);
      } else if (
        e.ctrlKey &&
        (e.key === 'j' || e.key === 'J') &&
        props.itemSelected !== null
      ) {
        e.preventDefault();
        let idx = props.canvaContent.findIndex(
          (item) => item.id === props.itemSelected
        );
        let newObj = { ...props.canvaContent[idx] };
        newObj.zindex = props.handleZ();
        newObj.id = props.handleId();
        newObj.layername = newObj.type.toUpperCase() + '_' + newObj.id;
        newObj.left = props.canvaContent[idx].left + 10;
        newObj.top = props.canvaContent[idx].top + 10;
        props.canvaContent.push(newObj);
        props.handleContent(props.canvaContent);
        props.undoRedoAction();
        props.setItem(newObj.id);
        props.setRightClicked('');
      }
    }
  }
  //Convert pixel to vw
  function pxTOvw(value) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    var result = (100 * value) / x;
    return result;
  }

  function storMisData() {
    let data = props.canvaContent
      .filter((layer, index) => {
        if (layer.type === 'video' || layer.type === 'image') {
          return false;
        }
        return true;
      })
      .map((layer, index) => {
        if (layer.type === 'label') {
          const inputString = layer.name;
          const regex = /{([^}]+)}/g;
          const matches = inputString.match(regex);
          console.log(matches);
          const match =
            matches !== null
              ? matches[0].slice(1, -1).trim() !== ''
                ? true
                : false
              : false;
          console.log(match);
          if (match) {
            let obj = {
              layerName: layer.layername,
              checked: true,
              value: '',
              type: 'video',
              variableName: matches[0].substring(1, matches[0].length - 1),
            };
            return obj;
          }
        } else if (layer.type === 'vimage' || layer.type === 'vVideo') {
          let obj = {
            layerName: layer.layername,
            checked: true,
            value: '',
            type: 'video',
            variableName: layer.layername,
          };
          return obj;
        } else {
          let obj = {
            layerName: layer.type === 'radio' ? layer.name : layer.layername,
            checked: true,
            value: '',
            type: 'video',
          };
          return obj;
        }
      });
    let hiddenVariableData = props.variableData.map((variable, index) => {
      let obj = {
        layerName: variable.Column_Name,
        checked: true,
        value: '',
        type: 'hidden',
        variableName: variable.Variable,
      };
      return obj;
    });
    data = data.filter((item) => item !== undefined);
    data = [...data, ...hiddenVariableData];
    const uniqueArray = [...new Set(data.map(JSON.stringify))].map(JSON.parse);
    console.log('Result Wanted', uniqueArray);
    // console.log(uniqueArray);
    // if (props.misData.length !== 0) {
    //   const commonObjects = props.misData.filter((obj1) => {
    //     const obj2 = uniqueArray.find(
    //       (obj2) => obj2.layerName === obj1.layerName
    //     );
    //     // console.log(obj2);
    //     return obj2 && obj1.checked !== obj2.checked;
    //   });
    //   console.log(commonObjects);
    //   commonObjects.forEach((obj) => {
    //     const matchingObject = uniqueArray.find(
    //       (obj2) => obj2.layerName === obj.layerName
    //     );
    //     matchingObject.checked = obj.checked;
    //     // console.log( matchingObject);
    //   });
    //   console.log(commonObjects);
    // }
    if (props.misData.length !== 0) {
      // Create a map of uniqueArray objects by layerName for faster access
      const uniqueMap = new Map(uniqueArray.map((obj) => [obj.layerName, obj]));

      // Iterate through misData
      props.misData.forEach((obj1) => {
        const obj2 = uniqueMap.get(obj1.layerName);
        if (obj2 && obj1.checked !== obj2.checked) {
          obj2.checked = obj1.checked;
        }
      });
    }
    // console.log(uniqueArray);
    let arr = [];
    for (let i = 0; i < uniqueArray.length; i++) {
      let obj = uniqueArray[i];
      if (obj.checked && obj.variableName !== undefined) {
        arr.push(obj.variableName);
      }
    }
    console.log(arr);
    arr = [...new Set(arr.map(JSON.stringify))].map(JSON.parse);
    let allVariables = arr.join(',') + '';
    console.log('misallVariables', allVariables);
    props.setMisData([...uniqueArray]);
    // localStorage.setItem('misData', JSON.stringify(uniqueArray));
    return { uniqueArray, allVariables };
  }

  function parseResponseData(data) {
    console.log(data);
  }
  function getReverseColor(hexColor) {
    // Remove the '#' character from the beginning of the hex color code
    hexColor = hexColor.replace(/^#/, '');

    // Convert the hex color code to RGB
    const red = parseInt(hexColor.slice(0, 2), 16);
    const green = parseInt(hexColor.slice(2, 4), 16);
    const blue = parseInt(hexColor.slice(4, 6), 16);

    // Calculate the complement (reverse) color
    const complementRed = 255 - red;
    const complementGreen = 255 - green;
    const complementBlue = 255 - blue;

    // Convert the complement RGB values back to hex
    const complementColor = `#${complementRed
      .toString(16)
      .padStart(2, '0')}${complementGreen
      .toString(16)
      .padStart(2, '0')}${complementBlue.toString(16).padStart(2, '0')}`;

    return complementColor;
  }

  function calculateMaxCorners(obj, action, delIn) {
    console.log('Object', obj);
    let max = {};
    if (props.maxCorners === null) {
      max.left = obj.left;
      max.top = obj.top;
      max.bottom = obj.top + obj.height;
      max.right = obj.left + obj.width;
    } else {
      if (action === 'ins') {
        let maxObj = props.maxCorners;
        max.left = Math.min(maxObj.left, obj.left);
        max.top = Math.min(maxObj.top, obj.top);
        max.bottom = Math.max(maxObj.bottom, obj.top + obj.height);
        max.right = Math.max(maxObj.right, obj.left + obj.width);
      } else if (action === 'del') {
        max.top = Number.MAX_SAFE_INTEGER;
        max.left = Number.MAX_SAFE_INTEGER;
        max.right = Number.MIN_SAFE_INTEGER;
        max.bottom = Number.MIN_SAFE_INTEGER;
        props.alignElement.forEach((idx) => {
          if (idx !== delIn) {
            max.left = Math.min(max.left, props.canvaContent[idx].left);
            max.top = Math.min(max.top, props.canvaContent[idx].top);
            max.bottom = Math.max(
              max.bottom,
              props.canvaContent[idx].top + props.canvaContent[idx].height
            );
            max.right = Math.max(
              max.right,
              props.canvaContent[idx].left + props.canvaContent[idx].width
            );
          }
        });
      }
    }

    let id = 'MultiLayer';

    let newDiv = new divObj(
      max.left,
      max.top,
      max.bottom - max.top,
      max.right - max.left,
      'transparent',
      0,
      id,
      props.canvaContent[props.canvaContent.length - 1].id === 'MultiLayer'
        ? Math.min(
            props.canvaContent[props.canvaContent.length - 1].zindex,
            obj.zindex
          ) - 1
        : obj.zindex - 1,
      // props.canvaContent[index].zindex - 1,
      '1px dashed blue',
      props.currentTime,
      props.currentTime + 1,
      true,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      true
    );
    if (props.canvaContent[props.canvaContent.length - 1].id === 'MultiLayer') {
      props.canvaContent.pop();
    }
    if (props.alignElement.size === 0 && action === 'del') {
    } else {
      props.canvaContent.push(newDiv);
    }
    props.handleContent(props.canvaContent);
    props.setMaxCorners({ ...max });
  }

  function handleCurrLock(currTime) {
    let currflag = true;
    let count = 0;
    props.canvaContent.forEach((item, index) => {
      if (currTime >= item.starttime && currTime <= item.stoptime) {
        count++;
        currflag = props.canvaContent[index].draggable;
      }
    });
    for (let i = 0; i < props.canvaContent.length; i++) {
      if (
        currTime >= props.canvaContent[i].starttime &&
        currTime <= props.canvaContent[i].stoptime
      ) {
        count--;
        if (props.currLocks.currState === -1) {
          if (props.canvaContent[i].draggable !== currflag) {
            props.setCurrLocks({ currState: 0, prevState: -1 });
            break;
          }
        } else if (props.currLocks.currState === 1) {
          if (props.canvaContent[i].draggable !== currflag) {
            props.setCurrLocks({ currState: 0, prevState: 1 });
            break;
          }
        } else {
          if (props.canvaContent[i].draggable !== currflag) {
            break;
          }
          if (count === 0 && props.canvaContent[i].draggable === currflag) {
            props.setCurrLocks(
              currflag
                ? { currState: -1, prevState: 0 }
                : { currState: 1, prevState: 0 }
            );
          }
        }
      }
    }
  }
  function calculateMaxBorders() {
    let max = {};
    for (const idx of props.alignElement) {
      if (Object.keys(max).length === 0) {
        max.left = props.canvaContent[idx].left;
        max.top = props.canvaContent[idx].top;
        max.bottom =
          props.canvaContent[idx].top + props.canvaContent[idx].height;
        max.right =
          props.canvaContent[idx].left + props.canvaContent[idx].width;
      } else {
        max.left = Math.min(max.left, props.canvaContent[idx].left);
        max.top = Math.min(max.top, props.canvaContent[idx].top);
        max.bottom = Math.max(
          max.bottom,
          props.canvaContent[idx].top + props.canvaContent[idx].height
        );
        max.right = Math.max(
          max.right,
          props.canvaContent[idx].left + props.canvaContent[idx].width
        );
      }
    }
    props.setMaxCorners({ ...max });
  }

  console.log(props.canvaContent);
  console.log('INDEX--------------------------------', props.index);
  console.log(props.alignElement.has(props.index));
  console.log("ALert",props.pathName);
  return (
    <div
      className='Editor flex items-center justify-center h-[calc(100vh-64px)] w-[calc(100vw-600px)]'
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onDoubleClick={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => {
        e.stopPropagation();
        props.setRightClicked('');
        props.setItem(null);
        props.setGroupSelection(false);
        props.setAlign('');
        props.setMaxCorners(null);
        props.setAlignElement(new Set([]));
        props.setTemplateDetails(true);
        props.setShowAlign(false);
        props.setSelectedSidebarItem(0);
        if (
          props.canvaContent.length > 0 &&
          props.canvaContent[props.canvaContent.length - 1].id === 'MultiLayer'
        ) {
          props.canvaContent.pop();
          props.handleContent(props.canvaContent);
          props.setDeleted(true);
          props.handleItem(null);
        }
      }}
    >
      {!props.isRight && (
        <div className='h-full w-full z-10 absolute top-0 left-0'></div>
      )}
      <div
        className='drawArea'
        id='drawArea'
        style={{
          width: '400px',
          height: props.baseHeight + 'px',
          overflow: props.vis ? 'visible' : 'hidden',
          backgroundColor : props.baseColor,
          position: 'absolute',
        }}
        ref={props.parentdivRef}
        onDragOver={handleDivDrag}
        onDrop={handleDivDrop}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onContextMenu={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <>
          {props.canvaContent.map(function (obj, index) {
            return (
              <>
                <div
                  key={obj.id}
                  className='item brand'
                  id={obj.id}
                  onClick={function (e) {
                    e.stopPropagation();
                    if (!props.play || !props.isRight) {
                      return;
                    }
                    if (!obj.draggable) {
                      props.setItem(null);
                      props.setRightClicked('');
                      props.setIndex(
                        props.canvaContent.findIndex(
                          (item) => item.id === obj.id
                        )
                      );
                      props.setDeleted(true);
                      props.setTemplateDetails(true);
                      MessageDisplay();
                    } else {
                      if (e.ctrlKey || e.metaKey) {
                        if (obj.id === 'MultiLayer') {
                          return;
                        }
                        console.log('hi');
                        if (props.alignElement.has(index)) {
                          props.alignElement.delete(index);
                          if (props.alignElement.size === 0) {
                            props.setGroupSelection(false);
                          }
                          calculateMaxCorners(obj, 'del', index);
                          props.setAlignElement(
                            new Set([...props.alignElement])
                          );
                        } else {
                          calculateMaxCorners(obj, 'ins', '');
                          props.setAlignElement(
                            new Set([...props.alignElement, index])
                          );
                          props.setGroupSelection(true);
                        }
                        props.setDeleted(true);
                        props.handleItem(null);
                        props.setTemplateDetails(true);
                      } else {
                        if (
                          obj.id === 'MultiLayer' ||
                          props.alignElement.has(index)
                        ) {
                          // props.handleItem(obj.id);
                        } else {
                          props.setGroupSelection(false);
                          props.setAlign('');
                          props.setMaxCorners(null);
                          props.setAlignElement(new Set([]));
                          props.handleItem(obj.id);
                          props.setSettingButton(false);
                          props.setLayerButton(false);
                          props.setMusicButton(false);
                          props.setTemplateDetails(false);
                          props.setRightClicked('');
                          if (
                            props.link ||
                            obj.type === 'checkbox' ||
                            obj.type === 'radio'
                          ) {
                            props.setLink(true);
                            props.setResizeRatio(obj.width / obj.height);
                          }
                          if (
                            props.canvaContent.length > 0 &&
                            props.canvaContent[props.canvaContent.length - 1]
                              .id === 'MultiLayer'
                          ) {
                            props.canvaContent.splice(
                              props.canvaContent.length - 1,
                              1
                            );
                            props.handleContent(props.canvaContent);
                          }
                        }
                      }
                    }
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    if (drag.current) {
                      e.preventDefault();
                    }
                    if (!props.play || !obj.draggable || !props.isRight) {
                      e.preventDefault();
                      return;
                    }
                    props.setItem(null);
                    let index = props.canvaContent.findIndex(function (o) {
                      return obj.id === o.id;
                    });
                    var move = false;
                    const parentdiv = props.parentdivRef.current;
                    const parentdivRect = parentdiv.getBoundingClientRect();
                    let f = props.vis ? 1.1 : 1;
                    let cx = e.clientX - parentdivRect.left;
                    let cy = e.clientY - parentdivRect.top;
                    let prevTop = props.canvaContent[index].top;
                    let prevLeft = props.canvaContent[index].left;
                    console.log(props.canvaContent[index]);
                    let angleinDegree = parseInt(
                      props.canvaContent[index].rotate
                    );
                    let angleInRadians = (angleinDegree * Math.PI) / 180;
                    let cosAngle = Math.cos(angleInRadians);
                    let sinAngle = Math.sin(angleInRadians);
                    let X;
                    let Y;
                    if (
                      angleinDegree < 45 ||
                      (angleinDegree > 290 && angleinDegree <= 360)
                    ) {
                      X =
                        props.canvaContent[index].left +
                        props.canvaContent[index].width / 2 +
                        (props.canvaContent[index].width / 2) * cosAngle -
                        (props.canvaContent[index].height / 2) * sinAngle;
                      Y =
                        props.canvaContent[index].top +
                        props.canvaContent[index].height / 2 +
                        (props.canvaContent[index].width / 2) * sinAngle +
                        (props.canvaContent[index].height / 2) * cosAngle;
                    } else if (angleinDegree >= 45 && angleinDegree < 105) {
                      X =
                        props.canvaContent[index].left +
                        props.canvaContent[index].width / 2 +
                        (props.canvaContent[index].width / 2) * cosAngle +
                        (props.canvaContent[index].height / 2) * sinAngle;
                      Y =
                        props.canvaContent[index].top +
                        props.canvaContent[index].height / 2 +
                        (props.canvaContent[index].width / 2) * sinAngle -
                        (props.canvaContent[index].height / 2) * cosAngle;
                    } else if (angleinDegree >= 105 && angleinDegree < 200) {
                      X =
                        props.canvaContent[index].left +
                        props.canvaContent[index].width / 2 -
                        (props.canvaContent[index].width / 2) * cosAngle +
                        (props.canvaContent[index].height / 2) * sinAngle;
                      Y =
                        props.canvaContent[index].top +
                        props.canvaContent[index].height / 2 -
                        (props.canvaContent[index].width / 2) * sinAngle -
                        (props.canvaContent[index].height / 2) * cosAngle;
                    } else if (angleinDegree >= 200 && angleinDegree <= 290) {
                      X =
                        props.canvaContent[index].left +
                        props.canvaContent[index].width / 2 -
                        (props.canvaContent[index].width / 2) * cosAngle -
                        (props.canvaContent[index].height / 2) * sinAngle;
                      Y =
                        props.canvaContent[index].top +
                        props.canvaContent[index].height / 2 -
                        (props.canvaContent[index].width / 2) * sinAngle +
                        (props.canvaContent[index].height / 2) * cosAngle;
                    }
                    let sizeControl = false;
                    let flag = false;
                    if (props.canvaContent[index].width < 30) {
                      if (
                        cx - 5 <= X &&
                        cx + 5 >= X &&
                        cy - 11 <= Y &&
                        cy + 11 >= Y
                      ) {
                        flag = true;
                      }
                    }
                    if (props.canvaContent[index].height < 30) {
                      if (
                        cx - 11 <= X &&
                        cx + 11 >= X &&
                        cy - 5 <= Y &&
                        cy + 5 >= Y
                      ) {
                        flag = true;
                      }
                    } else {
                      if (
                        cx - 11 <= X &&
                        cx + 11 >= X &&
                        cy - 11 <= Y &&
                        cy + 11 >= Y
                      ) {
                        flag = true;
                      }
                    }
                    if (
                      flag &&
                      props.itemSelected === obj.id &&
                      obj.id !== 'MultiLayer'
                    ) {
                      sizeControl = true;
                      var oppa = props.canvaContent[index].opacity;
                      // props.setPrevOpacity(props.canvaContent[index].opacity);
                      props.canvaContent[index].opacity = 0;
                      let id = idCreate();
                      let newDiv = new divObj(
                        props.canvaContent[index].left,
                        props.canvaContent[index].top,
                        props.canvaContent[index].height,
                        props.canvaContent[index].width,
                        'transparent',
                        props.canvaContent[index].rotate,
                        id,
                        100000,
                        // props.canvaContent[index].zindex - 1,
                        '1px dashed blue',
                        props.canvaContent[index].starttime,
                        props.canvaContent[index].stoptime,
                        props.canvaContent[index].visibility
                      );
                      props.canvaContent.push(newDiv);
                      props.handleContent(props.canvaContent);
                    }
                    let deltaX;
                    let deltaY;

                    if (props.alignElement.has(index)) {
                      deltaX =
                        e.clientX -
                        parentdivRect.left -
                        props.canvaContent[props.canvaContent.length - 1].left;

                      deltaY =
                        e.clientY -
                        parentdivRect.top -
                        props.canvaContent[props.canvaContent.length - 1].top;
                    } else {
                      deltaX =
                        e.clientX -
                        parentdivRect.left -
                        props.canvaContent[index].left;

                      deltaY =
                        e.clientY -
                        parentdivRect.top -
                        props.canvaContent[index].top;
                    }

                    var gap = [];
                    if (obj.id === 'MultiLayer') {
                      for (const idx of props.alignElement) {
                        let diff1 =
                          props.canvaContent[index].left -
                          props.canvaContent[idx].left;
                        let diff2 =
                          props.canvaContent[index].top -
                          props.canvaContent[idx].top;
                        gap.push({ diff1, diff2 });
                      }
                    } else if (props.alignElement.has(index)) {
                      for (const idx of props.alignElement) {
                        let diff1 =
                          props.canvaContent[props.canvaContent.length - 1]
                            .left - props.canvaContent[idx].left;
                        let diff2 =
                          props.canvaContent[props.canvaContent.length - 1]
                            .top - props.canvaContent[idx].top;
                        gap.push({ diff1, diff2 });
                      }
                    }

                    props.handleContent(props.canvaContent);

                    const ReMove = (e) => {
                      props.setLayerButton(false);
                      props.setMusicButton(false);
                      props.setSettingButton(false);
                      props.setRightClicked('');
                      props.setSelectedSidebarItem(0);
                      drag.current = true;
                      const parentdiv = props.parentdivRef.current;
                      const parentdivRect = parentdiv.getBoundingClientRect();
                      const { offsetWidth, offsetHeight } =
                        props.parentdivRef.current;
                      let currDiv =
                        props.canvaContent[props.canvaContent.length - 1];
                      let X = e.clientX - parentdivRect.left;
                      let Y = e.clientY - parentdivRect.top;

                      if (sizeControl && props.itemSelected === obj.id) {
                        let factor = props.vis ? 1.1 : 1;
                        let finalWidth = X - currDiv.left;
                        let finalHeight = Y - currDiv.top;

                        if (props.resizeRatio !== null) {
                          if (finalWidth <= 5) {
                            finalWidth = 5;
                          }
                          currDiv.height = finalWidth / props.resizeRatio;
                          currDiv.width = finalWidth;
                        } else {
                          if (finalWidth <= 5) {
                            finalWidth = 5;
                          }
                          if (finalHeight <= 5) {
                            finalHeight = 5;
                          }
                          currDiv.height = finalHeight;
                          currDiv.width = finalWidth;
                        }
                        // props.handleContent(props.canvaContent);
                        props.elementProperties.width = currDiv.width;
                        props.elementProperties.height = currDiv.height;
                        props.elementProperties.X = currDiv.left;
                        props.elementProperties.Y = currDiv.top;
                        props.elementProperties.rotate =
                          props.canvaContent[index].rotate;
                        props.elementProperties.flip =
                          props.canvaContent[index].flip;
                        props.elementProperties.border =
                          props.canvaContent[index].border;
                        props.elementProperties.borderradius =
                          props.canvaContent[index].borderradius;
                          props.elementProperties.type =
                          props.canvaContent[index].type;
                        props.elementProperties.subtype = 
                           props.canvaContent[index].subtype;
                        props.elementProperties.layername = 
                           props.canvaContent[index].layername;
                        props.elementProperties.placeholder = 
                           props.canvaContent[index].placeholder;
                        props.elementProperties.cltoggle = 
                           props.canvaContent[index].cltoggle;
                        props.elementProperties.emtoggle = 
                           props.canvaContent[index].emtoggle;
                        props.elementProperties.characterlimit = 
                           props.canvaContent[index].characterlimit;
                        props.elementProperties.errormessage = 
                           props.canvaContent[index].errormessage;
                        props.elementProperties.name = 
                           props.canvaContent[index].name;
                        props.elementProperties.action = 
                           props.canvaContent[index].action;
                        props.elementProperties.actionsarray = 
                           props.canvaContent[index].actionsarray;
                        props.setSizing(true);
                        props.handleProperties(props.elementProperties);
                      }
                      else {
                        if (obj.id === 'MultiLayer') {
                          let i = 0;
                          props.canvaContent[index].left =
                            e.clientX - parentdivRect.left - deltaX;
                          props.canvaContent[index].top =
                            e.clientY - parentdivRect.top - deltaY;
                          for (const idx of props.alignElement) {
                            props.canvaContent[idx].left =
                              props.canvaContent[index].left - gap[i].diff1;
                            props.canvaContent[idx].top =
                              props.canvaContent[index].top - gap[i].diff2;
                            i++;
                          }
                          move = true;
                          props.setTemplateDetails(true);
                        } 
                        else if (props.alignElement.has(index)) {
                          props.canvaContent[
                            props.canvaContent.length - 1
                          ].left = e.clientX - parentdivRect.left - deltaX;
                          props.canvaContent[
                            props.canvaContent.length - 1
                          ].top = e.clientY - parentdivRect.top - deltaY;
                          let i = 0;
                          for (const idx of props.alignElement) {
                            props.canvaContent[idx].left =
                              props.canvaContent[props.canvaContent.length - 1]
                                .left - gap[i].diff1;
                            props.canvaContent[idx].top =
                              props.canvaContent[props.canvaContent.length - 1]
                                .top - gap[i].diff2;
                            i++;
                          }
                          move = true;
                          props.setTemplateDetails(true);
                        }
                        else {
                          props.setTemplateDetails(false);
                          props.canvaContent[index].left =
                            e.clientX - parentdivRect.left - deltaX;
                          props.canvaContent[index].top =
                            e.clientY - parentdivRect.top - deltaY;
                          props.setGroupSelection(false);
                          props.setAlign('');
                          props.setMaxCorners(null);
                          props.setAlignElement(new Set([]));
                          if (
                            props.canvaContent.length > 0 &&
                            props.canvaContent[props.canvaContent.length - 1]
                              .id === 'MultiLayer'
                          ) {
                            props.canvaContent.splice(
                              props.canvaContent.length - 1,
                              1
                            );
                            props.handleContent(props.canvaContent);
                          }
                        }
                        props.elementProperties.width =
                          props.canvaContent[index].width;
                        props.elementProperties.height =
                          props.canvaContent[index].height;
                        props.elementProperties.border =
                          props.canvaContent[index].border;
                        props.elementProperties.borderradius =
                          props.canvaContent[index].borderradius;
                        props.elementProperties.rotate =
                          props.canvaContent[index].rotate;
                        props.elementProperties.flip =
                          props.canvaContent[index].flip;
                        props.elementProperties.X =
                          props.canvaContent[index].left;
                        props.elementProperties.Y =
                          props.canvaContent[index].top;
                          props.elementProperties.type =
                          props.canvaContent[index].type;
                        props.elementProperties.subtype = 
                           props.canvaContent[index].subtype;
                        props.elementProperties.layername = 
                           props.canvaContent[index].layername;
                        props.elementProperties.placeholder = 
                           props.canvaContent[index].placeholder;
                        props.elementProperties.cltoggle = 
                           props.canvaContent[index].cltoggle;
                        props.elementProperties.emtoggle = 
                           props.canvaContent[index].emtoggle;
                        props.elementProperties.characterlimit = 
                           props.canvaContent[index].characterlimit;
                        props.elementProperties.errormessage = 
                           props.canvaContent[index].errormessage;
                        props.elementProperties.name = 
                           props.canvaContent[index].name;
                        props.elementProperties.action = 
                           props.canvaContent[index].action;
                        props.elementProperties.actionsarray = 
                           props.canvaContent[index].actionsarray;
                        props.handleProperties(props.elementProperties);
                        props.handleContent([...props.canvaContent]);
                      }
                    };

                    const Up = (e) => {
                      e.stopPropagation();
                      window.removeEventListener('mousemove', ReMove);
                      props.setDragging(false);
                      drag.current = false;
                      if (
                        props.parentdivRef.current !== undefined &&
                        props.parentdivRef.current !== null
                      ) {
                        var { parentdivRect, offsetHeight, offsetWidth } =
                          parentDivDetails();
                      }
                      if (
                        (obj.id === 'MultiLayer' ||
                          props.alignElement.has(index)) &&
                        move
                      ) {
                        calculateMaxBorders();
                        props.setTemplateDetails(true);
                        move = false;
                      }
                      props.setSizing(false);
                      if (props.itemSelected === false) {
                        e.preventDefault();
                        return;
                      }
                      if (sizeControl) {
                        let currDiv = props.canvaContent[index];
                        let factor = props.vis ? 1.1 : 1;
                        let X = e.clientX - parentdivRect.left;
                        let Y = e.clientY - parentdivRect.top;
                        let finalWidth = X - currDiv.left;
                        let finalHeight = Y - currDiv.top;
                        let fw = finalWidth;
                        if (props.resizeRatio !== null) {
                          if (fw <= 5) {
                            fw = 5;
                          }
                          finalHeight = fw / props.resizeRatio;
                          finalWidth = fw;
                        } else {
                          if (finalWidth <= 10) {
                            finalWidth = 10;
                          }
                          if (finalHeight <= 10) {
                            finalHeight = 10;
                          }
                          if (
                            props.canvaContent[index].type === 'radio' ||
                            props.canvaContent[index].type === 'checkbox'
                          ) {
                            finalWidth = Math.min(finalHeight, finalWidth);
                            finalHeight = Math.min(finalHeight, finalWidth);
                          }
                        }

                        if (
                          props.canvaContent[index].border >=
                            props.canvaContent[index].width / 2 ||
                          props.canvaContent[index].border >=
                            props.canvaContent[index].height / 2
                        ) {
                          props.canvaContent[index].border = calculateBorder(
                            finalHeight,
                            finalWidth
                          );
                        }
                        props.canvaContent[index].width = finalWidth;
                        props.canvaContent[index].height = finalHeight;
                        props.canvaContent[index].opacity = oppa;
                        props.canvaContent[index].top = prevTop;
                        props.canvaContent[index].left = prevLeft;
                        props.canvaContent.splice(
                          props.canvaContent.length - 1,
                          1
                        );
                        props.handleContent(props.canvaContent);
                        props.undoRedoAction();
                        sizeControl = false;
                        props.handleItem(obj.id);
                        return;
                      }

                      props.setDisplayTextConfig(true);
                    };
                    window.addEventListener('mousemove', ReMove);
                    window.addEventListener('mouseup', Up);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    if (
                      props.canvaContent[props.canvaContent.length - 1].id !==
                      'MultiLayer'
                    ) {
                      props.setIndex(
                        props.canvaContent.findIndex(
                          (item) => item.id === obj.id
                        )
                      );
                      if (obj.draggable) {
                        props.handleItem(obj.id);
                      }
                      if (props.play) props.setRightClicked(obj.id);
                    }
                  }}
                  style={{
                    userSelect: 'none',
                    resize: !props.play ? 'none' : 'both',
                    position: 'absolute',
                    left: obj.left + 'px',
                    top: obj.top + 'px',
                    width: obj.width + 'px',
                    height: obj.height + 'px',
                    transform: `rotate(${obj.rotate}deg) `,
                    opacity: obj.opacity,
                    zIndex: obj?.zindex,
                    border:
                      obj.border === '1px dashed blue'
                        ? obj.border
                        : obj.id === props.itemSelected ||
                          (props.groupSelection &&
                            props.alignElement.has(index))
                        ? '2px dashed white'
                        : 'none',
                    borderImage:
                      props.alignElement.has(index) ||
                      obj.border === '1px dashed blue'
                        ? 'linear-gradient(to bottom, #aa00ff, #e500e5) 1 / 1 / 0 stretch'
                        : obj.id === props.itemSelected
                        ? // 'linear-gradient(to right, red 20%, green 20%, green 40%, blue 40%, blue 60%, maroon 60%, maroon 80%, chocolate 80%) 1 / 1 / 0 stretch'
                          'linear-gradient(to right, #A6FFCB, #12D8FA, #1FA2FF)  1 / 1 / 0 stretch'
                        : 'none',
                    boxShadow:
                      obj.id === props.itemSelected
                        ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                        : 'none',
                    transformStyle: 'preserve-3d',
                    // animation: `${obj.lastanimation}`,
                    animation: !props.play
                      ? props.currentTime >= obj.starttime &&
                        props.currentTime < (obj.starttime + obj.stoptime) / 2
                        ? obj.entryanimation + ' ' + obj.animationentryspeed
                        : props.currentTime > obj.stoptime - 0.5 &&
                          props.currentTime < obj.stoptime
                        ? obj.exitanimation + ' ' + obj.animationexitspeed
                        : ''
                      : props.itemSelected === obj.id
                      ? props.lastAnimation
                      : '',
                    // display: 'flex',
                    display: obj.visibility
                      ? props.currentTime >= obj.starttime &&
                        props.currentTime <= obj.stoptime
                        ? 'flex'
                        : 'none'
                      : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {obj.type === 'label' && (
                    <label
                      className='textarea labelClass'
                      // draggable={
                      //   props.play &&
                      //   obj.draggable &&
                      //   !props.alignElement.has(index)
                      // }
                      placeholder={obj?.placeholder}
                      style={{
                        whiteSpace:
                          obj?.subtype !== 'multiline' ? 'pre' : 'pre-wrap',
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        // display : 'flex',
                        // visibility : 'hidden',
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        textDecoration: obj?.textdecoration,
                        textAlign: obj?.textalign,
                        fontFamily: obj?.fontfamily,
                        fontSize: obj?.fontsize + 'px',
                        fontStyle: obj?.fontstyle,
                        fontWeight: obj?.fontweight,
                        letterSpacing: obj?.letterspacing,
                        lineHeight: obj?.lineheight,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        userSelect: 'none',
                        // animation: props.lastAnimation,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        height: '100%',
                        width : '100%',
                      }}
                    >
                      {obj?.subtype !== 'multiline' ? (
                        <span>{obj?.name}</span>
                      ) : (
                        obj.name.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                      )}
                    </label>
                  )}
                  {obj.type === 'text' && (
                    <input
                      // type={obj?.subtype}
                      className='textarea textClass'
                      layerName  = {obj.layername}
                      disabled={!props.isRight}
                      typeId = 'input'
                      onClick={() => {
                        // props.setFieldEnabled(true);
                        props.fieldEnabled.current = true;
                      }}
                      onBlur={() => {
                        // props.setFieldEnabled(false);
                        props.fieldEnabled.current = false;
                      }}
                      // draggable={
                      //   props.play &&
                      //   obj.draggable &&
                      //   !props.alignElement.has(index)
                      // }
                      autoComplete='off'
                      placeholder={obj?.placeholder}
                      // onMouseDown={(e)=> {e.stopPropagation();}}
                      style={{
                        userSelect:
                          obj.id === props.itemSelected ? 'text' : 'none',
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        '::placeholder': {
                          color: obj?.textColor, // Placeholder text color
                        },
                        '::MsInputPlaceholder': {
                          color: obj?.textColor,
                        },
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        textDecoration: obj?.textdecoration,
                        textAlign: obj?.textalign,
                        fontFamily: obj?.fontfamily,
                        fontSize: obj?.fontsize,
                        fontStyle: obj?.fontstyle,
                        fontWeight: obj?.fontweight,
                        letterSpacing: obj?.letterspacing,
                        lineHeight: obj?.lineheight,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        height: '100%',
                        width : '100%',
                      }}
                    ></input>
                  )}
                  {obj.type === 'textarea' && (
                    <textarea
                      className='textarea textClass'
                      disabled={!props.isRight}
                      layerName  = {obj.layername}
                      typeId = 'input'
                      spellCheck='false'
                      onSelectStart={(e) => {
                        e.preventDefault();
                      }}
                      placeholder={obj?.placeholder}
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        // overflow: 'hidden',
                        // borderColor: obj?.borderColor,
                        resize: 'none',
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        textDecoration: obj?.textdecoration,
                        textAlign: obj?.textalign,
                        fontFamily: obj?.fontfamily,
                        fontSize: obj?.fontsize,
                        fontStyle: obj?.fontstyle,
                        fontWeight: obj?.fontweight,
                        letterSpacing: obj?.letterspacing,
                        lineHeight: obj?.lineheight,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        height: '100%',
                        width : '100%',
                      }}
                    ></textarea>
                  )}
                  {obj.type === 'hotspot' && (
                    <button
                      className='textarea buttonClass'
                      disabled={!props.isRight}
                      typeId = 'button'
                      layerName  = {obj.layername}
                      action = {obj.action +","+obj.actionsarray.join(",")}
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        height: '100%',
                        width : '100%',
                      }}
                    ></button>
                  )}
                  {obj.type === 'svg' && (
                    <div
                      className = 'assetClass'
                      id={obj.id + 'svg'}
                      dangerouslySetInnerHTML={{ __html: obj.svgContent }}
                      style={{
                        width: '100%',
                        height: '100%',
                        fill: obj.backgroundColor,
                        stroke: obj.borderColor,
                        strokeWidth: obj.border,
                        cursor: 'move',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                      }}
                    ></div>
                  )}
                  {obj.type === 'image' && obj.subtype !== 'globalasset' && (
                    <>
                      <div
                        id={`loader${obj.id}`}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#000000',
                          placeItems: 'center',
                          // font: 1em/1.5 sans-serif,
                          // backgroundImage : 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                          // backgroundRepeat : 'no-repeat',
                          // background :  'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',

                          // animation :'shine 1s infinite',
                          display: 'none',
                          zIndex: 1000,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          class='l1'
                          style={{
                            width: `${Math.min(obj.width, obj.height) / 4}px`,
                            height: `${Math.min(obj.width, obj.height) / 4}px`,
                          }}
                        ></div>
                      </div>
                      <img
                        alt='img'
                        id={`img_${obj.id}`}
                        crossOrigin='anonymous'
                        lt='media'
                        dp={
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none'
                        }
                        src={
                          // obj.payload.includes('http')
                          //   ? 
                            obj.payload
                            // : 
                            // props.pathName == "createBrandTemplate"
                            // ? `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMP/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${obj.payload}?t=1`
                            // : `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMPLATE/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${obj.payload}?t=1`
                        
                          }
                        className='textarea assetClass'
                        onLoad={() => {
                          const loader = document.getElementById(
                            `loader${obj.id}`
                          );
                          setTimeout(() => {
                            loader.style.display = 'none';
                          }, 100);
                        }}
                        draggable={false}
                        // draggable={
                        //   props.play &&
                        //   obj.draggable &&
                        //   !props.alignElement.has(index)
                        // }
                        style={{
                          backgroundColor: obj.backgroundColor,
                          // paddingInline: '10px',
                          color: obj?.textColor,
                          display:
                            props.currentTime >= obj.starttime &&
                            props.currentTime <= obj.stoptime
                              ? 'flex'
                              : 'none', //  css for text label
                          alignItems: 'center', //  css for text label
                          justifyContent: obj?.textalign, //  css for text label
                          overflow: 'hidden', // css for text label
                          // borderColor: obj?.borderColor,
                          resize: 'none',
                          transformStyle: 'preserve-3d',
                          transform: `rotateX(${
                            obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                          }deg) rotateY(${
                            obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                          }deg) `,
                          borderRadius:
                            obj.tlborderradius === undefined
                              ? obj?.borderradius
                              : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                          border: `${obj.border}px solid ${obj.borderColor}`,
                          cursor: props.groupSelection
                            ? 'default'
                            : obj.draggable
                            ? 'move'
                            : 'not-allowed',
                          objectFit: 'fill',
                          userSelect: 'none',
                          height: '100%',
                          width : '100%',
                        }}
                        // crossOrigin='anonymous'
                      />
                    </>
                  )}
                  {obj.type === 'image' && obj.subtype === 'globalasset' && (
                    <>
                      <div
                        id={`loader${obj.id}`}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#000000',
                          placeItems: 'center',
                          // font: 1em/1.5 sans-serif,
                          // backgroundImage : 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                          // backgroundRepeat : 'no-repeat',
                          // background :  'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',

                          // animation :'shine 1s infinite',
                          display: 'none',
                          zIndex: 1000,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          class='l1'
                          style={{
                            width: `${Math.min(obj.width, obj.height) / 4}px`,
                            height: `${Math.min(obj.width, obj.height) / 4}px`,
                          }}
                        ></div>
                      </div>
                      <img
                        alt='img'
                        crossOrigin='anonymous'
                        lt='media'
                        dp={
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none'
                        }
                        src={obj.payload}
                        className='textarea assetClass'
                        // draggable={
                        //   props.play &&
                        //   obj.draggable &&
                        //   !props.alignElement.has(index)
                        // }
                        draggable={false}
                        onLoad={() => {
                          const loader = document.getElementById(
                            `loader${obj.id}`
                          );
                          loader.style.display = 'none';
                        }}
                        style={{
                          backgroundColor: obj.backgroundColor,
                          // paddingInline: '10px',
                          color: obj?.textColor,
                          display:
                            props.currentTime >= obj.starttime &&
                            props.currentTime <= obj.stoptime
                              ? 'flex'
                              : 'none', //  css for text label
                          alignItems: 'center', //  css for text label
                          justifyContent: obj?.textalign, //  css for text label
                          overflow: 'hidden', // css for text label
                          // borderColor: obj?.borderColor,
                          resize: 'none',
                          transformStyle: 'preserve-3d',
                          transform: `rotateX(${
                            obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                          }deg) rotateY(${
                            obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                          }deg) `,
                          borderRadius:
                            obj.tlborderradius === undefined
                              ? obj?.borderradius
                              : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                          border: `${obj.border}px solid ${obj.borderColor}`,
                          cursor: props.groupSelection
                            ? 'default'
                            : obj.draggable
                            ? 'move'
                            : 'not-allowed',
                          objectFit: 'fill',
                          height: '100%',
                          width : '100%',
                        }}
                        // crossOrigin='anonymous'
                      />
                    </>
                  )}
                  {obj.type === 'vimage' && (
                    <img
                      alt='img'
                      //crossOrigin='anonymous'
                      lt='media'
                      dp={
                        props.currentTime >= obj.starttime &&
                        props.currentTime <= obj.stoptime
                          ? 'flex'
                          : 'none'
                      }
                      src={vImage}
                      className='textarea assetClass'
                      // draggable={
                      //   props.play &&
                      //   obj.draggable &&
                      //   !props.alignElement.has(index)
                      // }
                      draggable={false}
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        resize: 'none',
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                          height: '100%',
                          width : '100%',
                      }}
                    />
                  )}
                  {obj.type === 'video' && obj.subtype !== 'globalasset' && (
                    <video
                      key={obj.id + 'v'}
                      lt='media'
                      dp={
                        props.currentTime >= obj.starttime &&
                        props.currentTime <= obj.stoptime
                          ? 'flex'
                          : 'none'
                      }
                      crossOrigin='anonymous'
                      // draggable={
                      //   props.play &&
                      //   obj.draggable &&
                      //   !props.alignElement.has(index)
                      // }
                      draggable={false}
                      src={
                        // obj.payload.includes('http')
                        //   ? 
                          obj.payload
                          // : props.pathName === 'createBrandTemplate'
                          // ? `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMP/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${obj.payload}?t=1`
                          // : `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMPLATE/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${obj.payload}?t=1`
                      
                        }
                      sTime={obj.starttime}
                      eTime={obj.stoptime}
                      className='textarea inVideo assetClass'
                      // controls
                      autoPlay
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        resize: 'none',
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        objectFit: 'fill',
                        height: '100%',
                        width : '100%',
                      }}
                    />
                  )}
                  {obj.type === 'video' && obj.subtype === 'globalasset' && (
                    <video
                      key={obj.id + 'v'}
                      lt='media'
                      dp={
                        props.currentTime >= obj.starttime &&
                        props.currentTime <= obj.stoptime
                          ? 'flex'
                          : 'none'
                      }
                      crossOrigin='anonymous'
                      // draggable={
                      //   props.play &&
                      //   obj.draggable &&
                      //   !props.alignElement.has(index)
                      // }
                      draggable={false}
                      src={obj.payload}
                      sTime={obj.starttime}
                      eTime={obj.stoptime}
                      className='textarea inVideo assetClass'
                      // controls
                      autoPlay
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        resize: 'none',
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        objectFit: 'fill',
                        height: '100%',
                        width : '100%',
                      }}
                    />
                  )}
                  {obj.type === 'vVideo' && (
                    <img
                      key={obj.id + 'v'}
                      lt='media'
                      dp={
                        props.currentTime >= obj.starttime &&
                        props.currentTime <= obj.stoptime
                          ? 'flex'
                          : 'none'
                      }
                      draggable={false}
                      src={vVideo}
                      sTime={obj.starttime}
                      eTime={obj.stoptime}
                      className='textarea assetClass'
                      // controls
                      autoPlay
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        resize: 'none',
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        height: '100%',
                        width : '100%',
                      }}
                      alt='V'
                    />
                  )}
                  {(obj.type === 'radio' || obj.type === 'checkbox') && (
                    <input
                      type={obj?.type}
                      typeId = 'input'
                      layerName  = {obj.layername}
                      name={obj?.name}
                      disabled={!props.isRight}
                      value = {obj?.value}
                      className='textarea optionClass'
                      placeholder={obj?.placeholder}
                      defaultChecked={obj?.defaultChecked}
                      style={{
                        userSelect:
                          obj.id === props.itemSelected ? 'text' : 'none',
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none',
                        accentColor: obj.backgroundColor,
                        height: '100%',
                        width: '100%',
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        margin : '0px',
                      }}
                    ></input>
                  )}
                  {obj.type === 'dropdown' && (
                    <select
                      className='textarea optionClass'
                      typeId = 'input'
                      layerName  = {obj.layername}
                      disabled={!props.isRight}
                      placeholder={obj?.placeholder}
                      style={{
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        userSelect: 'none',
                        color: obj?.textColor,
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        textDecoration: obj?.textdecoration,
                        textAlign: obj?.textalign,
                        fontFamily: obj?.fontfamily,
                        fontSize: obj?.fontsize,
                        fontStyle: obj?.fontstyle,
                        fontWeight: obj?.fontweight,
                        letterSpacing: obj?.letterspacing,
                        lineHeight: obj?.lineheight,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        height: '100%',
                        width : '100%',
                      }}
                    >
                      <option
                        key={-1}
                        value={null}
                        style={{
                          userSelect: 'none',
                          display: drag.current ? 'none' : 'block',
                        }}
                      >
                        {obj.name}
                      </option>
                      {obj.optionsarray.map((item, index) => (
                        <option
                          key={index}
                          value={item.Value}
                          style={{
                            userSelect: 'none',
                            display: drag.current ? 'none' : 'block',
                          }}
                        >
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  )}
                  {obj.type === 'button' && (
                    <button
                      className='textarea buttonClass'
                      typeId = 'button'
                      layerName  = {obj.layername}
                      action = {obj.action +","+obj.actionsarray.join(",")}
                      disabled={!props.isRight}
                      placeholder={obj?.placeholder}
                      style={{
                        userSelect: 'none',
                        backgroundColor: obj.backgroundColor,
                        // paddingInline: '10px',
                        color: obj?.textColor,
                        display:
                          props.currentTime >= obj.starttime &&
                          props.currentTime <= obj.stoptime
                            ? 'flex'
                            : 'none', //  css for text label
                        alignItems: 'center', //  css for text label
                        justifyContent: obj?.textalign, //  css for text label
                        overflow: 'hidden', // css for text label
                        // borderColor: obj?.borderColor,
                        transformStyle: 'preserve-3d',
                        transform: `rotateX(${
                          obj.flip === 'N' ? 0 : obj.flip === 'V' ? 180 : 0
                        }deg) rotateY(${
                          obj.flip === 'N' ? 0 : obj.flip === 'H' ? 180 : 0
                        }deg) `,
                        textDecoration: obj?.textdecoration,
                        textAlign: obj?.textalign,
                        fontFamily: obj?.fontfamily,
                        fontSize: obj?.fontsize,
                        fontStyle: obj?.fontstyle,
                        fontWeight: obj?.fontweight,
                        letterSpacing: obj?.letterspacing,
                        lineHeight: obj?.lineheight,
                        borderRadius:
                          obj.tlborderradius === undefined
                            ? obj?.borderradius
                            : `${obj?.tlborderradius}px ${obj?.trborderradius}px ${obj?.blborderradius}px ${obj?.brborderradius}px`,
                        border: `${obj.border}px solid ${obj.borderColor}`,
                        cursor: props.groupSelection
                          ? 'default'
                          : obj.draggable
                          ? 'move'
                          : 'not-allowed',
                        whiteSpace: 'pre',
                        height: '100%',
                        width : '100%',
                      }}
                    >
                      {obj.placeholder.charAt(0) === 'L' && (
                        <div
                          style={{
                            margin: '6px',
                            fill: obj.textColor,
                          }}
                        >
                          <FontAwesomeIcon icon={obj.icon} />
                        </div>
                      )}
                      {obj.name}
                      {obj.placeholder.charAt(0) === 'R' && (
                        <div
                          style={{
                            margin: '6px',
                            fill: obj.textColor,
                          }}
                        >
                          <FontAwesomeIcon icon={obj.icon} />
                        </div>
                      )}
                    </button>
                  )}

                  <div
                    className='resizable-handler'
                    id={'R' + obj.id}
                    style={{
                      position: 'absolute',
                      // right : 0,
                      // bottom : 0,
                      right:
                        parseInt(obj.rotate) < 105 ||
                        parseInt(obj.rotate) >= 290
                          ? 0
                          : parseInt(obj.width) - 14,
                      bottom:
                        parseInt(obj.rotate) < 45
                          ? 0
                          : parseInt(obj.rotate) < 200
                          ? parseInt(obj.height) - 14
                          : 0,
                      width: obj.width < 30 || obj.height < 30 ? 5 : 10,
                      height: obj.width < 30 || obj.height < 30 ? 5 : 10,
                      transform:
                        parseInt(obj.rotate) < 45 || parseInt(obj.rotate) > 290
                          ? `rotate(0deg) `
                          : parseInt(obj.rotate) > 200 &&
                            parseInt(obj.rotate) < 290
                          ? `rotate(90deg) `
                          : parseInt(obj.rotate) > 105 &&
                            parseInt(obj.rotate) < 200
                          ? `rotate(180deg) `
                          : `rotate(270deg) `,
                      display:
                        props.play &&
                        props.itemSelected === obj.id &&
                        obj.draggable &&
                        obj.id !== 'MultiLayer'
                          ? 'block'
                          : 'none',
                      cursor: 'se-resize',
                      zIndex: 100000,
                    }}
                  >
                    {/* <svg
                      width='10'
                      height='10'
                      fill={
                        obj.backgroundColor
                          ? getReverseColor(obj.backgroundColor)
                          : 'transparent'
                      }
                      viewBox='0 0 16 16'
                    >
                      <path d='M6.7 16l9.3-9.3v-1.4l-10.7 10.7z'></path>
                      <path d='M9.7 16l6.3-6.3v-1.4l-7.7 7.7z'></path>
                      <path d='M12.7 16l3.3-3.3v-1.4l-4.7 4.7z'></path>
                      <path d='M15.7 16l0.3-0.3v-1.4l-1.7 1.7z'></path>
                    </svg> */}
                    <svg id="Layer_1" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path id="Corner" d="m22 5c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3 3 1.3 3 3zm-3 4c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm-14 7c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm7 0c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm7 0c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm-7-7c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z" fill="#1089ff"/></svg>
                  </div>
                  <div
                    className='rotate--handler'
                    draggable={false}
                    id={'R' + obj.id}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let index = props.canvaContent.findIndex(function (o) {
                        return obj.id === o.id;
                      });
                      const parentdiv = props.parentdivRef.current;
                      const parentdivRect = parentdiv.getBoundingClientRect();
                      function getAngle(x, y) {
                        const centerX =
                          props.canvaContent[index].left +
                          props.canvaContent[index].width / 2;
                        const centerY =
                          props.canvaContent[index].top +
                          props.canvaContent[index].height / 2;
                        console.log(
                          'Angle',
                          Math.atan2(y - centerY, x - centerX)
                        );
                        return Math.atan2(y - centerY, x - centerX);
                      }
                      const Rotate = (e) => {
                        const currentAngle = getAngle(
                          e.clientX - parentdivRect.left,
                          e.clientY - parentdivRect.top
                        );
                        console.log('SA', currentAngle);
                        let rotation =
                          ((currentAngle - startAngle + initialRotation) *
                            180) /
                          Math.PI;
                        rotation = (rotation + 360) % 360;
                        rotation = rotation < 0 ? 360 + rotation : rotation;
                        props.canvaContent[index].rotate = Math.trunc(rotation);
                        props.elementProperties.width =
                        props.canvaContent[index].width;
                      props.elementProperties.height =
                        props.canvaContent[index].height;
                      props.elementProperties.border =
                        props.canvaContent[index].border;
                      props.elementProperties.borderradius =
                        props.canvaContent[index].borderradius;
                      props.elementProperties.rotate =
                        props.canvaContent[index].rotate;
                      props.elementProperties.flip =
                        props.canvaContent[index].flip;
                      props.elementProperties.X =
                        props.canvaContent[index].left;
                      props.elementProperties.Y =
                        props.canvaContent[index].top;
                      props.elementProperties.type =
                        props.canvaContent[index].type;
                      props.elementProperties.subtype = 
                         props.canvaContent[index].subtype;
                      props.elementProperties.layername = 
                         props.canvaContent[index].layername;
                      props.elementProperties.placeholder = 
                         props.canvaContent[index].placeholder;
                      props.elementProperties.cltoggle = 
                         props.canvaContent[index].cltoggle;
                      props.elementProperties.emtoggle = 
                         props.canvaContent[index].emtoggle;
                      props.elementProperties.characterlimit = 
                         props.canvaContent[index].characterlimit;
                      props.elementProperties.errormessage = 
                         props.canvaContent[index].errormessage;
                      props.elementProperties.name = 
                         props.canvaContent[index].name;
                        props.handleContent([...props.canvaContent]);
                        props.handleProperties(props.elementProperties);
                      };
                      let initialRotation =
                        (props.canvaContent[index].rotate * Math.PI) / 180;
                      let startAngle =
                        // props.canvaContent[index].rotate;
                        getAngle(
                          e.clientX - parentdivRect.left,
                          e.clientY - parentdivRect.top
                        );
                      console.log(
                        'SA',
                        startAngle,
                        (startAngle * 180) / Math.PI
                      );
                      window.addEventListener('mousemove', Rotate);
                      window.addEventListener('mouseup', (e) => {
                        window.removeEventListener('mousemove', Rotate);
                      });
                    }}
                    style={{
                      position: 'absolute',
                      left: obj.width <= 15 ? -5 : (obj.width - 20) * 0.5,
                      top: -35,
                      zIndex: obj?.zindex,
                      width: 10,
                      height: 10,
                      display:
                        props.play &&
                        props.itemSelected === obj.id &&
                        obj.draggable &&
                        obj.id !== 'MultiLayer'
                          ? 'block'
                          : 'none',
                      cursor: 'crosshair',
                    }}
                  >
                    {/* <svg
                      width='12'
                      height='45'
                      viewBox='0 0 12 45'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line x1='5.5' y1='35' x2='5.5' y2='4' stroke='#1687EF' />
                      <circle cx='6' cy='6' r='6' fill='#1687EF' />
                    </svg> */}
                    {/* <svg
                      width='15'
                      height='40'
                      viewBox='0 0 46 77'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_15186_12411)'>
                        <path
                          d='M40.8971 25.3992C40.7087 25.6254 40.4107 25.7603 40.0973 25.7268C39.5896 25.6751 39.2249 25.2217 39.2737 24.7176C39.8336 19.2871 37.6695 13.9646 33.4846 10.4789C27.3344 5.35648 18.4658 5.41706 12.4019 10.6275C12.0174 10.9597 11.4384 10.9145 11.1061 10.53C10.7738 10.1455 10.819 9.56654 11.2036 9.23427C17.9517 3.43236 27.8192 3.36564 34.6622 9.06511C39.3172 12.9423 41.7253 18.8632 41.1037 24.9067C41.0849 25.0946 41.0089 25.2649 40.8971 25.3992Z'
                          fill='#212AE0'
                        />
                        <path
                          d='M17.6717 12.2209C17.4391 12.5001 17.0551 12.6233 16.6888 12.5097L11.1679 10.779C10.7173 10.6372 10.4469 10.1784 10.5422 9.71902L11.7304 3.90794C11.7546 3.78942 11.802 3.67685 11.8698 3.57667C11.9377 3.47649 12.0246 3.39067 12.1256 3.32412C12.2266 3.25757 12.3398 3.2116 12.4586 3.18884C12.5774 3.16608 12.6996 3.16697 12.818 3.19147C12.9365 3.21573 13.0491 3.26312 13.1493 3.33094C13.2495 3.39876 13.3353 3.48567 13.4018 3.58669C13.4684 3.68772 13.5144 3.80088 13.5371 3.91969C13.5599 4.03851 13.559 4.16065 13.5345 4.27912L12.5104 9.27502L17.2378 10.758C17.722 10.9098 17.9913 11.4275 17.8395 11.9116C17.8077 12.0289 17.7482 12.129 17.6717 12.2209Z'
                          fill='#212AE0'
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M39.509 28.4106C39.3532 28.2231 39.1293 28.1051 38.8866 28.0825C38.6439 28.0599 38.4021 28.1346 38.2144 28.2902C38.0269 28.446 37.9089 28.6698 37.8863 28.9125C37.8638 29.1553 37.9384 29.397 38.094 29.5848C38.2498 29.7722 38.4736 29.8902 38.7164 29.9128C38.9591 29.9354 39.2009 29.8607 39.3886 29.7052C39.7802 29.3788 39.8354 28.8022 39.509 28.4106ZM37.8307 31.9277C37.6749 31.7403 37.4511 31.6223 37.2083 31.5997C36.9656 31.5771 36.7238 31.6518 36.5361 31.8073C36.3486 31.9631 36.2307 32.187 36.2081 32.4297C36.1855 32.6724 36.2602 32.9142 36.4157 33.1019C36.5715 33.2894 36.7954 33.4074 37.0381 33.43C37.2808 33.4526 37.5226 33.3779 37.7103 33.2223C38.1019 32.8959 38.1571 32.3193 37.8307 31.9277Z'
                          fill='#212AE0'
                        />
                        <path
                          d='M34.9074 36.6574C34.8751 36.6963 34.8391 36.7323 34.7997 36.7653C28.0516 42.5672 18.184 42.6339 11.3411 36.9344C6.68604 33.0572 4.27799 27.1364 4.89958 21.0928C4.95127 20.5851 5.40469 20.2204 5.90886 20.2691C6.41656 20.3208 6.78125 20.7743 6.7325 21.2784C6.16966 26.7124 8.33372 32.035 12.5187 35.5206C18.6688 40.643 27.5375 40.5825 33.6014 35.3721C33.9859 35.0398 34.5648 35.085 34.8971 35.4695C35.1964 35.8146 35.1901 36.3181 34.9074 36.6574Z'
                          fill='#212AE0'
                        />
                        <path
                          d='M34.0744 42.4963C33.9435 42.6544 33.7634 42.7641 33.5629 42.8079C33.3624 42.8516 33.153 42.827 32.9681 42.7378C32.7833 42.6486 32.6336 42.5001 32.5431 42.3159C32.4526 42.1317 32.4263 41.9225 32.4686 41.7216L33.4892 36.7228L28.7618 35.2398C28.2776 35.088 28.0083 34.5703 28.1601 34.0862C28.312 33.602 28.8296 33.3327 29.3138 33.4845L34.8346 35.2153C35.2852 35.3571 35.5557 35.8158 35.4604 36.2753L34.2722 42.0863C34.238 42.2495 34.1686 42.3832 34.0744 42.4963Z'
                          fill='#212AE0'
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M6.49153 17.5942C6.64733 17.7817 6.87118 17.8997 7.11391 17.9223C7.35664 17.9448 7.59841 17.8702 7.78611 17.7146C7.97361 17.5588 8.0916 17.335 8.11417 17.0922C8.13675 16.8495 8.06206 16.6077 7.90652 16.42C7.75072 16.2325 7.52687 16.1145 7.28414 16.092C7.04141 16.0694 6.79964 16.1441 6.61193 16.2996C6.21976 16.6195 6.16808 17.1991 6.49153 17.5942ZM8.16978 14.077C8.32558 14.2645 8.54943 14.3825 8.79216 14.4051C9.03489 14.4277 9.27666 14.353 9.46436 14.1974C9.85595 13.8711 9.90763 13.2915 9.58477 12.9029C9.26192 12.5142 8.67882 12.4596 8.29019 12.7824C7.90155 13.1053 7.84633 13.6819 8.16978 14.077Z'
                          fill='#212AE0'
                        />
                      </g>
                      <rect
                        x='23'
                        y='41'
                        width='2'
                        height='36'
                        fill='#212AE0'
                      />
                      <defs>
                        <clipPath id='clip0_15186_12411'>
                          <rect width='46' height='46' fill='white' />
                        </clipPath>
                      </defs>
                    </svg> */}
                    <svg
                      width='20'
                      height='35'
                      viewBox='0 0 60 84'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='23'
                        y='48'
                        width='5'
                        height='36'
                        fill='#212AE0'
                      />
                      <g clip-path='url(#clip0_15220_12194)'>
                        <path
                          d='M30.032 17.1371L31.9821 12.6825C28.5603 11.6723 25.0367 11.6021 21.7404 12.4475L23.528 17.019C25.6366 16.573 27.8498 16.5946 30.032 17.1371ZM19.0018 18.7891L17.2141 14.2176C14.2189 15.8325 11.6774 18.2744 9.84867 21.3378L14.3033 23.2878C15.5385 21.4089 17.1499 19.8916 19.0018 18.7891ZM7.86711 25.7785C6.84554 29.2047 6.82953 32.7334 7.67488 36.0296L12.2465 34.2419C11.8002 32.1333 11.8376 29.927 12.3847 27.7561L7.86711 25.7785ZM45.3268 26.5244L52.2745 23.8075L37.9506 17.5369L31.9064 31.7724L40.7552 28.3121C42.1892 35.1484 38.5266 42.2296 31.8051 44.858C25.0836 47.4864 17.589 44.7682 14.0051 38.7726L9.43362 40.5602C14.0451 49.0502 24.3415 52.9949 33.575 49.3842C42.8086 45.7734 47.6978 35.8905 45.3268 26.5244Z'
                          fill='#212AE0'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_15220_12194'>
                          <rect
                            width='46'
                            height='46'
                            fill='white'
                            transform='translate(43.0439 0.203125) rotate(68.6422)'
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </>
            );
          })}
          {props.audioData?.length > 0 && props.playbgmusic && (
            <audio
              ref={bgMusic}
              loop={props.musicLoop}
              src={
                props.pathName === 'createBrandTemplate'
                  ? `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMP/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${props.audioData[0].name}`
                  : `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMPLATE/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${props.audioData[0].name}`
              }
            ></audio>
          )}
          {props?.musicData?.map((item, index) => {
            if (item.checked) {
              return (
                <audio
                  loop={true}
                  className='inAudio'
                  stime={item.starttime}
                  etime={item.stoptime}
                  src={
                    props.pathName === 'createBrandTemplate'
                      ? `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMP/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${props.musicData[index].name}`
                      : `${props.templateInfo[0]?.BRANDPAGEBASEPATH}TEMPLATE/${props.templateInfo[0]?.BRANDPAGEID}/CONTENT/${props.musicData[index].name}`
                  }
                ></audio>
              );
            } else {
              return null;
            }
          })}
        </>
      </div>
    </div>
  );
}

export default Canva;
