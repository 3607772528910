import React from "react";
import {  Modal } from "antd";
import logoutNewIcon from '../../assests/commonIcons/logoutNewIcon.svg'
import '../styles/commonStyles.css'
import SimpleButton from "./SimpleButton";
import GhostButton from "./GhostButton";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import { logoutUserApi } from "../../api/api_updateData";
import useWindowWidth from "../hooks/useWindowWidth";
import { toast } from "react-toastify";

const LogoutModal = ({ modalVisible, closeModal }) => {
  const navigate = useNavigate();
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isMediumScreen = windowWidth >= 600;
  // const [modalopen, setModalopen] = useState(false);

  // const goToLogin = () => {
  //   navigate(`${process.env.REACT_APP_BASE_PATH}/logout`);
  // }


  const { mutate: logoutUserMutation } = useMutation(
    (logoutData) => logoutUserApi(logoutData),
    {
      onSuccess: (data) => {
        debugger
        // const parsedData = JSON.parse(data.DATA);
        if (data.STATUS === "1000") {
          console.log(data, "DATA")
          toast.success(data.DESCRIPTION);
          sessionStorage.clear();
          localStorage.clear();
          navigate(`${process.env.REACT_APP_BASE_PATH}/logout`);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const handleLogout = () => {
    const sessionuserid = localStorage.getItem("sessionUserId");
    // debugger
    const logoutData = {
      sessionuserid: sessionuserid,
    }

    logoutUserMutation(logoutData);
  };

  return (
    <>
      <div className="logout-modal flex-shrink-0">
        <Modal
          className="logout-modal"
          //   bodyStyle={{ top: 20, padding: 20 }}
          style={{ top: isMediumScreen ? 20 : 79, padding: 20 }}
          open={modalVisible}
          closeIcon={false}
          // onCancel={closeModal}
        >
          <div className="flex flex-col items-center">
            <span className="cursor-pointer">
              <img
                className="w-16 h-16"
                height="28px"
                src={logoutNewIcon}
                alt="logout icon"
              />
            </span>
            <span className="pb-2 pt-4 text-[#1F1F1F] font-inter text-lg font-medium select-none text-center">Are you sure you want to Logout?</span>
            <span className="text-[#757575] font-normal px-2 pb-4 text-center font-inter text-sm leading-5 select-none">
            Ready to log out? Your current session will end, and any unsaved changes will be lost.
            </span>
            <span className="flex items-center">
              <GhostButton
                key="logout"
                onClick={() => {handleLogout();}}
                label="Yes, Logout "
                className="white-bg-btn mr-6 text-blue-500 font-inter text-sm font-normal leading-5 rounded-md border border-blue-500"
              />
              <span>
                <SimpleButton
                  key="cancel"
                  type="primary"
                  onClick={closeModal}
                  label="Cancel"
                />
              </span>
            </span>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LogoutModal;
