import React, { useState } from "react";
import questionMark from "../../assets/questionMark.svg";
import { sidebarData } from "../data";
import ToolBar from "./ToolBar";
import { Divider, Popover, Tooltip } from "antd";
import "../../Styling/sidebar.css";
import cross from "../../assets/Cross.svg";
import { useEffect } from "react";
const Sidebar = ({
  changeMode,
  play,
  handleDropX,
  handleDropY,
  handleZ,
  handleId,
  id,
  setRightClicked,
  templateInfo,
  imageData,
  setImageData,
  videoData,
  setVideoData,
  pathName,
  musicData,
  setMusicData,
  globalmusicData,
  setGlobalMusicData,
  currentTime,
  setTemplateDetails,
  setLayerButton,
  setSettingButton,
  setMusicButton,
  fieldEnabled,
  index,
  canvaContent,
  handleContent,
  itemSelected,
  payload,
  setPayload,
  isModalOpen,
  setShowDialog,
  isRight,
  vis,
  imageChange,
  setImageChange,
  templateId,
  userId,
  templateName
}) => {
  const [selectedSidebarItem, setSelectedSidebarItem] = useState(0);
  const [toolSearch, setToolSearch] = useState("");
  const [openHelp, setOpenHelp] = useState(false);
  const [openShortCut,setOpenShortCut] = useState(false);

  useEffect(() => {
    // Attach event listener for keydown event
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  

  function handleKeyPress(e) {
    if (isRight) {
    if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
      if (e.key === "l" || e.key === "L") {
        e.preventDefault();
        setSelectedSidebarItem(0);
        setToolSearch("");
      } else if (e.key === "i" || e.key === "I") {
        e.preventDefault();
        setSelectedSidebarItem(1);
        setToolSearch("");
      } else if (e.key === "h" || e.key === "H") {
        e.preventDefault();
        setSelectedSidebarItem(2);
        setToolSearch("");
      } else if (e.key === "a" || e.key === "A") {
        e.preventDefault();
        setSelectedSidebarItem(3);
        setToolSearch("");
      } else if (e.key === "b" || e.key === "B") {
        e.preventDefault();
        setSelectedSidebarItem(4);
        setToolSearch("");
      } else if (e.key === "o" || e.key === "O") {
        e.preventDefault();
        setSelectedSidebarItem(5);
        setToolSearch("");
      }
    }
    }
  }
  function handleOpenHelp() {
    setOpenHelp(!openHelp);
  }
  function handleOpenShortCut(e){
    
    setOpenShortCut(!openShortCut);
  }
  return (
    <div
      className={`flex h-[calc(100vh-64px)] left-drawer ${!vis?"open":""}`}
      style={{
        userSelect: "none",
      }}
      onClick={() => {
        setRightClicked("");
      }}
    >
      <div className="bg-[#050506] w-[60px]  px-1 py-5 border-solid border-r-2 border-r-[#2B2B2C] flex flex-col justify-between">
        <div className="flex flex-col gap-[25px]">
          {sidebarData.map((item, index) => (
            <Tooltip
              className=""
              key={index}
              title={item.name}
              color="#2B2B2C"
              placement="left"
              overlayStyle={{
                boxShadow: "2px",
              }}
            >
              <div
                key={index}
                className={`${
                  selectedSidebarItem === index
                    ? "bg-[#056DE8] hover:!bg-[#056DE8]"
                    : ""
                } flex items-center justify-center p-2 rounded hover:bg-[#1b1b1b]`}
              >
                <img
                  onClick={() => {
                    setSelectedSidebarItem(index);
                    setToolSearch("");
                  }}
                  className="cursor-pointer w-7 h-7 "
                  src={item.image}
                  alt={item.name}
                />
              </div>
            </Tooltip>
          ))}
        </div>
        {/* {
          <div className="flex justify-center">
            <img
              className=" justify-center items-center cursor-pointer"
              src={questionMark}
              alt="help"
            />
          </div>
        } */}
        <div className="template-popover flex justify-center">
          <Popover
            placement="left"
            arrow={false}
            open={openHelp}
            onOpenChange={handleOpenHelp}
            content={
              <div
                className="bg-[#050506] w-[250px] h-[180px] absolute left-[45px] bottom-[-20px] overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div className="flex justify-between p-4 py-2 pr-3">
                  <div className="text-[#fff] font-semibold"> Editor Help</div>
                  <img
                    className="cursor-pointer"
                    src={cross}
                    alt="cross"
                    onClick={() => {
                      setOpenHelp(false);
                    }}
                  />
                </div>
                <Divider className="!m-0" />
                <div className="text-[#989898] text-[12px]">
                  <div className="flex justify-between px-4 py-1  pr-3">
                    Help Center
                  </div>
                  <div
                    className="flex justify-between px-4 py-1 pr-3 cursor-pointer hover:text-cyan-700"
                    onClick={() => {
                      const newTab = window.open(
                        "https://www.youtube.com/@VSPAGY",
                        "_blank"
                      );
                      newTab.focus();
                    }}
                  >
                    Youtube Videos
                  </div>
                  <div className="flex justify-between px-4 py-1  pr-3">
                    Submit Feedback
                  </div>
                  <div className="flex justify-between px-4 py-1 pr-3">
                    New Releases
                  </div>

                  <Popover
                    placement="left"
                    arrow={false}
                    open={openShortCut}
                    onOpenChange={handleOpenShortCut}
                    content={
                      <div
                        className="bg-[#050506] w-[250px] h-[443px] absolute left-[5px] bottom-[-20px] "
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        <div className="flex justify-between p-3 py-2 pr-3">
                          <div className="text-[#fff] font-semibold">
                            
                            Editor ShortCut Keys
                          </div>
                          <img
                            className="cursor-pointer"
                            src={cross}
                            alt="cross"
                            onClick={()=>{
                              setOpenShortCut(false);
                            }}
                          />
                        </div>
                        <Divider className="!m-0" />
                        <div className="h-[400px] overflow-auto  scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]">
                        <div className="">
                          <div className="text-[#ffffff] text-[14px] font-semibold ml-[12px] my-1 mb-2">
                             Basics
                          </div>
                          <div className="">
                          <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Delete Layer</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[66px] bg-[#D9D9D9] text-[#000000] text-center rounded">Delete</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Save Template</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">S</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Undo</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">Z</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Redo</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">Y</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Clone</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">J</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Template Info</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">I</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="text-[#ffffff] text-[14px] font-semibold ml-[12px]  my-2">
                             Global Settings
                          </div>
                          <div className="">
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Video Settings</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">G</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Layers</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">L</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[77px] ml-[12px] mt-[2px] mr-[69px]">Audio</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">A</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="text-[#ffffff] text-[14px] font-semibold ml-[12px]  my-2">
                             Components
                          </div>
                          <div className="">
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[70px] ml-[12px] mt-[2px] mr-[33.5px]">Text Label</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Shift</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">L</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[70px] ml-[12px] mt-[2px] mr-[33.5px]">Text Input</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Shift</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">I</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[70px] ml-[12px] mt-[2px] mr-[33.5px]">Hotspot</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Shift</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">H</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[70px] ml-[12px] mt-[2px] mr-[33.5px]">Assets</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Shift</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">A</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[70px] ml-[12px] mt-[2px] mr-[33.5px]">Buttons</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Shift</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">B</div>
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="text-[#ffffff] text-[12px] w-[70px] ml-[12px] mt-[2px] mr-[33.5px]">Options</div>
                              <div className="flex justify-evenly ">
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Ctrl</div>
                              <div className="w-[36px] bg-[#D9D9D9] text-[#000000] mr-2 text-center rounded">Shift</div>
                              <div className="w-[22px] bg-[#D9D9D9] text-[#000000] text-center rounded">O</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    }
                    trigger="hover"
                  >
                    <div className="flex justify-between px-4 py-1 pr-3 cursor-pointer hover:text-cyan-700"
                     //onClick={handleOpenHelp}
                    >
                      ShortCut Keys
                    </div>
                  </Popover>
                </div>
              </div>
            }
            trigger="click"
          >
            <img
              className=" justify-center items-center cursor-pointer"
              src={questionMark}
              alt="help"
            />
          </Popover>
        </div>
      </div>
      <ToolBar
        changeMode={changeMode}
        play={play}
        handleDropX={handleDropX}
        handleDropY={handleDropY}
        selectedSidebarItem={selectedSidebarItem}
        handleZ={handleZ}
        handleId={handleId}
        id={id}
        toolSearch={toolSearch}
        setToolSearch={setToolSearch}
        setRightClicked={setRightClicked}
        templateInfo={templateInfo}
        imageData={imageData}
        setImageData={setImageData}
        videoData={videoData}
        setVideoData={setVideoData}
        pathName={pathName}
        musicData={musicData}
        setMusicButton={setMusicButton}
        setMusicData={setMusicData}
        globalmusicData={globalmusicData}
        setGlobalMusicData={setGlobalMusicData}
        currentTime={currentTime}
        fieldEnabled={fieldEnabled}
        // setFieldEnabled={setFieldEnabled}
        index = {index}
        canvaContent = {canvaContent}
        handleContent = {handleContent}
        itemSelected = {itemSelected}
        payload = {payload}
        setPayload = {setPayload}
        isModalOpen = {isModalOpen}
        setShowDialog = {setShowDialog}
        isRight = {isRight}
        imageChange = {imageChange}
        setImageChange = {setImageChange}
        templateId={templateId}
        userId={userId}
        templateName={templateName}
      />
    </div>
  );
};

export default Sidebar;
