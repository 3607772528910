import React from "react";
import SimpleButton from "../../common/components/SimpleButton";
import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { concernData } from "./data";
import { useState } from "react";
import GlobalSelecter from "../common/GlobalSelecter";
import "../../common/styles/userModule/SupportStyles.css";
import useSessionChecker from "../../common/functions/useSessionChecker";
import { useNavigate } from "react-router-dom";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import { toast } from "react-toastify";

const Support = () => {

  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  const [concern, setConcern] = useState("--Select Your Concern--");
  const [remarks, setRemarks] = useState('');

  const toEmail = "support@vspagy.com";

  const handleEmailClick = () => {
    // const to = 'abhijeet@gmail.com';
    const subject = concern;
    const body = remarks;
    const mailtoLink = `mailto:${toEmail}?subject=${subject}&body=${body}`;
    if(concern !== '--Select Your Concern--' && remarks !== '') {
      window.location.href = mailtoLink;
    } else {
      toast.error("Please fill the form");
    }
    setRemarks('');
    setConcern("--Select Your Concern--");
  };

 

    const openEmailClient = () => {
      window.location.href = `mailto:${toEmail}`;
    };
  return (
    <div className="pb-8">
      <div className="px-4 pt-0 pb-8 flex flex-col">
        <span className="text-[#323232] text-base font-bold">
          RAISE YOUR CONCERN
        </span>
        <span className="text-[#1F1F1F] text-sm mt-3">
          We are always glad to hear some feedback. It allows us to understand
          strengths and areas for improvement, allowing us to make positive
          changes and improve user experience.
        </span>
        <Form layout="vertical" className="mt-8 md:w-[22rem]">
          <Form.Item
            label="Type of concern"
            className="ant-d-select-s !rounded-[4px] text-[#131313] font-medium text-sm mb-8"
          >
            <GlobalSelecter
              setConcern={setConcern}
              type="concern"
              show={true}
              value={concern}
              // defaultValue="--Select Industry--"
              data={concernData}
              onChange={(e) => setConcern(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Remarks (If any)"
            className="!rounded-[4px] text-[#131313] font-medium text-sm mb-8"
          >
            <TextArea
              rows={4}
              style={{ height: 60, width: 352, resize: "none" }}
              className="px-3 w-[18rem] h-10 rounded-[4px] scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full
                scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            />
          </Form.Item>
        </Form>
        <span className="">
          <SimpleButton
            type="primary"
            size="large"
            label="Submit"
            className="w-fit text-base font-normal"
            onClick={handleEmailClick}
          />
        </span>
      </div>
      <div className="bg-[#FEFEF2] flex flex-col mt-8 p-4 w-full">
        <span className="text-[#323232] text-base font-bold">
          CUSTOMER SUPPORT
        </span>
        <span className="text-[#1F1F1F] text-sm mt-3">
          We are here to help you with any issues you may be experiencing with
          our application. If you have an issue or question that is not
          addressed in our FAQ section, you can submit a support request by
          mailing us. Please provide us with as much detail as possible so we
          can assist you more efficiently.
        </span>
        <span className="mt-3">
          <SimpleButton
            type="primary"
            onClick={openEmailClient}
            size="large"
            label="Email Us"
            className="w-fit text-base font-medium"
          />
        </span>
      </div>
    </div>
  );
};

export default Support;
