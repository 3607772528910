import { Button } from "antd";
import React from "react";
// import { useNavigate } from "react-router-dom";

const NoDataFound = ({
  svgImage,
  mainHeading,
  description,
  navigatePath,
  type,
  onClick,
  danger,
  size,
  disabled,
  htmlType,
  label,
}) => {
  // const navigate = useNavigate();

  // const navigateToPath = () => {
  //   navigate(`${process.env.REACT_APP_BASE_PATH}/${navigatePath}`);
  // };

  console.log(onClick, "O CLICK")
  return (
    <>
      <span className="text-center flex justify-center">
        <img src={svgImage} alt={svgImage} />
      </span>
      <span className="text-[#4B4B4B] text-xl font-medium pt-4">
        {mainHeading}
      </span>
      <span className="text-[#7A7A7A] text-[15px] font-normal w-[480px] leading-5 text-center pt-1">
        {description}
      </span>
      <span className="pt-4">
        <Button
          type={type}
          danger={danger}
          onClick={onClick}
          size={size}
          disabled={disabled}
          htmlType={htmlType}
          className="rounded-[4px] bg-transparent border-[#1C68EB] text-[#1C68EB] hover:bg-gradient-to-r from-[#f6f9f6] to-[#F2F7FF] hover:shadow-md hover:shadow-[#155fff14]"
        >
          {label}
        </Button>
      </span>
    </>
  );
};

export default NoDataFound;
