import React, { useState } from "react";
import SimppleButtonIcon from "../../common/components/SimpleButtonIcon";
// import Download from "../../assests/commonIcons/userModuleIcons/Download.svg";
import { ReactComponent as Download } from "../../assests/commonIcons/userModuleIcons/Download.svg";
import PlusCircles from "../../assests/commonIcons/userModuleIcons/plus-circle.svg";
import Delete from "../../assests/commonIcons/userModuleIcons/Delete.svg";
import { membersData } from "./data";
import TeammatesDialog from "../common/TeammatesDialog";
const Member = (props) => {
  return (
    <div
      className="w-[807px] mt-[24px]"
      style={{
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex">
        <div className="w-[100px] ml-[26px] mt-[24px] mr-[660px]">
          <div
            className="text-[12px] text-white font-medium leading-4  text-center rounded-2xl px-[1px] py-[4px]"
            style={{
              backgroundColor:
                props.obj.member === true ? "#3FDAB5" : "#FFA800",
            }}
          >
            {props.obj.member === true ? "Member" : "Pending"}
          </div>
        </div>
        <img
          className="mr-[16px] mt-[24px] cursor-pointer"
          src={Delete}
          alt="delete"
          onClick={() => {
            let arr = [...props.member];
            arr.splice(props.index, 1);
            props.setMember([...arr]);
          }}
        />
      </div>
      <div className="mt-[11px] mb-[2px] font-medium text-[18px] pl-[26px] ">
        {props.obj.name}
      </div>
      <div className="flex pl-[26px] text-[15px] pb-[16px]">
        <div className="pr-[20px] pt-[10px] w-[200px]">{props.obj.email}</div>
        <div className="pr-[40px] pt-[10px]">
          <span>
            {props.obj.member === true ? "Joined On " : "Requested On "}
          </span>
          Jan 22, 2023
        </div>
        {props.obj?.credits && (
          <div className="pr-[40px] pt-[10px]">
            <span className="font-medium">{props.obj?.credits} </span>credits
            used
          </div>
        )}
        {props.obj?.credits && (
          <div className="mr-5 mt-1 flex items-center font-normal fill-secondary-blue text-secondary-blue text-sm cursor-pointer hover:text-primary-blue hover:underline hover:fill-primary-blue">
            <Download className="mr-1" fill="" />
            <span className="mt-[2px]">Download Report</span>
          </div>
        )}
      </div>
    </div>
  );
};

const Teammates = () => {
  const [dialog, setDialog] = useState(false);
  const [member, setMember] = useState(membersData);
  return (
    <>
      <div>
        <div
          className="ownerdetail w-[807px]"
          style={{
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
            background:
              "linear-gradient(80.05deg, #DDFCC3 -6.03%, #E4FDF5 26.16%)",
            borderRadius: "4px",
          }}
        >
          <div className="w-[100px] pl-[26px] pt-[24px]">
            <div className="text-[12px] text-black font-medium leading-4 bg-white text-center rounded-2xl px-[1px] py-[4px]">
              Owner
            </div>
          </div>
          <div className="pl-[26px] pt-[18px]">
            <div className="font-semibold text-[20px] text-black">
              Prabhat Kumar
            </div>
            <div className="flex text-[15px] font-normal pt-[10px]">
              <div className="pr-[42px]">prabhat@vspagy.com</div>
              <div className="pr-[42px]">Joined on Jan 22, 2023</div>
              <div className="">
                <span className="font-medium">144 </span>out of 600,000 credits
                used
              </div>
            </div>
          </div>
          <div className="pt-[12px] pb-[18px] pl-[24px] ">
            <div className="mr-5 mt-1 flex items-center font-normal fill-secondary-blue text-secondary-blue text-sm cursor-pointer hover:text-primary-blue hover:underline hover:fill-primary-blue">
              <Download className="mr-1" fill="" />
              <span className="mt-[2px]">Download Report</span>
            </div>

            {/* <SimppleButtonIcon
            label="Download Report"
            image={Download}
            type="primary"
          /> */}
          </div>
        </div>
        <div className="mt-[68px]">
          <div className="text-[#313131] font-bold">TEAMMATES</div>
          <div className="flex">
            <div className="text-[#7A7A7A] pt-[8px] pr-[309px] text-sm">
              Add teammates to collaborate on projects.
            </div>
            <div className="text-[#2E2E2E] pt-[8px] pr-[17px] text-sm">
              2/3 invites left
            </div>
            <div>
              <SimppleButtonIcon
                onClick={() => {
                  setDialog(true);
                }}
                label={"Invite"}
                image={PlusCircles}
                type={"primary"}
                className={"text-[#FFFFFF] font-semibold"}
                buttonclassName={"bg-[#1C68EB]"}
              />
            </div>
          </div>
        </div>

        <div className="members mb-[20px]">
          {member.map((obj, index) => {
            return (
              <Member
                obj={obj}
                index={index}
                member={member}
                setMember={setMember}
              />
            );
          })}
        </div>
      </div>
      <TeammatesDialog
        setDialog={setDialog}
        dialog={dialog}
        member={member}
        setMember={setMember}
      />
    </>
  );
};

export default Teammates;
