import { Divider } from "antd";
import React from "react";
import { useState } from "react";
import ExitAnimation from "./ExitAnimation";
import StartAnimation from "./StartAnimation";

const Animation = (props) => {
  const [effectType, setEffectType] = useState("entry");
  return (
    <div className="mt-4">
      <div className="flex mb-2 gap-12 justify-center">
        <div
          className={effectType === "entry" ? "font-semibold cursor-pointer" : "text-sm cursor-pointer"}
          onClick={() => setEffectType("entry")}
        >
          Entry Effect
        </div>
        <div
          className={effectType === "exit" ? "font-semibold cursor-pointer" : "text-sm cursor-pointer"}
          onClick={() => setEffectType("exit")}
        >
          Exit Effect
        </div>
      </div>
      <Divider className="!m-0 bg-[#2b2b2c]" />
      {effectType === "entry" ? (
        <StartAnimation
          animationEntrySpeed = {props.animationEntrySpeed}
          setAnimationEntrySpeed = {props.setAnimationEntrySpeed}
          entryAnimation  = {props.entryAnimation}
          setEntryAnimation  = {props.setEntryAnimation}
          lastAnimation = {props.lastAnimation}
          setLastAnimation = {props.setLastAnimation}
        />
      ) : (
        <ExitAnimation
          animationExitSpeed = {props.animationExitSpeed}
          setAnimationExitSpeed = {props.setAnimationExitSpeed}
          exitAnimation  = {props.exitAnimation}
          setExitAnimation = {props.setExitAnimation}
          lastAnimation = {props.lastAnimation}
          setLastAnimation = {props.setLastAnimation}
        />
      )}
    </div>
  );
};

export default Animation;
