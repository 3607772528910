import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// LIBRARY IMPORT **************
import { toast } from "react-toastify";
import { Col, Row, Form, Input, Spin, Divider } from "antd";

// COMPONENT/FUNCTION/IMAGE/CSS IMPORT **************
import { createSession } from "../../common/functions/Session";
import SimpleButton from "../../common/components/SimpleButton";
import Banner from "../common/Banner";
// import newVspagyLogo from "../../assests/commonImages/new-vspagy-logo.svg";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import "../../common/styles/userModule/LoginStyles.css";
// import googleIconSvg from "../../assests/commonIcons/userModuleIcons/googleIconSvg.svg";

// API AND REACT QUERY **************
import { useMutation } from "react-query";
import { loginCustomer } from "../../api/api_updateData";
import googleLogo from "../../assests/commonImages/google-logo.png";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import clevertap from "clevertap-web-sdk";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import useSessionChecker from "../../common/functions/useSessionChecker";

export default function Login({
  forgotPasswordClicked,
  setForgotPasswordClicked
}) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useSessionChecker(navigate);
  useCurrentUrl();

  const urlParams = new URLSearchParams(window.location.search);
  const utmParam = urlParams.get('utm');

  // ********** STATES DEFINED ***************
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");

  const userAgent = navigator.userAgent;

  const Forgot_Password_Button_Click = () => {
    clevertap.event.push("Forgot_Password_Button_Click", {
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Forgot_Password_Button_Click EVENT CLICKED");
  };

  const handleForgotPasswordClick = () => {
    // console.log(forgotPasswordClicked, "FP CLICKED IN LOGIn");
    Forgot_Password_Button_Click();
    setForgotPasswordClicked(true);
  };

  useEffect(() => {
    const checkSessionUserId = () => {
      const sessionuserid = localStorage.getItem("sessionUserId");
      if (sessionuserid) {
        console.log("CHECKED IF SESSION")
        navigate(`${process.env.REACT_APP_BASE_PATH}/dashboard`);
      }
    };

    checkSessionUserId(); // Check for session ID initially
    return
  }, [navigate]);

  useEffect(() => {
    if (forgotPasswordClicked) {
      // Redirect to login page if the user click "Forgot Password"
      navigate(`${process.env.REACT_APP_BASE_PATH}/forgot-password`);
    } 
    // else {
    //   navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
    // }
  }, [forgotPasswordClicked, navigate]);

  //calculates width of screen size
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth([window.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  /**
   * The code includes functions for handling form validation, toggling password visibility, and
   * updating the validPassword state based on the length of the password.
   * @param errorInfo - An object that contains information about the failed form validation, such as
   * the fields that failed validation and the error messages associated with them.
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateEmailAndPassword = () => {
    form.validateFields(["email"]);
  };

  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };

  // ******************** LOGIN API CALLING AND MUTATION STARTS **************************
  /**
   * The `handleLogin` function is responsible for handling the login process, including making an API
   * call to login the customer, storing session data in localStorage, and displaying success or error
   * messages.
   */

  // IF USER IS INITITATING LOGIN
  const Login_Initiated = () => {
    clevertap.event.push("Login_Initiated", {
      USER_ID: email,
      Email: email,
      Password: "true",
      Submit_Button_Clicked: "true",
      timestamp: new Date().getTime(),
      user_agent: userAgent,
      // location: location,
    });
    console.log("EVENT INITIATED CLICKED");
  };

  const Login_Success = (customerData) => {
    clevertap.event.push("Login_Success", {
      Name: customerData.customerName,
      Identity: customerData.customerId,
      Email: customerData.customerEmail,
      USER_ID: customerData.customerEmail,
      Login_Method: customerData.customerRegvia,
      FirstTime_User_Login: customerData.firstTime_User_Login,
      Onboarding_Screen_Viewed: customerData.onboarding_Screen_Viewed,
      Timestamp: customerData.timeStamp,
      timestamp: new Date().getTime(),
      user_agent: userAgent,
      // location: location,
    });

    console.log("Login_Success EVENT CLICKED");
  };

  const OnUserLogin = (customerData) => {
    clevertap.onUserLogin.push({
      Site: {
        Name: customerData.customerName,
        Identity: customerData.customerId,
        Email: customerData.customerEmail,
        USER_ID: customerData.customerEmail,
        timestamp: new Date().getTime(),
      },
    });
  }

  const FetchUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(latitude, "LAT", longitude, "LONG");

        // Include location in CleverTap user profile
        clevertap.onUserLogin.push({
          Site: {
            Location: {
              lat: latitude,
              lon: longitude,
            },
          },
        });
        console.log("LOCATION SENT CLICKED");
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
  }

  const Login_Failure = (error_mgs) => {
    clevertap.event.push("Login_Failure", {
      Status: "Failure",
      Error_Message: error_mgs,
      FirstTime_User_Login: "No",
      Onboarding_Screen_Viewed: "No",
      AfterLogin_Screen: "Template Library",
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Login_Failure EVENT CLICKED");

    // Get user's location using Geolocation API
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(latitude, "LAT", longitude, "LONG");

        // Include location in CleverTap user profile
        clevertap.onUserLogin.push({
          Site: {
            Location: {
              lat: latitude,
              lon: longitude,
            },
          },
        });
        console.log("LOCATION SENT CLICKED");
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
  };

  const handleLogin = () => {
    setIsLoading(true);

    const custDataLogin = {
      custregid: email,
      password: password,
    };
    Login_Initiated();
    loginCustomerMutation(custDataLogin);
  };

  const { mutate: loginCustomerMutation } = useMutation(
    (custDataLogin) => loginCustomer(custDataLogin),
    {
      onSuccess: (custDataLogin) => {
        // console.log(custDataLogin.STATUS, "INSIDE DATA STATUS")
        // debugger
        if (custDataLogin.STATUS === "1000") {
          const custData = JSON.parse(custDataLogin.DATA);
          // console.log(custData, "CUST DATA");
          // console.log("INSIDE 1000");
          // debugger
          const sessionUserId = custData[0].CUSTOMERREGID;
          const sessionId = createSession(sessionUserId);
          localStorage.setItem("sessionId", sessionId);
          localStorage.setItem("sessionUserId", sessionUserId);
          localStorage.setItem("testing", "TEST-CASE");
          // console.log(sessionId, "SESSION ID")
          // console.log(sessionUserId, "SESSION USER ID")

          // const customerID = custData[0].CUSTOMERID;
          // console.log(customerID, "CID");

          const customerData = {
            customerEmail: custData[0].CUSTOMERREGID,
            customerRegvia: custData[0].CUSTOMERREGVIA,
            firstTime_User_Login: "NO",
            onboarding_Screen_Viewed: "NO",
            timeStamp: custData[0].CUSTOMERLASTLOGIN,
            customerName: custData[0].CUSTOMERNAME,
            customerId: custData[0].CUSTOMERID,
          };
          Login_Success(customerData);
          OnUserLogin(customerData);
          FetchUserLocation();
          // console.log("LOGIN SUCCESS");

          setTimeout(() => {
            navigate(`${process.env.REACT_APP_BASE_PATH}/global-template`);
          }, 2000);
        } else if (custDataLogin.STATUS === "2000") {
          // console.log("INSIDE 2000");
          const custData = JSON.parse(custDataLogin.DATA);
          // console.log(custData, "CUST DATA");
          const sessionUserId = custData[0].CUSTOMERREGID;
          const sessionId = createSession(sessionUserId);
          localStorage.setItem("sessionId", sessionId);
          localStorage.setItem("sessionUserId", sessionUserId);
          localStorage.setItem("testing", "TEST-CASE");

          const customerData = {
            customerEmail: custData[0].CUSTOMERREGID,
            customerRegvia: custData[0].CUSTOMERREGVIA,
            firstTime_User_Login: "YES",
            onboarding_Screen_Viewed: "YES",
            timeStamp: custData[0].CUSTOMERLASTLOGIN,
            customerName: custData[0].CUSTOMERNAME,
            customerId: custData[0].CUSTOMERID,
          };
          Login_Success(customerData);
          OnUserLogin(customerData);
          FetchUserLocation();
          // debugger
          toast.success(custDataLogin.DESCRIPTION);

          // Store "yes" in local storage for the key "startingModalShow"
          localStorage.setItem("startingModalShow", "yes");

          setTimeout(() => {
            navigate(`${process.env.REACT_APP_BASE_PATH}/getting-started-1`);
          }, 2000);
        } else {
          // console.log("INSIDE ELSE");
          toast.error(custDataLogin.DESCRIPTION);
          const error_mgs = custDataLogin.DESCRIPTION;
          Login_Failure(error_mgs);
          setIsLoading(false);
        }
      },
      onError: () => {
        console.log("INSIDE ERROR");
        setIsLoading(false);
      },
    }
    );
    // console.log("OUTSIDE THE LOOP");
  // ******************** LOGIN API CALLING AND MUTATION ENDS **************************

  const redirectToGoogle = () => {
    // const googleUrl = '/togoogle';
    if (!navigator.onLine) {
      setIsLoading(false);
      toast.error("No internet connection. Please check your network.");
      return;
    }
    // var googleUrl = `${process.env.REACT_APP_BASE_PATH}/togoogle`;
    var googleUrl = utmParam === null ? `${process.env.REACT_APP_BASE_PATH}/togoogle` : `${process.env.REACT_APP_BASE_PATH}/togoogle?utm=${utmParam}`;

    window.location.replace(googleUrl);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <div className="h-screen">
          <Row>
            <Col span={width > 1024 ? 16 : 24}>
              <div className="form-wrapper email-verify">
                <div className="w-full">
                  <div className=" mb-8 ">
                    <div className="w-[200px] ml-auto mr-auto">
                      <img
                        src={newVspagyLogo}
                        alt="vspagyLogo"
                        className="w-full mb-4"
                      />
                    </div>
                    <div className="font-semibold text-center mt-2 text-[#151515]  text-[26px] select-none">
                      Login to Continue
                    </div>
                  </div>
                </div>

                <div>
                  <Form
                    form={form}
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                  >
                    <Form.Item
                      className="mb-6"
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email" },
                        // { type: "email", message: "Enter valid email" },
                        {
                          // pattern:
                          //   /^[A-Za-z0-9]+(\.[A-Za-z0-9]+)*@([A-Za-z]+\.)+[A-Za-z]{2,}$/,
                          type: 'email', // Custom pattern for emails with only dots allowed
                          message: "Enter a valid email!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        className="h-[44px]  text-base"
                        placeholder="someone@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      className="mb-2"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password",
                        },
                      ]}
                    >
                      <Input.Password
                        iconRender={(visible) =>
                          visible ? (
                            <EyeTwoTone onClick={togglePasswordVisibility} />
                          ) : (
                            <EyeInvisibleOutlined
                              onClick={togglePasswordVisibility}
                            />
                          )
                        }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        visibilityToggle
                        className="cursor-pointer h-[44px] text-base"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <div className="flex justify-between mb-5">
                      <div
                        onClick={handleForgotPasswordClick}
                        className="cursor-pointer hover:underline hover:font-medium text-[#1C68EB] text-sm font-normal"
                      >
                        Forgot Password?
                      </div>
                    </div>
                    <Form.Item shouldUpdate>
                      {() => (
                        <SimpleButton
                          label="Login"
                          type="primary"
                          htmlType="submit"
                          size="large"
                          className="!rounded-[4px] !text-[#ffffff] w-full"
                          // disabled={!validPassword}
                          onClick={() => {
                            handleLogin();
                            validateEmailAndPassword();
                          }}
                        />
                      )}
                    </Form.Item>
                  </Form>
                </div>

                {/* ****************** TO BE USED IN FUTURE ******************* */}
                <Divider className="!border-[#CFCFCF] !border-[0.5px]" plain>
                  or
                </Divider>
                <div className="my-4">
                  <span
                    className="flex justify-center items-center h-[44px] select-none cursor-pointer rounded-[4px] bg-FCFCFC border border-[#E3E3E3] border-solid"
                    onClick={redirectToGoogle}
                  >
                    <span>
                      <img src={googleLogo} alt="google logo" />
                    </span>
                    <span className="text-base ml-3">Sign In with Google</span>
                  </span>
                  {/* </Link> */}
                </div>
                <div className="flex flex-col text-center text-base">
                  <span className="text-black font-normal">
                    Don't have an account yet?
                    <Link
                      to={`${process.env.REACT_APP_BASE_PATH}/signup`}
                      className="ml-2 text-[#155AD1] font-semibold "
                    >
                      Signup
                    </Link>
                  </span>
                </div>
                {/* <div className="flex flex-col text-center text-base mt-2">
                  <span className="text-black font-normal">
                    Having trouble?
                    <Link
                      // to={`${process.env.REACT_APP_BASE_PATH}/signup`}
                      className="ml-2 text-[#155AD1] font-semibold "
                    >
                      Get help
                    </Link>
                  </span>
                </div> */}
              </div>
            </Col>

            <Col className="max-[1024px]:hidden" span={8}>
              <Banner />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
