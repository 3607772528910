import React from "react";
// import featherSvg from "../../assests/commonIcons/userModuleIcons/featherSvg.svg";
// import globeSvg from "../../assests/commonIcons/userModuleIcons/globeSvg.svg";
// import linkChainSvg from "../../assests/commonIcons/userModuleIcons/linkChainSvg.svg";

// THIS IS TO DISPLAY THE RIGHT IMAGE FOR THE PAGES ForgotPassword.js, ResetPassword.js, Login.js, Logout.js and Resgister.js
const Banner = () => {
  return (
    <div>
      <div className="signup-banner fixed right-0">
        {/* <div className="banner-info px-8">
          <div
            className="!text-white !mb-7 !text-[22px] !font-semibold ml-5"
          >
            Personalized Video Interactivity
          </div>
        </div> */}
        <div className="copyright-section absolute">
          <div className="p-1 pb-6 px-8  text-white">
            <span>&#169;</span>2023 VSPAGY· ALL RIGHTS RESERVED
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
