import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
// import './saasWebsite/Fonts/Lobster-Regular.ttf';
import "./saasWebsite/Fonts/Lobster-Regular.ttf";
import {  BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider } from "react-query";
import queryClient from "./saasWebsite/api/QueryClient/queryClient";
import clevertap from "clevertap-web-sdk";
// import history from './plEditor/Components/history';
import { CustomerInfoProvider } from "./saasWebsite/common/functions/useCustomerInfo";
window.PUBLIC_URL = `${process.env.PUBLIC_URL}`;
// window.PUBLIC_URL = 'http://localhost:8080';

// CLEVERTAP PID
clevertap.init("R5K-R9W-796Z");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter >
      <QueryClientProvider client={queryClient}>
        <CustomerInfoProvider>
          <App />
        </CustomerInfoProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();