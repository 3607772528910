import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// IMG/SVGs IMPORT
import AnimationButtonIcon from "../../common/components/AnimationButtonIcon";
import leftArrowIcon from "../../assests/commonIcons/leftArrowIcon.svg";
import downloadSampleIcon from "../../assests/commonIcons/projectIcons/downloadSampleIcon.svg";
import fillDataSampleIcon from "../../assests/commonIcons/projectIcons/fillDataSampleIcon.svg";
import uploadFileSampleIcon from "../../assests/commonIcons/projectIcons/uploadFileSampleIcon.svg";
import helpCircleIcon from "../../assests/commonIcons/projectIcons/helpCircleIcon.svg";
import downArrowBlueIcon from "../../assests/commonIcons/projectIcons/downArrowBlueIcon.svg";
import uploadFileBlueIcon from "../../assests/commonIcons/projectIcons/uploadFileBlueIcon.svg";
import generateLinkWhiteIcon from "../../assests/commonIcons/projectIcons/generateLinkWhiteIcon.svg";
import rocketSvg from "../../assests/commonIcons/projectIcons/rocketSvg.svg";

// LIBRARY IMPORT
import { Button, Divider, Input, Modal, Tooltip } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";

// COMPONENT/API IMPORT
import SimpleButtonIcon from "../../common/components/SimpleButtonIcon";
import { zipFileUploadApi } from "../../api/api_uploadFile";
import { downloadZipFileApi, generateLinkApi } from "../../api/api_updateData";
import "../../common/styles/project/projectStyles.css";
import SimpleButton from "../../common/components/SimpleButton";
import { getAllUserTemplate, getCustomerInfo } from "../../api/api_FetchData";
import useSessionChecker from "../../common/functions/useSessionChecker";
import { useCustomerInfo } from "../../common/functions/useCustomerInfo";
import clevertap from "clevertap-web-sdk";
import useCurrentUrl from "../../common/functions/useCurrentUrl";

const GoToProject = ({ custregid }) => {
  custregid = localStorage.getItem("sessionUserId");
  const sessionuserid = localStorage.getItem("sessionUserId");
  const location = useLocation();
  const navigate = useNavigate();
  const userAgent = navigator.userAgent;
  useSessionChecker(navigate);
  useCurrentUrl();
  const { ptid, linkprojectname } = location.state || {};

  const [projectName, setProjectName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  //   FOR ENABLE AND DISABLE OF LINK/BUTTON
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const [isGenerateLinkEnabled, setIsGenerateLinkEnabled] = useState(false);
  const [isZipFileUploaded, setIsZipFileUploaded] = useState(false);

  const [nameInput, setNameInput] = useState(false);
  const [isNameTooltipVisible, setIsNameTooltipVisible] = useState(false);
  // This useEffect listens to changes in the input field and enables/disables
  // the "Download Zip File" button accordingly.

  useEffect(() => {
    if(linkprojectname === undefined){
      setIsDownloadEnabled(projectName.length >= 4);
    } else {
      setProjectName(linkprojectname);
      setIsDownloadEnabled(true);
    }
  }, [projectName, linkprojectname]);

  const fileInputRef = useRef(null);

  const queryClient = useQueryClient();

  const { data: userTemplates } = useQuery(
    "userTemplates",
    () => getAllUserTemplate(sessionuserid, null, "/template"),
    {
      enabled: false,
    }
  );
  
  console.log(linkprojectname, "LINK P NAME")

  const { setCustomerInfo } = useCustomerInfo();

  const { refetch: custRefetch } = useQuery(
    ["customerinfo", custregid],
    () => getCustomerInfo(null, custregid, "/customer"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (fetchedData) => {
        setCustomerInfo(fetchedData);
      },
    }
  );

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 20) {
      setProjectName(value);
      setIsNameTooltipVisible(value.length <= 20);
    }
  };

  const handleNameClick = () => {
    setIsNameTooltipVisible(projectName.length <= 20);
  };

  const handleNameBlur = () => {
    setIsNameTooltipVisible(false);
  };

  useEffect(() => {
    // Fetch user templates when the component mounts
    queryClient.fetchQuery("userTemplates", "customerinfo"); // You can also use queryClient to fetch
    custRefetch();
    console.log("UPDATED");
  }, [queryClient, custRefetch]);

  const selectedTemplate = userTemplates?.data?.find((template) =>
    ptid ? template.TEMPLATEID === ptid : {}
  );
  // console.log(selectedTemplate, "SELECTED TEMPLATE");

  const { mutate: downloadZipMutation } = useMutation(
    (projectData) => downloadZipFileApi(projectData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          const downloadUrl = JSON.parse(data.DATA)[0].url;
          console.log(data.DATA, "DOWN DATA");
          setNameInput(true);
          toast.success(data.DESCRIPTION);
          setIsUploadEnabled(true);
          window.open(downloadUrl, "_blank");
        } else if (data.STATUS === "-1001") {
          console.log(data.DATA, "DOWN DATA");
          setNameInput(false);
          toast.error(data.DESCRIPTION);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  console.log(nameInput, "NAME LINK")

  const downloadZipFile = () => {
    const projectData = {
      templateid: ptid,
      sessionuserid: sessionuserid,
      projectname: projectName,
    };
    // debugger
    downloadZipMutation(projectData);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // ************** UPLOAD ZIP FILE MUTATION ******************

  const { mutate: uploadZipMutation } = useMutation(
    (zipData) => zipFileUploadApi(zipData),
    {
      onSuccess: (data) => {
        const parsedData = JSON.parse(data.DATA);
        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);

          const uploadedfilename = parsedData[0].uploadedfilename;
          const systemfilename = parsedData[0].systemfilename;
          localStorage.setItem("uploadedfilename", uploadedfilename);
          localStorage.setItem("systemfilename", systemfilename);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  useEffect(() => {
    setIsGenerateLinkEnabled(!!selectedFile);
  }, [selectedFile]);

  /**
   * The handleUpload function checks if a file is selected, performs an upload logic with a delay, and
   * displays a toast message based on the upload status.
   */
  const handleUpload = () => {
    if (selectedFile) {
      const zipData = {
        zipfile: selectedFile,
        templateid: ptid,
        sessionuserid: sessionuserid,
      };
      // console.log("Uploading file:", selectedFile);
      // console.log("Uploading file:", zipData);
      uploadZipMutation(zipData);
      setIsModalVisible(false);
      setIsZipFileUploaded(true); // Set the state to true when ZIP file is uploaded
    } else {
      toast.error("Please select a ZIP file.");
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedFile(null);
  };

  // ************** SUCCESS MODAL ******************

  const { mutate: generateLinkMutation } = useMutation(
    (linkData) => generateLinkApi(linkData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          setIsSuccessModalVisible(true);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  // THIS FUNCTION IS CALLED WHEN GENERATE_PERSONALIZED_LINK BUTTON CLICKED
  // THIS SENDS ALL THE DETAILS OF PROJECT
  const uploadedfilenamelocal = localStorage.getItem("uploadedfilename");
  console.log(uploadedfilenamelocal, "FILE NAME");
  const systemfilenamelocal = localStorage.getItem("systemfilename");
  console.log(systemfilenamelocal, "SYS FILE NAME");
  const handleGenerateLink = () => {
    const linkData = {
      templateid: ptid,
      projectname: projectName,
      systemfilename: systemfilenamelocal,
      uploadfilename: uploadedfilenamelocal,
      sessionuserid: sessionuserid,
    };
    // console.log("GENERATED DATA:", linkData);
    generateLinkMutation(linkData);
  };

  const goToMyTemplates = () => {
    navigate(`${process.env.REACT_APP_BASE_PATH}/user-template`);
    // console.log("CLICK  ED");
  };

  const ProjectDownloadFile = () => {
    clevertap.event.push("Project_DownloadFile", {
      USER_ID: custregid,
      Email: custregid,
      Project_DownloadFile_Clicked: downloadZipFile ? "Yes" : "No",
      Project_DownloadFile_Success: downloadZipMutation ? "Yes" : "No",
      TemplateName: selectedTemplate.TEMPLATENAME,
      TemplateID: ptid,
      // "CategoryName"
      // "CategoryID"
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Project_DownloadFile EVENT CLICKED");
  };

  const ProjectUploadFile = () => {
    clevertap.event.push("Project_UploadFile", {
      USER_ID: custregid,
      Email: custregid,
      Project_UploadFile_Clicked: showModal ? "Yes" : "No",
      // "Project_UploadFile_BrowseZip_Clicked": fileInputRef.current.click() ? "Yes" : "No",
      Project_UploadFile_UploadZip_Clicked: handleUpload ? "Yes" : "No",
      Project_UploadFile_Success: isGenerateLinkEnabled ? "Yes" : "No",
      TemplateName: selectedTemplate.TEMPLATENAME,
      TemplateID: ptid,
      // "CategoryName"
      // "CategoryID"
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Project_UploadFile EVENT CLICKED");
  };

  const ProjectDetailsEntered = () => {
    clevertap.event.push("Project_Details_Entered", {
      USER_ID: custregid,
      Email: custregid,
      ProjectName: projectName,
      TemplateName: selectedTemplate.TEMPLATENAME,
      TemplateID: ptid,
      // "CategoryName"
      // "CategoryID"
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Project_Details_Entered EVENT CLICKED");
  };

  const GeneratePersonalizedLinksClicked = () => {
    clevertap.event.push("Generate_Personalized_Links_Clicked", {
      USER_ID: custregid,
      Email: custregid,
      Generate_Personalized_Links_Clicked: handleGenerateLink ? "Yes" : "No",
      Generate_Personalized_Links_Clicked_Success: isSuccessModalVisible
        ? "Yes"
        : "No",
      TemplateName: selectedTemplate.TEMPLATENAME,
      TemplateID: ptid,
      // "CategoryName"
      // "CategoryID"
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Generate_Personalized_Links_Clicked EVENT CLICKED");
  };

  return (
    <>
      <div className="flex h-[calc(100vh_-_3.75rem)]">
        <div
          className="w-3/4 pl-20 p-10 overflow-auto"
        >
          <AnimationButtonIcon
            image={leftArrowIcon}
            onClick={() => navigate(-1)}
            label="Back to Template"
          />
          <div className="mt-10 flex">
            {selectedTemplate && (
              <>
                <div className="flex flex-col">
                  <div className="font-semibold text-[22px]">
                    <span>{selectedTemplate.TEMPLATENAME}</span> <br />
                  </div>
                  <div className="mt-7">
                    <img
                      src={`${process.env.REACT_APP_SOURCE_TEMPLATE_BUCKET_BASE_URL}TEMPLATE/${ptid}/SOURCE/${selectedTemplate.TEMPLATETHUMBNAIL}`}
                      alt="template_image"
                      className="w-full h-[280px]"
                    />
                  </div>
                </div>
              </>
            )}

            <div className="pl-16 pt-12 w-[50%]">
              <div className="flex flex-col pb-2">
                <span className="text-lg text-black font-medium">
                  Steps to personalize a template
                </span>
                <span className="text-sm text-[#484848] whitespace-normal">
                  By personalization we mean that you can add your data in the
                  pre-defined template.
                </span>
              </div>
              <div className="">
                <span className="flex pt-6 pr-4 ">
                  <span className="">
                    <img src={downloadSampleIcon} alt="download-sample-svg" />
                  </span>
                  <span className="flex flex-col ml-4">
                    <span className="text-base text-black font-medium">
                      Download sample file
                    </span>
                    <span className="text-sm text-[#484848]">
                      Download a sample file, ready with pre-defined column
                      headers.
                    </span>
                  </span>
                </span>
                <span className="flex pt-6 pr-4 ">
                  <span>
                    <img src={fillDataSampleIcon} alt="fill-data-svg" />
                  </span>
                  <span className="flex flex-col ml-4">
                    <span className="text-base text-black font-medium">
                      Add data in File
                    </span>
                    <span className="text-sm text-[#484848]">
                      Add the data as per the columns and save it. Pre-defined
                      column headers should not be edited.
                    </span>
                  </span>
                </span>
                <span className="flex pt-6 pr-4 ">
                  <span>
                    <img src={uploadFileSampleIcon} alt="upload-file-svg" />
                  </span>
                  <span className="flex flex-col ml-4">
                    <span className="text-base text-black font-medium">
                      Upload Data
                    </span>
                    <span className="text-sm text-[#484848]">
                      Upload the file with data and required media.
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="py-9 w-1/4 shadow-left overflow-auto"
        >
          <div className="mx-8 flex flex-col mb-3">
            <span className="text-lg text-black font-medium">
              Project Details
            </span>
            <span></span>
            <span className="text-smm text-[#707070]">
              Start a project by filling the details
            </span>
            <span className="mt-6">
              <span className=" bg-[#EB8C1C] px-2 py-1 text-white rounded-3xl text-xs">
                Step 1
              </span>
            </span>
            <div className="mt-4 flex flex-col input-project">
              <label className="text-[#141414] font-medium text-sm">
                Project Name
              </label>
              {/* <Input className="my-3 h-10 w-full" /> */}
              <Tooltip
                open={isNameTooltipVisible} // Show tooltip only when the length is less than 4
                title="Character limit 4 to 20"
                placement="topRight"
              >
                <Input
                  className="my-3 h-10 w-full"
                  value={linkprojectname === undefined ? projectName : linkprojectname}
                  // onChange={(e) => setProjectName(e.target.value)}
                  onChange={handleNameChange}
                  onClick={handleNameClick}
                  onBlur={handleNameBlur}
                  disabled={linkprojectname === undefined ? nameInput : true}
                />
              </Tooltip>
              {/* <label className="text-[#141414] font-medium text-sm">
                Remarks
              </label>
              <Input className="mt-3 h-10 w-full" /> */}
            </div>
            <span className="text-[11px] text-gray-500">Character limit 4 to 20 || Only "_" is allowed</span>
          </div>

          <Divider />

          <div
            // className="mx-8 my-6 flex flex-col"
            className={`mx-8 my-6 flex flex-col ${
              isDownloadEnabled ? "" : "pointer-events-none opacity-50"
            }`}
          >
            <span className="">
              <span className=" bg-[#EB8C1C] px-2 py-1 text-white rounded-3xl text-xs">
                Step 2
              </span>
            </span>
            <span className="mt-3 flex items-center mb-1">
              <span className="text-black text-base font-medium mr-2">
                Personalize Template
              </span>
              <span>
                <img src={helpCircleIcon} alt="help-cirlce-svg" />
              </span>
            </span>
            <span className="mb-1 text-[#505050] text-sm font-normal">
              Download sample ZIP file and upload data as per the downloaded
              format.
            </span>
            {/* <span
              className="my-1 flex items-center cursor-pointer select-none"
              onClick={downloadZipFile}
            > */}
            <span
              className={`my-1 flex items-center cursor-pointer select-none ${
                isDownloadEnabled ? "" : "pointer-events-none opacity-50"
              }`}
              onClick={() => {
                downloadZipFile();
                ProjectDownloadFile();
              }}
            >
              <span>
                <img src={downArrowBlueIcon} alt="download-cirlce-svg" />
              </span>
              <span className="text-[#1C68EB] text-base font-normal ml-1">
                Download File
              </span>
            </span>
            {/* <span className="flex mt-3 bg-[#ECFFD8] px-3 py-[8px] border border-dashed border-[#23790E]">
              <span className="select-none">
                This template contains 5 variables
              </span>
              <span className="ml-2">
                <img src={helpCircleIcon} alt="help-cirlce-svg" />
              </span>
            </span> */}
          </div>

          <Divider />

          {/* <div className="mx-8 my-6 flex flex-col"> */}
          <div
            // className="mx-8 my-6 flex flex-col"
            className={`mx-8 my-6 flex flex-col ${
              isUploadEnabled ? "" : "pointer-events-none opacity-50"
            }`}
          >
            <span className="">
              <span className=" bg-[#EB8C1C] px-2 py-1 text-white rounded-3xl text-xs">
                Step 3
              </span>
            </span>
            <span className="mb-1 mt-3">
              <span className="text-black text-base font-medium mr-2">
                Upload ZIP File
              </span>
            </span>
            <span className="mb-1 text-[#505050] text-sm font-normal">
              To create links, upload the data in ZIP file.
            </span>
            {/* <span
              className="flex items-center cursor-pointer"
              onClick={showModal}
            > */}
            <span
              className={`my-1 flex items-center cursor-pointer select-none ${
                isUploadEnabled ? "" : "pointer-events-none opacity-50"
              }`}
              onClick={showModal}
            >
              <span>
                <img src={uploadFileBlueIcon} alt="upload-file-svg" />
              </span>
              <span className="text-[#1C68EB] text-base font-normal ml-1">
                Upload ZIP File
              </span>
            </span>
          </div>

          <Divider />

          <div className="mt-7 mx-8 flex justify-end generate-link-button">
            <SimpleButtonIcon
              label="Generate Personalized Links"
              type="primary"
              szie="large"
              image={generateLinkWhiteIcon}
              className="ml-1"
              onClick={() => {
                handleGenerateLink();
                GeneratePersonalizedLinksClicked();
                ProjectDetailsEntered();
              }}
              // onClick={() => setIsSuccessModalVisible(true)}
              disabled={!isGenerateLinkEnabled || !isZipFileUploaded} // Enable if ZIP file is uploaded
            />
          </div>
        </div>
      </div>

      {/* ****************** UPLOAD ZIP FILE MODAL ************************* */}
      <Modal
        className="upload-zip-file"
        title="Upload ZIP File"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            key="upload"
            type="primary"
            onClick={() => {
              handleUpload();
              ProjectUploadFile();
            }}
          >
            Upload ZIP
          </Button>,
        ]}
      >
        <Button type="default" onClick={() => fileInputRef.current.click()}>
          Browse Zip
        </Button>
        <input
          type="file"
          accept=".zip"
          onChange={handleFileSelection}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        {selectedFile && (
          <div>
            <p>Name: {selectedFile.name}</p>
            <p>Size: {Math.round(selectedFile.size / 1024)} KB</p>
          </div>
        )}
      </Modal>

      {/* ****************** SUCCESS UPLOAD FILE MODAL ************************* */}
      <Modal
        className="success-file-upload"
        open={isSuccessModalVisible}
        closeIcon={false}
        footer={null}
        maskClosable={false}
        width={520}
      >
        <div className="flex items-center flex-col">
          <span>
            <img src={rocketSvg} alt="rocket-svg" />
          </span>
          <span className="text-black text-lg font-medium mt-4">
            Generating Links
          </span>
          <span className="text-[#757575] text-sm font-normal mt-2">
            Please wait we are generating your links. You will find them in My
            Templates.
          </span>
          <span className="mt-6">
            <SimpleButton
              type="primary"
              size="large"
              label="Go To My Templates"
              onClick={goToMyTemplates}
            />
          </span>
        </div>
      </Modal>
    </>
  );
};

export default GoToProject;
