import React from "react";

const GlobalBrandCard = ({ brandData }) => {
  console.log(brandData, "BRAND DATA");
  return (
    <div className="">
      <div
        className="w-[188px] h-[250px] bg-[#FFFFFF] rounded-md shadow-md"
        style={{ border: "1px solid #E7E7E7" }}
      >
        <img
          alt="Thumbnail"
          src={
            brandData.BRANDPAGETHUMBNAIL
              ? `${process.env.REACT_APP_SOURCE_TEMPLATE_BUCKET_BASE_URL}BRANDPAGE/${brandData.BRANDPAGEID}/SOURCE/${brandData.BRANDPAGETHUMBNAIL}`
              : `https://placehold.co/210x187`
          }
          className="h-[210px] w-[187px] rounded-md"
        />

        <div
          className="bg-[#FAFBFC] rounded-b-lg w-full h-[39px] flex justify-center items-center"
          style={{ borderTop: "1px solid #E0E0E0" }}
        >
          <span className="bg-[#466BC7] rounded-[12px] px-[10px] py-[4px] flex justify-center items-center cursor-pointer">
            <span className="text-[#FFFFFF] text-[11px] font-normal">
              Use Template
            </span>
          </span>
        </div>
      </div>
      <div className="mt-3 ml-1 w-[179px]">
        <span className="text-[11px] font-medium flex">
          {brandData.BRANDPAGENAME}
        </span>
        <p className="text-[10px] leading-[13px] font-normal mt-1">
          {brandData.BRANDPAGEDESC}
        </p>
      </div>
    </div>
  );
};

export default GlobalBrandCard;
