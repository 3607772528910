import { Divider, Input, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
// import SimpleButton from "../../SimpleButton";
import SimpleButton from "../../../SimpleButton";
import { toast } from "react-toastify";
import "../../../../styles/commonStyles.css";
import { createTemplateTemp } from "../../../../../api/api_updateData";
import { useMutation } from "react-query";
import "../../../../styles/commonStyles.css";

const TemplateDetail = ({
  onComplete,
  templateName,
  templateDescription,
  setTemplateName,
  setTemplateDescription,
  saveTemplateClicked,
  setSaveTemplateClicked,
  handleUpdateDashboard,
}) => {
  const sessionuserid = localStorage.getItem("sessionUserId");

  // const [templateName, setTemplateName] = useState("");
  // const [templateDescription, setTemplateDescription] = useState("");
  const [, setTemplateId] = useState(null);
  const [isNameTooltipVisible, setIsNameTooltipVisible] = useState(false);

  const handleTemplateNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      setTemplateName(value);
      setIsNameTooltipVisible(value.length < 4);
    }
  };

  const handleTemplateNameClick = () => {
    setIsNameTooltipVisible(templateName.length < 4);
  };

  const handleTemplateNameBlur = () => {
    setIsNameTooltipVisible(false);
  };

  const handleTemplateDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 500) {
      setTemplateDescription(value);
    } else {
    }
  };

  // MUTATION FOR CREATION OF TEMP TEMPLATE
  const { mutate: createTemplateTempMutation } = useMutation(
    (tempData) => createTemplateTemp(tempData),
    {
      onSuccess: (data) => {
        // debugger
        const parsedData = JSON.parse(data.DATA);
        if (data.STATUS === "1000") {
          // Add SESSIONUSERID to each object in the DATA array
          // handleUpdateDashboard();
          const newDataArray = parsedData.map((item, index) => ({
            ...item,
            SESSIONUSERID: sessionuserid,
          }));

          /// Create a new object with the updated DATA property
          const createTemplateData = {
            ...data,
            DATA: newDataArray,
          };

          // Store the updated data in localStorage
          localStorage.setItem(
            "createTemplateData",
            JSON.stringify(createTemplateData)
          );

          const storedData = localStorage.getItem("createTemplateData");
          const parsedTemplateData = JSON.parse(storedData);

          console.log(parsedTemplateData, "Parsed Template Data");

          // Access TEMPLATEID from the parsed array
          const templateId = parsedData[0].TEMPLATEID;

          // Store the templateId in localStorage
          localStorage.setItem("templateId", templateId);

          // Log the updated data
          console.log(createTemplateData, "Updated Data");

          // Trigger the onComplete callback if needed
          onComplete();
          setTemplateId(templateId);
          onComplete(templateId);
          handleUpdateDashboard();
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const handleSaveTemplate = () => {
    // Remove newline characters from templateDescription
    const sanitizedDescription = templateDescription.replace(/\n/g, " ");
    setSaveTemplateClicked("YES");
    // Create the template data
    const tempData = {
      custregid: sessionuserid,
      templatename: templateName,
      templatedesc: sanitizedDescription,
    };

    // Perform the API call
    createTemplateTempMutation(tempData);
  };

  // console.log(saveTemplateClicked, "SAVE TEMPLATE CLICKED FROM T DETAILS");

  return (
    <>
      <div className="flex flex-col">
        <div className="text-left">
          <label className="text-[#2F2F2F] font-Inter text-sm font-medium ">
            Template Name
          </label>
          <Tooltip
            open={isNameTooltipVisible} // Show tooltip only when the length is less than 4
            title="Minimum 4 characters required"
            placement="topRight"
          >
            <Input
              className="mt-2 h-[40px]"
              type="text"
              value={templateName}
              onChange={handleTemplateNameChange}
              onClick={handleTemplateNameClick}
              onBlur={handleTemplateNameBlur}
            />
          </Tooltip>
        </div>
        <div className="py-4 text-left">
          <span className="flex justify-between items-center">
            <label className="text-[#2F2F2F] font-Inter text-sm font-medium ">
              Template Description
            </label>
            <span className="font-normal text-[#707070] text-[11px]">
              500 Characters limit
            </span>
          </span>
          <TextArea
            autoSize={{
              maxRows: 2,
            }}
            value={templateDescription}
            onChange={handleTemplateDescriptionChange}
            className="mt-2 !h-[40px] rounded-[4px] overflow-y-auto"
          />
        </div>

        <span className="text-end pt-7">
          <Divider className="divider-temp" />
          <SimpleButton
            label="Save Template"
            type="primary"
            htmlType="submit"
            size="default"
            onClick={handleSaveTemplate}
            disabled={!templateName || !templateDescription}
            className="!rounded-[4px] w-fit !px mt-4"
          />
        </span>
      </div>
    </>
  );
};

export default TemplateDetail;
