import React, { useState, useRef } from "react";
// import newVspagyLogo from "../assests/commonImages/new-vspagy-logo.svg";
import newVspagyLogo from "../assests/commonImages/new-vspagy-beta-icon-revised.png";
import coinIcon from "../assests/commonImages/coins-icon.svg";
// import { ReactComponent as Icon } from "../assests/commonIcons/bellIcon.svg";
import helpIcon from "../assests/commonIcons/helpIcon.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LogoutModal from "../common/components/LogoutModal";
import HelpNavbar from "./HelpNavbar";
import { Spin, Tooltip } from "antd";
import { useQuery } from "react-query";
import { getCustomerInfo } from "../api/api_FetchData";
import useSessionChecker from "../common/functions/useSessionChecker";
import CreateTemplateModal from "../common/components/Modal/template/createTemplate/CreateTemplateModal";
// import CreateTemplateModal from "../common/components/template/CreateTemplateModal";
import { useCustomerInfo } from "../common/functions/useCustomerInfo";
import { useEffect } from "react";
// for mobile responsive
import useWindowWidth from "../common/hooks/useWindowWidth";
import createTemp from "../assests/ResponsiveIcons/createTemplate.svg";
// import profileArrow from "../assests/ResponsiveIcons/profileArrow.svg";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import Sidebar from "./Sidebar";
import MobileResModal from "../common/components/Modal/MobileResModal"

const Navbar = ({ custregid }) => {
  const [open, setOpen] = useState(false); // for dropdown
  const [modalVisible, setModalVisible] = useState(false); // for modal
  const [modalOpen, setModalOpen] = useState(false); //New state for modal open/closed
  const [createTempModal, setCreateTempModal] = useState(false);
  const [helpDrawerVisible, setHelpDrawerVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  const [mobileResModal, setMobileResModal] = useState(false);
  const [mobSidebarOpen, setMobSidebarOpen] = useState(false);  
  

  const navigate = useNavigate();
  custregid = localStorage.getItem("sessionUserId");

  useSessionChecker(navigate);

  const openModal = () => {
    setModalVisible(true);
    setModalOpen(true); // Set modal open when opening the logout modal
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalOpen(false); // Set modal closed when closing the logout modal
  };

  const openHelpDrawer = () => {
    setHelpDrawerVisible(true);
  };

  const closeHelpDrawer = () => {
    setHelpDrawerVisible(false);
  };

  const handleVisibleChange = (visible) => {
    setTooltipVisible(visible);
  };

  const { customerInfo, setCustomerInfo } = useCustomerInfo();

  const { data, isLoading, isError, refetch } = useQuery(
    ["customerinfo", custregid],
    () => getCustomerInfo(null, custregid, "/customer"),
    {
      staleTime: 10 * 60 * 1000,
      enabled: customerInfo === null, // Only fetch if customerInfo is not available in context
      onSuccess: (fetchedData) => {
        setCustomerInfo(fetchedData);
      },
    }
  );

  useEffect(() => {
    // Call the refetch function when the component mount
    refetch();
  }, [refetch]);

  const Items = [
    {
      name: "Manage Profile",
      key: 1,
      link: `${process.env.REACT_APP_BASE_PATH}/profile`,
    },
    {
      name: "My Templates",
      key: 2,
      link: `${process.env.REACT_APP_BASE_PATH}/user-template`,
    },
    { name: "Logout", key: 3 },
  ];

  // solution for close dropdown on outer click
  const menuRef = useRef();
  const imgRef = useRef();

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current && e.target !== imgRef.current) {
      setOpen(false);
    }
  });

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  if (isError || (data?.status === "-1001" && data?.data === "[]")) {
    return <div>Error loading data.</div>;
  } else if (data?.status === "-2001") {
    sessionStorage.clear();
    localStorage.clear();
    navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
  } else {
    var customername = data?.data[0].CUSTOMERNAME || "";
    localStorage.setItem("customerName", customername);
    var lastlogin = data?.data[0].CUSTOMERLASTLOGIN;
    var customerCredits = data?.data[0].CUSTOMERCREDITS;
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const year = date.getFullYear().toString().slice(-2); // Get the last 2 digits of the year

    return `${hours}:${minutes} ${ampm} ${day}-${month}-${year}`;
  };

  const formattedLastLogin = formatDate(lastlogin);

  const customerFirstLetter = customername
    ? customername.charAt(0).toUpperCase()
    : "";

  const openCreateTemplateModal = () => {
    setCreateTempModal(true);
  };

  return (
    <>
      {/* Desktop version */}
      {isDesktop && (
        <div className="h-15 w-full flex items-center justify-between px-6 border-2 border-solid shadow-sm border-[#F3F3F3]">
          <span>
            <Link
              className="outline-none"
              to={`${process.env.REACT_APP_BASE_PATH}/dashboard`}
            >
              <img
                className="w-[150px]"
                src={newVspagyLogo}
                alt="VSPAGY LOGO"
              />
            </Link>
          </span>
          <div className="flex items-center justify-around">
            <span
              className="relative mr-4 overflow-hidden group bg-[#E16023] hover:bg-gradient-to-r hover:from-[#E16023] hover:to-[#d5632e] rounded-[50px] px-3 py-1 cursor-pointer transition-all ease-out duration-300"
              onClick={openCreateTemplateModal}
            >
              <span className="absolute right-0 w-8 h-24 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-20 rotate-12 group-hover:-translate-x-40 ease"></span>
              <span className="text-sm font-medium text-white relative select-none">
                Create Template
              </span>
            </span>
            <span className="text-[#CACACA] text-2xl">|</span>
            <Tooltip placement="bottom" title="Available Credits">
              <span className="flex items-center bg-[#E5FFCA] rounded-2xl px-3 py-1 mx-4">
                <span>
                  <img src={coinIcon} alt="coin-icon" />
                </span>
                <span className="text-[#323445] font-medium text-15 ml-1 select-none">
                  {customerCredits}
                </span>
              </span>
            </Tooltip>

            <Tooltip placement="bottom" title="Help">
              <span
                className="flex items-center cursor-pointer helpIconNav"
                onClick={openHelpDrawer}
              >
                <img src={helpIcon} alt="Help and support icon" />
              </span>
            </Tooltip>

            <span className="flex items-center relative ml-4">
              <Tooltip
                placement="bottom"
                title="My Profile"
                open={tooltipVisible}
                onOpenChange={handleVisibleChange}
                trigger="hover"
              >
                <span
                  className="bg-[#D31442] rounded-full inline-block my-[10px] cursor-pointer select-none"
                  ref={imgRef}
                  onClick={(e) => {
                    if (!modalOpen) {
                      setOpen(!open);
                      setTooltipVisible(false);
                    }
                    e.stopPropagation();
                  }}
                >
                  <span className="text-white text-base  w-[26px] h-[26px] table-cell text-center align-middle select-none">
                    {customerFirstLetter}
                  </span>
                </span>
              </Tooltip>
              {open && (
                <>
                  <div
                    className="z-50 absolute top-12 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-gray-800 rotate-[45deg] mb-0 mt-0"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div
                    ref={menuRef}
                    onClick={(e) => e.stopPropagation()}
                    className="z-50 rounded-md bg-gray-800 shadow-md absolute rounded-7 flex-shrink-0 right-0 top-14 transition-opacity duration-300 opacity-100"
                  >
                    <ul className="w-52 h-[13rem] text-left px-0 pt-2 pb-1 mb-0 ">
                      <li className="list-none px-4 py-1 text-gray-300 font-inter select-none text-base font-medium">
                        Hi, {customername}
                      </li>
                      <li className="list-none px-4 py-1">
                        <span className=" text-white font-inter text-xs font-normal select-none">
                          Free Plan
                        </span>
                      </li>
                      <li className="list-none divide-y w-300 mb-2 mt-4 h-0.5 bg-gray-700"></li>
                      {Items.map((item, index) => (
                        <NavLink
                          className="no-underline  text-white font-inter text-sm font-normal"
                          to={item.link}
                          key={index}
                        >
                          <li
                            onClick={() => {
                              if (item.name === "Logout") {
                                openModal();
                              } else {
                                setOpen(false);
                              }
                            }}
                            className="list-none cursor-pointer px-4 py-2 hover:bg-gray-700 hover:rounded-md hover:mx-1 hover:px-3 "
                            key={item.key}
                          >
                            {" "}
                            {item.name}
                          </li>
                        </NavLink>
                      ))}
                    </ul>
                    <div className="text-left px-0 pt-1 pb-2 mb-0">
                      <span className="px-4 py-2 text-white font-inter text-xs font-normal select-none">
                        Last Login : {formattedLastLogin}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </span>
          </div>
        </div>
      )}

      {/* Mobile version */}
      {isMobile && (
        <div className="h-15 w-full flex items-center justify-between px-6 border-2 border-solid shadow-sm border-[#F3F3F3]">
          <span className="flex justify-around items-center">
            <span className="pt-2 mr-3">
              { mobSidebarOpen ? <CloseOutlined style={{color: "#4B4B4D", fontSize: "22px", fontWeight: "700"}} onClick={()=> setMobSidebarOpen(false)} /> : <MenuOutlined style={{color: "#4B4B4D", fontSize: "22px", fontWeight: "700"}} onClick={()=> setMobSidebarOpen(true)} /> }
            </span>
            <span>
              <Link
                className="outline-none"
                to={`${process.env.REACT_APP_BASE_PATH}/dashboard`}
              >
                <img
                  className="w-[117px]"
                  src={newVspagyLogo}
                  alt="VSPAGY LOGO"
                />
              </Link>
            </span>
          </span>
          <div className="flex items-center justify-around">
            <span className="bg-[#e16023] h-[26px] w-[38px] rounded-[8px] flex justify-center items-center" onClick={()=> setMobileResModal(true)}>
              <img src={createTemp} alt="video" />
            </span>
            {/* -----profile-- */}
            <span className="flex items-center relative ml-4">
              <span>
                <span
                  className="bg-[#D31442] rounded-full inline-block my-[10px] cursor-pointer select-none"
                  ref={imgRef}
                  onClick={(e) => {
                    if (!modalOpen) {
                      setOpen(!open);
                      setTooltipVisible(false);
                    }
                    e.stopPropagation();
                  }}
                >
                  <span className="text-white text-base  w-[26px] h-[26px] table-cell text-center align-middle select-none">
                    {customerFirstLetter}
                  </span>
                </span>
              </span>
              {open && (
                <>
                  <div
                    className="z-50 absolute top-12 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-gray-800 rotate-[45deg] mb-0 mt-0"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div
                    ref={menuRef}
                    onClick={(e) => e.stopPropagation()}
                    className="z-50 rounded-md bg-gray-800 shadow-md absolute rounded-7 flex-shrink-0 right-0 top-14 transition-opacity duration-300 opacity-100"
                  >
                    <ul className="w-[200px] h-[13rem] text-left px-0 pt-2  mb-0 ">
                      <li className="flex w-full justify-between items-center list-none px-4 pt-2 text-gray-300 font-inter select-none text-base font-medium">
                        {/* <span className="bg-[#D31442] rounded-full flex justify-center items-center  w-[26px] h-[26px] select-none">
                          <span className="text-white select-none">
                            {customerFirstLetter}
                          </span>
                        </span> */}
                        {/* <span className="text-[#9DA3A5] text-2xl">|</span> */}
                        <span className="flex flex-col">
                          <span className="text-[15px] font-semibold text-[#FFFFFF]">
                            Hi, {customername}
                          </span>
                          <span className=" text-[#D9D9D9] font-inter text-xs font-medium select-none">
                            Free Plan
                          </span>
                        </span>
                        {/* <span>
                          <img src={profileArrow} alt="arrow" />
                        </span> */}
                      </li>
                      <li className="list-none divide-y w-300 mb-2 mt-4 h-[1px] bg-[#f8f7f7]"></li>
                      {Items.map((item, index) => (
                        <NavLink
                          className="no-underline  text-white font-inter text-sm font-normal"
                          to={item.link}
                          key={index}
                        >
                          <li
                            onClick={() => {
                              if (item.name === "Logout") {
                                openModal();
                              } else {
                                setOpen(false);
                              }
                            }}
                            className="list-none cursor-pointer px-4 py-2 hover:bg-gray-700 hover:rounded-md hover:mx-1 hover:px-3 "
                            key={item.key}
                          >
                            {" "}
                            {item.name}
                          </li>
                        </NavLink>
                      ))}
                      <li className="list-none divide-y w-300 mb-1 mt-3 h-[0.5px] bg-[#f8f7f7]"></li>
                    </ul>
                    <div className="text-left px-0  pb-2 mb-0">
                      <span className="px-4 py-2 text-[#d9d9d9] font-inter text-[10px] font-normal select-none">
                        Last Login : {formattedLastLogin}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {isMobile && (
                <MobileResModal
                  mobileResModal={mobileResModal}
                  setMobileResModal={setMobileResModal}
                />
              )}
            </span>
          </div>
        </div>
      )}
      {modalVisible && (
        <LogoutModal modalVisible={modalVisible} closeModal={closeModal} />
      )}

      <CreateTemplateModal
        modal={createTempModal}
        setModal={setCreateTempModal}
      />
      <HelpNavbar
        customername={customername}
        visible={helpDrawerVisible}
        onClose={closeHelpDrawer}
      />
      {mobSidebarOpen && (
        <Sidebar
        mobSidebarOpen={mobSidebarOpen}
        setMobSidebarOpen={setMobSidebarOpen}
      />
      )}      
    </>
  );
};

export default Navbar;
