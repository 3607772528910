import React, { useEffect } from 'react'
import "./InFrame.css";
import { useLocation } from 'react-router-dom';
import NotFound from '../saasWebsite/userModule/common/NotFound';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Spin } from 'antd';
function InFrame() {
  // const { search } = useLocation();
  // const params = new URLSearchParams(search);
  // const rValue = params.get('r');
  const { as } = useParams();
  const [notFound,setNotFound] = useState(false);
  const [editorLoader,setEditorLoader] = useState(true);
  // console.log("RV",rValue);
  useEffect(()=>{
    let previewData = localStorage.getItem("previewData");
    if(previewData ===  null){
      setNotFound(true);
      setEditorLoader(false);
    }
    let timer = setTimeout(()=>{
      setEditorLoader(false);
    },3000);
    return ()=>{
      clearTimeout(timer);
    }
  },[])
  console.error(process.env.REACT_APP_BASE_PATH,window.baseurl);
  
  return (
    <>
    {editorLoader && (
      <>
        <div
          className="overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#000000",
            opacity: 1,
            zIndex: 1000,
            cursor: "wait",
          }}
        ></div>
        <span className="text-center top-1/2 left-1/2 absolute z-[10000000]">
          <Spin size="large" />
        </span>
      </>
    )}
    {notFound && <NotFound previewCheck={true}/>}
    <div style={{
      height: "100%",
	    margin: 0,
    }}>
    {!notFound && <div class="wrapper" id="wrapper" style={{
      width : as<1?"300px":as>1?"600px":"500px",
    }} >
         <div class="mainwrapper" id="mainwrapper"
         style={{
          paddingBottom : `${100/as}%`,
         }}>
				 <iframe id="videoIframe" class="mainwrapperIframe" src={`${process.env.REACT_APP_BASE_PATH}/video-preview`} width="100%" height="100%" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" scrolling="no" allowfullscreen></iframe>	
         </div>
      </div>}
    </div>
    </>
  )
}

export default InFrame