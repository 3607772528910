import React, { useEffect, useRef, useState } from "react";
import { Modal, Tooltip } from "antd";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import useWindowWidth from "../common/hooks/useWindowWidth";

const AssetsCard = ({ asset }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  const audioRef = useRef(null);
  const videoRef = useRef(null);

  const toggleAudio = () => {
    if (isAudioPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsAudioPlaying(!isAudioPlaying);
  };

  const showModal = () => {
    if (asset.ASSETSTYPE !== "audio") {
      setIsModalVisible(true);
    } else {
      toggleAudio();
    }
  };

  useEffect(() => {
    if (!isModalVisible) {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isModalVisible]);

  const handleCancel = () => {
    setIsModalVisible(false);
    if (isAudioPlaying) {
      toggleAudio();
    }
  };

  const handleAudioEnded = () => {
    setIsAudioPlaying(false); // Reset the state when audio playback ends
  };

  const assetsSizeInKB = asset.ASSETSSIZE; // Assuming assetsSize is in KB
  const assetsSizeInMB = (assetsSizeInKB / (1024 * 1024)).toFixed(2); // Convert to MB

  return (
    <div className="w-[335px] my-3 pr-6">
      <div className="flex justify-between shadow-card hover:shadow-card-hover rounded-5 transition delay-100 duration-300 ease-in-out p-1.5">
        {asset.ASSETSTYPE !== "audio" ? (
          <div className="w-1/4 bg-gray-200 flex items-center bg-contain">
            <Tooltip title={isMobile ? "" : "Click to preview assets"} trigger="hover">
              <span className="cursor-pointer" onClick={showModal}>
                {asset.ASSETSTYPE === "image" && (
                  <img
                    src={`${asset.ASSETSBASEPATH}/${asset.ASSETSSYSTEMFILENAME}`}
                    alt="Thumbnail"
                    className="w-full h-[75px] rounded-md"
                  />
                )}
                {asset.ASSETSTYPE === "video" && (
                  <video
                    width="100%"
                    height="75px"
                    className="rounded-md"
                    src={`${asset.ASSETSBASEPATH}/${asset.ASSETSSYSTEMFILENAME}`}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        ) : (
          <div className="w-1/4 h-[75px] bg-gray-200 flex items-center justify-center bg-contain">
            <button className="cursor-pointer border-none bg-transparent" onClick={showModal}>
              {isAudioPlaying ? <PauseCircleOutlined style={{ fontSize: '32px' }} /> : <PlayCircleOutlined style={{ fontSize: '32px' }} />}
            </button>
          </div>
        )}
        <div className="flex flex-col justify-between ml-3 w-[75%]">
          <div className="flex flex-col">
            <span className="text-[#474747] text-xs">
              {asset.ASSETSTYPE} {assetsSizeInMB} mb
            </span>
            <span className="text-black text-sm font-medium">
              {asset.ASSETSNAME}
            </span>
            <span className="text-[#7A7A7A] text-[11px]">
             Added by {asset.ASSETSUPDATEDBY}              
            </span>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <Modal
          title={`${asset.ASSETSNAME}`}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={400}
        >
          {asset.ASSETSTYPE === "image" && (
            <img
              src={`${asset.ASSETSBASEPATH}/${asset.ASSETSSYSTEMFILENAME}`}
              alt="Thumbnail"
              className="w-full h-full"
            />
          )}
          {asset.ASSETSTYPE === "video" && (
            <video
              ref={videoRef}
              className="w-full max-h-56"
              controls
              autoPlay
            >
              <source
                src={`${asset.ASSETSBASEPATH}/${asset.ASSETSSYSTEMFILENAME}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal>
      )}
      {asset.ASSETSTYPE === "audio" && (
        <audio ref={audioRef} src={`${asset.ASSETSBASEPATH}/${asset.ASSETSSYSTEMFILENAME}`} onEnded={handleAudioEnded} />
      )}
    </div>
  );
};

export default AssetsCard;
