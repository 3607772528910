import React, { useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Canva from "./Canva";
import "../Styling/Figma.css";
import Sidebar from "./leftSidebar/Sidebar";
import Delete from "../assets/Delete.svg";
import Clone from "../assets/Clone.svg";
import Lock from "../assets/unlock 1.svg";
import Unlock from "../assets/open-lock.svg";
import { useLocation, useNavigate } from "react-router-dom";
import RightSidebarDashboard from "./rightSidebar/RightSidebarDashboard";
import useSessionChecker from "../../saasWebsite/common/functions/useSessionChecker";
import { Button, Modal, Spin } from "antd";
import useCurrentUrl from "../../saasWebsite/common/functions/useCurrentUrl";
import DialogBox from "./DialogBox";
import { useCallbackPrompt } from "../hooks/useCallBackPrompt.js";
import { usePrompt } from "../hooks/usePrompt.js";
import { useProm } from "../hooks/useProm.js";
import history from "./history.js";
function Stigma() {
  const _ = require("lodash");
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();
  const [canvaContent, setContent] = useState([]);
  const [itemSelected, setItem] = useState(null);
  const [rightClicked, setRightClicked] = useState("");
  const [play, setPlay] = useState(true);
  const [dropX, setDropX] = useState("");
  const [dropY, setDropY] = useState("");
  const [elementProperties, setElementProperties] = useState({});
  const [deleted, setDeleted] = useState(true);
  const [dragDiv, setDragdiv] = useState(null);
  const [layerButton, setLayerButton] = useState(false);
  const [settingButton, setSettingButton] = useState(false);
  const [musicButton, setMusicButton] = useState(false);
  const [timeSetting, setTimeSetting] = useState(false);
  const [zSetter, setZSetter] = useState(5);
  const [contentArray, setContentArray] = useState([""]);
  const [contentIndex, setContentIndex] = useState(0);
  const [undoRedoButton, setUndoRedoButton] = useState("");
  const [isDivDropped, setIsDivDropped] = useState(false);
  const [templateDetails, setTemplateDetails] = useState(true);
  const [selectedSidebarItem, setSelectedSidebarItem] = useState(0);
  const [displayTextConfig, setDisplayTextConfig] = useState(true);
  const [timeLine, setTimeLine] = useState(true);
  const [autoPlay, setAutoPlay] = useState(true);
  const [storeData, setStoreData] = useState(true);
  const [variableData, setVariableData] = useState([]);
  const [misData, setMisData] = useState([]);
  const [lastAnimation, setLastAnimation] = useState("");
  const [newId, setNewId] = useState(0);
  const [index, setIndex] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [musicData, setMusicData] = useState([]);
  const [seekLayer, setSeekLayer] = useState(null);
  const [templateInfo, setTemplateInfo] = useState([]);
  const [pathName, setPathName] = useState(null);
  const [totalTime, setTotalTime] = useState("00:00");
  const [playbgmusic, setPlaybgmusic] = useState(false);
  const [musicLoop, setMusicLoop] = useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [groupSelection, setGroupSelection] = useState(false);
  const [align, setAlign] = useState("");
  const [alignElement, setAlignElement] = useState(new Set());
  const [maxCorners, setMaxCorners] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [disapper, setDisapper] = useState(false);
  const [editorLoader, setEditorLoader] = useState(true);
  const [templateName, setTemplateName] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [userId, setUserId] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [imageBase64, setImageBase64] = useState(null);
  const [ratio, setRatio] = useState("");
  const [resizeRatio, setResizeRatio] = useState(null);
  const [link, setLink] = useState(false);
  // const [fieldEnabled, setFieldEnabled] = useState(false);
  let fieldEnabled = useRef(false);
  const [allLocks, setAllLocks] = useState({ currState: 0, prevState: 0 });
  const [currLocks, setCurrLocks] = useState({ currState: 0, prevState: 0 });
  let [payload, setPayload] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRight, setIsRight] = useState(true);
  const [svgCode, setSVGCode] = useState([]);
  const [sizing, setSizing] = useState(false);
  const [vis, setVis] = React.useState(false);
  const [dragging, setDragging] = useState(true);
  const parentdivRef = useRef(null);
  const [isSaveTemplateClicked, setIsSaveTemplateClicked] = useState(false);
  const [horizontalAlign, setHorizontalAlign] = useState(null);
  const [verticalAlign, setVerticalAlign] = useState(null);
  const [baseColor,setBaseColor] =  useState("#ffffff");
  const [baseHeight,setBaseHeight] =  useState(400);
  const [showAlign,setShowAlign] =  React.useState(false);
  const [brandLoader, setBrandLoader] = useState(true);

  usePrompt("You will loose all the current changes", showDialog);

  // useProm(showDialog);

  const location = useLocation();
  function handleZ() {
    setZSetter(Number(zSetter) + 1);
    return Number(zSetter) + 1;
  }
  function handleId() {
    setNewId(Number(newId) + 1);
    return Number(newId) + 1;
  }
  function deleteDiv(index) {
    // if(canvaContent[index].id === "MultiLayer"){
    //   let arr = canvaContent.filter((item, idx) => !alignElement.has(idx));
    //   arr.splice(arr.length - 1, 1);
    //   setGroupSelection(false);
    //   setAlign("");
    //   setMaxCorners(null);
    //   setAlignElement(new Set([]));
    //   handleContent(arr);
    //   console.log("POP",arr);
    // }
    // else{
      canvaContent.splice(index, 1);
      handleContent(canvaContent);
      undoRedoAction();
    // }
    
    setDeleted(true);
    handleItem(null);
    setIsRight(true);
  }
  function undoRedoAction() {
    // console.log(canvaContent);
    if (contentIndex < 12) {
      let tempData = {};
      tempData.layers = _.cloneDeep(canvaContent.filter((item) => item.id!=="MultiLayer"));
      tempData.timing = currentTime;
      contentArray[contentIndex + 1] = tempData;
      // contentArray[contentIndex + 1] = _.cloneDeep(canvaContent);
      console.log(contentArray[contentIndex + 1]);
      setContentArray(_.cloneDeep(contentArray));
      setContentIndex(contentIndex + 1);
    } else {
      let newContent = contentArray.slice(1);
      let tempData = {};
      tempData.layers = _.cloneDeep(canvaContent.filter((item) => item.id!=="MultiLayer"));
      tempData.timing = currentTime;
      newContent.push(tempData);
      setContentArray(_.cloneDeep(newContent));
      setContentIndex(contentIndex);
    }
  }

  function handleCurrLock(currTime) {
    let currflag = true;
    let count = 0;
    canvaContent.forEach((item, index) => {
      if (currTime >= item.starttime && currTime <= item.stoptime) {
        count++;
        currflag = canvaContent[index].draggable;
      }
    });
    for (let i = 0; i < canvaContent.length; i++) {
      if (
        currTime >= canvaContent[i].starttime &&
        currTime <= canvaContent[i].stoptime
      ) {
        count--;
        if (currLocks.currState === -1) {
          if (canvaContent[i].draggable !== currflag) {
            setCurrLocks({ currState: 0, prevState: -1 });
            break;
          }
        } else if (currLocks.currState === 1) {
          if (canvaContent[i].draggable !== currflag) {
            setCurrLocks({ currState: 0, prevState: 1 });
            break;
          }
        } else {
          if (canvaContent[i].draggable !== currflag) {
            break;
          }
          if (count === 0 && canvaContent[i].draggable === currflag) {
            setCurrLocks(
              currflag
                ? { currState: -1, prevState: 0 }
                : { currState: 1, prevState: 0 }
            );
          }
        }
      }
    }
  }
  function handleDeleted() {
    deleted === true ? setDeleted(false) : setDeleted(true);
  }
  function handlePlayPause(val) {
    setPlay(val);
  }

  function handleContent(arr) {
    let newArr = [...arr];
    setContent(newArr);
    setShowDialog(true);
  }
  function handleItem(val) {
    setItem(val);
  }

  function handleProperties(val) {
    setElementProperties({ ...val });
  }

  function handleDropX(val) {
    setDropX(val);
  }

  function handleDropY(val) {
    setDropY(val);
  }

  // Convert vw to pixel
  function vwTOpx(value) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    var result = (w.screen.availWidth * value) / 100;
    return result;
  }

  useEffect(() => {
    console.log("ABCD", location);
    if(!showDialog && location.pathname === "/user/editor/updateBrandTemplate" && isSaveTemplateClicked){
      window.location.reload();
    }
  }, [location]);

  useEffect(() => {
    
    const unlisten = history.listen((update) => {
      if (showDialog && update.action === "POP") {
        let confirm = window.confirm("The changes you made will be removed");

        if (confirm) {
          navigate("/user/user-template", { replace: true });
        } else {
          if (location.pathname === "/user/editor/updateTemplate") {
            navigate("/user/editor/updateTemplate", { replace: true });
          } else {
            navigate("/user/editor/createTemplate", { replace: true });
          }
        }
      }
    });
    return () => {
      unlisten();
    };
  }, [showDialog]);

  useEffect(() => {
    if (showLoader) {
      setDisapper(true);
      setTimeout(() => {
        setDisapper(false);
      }, 1000);
    }
  }, [showLoader]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const createTemplateString = pathSegments[pathSegments.length - 1];
    setPathName(createTemplateString);
    console.log("ALert",createTemplateString);

    if (createTemplateString === "createBrandTemplate") {
      let apiData = localStorage.getItem("createBrandTemplateData");

      // console.log(apiData);
      if (!apiData) {
        navigate(`${process.env.REACT_APP_BASE_PATH}/user-template`);
      } else {
        apiData = apiData.replace(/[\x00-\x1F\x7F-\x9F]/g, "");
        let data = JSON.parse(apiData).DATA;
        setTemplateInfo(data);
        setTemplateName(data[0].BRANDPAGENAME);
        setTemplateId(data[0].BRANDPAGEID);
        setUserId(data[0].SESSIONUSERID);
        setTemplateDescription(data[0].BRANDPAGEDESC);
        console.log(data);
        console.log("ALert",data[0].BRANDPAGENAME,data[0].BRANDPAGEID,data[0].BRANDPAGEDESC);
      }
    } else if (createTemplateString === "updateBrandTemplate") {
      let apiData = localStorage.getItem("updateBrandTemplateData");
      if (!apiData || apiData === undefined) {
        navigate(`${process.env.REACT_APP_BASE_PATH}/user-template`);
      } else {
        apiData = apiData.replace(/[\x00-\x1F\x7F-\x9F]/g, "");
        setTemplateInfo(JSON.parse(apiData));
        console.log("ALertInfoHi",apiData);

        let allData = JSON.parse(apiData)[0].BRANDPAGEDATA;
        allData = allData.replace(/[\x00-\x1F\x7F-\x9F]/g, "");
        setTemplateName(JSON.parse(apiData)[0].BRANDPAGENAME);
        setTemplateId(JSON.parse(apiData)[0].BRANDPAGEID);
        setUserId(JSON.parse(apiData)[0].SESSIONUSERID);
        setTemplateDescription(JSON.parse(apiData)[0].BRANDPAGEDESC);
        console.log("ALert",allData,templateName,templateId,templateDescription);
        if (allData) {
          let parseData = JSON.parse(allData);
          // setContent(parseData.layerData.layers);
          setZSetter(parseData.templateData.highestZ);
          setTimeLine(parseData.templateData.timeLine);
          setAutoPlay(parseData.templateData.autoPlay);
          setMusicLoop(parseData.templateData.musicLoop);
          setPlaybgmusic(parseData.templateData.playbgmusic);
          setStoreData(parseData.templateData.storeData);
          setNewId(parseData.templateData.highestID);
          setImageData(parseData.templateData.imageData);
          setVideoData(parseData.templateData.videoData);
          setBaseHeight(parseData.templateData.baseHeight!==undefined ? parseData.templateData.baseHeight:400);
          setAudioData(
            parseData.templateData.audioData === undefined
              ? []
              : parseData.templateData.audioData
          );
          setMusicData(
            parseData.templateData.musicData === undefined
              ? []
              : parseData.templateData.musicData
          );
          let w = parseData.templateData.w;
          let h = parseData.templateData.h;
          let prevZoom = parseData.templateData.prevZoom;
          let currZoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
          console.log("X-X", prevZoom, currZoom);
          let parseLayers = parseData.layerData.layers;
          let misData = parseData.layerData.misData;
          let hiddenVariables = parseData.layerData.variableData;
          if (hiddenVariables) {
            setVariableData(hiddenVariables);
          }
          if (misData) {
            setMisData(misData);
          }
          let arr = [...parseLayers];
          
          let flag = arr[0]?.draggable;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].draggable !== flag) {
              setAllLocks({ currState: 0, prevState: -1 });
              break;
            }
            if (
              i === arr.length - 1 &&
              arr[arr.length - 1].draggable === flag
            ) {
              if (flag) {
                setAllLocks({ currState: -1, prevState: 0 });
              } else {
                setAllLocks({ currState: 1, prevState: 0 });
              }
            }
          }
          setContent(arr);
          let temp = {};
          temp.layers = arr;
          temp.timing = 0;
          contentArray[0] = temp;
          setContentArray(_.cloneDeep(contentArray));
          setContentIndex(0);
        }
        let viewPort = {
          height: window.innerHeight,
          width: window.innerWidth,
        };
        localStorage.setItem("viewPort", JSON.stringify(viewPort));
      }
    }
    setBrandLoader(false);
  }, []);

  useEffect(() => {
    if (
      itemSelected === null &&
      dragDiv === null &&
      layerButton === false &&
      settingButton === false
    ) {
      setTemplateDetails(true);
    }
  }, [layerButton, settingButton]);
  // console.log(deleted);
  // console.log(itemSelected);
  console.log(canvaContent);
  console.log("Zoom", ((window.outerWidth - 10) / window.innerWidth) * 100);
  return (
    <div
      id="figma"
      className="overflow-hidden
       bg-[#181d1f] "
    >
       {(showLoader || disapper) && (
        <>
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(0, 0, 0,0.5)",
              opacity: 1,
              zIndex: 1000,
              cursor: "wait",
            }}
          ></div>
          <span className="text-center top-1/2 left-1/2 absolute z-[10000000]">
            <Spin size="large" />
          </span>
        </>
      )}


      {imageBase64 !== null && (
        <>
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(0, 0, 0,0.5)",
              opacity: 1,
              zIndex: 1000,
              cursor: "wait",
            }}
          ></div>
          <span className="text-center top-1/2 left-1/2 absolute z-[10000000]">
            <Spin size="large" />
          </span>
        </>
      )}

      {brandLoader && (
        <>
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#000000",
              opacity: 1,
              zIndex: 1000,
              cursor: "wait",
            }}
          ></div>
          <span className="text-center top-1/2 left-1/2 absolute z-[10000000]">
            <Spin size="large" />
          </span>
        </>
      )}

      {index !== null &&  (
        <div
          className="optionMenu"
          style={{
            position: "absolute",
            left:
              parentdivRef.current.getBoundingClientRect().left +
              canvaContent[index].left +
              canvaContent[index].width / 2 +
              "px",
            top:
              parentdivRef.current.getBoundingClientRect().top +
              canvaContent[index].top -
              44 +
              "px",
            height: "42px",
            // width: "120px",
            borderRadius: "4px",
            backgroundColor: "#FFF",
            boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.15)",
            zIndex: 1000000,
            display:
              // obj.id === props.itemSelected &&
              rightClicked !== "" ? "block" : "none",
          }}
        >
          <div className="flex">
            {canvaContent[index].draggable && (
              <div
                className="w-[40px] p-3 cursor-pointer border-solid border-0 !border-r-[1px] border-[#E2E2E2]"
                onClick={() => {
                  // if (canvaContent[index].id === "MultiLayer") {
                  //   for (const idx of alignElement) {
                  //     let newObj = { ...canvaContent[idx] };
                  //     newObj.zindex = handleZ();
                  //     newObj.id = handleId();
                  //     newObj.layername =
                  //       newObj.type.toUpperCase() + "_" + newObj.id;
                  //     newObj.left = canvaContent[idx].left + 10;
                  //     newObj.top = canvaContent[idx].top + 10;
                  //     canvaContent.push({...newObj});
                  //   }
                  //   handleContent(canvaContent);
                  //   undoRedoAction();
                  //   setRightClicked("");
                  // } else {
                    let newObj = { ...canvaContent[index] };
                    newObj.zindex = handleZ();
                    newObj.id = handleId();
                    newObj.layername =
                      newObj.type.toUpperCase() + "_" + newObj.id;
                    newObj.left = canvaContent[index].left + 10;
                    newObj.top = canvaContent[index].top + 10;
                    canvaContent.push(newObj);
                    handleContent(canvaContent);
                    undoRedoAction();
                    setItem(newObj.id);
                    setRightClicked("");
                  // }
                }}
              >
                <img src={Clone} alt="Copy" />
              </div>
            )}
            {canvaContent[index].draggable && (
              <div
                className="w-[40px] p-3 cursor-pointer border-solid border-0 !border-r-[1px] !border-[#E2E2E2]"
                onClick={() => {
                  deleteDiv(index);
                  setIndex(null);
                  setTemplateDetails(true);
                }}
              >
                <img src={Delete} alt="delete" />
              </div>
            )}
            { (
            <div
              className="w-[40px] p-3 cursor-pointer"
              onClick={() => {
                canvaContent[index].draggable = !canvaContent[index].draggable;
                let flag = canvaContent[0]?.draggable;
                for (let i = 0; i < canvaContent.length; i++) {
                  if (allLocks.currState === -1) {
                    if (canvaContent[i].draggable !== flag) {
                      setAllLocks({ currState: 0, prevState: -1 });
                      break;
                    }
                  } else if (allLocks.currState === 1) {
                    if (canvaContent[i].draggable !== flag) {
                      setAllLocks({ currState: 0, prevState: 1 });
                      break;
                    }
                  } else {
                    if (canvaContent[i].draggable !== flag) {
                      break;
                    }
                    if (
                      i === canvaContent.length - 1 &&
                      canvaContent[canvaContent.length - 1].draggable === flag
                    ) {
                      setAllLocks(
                        flag
                          ? { currState: -1, prevState: 0 }
                          : { currState: 1, prevState: 0 }
                      );
                    }
                  }
                }

                handleCurrLock(currentTime);

                handleContent([...canvaContent]);
                if (!canvaContent[index].draggable) {
                  setItem(null);
                  setDeleted(true);
                  setTemplateDetails(true);
                } else {
                  setItem(canvaContent[index].id);
                }
                setRightClicked("");
              }}
            >
              {canvaContent[index].draggable ? (
                <img src={Lock} alt="lock" />
              ) : (
                <img src={Unlock} alt="Unlock" />
              )}
            </div>)}
          </div>
        </div>
      )}
      <Navbar
        canvaContent={canvaContent}
        handlePlayPause={handlePlayPause}
        handleContent={handleContent}
        itemSelected={itemSelected}
        handleItem={handleItem}
        deleted={deleted}
        setDeleted={setDeleted}
        handleDeleted={handleDeleted}
        layerButton={layerButton}
        setLayerButton={setLayerButton}
        settingButton={settingButton}
        setSettingButton={setSettingButton}
        musicButton={musicButton}
        setMusicButton={setMusicButton}
        timeSetting={timeSetting}
        setTimeSetting={setTimeSetting}
        undoRedoAction={undoRedoAction}
        setRightClicked={setRightClicked}
        templateInfo={templateInfo}
        groupSelection={groupSelection}
        setGroupSelection={setGroupSelection}
        align={align}
        setAlign={setAlign}
        alignElement={alignElement}
        setAlignElement={setAlignElement}
        maxCorners={maxCorners}
        setMaxCorners={setMaxCorners}
        contentIndex={contentIndex}
        setContentIndex={setContentIndex}
        setIndex={setIndex}
        setTemplateDetails={setTemplateDetails}
        contentArray={contentArray}
        ratio={ratio}
        setRatio={setRatio}
        setShowLoader={setShowLoader}
        zSetter={zSetter}
        id={newId}
        timeLine={timeLine}
        autoPlay={autoPlay}
        storeData={storeData}
        musicLoop={musicLoop}
        playbgmusic={playbgmusic}
        imageData={imageData}
        videoData={videoData}
        audioData={audioData}
        musicData={musicData}
        variableData={variableData}
        templateName={templateName}
        templateDescription={templateDescription}
        templateId={templateId}
        userId={userId}
        misData={misData}
        setMisData={setMisData}
        setSeekLayer={setSeekLayer}
        fieldEnabled={fieldEnabled}
        setShowDialog={setShowDialog}
        isModalOpen={isModalOpen}
        isRight={isRight}
        setIsRight={setIsRight}
        vis={vis}
        setVis={setVis}
        isSaveTemplateClicked = {isSaveTemplateClicked}
        setIsSaveTemplateClicked = {setIsSaveTemplateClicked}
        horizontalAlign = {horizontalAlign}
        setHorizontalAlign = {setHorizontalAlign}
        verticalAlign = {verticalAlign}
        setVerticalAlign = {setVerticalAlign}
        showAlign = {showAlign}
        setShowAlign = {setShowAlign}
        baseHeight = {baseHeight}
      />
      <div className="flex">
        <Sidebar
          play={play}
          handleDropX={handleDropX}
          handleDropY={handleDropY}
          handleZ={handleZ}
          id={newId}
          handleId={handleId}
          setRightClicked={setRightClicked}
          templateInfo={templateInfo}
          setTemplateDetails={setTemplateDetails}
          setLayerButton={setLayerButton}
          setSettingButton={setSettingButton}
          setMusicButton={setMusicButton}
          imageData={imageData}
          setImageData={setImageData}
          videoData={videoData}
          setVideoData={setVideoData}
          pathName={pathName}
          musicData={musicData}
          setMusicData={setMusicData}
          currentTime={currentTime}
          fieldEnabled={fieldEnabled}
          // setFieldEnabled={setFieldEnabled}
          index={index}
          canvaContent={canvaContent}
          handleContent={handleContent}
          itemSelected={itemSelected}
          payload={payload}
          setPayload={setPayload}
          isModalOpen={isModalOpen}
          setShowDialog={setShowDialog}
          isRight={isRight}
          vis={vis}
          templateId={templateId}
          userId={userId}
          templateName={templateName}
        />
        {/* <ToolBar changeMode={changeMode} play={play}></ToolBar> */}
        <Canva
          canvaContent={canvaContent}
          play={play}
          setPlay={setPlay}
          itemSelected={itemSelected}
          handleItem={handleItem}
          handleContent={handleContent}
          deleted={deleted}
          setDeleted={setDeleted}
          dragDiv={dragDiv}
          setDragdiv={setDragdiv}
          dropX={dropX}
          dropY={dropY}
          handleProperties={handleProperties}
          elementProperties={elementProperties}
          zSetter={zSetter}
          handleZ={handleZ}
          id={newId}
          handleId={handleId}
          undoRedoButton={undoRedoButton}
          setUndoRedoButton={setUndoRedoButton}
          contentArray={contentArray}
          setContentArray={setContentArray}
          contentIndex={contentIndex}
          setContentIndex={setContentIndex}
          undoRedoAction={undoRedoAction}
          isDivDropped={isDivDropped}
          setIsDivDropped={setIsDivDropped}
          setItem={setItem}
          variableData={variableData}
          misData={misData}
          setMisData={setMisData}
          setLayerButton={setLayerButton}
          setSettingButton={setSettingButton}
          setMusicButton={setMusicButton}
          setTemplateDetails={setTemplateDetails}
          selectedSidebarItem={selectedSidebarItem}
          setSelectedSidebarItem={setSelectedSidebarItem}
          setDisplayTextConfig={setDisplayTextConfig}
          timeLine={timeLine}
          autoPlay={autoPlay}
          storeData={storeData}
          lastAnimation={lastAnimation}
          setLastAnimation={setLastAnimation}
          seekLayer={seekLayer}
          setSeekLayer={setSeekLayer}
          index={index}
          setIndex={setIndex}
          rightClicked={rightClicked}
          setRightClicked={setRightClicked}
          templateInfo={templateInfo}
          imageData={imageData}
          setImageData={setImageData}
          videoData={videoData}
          setVideoData={setVideoData}
          audioData={audioData}
          musicData={musicData}
          pathName={pathName}
          totalTime={totalTime}
          setTotalTime={setTotalTime}
          musicLoop={musicLoop}
          playbgmusic={playbgmusic}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
          groupSelection={groupSelection}
          setGroupSelection={setGroupSelection}
          align={align}
          setAlign={setAlign}
          alignElement={alignElement}
          setAlignElement={setAlignElement}
          maxCorners={maxCorners}
          setMaxCorners={setMaxCorners}
          setShowLoader={setShowLoader}
          editorLoader={editorLoader}
          setEditorLoader={setEditorLoader}
          templateName={templateName}
          templateDescription={templateDescription}
          ratio={ratio}
          setRatio={setRatio}
          resizeRatio={resizeRatio}
          setResizeRatio={setResizeRatio}
          link={link}
          setLink={setLink}
          fieldEnabled={fieldEnabled}
          allLocks={allLocks}
          setAllLocks={setAllLocks}
          currLocks={currLocks}
          setCurrLocks={setCurrLocks}
          isRight={isRight}
          setIsRight={setIsRight}
          svgCode={svgCode}
          setSVGCode={setSVGCode}
          sizing={sizing}
          setSizing={setSizing}
          vis={vis}
          setVis={setVis}
          dragging={dragging}
          setDragging={setDragging}
          parentdivRef={parentdivRef}
          baseColor = {baseColor}
          setBaseColor={setBaseColor}
          baseHeight = {baseHeight}
          setBaseHeight = {setBaseHeight}
          setShowAlign = {setShowAlign}
        />
        
        <RightSidebarDashboard
          itemSelected={itemSelected}
          setItem={setItem}
          canvaContent={canvaContent}
          handleContent={handleContent}
          elementProperties={elementProperties}
          deleted={deleted}
          setDeleted={setDeleted}
          handleDeleted={handleDeleted}
          layerButton={layerButton}
          setLayerButton={setLayerButton}
          settingButton={settingButton}
          setSettingButton={setSettingButton}
          timeSetting={timeSetting}
          setTimeSetting={setTimeSetting}
          musicButton={musicButton}
          setMusicButton={setMusicButton}
          undoRedoAction={undoRedoAction}
          isDivDropped={isDivDropped}
          setIsDivDropped={setIsDivDropped}
          templateDetails={templateDetails}
          setTemplateDetails={setTemplateDetails}
          selectedSidebarItem={selectedSidebarItem}
          setSelectedSidebarItem={setSelectedSidebarItem}
          displayTextConfig={displayTextConfig}
          timeLine={timeLine}
          setTimeLine={setTimeLine}
          autoPlay={autoPlay}
          setAutoPlay={setAutoPlay}
          storeData={storeData}
          setStoreData={setStoreData}
          variableData={variableData}
          setVariableData={setVariableData}
          misData={misData}
          setMisData={setMisData}
          lastAnimation={lastAnimation}
          setLastAnimation={setLastAnimation}
          seekLayer={seekLayer}
          setSeekLayer={setSeekLayer}
          index={index}
          setIndex={setIndex}
          setRightClicked={setRightClicked}
          templateInfo={templateInfo}
          pathName={pathName}
          totalTime={totalTime}
          audioData={audioData}
          setAudioData={setAudioData}
          musicLoop={musicLoop}
          setMusicLoop={setMusicLoop}
          playbgmusic={playbgmusic}
          setPlaybgmusic={setPlaybgmusic}
          musicData={musicData}
          setMusicData={setMusicData}
          groupSelection={groupSelection}
          setGroupSelection={setGroupSelection}
          setAlign={setAlign}
          alignElement={alignElement}
          setAlignElement={setAlignElement}
          templateName={templateName}
          setTemplateName={setTemplateName}
          templateDescription={templateDescription}
          setTemplateDescription={setTemplateDescription}
          imageBase64={imageBase64}
          setImageBase64={setImageBase64}
          currentTime={currentTime}
          resizeRatio={resizeRatio}
          setResizeRatio={setResizeRatio}
          link={link}
          setLink={setLink}
          fieldEnabled={fieldEnabled}
          allLocks={allLocks}
          setAllLocks={setAllLocks}
          currLocks={currLocks}
          setCurrLocks={setCurrLocks}
          payload={payload}
          setPayload={setPayload}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isRight={isRight}
          setIsRight={setIsRight}
          svgCode={svgCode}
          setSVGCode={setSVGCode}
          sizing={sizing}
          setSizing={setSizing}
          vis={vis}
          dragging={dragging}
          setDragging={setDragging}
          horizontalAlign = {horizontalAlign}
          setHorizontalAlign = {setHorizontalAlign}
          verticalAlign = {verticalAlign}
          setVerticalAlign = {setVerticalAlign}
          baseColor = {baseColor}
          setBaseColor={setBaseColor}
          baseHeight = {baseHeight}
          setBaseHeight = {setBaseHeight}
        />
      </div>
    </div>
  );
}

export default Stigma;
