import React from "react";
import SimpleButton from "../../../common/components/SimpleButton";
import "../../../common/styles/report/credits/Styles.css";
import { DatePicker, Select, Space } from "antd";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import {
  getCreditReportDataSearch,
  getCreditReportToday,
} from "../../../api/api_updateData";
import { toast } from "react-toastify";
import useWindowWidth from "../../../common/hooks/useWindowWidth";

const { Option } = Select;
const { RangePicker } = DatePicker;

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf("day");
};

const CreditHeader = ({
  sessionuserid,
  tableData,
  getTableHeading,
  handleUpdateDashboard,
}) => {
  sessionuserid = localStorage.getItem("sessionUserId");
  const [isActive, setIsActive] = useState("#D9D9D9");
  const [customDateVisible, setCustomDateVisible] = useState(false);
  const selectDateDivRef = useRef(null);
  const [selectedDays, setSelectedDays] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState("Template");
  const [selectedType, setSelectedType] = useState("New");
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;

  // };
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);

    if (value === "Template") {
      setSelectedType("New");
    } else if (value === "Link") {
      setSelectedType("Generated");
    }
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
    console.log(`selected ${value}`);
  };

  // const { data, isLoading, isError, refetch } = useQuery(
  //   ["dashboard", sessionuserid],
  //   // () => getCreditReport(sessionuserid, null, "reports"),
  //   () => getCreditReportToday(sessionuserid, null, "reports"),
  //   {
  //     staleTime: 10 * 60 * 1000,
  //     onSuccess: (data) => {
  //       // debugger
  //       console.log(data, "DATA");
  //       if (data.STATUS === "1000") {
  //         const creditData = JSON.parse(data?.DATA);
  //         // debugger
  //         const dataList = creditData;
  //         // setCreditList(dataList);
  //         tableData(dataList)
  //         // debugger
  //         // console.log(data.status, "CREDIT DATA");
  //       }
  //       // console.log(data, "CREDIT DATA")
  //     },
  //   }
  // );

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  const handleDivClick = () => {
    setIsActive("#4095FE");
  };

  const handleOutsideClick = (e) => {
    if (
      selectDateDivRef.current &&
      !selectDateDivRef.current.contains(e.target)
    ) {
      setIsActive("#D9D9D9");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function handleDateChange(date, dateString) {
    console.log("Selected Date:", dateString);
    setSelectedDays("");
    const fromDate = dateString[0];
    const toDate = dateString[1];
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
  }

  const handleSelectChange = (value) => {
    if (value === "date") {
      setCustomDateVisible(true);
      console.log(value, "DATE");
    } else {
      setCustomDateVisible(false);
      console.log(value, "DATE");
      const days = value;
      setSelectedDays(days);
      setSelectedFromDate("");
      setSelectedToDate("");
    }
  };

  const { mutate: getCreditDataSearch } = useMutation(
    (searchData) => getCreditReportDataSearch(searchData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          const creditData = JSON.parse(data?.DATA);
          // debugger
          const dataList = creditData;
          // setCreditList(dataList);
          tableData(dataList);
          handleUpdateDashboard();
          // onSearchResult(searchData);
          // updateDashboard();
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const handleSearchResult = () => {
    const searchData = {
      category: selectedCategory,
      selectedType: selectedType,
      days: selectedDays === null ? "0" : selectedDays,
      fromdate: selectedFromDate,
      todate: selectedToDate,
      sessionuserid: sessionuserid,
    };
    // debugger

    // if (selectedDays === null) {
    //   searchData.days = "0";
    // }
    console.log(searchData, "SEARCH DATA JSON");
    getCreditDataSearch(searchData);
  };
  const tableHeading = selectedCategory + " " + selectedType;
  // console.log(tableHeading, "HEADING");
  getTableHeading(tableHeading);
  return (
    <>
      <div className={` ${isDesktop ? "h-[80px]" : "h-[100px] pb-4"} w-full flex items-center`}>
        <div className="flex items-center justify-between w-[100%]">
          <Space size={12} align="center" className="credit-dropdown flex flex-wrap">
            <Select
              style={{ width: 120 }}
              value={selectedCategory}
              onChange={handleCategoryChange}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Option value="Template">Template</Option>
              <Option value="Link">Link</Option>
            </Select>

            {selectedCategory === "Template" ? (
              <>
                <Select
                  style={{ width: 120, marginLeft: isDesktop ? 16 : 4 }}
                  value={selectedType}
                  defaultValue="New"
                  onChange={handleTypeChange}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  <Option value="New">New</Option>
                  <Option value="Clone">Clone</Option>
                </Select>
              </>
            ) : (
              <>
                <Select
                  style={{ width: 120, marginLeft: 16 }}
                  value={selectedType}
                  defaultValue="Generated"
                  onChange={handleTypeChange}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  <Option value="Generated">Generated</Option>
                  <Option value="Played">Played</Option>
                </Select>
              </>
            )}

            <div
              style={{
                position: "relative",
                border: "1px solid",
                borderRadius: "4px",
                borderColor: isActive,
              }}
              className="selectDateDiv mr-5"
              onClick={handleDivClick}
              ref={selectDateDivRef}
            >
              <Select
                defaultValue="0"
                style={{
                  width: isDesktop ? 285 : 210,
                  height: 31,
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                bordered={false}
                onChange={handleSelectChange}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Option value="0">Today</Option>
                <Option value="1">Yesterday</Option>
                <Option value="7">Last 7 Days</Option>
                <Option value="30" Select>
                  Last 30 Days
                </Option>
                <Option value="90">Last 90 Days</Option>
                <Option value="date">Custom Date</Option>
              </Select>
              <span>
                {customDateVisible && (
                  <RangePicker
                    className="RangeDate cursor-pointer"
                    onChange={handleDateChange}
                    bordered={false}
                    popupClassName={`${isMobile ? "popupCalendar " : ""}`}
                    style={{
                      width: isDesktop ? 260 : 185,
                      height: 30,
                      position: "absolute",
                      left: 0,
                      top: 0,
                    }}
                    disabledDate={disabledDate}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                )}
              </span>
            </div>

            <span className={`${isLargeScreen ? "" : "ml-[-15px]"}`}>
              <SimpleButton
                label={isDesktop ? "Search Results" : "Search"}
                type="primary"
                onClick={handleSearchResult}
              />
            </span>
          </Space>
        </div>
      </div>
    </>
  );
};

export default CreditHeader;
