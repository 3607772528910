import { Modal, Spin } from 'antd'
import React from 'react'
import SimpleButton from '../../SimpleButton'

const ShowPreviewVideoModal = (props) => {
  return (
    <>
    <Modal
        className="global-template-preview-modal overflow-auto  scrollbar-w-[2px] scrollbar-thumb-rounded-full
        scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
        maskClosable={false}
        onCancel={() => props.setDialog(false)}
        open={props.dialog}
        width={1000}
      >
        <div className="flex">
            <div className="w-1/2 preview-container">
              <iframe
                crossOrigin="anonymous"
                id="preview-iframe-id"
                className="preview-iframe"
                title="Video Preview"
                width="100%"
                height="350"
                src={props.previewfinallink}
                frameBorder="0"
                allowFullScreen
                scrolling="no"
                onLoad={props.iframeload} // Listen for iframe load event
              />
            </div>
            <div
              className="w-1/2 flex flex-col px-8 h-[300px]"
              // style={{ justifyContent: "space-between" }}
            >
              <div className="flex flex-col space-y-4">
                <span className="text-2xl mt-4 text-black font-medium">
                  {props.templatename}
                </span>
                <span className="text-sm text-[#6d6d6d] font-normal mt-2">
                  {props.templatedesc}
                </span>
              </div>
              <div className="flex justify-between items-center mt-6">
                <SimpleButton
                  type="primary"
                  className="bg-[#1C68EB] hover:bg-gradient-to-r hover:to-[#0943A6] hover:from-[#1C68EB] rounded-3xl text-white hover:!text-white font-normal text-sm px-6 mr-2"
                  label="Preview Template"
                //   onClick={openPreviewLink}
                />
                <SimpleButton
                  type="primary"
                  className="bg-[#1C68EB] hover:bg-gradient-to-r hover:to-[#0943A6] hover:from-[#1C68EB] rounded-3xl text-white hover:!text-white font-normal text-sm px-6"
                  label="Use Template"
                //   onClick={navigateToAnotherRoute}
                />
              </div>
            </div>
          </div>
          {props.spinning &&
            !props.iframeloaded && ( // Show spinner only if iframe hasn't loaded
              <div className="overlay">
                <span className="text-center absolute inset-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2 z-[10000000]">
                  <Spin size="large" />
                </span>
              </div>
            )}
      </Modal>
    </>
  )
}

export default ShowPreviewVideoModal