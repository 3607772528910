import React from "react";
import { Button, Input, Popover, Switch, Table } from "antd";
import { ReactComponent as AnalyticImg } from "../../../assests/commonIcons/analytics/Column Filter.svg";
import { ReactComponent as DownArrow } from "../../../assests/commonIcons/analytics/Vector.svg";
import SimpleButton from "../../../common/components/SimpleButton";
import { useState } from "react";
import { useEffect } from "react";
import "../../../common/styles/report/analyticsStyles.css";
import { useMemo } from "react";
import useWindowWidth from "../../../common/hooks/useWindowWidth";

const PlayReportTable = ({ pRTableData, pageTitle }) => {
  console.log(pRTableData, "PRTABLE DATA");

  const [switchStates, setSwitchStates] = useState({});
  const [switchStatesInitial, setSwitchStatesInitial] = useState({});
  const [switchStatesFinal, setSwicthStatesFinal] = useState({});
  const [currCols, setCurrentCols] = useState([]);
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  // Initialize columns based on pRTableData[0]
  const columns = useMemo(() => {
    return pRTableData && pRTableData.length > 0
      ? Object.keys(pRTableData[0]).map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
        }))
      : [];
  }, [pRTableData]);

  useEffect(() => {
    if (pRTableData && pRTableData.length > 0) {
      const columnSwitch = Object.keys(pRTableData[0]);

      const first6Keys = columnSwitch.slice(0, 6);

      const allSwitches = {};

      first6Keys.forEach((item) => {
        allSwitches[`switch${item}`] = true;
      });
      // debugger
      setSwitchStates(allSwitches);
      setSwitchStatesInitial(allSwitches);
    }
  }, [pRTableData]);

  const handleSwitchChange = (switchName) => (checked) => {
    // debugger
    const updatedSwitchStates = {
      ...switchStates,
      [switchName]: checked,
    };
    console.log("Updated Switch States:", updatedSwitchStates);
    setSwitchStates(updatedSwitchStates);
    setSwicthStatesFinal(updatedSwitchStates);
  };

  const resetSwitches = () => {
    setSwitchStates(switchStatesInitial);
    setSwicthStatesFinal(switchStatesInitial);
  };

  useEffect(() => {
    if (Object.keys(switchStatesFinal).length === 0) {
      setCurrentCols(columns.slice(0, 6));
    } else {
      setCurrentCols(switchStatesFinal);
      console.log(currCols, "CURRR COLS")
      // debugger
      const cols = [];
      let index = 0;
      for (let key in switchStatesFinal) {
        if (switchStatesFinal[key] === true) {
          cols.push(columns[index]);
        }
        index++;
      }
      console.log(cols, "NEW COL")
      setCurrentCols(cols);
    }
  }, [switchStatesFinal, columns]);

  // if (!pRTableData || pRTableData.length === 0) {
  //   return (
  //     <p>No data available. Please select filters from above to get results</p>
  //   );
  // }
  // }

  // const columns = Object.keys(pRTableData[0] ||  {})?.map((key) => ({
  //   title: key,
  //   dataIndex: key,
  //   key: key,
  // }));

  // console.log(columns, "COLUMNS")

  return (
    <>
      <div className={`flex flex-wrap justify-between align-middle bg-white pt-6 ${isDesktop ? "" : "pb-3"}`}>
        <div className="flex">
          <div className={` ${isDesktop ? "mb-6" : ""} w-[300px] pl-2 `}>
            <Input
              style={{ border: "1px solid #D6D6D6" }}
              className="block w-full border-2 relative pl-4 italic h-9 rounded-[5p] p-1 bg-[#F9F9F9] text-[#676767]"
              placeholder="Search by links, device, browser, country ..."
            ></Input>
          </div>
        </div>
        <div className={`flex ${isDesktop ? "" : "justify-between w-full mt-2"}`}>
          <div className={`${isDesktop ? "" : "pl-2"}`}>
            <SimpleButton
              label={"Download"}
              disabled
              className={
                "w-[102px] h-[32px] bg-[#1C67E9] text-[#FFFFFF] TableHead"
              }
            />
          </div>
          <div className="mx-5">
            <Popover
              className="custom-popover"
              placement="bottomRight"
              content={
                <>
                  <div className="popover-switch pt-2 px-2 scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full scrollbar-thumb-[#CCCCCC] scrollbar-track-[#EFEFFA] overflow-y-scroll">
                    {columns?.map((obj, index) => {
                      const isChecked = index < 6 ? switchStates[`switch${obj.title}`] : false;
                      return (
                        <div className="flex justify-between mb-2">
                          {obj.title}
                          <Switch
                            size="small"
                            key={obj.title}
                            // disabled = {obj.title === 1 || obj.title === 2|| obj.title===3 ? true : false}
                            // checked={switchStates[`switch${obj.title}`]}
                            checked={isChecked}
                            onChange={handleSwitchChange(`switch${obj.title}`)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    className="bg-white text-[#34519C] border-0 mt-1"
                    onClick={resetSwitches}
                  >
                    Reset to default
                  </Button>
                </>
              }
              trigger="click"
            >
              <button>
                {
                  <div className="flex">
                    <AnalyticImg />{" "}
                    <DownArrow
                      style={{
                        fontSize: "1px",
                        marginTop: "10px",
                        marginLeft: "1px",
                      }}
                    />
                  </div>
                }
              </button>
            </Popover>
          </div>
        </div>
      </div>
      <div className={`play-report-table`}>
        <Table
          dataSource={pRTableData}
          // columns={columns}
          columns={currCols}
          rowKey="ID"
          scroll={{ x: columns.length <= 6 ?  "w-full" : isDesktop ? 1900 : 1000 }}
        />
      </div>
    </>
  );
};

export default PlayReportTable;
