import { Modal } from "antd";
import box from "../../assests/commonIcons/userModuleIcons/box.svg";
import SimpleButton from "./SimpleButton";
import "../styles/commonStyles.css";
import GhostButton from "./GhostButton";

const CloseDialog = (props) => {
  return (
    <>
      <br />
      <Modal
        className="delete-account-modal"
        maskClosable={false}
        onCancel={() => props.setDialog(false)}
        centered
        closeIcon={false}
        open={props.dialog}
      >
        <div>
          <div className="w-full flex justify-center mb-2">
            <img src={box} className="w-16 h-16" alt="box" />
          </div>
          <div className="font-medium text-[#000000] text-lg text-center">
            {props.title}
          </div>
          <div className="text-center text-[#757575] font-medium text-sm mt-2 mb-4">
            {props.description}
          </div>
          <div className="flex gap-6 mb-1 justify-center">
            <GhostButton
              key="Proceed"
              // onClick={}
              label="Delete"
              disabled
              size="large"
              className="white-bg-btn !px-6 text-blue-500 font-inter text-sm font-normal leading-5 !rounded-[4px] border border-blue-500"
            />
            <SimpleButton
              onClick={() => props.setDialog(false)}
              className="!rounded-[4px] w-fit !px-6"
              label="Cancel"
              type="primary"
              size="large"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CloseDialog;
