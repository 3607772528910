import React, { useState, useEffect } from "react";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import OtpInput from "otp-input-react";
import ResendOTP from "../../assests/commonIcons/userModuleIcons/ResendOTP.svg";
import ResendOTPGrey from "../../assests/commonIcons/userModuleIcons/ResendOTPGrey.svg";
import SimpleButton from "../../common/components/SimpleButton";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createSession } from "../../common/functions/Session";
import { validateForgotPwOtp } from "../../api/api_updateData";
import { forgetPassword } from "../../api/api_updateData";

const ForgotPwOtpVerify = (props) => {
  const [otp, setOtp] = useState("");
  const [isOtpFilled, setIsOtpFilled] = useState(false); // New state variable for tracking OTP input status

  let navigate = useNavigate();

  //****************************** CREATE CUSOTMER STARTS ******************************************
  const { mutate: createValidateEmailOtpMutation } = useMutation(
    (emailVerifyData) => validateForgotPwOtp(emailVerifyData),
    {
      onSuccess: (data) => {
        // queryClient.invalidateQueries("");
        const sessionUserId = props.email;
        const sessionId = createSession(sessionUserId);
        localStorage.setItem("sessionId", sessionId); // Store sessionId in localStorage
        localStorage.setItem("sessionUserId", sessionUserId); // Store sessionUserId in localStorage
        console.log(sessionUserId, "session user ID");

        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
          // navigate(`${process.env.REACT_APP_BASE_PATH}/reset-password`);
          navigate(props.sendRoute);
          props.setForgotPasswordClicked(false);
          console.log(props.sendRoute, "ROUTE");
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  //  *************************** CREATE CUSOTMER ENDS ******************************************

  const handleVerifyEmail = () => {
    const emailVerifyData = {
      custregid: props.email,
      emailotp: otp,
    };
    createValidateEmailOtpMutation(emailVerifyData);
    console.log(emailVerifyData, "CUST DATA");
  };

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const resendOTP = () => {
    setMinutes(0);
    setSeconds(30);
  };

  const changeEmail = () => {
    props.setIsRegistered(false);
  };

  // Use useEffect to check whether all OTP inputs are filled whenever `otp` changes
  useEffect(() => {
    setIsOtpFilled(otp.length === 6);
  }, [otp]);

  // FUNCTION FOR RESEND OTP ***************************************
  const { mutate: forgetPasswordMutation } = useMutation(
    (custData) => forgetPassword(custData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const handleForgetPassword = () => {
    // Convert the password to Base64 using btoa() function
    // const encryptPassword = window.btoa(password); // WILL BE USED IN FUTURE
    const custData = {
      custregid: props.email,
    };
    forgetPasswordMutation(custData);
    console.log(custData, "CUST DATA");
  };

  return (
    <div>
      <div className="form-wrapper email-verify otp-section">
        <div className="flex flex-col text-center">
          <div className="w-[200px] ml-auto mr-auto">
            <img src={newVspagyLogo} alt="vspagyLogo" className="w-full mb-4" />
          </div>
          <span className="font-semibold text-[#151515] my-2 text-[1.5rem] select-none">
            Verify Your Email
          </span>
          <span className="text-base font-normal text-[#868686] select-none">
            We've sent an email with the OTP for confirmation,{" "}
            <span className="text-[#464646] font-medium">{props.email}</span>.
            Check your spam or junk folder if you did not receive the email.
          </span>
          <span className="my-8">
            <OtpInput
              value={otp}
              onChange={setOtp}
              OTPLength={6}
              otpType="number"
              disabled={false}
              autoFocus
              className="opt-container-email"
            ></OtpInput>
          </span>
          <span className="flex justify-between items-center mb-6">
            <span
              onClick={resendOTP}
              size="large"
              disabled={seconds === 0 ? false : true}
              className=" text-black border-none cursor-pointer flex justify-start items-center"
            >
              <span>
                {minutes === 0 && seconds === 0 ? (
                  <div
                    onClick={handleForgetPassword}
                    className="flex justify-around items-center cursor-pointer select-none"
                    style={{
                      visibility: seconds === 0 ? "none" : "visible",
                      color: seconds === 0 ? "#003CD8" : "#757575",
                    }}
                  >
                    <span className="mb-1">
                      <img src={ResendOTP} alt="otp icon" />
                    </span>
                    <span className="text-[#003CD8] mx-2 font-medium">
                      Resend OTP
                    </span>
                    <span className="text-black font-medium"> now</span>
                  </div>
                ) : (
                  <div
                    className="flex justify-around items-center cursor-auto"
                    style={{
                      visibility: seconds === 0 ? "none" : "visible",
                      color: seconds === 0 ? "#003CD8" : "#757575",
                    }}
                  >
                    <span className="mb-1">
                      <img src={ResendOTPGrey} alt="otp icon" />
                    </span>
                    <span className="text-[#757575] mx-2 select-none">
                      Resend OTP in
                    </span>
                    <span className="text-[#757575]">
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </div>
                )}
              </span>
            </span>
            <span
              className="cursor-pointer text-[#003CD8] font-medium"
              onClick={changeEmail}
            >
              Change Email
            </span>
          </span>
          <div className="flex">
            <SimpleButton
              onClick={() => {
                handleVerifyEmail();
              }}
              className="!rounded-[4px] w-full !px-6"
              label="Verify OTP"
              type="primary"
              size="large"
              disabled={!isOtpFilled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPwOtpVerify;
