import React, { useEffect, useState } from "react";
import VideoJSPrev from "./VideoJSPrev";
import idCreate from "../plEditor/utils/uuid";
import { Spin } from "antd";
import NotFound from "../saasWebsite/userModule/common/NotFound";
// import videoFile from "./assets/SampleVideo.mp4";
// import VideoPlayer from './VideoPlayer';

const Preview = () => {
  let [music,setMusic] = useState([]);
  let [audio,setAudio]  = useState([]);
  let [timeline,setTimeline]  = useState(false);
  let [autoplay,setAutoplay] =  useState(false);
  let [thumbnail,setThumbnail] = useState("");
  let [aspectvalue,setAspectvalue] = useState("");
  let [assetaddress,setAssetaddress] = useState("");
  let [videodata,setVideodata] = useState("");
  let [layerContent,setLayerContent] = useState([]);
  let [musicLoop,setMusicLoop] = useState(false);
  let [playbgmusic,setPlaybgmusic]  =  useState(false);
  let [musicData,setMusicData] = useState(music);
  let [audioData,setAudioData] = useState(audio);
  let [pauseData,setPauseData] = useState([]);
  let [currIndex,setCurrIndex] = useState(null);
  let [id,setId] = useState(idCreate());
  const [editorLoader,setEditorLoader] = useState(true);
  let [templateId,setTemplateId] = useState("");
  // Function is called everytime increment button is clicked

  
  useEffect(()=>{
 
    let previewData = localStorage.getItem("previewData");
    if(previewData ===  null){
      setEditorLoader(false);
    }
    else{
    previewData = JSON.parse(previewData);
    let layerData = previewData.layers;
    setLayerContent(layerData);
    setMusicData(previewData.music);
    setAudioData(previewData.audio);
    setTimeline(true);
    setAutoplay(true);
    setThumbnail(previewData.thumbnail);
    
    setAspectvalue(previewData.aspectvalue);
    setAssetaddress(previewData.assetaddress);
    setMusicLoop(previewData.musicLoop);
    setPlaybgmusic(previewData.playbgmusic);
    setVideodata(previewData.videodata);
    setTemplateId(previewData.templateId);
    let data  = [];
    layerData.forEach((layer)=>{
      // console.log(layer.pausetime);
      if(layer.pausetime!==undefined){
       if(Number.isInteger(layer.pausetime)){
         layer.pausetime =  layer.pausetime+0.1;
       }
        data.push(layer);
      }
    })
    data  =  data.filter((layer)=>{
      return layer.pausetime!==null;
    })
    data  = data.sort((a,b)=>{
      return a.pausetime-b.pausetime;
    });
    // layers =  layers.sort((a,b)=>{
    //   return a.pausetime-b.pausetime;
    // })
    
    setPauseData(data);
    if(data.length!==0){
      setCurrIndex(0);
    }
     console.log(data);
  }
  },[]);
  
  console.log(currIndex,pauseData);
  return (
    <>
    {editorLoader && (
        <>
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#000000",
              opacity: 1,
              zIndex: 1000,
              cursor: "wait",
            }}
          ></div>
          <span className="text-center top-1/2 left-1/2 absolute z-[10000000]">
            <Spin size="large" />
          </span>
        </>
      )}

    
    
    {<div
      style={{
        width : "100%",
        height : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position : "absolute",
        backgroundColor : "#181d1f",
      }}
    >
      {videodata!=="" && <VideoJSPrev
        timeline={timeline}
        autoplay = {autoplay}
        musicLoop = {musicLoop}
        playbgmusic = {playbgmusic}
        musicData = {musicData}
        audioData = {audioData}
        aspectvalue = {aspectvalue}
        assetaddress = {assetaddress}
        thumbnail = {thumbnail}
        videodata = {videodata}
        layerContent={layerContent}
        setLayerContent = {setLayerContent}
        pauseData = {pauseData}
        currIndex = {currIndex}
        setCurrIndex = {setCurrIndex}
        id = {id}
        setEditorLoader = {setEditorLoader}
        templateId = {templateId}
      />}
    </div>}
    </>
  );
}

export default Preview