import React, { useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { generatePreviewLink } from "../../../../api/api_updateData";

const DynamicFormLink = ({ variables, projectid, apikey, setDialog }) => {
  const [form] = Form.useForm();
  const [isFormFilled, setIsFormFilled] = useState(false); // State to track if all fields are filled
  const [filePreviews, setFilePreviews] = useState({}); // State to store file previews
  const [uploadedFileNames, setUploadedFileNames] = useState({}); // State to store file names wrt variables

  const transid = new Date().getTime();

  const onFinish = async (values) => {
    const finalValues = {
      projectid,
      transid,
      ...values,
      ...uploadedFileNames, // Merge uploaded file names directly into form values
    };
    console.log("Form values:", finalValues);

    const params = new URLSearchParams();
    Object.entries(finalValues).forEach(([key, value]) => {
      params.append(key, value);
    });
    const queryString = params.toString();
    console.log("Query string:", queryString);
    try {
      const response = await generatePreviewLink(queryString, apikey);
      if (response) {
        console.log("API response:", response);
        debugger
        toast.success(response.DESCRIPTION);
        setTimeout(function() {
          setDialog(false);
          window.location.reload();
      }, 1000);
      }
    } catch (error) {
      console.error("API call error CATCH:", error);
      message.error("API call failed CATCH.");
    }
  };

  const isButtonDisabled = variables !== "";

  const checkAllFieldsFilled = () => {
    const formValues = form.getFieldsValue(); // Get values of all form fields
    const allFieldsFilled = Object.values(formValues).every(
      (value) => value !== undefined && value !== ""
    ); // Check if all values are filled
    setIsFormFilled(allFieldsFilled); // Update isFormFilled state
  };

  const handleInputChange = () => {
    checkAllFieldsFilled();
  };

  const handleUploadChange = async (info, variable) => {
    if (info.file.status === "removed") {
      setFilePreviews((prev) => {
        const updatedPreviews = { ...prev };
        delete updatedPreviews[variable];
        return updatedPreviews;
      });
      setUploadedFileNames((prev) => {
        const updatedNames = { ...prev };
        delete updatedNames[variable];
        return updatedNames;
      });
      checkAllFieldsFilled();
      return;
    }

    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      const sessionUserId = localStorage.getItem("sessionUserId");
      const API_BASE_URL = window.baseurl;
      const fileType = variable.startsWith("vimg_") ? "image" : "video";
      const fileName = file.name;

      const reader = new FileReader();
      reader.onload = (e) => {
        setFilePreviews((prev) => ({ ...prev, [variable]: e.target.result }));
        checkAllFieldsFilled();
      };
      reader.readAsDataURL(file);

      // Upload the file to the server
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("sessionuserid", sessionUserId);
        formData.append("assetstype", fileType);
        formData.append("assetssize", file.size);
        formData.append("assetsname", fileName);
        formData.append("assetspublishflag", "true"); // You may set this based on your requirements

        const response = await axios.post(
          `${API_BASE_URL}/uploadtempassets`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("API response:", response.data);
        const responseData = response.data;
        if (responseData.STATUS === "1000") {
          toast.success(responseData.DESCRIPTION);
          const uploadData = responseData.DATA;
          const uploadedFileName = JSON.parse(uploadData)[0].FILENAME;
          setUploadedFileNames((prev) => ({
            ...prev,
            [variable]: uploadedFileName,
          }));
          checkAllFieldsFilled();
        }
      } catch (error) {
        message.error(`${info.file.name} file upload failed.`);
        console.error("API upload error:", error);
      }
    }
  };

  let regularInputFields = [];
  let imageUploadFields = [];
  let videoUploadFields = [];

  if (variables && typeof variables === "string") {
    const variableArray = variables.split(",").map((v) => v.trim());
    variableArray.forEach((variable) => {
      if (variable.startsWith("vimg_")) {
        imageUploadFields.push(
          <Form.Item key={variable} name={variable} label={variable}>
            <Upload
              accept=".jpg,.jpeg,.png"
              beforeUpload={() => false}
              onChange={(info) => handleUploadChange(info, variable)}
              maxCount={1}
              showUploadList={{ showRemoveIcon: true }}
            >
              <Button>Select Image</Button>
            </Upload>
            {filePreviews[variable] && (
              <img
                src={filePreviews[variable]}
                alt="preview"
                style={{ width: "100px", marginTop: "10px" }}
              />
            )}
          </Form.Item>
        );
      } else if (variable.startsWith("vvid_")) {
        videoUploadFields.push(
          <Form.Item key={variable} name={variable} label={variable}>
            <Upload
              accept=".mp4"
              beforeUpload={() => false}
              onChange={(info) => handleUploadChange(info, variable)}
              maxCount={1}
              showUploadList={{ showRemoveIcon: true }}
            >
              <Button>Select Video</Button>
            </Upload>
            {filePreviews[variable] && (
              <video
                src={filePreviews[variable]}
                controls
                style={{ width: "200px", marginTop: "10px" }}
              />
            )}
          </Form.Item>
        );
      } else {
        regularInputFields.push(
          <Form.Item key={variable} name={variable} label={variable}>
            <Input onChange={handleInputChange} />
          </Form.Item>
        );
      }
    });
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="grid grid-cols-2 gap-4"
      >
        {regularInputFields}
        {imageUploadFields}
        {videoUploadFields}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isFormFilled && isButtonDisabled}
            className={`${regularInputFields.length % 2 !== 0 ? "mt-[30px]" : ""}`}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DynamicFormLink;
