import React, { useState } from "react";
import { Modal } from "antd";
import "../../../styles/commonStyles.css";
import copy from "../../../../assests/commonIcons/copy.svg";
import downArrow from "../../../../assests/commonIcons/downArrow.svg";
import SimpleButtonIcon from "../../../components/SimpleButtonIcon";

const ApiDialog = ({ dialog, setDialog, apiLinkGenerated, templatename }) => {

  const [isCopied, setIsCopied] = useState(false);

  // Function to copy the API link to the clipboard
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = apiLinkGenerated;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setIsCopied(true);
  };

  const revertCopy = () => {
    setIsCopied(false);
  }

  return (
    <div>
      <Modal
        className="APIdoc-Modal"
        maskClosable={false}
        width={660}
        // closable={false}
        onCancel={() => {setDialog(false); revertCopy();}}
        centered
        open={dialog}
      >
        <div className="p-2">
          <div className="text-2xl font-bold text-[#242424]">
            Get API Link for the template
          </div>
          <p className="mt-2">
            Here is the API link for "
            <span className="font-semibold">{templatename}</span>"
            template. Download
            the document to learn more about the API.
          </p>
          <div className="flex w-full border-[1px] border-dashed border-[#D1D1D1] mt-3 items-center gap-2 px-2 h-9 rounded-sm bg-[#F1F1F1]">
            <span className="text-[#3A3A3A] w-[75px] text-sm font-semibold">API Link:</span>
            <span className="text-[#0755DB] text-sm font-normal w-full whitespace-nowrap overflow-hidden overflow-ellipsis select-none">
              {apiLinkGenerated}
            </span>
            {isCopied ? (
              <>
                <span className="text-blue-300">Copied.</span>
              </>
            ) : (
              <>
                <img
                  onClick={copyToClipboard}
                  className="cursor-pointer"
                  src={copy}
                  alt="copy"
                />
              </>
            )}
          </div>
          <div className="text-[#2C2C2C] mt-8 mb-2 font-semibold text-base">
            Download the API Document{" "}
          </div>
          <p className="mb-5">
            The documentation provides detailed information about how to use the
            API, what endpoints are available, what requests can be made, what
            data types are accepted, and what responses to expect. This helps
            developers to get familiar with the API and use it more effectively.
          </p>
          <SimpleButtonIcon
            // className="bg-[#1C67E9] mt-4 text-white flex rounded items-center p-3 gap-2"
            image={downArrow}
            type="primary"
            label="API Document"
            disabled
          ></SimpleButtonIcon>
        </div>
      </Modal>
    </div>
  );
};

export default ApiDialog;