import React, { useState } from "react";
import "../../Styling/DetailMenu.css";
import borderFocus from "../../assets/videofocus.svg";
import circlecross from "../../assets/CircleCancel.svg";
import corner1 from "../../assets/corner 1.svg";
import corner2 from "../../assets/corner 2.svg";
import corner3 from "../../assets/corner 3.svg";
import corner4 from "../../assets/corner 4.svg";
import Link from "../../assets/link.svg";
import Unlink from "../../assets/unlink.svg";
import PopTick from "../../assets/PopTick.svg";
import { Divider, InputNumber, Popover, Select, Slider } from "antd";
import { useEffect } from "react";
import { FlipOptions} from "../data.js";
function DetailMenu(props) {
  const drawArea = document.querySelector(".drawArea");
  const [openRadius, setOpenRadius] = useState(false);
  const [openFlip,setOpenFlip] = useState(false);
  
  function handleOpenFlip(){
    setOpenFlip(!openFlip);
  }
  useEffect(() => {
    // Attach event listener for keydown event
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  })

  function handleKeyPress(e) {
    function handleMotion(val,e){
      if(e.keyCode == '38'){
        //up
        e.preventDefault();
        if (
          props.itemSelected != null 
          // &&
          // parseFloat(props.ycord-val) >=
          //   0
        ) {
          props.setYcord(props.ycord-val);
        }
        
      }
      else if(e.keyCode == '40'){
        //down
        e.preventDefault();
        if (
          props.itemSelected != null 
          // &&
          // parseFloat(props.ycord+val) + parseFloat(props.height) <=
          //   parseInt(window.getComputedStyle(drawArea).height)
        ) {
          props.setYcord(props.ycord+val);
        }
        
      }
      else if(e.keyCode == '37'){
        //left
        e.preventDefault();
        if (
          props.itemSelected != null
          //  &&
          // parseFloat(props.xcord-val) >=0
        )
          props.setXcord(props.xcord-val);
        
      }
      else if(e.keyCode == '39'){
        //right
        e.preventDefault();
        if (
          props.itemSelected != null 
          // &&
          // parseFloat(props.xcord+val) + parseFloat(props.width) <=
          //   parseInt(window.getComputedStyle(drawArea).width)
        )
          props.setXcord(props.xcord+val);
        
      }
    }
    if ((e.ctrlKey || e.metaKey) && props.itemSelected!==null && !props.fieldEnabled.current)  {
      handleMotion(1,e);
    }
    else if((e.shiftKey) && props.itemSelected!==null && !props.fieldEnabled.current) {
      handleMotion(10,e);
    }
  }
  
  function handleFlipChange(value) {
    props.setFlip(value);
  }
  function calculateMaxBorder() {
    if (props.height > props.width) {
      return props.width / 2;
    } else {
      return props.height / 2;
    }
  }
  const handleOpenRadius = () => {
    setOpenRadius(!openRadius);
  };
  console.log("rr",props.resizeRatio);
  return (
    <div className="detail_menu pt-0 font-sans">
      <div className="px-4">
        <div className="font-medium mt-4 mb-3 text-left text-sm text-white">
          Component Styling
        </div>
        <div className="flex h-10 gap-5 mt-5">
          <div className="flex  gap-2 items-center">
            <div className="text-sm">Left(X)</div>
            <InputNumber
              className="editor-inum ml-[1px] !px-0  w-16 "
              disabled = {props.isModalOpen}
              readOnly = {!props.isRight}
              type="number"
              step={1}
              bordered={true}
              value={props.xcord}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3")
              }
              onClick={
                ()=>{
                  props.fieldEnabled.current = true;
                }
              }
              onStep={()=>{
                props.fieldEnabled.current = true;
              }}
              onBlur={() => {
                if (props.xcord == null) {
                  props.setXcord(0);
                }
                props.fieldEnabled.current = false;
              }}
              onChange={(value) => {
                if (value === null) {
                  props.setXcord(value);
                }
                if (
                  props.itemSelected != null
                  //  &&
                  // parseFloat(value) + parseFloat(props.width) <=
                  //   parseInt(window.getComputedStyle(drawArea).width)
                )
                  props.setXcord(value);
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-sm">Top(Y)</div>
            <InputNumber
              className="editor-inum !p-0  w-16 "
              disabled = {props.isModalOpen}
              readOnly = {!props.isRight}
              type="number"
              step={1}
              value={props.ycord}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3")
              }
              onClick={
                ()=>{
                  props.fieldEnabled.current = true;
                }
              }
              onStep={()=>{
                props.fieldEnabled.current = true;
              }}
              onBlur={() => {
                if (props.ycord === null) props.setYcord(0);
                props.fieldEnabled.current = false;
              }}
              onChange={(value) => {
                if (value === null) {
                  props.setYcord(value);
                }
                if (
                  props.itemSelected != null 
                  // &&
                  // parseFloat(value) + parseFloat(props.height) <=
                  //   parseInt(window.getComputedStyle(drawArea).height)
                ) {
                  props.setYcord(value);
                }
              }}
            />
          </div>
        </div>
        <div className="flex h-10 gap-5 mt-3 mb-3">
          <div className="flex gap-2 items-center">
            <div className="text-sm">Height</div>
            <InputNumber
              className="editor-inum !px-0  w-16 "
              disabled = {props.isModalOpen}
              readOnly = {!props.isRight}
              type="number"
              step={1}
              // min="10"
              value={props.height}
              onClick={
                ()=>{
                  props.fieldEnabled.current = true;
                }
              }
              onStep={()=>{
                props.fieldEnabled.current = true;
              }}
              onBlur={() => {
                if (props.height === null || props.height < 0) {
                  props.setHeight(20);
                  if(props.link){
                    props.setWidth(20*props.resizeRatio);
                  }
                }
                props.fieldEnabled.current = false;
              }}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3")
              }
              
              onChange={(value) => {
                if (props.itemSelected !== null) {
                  if (
                    props.canvaContent[props.idx].type === "checkbox" ||
                    props.canvaContent[props.idx].type === "radio"||
                    props.link
                  ) {
                    props.setWidth(value*props.resizeRatio);
                    // if(parseFloat(value*props.resizeRatio) + parseFloat(props.xcord) <=
                    //  parseInt(window.getComputedStyle(drawArea).width)) 
                     props.setHeight(value);
                  }
                  else{
                    props.setHeight(value);
                  }
                }
                // if (
                //   parseFloat(value) + parseFloat(props.ycord) >
                //   parseInt(window.getComputedStyle(drawArea).height)
                // ) {
                //   if (value > props.drawAreaHeight) {
                //     props.setYcord(0);
                //     props.setHeight(props.drawAreaHeight);
                //     return;
                //   }
                //   props.setYcord(
                //     parseInt(window.getComputedStyle(drawArea).height) -
                //       parseFloat(value)
                //   );
                // }
                // if (
                //   parseFloat(value*props.resizeRatio) + parseFloat(props.xcord) >
                //   parseInt(window.getComputedStyle(drawArea).width) && props.link
                // ) {
                //   if (value*props.resizeRatio > props.drawAreaWidth) {
                //     props.setXcord(0);
                //     props.setWidth(props.drawAreaWidth);
                //     return;
                //   }
                //   props.setXcord(
                //     parseInt(window.getComputedStyle(drawArea).width) -
                //       parseFloat(value*props.resizeRatio)
                //   );
                // }
                
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-sm">Width</div>
            <InputNumber
              className="editor-inum !px-0  w-16 "
              disabled = {props.isModalOpen}
              readOnly = {!props.isRight}
              type="number"
              // min="10"
              step={1}
              // max={props.drawAreaWidth}
              value={props.width}
              onClick={
                ()=>{
                  props.fieldEnabled.current = true;
                }
              }
              onStep={()=>{
                props.fieldEnabled.current = true;
              }}
              onBlur={() => {
                if (props.width === null || props.width < 0) {
                  props.setWidth(20);
                  if(props.link){
                    props.setHeight(20/props.resizeRatio);
                  }
                }
                props.fieldEnabled.current = false;
              }}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3")
              }
              onChange={(value) => {
                if (props.itemSelected !== null) {
                  if (
                    props.canvaContent[props.idx].type === "checkbox" ||
                    props.canvaContent[props.idx].type === "radio" ||
                    props.link
                  ) {
                    props.setHeight(value/props.resizeRatio);
                    // if(parseFloat(value/props.resizeRatio) + parseFloat(props.ycord) <=
                    // parseInt(window.getComputedStyle(drawArea).height)){
                      props.setWidth(value);
                    // }
                  }
                  else{
                    props.setWidth(value);
                  }
                }
                // if (
                //   parseFloat(value) + parseFloat(props.xcord) >
                //   parseInt(window.getComputedStyle(drawArea).width)
                // ) {
                //   if (value > props.drawAreaWidth) {
                //     props.setXcord(0);
                //     props.setWidth(props.drawAreaWidth);
                //     return;
                //   }
                //   props.setXcord(
                //     parseInt(window.getComputedStyle(drawArea).width) -
                //       parseFloat(value)
                //   );
                // }
                // if (
                //   parseFloat(value/props.resizeRatio) + parseFloat(props.ycord) >
                //   parseInt(window.getComputedStyle(drawArea).height) && props.link
                // ) {
                //   if (value/props.resizeRatio > props.drawAreaHeight) {
                //     props.setYcord(0);
                //     props.setHeight(props.drawAreaHeight);
                //     return;
                //   }
                //   props.setYcord(
                //     parseInt(window.getComputedStyle(drawArea).height) -
                //       parseFloat(value/props.resizeRatio)
                //   );
                // }
              }}
            />
            {props.type!=="checkbox" && props.type!=="radio" && <div
              className={`cursor-pointer`}
              onClick={() => {
                if(!props.link){
                  props.setResizeRatio(props.width/props.height);
                }
                else{
                  props.setResizeRatio(null);
                }
                props.setLink(!props.link);
              }}
            >
              <img src={!props.link ? Unlink : Link} alt="" />
            </div>}
          </div>
        </div>
        <div className="flex h-10 gap-5 mt-3 mb-3">
          <div className="flex gap-2 items-center">
            <div className="text-sm">Border</div>
            <InputNumber
              className="editor-inum !px-0  w-16 "
              disabled = {props.isModalOpen}
              readOnly = {!props.isRight}
              type="number"
              min="0"
              step={1}
              max={calculateMaxBorder()}
              value={props.border}
              onClick={
                ()=>{
                  props.fieldEnabled.current = true;
                }
              }
              onStep={()=>{
                props.fieldEnabled.current = true;
              }}
              onBlur={() => {
                if (props.border === null) {
                  props.setBorder(0);
                }
                props.fieldEnabled.current = false;
              }}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3")
              }
              onChange={(value) => {
                if (props.itemSelected != null) {
                  props.setBorder(value);
                }
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-sm">Radius</div>
            <InputNumber
              className="editor-inum !px-0  w-[60px] "
              disabled = {props.isModalOpen}
              readOnly = {!props.isRight}
              type="number"
              min="0"
              step={1}
              value={props.borderRadius}
              onClick={
                ()=>{
                  props.fieldEnabled.current = true;
                }
              }
              onStep={()=>{
                props.fieldEnabled.current = true;
              }}
              onBlur={() => {
                if (props.borderRadius === null) {
                  props.setBorderRadius(0);
                  props.setTLBorderRadius(0);
                  props.setTRBorderRadius(0);
                  props.setBLBorderRadius(0);
                  props.setBRBorderRadius(0);
                }
                props.fieldEnabled.current = false;
              }}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3")
              }
              onChange={(value) => {
                if (props.itemSelected != null) {
                  props.setBorderRadius(value);
                  props.setTLBorderRadius(value);
                  props.setTRBorderRadius(value);
                  props.setBLBorderRadius(value);
                  props.setBRBorderRadius(value);
                }
              }}
            />
            <div className="template-popover">
              <Popover
                placement="left"
                arrow={false}
                open={openRadius}
                onOpenChange={handleOpenRadius}
                content={
                  <div
                    className="bg-[#191919] w-[250px] h-[150px] absolute right-[270px] bottom-[-75px] overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="flex justify-between p-4 py-3 pr-3">
                      <div className="text-[#fff] font-semibold">
                        Corner Radius
                      </div>
                      <img
                        className="cursor-pointer"
                        src={circlecross}
                        alt="cross"
                        onClick={() => {
                          setOpenRadius(false);
                        }}
                      />
                    </div>
                    <Divider className="!m-0" />
                    <div className="flex w-[100%] justify-evenly align-middle mt-2">
                      <div className="flex ml-2 mt-2">
                        <div className="mt-2 mr-2">
                          <img src={corner1} alt="" />
                        </div>
                        <div className="ml-2">
                          <InputNumber
                            className="editor-inum !px-0  w-16 "
                            type="number"
                            min="0"
                            step={1}
                            readOnly = {!props.isRight}
                            value={props.tlborderRadius}
                            onClick={
                              ()=>{
                                props.fieldEnabled.current = true;
                              }
                            }
                            onStep={()=>{
                              props.fieldEnabled.current = true;
                            }}
                            onBlur={() => {
                              if (props.border === null) {
                                props.setTLBorderRadius(0);
                              }
                              props.fieldEnabled.current = false;
                            }}
                            formatter={(value) =>
                              `${value}`.replace(
                                /^(-)*(\d+)\.(\d).*$/,
                                "$1$2.$3"
                              )
                            }
                            onChange={(value) => {
                              if (props.itemSelected != null) {
                                props.setTLBorderRadius(value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex ml-2 mt-2">
                        <div className="mt-2 mr-2">
                          <img src={corner2} alt="" />
                        </div>
                        <div className="ml-2">
                          <InputNumber
                            className="editor-inum !px-0  w-16 "
                            type="number"
                            min="0"
                            step={1}
                            readOnly = {!props.isRight}
                            value={props.trborderRadius}
                            onClick={
                              ()=>{
                                props.fieldEnabled.current = true;
                              }
                            }
                            onStep={()=>{
                              props.fieldEnabled.current = true;
                            }}
                            onBlur={() => {
                              if (props.border === null) {
                                props.setTRBorderRadius(0);
                              }
                              props.fieldEnabled.current = false;
                            }}
                            formatter={(value) =>
                              `${value}`.replace(
                                /^(-)*(\d+)\.(\d).*$/,
                                "$1$2.$3"
                              )
                            }
                            onChange={(value) => {
                              if (props.itemSelected != null) {
                                props.setTRBorderRadius(value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex w-[100%] justify-evenly">
                      <div className="flex ml-2 mt-2">
                        <div className="mt-2 mr-2">
                          <img src={corner3} alt="" />
                        </div>
                        <div className="ml-2">
                          <InputNumber
                            className="editor-inum !px-0  w-16 "
                            type="number"
                            min="0"
                            step={1}
                            readOnly = {!props.isRight}
                            value={props.brborderRadius}
                            onClick={
                              ()=>{
                                props.fieldEnabled.current = true;
                              }
                            }
                            onStep={()=>{
                              props.fieldEnabled.current = true;
                            }}
                            onBlur={() => {
                              if (props.border === null) {
                                props.setBRBorderRadius(0);
                              }
                              props.fieldEnabled.current = false;
                            }}
                            formatter={(value) =>
                              `${value}`.replace(
                                /^(-)*(\d+)\.(\d).*$/,
                                "$1$2.$3"
                              )
                            }
                            onChange={(value) => {
                              if (props.itemSelected != null) {
                                props.setBRBorderRadius(value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex ml-2 mt-2">
                        <div className="mt-2 mr-2">
                          <img src={corner4} alt="" />
                        </div>
                        <div className="ml-2">
                          <InputNumber
                            className="editor-inum !px-0  w-16 "
                            type="number"
                            min="0"
                            step={1}
                            readOnly = {!props.isRight}
                            value={props.blborderRadius}
                            onClick={
                              ()=>{
                                props.fieldEnabled.current = true;
                              }
                            }
                            onStep={()=>{
                              props.fieldEnabled.current = true;
                            }}
                            onBlur={() => {
                              if (props.blborderRadius === null) {
                                props.setBLBorderRadius(0);
                              }
                              props.fieldEnabled.current = false;
                            }}
                            formatter={(value) =>
                              `${value}`.replace(
                                /^(-)*(\d+)\.(\d).*$/,
                                "$1$2.$3"
                              )
                            }
                            onChange={(value) => {
                              if (props.itemSelected != null) {
                                props.setBLBorderRadius(value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                trigger="click"
              >
                <img
                  src={borderFocus}
                  className="cursor-pointer"
                  onClick={() => {
                    if (
                      props.tlborderRadius === undefined ||
                      props.trborderRadius === undefined ||
                      props.blborderRadius === undefined ||
                      props.brborderRadius === undefined
                    ) {
                      props.setTLBorderRadius(props.borderRadius);
                      props.setTRBorderRadius(props.borderRadius);
                      props.setBLBorderRadius(props.borderRadius);
                      props.setBRBorderRadius(props.borderRadius);
                    }
                    setOpenRadius(!openRadius);
                  }}
                  alt=""
                />
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <>
        <Divider className="!m-0  !px-1" />
        <div className="px-4">
          <div className="text-start mt-5 font-medium text-sm">Orientation</div>
          <div className="w-full items-center justify-between">
            <h6 className="mr-2 pt-2 text-left text-[14px]">Rotation</h6>
            <div className="flex mt-2 ml-2 items-center justify-between h-6">
              <Slider
                className="w-[150px] m-0 mt-2 h-4"
                min={0}
                max={360}
                disabled = {!props.isRight}
                onChange={(value) => {
                  if (props.itemSelected != null) props.setRotate(value);
                }}
                value={typeof props.rotate === "number" ? props.rotate : 0}
              />
              {/* </Col>
              <Col span={4}> */}
              <InputNumber
                className="editor-inum !px-0  w-[60px] ml-[25px] "
                disabled = {props.isModalOpen}
                readOnly = {!props.isRight}
                min={0}
                max={360}
                value={Math.trunc(props.rotate)}
                onClick={
                  ()=>{
                    props.fieldEnabled.current = true;
                  }
                }
                onStep={()=>{
                  props.fieldEnabled.current = true;
                }}
                onBlur={() => {
                  if (props.rotate === null) {
                    props.setRotate(0);
                  }
                  props.fieldEnabled.current = false;
                }}
                onChange={(value) => {
                  if (props.itemSelected != null) {
                    props.setRotate(Math.trunc(value));
                  }
                }}
              />
              
            </div>
          </div>
          {props.type !=="hotspot" && <div className={`flex mt-2 ${props.flip==="N"?"mb-[60px]":props.flip==="H"?"mb-[40px]":"mb-2"} `}>
            <div className="mr-4 pt-1 text-[16px]">Flip</div>
            {/* <Select
              className="!w-30 action-select"
              defaultValue="None"
              dropdownClassName="action-select"
              style={{ width: 120 }}
              value={props.flip}
              onChange={handleFlipChange}
              options={[
                { value: "N", label: "None" },
                { value: "H", label: "Horizontal" },
                { value: "V", label: "Vertical" },
              ]}
            /> */}
            <Popover
                    placement="left"
                    arrow={false}
                    open={openFlip}
                    onOpenChange={handleOpenFlip}
                    content={
                      <div
                        className={`bg-[#1D1D1F] w-[100px] absolute ${props.flip==="N"?"top-[-20px]":props.flip==="H"?"top-[-50px]":"top-[-80px]"} ${props.flip==="N"?"right-[-88px]":"right-[-110px]"}`}
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        <div className=" overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2">
                        {
                          FlipOptions.map((item,index)=>{
                             return (
                              <div className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                               onClick={()=>{
                                props.setFlip(item.value);
                                handleOpenFlip();
                               }}
                              > 
                                <div className="px-2 w-[30px] h-[20px]">
                                  {props.flip === item.value && <img className="w-[100%] h-[100%]" src={PopTick} alt="P"/> }
                                </div>
                                <div className="text-[#ffffff] text-[14px] ">
                                  {item.label}
                                </div>
                              </div>
                             )
                          })
                        }
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div className="mx-4 cursor-pointer"
                    style={{
                      textAlign : "center",
                      height : "28px"
                    }}
                    >
                     <div className={`flex mt-[6px]`}>
                       <div className="ml-2 text-[14px]">
                       {props.flip==="N"?"None":props.flip==="H"?"Horizontal":"Vertical"}
                       </div>
                       <div className="mt-[4px] ml-4">
                       <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="10px" height="10px" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                       </div>
                     </div>
                    </div>
                  </Popover>
          </div>}
        </div>
      </>
      {/* </div> */}
    </div>
  );
}

export default DetailMenu;
