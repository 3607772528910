import { Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import SimpleButton from "../../../common/components/SimpleButton";
import congratsGif from "../../../assests/commonIcons/congratsGif.gif"
import "../../../common/styles/commonStyles.css"

const ConfirmModal = (props) => {
  let navigate = useNavigate();

  const goToLogin = () => {
    navigate(`${process.env.REACT_APP_BASE_PATH}/login`);
  };

  return (
    <>
      <Modal
        width={570}
        maskClosable={false}
        open={props.openModal}
        closeIcon={null}
        className="confirmModal"
      >
        <div className="flex flex-col justify-center p-8">
          <span className="flex justify-center text-center">
            <img height={200} src={congratsGif} alt="congrats gif"/>
          </span>
          <span className="text-xl text-[#1F1F1F] font-medium text-center mt-3">
            Wohoo! Account successfully created.
          </span>
          <span className="text-base text-[#757575] font-normal text-center mt-2">
            Now, just log in with your registered credentials to start the
            VSPAGY journey.
          </span>
          <div className="flex justify-center mt-4">
            <SimpleButton
              onClick={() => goToLogin()}
              className="!rounded-[4px] w-fit !px-6"
              label="Login Now"
              type="primary"
              size="large"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
