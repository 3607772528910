import React, { useState } from "react";
import SidebarData from "./data";
import { NavLink, useNavigate } from "react-router-dom";
import { Drawer, Menu } from "antd";
import "../common/styles/commonStyles.css";
import clevertap from "clevertap-web-sdk";
import useWindowWidth from "../common/hooks/useWindowWidth";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Sidebar = ({ custregid, mobSidebarOpen, setMobSidebarOpen }) => {
  const navigate = useNavigate();
  custregid = localStorage.getItem("sessionUserId");

  const [selectedItem, setSelectedItem] = useState(null); // Track the selected item
  // console.log(selectedItem, "SELECTED ITEM");

  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  const items = [
    getItem(
      "Analytics",
      "1",
      <div className="ml-[-4px] w-[25px] h-[25px]">
        {React.cloneElement(SidebarData[5].payload, {
          fill: selectedItem ? "#1560E1" : "#3C3C3C",
          // stroke: selectedItem ? "#1560E1" : "#3C3C3C"
        })}
      </div>,

      [
        getItem(
          "Play Report",
          `${process.env.REACT_APP_BASE_PATH}/play-report`
        ),
        getItem(
          "Heatmap Report",
          `${process.env.REACT_APP_BASE_PATH}/heatmap-report`
        ),
        getItem(
          "Data Report",
          `${process.env.REACT_APP_BASE_PATH}/data-report`
        ),
        getItem(
          "Credit Report",
          `${process.env.REACT_APP_BASE_PATH}/credit-report`
        ),
      ]
    ),
  ];

  const userAgent = navigator.userAgent;

  const Sidebar_Item_Clicked = (item) => {
    if (item.id <= 6 && item.key === undefined) {
      const selectedTitle = item.title;
      const itemClick = `${selectedTitle}_Clicked`;
      clevertap.event.push(`${selectedTitle}_Clicked`, {
        USER_ID: custregid,
        Email: custregid,
        [itemClick]: "YES",
        timestamp: new Date().getTime(),
        user_agent: userAgent,
      });
      console.log(`${selectedTitle}_Clicked EVENT CLICKED`);
    } else if (item.id === undefined) {
      const selectedTitle = item.key;
      const substringToRemove = "/user/";
      const modifiedSubItem = selectedTitle.replace(substringToRemove, "");
      const analyticsKey = `Analytics_${modifiedSubItem}_Clicked`;
      clevertap.event.push(
        `${capitalizeFirstLetter(modifiedSubItem)}_Clicked`,
        {
          USER_ID: custregid,
          Email: custregid,
          [analyticsKey]: "YES",
          timestamp: new Date().getTime(),
          user_agent: userAgent,
        }
      );
      console.log(
        `${capitalizeFirstLetter(modifiedSubItem)}_Clicked EVENT CLICKED`
      );
    }
  };

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // const removeSuffix = (subItem) => {
  //   const substringToRemove = "/user/";
  //   return subItem.replace(substringToRemove, "");
  // }

  const onItemClick = (item) => {
    setSelectedItem(item.key);
    navigate(item.key);
    Sidebar_Item_Clicked(item);
    onClose();
  };

  const onClose = () => {
    if (setMobSidebarOpen) {
      setMobSidebarOpen(false);
    }
  };

  return (
    <>
      {/* Desktop version */}
      {isDesktop && (
        <div className="bg-[#FFFFFF] h-[calc(100vh-60px)]">
          <div className="w-[195px] h-full overflow-auto pt-5 scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]">
            <div>
              <ul className="list-none  pl-0">
                {SidebarData.map((item, index) => {
                  if (item.id !== 6) {
                    return (
                      <li
                        className={`text-sm  m-1 rounded-lg  text-center  hover:!bg-[#E6F0F6] ${
                          selectedItem === item.path
                            ? `hover:!bg-[#E6F0F6] bg-[#E6F0F6]`
                            : ""
                        }`}
                        key={index}
                      >
                        <NavLink
                          to={item.path}
                          activeClassName="active-link"
                          activeclassname="active-link"
                          onClick={() => onItemClick(item)}
                        >
                          {({ isActive }) => (
                            <div
                              className={`flex py-3 items-center ${
                                isActive ? "active-link" : ""
                              }`}
                            >
                              <div className="ml-[17px] mr-[12px]">
                                <div className="w-[25px] h-6">
                                  {React.cloneElement(item.payload, {
                                    fill: isActive ? "#1560E1" : "#3C3C3C",
                                  })}
                                </div>
                              </div>
                              <div
                                className={`text-[14px] pt-[2px] ${
                                  isActive
                                    ? "text-[#1560E1] font-medium"
                                    : "text-[#3C3C3C]"
                                }`}
                              >
                                {item.title}
                              </div>
                            </div>
                          )}
                        </NavLink>
                      </li>
                    );
                  }
                  if (item.id === 6) {
                    return (
                      <li className="text-medium  mb-6" key={index}>
                        <Menu
                          className="analytic"
                          onClick={({ key }) => onItemClick({ key })}
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            width: "194px",
                          }}
                          mode="inline"
                          items={items}
                          selectedKeys={selectedItem ? [selectedItem] : []}
                        />
                      </li>
                    );
                  }
                  return 0;
                })}
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* Mobile version */}
      {isMobile && (
        <>
          <Drawer
            onClose={onClose}
            open={mobSidebarOpen}
            placement="left"
            width={220}
            rootClassName="msidebarDrawer top-[60px]"
            // mask={false}
            // maskClosable={true}
          >
            <div>
              <ul className="list-none  pl-0">
                {SidebarData.map((item, index) => {
                  if (item.id !== 6) {
                    return (
                      <li
                        className={`text-sm  m-1 rounded-lg  text-center  hover:!bg-[#E6F0F6] ${
                          selectedItem === item.path
                            ? `hover:!bg-[#E6F0F6] bg-[#E6F0F6]`
                            : ""
                        }`}
                        key={index}
                      >
                        <NavLink
                          to={item.path}
                          activeClassName="active-link"
                          activeclassname="active-link"
                          onClick={() => onItemClick(item)}
                        >
                          {({ isActive }) => (
                            <div
                              className={`flex py-3 items-center ${
                                isActive ? "active-link" : ""
                              }`}
                            >
                              <div className="ml-[17px] mr-[12px]">
                                <div className="w-[25px] h-6">
                                  {React.cloneElement(item.payload, {
                                    fill: isActive ? "#1560E1" : "#3C3C3C",
                                  })}
                                </div>
                              </div>
                              <div
                                className={`text-[14px] pt-[2px] ${
                                  isActive
                                    ? "text-[#1560E1] font-medium"
                                    : "text-[#3C3C3C]"
                                }`}
                              >
                                {item.title}
                              </div>
                            </div>
                          )}
                        </NavLink>
                      </li>
                    );
                  }
                  if (item.id === 6) {
                    return (
                      <li className="text-medium  mb-6" key={index}>
                        <Menu
                          className="analytic"
                          onClick={({ key }) => onItemClick({ key })} 
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            width: "220px",
                          }}
                          mode="inline"
                          items={items}
                          selectedKeys={selectedItem ? [selectedItem] : []}
                        />
                      </li>
                    );
                  }
                  return 0;
                })}
              </ul>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
};

export default Sidebar;
