import React from 'react'
import Navbar from '../Navbar'
import { Outlet } from 'react-router-dom'
import Sidebar from "../Sidebar";
import NewTemplate from '../../common/components/NewTemplate';

const Layout = () => {

  return (
      <div>
        <Navbar />
        <div className='bg-[#F7FAFC]'>
            <div className='flex'>
              <Sidebar />
              <Outlet newTemplate={<NewTemplate />} />
            </div>
        </div>
      </div>
  );
};

export default Layout