import React, { useState } from "react";
import { Modal, Input, Spin, Tooltip } from "antd";
// import coinIcon from "../assests/commonImages/coins-icon.svg";
import coinIcon from "../../assests/commonImages/coins-icon.svg";
import SimpleButton from "./SimpleButton";
import "../styles/commonStyles.css";
import TextArea from "antd/es/input/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { copyTemplate, editTemplateApi } from "../../api/api_updateData";
import { getAllUserTemplate } from "../../api/api_FetchData";
import clevertap from "clevertap-web-sdk";

const ProjectModal = ({
  modalVisible,
  setModalVisible,
  title,
  id,
  bgColor,
  borderColor,
  credits,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userAgent = navigator.userAgent;
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const { templateid } = location.state;
  const [isNameTooltipVisible, setIsNameTooltipVisible] = useState(false);
  const [isEventSuccess, setIsEventSuccess] = useState(null);

  // console.log(id, "ID");

  const sessionuserid = localStorage.getItem("sessionUserId");

  const handleTemplateNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100 && /^[A-Za-z0-9- ]*$/.test(value)) {
      setTemplateName(value);
      setIsNameTooltipVisible(value.length < 4);
    }
  };

  const handleTemplateNameClick = () => {
    setIsNameTooltipVisible(templateName.length < 4);
  };

  const handleTemplateNameBlur = () => {
    setIsNameTooltipVisible(false);
  };

  const handleTemplateDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 500 && /^[A-Za-z0-9- \s]*$/.test(value)) {
      setTemplateDescription(value);
    }
  };

  const queryClient = useQueryClient();

  const { data: userTemplates } = useQuery(
    "userTemplates",
    () => getAllUserTemplate(sessionuserid, null, "/template"),
    { staleTime: 10 * 60 * 1000 }
  );

  // console.log(userTemplates, "USER T DATA");

  const [showSpinner, setShowSpinner] = useState(false);

  const goToUrl = (ptid) => {
    if (id === "1") {
      setTimeout(() => {
        setShowSpinner(false); // Hide loading spinner
        navigate(`${process.env.REACT_APP_BASE_PATH}/go-to-project/${ptid}`, {
          state: {
            ptid: ptid,
          },
        });
      }, 2000);
    } else if (id === "2") {
      setTimeout(() => {
        setShowSpinner(false); // Hide loading spinner
        navigate(
          `${process.env.REACT_APP_BASE_PATH}/user-template`
          // , {
          //   state: {
          //     id: id, // Pass the id as state to UserTemplateLayout
          //   }}
        );
      }, 2000);
    } else if (id === "3") {
      setTimeout(() => {
        setShowSpinner(false); // Hide loading spinner
        // debugger
        // navigate(`${process.env.REACT_APP_BASE_PATH}/dashboard`);
        customizeTemplate(ptid);
      }, 2000);
    }
  };

  // MUTATION FOR EDIT TEMPLATE
  const { mutate: editTemplateMutation } = useMutation(
    (customizeTemplateData) => editTemplateApi(customizeTemplateData),
    {
      onSuccess: (data) => {
        // debugger;
        const parsedData = JSON.parse(data.DATA);
        if (data.STATUS === "1000") {
          // console.log(data, "DATA")
          toast.success(data.DESCRIPTION, {
            theme: "dark",
          });
          console.log(data);
          // Add SESSIONUSERID to each object in the DATA array
          const newDataArray = parsedData.map((item, index) => ({
            ...item,
            SESSIONUSERID: sessionuserid,
          }));

          /// Create a new object with the updated DATA property
          const updateTemplateData = {
            ...data,
            DATA: newDataArray,
          };
          console.log(updateTemplateData, "UPDATE");
          // Store the updated data in localStorage
          localStorage.setItem(
            "updateTemplateData",
            JSON.stringify(updateTemplateData.DATA)
          );

          const storedData = localStorage.getItem("updateTemplateData");
          const parsedTemplateData = JSON.parse(storedData);

          console.log(parsedTemplateData, "Parsed Template Data");

          // Log the updated data
          console.log(updateTemplateData, "Updated Data");
          navigate(`${process.env.REACT_APP_BASE_PATH}/editor/updateTemplate`);
        } else {
          toast.error(data.DESCRIPTION, {
            theme: "dark",
          });
        }
      },
    }
  );

  const customizeTemplate = (ptid) => {
    // debugger;
    const customizeTemplateData = {
      templateid: ptid,
      sessionuserid: sessionuserid,
    };
    console.log(customizeTemplateData, "E T DATA");
    editTemplateMutation(customizeTemplateData);
  };

  // ************************** CUTOMIZE TEMPLATE ENDS ****************************

  //******************************* CREATE PROJECT STARTS ******************************************
  const { mutate: copyTemplateMutation } = useMutation(
    (cloneTemplateData) => copyTemplate(cloneTemplateData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          const parsedData = JSON.parse(data.DATA);
          toast.success(data.DESCRIPTION, {
            theme: "dark",
          });
          const ptid = parsedData[0].TEMPLATEID; // Store sessionId in localStorage
          console.log(ptid, "NEW T ID");
          queryClient.invalidateQueries("userTemplates");
          console.log(userTemplates, "USER T DATA");
          setShowSpinner(true); // Show loading spinner
          goToUrl(ptid);
          setIsEventSuccess("YES");
          setModalVisible(false);
          console.log(ptid, "NNNNN");
        } else {
          toast.error(data.DESCRIPTION, {
            theme: "dark",
          });
          setIsEventSuccess("NO");
          setShowSpinner(false);
        }
      },
    }
  );

  const createProject = () => {
    // Remove newline characters from templateDescription
    const sanitizedDescription = templateDescription.replace(/\n/g, " ");
    setShowSpinner(true);
    const projectData = {
      templateid: templateid,
      sessionuserid: sessionuserid,
      templatename: templateName,
      templatedesc: sanitizedDescription,
    };
    console.log(projectData, "P DATA");
    copyTemplateMutation(projectData);
  };
  if (showSpinner) {
    return (
      <>
        <div
          className="overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0, 0, 0,0.5)",
            opacity: 1,
            zIndex: 1000,
            cursor: "wait",
          }}
        ></div>
        <span className="text-center top-1/2 left-1/2 absolute z-[10000000]">
          <Spin size="large" />
        </span>
      </>
    );
  }

  const StartProjectClicked = () => {
    clevertap.event.push("Start_Project_Clicked", {
      USER_ID: sessionuserid,
      Email: sessionuserid,
      TemplateName: templateName,
      TemplateID: templateid,
      // TemplateType:
      // Template:
      // CategoryName
      Timestamp: new Date().getTime(),
      // Gelolocation
      User_Agent: userAgent,
      success: isEventSuccess === "YES" ? "YES" : "NO",
      // Error_Message
      CreditsDeducted: credits === 0 ? "NO" : "YES",
      CreditsDeductedValue: credits,
    });

    console.log("Start_Project_Clicked EVENT CLICKED");
  };

  const GetAPIClicked = () => {
    clevertap.event.push("Get_API_Clicked", {
      User_ID: sessionuserid,
      Email: sessionuserid,
      TemplateName: templateName,
      TemplateID: templateid,
      // TemplateType
      // Template
      // CategoryName
      Timestamp: new Date().getTime(),
      // Gelolocation
      User_Agent: userAgent,
      // Error_Message
    });

    console.log("Get_API_Clicked EVENT CLICKED");
  };

  const StartCustomizationClicked = () => {
    clevertap.event.push("Start_Customization_Clicked", {
      User_ID: sessionuserid,
      Email: sessionuserid,
      TemplateName: templateName,
      TemplateID: templateid,
      // TemplateType
      // Template
      // CategoryName
      Timestamp: new Date().getTime(),
      // Gelolocation
      User_Agent: userAgent,
      // Error_Message
    });

    console.log("Start_Customization_Clicked EVENT CLICKED");
  };

  return (
    <>
      {showSpinner && (
        <>
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              cursor: "wait",
            }}
          ></div>
          <span className="text-center top-1/2 left-1/2 absolute z-[10000000]">
            <Spin size="large" />
          </span>
        </>
      )}
      <span>
        <Modal
          className="project-modal flex"
          title={title}
          centered
          open={modalVisible}
          //   onOk={() => setModalVisible(false)} // Close the modal
          onCancel={() => {
            setModalVisible(false);
            setTemplateName("");
            setTemplateDescription("");
          }} // Close the modal
        >
          <div className="flex flex-col">
            <span className="text-[#5E5C5C] font-Inter text-sm font-normal">
              You can always find the saved templates in{" "}
              <span className="text-[#4A4A4A] font-medium">“My Templates”</span>
              .
            </span>
            <span
              className="flex py-3 mt-2  rounded border-dashed border-2 p-4"
              style={{ backgroundColor: bgColor, borderColor: borderColor }}
            >
              <span>
                <img src={coinIcon} alt="coin-icon" />
              </span>
              <span className="text-[#000] font-Inter text-sm font-normal">
                <span className="ml-2 font-semibold">{credits} credits</span>{" "}
                will be deducted from your balance.
              </span>
            </span>
            <div className="pt-4">
              <label className="text-[#2F2F2F] font-Inter text-sm font-medium ">
                Template Name
              </label>
              <Tooltip
                open={isNameTooltipVisible} // Show tooltip only when the length is less than 4
                title="Minimum 4 characters required"
                placement="topRight"
              >
                <Input
                  className="mt-2"
                  type="text"
                  value={templateName}
                  onClick={handleTemplateNameClick}
                  onChange={handleTemplateNameChange}
                  onBlur={handleTemplateNameBlur}
                />
              </Tooltip>
            </div>
            <div className="py-4">
              <span className="flex justify-between items-center">
                <label className="text-[#2F2F2F] font-Inter text-sm font-medium ">
                  Template Description
                </label>
                <span className="font-normal text-[#707070] text-[11px]">
                  500 Characters
                </span>
              </span>
              <TextArea
                rows={4}
                style={{ height: 44, resize: "none" }}
                value={templateDescription}
                onChange={handleTemplateDescriptionChange}
                className="mt-2 h-2 rounded-[4px] scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full
                scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
              />
            </div>
            <span className="flex justify-end project-button">
              <SimpleButton
                key="cancel"
                type="primary"
                onClick={() => {
                  createProject();
                  if (title === "Start Project") {
                    StartProjectClicked();
                  } else if (title === "Get API") {
                    GetAPIClicked();
                  } else if (title === "Customize Template") {
                    StartCustomizationClicked();
                  }
                }}
                label="Save Template"
                className="items-end"
                disabled={
                  templateName.length < 4 || templateDescription.length < 4
                }
              />
            </span>
          </div>
        </Modal>
      </span>
    </>
  );
};

export default ProjectModal;
