import React from "react";
import PieChart from "../../common/components/chart/PieChart";
import { Tooltip } from "antd";

const PieDataChart = ({
  pieData,
  title,
  customColors,
  labelColor,
  totalOpenLinks,
}) => {
  console.log(pieData, "PIE DATA");
  // debugger

  if (!title || !pieData) {
    return null; // or some default UI when title or pieData is not available
  }

  const convertedData = pieData?.map((item) => {
    // debugger
    const percentage = ((item.total / totalOpenLinks) * 100).toFixed(2);
    return {
      id: item[title] || item.MODAL,
      label: item[title] || item.MODAL,
      value: percentage,
    };
  });

  console.log(convertedData.length, "CON DATA");

  return (
    <>
      <div className="rounded-5 shadow-md border border-solid border-[#E6E6E6] px-4 py-2 mt-8">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-3/5 w-full">
            <div className="text-base font-medium text-[#151515] capitalize">
              Top 5 <span className="lowercase">{title}</span>
            </div>
            <div className="text-neutral-500 text-[13px] font-normal mt-1">
              Here are your top 5 {title}
            </div>
            <div className="mt-9 flex flex-wrap">
              {/* {convertedData.map((data, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/5 mt-8 md:mt-0 flex flex-col items-start"
                >
                  <Tooltip title={data.label}>
                    <div
                      style={{ backgroundColor: `${customColors[index]}` }}
                      className={`w-6 h-6 rounded-[1px]`}
                    />
                    <div className="text-[#5A5A5A] text-[15px] font-normal mt-3 mb-1 whitespace-nowrap overflow-hidden text-ellipsis w-[6rem]">
                      {data.label}
                    </div>
                  
                  <div className="text-black text-[22px] font-semibold">
                    {data.value}%
                  </div>
                  </Tooltip>
                </div>
              ))} */}
              {convertedData.length === 0 ? (
                // <div className="text-center text-red-500">No data found</div>
                <></>
              ) : (
                convertedData.map((data, index) => (
                  <div
                    key={index}
                    className="w-full md:w-1/5 mt-8 md:mt-0 flex flex-col items-start"
                  >
                    <Tooltip title={data.label}>
                      <div
                        style={{ backgroundColor: `${customColors[index]}` }}
                        className={`w-6 h-6 rounded-[1px]`}
                      />
                      <div className="text-[#5A5A5A] text-[15px] font-normal mt-3 mb-1 whitespace-nowrap overflow-hidden text-ellipsis w-[6rem]">
                        {data.label}
                      </div>
                      <div className="text-black text-[22px] font-semibold">
                        {data.value}%
                      </div>
                    </Tooltip>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/5 px-1 py-2">
            <PieChart
              data={convertedData}
              colors={customColors}
              labelColor={labelColor}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PieDataChart;
