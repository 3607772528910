import React, { useState, useEffect } from "react";

// LIBRARIES IMPORTED
import OtpInput from "otp-input-react";
import clevertap from "clevertap-web-sdk";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

// API IMPORTED
import { validateEmailOtp } from "../../api/api_updateData";

// COMPONENTS AND ASSETS IMPORTED
import SimpleButton from "../../common/components/SimpleButton";
import ConfirmModal from "../common/modals/ConfirmModal";
import ResendOTPGrey from "../../assests/commonIcons/userModuleIcons/ResendOTPGrey.svg";
import ResendOTP from "../../assests/commonIcons/userModuleIcons/ResendOTP.svg";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";

const EmailOtpVerify = (props) => {
  const [otp, setOtp] = useState("");
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const userAgent = navigator.userAgent;
  const location = navigator.geolocation;

  const Signup_Completed = () => {
    clevertap.event.push("Signup_Completed", {
      USER_ID: props.email,
      Name: props.username,
      Email: props.email,
      Email_Verified: "YES",
      Signup_Method: "Email_Password",
      Email_OTP_Entered: "YES",
      Valid_OTP_Entered: "YES",
      Status: "Success",
      timestamp: new Date().getTime(),
      user_agent: userAgent,
      location: location,
      utm: props.utmParam || "NA",
    });
    console.log("Signup_Completed EVENT CLICKED");
  };

  const Signup_Failed = () => {
    clevertap.event.push("Signup_Failed", {
      USER_ID: props.email,
      Name: props.username,
      Email: props.email,
      Email_Verified: "YES",
      Signup_Method: "Email_Password",
      Email_OTP_Entered: "YES",
      Valid_OTP_Entered: "YES",
      Status: "Success",
      timestamp: new Date().getTime(),
      user_agent: userAgent,
      location: location,
    });
    console.log("Signup_Failed EVENT CLICKED");
  };

  //******************************* CREATE CUSOTMER STARTS ******************************************
  const { mutate: createValidateEmailOtpMutation } = useMutation(
    (emailVerifyData) => validateEmailOtp(emailVerifyData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          Signup_Completed();
          toast.success(data.DESCRIPTION);
          setOpenConfirm(true);
        } else {
          Signup_Failed();
          toast.error(data.DESCRIPTION);
          setOpenConfirm(false);
        }
      },
    }
  );

  //  *************************** CREATE CUSOTMER ENDS ******************************************

  const handleVerifyEmail = () => {
    const emailVerifyData = {
      custregid: props.email,
      emailotp: otp,
    };
    createValidateEmailOtpMutation(emailVerifyData);
    // console.log(emailVerifyData, "CUST DATA");
  };

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const resendOTP = () => {
    // setMinutes(1);
    setSeconds(30);
    props.handleAddCust();
  };

  const changeEmail = () => {
    props.setIsRegistered(false);
  };

  // Use useEffect to check whether all OTP inputs are filled whenever `otp` changes
  useEffect(() => {
    setIsOtpFilled(otp.length === 6);
  }, [otp]);

  return (
    <div>
      <div className="form-wrapper email-verify otp-section">
        <div className="flex flex-col text-center">
          <div className="w-[200px] ml-auto mr-auto">
            <img src={newVspagyLogo} alt="vspagyLogo" className="w-full mb-4" />
          </div>
          <span className="font-semibold text-[#151515] my-2 text-[1.5rem] select-none">
            Verify Your Email
          </span>
          <span className="text-base font-normal text-[#868686] select-none">
            We've sent an email with the OTP for confirmation,{" "}
            <span className="text-[#464646] font-medium">{props.email}</span>.
            Check your spam or junk folder if you did not receive the email.
          </span>
          <span className="my-8">
            <OtpInput
              value={otp}
              onChange={setOtp}
              OTPLength={6}
              otpType="number"
              disabled={false}
              autoFocus
              className="opt-container-email"
            ></OtpInput>
          </span>
          <span className="flex justify-between items-center mb-6">
            <span
              onClick={resendOTP}
              size="large"
              disabled={seconds === 0 ? false : true}
              className=" text-black border-none cursor-pointer flex justify-start items-center"
            >
              <span>
                {minutes === 0 && seconds === 0 ? (
                  <div
                    className="flex justify-around items-center"
                    style={{
                      visibility: seconds === 0 ? "none" : "visible",
                      color: seconds === 0 ? "#003CD8" : "#757575",
                    }}
                  >
                    <span className="mb-1">
                      <img src={ResendOTP} alt="otp icon" />
                    </span>
                    <span className="text-[#003CD8] mx-2 font-medium">
                      Resend OTP
                    </span>
                    <span className="text-black font-medium"> now</span>
                  </div>
                ) : (
                  <div
                    className="flex justify-around items-center"
                    style={{
                      visibility: seconds === 0 ? "none" : "visible",
                      color: seconds === 0 ? "#003CD8" : "#757575",
                    }}
                  >
                    <span className="mb-1">
                      <img src={ResendOTPGrey} alt="otp icon" />
                    </span>
                    <span className="text-[#757575] mx-2">Resend OTP in</span>
                    <span className="text-[#757575]">
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </div>
                )}
              </span>
            </span>
            <span
              className="cursor-pointer text-[#003CD8] font-medium"
              onClick={changeEmail}
            >
              Change Email
            </span>
          </span>
          <div className="flex">
            <SimpleButton
              onClick={() => {
                handleVerifyEmail();
                // setOpenConfirm(true);
              }}
              className="!rounded-[4px] w-full !px-6"
              label="Verify OTP"
              type="primary"
              size="large"
              disabled={!isOtpFilled}
            />
          </div>
        </div>
      </div>
      {props.sendRoute ? (
        <ConfirmModal
          openModal={openConfirm}
          setOpenModal={setOpenConfirm}
          route={props.route}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmailOtpVerify;
