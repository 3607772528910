import React, { useEffect, useState } from "react";
import check from "../../assests/commonIcons/ongoingCheck.svg";
import SimpleButton from "../../common/components/SimpleButton";
import "../../common/styles/commonStyles.css";
import { Switch } from "antd";
import { useQuery } from "react-query";
import { getPlanDetails } from "../../api/api_FetchData";
import "../../common/styles/userModule/SubscriptionStyles.css";
import GhostButton from "../../common/components/GhostButton";

const Subscription = ({ custregid }) => {

  // const queryClient = useQueryClient();

  const [planInfo, setPlanInfo] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  // const [planInfo, setPlanInfo] = useState("");

  const { refetch, status } = useQuery(
    ["planinfo", custregid],
    () => getPlanDetails(null, custregid, "/planinfo"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (fetchedPlanData) => {
        setPlanInfo(fetchedPlanData);
      },
    }
  );
  console.log(planInfo, "PLANINFO");
  console.log(status, "STATUS");
  // debugger;

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    // Call the refetch function when the component mount
    refetch();
  }, [refetch]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  const goToBuyPlan = () => {
    const buyPlanElement = document.getElementById("buy-plan");

    if (buyPlanElement) {
      buyPlanElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (status === "success" && planInfo.status === "1000") {
    return (
      <div className="mb-10">
        <span className="text-sm font-medium">YOUR ONGOIGN PLAN</span>
        {planInfo.data.map((plan, index) => (
          <>
            {plan.PLANPURCHASED === "TRUE" ? (
              <>
                <div
                  key={index}
                  className="rounded-[10px] w-[700px] mt-4 max-h-[260px] p-6 border-[1px] border-solid border-[#F2E4E4] bg-gradient-to-l from-[#FBEBEB] to-[#FFFFFF] mb-16"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="font-semibold text-[20px]">
                        {plan.PLANNAME}
                      </span>
                      <span className="text-[15px] text-black mb-6 mt-2">
                        You received{" "}
                        <span className="font-semibold">
                          {plan.PLANCREDITS} Credits
                        </span>{" "}
                        in the <span className="">{plan.PLANNAME}</span>
                      </span>
                      <div className="grid grid-cols-2 gap-3">
                        {plan.PLANFEATURE?.split(",")
                          ?.map((item) => item.trim())
                          ?.map((feature, index) => (
                            <span key={index} className="">
                              <span className="flex items-center">
                                <img
                                  src={check}
                                  alt="check icon"
                                  height={20}
                                  width={20}
                                  style={{ marginRight: "3px" }}
                                />
                                <span className="text-sm text-[#3C3C3C]">
                                  {feature}
                                </span>
                              </span>
                            </span>
                          ))}
                      </div>
                      <span></span>
                    </div>
                    <div class="min-h-[132px] grid grid-cols-1 gap-4 content-between text-right">
                      <div className="text-[28px] font-semibold">
                        <span>$</span>
                        <span>
                          {isSwitchOn
                            ? plan.ANNUALAMOUNTDISPLAY
                            : plan.MONTHLYAMOUNTDISPLAY}
                        </span>
                      </div>
                      <div className="">
                        <SimpleButton
                          type="primary"
                          className="bg-[#1C68EB] hover:bg-gradient-to-r hover:to-[#0943A6] hover:from-[#1C68EB] rounded-3xl w-fit !px-4"
                          label="Upgrade Plan"
                          onClick={goToBuyPlan}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ))}
        <div className="my-4 flex justify-between items-center">
          <span className="text-sm font-medium">
            CHOOSE ANY PLAN TO UPGRADE
          </span>
          <div className="price-swicth-div" id="buy-plan">
            <span>
              {isSwitchOn ? (
                <>
                  <span>Monthly</span>
                </>
              ) : (
                <>
                  <span className="font-bold">Monthly</span>
                </>
              )}
            </span>
            <span className="price-switch-span">
            <Switch
              checked={isSwitchOn}
              onChange={handleSwitchChange}
              defaultChecked={false}
              className="mx-2"
            />
            </span>
            <span>
              {isSwitchOn ? (
                <>
                  <span className="font-bold">Yearly</span>
                </>
              ) : (
                <>
                  <span>Yearly</span>
                </>
              )}
            </span>
          </div>
        </div>
        {planInfo.data.map((plan, index) => (
          <>
            {plan.PLANPURCHASED === "FALSE" &&
              plan.PLANNAME !== "Free Plan" &&
              plan.PLANNAME !== "Enterprise Plan" && (
                <div
                  key={index}
                  className="rounded-[10px] w-[700px] bg-white p-6 border-[1px] border-solid border-[#EEEEEE] mb-16"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="font-semibold text-[20px]">
                        {plan.PLANNAME}
                      </span>
                      <span className="text-[15px] text-black mb-6 mt-2">
                        You will get{" "}
                        <span className="font-semibold">
                          {plan.PLANCREDITS} Credits
                        </span>{" "}
                        in the{" "}
                        <span className="font-semibold">{plan.PLANNAME}</span>
                      </span>
                      <div className="grid grid-cols-2 gap-3">
                        {plan.PLANFEATURE?.split(",")
                          ?.map((item) => item.trim())
                          ?.map((feature, index) => (
                            <span key={index} className="">
                              <span className="flex items-center">
                                <img
                                  src={check}
                                  alt="check icon"
                                  height={20}
                                  width={20}
                                  style={{ marginRight: "3px" }}
                                />
                                <span className="text-sm text-[#3C3C3C]">
                                  {feature}
                                </span>
                              </span>
                            </span>
                          ))}
                      </div>
                      <span></span>
                    </div>
                    <div className="flex flex-col items-end">
                      <div className="text-[28px] font-semibold">
                        <span>$</span>
                        <span>
                          {isSwitchOn
                            ? plan.ANNUALAMOUNTDISPLAY
                            : plan.MONTHLYAMOUNTDISPLAY}
                        </span>
                      </div>
                      <div className="mt-14">
                        <SimpleButton
                          type="primary"
                          // danger={danger}
                          // onClick={onClick}
                          // size="p"
                          // disabled={disabled}
                          // htmlType={htmlType}
                          className="bg-[#1C68EB] hover:bg-gradient-to-r hover:to-[#0943A6] hover:from-[#1C68EB] rounded-3xl w-fit !px-4"
                          label="Buy Now"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </>
        ))}
        {planInfo.data.map((plan, index) => (
          <>
            {plan.PLANNAME === "Enterprise Plan" && (
              <>
                <div
                  key={index}
                  className="rounded-[10px] bg-white w-[700px] mt-4 max-h-[418px] pl-6 border-[1px] border-solid border-[#EEEEEE] mb-16"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col w-full py-6 enterpriseImage">
                      <span className="font-semibold text-[20px]">
                        {plan.PLANNAME}
                      </span>
                      <span className="text-[15px] text-black mb-6 mt-2">
                        {/* You received{" "}
                        <span className="font-semibold">
                          {plan.PLANCREDITS} Credits
                        </span>{" "}
                        in the <span className="">{plan.PLANNAME}</span> */}
                        {plan.PLANDESC}
                      </span>
                      <div className="grid grid-cols-2 gap-x-0 gap-y-3 w-[75%]">
                        {plan.PLANFEATURE?.split(",")
                          ?.map((item) => item.trim())
                          ?.map((feature, index) => (
                            <span key={index} className="">
                              <span className="flex items-start">
                                <img
                                  src={check}
                                  alt="check icon"
                                  height={20}
                                  width={20}
                                  style={{ marginRight: "3px" }}
                                />
                                <span className="text-smm text-[#3C3C3C] leading-5">
                                  {feature}
                                </span>
                              </span>
                            </span>
                          ))}
                      </div>
                      <span className="my-5">
                      <GhostButton
                        key="Talk To Sales"
                        // onClick={}
                        label="Talk To Sales"
                        size="large"
                        className="white-bg-btn !px-4 text-[#1560E1] font-inter !text-sm !h-[34px] font-semibold leading-5 !rounded-[34px] border border-[#1560E1]"
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ))}
      </div>
    );
  }
};

export default Subscription;
