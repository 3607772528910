import React, { useEffect, useState } from "react";
import InputBox from "../common/components/InputBox";
import SimpleButtonIcon from "../common/components/SimpleButtonIcon";
import plus from "../assests/commonIcons/plus.svg";
import { useQuery } from "react-query";
import AssetsCard from "./AssetsCard";
import UploadAssetModal from "./UploadAssetModal";
import noAssetImage from "../assests/commonIcons/assetImages/noAssetSvg.svg";
import { Select, Spin } from "antd";
// import { getAllAssetsData } from "../api/api_uploadAssets";
import { getAllAssetsData } from "../api/api_FetchData";
import GhostButton from "../common/components/GhostButton";
import useCurrentUrl from "../common/functions/useCurrentUrl";
import useSessionChecker from "../common/functions/useSessionChecker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWindowWidth from "../common/hooks/useWindowWidth";
const { Option } = Select;

const AssetsLayout = () => {
  const sessionuserid = localStorage.getItem("sessionUserId");
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  const [showSuffixIcon, setShowSuffixIcon] = useState(false);
  const [prefixActive, setPrefixActive] = useState(false);
  const [selectedType, setSelectedType] = useState("ALL");
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;

  const { data, status, refetch } = useQuery(
    ["users", sessionuserid],
    () => getAllAssetsData(sessionuserid, "/getcustassets"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        console.log(data.status, "DATA");
        if (data.status === "-2001") {
          sessionStorage.clear();
          localStorage.clear();
          navigate(`${process.env.REACT_APP_BASE_PATH}/logout`);
          toast.error(data.description);
        }
        // debugger
      },
    }
  );

  console.log(data, "ASSET DATA");

  useEffect(() => {
    // Show the spinner when the component mounts
    setIsSpinning(true);

    // Set a timeout to hide the spinner after 2 seconds
    const spinnerTimeout = setTimeout(() => {
      setIsSpinning(false);
    }, 1500);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(spinnerTimeout);
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts

  useEffect(() => {
    // Call the refetch function when the component mounts
    refetch();
  }, [refetch]);

  const handleUploadSuccess = () => {
    setIsSpinning(true); // Show the spinner

    setTimeout(() => {
      setIsSpinning(false); // Hide the spinner after 1 second
    }, 1200);

    refetch(); // Refetch the data after a successful upload
  };

  const handleUploadAssets = () => {
    setIsSpinning(true);

    setTimeout(() => {
      setIsSpinning(false);
    }, 1200);
  };

  //   console.log(data, "DATA ASSETS");

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const filteredAssets =
    status === "success" && data?.status === "1000" && data?.data
      ? data?.data.filter(
          (asset) =>
            (selectedType === "ALL" || asset.ASSETSTYPE === selectedType) &&
            ((asset.ASSETSNAME &&
              asset.ASSETSNAME.includes(searchQuery.toLowerCase())) ||
              (asset.ASSETSUPDATEDBY &&
                asset.ASSETSUPDATEDBY.includes(searchQuery.toLowerCase())))
        )
      : [];

  console.log(data?.data.length, "FILTERD ASSETS");
  // debugger
  return (
    <>
      <div
        className={` ${
          isDesktop ? "w-[calc(100vw_-_195px)]" : "w-full"
        }  h-[calc(100vh_-_3.75rem)] flex flex-col overflow-y-hidden`}
      >
        {isLargeScreen ? (
          <>
            <div className="w-full h-[12rem] bg-[#F7FAFC]">
              <div className="pt-9 pl-11 pr-8 pb-4">
                <div className="flex gap-5 justify-between">
                  <div className="text-[22px] font-semibold">My Assets</div>
                  <div className="flex gap-6 items-center">
                    <InputBox
                      searchIconClass="px-[1px] h-[12px] w-[15px]"
                      xIconClass=""
                      className="h-7 w-60 text-[12px] rounded-3xl global-search"
                      placeholder="Search assets..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onClearClick={() => {
                        setSearchQuery("");
                      }}
                      showSuffixIcon={showSuffixIcon}
                      prefixActive={prefixActive}
                      setShowSuffixIcon={setShowSuffixIcon}
                      setPrefixActive={setPrefixActive}
                    />
                    <SimpleButtonIcon
                      onClick={openModal}
                      label="Upload"
                      type="primary"
                      image={plus}
                    />
                  </div>
                </div>
              </div>
              <div className="pl-11 pr-8 pb-4 drop-shadow-sm flex justify-between items-center">
                <div className="flex gap-4">
                  {data?.data.length !== 0 ? (
                    <>
                      <button
                        className={`text-sm font-medium text-[#141C37] px-3 py-2 rounded-[4px] cursor-pointer border border-[#E7E7E7] border-solid hover:bg-[#DEE6FF] ${
                          selectedType === "ALL"
                            ? "bg-[#DEE6FF] text-[#141C37] border-[#DEE6FF] border-solid"
                            : ""
                        }`}
                        onClick={() => handleTypeChange("ALL")}
                      >
                        ALL
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  {status === "success" &&
                    data?.data &&
                    Array.from(
                      new Set(data?.data.map((asset) => asset.ASSETSTYPE))
                    ).map((ASSETSTYPE) => (
                      <button
                        key={ASSETSTYPE}
                        className={`text-sm font-medium text-[#141C37] px-3 py-2 rounded-[4px] cursor-pointer border border-[#E7E7E7] border-solid hover:bg-[#DEE6FF] ${
                          selectedType === ASSETSTYPE
                            ? "bg-[#DEE6FF] text-[#141C37] border-[#DEE6FF] border-solid"
                            : ""
                        }`}
                        onClick={() => setSelectedType(ASSETSTYPE)}
                      >
                        {ASSETSTYPE}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full h-[12rem] bg-[#F7FAFC]">
              <div className="pt-7 pb-4">
                <div className="flex flex-col pl-8 pr-6">
                  <div className="text-[20px] font-medium ml-2">My Assets</div>
                  <div className="mt-3">
                    <InputBox
                      searchIconClass="px-[1px] h-[12px] w-[15px]"
                      xIconClass=""
                      className={`h-9 ${
                        windowWidth > 500 ? "w-80" : "w-full"
                      } text-[12px] rounded-3xl global-search`}
                      placeholder="Search assets..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onClearClick={() => {
                        setSearchQuery("");
                      }}
                      showSuffixIcon={showSuffixIcon}
                      prefixActive={prefixActive}
                      setShowSuffixIcon={setShowSuffixIcon}
                      setPrefixActive={setPrefixActive}
                    />
                  </div>
                </div>
                <div
                  className="flex justify-between items-center h-[46px] mt-4 pl-8 pr-6"
                  style={{
                    borderTop: "1px solid #D9D9D9",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <div className="template-select pt-1">
                    <Select
                      defaultValue="ALL"
                      bordered={false}
                      style={{ minWidth: 55, width: "auto" }}
                      dropdownStyle={{ minWidth: "90px" }}
                      onChange={(value) => handleTypeChange(value)}
                    >
                      {data?.data.length !== 0 && (
                        <Option value="ALL">ALL</Option>
                      )}

                      {status === "success" &&
                        data?.data &&
                        Array.from(
                          new Set(data?.data.map((asset) => asset.ASSETSTYPE))
                        ).map((ASSETSTYPE) => (
                          <Option key={ASSETSTYPE} value={ASSETSTYPE}>
                            {ASSETSTYPE}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="">
                    <SimpleButtonIcon
                      onClick={openModal}
                      label="Upload"
                      type="primary"
                      image={plus}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className={`w-full h-[100%] overflow-auto bg-[#F7FAFC] scrollbar-w-[4px] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] border-[#DADDEF] ${
            isLargeScreen ? "pl-11 pr-8" : "pl-7 pr-6"
          }  pb-8`}
        >
          <div className="flex pb-4 w-full">
            {status === "loading" && (
              <div className="loading-indicator">
                <Spin />
              </div>
            )}
            {status === "error" && <div>Error fetching data</div>}
            {status === "success" && (
              <div className="flex flex-wrap w-full">
                {filteredAssets.length === 0 ? (
                  <>
                    {searchQuery ? (
                      <div className={` ${isDesktop ? "w-[calc(100vw-316px)]" : "w-full"} flex flex-col items-center pt-12`}>
                        <span className="text-center flex justify-center">
                          <img src={noAssetImage} alt="no asset svg"  className={` ${isLargeScreen ? "" : "w-[120px]"}`} />
                        </span>
                        <span className={`${isLargeScreen ? "text-xl" : "text-[17px]"} text-[#4B4B4B] font-medium pt-4`}>
                          No matching assets found
                        </span>
                      </div>
                    ) : (
                      <div className={`${isDesktop ? "w-[calc(100vw-316px)]" : "w-full"} flex flex-col items-center pt-12`}>
                        <span className="text-center flex justify-center">
                          <img src={noAssetImage} alt="no asset svg" className={` ${isLargeScreen ? "" : "w-[120px]"}`} />
                        </span>
                        <span className={`${isLargeScreen ? "text-xl" : "text-[17px] text-center"} text-[#4B4B4B] font-medium pt-4`}>
                          An empty assets folder does not look nice, right?
                        </span>
                        <span className={`text-[#7A7A7A] ${isLargeScreen ? "text-[15px] w-[480px]" : "text-[12px]"} font-normal  leading-5 text-center pt-1`}>
                          Upload images, videos, and audio files that you will
                          repeatedly use in the templates. Find media files in
                          seconds when you’re editing.
                        </span>
                        <span className="pt-4">
                          <GhostButton
                            key="Upload"
                            onClick={openModal}
                            label="Upload Assets"
                            className="white-bg-btn mr-6 text-blue-500 font-inter text-sm font-normal leading-5 rounded-md border border-blue-500"
                          />
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  filteredAssets.map((asset) => (
                    <AssetsCard key={asset.ASSETSID} asset={asset} />
                  ))
                )}
              </div>
            )}
          </div>
        </div>
        {isSpinning && (
          <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
            <Spin tip="Uploading..." />
          </div>
        )}
      </div>
      {isModalOpen && (
        <UploadAssetModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          onUploadSuccess={handleUploadSuccess}
          handleUploadAssets={handleUploadAssets}
        />
      )}
    </>
  );
};

export default AssetsLayout;
