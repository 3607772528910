import { toast } from "react-toastify";

const API_BASE_URL = window.baseurl;

// ***************** CENTRALIZED API CALLING ONLY FOR FETCHING DATA FROM SERVER *************************

// THIS FETCH_API_DATA RECEIVES 4 PARAMETERS ENDPOINT, SESSIONUSERID, CUSTREGID AND ACTION BASED UPON WHICH
// API IS BEING CALLED

const fetchApiData = async (endpoint, sessionuserid, custregid, action) => {
  if (!navigator.onLine) {
    toast.error("No internet connection. Please check your network.");
    return;
  }
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        action,
        sessionuserid,
        custregid,
      }),
    };

    const currentTime = new Date().getTime();
    const response = await fetch(
      `${API_BASE_URL}${endpoint}?t=${currentTime}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      const parsedData = JSON.parse(data.DATA);
      return {
        status: data.STATUS,
        description: data.DESCRIPTION,
        data: parsedData,
      };
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.error(error);
    throw toast.error("Something went wrong!", {});
  }
};

// BELOW FUNCTION IS CALLED FROM TemplateLayout.js WHICH IS FETCHING ALL THE GLOBAL TEMPLATES FROM SERVER
export const getAllGlobalTemplate = async (
  sessionuserid,
  custregid,
  endpoint
) => {
  return fetchApiData(
    endpoint,
    sessionuserid,
    custregid,
    "GETTEMPLATESLIBRARY"
  );
};

// BELOW FUNCTION IS CALLED FROM UserTemplateLayout.js || GoToProject.js || ProjectModal.js WHICH IS FETCHING ALL THE USER TEMPLATES FROM SERVER
export const getAllUserTemplate = async (
  sessionuserid,
  custregid,
  endpoint
) => {
  return fetchApiData(
    endpoint,
    sessionuserid,
    custregid,
    "GETALLCUSTTEMPLATES"
  );
};

// BELOW FUNCTION IS CALLED FROM GoToProjetc.js || Navbar.js || Layout.js || UserLayout.js || Profile.js WHICH IS FETCHING USER/CUSTOMER INFORMATIONS
export const getCustomerInfo = async (sessionuserid, custregid, endpoint) => {
  return fetchApiData(endpoint, sessionuserid, custregid, "GETCUSTOMERINFO");
};

// BELOW FUNCTION IS CALLED FROM GoToProjetc.js || Navbar.js || Layout.js || UserLayout.js || Profile.js WHICH IS FETCHING USER/CUSTOMER INFORMATIONS
export const getTemplateInfo = async (sessionuserid, custregid, endpoint) => {
  return fetchApiData(endpoint, sessionuserid, custregid, "GETTEMPLATEDETAIL");
};

// BELOW FUNCTION IS CALLED FROM UserTemplateLayout.js || HeaderDashboard.js WHICH IS FETCHING ALL THE USER TEMPLATES FROM SERVER
export const getDashboardData = async (sessionuserid, custregid, endpoint) => {
  return fetchApiData(endpoint, sessionuserid, custregid, "GETDASHBOARDDATA");
};

// PLAY REPORT DATA VideoPlayReport.js
export const getPlayReportData = async (
  sessionuserid,
  custregid,
  endpoint,
  action
) => {
  return fetchApiData(endpoint, sessionuserid, custregid, action);
};

// USER BRAND DATA UserBrandCard.js || MapBrandEditModal.js || MapBrandPageModal.js
export const getAllUserBrandPages = async (
  sessionuserid,
  custregid,
  endpoint
) => {
  return fetchApiData(
    endpoint,
    sessionuserid,
    custregid,
    "GETALLCUSTBRANDPAGES"
  );
};

// BELOW FUNCTION IS CALLED FROM BrandLayout.js WHICH IS FETCHING ALL THE BRAND PAGES FROM SERVER
export const getAllGlobalBrandPages = async (
  sessionuserid,
  custregid,
  endpoint
) => {
  return fetchApiData(
    endpoint,
    sessionuserid,
    custregid,
    "GETBRANDPAGESLIBRARY"
  );
};

const fetchPlanDetailsData = async () =>
  // endpoint,
  // sessionuserid,
  // custregid,
  // action
  {
    try {
      // debugger
      // const requestOptions = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     action,
      //     sessionuserid,
      //     custregid,
      //   }),
      // };

      // const response = await fetch(`${API_BASE_URL_MOCK_PLAN}${endpoint}`, requestOptions);
      const response = await fetch(
        "https://mocki.io/v1/055d029d-20f1-419d-b3dc-0c92db7e5224"
      ); // Buy Free Plan + Enterprise
      // const response = await fetch('https://mocki.io/v1/97c7eecf-171d-40ed-860f-9f1703b7123e'); // Buy Free Plan + Enterprise
      // const response = await fetch('https://mocki.io/v1/2b274cd3-d249-4842-aac2-39c170b5ca1c'); // Buy Free Plan
      // const response = await fetch('https://mocki.io/v1/d4e3576a-e7b1-4e75-a322-7a8504d9a9cc'); //Buy Starter Plan
      const data = await response.json();

      if (response.ok) {
        // debugger
        // const parsedData = JSON.parse(data.DATA);
        const parsedData = data.DATA;
        // console.log(parsedData, "P DATA")
        return {
          status: data.STATUS,
          description: data.DESCRIPTION,
          data: parsedData,
        };
      } else {
        throw new Error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error(error);
      throw toast.error("Something went wrong!", {});
    }
  };

export const getPlanDetails = async (sessionuserid, custregid, endpoint) => {
  return fetchPlanDetailsData(
    endpoint,
    sessionuserid,
    custregid,
    "GETPLANINFO"
  );
};

// *******************************************************************************************************//
// ***************************************** ASSETS ******************************************************//
// *******************************************************************************************************//
const fetchAssetsData = async (sessionuserid, endpoint) => {
  if (!navigator.onLine) {
    toast.error("No internet connection. Please check your network.");
    return;
  }
  try {
    // debugger
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionuserid,
      }),
    };

    const currentTime = new Date().getTime();
    const response = await fetch(
      `${API_BASE_URL}${endpoint}?t=${currentTime}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      const parsedData = JSON.parse(data.DATA);
      return {
        status: data.STATUS,
        description: data.DESCRIPTION,
        data: parsedData,
      };
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.error(error);
    throw toast.error("Something went wrong!", {});
  }
};

// BELOW FUNCTION IS CALLED FROM AssetsLayout.js WHICH IS FETCHING ALL THE ASSETS FROM SERVER
export const getAllAssetsData = async (sessionuserid, endpoint) => {
  return fetchAssetsData(sessionuserid, endpoint);
};
// *******************************************************************************************************//
// *******************************************************************************************************//
// *******************************************************************************************************//
