import React, { useEffect, useState } from "react";
import OtpInput from "otp-input-react";
import { Modal } from "antd";
import SimpleButton from "../components/SimpleButton";
import ResendOTP from "../../assests/commonIcons/userModuleIcons/ResendOTP.svg";
import ResendOTPGrey from "../../assests/commonIcons/userModuleIcons/ResendOTPGrey.svg";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { validateComEmailOtp, verifyComEmail } from "../../api/api_updateData";

const OtpModalEmail = (props) => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [isOtpFilled, setIsOtpFilled] = useState(false); // New state variable for tracking OTP input status

  const sessionuserid = localStorage.getItem("sessionUserId");

  const onOTPVerifyClick = () => {
    setOtp("");
  };

  //******************************* CREATE CUSOTMER STARTS ******************************************
  const { mutate: createValidateComEmailOtpMutation } = useMutation(
    (emailVerifyData) => validateComEmailOtp(emailVerifyData),
    {
      onSuccess: (data) => {
        // queryClient.invalidateQueries("");
        // const sessionUserId = props.email;
        // const sessionId = createSession(sessionUserId);
        // localStorage.setItem("sessionId", sessionId); // Store sessionId in localStorage
        // localStorage.setItem("sessionUserId", sessionUserId); // Store sessionUserId in localStorage
        // console.log(sessionId, "session ID");
        // console.log(sessionUserId, "session user ID");

        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
          props.setDialog(false);
          props.setIsEmailVerified(true);
          props.setIsEmailValid(true);
          props.setCommEmail(true);
          props.setOtpEmail(true);
          // navigate(props.sendRoute);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  //  *************************** CREATE CUSOTMER ENDS ******************************************

  const handleVerifyComEmailOtp = () => {
    const emailVerifyData = {
      custregid: sessionuserid,
      email: props.email,
      emailotp: otp,
    };
    createValidateComEmailOtpMutation(emailVerifyData);
    console.log(emailVerifyData, "CUST EMAIL OTP DATA");
  };

  const { mutate: createValidateEmailOtpMutation } = useMutation(
    (emailData) => verifyComEmail(emailData),
    {
      onSuccess: (data) => {
        // debugger
        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  //  *************************** CREATE CUSOTMER ENDS ******************************************

  const handleVerifyComEmail = () => {
    // debugger
    const emailData = {
      custregid: sessionuserid,
      email: props.email || email,
    };

    // Show spinner when verifying email
    // setShowSpinner(true);
    setEmail(props.email);
    createValidateEmailOtpMutation(emailData);
    console.log(props.email, "prop email");
    console.log(email, "set email");
  };

  //   for resend OTP
  const [seconds, setSeconds] = useState(30);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [seconds, isActive]);

  useEffect(() => {
    setIsActive(props.timerActive);
  }, [props.timerActive]);

  const handleClick = () => {
    // debugger
    if (seconds > 0) {
      return;
    }
    // props.handleVerifyComEmail();
    setSeconds(30);
    console.log("CLICKED RESEND");
  };

  // Use useEffect to check whether all OTP inputs are filled whenever `otp` changes
  useEffect(() => {
    setIsOtpFilled(otp.length === 6);
  }, [otp]);

  return (
    <>
      <Modal
        className="otp-email-modal"
        width={570}
        height={300}
        maskClosable={false}
        onCancel={() => {
          props.setDialog(false);
          setSeconds(5);
          props.handleTimer(false);
        }}
        centered
        open={props.dialog}
      >
        <div className="py-5 md:px-5  flex flex-col justify-center">
          <span className="font-bold md:text-[20px] text-[19px] text-[#242424]">
            Verify Communication Email ID
          </span>
          <span className="mt-3 text-sm text-[#6F6F6F] font-normal">
            OTP is sent to{" "}
            <span className="text-sm font-semibold text-[#6F6F6F]">{`${props.email}`}</span>{" "}
          </span>
          <span className="mt-4">
            <OtpInput
              value={otp}
              onChange={setOtp}
              OTPLength={6}
              otpType="number"
              disabled={false}
              autoFocus
              className="opt-container !w-[40px]"
            ></OtpInput>
          </span>

          {/* <span className="mt-3"> */}
          <span
            // onClick={handleClick}

            size="large"
            disabled={seconds === 0 ? false : true}
            className="mt-4 text-black border-none cursor-pointer flex justify-start items-center"
          >
            <span>
              {seconds === 0 ? (
                <div
                  className="flex justify-around items-center"
                  onClick={() => {
                    handleClick();
                    handleVerifyComEmail();
                  }}
                  style={{
                    visibility: seconds === 0 ? "none" : "visible",
                    color: seconds === 0 ? "#003CD8" : "#757575",
                  }}
                >
                  <span>
                    <img src={ResendOTP} alt="otp icon" />
                  </span>
                  <span className="text-[#003CD8] mx-2">Resend OTP</span>
                  <span className="text-black"> now</span>
                </div>
              ) : (
                <div
                  className="flex justify-around items-center"
                  style={{
                    visibility: seconds === 0 ? "none" : "visible",
                    color: seconds === 0 ? "#003CD8" : "#757575",
                  }}
                >
                  <span>
                    <img src={ResendOTPGrey} alt="otp icon" />
                  </span>
                  <span className="text-[#757575] mx-2">Resend OTP in</span>
                  <span className="text-[#757575]">{seconds}s</span>
                </div>
              )}
            </span>
          </span>
          {/* </span> */}

          <div className="flex justify-end">
            <SimpleButton
              onClick={() => {
                handleVerifyComEmailOtp();
                onOTPVerifyClick();
              }}
              // onClick={() => onOTPVerify()}
              className="!rounded-[4px] w-fit md:!px-6"
              label="Verify OTP"
              type="primary"
              size="large"
              disabled={!isOtpFilled} // Use isOtpFilled to enable/disable the button
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OtpModalEmail;
