import { Input } from "antd";
import React from "react";
import search from "../../assests/commonIcons/templateIcons/Search.svg";
import xIcon from "../../assests/commonIcons/templateIcons/xIconSvg.svg";
import searchBlue from "../../assests/commonIcons/templateIcons/searchBlue.svg";
import "../styles/commonStyles.css"

const InputBox = ({
  className,
  placeholder,
  value,
  onChange,
  searchIconClass,
  xIconClass,
  onClearClick,
  showSuffixIcon,
  prefixActive,
  setShowSuffixIcon,
  setPrefixActive,
}) => {
  const handleClearClick = () => {
    if (onClearClick) {
      onClearClick();
    }
    setShowSuffixIcon(false);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setShowSuffixIcon(newValue.length > 0);
    onChange(e);
  };

  const handleFocus = () => {
    setPrefixActive(true);
  };

  const handleBlur = () => {
    setPrefixActive(false);
  };

  return (
    <div>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`${className} px-3 searchInput`}
        prefix={
          <img
            src={prefixActive ? searchBlue : search}
            alt="Search Icon"
            className={searchIconClass}
          />
        }
        suffix={
          showSuffixIcon && (
            <img
              src={xIcon}
              alt="cross Icon"
              onClick={handleClearClick}
              className={` ${xIconClass} bg-[#E8F4FF]  rounded-full cursor-pointer`}
            />
          )
        }
      />
    </div>
  );
};

export default InputBox;
