import { Button } from "antd";
import React from "react";
import "../styles/commonStyles.css"

const SimpleButtonIcon = ({
  label,
  className,
  type,
  image,
  imageClass,
  onClick,
  size,
  disabled,
}) => {
  return (
    <div className="simple-button-icon">
      <Button onClick={onClick} type={type} size={size} disabled={disabled}>
        <div className="flex gap-1 items-center">
          <img className={imageClass} src={image} alt="plus" />
          <span className={className}>{label}</span>
        </div>
      </Button>
    </div>
  );
};

export default SimpleButtonIcon;
