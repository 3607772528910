import React, { useState } from "react";
// import OtpInput from "react-otp-input";
import { Form, Spin } from "antd";

import LoadingBar from "react-top-loading-bar";
import { PhoneInput, usePhoneValidation } from "react-international-phone";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
// import logoimage from "../../assests/commonImages/new-vspagy-logo.svg";
import newVspagyLogo from "../../assests/commonImages/new-vspagy-beta-icon-revised.png";
import personOnboarding from "../../assests/commonImages/userOnboarding/getting-started-1.png";

import "../../common/styles/userModule/UserOnboarding.css";
import SimpleButton from "../../common/components/SimpleButton";
import { RightOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { updateMobile } from "../../api/api_updateData";
import useSessionChecker from "../../common/functions/useSessionChecker";

export default function PersonOnboarding() {
  const navigate = useNavigate();
  useSessionChecker(navigate);

  const sessionuserid = localStorage.getItem("sessionUserId");

  const [form] = useForm();
  const [mobile, setMobile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const phoneValidation = usePhoneValidation(mobile);

  function handleFinish(value) {
    // navigate(`${process.env.REACT_APP_BASE_PATH}/getting-started-2`);
    handleUpdateMobile();
  }

  const handleUpdateMobile = () => {
    setIsLoading(true);
    const cleanedMobile = mobile.replace(/\D/g, ""); // Remove all non-numeric characters
    console.log(cleanedMobile);

    const custMobileData = {
      custregid: sessionuserid,
      mobile: cleanedMobile,
    };
    // console.log(custMobileData, "MOBILE DATA");
    updateCustomerMobileMutation(custMobileData);
  };

  const { mutate: updateCustomerMobileMutation } = useMutation(
    (custMobileData) => updateMobile(custMobileData),
    {
      onSuccess: (custMobileData) => {
        if (custMobileData.STATUS === "1000") {
          setTimeout(() => {
            navigate(`${process.env.REACT_APP_BASE_PATH}/getting-started-2`);
          }, 2000); // Navigate after 2 seconds
        } else {
          toast.error(custMobileData.DESCRIPTION);
          setIsLoading(false);
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex h-[68px] items-center justify-between">
              {/* <div className="header px-4 pt-4 flex items-center justify-between"> */}
              <img
                src={newVspagyLogo}
                alt="VPSAGY logo"
                className="logo-img w-[132px] snap-center ml-2"
              />
              <span className="flex items-center pr-4">
                <Link
                  to={`${process.env.REACT_APP_BASE_PATH}/getting-started-2`}
                  className="no-underline"
                >
                  <p className="skip-link flex items-center font-medium text-base text-secondary-blue hover:text-primary-blue hover:underline">
                    <span>Skip </span>
                    <RightOutlined />
                  </p>
                </Link>
              </span>
            </div>

            <div className=" pt-6 m-auto text-center">
              <div className="mt-10 sm:px-20 max-sm:pr-[12px] flex flex-col">
                <img
                  src={personOnboarding}
                  alt="personOnboarding"
                  className="block m-auto w-[250px] my-3"
                />
                <span
                  className="select-none font-medium sm:!text-[28px] max-sm:text-[22px]"
                  style={{
                    fontFamily: "inter",
                  }}
                >
                  Enter your contact{" "}
                  <span className="text-[#C62134] fontl font-normal sm:text-[25px] max-sm:text-[19px]">
                    number
                  </span>
                </span>
                <span className="mt-4 mx-auto text-[#727171] select-none sm:text-base max-sm:text-sm max-sm:px-[50px]">
                  This information is required in order for us to build your
                  business card, which will be shown throughout the product.
                </span>
              </div>
              <div className="contact-details sm:w-[422px] p-3 m-auto font-inter ">
                <div className="flex flex-col justify-center sm:items-start max-sm:items-center mt-[25px]">
                  <div className="font-medium flex max-sm:pr-[175px] text-sm text-left mt-1 text-[#383838]">
                    Mobile Number
                  </div>
                  <div className="">
                    <div className="ant-input mt-2 h-10">
                      <div className="flex items-flex phone-input-profile-onboarding">
                        <PhoneInput
                          defaultCountry="in"
                          value={mobile}
                          onChange={(mobile) => setMobile(mobile)}
                        />
                      </div>
                    </div>
                    <Form onFinish={handleFinish}>
                      <Form.Item form={form} name="myForm">
                        <SimpleButton
                          disabled={!phoneValidation.isValid}
                          className="w-full !rounded-[5px] mt-8 my-3"
                          size="large"
                          type="primary"
                          label="Submit"
                          htmlType="submit"
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="onboardLoader">
              <LoadingBar
                progress={99}
                width={100}
                height={3}
                color="#C62134"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
