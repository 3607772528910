import { Modal, Popover, QRCode, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import copy from "../../../../assests/commonIcons/copy.svg";
import { useMutation } from "react-query";
import { editTemplateApi } from "../../../../api/api_updateData";
import { toast } from "react-toastify";
import useWindowWidth from "../../../hooks/useWindowWidth";
import DynamicFormLink from "./DynamicFormLink";

const GeneratePreviewLinkModal = (props) => {
  const custregid = localStorage.getItem("sessionUserId");
  const link = props.templateprevlink;
  console.log(link, "LINK");

  const [isCopied, setIsCopied] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isMediumScreen = windowWidth >= 500;

  const { mutate: editTemplateMutation } = useMutation(
    (editTemplateData) => editTemplateApi(editTemplateData),
    {
      onSuccess: (data) => {
        // debugger
        const parsedData = JSON.parse(data.DATA);
        if (data.STATUS === "1000") {
          console.log(data, "DATA");
          console.log(parsedData, "P DATA");
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  useEffect(() => {
    const getTemplateDetail = () => {
      const editTemplateData = {
        templateid: props.templateid,
        sessionuserid: custregid,
      };
      console.log(editTemplateData, "E T DATA");
      editTemplateMutation(editTemplateData);
    };

    getTemplateDetail();
  }, [props.templateid, custregid, editTemplateMutation]);

  // Function to copy the API link to the clipboard
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setIsCopied(true);
  };

  const revertCopy = () => {
    setIsCopied(false);
  };

  // Helper function to parse the URL and extract the projectid and apikey.
  const extractParams = (url) => {
    if (!url) {
      console.error("Invalid URL:", url);
      return {};
    }

    try {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.search);
      const projectid = params.get("projectid");
      const apikey = params.get("apikey");
      return { projectid, apikey };
    } catch (error) {
      console.error("Failed to construct URL:", error);
      return {};
    }
  };

  const { projectid, apikey } = extractParams(props.apiLink);
  console.log(props.apiLink, "API LINK");

  return (
    <>
      <Modal
        className="generate-preview-link-modal-new"
        maskClosable={false}
        onCancel={() => {
          props.setDialog(false);
          revertCopy();
        }}
        centered
        open={props.dialog}
        width={isMediumScreen ? 600 : 360}
      >
        {isMediumScreen ? (
          <>
            <div className="flex flex-col justify-start">
              <span className="px-4 font-semibold text-[#000000] text-base">
                Generate Preview Link{" "}
                <span className="font-normal text-[#504F4F]">for</span>{" "}
                <span className="font-medium text-[#504F4F]">
                  '{props.templatename}'
                </span>
              </span>
              {link === null ? (
                <></>
              ) : (
                <span className="bg-[#EDF6F9CC] px-4 flex my-2 justify-between items-center">
                  <span className="text-smm font-medium flex items-center">
                    Last preview link :
                    <span className="text-blue-600 font-normal">
                      <Tooltip
                        title={isMobile ? "" : "Copy link"}
                        placement="rightBottom"
                        trigger="hover"
                      >
                        <span
                          className="flex items-center justify-between cursor-pointer"
                          onClick={copyToClipboard}
                        >
                          <span className="text-[#0755DB] text-sm font-normal whitespace-nowrap overflow-hidden overflow-ellipsis select-none">
                            {" "}
                            {link}
                          </span>
                          {isCopied ? (
                            <>
                              <span className="ml-2 text-[#0755dba3] text-sm font-normal">
                                Copied.
                              </span>
                            </>
                          ) : (
                            <>
                              <img
                                className="ml-2 cursor-pointer"
                                src={copy}
                                alt="copy"
                              />
                            </>
                          )}
                        </span>
                      </Tooltip>
                    </span>
                  </span>
                  <span className="link-qrcode flex justify-end items-center">
                    <span className="text-[#615F5F] text-[11px] w-[50%]">
                      Scan code to preview link in mobile
                    </span>
                    <Popover
                      overlayInnerStyle={{
                        padding: 0,
                      }}
                      placement="bottom"
                      content={
                        <QRCode
                          errorLevel="H"
                          value={link}
                          size="150"
                          bordered={false}
                        />
                      }
                    >
                      <span className="w-[100%]">
                        <QRCode errorLevel="H" value={link} size="60" />
                      </span>
                    </Popover>
                  </span>
                </span>
              )}

              <span className="text-[#242424] text-sm my-3 px-4">
                In case the template is redesigned or any changes have been made
                then add details below to generate new preview link
              </span>
              <span className="px-4">
                <DynamicFormLink
                  variables={props.variable}
                  projectid={projectid}
                  apikey={apikey}
                  setDialog={props.setDialog}
                />
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col justify-start">
              <span className="px-4 font-semibold text-[#000000] text-base">
                Generate Preview Link{" "}
                <span className="font-normal text-[#504F4F]">for</span> <br />
                <span className="font-medium text-[#504F4F]">
                  '{props.templatename}'
                </span>
              </span>
              {link === null ? (
                <></>
              ) : (
                <span className="bg-[#EDF6F9CC] px-4 flex my-2 flex-col">
                  <span className="text-smm font-medium flex flex-col">
                    Last preview link :
                    <br />
                    <span className="text-blue-600 font-normal">
                      <span
                        className="flex items-center cursor-pointer"
                        onClick={copyToClipboard}
                      >
                        <span className="text-[#0755DB] text-sm font-normal whitespace-nowrap overflow-hidden overflow-ellipsis select-none">
                          {" "}
                          {link}
                        </span>
                        {isCopied ? (
                          <>
                            <span className="ml-2 text-[#0755dba3] text-sm font-normal">
                              Copied.
                            </span>
                          </>
                        ) : (
                          <>
                            <img
                              className="ml-2 cursor-pointer"
                              src={copy}
                              alt="copy"
                            />
                          </>
                        )}
                      </span>
                    </span>
                  </span>
                  <span className="link-qrcode flex items-center mt-2">
                    <span className="text-[#615F5F] text-[11px] w-[38%]">
                      Scan code to preview link in mobile
                    </span>
                    <Popover
                      overlayInnerStyle={{
                        padding: 0,
                      }}
                      placement="bottom"
                      content={
                        <QRCode
                          errorLevel="H"
                          value={link}
                          size="150"
                          bordered={false}
                        />
                      }
                    >
                      <span className="">
                        <QRCode errorLevel="H" value={link} size="60" />
                      </span>
                    </Popover>
                  </span>
                </span>
              )}

              <span className="text-[#242424] text-sm my-3 px-4">
                In case the template is redesigned or any changes have been made
                then add details below to generate new preview link
              </span>
              <span className="px-4">
                <DynamicFormLink
                  variables={props.variable}
                  projectid={projectid}
                  apikey={apikey}
                  setDialog={props.setDialog}
                />
              </span>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default GeneratePreviewLinkModal;
