import { Button } from 'antd'
import React from 'react'

const SimpleButton = ({
  type,
  onClick,
  danger,
  size,
  disabled,
  htmlType,
  label,
  className,
}) => {
  return (
    <>
      <Button
        type={type}
        danger={danger}
        onClick={onClick}
        size={size}
        disabled={disabled}
        htmlType={htmlType}
        className={className}
      >
        {label}
      </Button>
    </>
  );
};

export default SimpleButton