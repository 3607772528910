import React from "react";
import { Button, Form, Select, Space } from "antd";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { useMutation } from "react-query";

const Option = Select.Option;

const SubmitRequest = () => {
  const [form] = Form.useForm();
  const [requestType, setRequestType] = useState();
  const [bugType, setBugType] = useState();
  const [creditType, setCreditType] = useState();
  const [shareType, setShareType] = useState();

  const API_BASE_URL = window.baseurl;
  const endpoint = "/template";

  const { mutate } = useMutation(
    (formData) => {
      // Assuming your API endpoint is 'https://api.example.com/submit'
      return fetch(`${API_BASE_URL}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify(formData),
      }).then((response) => response.json());
    },
    {
      onSuccess: (data) => {
        // Handle successful API response
        console.log("API response:", data);
        // message.success('Form submitted successfully!');
      },
      onError: (error) => {
        // Handle API request error
        console.error("API error:", error);
        // message.error('Error submitting form. Please try ag/ain.');
      },
    }
  );

  const handleRequestTypeChange = (value) => {
    // debugger
    setBugType(null);
    setCreditType(null);
    setShareType(null);
    setRequestType(value);
    console.log(bugType, "BUG TYPE");
    console.log(creditType, "CRED TYPE");
    console.log(shareType, "SHARE TYPE");
    console.log(value, "RE TYPE");
  };

  const onFinish = (values) => {
    console.log(values);
    mutate(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <>
    <Form
        form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item
          name="requesttype"
          label="Select type of request"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            value={requestType}
            onChange={handleRequestTypeChange}
            placeholder="Choose request type..."
            className="submit-request-select"
          >
            <Option value="Report a bug">Report a bug</Option>
            <Option value="Credit queries">Credit queries</Option>
            <Option value="Share feedback">Share feedback</Option>
          </Select>
        </Form.Item>

        {requestType === "Report a bug" && (
          <Form.Item
            name="query"
            label="I need help with ..."
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              value={bugType}
              placeholder="--- Select ---"
            >
              <Option value="Something stopped working">
                Something stopped working
              </Option>
              <Option value="Feature not functioning as expected">
                Feature not functioning as expected
              </Option>
              <Option value="Something else">Something else</Option>
            </Select>
          </Form.Item>
        )}

        {requestType === "Credit queries" && (
          <Form.Item
            name="query"
            label="I need help with ..."
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              value={creditType}
              placeholder="--- Select ---"
            >
              <Option value="Credits not displayed">
                Credits not displayed
              </Option>
              <Option value="Credit allocation or deduction inaccuracy">
                Credit allocation or deduction inaccuracy
              </Option>
              <Option value="Unexpected depletion of credits">
                Unexpected depletion of credits
              </Option>
              <Option value="Delays in credit refill">
                Delays in credit refill
              </Option>
              <Option value="Something else">Something else</Option>
            </Select>
          </Form.Item>
        )}

        {requestType === "Share feedback" && (
          <Form.Item
            name="query"
            label="I need help with ..."
            rules={[
              {
                required: true,
              },
            ]}
            className="mb-4"
          >
            <Select
              value={shareType}
              placeholder="--- Select ---"
            >
              <Option value="Editor features">Editor features</Option>
              <Option value="Others">Others</Option>
            </Select>
          </Form.Item>
        )}

        <div className="text-smm text-gray-500">
          Please include details of your request (steps to reproduce,
          device/enivironment, etc) so we can provide the most complete and
          accurate support. If relevent, screenshot and video recordings are
          highly encouraged.
        </div>

        <Form.Item
          name="description"
          label="Description"
          className="mt-2"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            className="!h-[120px] rounded-[4px] scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full
                    scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] overflow-y-auto"
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {/* <div class="border border-blue-300 shadow rounded-md w-[360px] mx-auto">
        <div class="animate-pulse flex flex-col">
          <div className="flex gap-8 justify-between items-start p-3">
            <div class="rounded-[5px] bg-slate-400 h-[88px] w-[88px]"></div>
            <div>
              <>
                <div class="h-2.5 w-[150px] bg-slate-400 rounded-5"></div>
                <div class="h-2.5 w-[150px] bg-slate-400 rounded-5 mt-3"></div>
                <div class="h-2.5 w-[150px] bg-slate-400 rounded-5 mt-3"></div>
                <div class="h-2.5 w-[150px] bg-slate-400 rounded-5 mt-3"></div>
              </>
            </div>
            <div className="flex justify-between w-[14px] items-center">
                <div class="h-[3px] w-[3px] bg-slate-400 rounded-5"></div>
                <div class="h-[3px] w-[3px] bg-slate-400 rounded-5"></div>
                <div class="h-[3px] w-[3px] bg-slate-400 rounded-5"></div>
            </div>
          </div>
          <Divider className="!border-[#D9D9D9] border-[1px]" />
          <div className="my-2 flex justify-between px-3">
            <div className="flex items-center">
                <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                <div class="rounded-5 bg-slate-400 h-2.5 w-[120px] ml-2"></div>
            </div>
            <div className="flex items-center">
                <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                <div class="rounded-5 bg-slate-400 h-2.5 w-[120px] ml-2"></div>
            </div>
          </div>
          <Divider className="!border-[#D9D9D9] border-[1px]" />
          <div className="my-2 flex justify-between items-center px-3">
            <div className="flex items-center">
                <div className="w-[3px] rounded-t-[2px] rounded-b-[2px] h-4 bg-[#747AFF]"></div>
                <div class="rounded-5 bg-slate-400 h-2.5 w-[120px] ml-2"></div>
            </div>
            <div class="rounded-5 bg-slate-400 h-4 w-1"></div>
          </div>
          <div className="mt-1 px-1 pb-3 flex justify-between items-center">
            <div class="rounded-5 bg-slate-400 h-2.5 w-[132px] ml-2"></div>
            <div className="flex w-20 justify-between items-center">
                <div class="rounded-[5px] bg-slate-400 h-8 w-8"></div>
                <div class="rounded-[5px] bg-slate-400 h-8 w-8"></div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SubmitRequest;
