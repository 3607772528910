import React from "react";
import "../../common/styles/commonStyles.css";
import { Spin } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { getDashboardData } from "../../api/api_FetchData";
import "../../common/styles/dashboard/DashboardStyles.css";
import HeaderDashboard from "./HeaderDashboard";
import AnalyticsOverview from "./AnalyticsOverview";
import CustomTable from "../common/CustomTable";
import PieDataChart from "../common/PieDataChart";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useSessionChecker from "../../common/functions/useSessionChecker";

const VideoDashboard = ({ sessionuserid }) => {
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();
  sessionuserid = localStorage.getItem("sessionUserId");
  const [isSpinning, setIsSpinning] = useState(false);

  const [dashboardData, setDashboardData] = useState(null);
  const [dashCityData, setDashCityData] = useState(null);
  const [dashHeatmapData, setDashHeatmapData] = useState(null);
  const [dashBrandData, setDashBrandData] = useState(null);
  const [dashBrowserData, setDashBrowserData] = useState(null);
  const [dashDeviceData, setDashDeviceData] = useState(null);
  const [dashModelData, setDashModelData] = useState(null);
  const [dashOsData, setDashOsData] = useState(null);
  const [deviceKey, setDeviceKey] = useState(null);
  const [browserKey, setBrowserKey] = useState(null);
  const [osKey, setOsKey] = useState(null);
  const [brandKey, setBrandKey] = useState(null);
  const [modalKey, setModalKey] = useState(null);
  const [totalOpenLinks, setTotalOpenLinks] = useState(null);

  const [searchData, setSearchData] = useState(null);
  const [searchCityData, setSearchCityData] = useState(null);
  const [searchHeatmapData, setSearchHeatmapData] = useState(null);
  const [searchBrandData, setSearchBrandData] = useState(null);
  const [searchBrowserData, setSearchBrowserData] = useState(null);
  const [searchDeviceData, setSearchDeviceData] = useState(null);
  const [searchModelData, setSearchModelData] = useState(null);
  const [searchOsData, setSearchOsData] = useState(null);
  const [searchTotalOpenLinks, setSearchTotalOpenLinks] = useState(null);

  const handleSearchResult = (data) => {
    setSearchData(data);
    const pData = JSON.parse(data);
    // console.log(pData, "PPPARSED DATA");
    setSearchCityData(pData[0].TOP5DATA[0].CITY);
    setSearchHeatmapData(pData[0].TOP5DATA[0].HEATMAP);

    setSearchDeviceData(pData[0].TOP5DATA[0].DEVICE);
    setSearchBrandData(pData[0].TOP5DATA[0].BRAND);
    setSearchBrowserData(pData[0].TOP5DATA[0].BROWSER);
    setSearchModelData(pData[0].TOP5DATA[0].MODEL);
    setSearchOsData(pData[0].TOP5DATA[0].OS);
    setSearchTotalOpenLinks(pData[0].LINKREPORT?.TOTALOPENLINKS);
  };

  const { data, isLoading, isError, refetch } = useQuery(
    ["dashboard", sessionuserid],
    () => getDashboardData(sessionuserid, null, "/dashboarddata"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        // debugger;
        // console.log(data, "DASH DATA");
        if (data.status === "-2001") {
          sessionStorage.clear();
          localStorage.clear();
          navigate(`${process.env.REACT_APP_BASE_PATH}/logout`);
          toast.error(data.DESCRIPTION);
        } else if (data.status === "1000") {
          handleUpdateDashboard();

          const dashData = data.data;
          setDashboardData(dashData);
          // console.log(dashData, "DASH DATA");

          const heatmapData = dashData[0].TOP5DATA[0].HEATMAP;
          setDashHeatmapData(heatmapData);
          const cityData = dashData[0].TOP5DATA[0].CITY;
          setDashCityData(cityData);

          const brandData = dashData[0].TOP5DATA[0].BRAND;
          setDashBrandData(brandData);
          const browserData = dashData[0].TOP5DATA[0].BROWSER;
          setDashBrowserData(browserData);
          const deviceData = dashData[0].TOP5DATA[0].DEVICE;
          setDashDeviceData(deviceData);
          const modelData = dashData[0].TOP5DATA[0].MODEL;
          setDashModelData(modelData);
          const osData = dashData[0].TOP5DATA[0].OS;
          setDashOsData(osData);

          const top5Data = dashData[0].TOP5DATA[0];

          const deviceKey = Object.keys(top5Data)[2];
          setDeviceKey(deviceKey);

          const browserKey = Object.keys(top5Data)[3];
          setBrowserKey(browserKey);

          const osKey = Object.keys(top5Data)[4];
          setOsKey(osKey);

          const brandKey = Object.keys(top5Data)[5];
          setBrandKey(brandKey);

          const modalKey = Object.keys(top5Data)[6];
          setModalKey(modalKey);

          const totalOpenLinks = dashData[0]?.LINKREPORT?.TOTALOPENLINKS;
          setTotalOpenLinks(totalOpenLinks);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  // console.log(dashboardData, "DASHBOARD DATA")

  useEffect(() => {
    refetch();
    handleUpdateDashboard();
  }, [refetch]);

  const handleUpdateDashboard = () => {
    setIsSpinning(true);

    setTimeout(() => {
      setIsSpinning(false);
    }, 1200);
  };

  if (isLoading) {
    return (
      <div className="m-auto">
        <span>
          <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
            <Spin tip="Loading..." />
          </div>
        </span>
      </div>
    );
  }

  if (isError) {
    return <div>Error loading data.</div>;
  }

  const customColors1 = ["#FF0000", "#FF597C", "#FF87B9", "#FFDBFF", "#FFB3E6"];
  const labelColor1 = "#000000";

  const customColors2 = ["#CB6CE6", "#915BC5", "#5D489F", "#2E3477", "#001F4E"];
  const labelColor2 = "#FFFFFF";

  const customColors3 = ["#C1FF72", "#57DF8D", "#00B9A1", "#008FA1", "#006588"];
  const labelColor3 = "#000000";

  const customColors4 = ["#E45D56", "#FF914D", "#BB2F5D", "#87095E", "#4B0056"];
  const labelColor4 = "#FFFFFF";

  const customColors5 = ["#000062", "#A2006E", "#62006E", "#FF5757", "#D81665"];
  const labelColor5 = "#FFFFFF";

  return (
    <>
      <HeaderDashboard
        dashboardData={dashboardData}
        dashboardDataStatus={data.status}
        onSearchResult={handleSearchResult}
        updateDashboard={handleUpdateDashboard}
      />
      <AnalyticsOverview
        dashboardData={dashboardData}
        searchData={searchData}
      />
      <div className="p-7 bg-white rounded-tl shadow mt-8">
        <div className="text-left text-neutral-900 text-base font-semibold leading-snug mb-5 select-none">
          Other Records
        </div>
        <div className="flex flex-col md:flex-row gap-10">
          <div className="w-full md:w-full">
            <CustomTable
              header="Top 5 Heatmap Points"
              data={dashHeatmapData}
              searchData={searchHeatmapData}
            />
          </div>
          <div className="w-full md:w-full">
            <CustomTable
              header="Top 5 Cities"
              data={dashCityData}
              searchData={searchCityData}
            />
          </div>
        </div>
        <PieDataChart
          title={browserKey}
          pieData={
            searchBrowserData === null && dashBrowserData
              ? dashBrowserData
              : searchBrowserData
          }
          customColors={customColors2}
          labelColor={labelColor2}
          totalOpenLinks={
            searchTotalOpenLinks === null && totalOpenLinks
              ? totalOpenLinks
              : searchTotalOpenLinks
          }
        />
        <PieDataChart
          title={deviceKey}
          pieData={
            searchDeviceData === null && dashDeviceData
              ? dashDeviceData
              : searchDeviceData
          }
          customColors={customColors1}
          labelColor={labelColor1}
          totalOpenLinks={
            searchTotalOpenLinks === null && totalOpenLinks
              ? totalOpenLinks
              : searchTotalOpenLinks
          }
        />

        <PieDataChart
          title={osKey}
          pieData={
            searchOsData === null && dashOsData ? dashOsData : searchOsData
          }
          customColors={customColors3}
          labelColor={labelColor3}
          totalOpenLinks={
            searchTotalOpenLinks === null && totalOpenLinks
              ? totalOpenLinks
              : searchTotalOpenLinks
          }
        />
        <PieDataChart
          title={brandKey}
          pieData={
            searchBrandData === null && dashBrandData
              ? dashBrandData
              : searchBrandData
          }
          customColors={customColors4}
          labelColor={labelColor4}
          totalOpenLinks={
            searchTotalOpenLinks === null && totalOpenLinks
              ? totalOpenLinks
              : searchTotalOpenLinks
          }
        />
        <PieDataChart
          title={modalKey}
          pieData={
            searchModelData === null && dashModelData
              ? dashModelData
              : searchModelData
          }
          customColors={customColors5}
          labelColor={labelColor5}
          totalOpenLinks={
            searchTotalOpenLinks === null && totalOpenLinks
              ? totalOpenLinks
              : searchTotalOpenLinks
          }
        />
      </div>
      {isSpinning && (
        <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
          <Spin tip="Uploading..." />
        </div>
      )}
    </>
  );
};

export default VideoDashboard;
