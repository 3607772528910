import React, { useEffect, useState } from "react";
import VideoJS from "./VideoJS";
import idCreate from "../plEditor/utils/uuid";
// import videoFile from "./assets/SampleVideo.mp4";
// import VideoPlayer from './VideoPlayer';

const MainVP = () => {
let layers  = window.parent.layers;
  let music = window.parent.music;
  let audio  = window.parent.audio;
  let timeline  = window.parent.timeline;
  let autoplay =  window.parent.autoplay;
  let analyticdata  = window.parent.analyticData;
  let baseUrl =  window.parent.baseurl;
  let templateId = window.parent.templateId;
  let [layerContent,setLayerContent] = useState(layers);
  let musicLoop = window.parent.musicLoop;
  let playbgmusic  =  window.parent.playbgmusic;
  let [musicData,setMusicData] = useState(music);
  let [audioData,setAudioData] = useState(audio);
  let [pauseData,setPauseData] = useState([]);
  let [currIndex,setCurrIndex] = useState(null);
  let [analyticData,setAnalayticData] = useState(analyticdata);
  let [tempId, setTempId] = useState(templateId);
  let id = idCreate();
  // Function is called everytime increment button is clicked
  
  useEffect(()=>{
  
    let data  = [];
    layerContent.forEach((layer)=>{
      // console.log(layer.pausetime);
      if(layer.pausetime!==undefined){
       if(Number.isInteger(layer.pausetime)){
         layer.pausetime =  layer.pausetime+0.1;
       }
        data.push(layer);
      }
    })
    data  =  data.filter((layer)=>{
      return layer.pausetime!==null;
    })
    data  = data.sort((a,b)=>{
      return a.pausetime-b.pausetime;
    });
    // layers =  layers.sort((a,b)=>{
    //   return a.pausetime-b.pausetime;
    // })
    
    setPauseData(data);
    if(data.length!==0){
      setCurrIndex(0);
    }
     console.log(data);
  },[]);
  
  console.log(currIndex,pauseData);
  return (
    // <div style ={{
    //   width : "100vw",
    //   position : "relative",

    // }}>
    // <div style = {{
    //   width : "100%",
    //   maxWidth : "600px",
    //   position : "absolute",
    //   display : "flex",
    //   justifyContent : "center",
    // }}>
    <div
      style={{
        // height: "100vh",
        width : "100%",
        height : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position : "absolute",
      }}
    >
      <VideoJS
        timeline={timeline}
        autoplay = {autoplay}
        musicLoop = {musicLoop}
        playbgmusic = {playbgmusic}
        musicData = {musicData}
        audioData = {audioData}
        analyticData = {analyticData}
        layerContent={layerContent}
        setLayerContent = {setLayerContent}
        pauseData = {pauseData}
        currIndex = {currIndex}
        setCurrIndex = {setCurrIndex}
        baseUrl = {baseUrl}
        id = {id}
        tempId = {tempId}
      />
    </div>
    // </div>
    // </div>
  );
}

export default MainVP