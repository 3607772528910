import React, { useState } from "react";
import { Modal, Select, Input, Button, Upload, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const { Option } = Select;

const UploadAssetModal = ({ closeModal, isModalOpen, onUploadSuccess }) => {
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [imageDimensions, setImageDimensions] = useState({
  //   width: 0,
  //   height: 0,
  // });
  // const [videoDimensions, setVideoDimensions] = useState({
  //   width: 0,
  //   height: 0,
  // });

  const handleFileTypeChange = (value) => {
    setFileType(value);
  };

  const handleFileSelection = (file) => {
    setSelectedFile(file);
    setIsFileSelected(true);
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleUpload = () => {
    const sessionUserId = localStorage.getItem("sessionUserId");
    if (!fileType || !isFileSelected || !fileName) {
      return; // Don't proceed if required data is missing
    }

    const formData = new FormData();
    formData.append("sessionuserid", sessionUserId); // Replace with the actual session user ID
    formData.append("assetstype", fileType);
    // formData.append('assetssize', (selectedFile.size / (1024 * 1024)).toFixed(2));
    formData.append("assetssize", selectedFile.size);
    formData.append("assetsname", fileName);
    formData.append("assetspublishflag", "true"); // You may set this based on your requirements
    formData.append("file", selectedFile);

    const API_BASE_URL = window.baseurl;
    setLoading(true);

    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;
        setUploadProgress(progress);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        setLoading(false);
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.STATUS === "1000") {
            closeModal();
            setUploadProgress(0);
            toast.success(response.DESCRIPTION);
            onUploadSuccess();
          } else {
            toast.error(response.DESCRIPTION);
            setUploadProgress(0);
          }
        } else {
          toast.error("Failed to upload the file.", {});
          setUploadProgress(0);
        }
      }
    };

    xhr.open("POST", `${API_BASE_URL}/uploadcustassets`, true);
    xhr.send(formData);

    if (fileType === "image" && selectedFile.type.startsWith("image")) {
      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);

      // image.onload = () => {
      //   const dimensions = {
      //     width: image.width,
      //     height: image.height,
      //   };

      //   console.log(formData, "DATA ASSETS");
      // };
    } else if (fileType === "video" && selectedFile.type.startsWith("video")) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(selectedFile);
    }
  };

  const beforeUpload = (file) => {
    handleFileSelection(file);

    if (fileType === "image") {
      const image = new Image();
      image.src = URL.createObjectURL(file);
    }

    if (fileType === "video") {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
    }

    return false; // Prevent default upload behavior
  };

  return (
    <>
      <Modal
        className="upload-asset-modal"
        open={isModalOpen}
        onCancel={closeModal}
        // footer={null}
        maskClosable={false}
        width={520}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleUpload}
            disabled={!fileType || !isFileSelected || !fileName || loading}
          >
            Upload
          </Button>,
        ]}
      >
        <h2>Upload Asset</h2>
        <span>Select File Type below</span>
        <Select
          placeholder="Select File Type"
          onChange={handleFileTypeChange}
          value={fileType}
          className="w-full mt-3"
        >
          <Option value="image">Image</Option>
          <Option value="video">Video</Option>
          {/* <Option value="pdf">PDF</Option> */}
          <Option value="audio">Audio</Option>
        </Select>
        {fileType && (
          <div className="mt-4 flex justify-between items-center">
            <Upload
              beforeUpload={beforeUpload}
              showUploadList={false}
              accept={
                fileType === "image"
                  ? "image/*"
                  : fileType === "video"
                  ? "video/*"
                  : fileType === "pdf"
                  ? ".pdf"
                  : fileType === "audio"
                  ? "audio/*"
                  : undefined
              }
            >
              <Button
                className="flex items-center"
                icon={<UploadOutlined className="flex items-center pt-[3px]" />}
              >
                Select File
              </Button>
            </Upload>
          </div>
        )}
        {selectedFile && (fileType === "pdf" || fileType === "audio") && (
          <>
            <div className="mt-2">
              <span>File Size: {selectedFile.size} bytes</span>
            </div>
          </>
        )}
        {isFileSelected && (
          <>
            <div className="my-4">
              <Input
                placeholder="Enter File Name"
                value={fileName}
                onChange={handleFileNameChange}
              />
            </div>
          </>
        )}
        <div className="mt-4">
          {uploadProgress > 0 && (
            <div>
              <p>Uploading... {uploadProgress.toFixed(2)}%</p>
              <Progress
                percent={uploadProgress}
                // status="active"
                showInfo={false}
                style={{ width: "100%" }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "#87d068",
                }}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default UploadAssetModal;
