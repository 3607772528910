import React, { useState, useEffect } from "react";
import "../../common/styles/userModule/ProfileStyles.css";
import { useNavigate } from "react-router-dom";
import {
  validateAlphaNumeric,
  validateEmail,
  validateNumber,
} from "../../common/functions/CommonFunctions";

// Library Import
import { Select, Spin, Tooltip } from "antd";
import { Form, Input } from "antd";
import { PhoneInput } from "react-international-phone";

// Assets Import
import verifyIconGreen from "../../assests/commonIcons/userModuleIcons/verifyIconGreen.svg";
import cancelIconRed from "../../assests/commonIcons/userModuleIcons/cancelIconRed.svg";
import alert_circle from "../../assests/commonIcons/userModuleIcons/alert_circle.svg";

// Component Import
import CloseDialog from "../../common/components/CloseDialog";
import SimpleButton from "../../common/components/SimpleButton";
import GhostButton from "../../common/components/GhostButton";
import OtpModalEmail from "../../common/otp/OtpModalEmail";

// API import
import { updateCustomer } from "../../api/api_updateData";
import { getCustomerInfo } from "../../api/api_FetchData";
import { verifyComEmail } from "../../api/api_updateData";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useSessionChecker from "../../common/functions/useSessionChecker";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
// for responsive
import useWindowWidth from "../../common/hooks/useWindowWidth";

const Profile = ({custregid}) => {
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  const [dialog, setDialog] = useState(false);

  const [userName, setUserName] = useState("");
  const [name, setName] = useState(userName);
  const [editName, setEditName] = useState(true);

  const [industry, setIndustry] = useState("");
  const [role, setRole] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userState, setUserState] = useState("");
  const [userCity, setUserCity] = useState("");
  const [, setShowState] = useState(false);
  const [, setShowCity] = useState(false);

  const [userMobile, setUserMobile] = useState("");
  const [mobile, setMobile] = useState("");
  const [editMobile, setEditMobile] = useState(true);

  const [otpDialog, setotpDialog] = useState(false);
  const [timerActive, setTimerActive] = useState(false);
  const [organization, setOrganization] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [gstIn, setGstIn] = useState("");
  const [taxIn, setTaxIn] = useState("");

  const [commEmail, setCommEmail] = useState("");
  const [email, setEmail] = useState(commEmail);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  const [otpEmail, setOtpEmail] = useState(""); // Add this line
  // const [selectedCountry, setSelectedCountry] = useState(undefined);


// spinner usestate after clicking on save changes
  const [showSpinner, setShowSpinner] = useState(false);

  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;

  // ***************** AUTHENTICATION VIA SESSIONUSERID *****************

  custregid = localStorage.getItem("sessionUserId");
  const sessionuserid = localStorage.getItem("sessionUserId");

  // Check for session ID in localStorage
  useEffect(() => {
    const sessionuserid = localStorage.getItem("sessionUserId");
    if (!sessionuserid) {
      navigate(`${process.env.REACT_APP_BASE_PATH}/login`); // Redirect to login page if session ID is not found
    }
  }, [navigate]);
  // ***************** AUTHENTICATION ENDS ******************

  //**************** Function to initialize state variables with customer data
  const initializeProfileData = (data) => {
    if (data) {
      setIndustry(data.CUSTOMERINDUSTRY || "");
      setRole(data.CUSTOMERPROFILE || "");
      setUserState(data.CUSTOMERSTATE || "");
      setUserCity(data.CUSTOMERCITY || "");
      setZipCode(data.CUSTOMERZIPCODE || "");
      // setSelectedCountry(data.CUSTOMERCOUNTRY || "");
      setUserCountry(data.CUSTOMERCOUNTRY || "");
      setGstIn(data.CUSTOMERGSTIN || "");
      setTaxIn(data.CUSTOMERTAXNO || "");
      setOrganization(data.CUSTOMERCOMPANY || "");
      setUserName(data.CUSTOMERNAME || "");
      setName(data.CUSTOMERNAME || "");
      setAddress(data.CUSTOMERADDRESS || "");
      setMobile(data.CUSTOMERMOBILE || "");
      setUserMobile(data.CUSTOMERMOBILE || "");
      setEmail(data.CUSTOMEREMAIL || "");
    }
    // console.log(data, "P DATA");
  };

  const queryClient = useQueryClient();

  const { data: custData, refetch } = useQuery(
    ["customerinfo", custregid],
    () => getCustomerInfo(null, custregid, "/customer"),
    {
      staleTime: 10 * 60 * 1000,
    }
  );

  useEffect(() => {
    initializeProfileData(
      custData?.data && custData?.data.length > 0 ? custData?.data[0] : null
    );
  }, [custData]);


  const handleIndustrySelect = (value) => {
    setIndustry(value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const checkIsValidEmail = validateEmail(e.target.value);
    setIsEmailValid(checkIsValidEmail);
    if (setIsEmailVerified === setEmail) {
      return setIsEmailVerified(true);
    } else {
      setIsEmailVerified(false);
    }
  };

  const { TextArea } = Input;

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  const clearForm = () => {
    setAddress("");
    setShowCity(false);
    setShowState(false);
    setGstIn("");
  };
  function handlePhone(value) {
    // setMobile(e.target.value);
    setMobile(value);
    console.log(value, "MOBILE");
  }

  function handleTimer(val) {
    setTimerActive(val);
  }

  // FUNCTION TO HANDLE PIN/ZIP VALUES
  const handlePinZipChange = (e) => {
    const value = e.target.value;

    if (validateNumber(value) || value === "") {
      if (value.length <= 10) {
        setZipCode(value);
      }
    }
  };

  // FUNCTION TO HANDLE GST IN NUMBER
  const handleGstInChange = (e) => {
    const value = e.target.value;

    if (validateAlphaNumeric(value) || value === "") {
      if (value.length <= 20) {
        setGstIn(value);
      }
    }
  };

  // FUNCTION TO HANDLE TAX IN NUMBER
  const handleTaxInChange = (e) => {
    const value = e.target.value;

    if (validateAlphaNumeric(value) || value === "") {
      if (value.length <= 20) {
        setTaxIn(value);
      }
    }
  };

  // const refresh = () => window.location.reload(true);

  const handleUpdateProfile = () => {
    // debugger;
    const profileDataUpdate = {
      custregid: sessionuserid,
      custname: name,
      mobile: mobile,
      industry: industry,
      role: role, //custprofile
      organization: organization, //company
      country: userCountry, //country id
      custaddress: address,
      custtaxno: taxIn,
      custgstin: gstIn,
      state: userState,
      city: userCity,
      zipcode: zipCode,
      email: email,
      updatedby: sessionuserid,
    };
    updateCustomer(profileDataUpdate)
      .then(() => {
        queryClient.invalidateQueries("customerInfo");
      })
      .catch((error) => {
        toast.success(error);
      });
    // refresh();
    // show spinner after click on save changes
    setShowSpinner(true);
    setTimeout(()=>{
      setShowSpinner(false);
    },1500)
    // console.log(profileDataUpdate, "P DATA UPDATE");
  };

  // Function to handle the update when the modal is closed
  // useEffect(() => {
  //   if (!otpDialog) {
  //     // Modal is closed, call updateCustomer here
  //     handleUpdateProfile();
  //   }
  // }, [otpDialog]);

  // ADDING AND UPDATING CUSTOMER COM EMAIL
  //******************************* CREATE CUSOTMER STARTS ******************************************
  const { mutate: createValidateEmailOtpMutation } = useMutation(
    (emailData) => verifyComEmail(emailData),
    {
      onSuccess: (data) => {
        // debugger
        if (data.STATUS === "1000") {
          toast.success(data.DESCRIPTION);
        } else {
          toast.error(data.DESCRIPTION);
        }
        refetch();
      },
    }
  );

  //  *************************** CREATE CUSOTMER ENDS ******************************************

  const handleVerifyComEmail = () => {
    // debugger
    const emailData = {
      custregid: sessionuserid,
      email: email,
    };

    // Show spinner when verifying email
    // setShowSpinner(true);
    setOtpEmail(email);
    createValidateEmailOtpMutation(emailData);
    // console.log(emailData, "CUST EMAIL DATA");
  };

  if (showSpinner) {
    return (<>
      <div
          className="overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: "rgb(0, 0, 0,0.5)",
            opacity : 1,
            zIndex: 1000,
            cursor: 'wait',
          }}
        ></div>
        <span className="text-center top-1/2 left-1/2 absolute z-[10000000]"><Spin size="large"/></span>
    </>);
  }

  return (
    <>
      <div
        className="scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full
    scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
      >
        <div>
          <Form layout="vertical">
            <Form.Item
              label="Name"
              name="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
              className="md:ant-d-select text-[#131313] font-medium text-sm mb-8 md:flex"
            >
              <div className="flex items-center input-profile">
                <Input
                  disabled={editName}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="px-3 md:w-[30.75rem] w-[300px] h-10 rounded-[4px]"
                />
                {editName ? (
                  <span
                    onClick={() => setEditName(false)}
                    className="ml-3 text-secondary-blue hover:!text-secondary-blue cursor-pointer hover:underline font-normal hover:font-medium"
                  >
                    Edit
                  </span>
                ) : (
                  <div className="flex ml-5 gap-4">
                    <img
                      src={verifyIconGreen}
                      alt="verify green icon"
                      className="cursor-pointer w-[24px] h-[24px] hover:scale-110"
                      onClick={() => {
                        if (name) {
                          setEditName(true);
                          setUserName(name);
                          handleUpdateProfile();
                        }
                      }}
                    />
                    <img
                      src={cancelIconRed}
                      alt="cancel red icon"
                      className="cursor-pointer w-[24px] h-[24px] hover:scale-110"
                      onClick={() => {
                        setName(userName);
                        setEditName(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item
              name="Mobile number"
              label="Mobile number"
              className="phone-input-profile-form text-[#131313] font-medium text-sm mb-8"
            >
            {/* <div className="mb-8 flex flex-col"> */}
              {/* <div className="text-[#131313] text-sm">Mobile Number</div> */}
              {/* <div className="flex items-center input-profile">
                <div className="ant-input border-[1px] h-10"> */}
              <div className="flex items-center phone-input-profile ">
                <PhoneInput
                  disabled={editMobile}
                  defaultCountry="in"
                  value={mobile}
                  onChange={handlePhone}
                  historySaveDebounceMS={200}
                  forceDialCode={true}
                  className="md:w-[30.75rem]"
                />
                {editMobile ? (
                  <span
                    onClick={() => setEditMobile(false)}
                    className="ml-3 text-secondary-blue hover:!text-secondary-blue cursor-pointer hover:underline font-normal hover:font-medium"
                  >
                    Edit
                  </span>
                ) : (
                  <div className="flex ml-5 gap-4">
                    <img
                      src={verifyIconGreen}
                      alt="verify green icon"
                      className="cursor-pointer w-[24px] h-[24px] hover:scale-110"
                      onClick={() => {
                        if (mobile) {
                          setEditMobile(true);
                          setUserMobile(mobile);
                          handleUpdateProfile();
                        }
                      }}
                    />
                    <img
                      src={cancelIconRed}
                      alt="cancel red icon"
                      className="cursor-pointer w-[24px] h-[24px] hover:scale-110"
                      onClick={() => {
                        setMobile(userMobile);
                        setEditMobile(true);
                      }}
                    />
                  </div>
                )}
                {/* </div>
                </div> */}
              </div>
              {/* </div> */}
            </Form.Item>
            <Form.Item
              label={
                <div className=" md:w-[30.75rem] w-[300px] items-center flex gap-2 ">
                  <div className="font-medium text-sm">Communication Email</div>
                  <Tooltip
                    placement={`${isDesktop ? "rightTop" : "top"}`}
                    title="You will receive all the emails and information such as bills and newsletters"
                  >
                    <img className="" src={alert_circle} alt="info-icon" />
                  </Tooltip>
                </div>
              }
              className="ant-d-select text-[#131313] font-medium text-sm mr-24 mb-8 md:flex"
            >
              <div className="flex items-center ">
                <div className={isLargeScreen ? "flex items-flex" : ""}>
                  <Input
                    onChange={handleEmailChange}
                    value={email}
                    className="border-[1px] px-3  md:w-[30.75rem] w-[300px] h-10 border-solid border-[#D1D1D1] rounded-[4px]"
                  />
                  {!isEmailValid && !isEmailVerified && email && (
                    <h2 className={`text-red-500 flex items-center text-sm mb-0 ml-4 ${isLargeScreen ? "" : "pt-2"}`}>
                      Invalid Email
                    </h2>
                  )}
                  {!isEmailVerified && isEmailValid && (
                    <span
                      onClick={() => {
                        setotpDialog(true);
                        handleTimer(true);
                        // handleVerifyEmail();
                        handleVerifyComEmail();
                      }}
                      className={`ml-3 flex items-center text-secondary-blue hover:!text-secondary-blue cursor-pointer hover:underline font-normal hover:font-medium ${isLargeScreen ? "" : "pt-2"}`}
                    >
                      Click here to Verify
                    </span>
                  )}
                  {isEmailVerified && isEmailValid && email && (
                    <span className={`flex items-center ml-3 ${isLargeScreen ? "" : "pt-2"}`}>
                      <img
                        src={verifyIconGreen}
                        alt="verify green icon"
                        className="w-[20px] h-[20px]"
                      />
                      <p className="text-green-500 flex items-center ml-1 text-sm mb-0 cursor-default">
                        Verified
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </Form.Item>
            {/* <Form.Item
              label={
                <div className="w-[30.75rem] items-center flex gap-2 ">
                  <div className="font-medium text-sm">Communication Email</div>
                  <Tooltip
                    placement="rightTop"
                    title="You will receive all the emails and information such as bills and newsletters"
                  >
                    <img className="" src={alert_circle} alt="info-icon" />
                  </Tooltip>
                </div>
              }
              className="ant-d-select text-[#131313] font-medium text-sm mr-24 mb-8"
            >
              <div>
                <div className="flex  items-center">
                  <Input
                    onChange={handleEmailChange}
                    value={email}
                    className="border-[1px] px-3  w-[30.75rem] h-10 border-solid border-[#D1D1D1] rounded-[4px] "
                  />
                  {!isEmailValid && email && (
                    <h2 className="text-red-500 text-sm mb-0 ml-4">
                      Invalid Email
                    </h2>
                  )}
                  {!isEmailVerified && isEmailValid && (
                    <span
                      onClick={handleVerifyEmail}
                      className="ml-3 text-secondary-blue hover:!text-secondary-blue cursor-pointer hover:underline font-normal hover:font-medium"
                    >
                      Verify Email
                    </span>
                  )}
                  {isEmailVerified && (
                    <span className="flex ml-3">
                      <img
                        src={verifyIconGreen}
                        alt="verify green icon"
                        className="w-[20px] h-[20px]"
                      />
                      <p className="text-green-500 ml-1 text-sm mb-0 cursor-default">
                        Verified
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </Form.Item> */}
          </Form>
        </div>
        <div className="mt-14">
          <div>
            <div className="text-[#323232] font-bold text-base">
              ORGANIZATION DETAILS
            </div>
            <div className="text-[#707070] text-sm mb-8 pt-1">
              Tell us the details of your organization for personalization and
              billing services
            </div>
          </div>

          <Form form={form} layout="vertical" className="org-form">
            <span className="md:flex">
              <Form.Item
                label="Your Industry"
                className="ant-d-select text-[#131313] font-medium text-sm md:mr-24 mb-8"
              >
                <Select
                  className="industry-select"
                  placeholder="Select Indusrry"
                  value={industry}
                  onChange={handleIndustrySelect}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  <Select.Option value="Banking">Banking</Select.Option>
                  <Select.Option value="Insurance">Insurance</Select.Option>
                  <Select.Option value="Hospital">Hospital</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="text-[#131313] font-medium text-sm mb-8"
                label="Organization Name"
              >
                <Input
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                />
              </Form.Item>
            </span>
            <span className="md:flex">
              <Form.Item
                label="Role"
                className="ant-d-select text-[#131313] font-medium text-sm md:mr-24 mb-8"
              >
                <Input
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                />
              </Form.Item>
              {/* <Form.Item
                className="ant-d-select text-[#131313] font-medium text-sm mr-24 mb-8"
                label="Country"
              >
                {countryData && (
                  <Select
                    placeholder="Select a Country"
                    value={selectedCountry}
                    onChange={handleCountrySelect}
                  >
                    {countryData.data?.map((country) => (
                      <Select.Option
                        key={country.COUNTRYID}
                        value={country.COUNTRYID}
                        placeholder="Enter Country"
                      >
                        {country.COUNTRYNAME}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item> */}
              <Form.Item
                label="Country"
                className="text-[#131313] font-medium text-sm md:mr-24 mb-8"
              >
                <Input
                  value={userCountry}
                  onChange={(e) => setUserCountry(e.target.value)}
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                />
              </Form.Item>
            </span>
            <span className="md:flex">
              <Form.Item
                label="State"
                className="text-[#131313] font-medium text-sm md:mr-24 mb-8"
              >
                <Input
                  value={userState}
                  onChange={(e) => setUserState(e.target.value)}
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                />
              </Form.Item>
              <Form.Item
                className="ant-d-select text-[#131313] font-medium text-sm md:mr-24 mb-8"
                label="City"
                placeholder="Enter your city"
              >
                <Input
                  value={userCity}
                  onChange={(e) => setUserCity(e.target.value)}
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                />
              </Form.Item>
            </span>
            <span className="md:flex">
              <Form.Item
                label="ZIP/PIN Code"
                className="zip-input text-[#131313] font-medium text-sm md:mr-24 mb-8"
              >
                <Input
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                  min={4}
                  value={zipCode}
                  onChange={handlePinZipChange}
                  maxLength={10}
                  controls={false}
                />
              </Form.Item>
              <Form.Item
                label="Address"
                className="address-input text-[#131313] font-medium text-sm mb-8"
              >
                <TextArea
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ height: 40, resize: "none", lineHeight: "30px" }}
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px] scrollbar scrollbar-w-[4px] scrollbar-thumb-rounded-full
                scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA]"
                />
              </Form.Item>
            </span>
            <span className="md:flex">
              <Form.Item
                label="GSTIN"
                className="gst-input text-[#131313] font-medium text-sm md:mr-24 mb-8"
              >
                <Input
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                  min={4}
                  value={gstIn}
                  // onChange={(e) => setGstIn(e.target.value)}
                  onChange={handleGstInChange}
                  maxLength={16}
                  controls={true}
                />
              </Form.Item>
              {/* <Form.Item
                name="customInput"
                rules={[{ validator: validateInput }]}
                className="gst-input text-[#131313] font-medium text-sm mr-24 mb-8"
              >
                <Input
                  className="px-3 w-[18rem] h-10 rounded-[4px]"
                  placeholder="Enter GST IN"
                  value={gstIn}
                  onChange={(e) => {handleInputChange(); setGstIn(e.target.value)}}
                />
              </Form.Item> */}
              <Form.Item
                label="TAX NO."
                className="tax-input text-[#131313] font-medium text-sm md:mr-24 mb-8"
              >
                <Input
                  className="px-3 md:w-[18rem] w-[300px] h-10 rounded-[4px]"
                  value={taxIn}
                  onChange={handleTaxInChange}
                  max={20}
                  controls={true}
                />
              </Form.Item>
            </span>
            <span className="flex">
              <Form.Item className="md:mr-28">
                <div className="">
                  <div
                    onClick={() => setDialog(true)}
                    className="flex gap-1 cursor-pointer items-center "
                  >
                    <img
                      className="w-4 h-4"
                      src={alert_circle}
                      alt="circle-icon"
                    />
                    <div className="text-[#707070] text-sm font-normal leading-7">
                      Close this account
                    </div>
                  </div>
                </div>
                {/* <div className="flex justify-end">
                  <GhostButton
                    label="Cancel"
                    htmlType="submit"
                    size="large"
                    ghost="true"
                    block
                    type="link"
                    onClick={clearForm}
                    className="rounded border-none bg-transparent shadow-none text-[#131313] w-fit !px-6 "
                  />
                  <SimpleButton
                    label="Save Changes"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={handleUpdateProfile}
                    className="!rounded-[4px] w-fit !px-6"
                  />
                </div> */}
              </Form.Item>
            </span>
            <span>
              <Form.Item className="md:mr-24">
                <div className="flex justify-end">
                  <GhostButton
                    label="Cancel"
                    htmlType="submit"
                    size="large"
                    ghost="true"
                    block
                    type="link"
                    onClick={clearForm}
                    className="rounded border-none bg-transparent shadow-none text-[#131313] w-fit !px-6 "
                  />
                  <SimpleButton
                    label="Save Changes"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={handleUpdateProfile}
                    className="!rounded-[4px] w-fit !px-6"
                  />
                </div>
              </Form.Item>
            </span>
          </Form>

          <CloseDialog
            title="Are you sure you want to delete this?"
            description=" Your account will be deleted along with all the data. Account
            deletion is irreversible."
            setDialog={setDialog}
            dialog={dialog}
          />
          <OtpModalEmail
            // setDialog={setotpDialog}
            // dialog={otpDialog}
            // email={email}
            // timerActive={timerActive}
            // handleTimer={handleTimer}
            // setIsEmailVerified={setIsEmailVerified}
            // setCommEmail={setCommEmail}
            // setIsEmailValid={setIsEmailValid}
            // handleVerifyComEmail={handleVerifyComEmail}
            setDialog={setotpDialog}
            dialog={otpDialog}
            email={otpEmail} // Pass the email value to the OTP modal
            setOtpEmail={setOtpEmail}
            timerActive={timerActive}
            handleTimer={handleTimer}
            setIsEmailVerified={setIsEmailVerified}
            setCommEmail={setCommEmail}
            setIsEmailValid={setIsEmailValid}
            handleVerifyComEmail={handleVerifyComEmail}
          />
          {/* <SaveChangesDialog saveDialog={saveDialog} setSaveDialog={setSaveDialog}/> */}
        </div>
      </div>
    </>
  );
};

export default Profile;
