import React,{useState} from "react";
import {
  speedValues,
  OutAnimation,
} from "../data.js";
import animationspeed from "../../assets/animationSpeed.svg";
import { Collapse, Divider, Popover, Select, theme } from "antd";
import minus from "../../assets/minus.svg";
import plus from "../../assets/plus.svg";
import "./designDetail.css";
import PopTick from "../../assets/PopTick.svg";
import ExitAnimationContent from "../commonComponents/ExitAnimationContent.js";
const ExitAnimation = ({
  animationExitSpeed,
  setAnimationExitSpeed,
  exitAnimation,
  setExitAnimation,
  lastAnimation,
  setLastAnimation,
}) => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const { Panel } = Collapse;
  const [openSpeed, setOpenSpeed] = useState(false);
  function handleOpenSpeed() {
    setOpenSpeed(!openSpeed);
  }

  return (
    <div
      className="w-full h-[78vh] overflow-auto scrollbar-w-[2px] scrollbar-thumb-rounded-full
    scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] font-sans"
    >
      <div className="flex w-full px-4 pt-2 gap-3  items-center">
        <div className="flex gap-2 pb-1">
          <img
            className="h-[18px] w-[18px]"
            src={animationspeed}
            alt="animation"
          />
          <div className="text-[13px] font-normal">Animation Speed</div>
        </div>
        <div>
          <div className="">
            {/* <Select
              className="action-select !border-[1px] !border-[#2b2b2c] !bg-[#19191a] "
              dropdownClassName = "action-select"
              defaultValue={speedValues[1]}
              style={{ width: 120 }}
              value={animationExitSpeed}
              onChange={(value) => {
                setAnimationExitSpeed(value);
                if (lastAnimation !== undefined) {
                  let newLastAnimation = lastAnimation.split(" ");
                  newLastAnimation.pop();
                  newLastAnimation.push(value);
                  newLastAnimation = newLastAnimation.join(" ");
                  setLastAnimation(newLastAnimation + "");
                } else {
                  setLastAnimation(lastAnimation + " " + value);
                }
              }}
              options={speedValues}
            /> */}
            <Popover
              placement="left"
              arrow={false}
              open={openSpeed}
              onOpenChange={handleOpenSpeed}
              content={
                <div
                  className={`bg-[#1D1D1F] w-[100px] absolute ${
                    animationExitSpeed === "1s"
                      ? "top-[-20px]"
                      : animationExitSpeed === ".5s"
                      ? "top-[-50px]"
                      : "top-[-80px]"
                  } ${animationExitSpeed === ".5s"?"right-[-90px]":"right-[-75px]"}`}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <div
                    className=" overflow-auto  scrollbar-w-[2px] scrollbar-thumb-rounded-full
                     scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-[#EFEFFA] mb-2"
                  >
                    {speedValues.map((item, index) => {
                      return (
                        <div
                          className="flex mt-2 w-full cursor-pointer hover:bg-[#1C68EB]"
                          onClick={() => {
                            setAnimationExitSpeed(item.value);
                            if (lastAnimation !== undefined) {
                              let newLastAnimation = lastAnimation.split(" ");
                              newLastAnimation.pop();
                              newLastAnimation.push(item.value);
                              newLastAnimation = newLastAnimation.join(" ");
                              setLastAnimation(newLastAnimation + "");
                            } else {
                              setLastAnimation(lastAnimation + " " + item.value);
                            }
                            handleOpenSpeed();
                          }}
                        >
                          <div className="px-2 w-[30px] h-[20px]">
                            {animationExitSpeed === item.value && (
                              <img
                                className="w-[100%] h-[100%]"
                                src={PopTick}
                                alt="P"
                              />
                            )}
                          </div>
                          <div className="text-[#ffffff] text-[14px] ">
                            {item.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              }
              trigger="click"
            >
              <div
                className="mx-4 cursor-pointer"
                style={{
                  textAlign: "center",
                  height: "28px",
                }}
              >
                <div className="flex">
                  <div className={`ml-2 text-[14px] mt-[2px]`}>
                    {speedValues[0].value === animationExitSpeed ? speedValues[0].label : speedValues[1].value === animationExitSpeed ? speedValues[1].label : speedValues[2].label}
                  </div>
                  <div className="mt-[7px] ml-4">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="down"
                      width="10px"
                      height="10px"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>

      <Divider className="!m-0 !mt-2 !px-1 border-[#2b2b2c]" />
      <Collapse
      className="editor-collapse"
        expandIconPosition="end"
        style={{
          padding: "15px",
          paddingTop : "0px",
          //   height: "60vh",
          color: "white",
        }}
        bordered={false}
        // defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <img src={isActive ? minus : plus} alt="open" />
        )}
      >
        {OutAnimation.map((item, index) => (
          <Panel
            header={item.name}
            className="mt-4"
            key={index}
            style={panelStyle}
          >
            <ExitAnimationContent
              key={index}
              index={index}
              animationData={item.data}
              exitAnimation={exitAnimation}
              setExitAnimation={setExitAnimation}
              animationExitSpeed={animationExitSpeed}
              setLastAnimation={setLastAnimation}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ExitAnimation;
