import React, { useState } from "react";
import { Divider, Drawer, Popover, Select } from "antd";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import {
  downloadProjectErrorReportApi,
  downloadProjectLinkApi,
  downloadProjectReportApi,
} from "../../../../api/api_updateData.js";
import InputBox from "../../InputBox.js";
import Threedots from "../../../../assests/commonIcons/threedots.svg";
import "../../../styles/templates/myTemplateStyles.css";
import useWindowWidth from "../../../hooks/useWindowWidth.js";

const MyTemplateDrawer = (props) => {
  const sessionuserid = localStorage.getItem("sessionUserId");

  // console.log(props.projectDetailData, "PR DATA");

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [showSuffixIcon, setShowSuffixIcon] = useState(false);
  const [prefixActive, setPrefixActive] = useState(false);
   // for mobile responsive
   const { isMobile, isDesktop, windowWidth } = useWindowWidth();
   const isLargeScreen = windowWidth >= 700;


  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const onClose = () => {
    props.setOpen(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // DOWNLOAD LINKS MUTATION AND API CALLING

  const { mutate: downloadProjectLinkMutation } = useMutation(
    (projectLinkData) => downloadProjectLinkApi(projectLinkData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          const downloadUrl = JSON.parse(data.DATA)[0].url;
          toast.success(data.DESCRIPTION);
          // console.log(data, "DATA");
          window.open(downloadUrl, "_blank");
        } else if (data.STATUS === "-1001") {
          toast.error(data.DESCRIPTION);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  // DOWNLOAD LINK FUNCTION
  const downloadProjectLinkFunction = (projectid) => {
    const projectLinkData = {
      projectid: projectid,
      sessionuserid: sessionuserid,
    };
    downloadProjectLinkMutation(projectLinkData);
  };

  // DOWNLOAD REPORT MUTATION AND API CALLING
  const { mutate: downloadProjectReportMutation } = useMutation(
    (projectLinkData) => downloadProjectReportApi(projectLinkData),
    {
      onSuccess: (data) => {
        console.log(data, "SUC DATA");
        if (data.STATUS === "1000") {
          const downloadUrl = JSON.parse(data.DATA)[0].url;
          toast.success(data.DESCRIPTION);
          console.log(data, "DATA");
          window.open(downloadUrl, "_blank");
        } else if (data.STATUS === "-1001") {
          toast.error(data.DESCRIPTION);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
      onError: (data) => {
        console.log(data, "ERROR DATA");
        if (data.STATUS === "-1001") {
          debugger;
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  // DOWNLOAD REPORT FUNCITON
  const downloadProjectReportFunction = (projectid) => {
    const projectReportData = {
      projectid: projectid,
      sessionuserid: sessionuserid,
    };
    downloadProjectReportMutation(projectReportData);
  };

  // DOWNLOAD ERROR LINKS DATA REPORT MUTATION AND API CALLING
  const { mutate: downloadProjectErrorLinksDataMutation } = useMutation(
    (projectLinkData) => downloadProjectErrorReportApi(projectLinkData),
    {
      onSuccess: (data) => {
        if (data.STATUS === "1000") {
          const downloadUrl = JSON.parse(data.DATA)[0].url;
          toast.success(data.DESCRIPTION);
          window.open(downloadUrl, "_blank");
        } else if (data.STATUS === "-1001") {
          toast.error(data.DESCRIPTION);
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
      onError: (data) => {
        toast.error(data.DESCRIPTION, {
          
        });
      },
    }
  );

  // DOWNLOAD ERROR LINKS REPORT FUNCITON
  const downloadProjectErrorLinksData = (projectid) => {
    const projectReportData = {
      projectid: projectid,
      sessionuserid: sessionuserid,
    };
    downloadProjectErrorLinksDataMutation(projectReportData);
  };

  const projectNewData = props.projectDetailData;
  console.log(projectNewData, "P NEW DATA");

  const totalOpen = projectNewData.reduce((acc, project) => {
    return acc + parseInt(project.TOTALOPEN, 10);
  }, 0);

  const uniqueOpen = projectNewData.reduce((acc, project) => {
    return acc + parseInt(project.UNIQUEOPEN, 10);
  }, 0);

  const totalPlay = projectNewData.reduce((acc, project) => {
    return acc + parseInt(project.TOTALPLAYED, 10);
  }, 0);

  const uniquePlay = projectNewData.reduce((acc, project) => {
    return acc + parseInt(project.UNIQUEPLAYED, 10);
  }, 0);

  const renderProjects = () => {
    const filteredProjects = props.projectDetailData
      ?.filter((project) => {
        // Filter based on search term
        const projectName = project.PROJECTNAME.toLowerCase();
        const projectId = project.PROJECTID.toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();
        return projectName.includes(searchTermLower) || projectId.includes(searchTermLower);
      })
      ?.filter((project) => {
        if (selectedStatus === "All") {
          return true; // Show all projects
        } else if (
          selectedStatus === "Active" &&
          project.PROJECTSTATUSDESC === "Active"
        ) {
          return true; // Show active projects
        } else if (
          selectedStatus === "Inactive" &&
          project.PROJECTSTATUSDESC === "Inactive"
        ) {
          return true; // Show inactive projects
        }
        return false; // Filter out other projects
      });

    if (!filteredProjects || filteredProjects.length === 0) {
      if (searchTerm === "" && selectedStatus === "All") {
        return (
          <div className="pt-8 pb-2 mb-3 summary bg-[#F2F5F8] shadow-none flex flex-col justify-center items-center">
            <span className="text-lg text-black font-medium">
              No Projects Found
            </span>
            <span className="text-[15px] font-normal text-[#343434] mt-2">
              {`No projects match your search criteria.`}
            </span>
          </div>
        );
      } else {
        return (
          <div className="pt-8 pb-2 mb-3 summary bg-[#F2F5F8] shadow-none flex flex-col justify-center items-center">
            <span className="text-lg text-black font-medium">
              No Matching Projects Found
            </span>
            <span className="text-[15px] font-normal text-[#343434] mt-2">
              {`No projects match your search criteria for "${searchTerm}" in "${selectedStatus}".`}
            </span>
          </div>
        );
      }
    }

    return filteredProjects?.map((project, index) => (
      <div className="pt-1 pb-1 mb-3 summary bg-white" key={index}>
        <div className="">
          <div key={index} className="summary bg-white">
            <div className="flex py-2 px-4 justify-between">
              <div className={`${isLargeScreen ? "" : "flex-col"} flex  gap-2`}>
                {project.PROJECTSTATUS === "1000" ? (
                  <div className={`bg-[#15D160] ${isLargeScreen ? "" : "w-max pb-1"} px-2 pt-1  text-[10px] font-medium text-white rounded-xl`}>
                    {project.PROJECTSTATUSDESC}
                  </div>
                ) : (
                  <div className="bg-[#A0A1A1] px-2 pt-1 text-[10px] font-medium text-white rounded-xl">
                    {project.PROJECTSTATUSDESC}
                  </div>
                )}
                <div className="text-sm font-medium">{project.PROJECTNAME}: {project.PROJECTID}</div>
              </div>
              {project.PROJECTSTATUS === "1000" ? (
                <div className="template-popover-user-card">
                  <Popover
                    // open={cloneDialog}
                    // onOpenChange={handleOpenChange}
                    placement="leftTop"
                    content={
                      <div className="flex flex-col justify-start w-[140px] rounded-5 py-[8px] px-[2px]">
                        <span
                          // onClick={hidePopover}
                          onClick={() =>
                            downloadProjectLinkFunction(project.PROJECTID)
                          }
                          className="text-[13px] font-normal pl-[10px] py-[3px] cursor-pointer hover:bg-[#E6F0F6] hover:font-medium hover:rounded-md"
                        >
                          Download Links
                        </span>
                        <span
                          // onClick={handleDeleteDialog}
                          onClick={() =>
                            downloadProjectReportFunction(project.PROJECTID)
                          }
                          className="text-[13px] font-normal pl-[10px] py-[3px] cursor-pointer hover:bg-[#E6F0F6] hover:font-medium hover:rounded-md"
                        >
                          Download Reports
                        </span>
                        <span
                          onClick={() =>
                            downloadProjectErrorLinksData(project.PROJECTID)
                          }
                          className="text-[13px] font-normal pl-[10px] py-[3px] cursor-pointer hover:bg-[#E6F0F6] hover:font-medium hover:rounded-md"
                        >
                          Invalid Report
                        </span>
                      </div>
                    }
                    trigger="click"
                  >
                    <img
                      className="cursor-pointer pt-1"
                      src={Threedots}
                      alt="Threedots"
                    />
                  </Popover>
                </div>
              ) : (
                <div className="template-popover-user-card">
                  <Popover
                    // open={cloneDialog}
                    // onOpenChange={handleOpenChange}
                    placement="leftTop"
                    content={
                      <div className="flex flex-col justify-start w-[140px] rounded-5 py-[8px] px-[2px]">
                        <span className="cursor-not-allowed text-[13px] font-normal pl-[10px] py-[3px] hover:bg-[#E6F0F6] hover:font-medium hover:rounded-md">
                          Download Links (Expired)
                        </span>
                        <span className="text-[13px] font-normal pl-[10px] py-[3px] cursor-pointer hover:bg-[#E6F0F6] hover:font-medium hover:rounded-md">
                          Download Reports
                        </span>
                        <span className="cursor-not-allowed text-[13px] font-normal pl-[10px] py-[3px] hover:bg-[#E6F0F6] hover:font-medium hover:rounded-md">
                          Invalid Report (Expired)
                        </span>
                      </div>
                    }
                    trigger="click"
                  >
                    <img
                      className="cursor-pointer pt-1"
                      src={Threedots}
                      alt="Threedots"
                    />
                  </Popover>
                </div>
              )}

              {/* <div className="flex gap-5 items-center justify-center">
                {project.PROJECTSTATUS === "1000" ? (
                  <div
                    className="flex gap-1 cursor-pointer items-center justify-center"
                    onClick={() =>
                      downloadProjectLinkFunction(project.PROJECTID)
                    }
                  >
                    <img
                      className="h-5 w-5"
                      src={downloadIcon}
                      alt="download"
                    />
                    <div className="text-[#121119] pt-1 text-xs font-normal select-none">
                      Download Links
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-1 items-center justify-center">
                    <img
                      className="h-5 w-5"
                      src={expiredDownload}
                      alt="download"
                    />
                    <div className="text-[#7C7C7C] pt-1 text-xs font-normal select-none">
                      Links Expired
                    </div>
                  </div>
                )}
                <div
                  className="flex gap-1 items-center cursor-pointer justify-center"
                  onClick={() =>
                    downloadProjectReportFunction(project.PROJECTID)
                  }
                >
                  <img className="h-5 w-5" src={report} alt="report" />
                  <div className="text-xs font-normal text-[#121119]">
                    Download Report
                  </div>
                </div>
              </div> */}
            </div>
            <Divider className="!border-[#D9D9D9] border-[1px]" />
            <div className={`px-4 py-2 flex ${isLargeScreen ? "" : "flex-col"}`}>
              <div className="flex-auto">
                <div className="text-[13px] text-[#D74D00] font-normal">
                  Total Links Created
                </div>
                <div className="pt-1 text-sm font-bold text-[#090808]">
                  {project.TOTALLINKSGENERATED}
                </div>
              </div>
              <div className="flex-auto">
                <div className="text-[13px] text-[#7E7E7E] font-normal">
                  Invalid Records
                </div>
                <div className="pt-1 text-sm font-bold text-[#090808]">
                  {project.TOTALERRORSGENERATED}
                </div>
              </div>
              <div className="flex-auto">
                <div className="text-[13px] text-[#4E8335] font-normal">
                  Opened Links
                </div>
                <div className="pt-1 text-sm font-bold text-[#090808]">
                  {project.TOTALOPEN}
                </div>
              </div>
              <div className="flex-auto">
                <div className="text-[13px] text-[#4E8335] font-normal">
                  Unique Open
                </div>
                <div className="pt-1 text-sm font-bold text-[#090808]">
                  {project.UNIQUEOPEN}
                </div>
              </div>
              <div className="flex-auto">
                <div className="text-[13px] text-[#6845A6] font-normal">
                  Total Play
                </div>
                <div className="pt-1 text-sm font-bold text-[#090808]">
                  {project.TOTALPLAYED}
                </div>
              </div>
              <div className="flex-auto">
                <div className="text-[13px] text-[#6845A6] font-normal">
                  Unique Play
                </div>
                <div className="pt-1 text-sm font-bold text-[#090808]">
                  {project.UNIQUEPLAYED}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="">
      <Drawer
        className=""
        placement="right"
        onClose={onClose}
        open={props.open}
        width={isDesktop ? "auto" : isLargeScreen ? 620 : 250}
        // width={"auto"}
      >
        <div>
          <div className={`${isDesktop ? "h-64" : ""}`}>
            <div className={`${isDesktop ? "px-8" : "px-5"} pt-6 pb-6 `}>
              <div className="text-[#2B2B2B] font-normal text-sm">
                TID: <span>{props.templateid}</span>
              </div>
              <div className="text-xl mt-1 font-medium text-[#000]">
                {/* <span>Template Name : </span> */}
                <span>{props.templatename}</span>
              </div>
            </div>
            <div className={`flex ${isDesktop ? "justify-between" : "flex-wrap"}  gap-5 px-8 pb-8`}>
              <div className="bg-[#FDFFE7] template-box text-center px-10 py-7 text-[#101010] w-fit">
                <div className="text-2xl font-bold text-[#101010]">
                  {props.templatetotalprojects}
                </div>
                <div className="text-base font-medium text-[#101010]">
                  Total Projects
                </div>
                <div className="text-[#353535] text-xs font-normal">
                  <span className="font-medium">
                    {props.templateactiveprojects}
                  </span>{" "}
                  Active running projects
                </div>
              </div>
              <div className="bg-[#FFDCC8] template-box text-center px-10 py-7 text-[#101010] w-fit">
                <div className="text-2xl font-bold text-[#101010]">
                  {props.templatetotalgeneratedlink}
                </div>{" "}
                <div className="text-base font-medium text-[#101010]">
                  Links Generated
                </div>
                <div className="text-[#353535] text-xs font-normal">
                  <span className="font-medium">
                    {props.templatetotalgeneratedlink}
                  </span>{" "}
                  Total links generated across projects
                </div>
              </div>
              <div className="bg-[#DBF4CF] template-box text-center px-10 py-7 text-[#101010] w-fit">
                <div className="text-2xl font-bold text-[#101010]">
                  {totalOpen}
                </div>{" "}
                <div className="text-base font-medium text-[#101010]">
                  Opened Links
                </div>
                <div className="text-[#353535] text-xs font-normal">
                  <span className="font-medium">{uniqueOpen}</span> Unique Open
                  Links
                </div>
              </div>

              {/* <div className="bg-[#F3FFEE] template-box text-center px-10 py-7 text-[#101010] w-fit">
              <div className="text-2xl font-bold text-[#101010]">0 </div>{" "}
              <div className="text-base font-medium text-[#101010]">
                Opened Links
              </div>
              <div className="text-[#353535] text-xs font-normal">
                <span className="font-medium">0 </span>Unique Open Links
              </div>
            </div> */}
              <div className="bg-[#DDD1F2] template-box text-center px-10 py-7 text-[#101010] w-fit">
                <div className="text-2xl font-bold text-[#101010]">
                  {totalPlay}
                </div>
                <div className="text-base font-medium text-[#101010]">
                  Total Play
                </div>
                <div className="text-[#353535] text-xs font-normal">
                  <span className="font-medium">{uniquePlay}</span> Unique Play
                  Links
                </div>
              </div>
            </div>
          </div>
          {/* Template summary */}
          <div className="bg-[#F2F5F8] px-8 pb-8">
            <div className={`flex py-6 ${isLargeScreen ? "justify-between" : "flex-col"} `}>
              <div className="text-xl font-medium ">Template Summary</div>
              <div className={`${isLargeScreen ? "flex" : ""}`}>
                <div>
                  <Select
                    defaultValue="All Projects"
                    bordered={false}
                    style={{ width: 120 }}
                    onChange={handleStatusChange}
                    options={[
                      { value: "All", label: "All Projects" },
                      { value: "Active", label: "Active Projects" },
                      { value: "Inactive", label: "Inactive Projects" },
                    ]}
                  />
                </div>
                <div className={`${isDesktop ? "ml-5" : ""}`}>
                  {/* <Input
                onChange={(e) => handleSearch(e.target.value)}
                value={searchTerm}
                placeholder="Search by Project Name..."
                className="w-72 rounded-3xl h-8"
              /> */}
                  <InputBox
                    searchIconClass="px-1 h-[15px] w-[23px]"
                    xIconClass="p-[1px]"
                    className={`h-8 ${isDesktop ? "w-72" : "w-44"} text-[13px] rounded-3xl`}
                    placeholder="Search by Project Name..."
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    onClearClick={() => {
                      setSearchTerm(""); // Clear the input value
                    }}
                    showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                    prefixActive={prefixActive} // Pass prefixActive prop
                    setShowSuffixIcon={setShowSuffixIcon}
                    setPrefixActive={setPrefixActive}
                  />
                </div>
              </div>
            </div>
            {renderProjects()}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MyTemplateDrawer;
