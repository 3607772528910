import React, { useEffect, useState } from "react";
import Threedots from "../assests/commonIcons/brandPageIcons/threedots.svg";
import { Popover, Spin } from "antd";
import noTemplateSvg from "../assests/commonImages/noTemplateSvg.svg";
import { useMutation, useQuery } from "react-query";
import { getAllUserBrandPages } from "../api/api_FetchData";
import { getBrandPageDetail } from "../api/api_updateData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateBrandPageModal from "../common/components/Modal/brand/CreateBrandPageModal";
import useWindowWidth from "../common/hooks/useWindowWidth";
import MobileResModal from "../common/components/Modal/MobileResModal";

const UserBrandCard = ({ custregid }) => {
  custregid = localStorage.getItem("sessionUserId");
  const sessionuserid = localStorage.getItem("sessionUserId");
  const navigate = useNavigate();

  const [brandModal, setBrandModal] = useState(false);
  const [activePopover, setActivePopover] = useState(null);
  const [brandPageid, setBrandPageId] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth >= 700;
  const [mobileResModal, setMobileResModal] = useState(false);

  const {
    data: userBrandData,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["ALLCUSTBRANDPAGES"],
    queryFn: () => getAllUserBrandPages(sessionuserid, custregid, "/brandpage"),
    // staleTime: 1000 * 30, // after 30 sec cached data refresh
  });

  useEffect(() => {
    // Call the refetch function when the component mount
    refetch();
  }, [refetch]);

  // MUTATION FOR EDIT UserBrand
  const { mutate: editUserBrandMutation } = useMutation(
    (getBrandData) => getBrandPageDetail(getBrandData),
    {
      onSuccess: (data) => {
        const parsedData = JSON.parse(data.DATA);
        if (data.STATUS === "1000") {
          // Add SESSIONUSERID to each object in the DATA array
          const newDataArray = parsedData.map((item, index) => ({
            ...item,
            SESSIONUSERID: sessionuserid,
            BRANDPAGETHUMBNAIL: null,
          }));

          /// Create a new object with the updated DATA property
          const updateUserBrandData = {
            ...data,
            DATA: newDataArray,
          };

          // console.log(updateUserBrandData.DATA, "local storage brand data")
          // Store the updated data in localStorage
          localStorage.setItem(
            "updateBrandTemplateData",
            JSON.stringify(updateUserBrandData.DATA)
          );
          navigate(
            `${process.env.REACT_APP_BASE_PATH}/editor/updateBrandTemplate`
          );
        } else {
          toast.error(data.DESCRIPTION);
        }
      },
    }
  );

  const editUserBrand = (brand) => {
    const getBrandData = {
      brandpageid: brand.BRANDPAGEID,
      sessionuserid: sessionuserid,
    };
    editUserBrandMutation(getBrandData);
  };

  const handleOpenChange = (newOpen, brandpageid) => {
    if (newOpen) {
      setActivePopover(brandpageid);
      setBrandPageId(brandpageid);
    } else {
      setActivePopover(null);
    }
  };

  const hidePopover = () => {
    setActivePopover(null);
  };

  const cloneBrandPageModal = () => {
    setBrandModal(true);

    setActivePopover(null);
  };

  const refreshUserBrandCard = () => {
    // Call refetch to reload the data
    setIsSpinning(true);
    refetch();
    setTimeout(() => {
      setIsSpinning(false); // Hide the spinner after 1 second
    }, 1200);
  };

  if (isLoading) {
    return <div>Loading.........</div>;
  }

  if (isError) {
    return <div>Error.......</div>;
  }

  // if (userBrandData.status === "-1001" ) {
  //   return <div>Error fetching data</div>
  // }

  if (userBrandData && userBrandData.status === "1000" && isSuccess) {
    return (
      <div className="flex flex-wrap gap-x-10 gap-y-12">
        {userBrandData &&
          userBrandData.data?.map((brand) => (
            <div
              key={brand.BRANDPAGEID}
              className="w-[210px] h-[102px] bg-[#FFFFFF] rounded-lg  shadow-md"
            >
              <div className="flex justify-between pt-2 px-2">
                {/* --------- */}
                <div className="">
                  <span className="text-[#6d6d6d] text-[10px] font-normal">
                    {brand.BRANDPAGEID}
                  </span>
                  <div className="">
                    <span className="text-[13px] font-medium text-[#000]">
                      {brand.BRANDPAGENAME}
                    </span>
                  </div>
                  <div className="text-[11px] font-normal text-[#555353] pt-1">
                    Last Updated on{" "}
                    <span>{brand.BRANDPAGEUPDATEDON.split(" ")[0]}</span>
                  </div>
                  <div className="flex flex-col text-[11px] font-normal text-[#555353] pt-1">
                    <span>Updated by:</span>
                    <span className="w-[180px] overflow-hidden text-ellipsis">
                      {brand.BRANDPAGEUPDATEDBY}
                    </span>
                  </div>
                </div>
                {/* ----------- */}
                <div className="template-popover-user-card">
                  <Popover
                    placement="rightTop"
                    open={activePopover === brand.BRANDPAGEID}
                    onOpenChange={(newOpen) =>
                      handleOpenChange(newOpen, brand.BRANDPAGEID)
                    }
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    content={
                      <div className="flex flex-col justify-start w-[100px] rounded-5 px-[2px] py-[2px]">
                        <span
                          // onClick={editUserBrand(brand)}
                          onClick={
                            isMobile
                              ? () => {
                                  setMobileResModal(true);
                                }
                              : () => {
                                  editUserBrand(brand);
                                }
                          }
                          className="clone text-[13px] pl-[5px] py-[3px] cursor-pointer  text-left"
                        >
                          Edit
                        </span>
                        <span
                          onClick={cloneBrandPageModal}
                          className="clone text-[13px] pl-[5px] py-[3px] cursor-pointer  text-left"
                        >
                          Clone
                        </span>
                        {/* <span
                          // onClick={hidePopover}
                          className="clone text-[13px] pl-[5px] py-[3px] cursor-pointer text-left"
                        >
                          Remove
                        </span> */}
                      </div>
                    }
                    trigger="click"
                    showArrow={hidePopover}
                  >
                    <img
                      className="cursor-pointer"
                      src={Threedots}
                      alt="Threedots"
                    />
                  </Popover>
                </div>
              </div>
            </div>
          ))}
        {isSpinning && (
          <>
            <div
              className="overlay opacity-50 bg-gray-100"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                // backgroundColor: "rgb(0, 0, 0,0.5)",
                zIndex: 1000,
                cursor: "wait",
              }}
            ></div>
            <span className="text-center top-1/2 left-1/2 absolute z-[10000000] cursor-wait">
              <Spin size="large" />
            </span>
          </>
        )}
        <div>
          {brandModal && (
            <CreateBrandPageModal
              dialog={brandModal}
              setDialog={setBrandModal}
              title={"Start Cloning New Brand Page"}
              brandpageid={brandPageid}
              refreshBrandCard={refreshUserBrandCard}
            />
          )}
          {isMobile && (
          <MobileResModal
            mobileResModal={mobileResModal}
            setMobileResModal={setMobileResModal}
          />
        )}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div
          className={`${
            isDesktop ? "w-[calc(100vw_-_316px)]" : "w-full"
          } flex flex-col items-center pt-12`}
        >
          <span className="text-center flex justify-center">
            <img
              src={noTemplateSvg}
              alt="template svg"
              className={` ${isLargeScreen ? "" : "w-[120px]"} `}
            />
          </span>
          <span
            className={`text-[#4B4B4B] ${
              isLargeScreen ? "text-xl" : "text-[17px]"
            } font-medium pt-4`}
          >
            No Brand Pages found yet!
          </span>
          <span
            className={`text-[#7A7A7A] ${
              isLargeScreen ? "text-[15px] w-[480px]" : "text-[12px]"
            }  font-normal  leading-5 text-center pt-1`}
          >
            It’s time to create your first Brand Page. Choose the Brand Page you
            need from Brand Content Templates or create one yourself from
            scratch. Ready get set goooo!
          </span>
          {/* <span className="pt-4">
                    <SimpleButton
                      type="default"
                      label="Browse Template Library"
                      className="rounded-[4px] bg-transparent border-[#1C68EB] text-[#1C68EB] hover:bg-gradient-to-r from-[#f6f9f6] to-[#F2F7FF] hover:shadow-md hover:shadow-[#155fff14]"
                      onClick={navigateToTemplate}
                    />
                  </span> */}
        </div>
      </>
    );
  }
};

export default UserBrandCard;
