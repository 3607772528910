import React, { useState } from "react";
import "../../Styling/ToolBar.css";
import { assetType } from "../data";
import { Collapse, Divider, Input, theme, Progress, Tooltip, Popover } from "antd";
import { baseVideo } from "../../api/baseVideo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Dots } from "../../assets/verticalDots.svg";
import {
  labelData,
  textData,
  buttonData,
  hotspotData,
  optionData,
} from "../data";
// import {handleUpload} from "../../utils/uploadAsset.js";
import minus from "../../assets/minus.svg";
import plus from "../../assets/plus.svg";
import uploadup from "../../assets/uploadup.svg";
import music from "../../assets/Music.svg";
import vImage from "../../assets/variableImg.svg";
import vVideo from "../../assets/variableVideo.svg";
import musicAdd from "../../assets/AssetsAdd.svg";
import musicRemove from "../../assets/AssetCancel.svg";
import Masonry from "react-masonry-css";
import { toast } from "react-toastify";
import clevertap from "clevertap-web-sdk";
const _ = require("lodash");
function ToolBar(props) {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const panelStyle2 = {
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const { Panel } = Collapse;
  const [globalimageData, setGlobalImageData] = useState([]);
  const [globalvideoData, setGlobalVideoData] = useState([]);
  const [globalaudioData, setGlobalAudioData] = useState([]);
  const [svgData, setSVGData] = useState([]);
  const [svgPath, setSVGPath] = useState("");
  const [progressbar, setProgressbar] = useState(0);
  const [selectedAsset, setSelectedAsset] = useState(0);
  const [openDD, setOpenDD] = useState(false);
  const handleOpenDD = () => {
    setOpenDD(null);
  };
  // const [isMediaUploaded, setIsMediaUploaded] = useState();
  // const [nice, setNice] = useState(false);
  let data = {
    height: 50,
    width: 150,
    backgroundColor: "#1A1A1C",
    textColor: "#ffffff",
    borderColor: "#000000",
    rotate: 0,
    opacity: 1,
    flip: "N",
    textalign: "center",
    textdecoration: "none",
    fontstyle: "Normal",
    fontsize: 18,
    fontfamily: "Times New Roman",
    fontweight: "normal",
    letterspacing: 0,
    lineheight: 1,
    border: 0,
    borderradius: 0,
    layerName: "",
    characterlimit: 10000,
    placeholder: "",
    name: "",
    value: "",
    optionsarray: [],
    animationentryspeed: "1s",
    animationexitspeed: "1s",
    entryanimation: "",
    exitanimation: "",
    starttime: 0,
    stoptime: 1,
    pausetoggle: false,
    resumetoggle: false,
    resumein: null,
    resumeto: null,
    pausetime: null,
    seekto: null,
    draggable: true,
    visibility: true,
    action: 0,
    actionsarray: [],
  };
  function changeBaseVideo(assetkind, assetname){
    let templateData = {
      tempID: props.templateInfo[0]?.TEMPLATEID,
      sessionuserid: props.templateInfo[0]?.SESSIONUSERID,
      assetID : assetname,
      assetType : assetkind
    };
    baseVideo(templateData)
    .then((data) => {
      if (data.STATUS === "1000") {
        
      }
    })
    .catch((error) => {
      toast.dismiss();
      toast.error(error, {
        theme: "dark",
      });
    }).finally(() => {
      handleOpenDD();
    });
  }
  function handleDragStart(event, item) {
    console.log(event);
    console.log(item.size);

    data = {
      ...data,
      // id  : props.handleId(),
      // layername : item.type.toUpperCase() +"_"+ props.id,
      fontsize: item.size === undefined ? data.fontsize : item.size,
      fontweight:
        item.fontWeight === undefined ? data.fontweight : item.fontWeight,
      placeholder: item.placeholder,
      name: item.name,
      fontstyle: item.fontStyle === undefined ? data.fontstyle : item.fontStyle,
      border: item.border === undefined ? data.border : item.border,
      borderColor:
        item.borderColor === undefined ? data.borderColor : item.borderColor,
      textColor: item.color === undefined ? data.textColor : item.color,
      backgroundColor:
        item.backgroundColor === undefined
          ? data.backgroundColor
          : item.backgroundColor,
      borderradius:
        item.borderRadius === undefined ? data.borderradius : item.borderRadius,
      tlborderradius:
        item.borderRadius === undefined ? data.borderradius : item.borderRadius,
      trborderradius:
        item.borderRadius === undefined ? data.borderradius : item.borderRadius,
      blborderradius:
        item.borderRadius === undefined ? data.borderradius : item.borderRadius,
      brborderradius:
        item.borderRadius === undefined ? data.borderradius : item.borderRadius,
      width: item.width === undefined ? data.width : item.width,
      type: item.type,
      height:
        item.height === undefined
          ? item.type === "dropdown"
            ? 35
            : data.height
          : item.height,
      subtype: item.subtype,
      icon: item.icon,
      // zindex: props.handleZ(),
      // id : props.handleId(),
      payload:
        item.svgPath !== undefined
          ? item.svgPath
          : item.subtype === "globalasset"
          ? `${item.ASSETSBASEPATH}/${
              item.ASSETSSYSTEMFILENAME
            }?t=${Date.now()}`
          : props.pathName === "createTemplate"
          ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${
              props.templateInfo[0]?.TEMPLATEID
            }/CONTENT/${item.name}?t=${Date.now()}`
          : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${
              props.templateInfo[0]?.TEMPLATEID
            }/CONTENT/${item.name}?t=${Date.now()}`,
      // item.path + "/" + item.name,

      defaultchecked: item.type === "video" || item.type==="vVideo"?true: false,
      checked: false,
    };
    let optimizedData =
      // data;
      optimizeData();
    console.log(event.nativeEvent);
    props.handleDropX(event.nativeEvent.offsetX);
    props.handleDropY(event.nativeEvent.offsetY);
    event.dataTransfer.setData("text/plain", JSON.stringify(optimizedData));
    // event.preventDefault();
  }
  function optimizeData() {
    if (data.type === "label" || data.type === "button") {
      let result = _.omit(data, [
        "characterlimit",
        "optionsarray",
        "defaultchecked",
        "checked",
      ]);
      if (data.type === "button") {
        result = {
          ...result,
          name: data.placeholder.substring(1),
        };
      }
      return result;
    } else if (data.type === "text" || data.type === "textarea") {
      let result = _.omit(data, ["optionsarray", "defaultchecked", "checked"]);
      result = {
        ...result,
        cltoggle: false,
        emtoggle: false,
        errormessage: "",
      };
      return result;
    } else if (data.type === "hotspot") {
      let result = _.omit(data, [
        "animationentryspeed",
        "animationexitspeed",
        "entryanimation",
        "exitanimation",
        "flip",
        "fontfamily",
        "fontsize",
        "fontstyle",
        "fontweight",
        "letterspacing",
        "lineheight",
        "textColor",
        "textalign",
        "textdecoration",
        "characterlimit",
        "optionsarray",
        "defaultchecked",
        "checked",
      ]);
      return result;
    } else if (
      data.type === "image" ||
      data.type === "vimage" ||
      data.type === "svg"
    ) {
      let result = _.omit(data, [
        "fontfamily",
        "fontsize",
        "fontstyle",
        "fontweight",
        "letterspacing",
        "lineheight",
        "textColor",
        "textalign",
        "textdecoration",
        "characterlimit",
        "optionsarray",
        "defaultchecked",
        "checked",
      ]);
      result =
        data.type === "svg"
          ? { ...result }
          : { ...result, backgroundColor: "transparent" };
      return result;
    } else if (data.type === "video" || data.type === "vVideo") {
      let result = _.omit(data, [
        "backgroundColor",
        "fontfamily",
        "fontsize",
        "fontstyle",
        "fontweight",
        "letterspacing",
        "lineheight",
        "textColor",
        "textalign",
        "textdecoration",
        "characterlimit",
        "optionsarray",
        // "defaultchecked",
        "checked",
      ]);
      result = { ...result, backgroundColor: "transparent" };
      return result;
    } else if (data.type === "radio" || data.type === "checkbox") {
      let result = _.omit(data, [
        "flip",
        "fontfamily",
        "fontsize",
        "fontstyle",
        "fontweight",
        "letterspacing",
        "lineheight",
        "textColor",
        "borderColor",
        "textalign",
        "textdecoration",
        "characterlimit",
        "optionsarray",
      ]);
      result = {
        ...result,
        emtoggle: false,
        errormessage: "",
      };
      return result;
    } else if (data.type === "dropdown") {
      let result = _.omit(data, [
        "characterlimit",
        "defaultchecked",
        "checked",
      ]);
      result = {
        ...result,
        emtoggle: false,
        errormessage: "",
      };
      return result;
    }
  }
  const handleUpload = async (e, type) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (type === "image") {
      if (!file.type.startsWith("image/")) {
        toast.error("Not an Image File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    } else if (type === "video") {
      if (!file.type.startsWith("video/")) {
        toast.error("Not a video File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    } else if (type === "audio") {
      if (!file.type.startsWith("audio/")) {
        toast.error("Not an audio File", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("templateid", props.templateInfo[0]?.TEMPLATEID);
    const pathSegments = window.location.pathname.split("/");
    const urlString = pathSegments[pathSegments.length - 1];
    let action = urlString === "createTemplate" ? "temp" : "live";
    formData.append("action", action);
    formData.append("uploadtype", "content");

    // FETCHING API_BASE_URL FROM INDEX.HTML
    const API_BASE_URL = window.baseurl;

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;
        setProgressbar(progress);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.STATUS === "1000") {
            let receivedData = JSON.parse(response.DATA);
            console.log("Image/Video", receivedData);
            let obj = {
              name: receivedData[0].FILENAME,
              //path: receivedData[0].FILEPATH,
            };

            if (type === "image") {
              let arr = [...props.imageData];
              arr.push({ ...obj });
              props.setImageData([...arr]);
            } else if (type === "video") {
              let arr = [...props.videoData];
              arr.push({ ...obj });
              props.setVideoData([...arr]);
            } else if (type === "audio") {
              let arr = [...props.musicData];
              arr.push({
                ...obj,
                displayName: `Audio ${props.musicData.length}`,
                checked: false,
              });
              props.setMusicData([...arr]);
            }
            toast.success(response.DESCRIPTION, {
              theme: "dark",
            });
            Media_Upload_Fun(type, "YES");
            // Call your onUploadVideo() function here if needed.

            setProgressbar(0);
            // setThumbnailFile(selectedFile);
          } else {
            toast.dismiss();
            toast.error(response.DESCRIPTION, {
              theme: "dark",
            });
            Media_Upload_Fun(type, "NO");
            setProgressbar(0);
          }
        } else {
          toast.dismiss();
          toast.error("Failed to upload the file.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
          Media_Upload_Fun(type, "NO");
          setProgressbar(0);
        }
      }
    };
    xhr.onerror = () => {
      console.error("Error during upload");
      toast.dismiss();
      toast.error("Upload failed", {
        theme: "dark",
      });
      Media_Upload_Fun(type, "NO");
      setProgressbar(0);
    };
    // xhr.open("POST", "http://180.151.195.62:8070/mconnectsaas/upload?", true);
    xhr.open("POST", `${API_BASE_URL}/upload`, true);
    xhr.send(formData);
  };

  const handlePanelOpen = async (type) => {
    // FETCHING API_BASE_URL FROM INDEX.HTML
    const API_BASE_URL = window.baseurl;

    try {
      // debugger
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionuserid: props.templateInfo[0]?.SESSIONUSERID,
          assetstype: type,
        }),
      };

      const svgOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionuserid: props.templateInfo[0]?.SESSIONUSERID,
          assetstype: type,
          action: "GETEDITORASSETSDATA",
        }),
      };

      const response = await fetch(
        type === "svg"
          ? `${API_BASE_URL}/editorassets`
          : `${API_BASE_URL}/getcustassets`,
        type === "svg" ? svgOptions : requestOptions
      );
      // const svgresponse = await fetch(
      //   `${API_BASE_URL}/editorassets`,
      //   svgOptions
      // );

      // const data3 = await fetch("https://vmediadatav3.s3.ap-south-1.amazonaws.com/EDITORASSETS/109.svg")
      // .then(response => response.text())
      // .then(data => {
      //   console.log("SVG",data);
      // })
      // .catch(error => console.error('Error fetching SVG:', error));

      if (!response.ok) {
        toast.dismiss();
        toast.error("Connection Problem", {
          theme: "dark",
        });
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // const svgdata  =  await svgresponse.json();
      console.log(data, "Response Data");
      // console.log("SVG",svgdata);
      if (data.STATUS === "1000") {
        let assetData = JSON.parse(data.DATA);
        console.log(typeof JSON.parse(data.DATA));
        if (type === "image") {
          setGlobalImageData([...assetData]);
        } else if (type === "video") {
          setGlobalVideoData([...assetData]);
        } else if (type === "audio") {
          setGlobalAudioData(assetData.map((item,index) => {
            return { ...item, checked: false , name : `GAudio${index}` };
          }).map(item1 => {
            // Check if item1 exists in array2
            const correspondingItem = props.globalmusicData.find(item2 => item2.ASSETSID === item1.ASSETSID); // Assuming there's an 'id' property to compare
            if (correspondingItem) {
                // If the corresponding item exists in array2, set the 'checked' property of item1 accordingly
                return { ...item1, checked: correspondingItem.checked };
            } else {
                // If the corresponding item doesn't exist in array2, keep the 'checked' property unchanged or set it to false (or whatever default value you prefer)
                return item1;
            }
        }));
        } else if (type === "svg") {
          setSVGData(JSON.parse(data.DATA).SVGDATA);
          setSVGPath(JSON.parse(data.DATA).SVGPATH);
        }
        return data;
      } else {
        // toast.dismiss();
        // toast.error(data.DESCRIPTION, {
        //   theme: "dark",
        // });
        // loader(false);
        return data;
      }
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(error.message, {
        theme: "dark",
      });
      // loader(false);
      // throw new Error(error.message);
    }
  };

  console.log("Boy", typeof globalimageData, globalimageData);
  console.log("alert", globalaudioData);

  // CLEVERTAP

  const panelClick = (item) => {
    console.log(item, "PANEL ITEM");
  };
  console.log(props.selectedSidebarItem, "ITEM");

  // CLEVERTAP EVENTS

  const userAgent = navigator.userAgent;

  const Media_Upload_Fun = (type, isMediaUploaded) => {
    clevertap.event.push("Media_Upload", {
      USER_ID: props.userId,
      Email: props.userId,
      TemplateID: props.templateId,
      TemplateName: props.templateName,
      MediaCategory: type,
      MediaUploaded: isMediaUploaded,
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    console.log("Media_Upload EVENT CLICKED");
  };

  return (
    <div className="bg-[#131317] relative">
      {!props.isRight && (
        <div className="h-full w-full z-10 absolute top-0 left-0"></div>
      )}
      <div
        className="bg-[#131317] w-[240px] h-full overflow-auto font-sans"
        onClick={() => {
          props.setRightClicked("");
        }}
        style={{
          userSelect: "none",
        }}
      >
        <div className="bg-[#0b0b0c] py-5 border-solid border-0 border-b-2  border-b-[#2B2B2C] flex justify-center">
          <Input
            placeholder="Search"
            className="h-7 w-48 editor-input !font-sans !rounded-3xl"
            disabled={props.isModalOpen}
            value={props.toolSearch}
            onClick={() => {
              // props.setFieldEnabled(true);
              props.fieldEnabled.current = true;
            }}
            onBlur={() => {
              // props.setFieldEnabled(false);
              props.fieldEnabled.current = false;
            }}
            onChange={(e) => {
              props.setToolSearch(e.target.value);
            }}
          />
        </div>
        {progressbar > 0 && (
          <Progress
            className="progressbar"
            percent={progressbar}
            showInfo={false}
            status="active"
            style={{
              width: "100%",
              height: "4px !important",
              marginInline: "0px",
            }}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
          />
        )}
        <div className="flex text-[#F0F0F0] flex-col">
          {props.selectedSidebarItem === 0 &&
            labelData
              .filter((item, index) => {
                if (props.toolSearch.trim() === "") {
                  return true;
                } else {
                  return item.name
                    .toLowerCase()
                    .includes(props.toolSearch.toLowerCase());
                }
              })
              .map((item, index) => (
                <Tooltip
                  className=""
                  key={index + "Toollabel"}
                  trigger="click"
                  title="Drag Element to main Canvas"
                  color="#2B2B2C"
                  placement="right"
                  overlayStyle={{
                    boxShadow: "2px",
                    height: "30px",
                    width: "120px",
                  }}
                >
                  <div key={index + "label"}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className={`w-[150px] h-[60px]`}
                        style={{
                          fontSize: item?.size + "px",
                          fontWeight: item?.fontWeight,
                          fontStyle: item?.fontStyle,
                          cursor: "move",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          opacity: 1,
                          visibility: "visible",
                        }}
                        draggable={props.play}
                        onDragStart={(event) => {
                          handleDragStart(event, item);
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                    <Divider className=" !m-0" />
                  </div>
                </Tooltip>
              ))}
          {props.selectedSidebarItem === 1 &&
            textData
              .filter((item, index) => {
                if (props.toolSearch.trim() === "") {
                  return true;
                } else {
                  return item.placeholder
                    .toLowerCase()
                    .includes(props.toolSearch.toLowerCase());
                }
              })
              .map((item, index) => (
                <Tooltip
                  key={index + "TooltextData"}
                  trigger="click"
                  title="Drag Element to main Canvas"
                  color="#2B2B2C"
                  placement="right"
                  overlayStyle={{
                    boxShadow: "2px",
                    height: "30px",
                    width: "120px",
                  }}
                >
                  <div key={index + "textData"}>
                    <div className="flex ">
                      <div
                        className={` w-[183px] h-[42px] rounded border-solid border-t-0 border-l-0 border-b-0 border-r-2 !border-[#00D8BE] text-left bg-[#2B2B2B] ml-7 mt-2`}
                        style={{
                          fontSize: item.size,
                          fontWeight: item.fontWeight,
                          fontStyle: item.fontStyle,
                          cursor: "move",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        draggable={props.play}
                        onDragStart={(event) => {
                          handleDragStart(event, item);
                        }}
                      >
                        {item.placeholder}
                      </div>
                    </div>
                  </div>
                </Tooltip>
              ))}
          {props.selectedSidebarItem === 2 && (
            <div className="mt-3">
              <div className="flex-col grid grid-rows-2 ">
                {hotspotData
                  .filter((item, index) => {
                    if (props.toolSearch.trim() === "") {
                      return true;
                    } else {
                      return item.name
                        .toLowerCase()
                        .includes(props.toolSearch.toLowerCase());
                    }
                  })
                  .map((item, index) => (
                    <div key={index + "Hot"}>
                      <div className="grid mx-5">
                        <Tooltip
                          className=""
                          trigger="click"
                          title="Drag Element to main Canvas"
                          color="#2B2B2C"
                          placement="right"
                          overlayStyle={{
                            boxShadow: "2px",
                            height: "30px",
                            width: "120px",
                          }}
                        >
                          <div
                            // className={`py-3 w-[150px] h-[60px]`}
                            className={` align-middle w-[150px] h-[60px] cursor-move`}
                            style={{
                              height: item.height,
                              width: item.width,
                              backgroundColor: "#2b2b2c",
                              borderRadius: "10px",
                              color: "#000000",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            draggable={props.play}
                            onDragStart={(event) => {
                              handleDragStart(event, item);
                            }}
                          >
                            <div className="flex align-middle">
                              <img src={item.img} alt="img" />
                            </div>
                          </div>
                        </Tooltip>
                        <div className=" mt-1 mb-2 text-left">
                          <div className=" mt-1 mb-1 text-[#dddddd] font-medium text-[14px]">
                            {item.name}
                          </div>
                          <div className=" text-[12px] text-[#BABABA] !leading-snug">
                            {item.text}
                          </div>
                        </div>
                      </div>
                      <Divider className="!mt-1 !mb-3 " />
                    </div>
                  ))}
              </div>
            </div>
          )}
          {props.selectedSidebarItem === 3 && (
            <div>
              <div className="flex pl-[15px] mt-2">
                {assetType.map((item, index) => (
                  <div
                    onClick={() => setSelectedAsset(index)}
                    className={`${
                      selectedAsset === index
                        ? "text-sm text-[#38c3ff] font-semibold border-solid border-t-0 border-l-0 border-r-0 !border-b-1 z-10 pb-1 !border-b-[#38c3ff] cursor-pointer"
                        : "text-[#DEDEDE]  text-sm font-normal pb-1 cursor-pointer"
                    } mr-4`}
                    key={index + "asset"}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <Collapse
                accordion
                className="editor-collapse"
                expandIconPosition="end"
                style={{
                  padding: "15px",
                  background: "#131317",
                  color: "white",
                }}
                bordered={false}
                onChange={(activeKey) => {
                  if (globalimageData.length === 0 && activeKey.includes("1")) {
                    handlePanelOpen("image");
                  } else if (
                    globalvideoData.length === 0 &&
                    activeKey.includes("2")
                  ) {
                    handlePanelOpen("video");
                  }else if (
                    globalaudioData.length === 0 &&
                    activeKey.includes("3")
                  ) {
                    handlePanelOpen("audio");
                  } else if (svgData.length === 0 && activeKey.includes("4")) {
                    handlePanelOpen("svg");
                  }
                }}
              >
                <Panel className="" header="Images" key={1} style={panelStyle}>
                  {/* <div className="flex flex-wrap justify-evenly mt-4 h-[200px] overflow-auto"> */}
                  <div
                    className={`${
                      selectedAsset === 0 ? "h-[400px]" : "h-[400px]"
                    } mt-1`}
                  >
                    {selectedAsset === 0 && (
                      <div
                        style={{
                          marginTop: "4px",
                          marginBottom: "4px",
                          display: "flex",
                        }}
                      >
                        <div className="">
                          <div className="mb-1 mt-2 text-[13px] text-[#BABABA] text-left">
                            Browse & upload image
                          </div>
                        </div>
                        <div className="ml-[16px]">
                          <input
                            type="file"
                            accept="image/jpeg, image/png, image/gif"
                            id="upload-file1"
                            onChange={(e) => {
                              if (progressbar === 0) {
                                handleUpload(e, "image");
                              }
                            }}
                          />
                          <label for="upload-file1" className="upload-label">
                            <img
                              className="h-5 mt-2 ml-[37.5px]"
                              src={uploadup}
                              alt="upload"
                            />
                          </label>
                        </div>
                      </div>
                    )}
                    {selectedAsset === 0 && <Divider />}

                    {selectedAsset === 0 && (
                      <div className="">
                        <div className="flex">
                          <div className="">
                            <div className="font-medium">
                              Add Variable Image
                            </div>
                            <div className="w-[100%] text-[#BABABA] text-[10px] mt-2">
                              Add personalized image
                            </div>
                          </div>
                          <div className="ml-2 mt-2">
                            <img
                              src={vImage}
                              alt="I"
                              style={{
                                height: "45px",
                                width: "45px",
                                cursor: "move",
                                marginLeft: "15px",
                              }}
                              draggable={props.play}
                              onDragStart={(event) => {
                                handleDragStart(event, {
                                  height: 40,
                                  width: 40,
                                  type: "vimage",
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Divider />
                    <div
                      className={`${
                        selectedAsset === 0 ? "max-h-[310px]" : "max-h-[400px]"
                      } flex flex-wrap justify-evenly overflow-auto`}
                    >
                      {selectedAsset === 0 && (
                        <div className="">
                          {/* <ResponsiveMasonry columnsCountBreakPoints={{ 20 : 2 }}>
                        <Masonry> */}
                          <Masonry
                            breakpointCols={{ default: 2, 1100: 2, 700: 1 }}
                            className="my-masonry-grid "
                            draggable
                            columnClassName="my-masonry-grid_column"
                          >
                            {props.imageData.map((item, index) => (
                              <div
                                // className={`py-1 w-[100px]`}
                                id={index + "img"}
                                key={index}
                                loading="lazy"
                                style={{
                                  cursor: "move",
                                  width: "100%",
                                  backgroundColor: `${
                                    index % 5 === 0
                                      ? "#515D45"
                                      : index % 5 === 1
                                      ? "#5A7359"
                                      : index % 5 === 2
                                      ? "#618A71"
                                      : index % 5 === 3
                                      ? "#454834"
                                      : "#2A4C4E"
                                  }`,
                                }}
                                draggable={props.play}
                                onDragStart={(event) => {
                                  // event.preventDefault();
                                  const img = new Image();
                                  img.src =
                                    props.pathName === "createTemplate"
                                      ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                      : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`;

                                  var image = document.getElementById(
                                    index + "img"
                                  ).querySelector("img");

                                  // Get the rendered size
                                  var rect = image.getBoundingClientRect();

                                  img.style.width = "100%";
                                  img.style.height = "100%";
                                  img.style.opacity = "1";

                                  const dragPreview =
                                    document.createElement("div");

                                  dragPreview.style.width = rect.width + "px";
                                  dragPreview.style.height = rect.height + "px";
                                  dragPreview.style.border = "2px solid green";
                                  dragPreview.style.position = "absolute";
                                  dragPreview.style.backgroundColor =
                                    "#F2FFE980";
                                  dragPreview.style.borderImage =
                                    "linear-gradient(to bottom, #00DBDE, #FC00FE)  1 / 1 / 0 stretch";
                                  document.body.appendChild(dragPreview);
                                  dragPreview.appendChild(img);

                                  event.dataTransfer.setDragImage(
                                    dragPreview,
                                    0,
                                    0
                                  );

                                  handleDragStart(event, {
                                    ...item,
                                    width: rect.width,
                                    height: rect.height,
                                    type: "image",
                                  });
                                  setTimeout(() => {
                                    document.body.removeChild(dragPreview);
                                  }, 0);
                                }}
                                onClick={() => {
                                  if (props.itemSelected !== null) {
                                    let index = props.canvaContent.findIndex(
                                      function (obj) {
                                        return obj.id === props.itemSelected;
                                      }
                                    );

                                    if (
                                      props.canvaContent[index].type === "image"
                                    ) {
                                      let asset =
                                        props.pathName === "createTemplate"
                                          ? `${
                                              props.templateInfo[0]
                                                ?.TEMPLATEBASEPATH
                                            }TEMP/${
                                              props.templateInfo[0]?.TEMPLATEID
                                            }/CONTENT/${
                                              item.name
                                            }?t=${Date.now()}`
                                          : `${
                                              props.templateInfo[0]
                                                ?.TEMPLATEBASEPATH
                                            }TEMPLATE/${
                                              props.templateInfo[0]?.TEMPLATEID
                                            }/CONTENT/${
                                              item.name
                                            }?t=${Date.now()}`;

                                      let loader = document.getElementById(
                                        `loader${props.itemSelected}`
                                      );
                                      loader.style.display = "grid";
                                      props.setPayload(asset);
                                    }
                                  }
                                }}
                              >
                                {/* <img
                                  className="max-w-full"
                                  onLoad={() => {
                                    var img = new Image();
                                    img.src =
                                      props.pathName === "createTemplate"
                                        ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                        : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`;

                                    console.log(
                                      "INW",
                                      img.naturalWidth / img.naturalHeight
                                    );
                                  }}
                                  src={
                                    props.pathName === "createTemplate"
                                      ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                      : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                  }
                                  alt="img"
                                /> */}
                                <LazyLoadImage
                                  width="100%"
                                  effect="blur"
                                  // onLoad={() => {
                                  //   var img = new Image();
                                  //   img.src =
                                  //     props.pathName === "createTemplate"
                                  //       ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                  //       : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`;

                                  //   console.log(
                                  //     "INW",
                                  //     img.naturalWidth / img.naturalHeight
                                  //   );
                                  // }}
                                  src={
                                    props.pathName === "createTemplate"
                                      ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                      : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                  }
                                />
                              </div>
                            ))}
                          </Masonry>
                          {/* </Masonry>
                      </ResponsiveMasonry> */}
                        </div>
                      )}
                      {selectedAsset === 1 && (
                        <div className="">
                          <Masonry
                            breakpointCols={{ default: 2, 1100: 2, 700: 1 }}
                            className="my-masonry-grid "
                            draggable
                            columnClassName="my-masonry-grid_column"
                          >
                            {globalimageData.map((item, index) => (
                              <div
                                // className={`py-1 w-[100px]`}
                                id={index + "gimg"}
                                key={index}
                                // loading = "lazy"
                                style={{
                                  cursor: "move",
                                  width: "100%",
                                  backgroundColor: `${
                                    index % 5 === 0
                                      ? "#515D45"
                                      : index % 5 === 1
                                      ? "#5A7359"
                                      : index % 5 === 2
                                      ? "#618A71"
                                      : index % 5 === 3
                                      ? "#454834"
                                      : "#2A4C4E"
                                  }`,
                                }}
                                draggable={props.play}
                                onClick={() => {
                                  if (props.itemSelected !== null) {
                                    let index = props.canvaContent.findIndex(
                                      function (obj) {
                                        return obj.id === props.itemSelected;
                                      }
                                    );
                                    if (
                                      props.canvaContent[index].type === "image"
                                    ) {
                                      let asset = `${item.ASSETSBASEPATH}/${
                                        item.ASSETSSYSTEMFILENAME
                                      }?t=${Date.now()}`;

                                      let loader = document.getElementById(
                                        `loader${props.itemSelected}`
                                      );
                                      loader.style.display = "grid";
                                      props.setPayload(asset);
                                    }
                                  }
                                }}
                                onDragStart={(event) => {
                                  var img = new Image();
                                  img.src = `${item.ASSETSBASEPATH}/${item.ASSETSSYSTEMFILENAME}`;
                                  var image = document.getElementById(
                                    index + "gimg"
                                  );

                                  // Get the rendered size
                                  var rect = image.getBoundingClientRect();

                                  img.style.width = "100%";
                                  img.style.height = "100%";
                                  img.style.opacity = "1";

                                  const dragPreview =
                                    document.createElement("div");

                                  dragPreview.style.width = rect.width + "px";
                                  dragPreview.style.height = rect.height + "px";
                                  dragPreview.style.border = "2px solid green";
                                  dragPreview.style.position = "absolute";
                                  dragPreview.style.backgroundColor =
                                    "#F2FFE980";
                                  dragPreview.style.borderImage =
                                    "linear-gradient(to bottom, #00DBDE, #FC00FE)  1 / 1 / 0 stretch";
                                  document.body.appendChild(dragPreview);
                                  dragPreview.appendChild(img);

                                  event.dataTransfer.setDragImage(
                                    dragPreview,
                                    0,
                                    0
                                  );

                                  handleDragStart(event, {
                                    ...item,
                                    width: rect.width,
                                    height: rect.height,
                                    type: "image",
                                    subtype: "globalasset",
                                  });
                                  setTimeout(() => {
                                    document.body.removeChild(dragPreview);
                                  }, 0);
                                }}
                              >
                                {/* <img
                                  // crossOrigin="anonymous"
                                  className="max-w-full"
                                  loading="lazy"
                                  src={`${item.ASSETSBASEPATH}/${item.ASSETSSYSTEMFILENAME}`}
                                  alt="img"
                                /> */}
                                <LazyLoadImage
                                  width="100%"
                                  effect="blur"
                                  onLoad={() => {
                                    var img = new Image();
                                    img.src =
                                      props.pathName === "createTemplate"
                                        ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`
                                        : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0`;

                                    console.log(
                                      "INW",
                                      img.naturalWidth / img.naturalHeight
                                    );
                                  }}
                                  src={`${item.ASSETSBASEPATH}/${item.ASSETSSYSTEMFILENAME}`}
                                />
                              </div>
                            ))}
                          </Masonry>
                        </div>
                      )}
                    </div>
                  </div>
                </Panel>
                <Panel className="" header="Shapes" key={4} style={panelStyle}>
                  <div className={"h-[300px]"}>
                    <div
                      className={`max-h-[310px]
                       flex flex-wrap justify-evenly overflow-auto`}
                    >
                      <div className="">
                        <Masonry
                          breakpointCols={{ default: 2, 1100: 2, 700: 1 }}
                          className="my-masonry-grid "
                          draggable
                          columnClassName="my-masonry-grid_column"
                        >
                          {svgData.map((item, index) => (
                            <div
                              // className={`py-1 w-[100px]`}
                              id={index + "svg"}
                              key={index}
                              loading="lazy"
                              style={{
                                cursor: "move",
                                width: "100%",
                                backgroundColor: `${
                                  index % 5 === 0
                                    ? "#515D45"
                                    : index % 5 === 1
                                    ? "#5A7359"
                                    : index % 5 === 2
                                    ? "#618A71"
                                    : index % 5 === 3
                                    ? "#454834"
                                    : "#2A4C4E"
                                }`,
                              }}
                              draggable={props.play}
                              onDragStart={(event) => {
                                handleDragStart(event, {
                                  ...item,
                                  width: "101px",
                                  height: "101px",
                                  svgPath: `${svgPath}${item.EDITORASSETS_SYSTEMFILENAME}?t=1`,
                                  type: "svg",
                                  backgroundColor: "#000000",
                                });
                              }}
                              // onClick={() => {
                              //   if (props.itemSelected !== null) {
                              //     let index = props.canvaContent.findIndex(
                              //       function (obj) {
                              //         return obj.id === props.itemSelected;
                              //       }
                              //     );
                              //     if (
                              //       props.canvaContent[index].type === "image"
                              //     ) {
                              //       let asset =
                              //         props.pathName === "createTemplate"
                              //           ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=1`
                              //           : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=1`;

                              //       props.setPayload(asset);
                              //     }
                              //   }
                              // }}
                            >
                              <LazyLoadImage
                                width="100%"
                                effect="blur"
                                src={`${svgPath}${item.EDITORASSETS_SYSTEMFILENAME}`}
                              />
                            </div>
                          ))}
                        </Masonry>
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel className="" header="Video" key={2} style={panelStyle}>
                  {/* <div className="flex flex-wrap justify-evenly mt-4 h-[200px] overflow-auto"> */}
                  <div className="mt-1 h-[350px]">
                    {selectedAsset === 0 && (
                      <div
                        // className={`py-1 w-[100px]`}
                        style={{
                          // textAlign: "center",
                          marginTop: "4px",
                          marginBottom: "4px",
                          display: "flex",
                          // justifyContent: "center",
                        }}
                      >
                        <div className="">
                          <div className="mb-1 mt-2 text-[13px] text-[#BABABA] text-left">
                            Browse & upload video files
                          </div>
                        </div>
                        <div className="ml-[16px]">
                          <input
                            type="file"
                            accept="video/*"
                            id="upload-file2"
                            onChange={(e) => {
                              if (progressbar === 0) {
                                handleUpload(e, "video");
                              }
                            }}
                          />
                          <label for="upload-file2" className="upload-label">
                            <img
                              className="h-5 mt-2 ml-[15px]"
                              src={uploadup}
                              alt="upload"
                            />
                          </label>
                        </div>
                      </div>
                    )}
                    {selectedAsset === 0 && <Divider />}
                    {selectedAsset === 0 && (
                      <div className="">
                        <div className="font-medium">Add Variable Video</div>
                        <div className="flex">
                          <div className="w-[60%] text-[#BABABA] text-[10px] mt-2">
                            Adding variable video to your video enable you to
                            add customer specific personalized video.
                          </div>
                          <img
                            src={vVideo}
                            alt="I"
                            style={{
                              height: "60px",
                              width: "60px",
                              cursor: "move",
                              marginTop: "10px",
                              marginLeft: "5px",
                            }}
                            draggable={props.play}
                            onDragStart={(event) => {
                              handleDragStart(event, {
                                height: 85,
                                width: 85,
                                type: "vVideo",
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <Divider />
                    <div
                      className={`${
                        selectedAsset === 0 ? "max-h-[200px]" : "max-h-[340px]"
                      } flex flex-wrap justify-evenly overflow-auto`}
                    >
                      {selectedAsset === 0 && (
                        <div className="">
                          <Masonry
                            breakpointCols={{ default: 2, 1100: 2, 700: 1 }}
                            className="my-masonry-grid "
                            draggable
                            columnClassName="my-masonry-grid_column"
                          >
                            {props.videoData.map((item, index) => (
                              <div
                                key={index + "video"}
                                id={index + "vid"}
                                style={{
                                  cursor: "move",
                                  position: "relative",
                                  width: "100%",
                                }}
                                draggable={props.play}
                                onDragStart={(event) => {
                                  var element = document.getElementById(
                                    index + "vid"
                                  );
                                  handleDragStart(event, {
                                    ...item,
                                    height: element.offsetHeight,
                                    width: element.offsetWidth,
                                    type: "video",
                                  });
                                }}
                                onClick={() => {
                                  if (props.itemSelected !== null) {
                                    let index = props.canvaContent.findIndex(
                                      function (obj) {
                                        return obj.id === props.itemSelected;
                                      }
                                    );
                                    if (
                                      props.canvaContent[index].type === "video"
                                    ) {
                                      let asset =
                                        props.pathName === "createTemplate"
                                          ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=1`
                                          : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=1`;

                                      props.setPayload(asset);
                                    }
                                  }
                                }}
                              >
                                <Popover
                                  placement="topLeft"
                                  arrow={false}
                                  open={openDD === index}
                                  onOpenChange={handleOpenDD}
                                  content={
                                    <div className="bg-[#969696] p-1 text-xs rounded-sm cursor-pointer" onClick={()=>{changeBaseVideo("template",item.name)}}>
                                      Change Base Video
                                    </div>
                                  }
                                  trigger="click"
                                >
                                    {/* <div className="rotate-90 bg-slate-600 w-[18px] relative top-[20px] right-[-80%] z-10 cursor-pointer rounded-[20px]" onClick={()=>{
                                      setOpenDD(index);
                                    }}>
                                      <Dots fill="#ffffff" />
                                    </div> */}
                                    {/* COMMENTED AS PER REQUIREMENT, IT WILL BE RESUMED WHEN IT WILL WORK FINE */}
                                </Popover>

                                <video
                                  className="max-w-full"
                                  // loading="lazy"
                                  controls={false}
                                  src={
                                    props.pathName === "createTemplate"
                                      ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0#t=1`
                                      : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}?t=0#t=1`
                                  }
                                />
                              </div>
                            ))}
                          </Masonry>
                        </div>
                      )}
                      {selectedAsset === 1 && (
                        <div className="">
                          <Masonry
                            breakpointCols={{ default: 2, 1100: 2, 700: 1 }}
                            className="my-masonry-grid "
                            draggable
                            columnClassName="my-masonry-grid_column"
                          >
                            {globalvideoData.map((item, index) => (
                              <div
                                // className={`py-1 w-[100px]`}
                                key={index + "GVD"}
                                id={index + "gvid"}
                                style={{
                                  // marginTop: "4px",
                                  // marginBottom: "4px",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  cursor: "move",
                                  // position: "relative",
                                  // height: "75px",
                                  width: "100%",
                                  // border: "1px solid white",
                                }}
                                draggable={props.play}
                                onDragStart={(event) => {
                                  var element = document.getElementById(
                                    index + "gvid"
                                  );
                                  handleDragStart(event, {
                                    ...item,
                                    height: element.offsetHeight,
                                    width: element.offsetWidth,
                                    type: "video",
                                    subtype: "globalasset",
                                  });
                                }}
                                onClick={() => {
                                  if (props.itemSelected !== null) {
                                    let index = props.canvaContent.findIndex(
                                      function (obj) {
                                        return obj.id === props.itemSelected;
                                      }
                                    );
                                    if (
                                      props.canvaContent[index].type === "video"
                                    ) {
                                      let asset = `${item.ASSETSBASEPATH}/${item.ASSETSSYSTEMFILENAME}?t=1`;
                                      props.setPayload(asset);
                                    }
                                  }
                                }}
                              >
                                <video
                                  className="max-w-full"
                                  loading="lazy"
                                  src={`${item.ASSETSBASEPATH}/${item.ASSETSSYSTEMFILENAME}`}
                                />
                                {/* <img
                        src={cross}
                        alt="img"
                        className="absolute top-1 right-0 cursor-pointer"
                        onClick={() => {
                          let alldata = [...videoData];
                          alldata.splice(index, 1);
                          setVideoData(alldata);
                        }}
                      /> */}
                              </div>
                            ))}
                          </Masonry>
                        </div>
                      )}
                    </div>
                  </div>
                </Panel>
                <Panel className="" header="Music" key={3} style={panelStyle2}>
                  <div
                    className={`mt-1 ${
                      selectedAsset === 0 ? "max-h-[200px]" : "max-h-[340px]"
                    }`}
                  >
                    {selectedAsset === 0 && (
                      <div
                        style={{
                          marginTop: "4px",
                          marginBottom: "10px",
                          display: "flex",
                        }}
                      >
                        <div className="">
                          <div className="mb-1 mt-2 text-[#BABABA] text-[13px] text-left">
                            Browse & upload mp3 files
                          </div>
                        </div>
                        <div className="ml-[16px]">
                          <input
                            type="file"
                            accept="audio/*"
                            id="upload-file3"
                            onChange={(e) => {
                              if (progressbar === 0) {
                                handleUpload(e, "audio");
                              }
                            }}
                          />
                          <label for="upload-file3" className="upload-label3">
                            <img
                              className="h-5 mt-2 ml-5 cursor-pointer"
                              src={uploadup}
                              alt="upload"
                            />
                          </label>
                        </div>
                      </div>
                    )}
                    <Divider />
                    <div
                      className={`${
                        selectedAsset === 0 ? "max-h-[320px]" : "max-h-[380px]"
                      } overflow-auto`}
                    >
                      {selectedAsset === 0 &&
                        props?.musicData?.map((item, index) => (
                          <div key={"audio" + index}>
                            <div className="">
                              <div className="flex h-[50px] justify-between">
                                <div className="h-[30px]">
                                  <img
                                    src={music}
                                    style={{
                                      height: "100%",
                                    }}
                                    alt="m"
                                  />
                                </div>
                                <div
                                  className="pt-[4px] 
                              
                               "
                                >
                                  {item.displayName}
                                </div>
                                <div className="custom-audio-player">
                                  <audio
                                    className="music-panel"
                                    controls
                                    controlsList=" nodownload noplaybackrate noslider notimeline"
                                    src={
                                      props.pathName === "createTemplate"
                                        ? `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMP/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}`
                                        : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/CONTENT/${item.name}`
                                    }
                                  />
                                </div>
                                <div className="mr-2 pt-[5px] cursor-pointer">
                                  <img
                                    src={item.checked ? musicRemove : musicAdd}
                                    onClick={() => {
                                      props.setMusicButton(true);
                                      let arr = [...props.musicData];
                                      arr[index].checked = !item.checked;
                                      arr[index].starttime = props.currentTime;
                                      arr[index].stoptime =
                                        Number(props.currentTime) + 1;
                                      arr[index].defaultChecked = true;
                                      props.setMusicData([...arr]);
                                      props.setShowDialog(true);
                                    }}
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      accentColor: "#3498db",
                                      borderRadius: "50%",
                                    }}
                                    alt="M"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {selectedAsset === 1 &&
                        globalaudioData.map((item, index) => (
                          <div key={"GA" + index}>
                            <div className="">
                              <div className="flex h-[50px] justify-between">
                                <div className="h-[30px]">
                                  <img
                                    src={music}
                                    style={{
                                      height: "100%",
                                    }}
                                    alt="m"
                                  />
                                </div>
                                <div className="pt-[4px]">
                                  {`GAudio ${index}`}
                                </div>
                                <div className="custom-audio-player">
                                  <audio
                                    className="music-panel"
                                    controls
                                    controlsList=" nodownload noplaybackrate noslider notimeline"
                                    src={`${item.ASSETSBASEPATH}/${item.ASSETSSYSTEMFILENAME}`}
                                  />
                                </div>
                                <div className="mr-2 pt-[5px] cursor-pointer">
                                  <img
                                    src={item.checked ? musicRemove : musicAdd}
                                    onClick={() => {
                                      props.setMusicButton(true);
                                      item.checked = !item.checked;
                                      item.defaultChecked = true;
                                      if(item.checked){
                                      item.starttime = props.currentTime;
                                      item.stoptime =
                                        Number(props.currentTime) + 1;
                                      props.setGlobalMusicData([...props.globalmusicData,item]);
                                      }
                                      else{
                                        props.setGlobalMusicData(props.globalmusicData.filter(v => v.name !== item.name));
                                      }
                                    }}
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      accentColor: "#3498db",
                                      borderRadius: "50%",
                                    }}
                                    alt="M"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          )}
          {props.selectedSidebarItem === 4 && (
            <Collapse
              className="editor-collapse2"
              expandIconPosition="end"
              style={{ padding: "15px", background: "#131317", color: "white" }}
              bordered={false}
              defaultActiveKey={["1", "2", "3", "4"]}
              expandIcon={({ isActive }) => (
                <img src={isActive ? minus : plus} alt="open" />
              )}
            >
              {buttonData.map((item, index) => (
                <Panel
                  className=""
                  header={item.panelName}
                  key={item.id}
                  style={panelStyle}
                  onClick={panelClick(item.panelName)}
                >
                  <div className="flex flex-wrap justify-between mt-4">
                    {item.data
                      .filter((item, index) => {
                        if (props.toolSearch.trim() === "") {
                          return true;
                        } else {
                          return item.placeholder
                            .substring(1)
                            .toLowerCase()
                            .includes(props.toolSearch.toLowerCase());
                        }
                      })
                      .map((item, index) => (
                        <Tooltip
                          className=""
                          key={index + "ToolButton"}
                          trigger="click"
                          title="Drag Element to main Canvas"
                          color="#2B2B2C"
                          placement="right"
                          overlayStyle={{
                            boxShadow: "2px",
                            height: "30px",
                            width: "120px",
                          }}
                        >
                          <div key={index + "button"}>
                            <button
                              className={`py-1 w-[100px]`}
                              // disabled
                              style={{
                                textAlign: "center",
                                backgroundColor: item.backgroundColor,
                                border: `${item.border}px solid ${item.borderColor}`,
                                borderRadius: item.borderRadius,
                                color: item.color,
                                marginTop: "4px",
                                marginBottom: "4px",
                                display: "flex",
                                justifyContent: "center",
                                cursor: "move",
                              }}
                              draggable={props.play}
                              onDragStart={(event) => {
                                handleDragStart(event, item);
                              }}
                            >
                              {item.placeholder.charAt(0) === "L" && (
                                <FontAwesomeIcon
                                  icon={item.icon}
                                  style={{
                                    marginTop: "4px",
                                    marginRight: "3px",
                                    color: item.color,
                                  }}
                                />
                              )}
                              <span>{item.placeholder.substring(1)}</span>
                              {item.placeholder.charAt(0) === "R" && (
                                <FontAwesomeIcon
                                  icon={item.icon}
                                  style={{
                                    marginTop: "4px",
                                    marginLeft: "3px",
                                  }}
                                />
                              )}
                            </button>
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                </Panel>
              ))}
            </Collapse>
          )}
          {props.selectedSidebarItem === 5 &&
            optionData
              .filter((item, index) => {
                if (props.toolSearch.trim() === "") {
                  return true;
                } else {
                  return item.name
                    .toLowerCase()
                    .includes(props.toolSearch.toLowerCase());
                }
              })
              .map((item, index) => (
                <div key={index + "options"}>
                  {item.type !== "dropdown" && (
                    <div
                      //  className="flex justify-center "
                      className="w-[84px]"
                    >
                      <div className="flex my-2 mt-3">
                        <div
                          // className={`py-3 w-[150px] h-[60px] flex align-middle cursor-default`}
                          className={`cursor-move ml-3 mr-2`}
                          style={{
                            borderRadius: item.borderRadius,
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.img && (
                            <div
                              // className="flex align-middle ml-8 mt-1 mr-4 cursor-move"
                              className=""
                            >
                              <Tooltip
                                className=""
                                trigger="click"
                                title="Drag Element to main Canvas"
                                color="#2B2B2C"
                                placement="right"
                                overlayStyle={{
                                  boxShadow: "2px",
                                  height: "30px",
                                  width: "120px",
                                }}
                              >
                                <img
                                  src={item.img}
                                  alt="img"
                                  draggable={props.play}
                                  onDragStart={(event) => {
                                    handleDragStart(event, item);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          )}
                          {/* <div className="pt-[1px]">{item.name}</div> */}
                        </div>
                        <div className=" text-left">
                          <div
                            className=" text-left h-full w-[150px] text-[#dddddd]  font-medium text-[14px]"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            {item.name} Button
                          </div>
                        </div>
                      </div>
                      <div className=" ml-3 mt-1 text-[12px] text-[#BABABA] w-[200px] text-left text-[#c8c6c6]  !leading-snug">
                        {item.text}
                      </div>
                    </div>
                  )}
                  {item.type !== "dropdown" && (
                    <Divider className=" mt-3 mb-1 " />
                  )}
                  {item.type === "dropdown" && (
                    <>
                      <div className="flex mt-2 ml-3 ">
                        <div className=" text-right text-[#dddddd]  font-medium text-[14px]">
                          Dropdown Button
                        </div>
                      </div>

                      <div
                        // className="mt-2 h-[60px] w-[150px] ml-12 cursor-move"
                        className=" h-[35px] w-[150px] text-center  ml-3 mt-2 cursor-move rounded-sm"
                        draggable={props.play}
                        onDragStart={(event) => {
                          handleDragStart(event, item);
                        }}
                      >
                        <Tooltip
                          className=""
                          trigger="click"
                          title="Drag Element to main Canvas"
                          color="#2B2B2C"
                          placement="right"
                          overlayStyle={{
                            boxShadow: "2px",
                            height: "30px",
                            width: "120px",
                          }}
                        >
                          <div className="">
                            <select
                              className="mt-1 cursor-move"
                              name="-Dropdown Label-"
                              style={{
                                width: 150,
                                paddingLeft: 8,
                                paddingRight: 8,
                                height: 35,
                                backgroundColor: "#2B2B2C",
                              }}
                              disabled
                            >
                              <option value="">--Label--</option>
                            </select>
                          </div>
                        </Tooltip>
                        <div className="mt-3 text-[12px] text-[#BABABA] w-[200px] text-left !leading-snug">
                          {item.text}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default ToolBar;
