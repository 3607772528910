import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useWindowWidth from "../../common/hooks/useWindowWidth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
      categoryPercentage: 0.8, // Adjust the categoryPercentage to change bar width
      barPercentage: 0.9, // Adjust the barPercentage to change bar height
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "General SMS - 150",
    },
    layout: {
      padding: 50,
    },
    datalabels: {
      font: {
        weight: "bold",
      },
      align: "end",
      anchor: "end",
      formatter: function (value, context) {
        return context.chart.formattedData[context.dataIndex];
      },
    },
  },
};

const HorizontalChart = ({ parsedSearchData, dashboardData }) => {
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();
  
  const playDashData =
    dashboardData && dashboardData.length > 0
      ? dashboardData[0].PROJECTPERFORMANCE.PLAYDURATION
      : null;
  const searchDashData =
    parsedSearchData && parsedSearchData.length > 0
      ? parsedSearchData[0].PROJECTPERFORMANCE.PLAYDURATION
      : null;

  const formattedData = [
    `${
      searchDashData === null && playDashData
        ? playDashData?.ZEROTO1SECONDS
        : searchDashData?.ZEROTO1SECONDS
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.ONETO10SECONDS
        : searchDashData?.ONETO10SECONDS
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.TENTO20SECONDS
        : searchDashData?.TENTO20SECONDS
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.TWENTYTO30SECONDS
        : searchDashData?.TWENTYTO30SECONDS
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.THIRTYTO40SECONDS
        : searchDashData?.THIRTYTO40SECONDS
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.FORTYTO50SECONDS
        : searchDashData?.FORTYTO50SECONDS
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.FIFTYTO60SECONDS
        : searchDashData?.FIFTYTO60SECONDS
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.GREATERTHAN60
        : searchDashData?.GREATERTHAN60
    }`,
    `${
      searchDashData === null && playDashData
        ? playDashData?.FULLVIDEO
        : searchDashData?.FULLVIDEO
    }`,
  ];

  const data = {
    labels: [
      "0-1s",
      "1-10s",
      "10-20s",
      "20-30s",
      "30-40s",
      "40-50s",
      "50-60s",
      ">60s",
      "Full Video",
    ],
    datasets: [
      {
        data: [
          searchDashData === null && playDashData
            ? playDashData?.ZEROTO1SECONDS
            : searchDashData?.ZEROTO1SECONDS,
          searchDashData === null && playDashData
            ? playDashData?.ONETO10SECONDS
            : searchDashData?.ONETO10SECONDS,
          searchDashData === null && playDashData
            ? playDashData?.TENTO20SECONDS
            : searchDashData?.TENTO20SECONDS,
          searchDashData === null && playDashData
            ? playDashData?.TWENTYTO30SECONDS
            : searchDashData?.TWENTYTO30SECONDS,
          searchDashData === null && playDashData
            ? playDashData?.THIRTYTO40SECONDS
            : searchDashData?.THIRTYTO40SECONDS,
          searchDashData === null && playDashData
            ? playDashData?.FORTYTO50SECONDS
            : searchDashData?.FORTYTO50SECONDS,
          searchDashData === null && playDashData
            ? playDashData?.FIFTYTO60SECONDS
            : searchDashData?.FIFTYTO60SECONDS,
          searchDashData === null && playDashData
            ? playDashData?.GREATERTHAN60
            : searchDashData?.GREATERTHAN60,
          searchDashData === null && playDashData
            ? playDashData?.FULLVIDEO
            : searchDashData?.FULLVIDEO,
        ],
        backgroundColor: [
          "#8CE89E",
          "#53C9A3",
          "#28A89F",
          "#1D8791",
          "#2A6778",
          "#2F4858",
          "#009AC4",
          "#0A7BB5",
          "#4D5B96",
        ],
        datalabels: {
          formatter: function (value, context) {
            return formattedData[context.dataIndex];
          },
        },
      },
    ],
  };

  return (
    <div className={`${isDesktop ? "!h-[26rem] !w-[36rem]" : "!h-[17rem] md:!h-[26rem] !w-full"}`}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default HorizontalChart;
