import React from "react";
import InputBox from "../../common/components/InputBox";
import SimpleButtonIcon from "../../common/components/SimpleButtonIcon";
import { useQuery } from "react-query";
import TemplateCard from "./TemplateCard";
import { getAllGlobalTemplate } from "../../api/api_FetchData";
import { useState } from "react";
import filter from "../../assests/commonIcons/templateIcons/filterBlackSvg.svg";
import { Divider, Drawer, Select, Spin } from "antd";
import SimpleButton from "../../common/components/SimpleButton";
import { useEffect } from "react";
// import CreateTemplateModal from "../../common/components/template/CreateTemplateModal";
import CreateTemplateModal from "../../common/components/Modal/template/createTemplate/CreateTemplateModal";
import { useNavigate } from "react-router-dom";
import useSessionChecker from "../../common/functions/useSessionChecker";
import NoDataFound from "../../common/components/NoDataFound";
import noGlobalTsvg from "../../assests/commonImages/noGlobalTsvg.svg";
import noAssetImage from "../../assests/commonIcons/assetImages/noAssetSvg.svg";
import "../../common/styles/commonStyles.css";
import noTemplateSvg from "../../assests/commonImages/noTemplateSvg.svg";
import StartingModal from "../../userModule/common/modals/StartingModal";
//mobile responsive
import useWindowWidth from "../../common/hooks/useWindowWidth";
import fliterTemp from "../../assests/ResponsiveIcons/TemplateCategoryFilter.svg";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";

import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import clevertap from "clevertap-web-sdk";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import { toast } from "react-toastify";

// Initialize Swiper core
SwiperCore.use([Navigation]);

// const { Search } = Input;
const { Option } = Select;

const TemplateLayout = ({ sessionuserid }) => {
  // console.log("USER TEMPLATE PAGE RENDERED");
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();
  // const location = useLocation();

  sessionuserid = localStorage.getItem("sessionUserId");
  const userAgent = navigator.userAgent;
  const [createTempModal, setCreateTempModal] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [filtersDrawerVisible, setFiltersDrawerVisible] = useState(false);
  const [selectedUseCases, setSelectedUseCases] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermUseCase, setSearchTermUseCase] = useState("");
  const [showSuffixIcon, setShowSuffixIcon] = useState(false);
  const [prefixActive, setPrefixActive] = useState(false);
  const [showStartingModal, setShowStartingModal] = useState(false);
  // for showing search on scroll
  const [isFixed, setIsFixed] = useState(false);

  // for mobile responsive
  const { isMobile, isDesktop, windowWidth } = useWindowWidth();
  const [showInputBox, setShowInputBox] = useState(false);

  const {
    data,
    refetch,
    isError,
    isLoading: isDataLoading,
  } = useQuery(
    ["globalTemplate", sessionuserid],
    () => getAllGlobalTemplate(sessionuserid, null, "/template"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        console.log(data.status, "DATA FILTER");
        if (data.status === "-2001") {
          sessionStorage.clear();
          localStorage.clear();
          // debugger
          navigate(`${process.env.REACT_APP_BASE_PATH}/logout`);
          toast.error(data.description);
          // debugger
        }
      },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      await refetch();

      // Ensure data?.data is an array before mapping
      const allTemplateTypes = Array.from(
        new Set(
          (data?.data || [])
            .map((template) => template?.TEMPLATETYPE)
            .filter(Boolean)
        )
      );

      setSelectedType(allTemplateTypes);
      console.log(allTemplateTypes, "G T DATA");
    };

    fetchData();
  }, [refetch, data]);

  useEffect(() => {
    // Check if startingModalShow is "yes" in local storage
    const startingModalShow = localStorage.getItem("startingModalShow");
    // console.log(startingModalShow, "MODAL SHOW");

    if (startingModalShow === "yes") {
      // Show the modal
      setShowStartingModal(true);
    }
  }, []);

  // check scroll for search
  const handleScroll = (e) => {
    // Adjust the scroll threshold as needed
    const scrollThreshold = 80;
    const currentScroll = e.target.scrollTop;

    if (currentScroll > scrollThreshold) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  // console.log(data, "G T DATA");

  if (isDataLoading) {
    return (
      <>
        <div className="m-auto">
          <span>
            <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
              <Spin tip="Loading..." />
            </div>
          </span>
        </div>
      </>
    );
  }

  const openCreateTemplateModal = () => {
    setCreateTempModal(true);
  };

  if (data.status === "-1001" || data.data.includes(null)) {
    return (
      <div>
        <div className={`${isDesktop ? "w-[calc(100vw_-_316px)]" : "w-full"}  flex flex-col items-center pt-20`}>
          <span className="text-center flex justify-center">
            <img src={noTemplateSvg} alt="template svg" />
          </span>
          {searchTerm ? <span>No Temp</span> : <span> No template</span>}
          <span className="text-[#4B4B4B] text-xl font-medium pt-4">
            No templates found yet!
          </span>
          <span className={`${isDesktop ? "w-[480px]" : ""} text-[#7A7A7A] text-[15px] font-normal  leading-5 text-center pt-1`}>
            It’s time to create your first Template. Choose the template you
            need from template library or create one yourself from scratch.
            Ready get set goooo!
          </span>
          {/* <span className="pt-4">
                  <SimpleButton
                    type="default"
                    label="Browse Template Library"
                    className="rounded-[4px] bg-transparent border-[#1C68EB] text-[#1C68EB] hover:bg-gradient-to-r from-[#f6f9f6] to-[#F2F7FF] hover:shadow-md hover:shadow-[#155fff14]"
                    onClick={navigateToTemplate}
                  />
                </span> */}
        </div>
      </div>
    );
  }

  if (isError) {
    // Handle the error condition
    return <div>Error loading data.</div>;
  }

  if (data.status !== "1000") {
    // return <div className="flex justify-center items-center w-full">Loading...</div>;
    return (
      <>
        <div className="w-[calc(100vw_-_195px)] h-[calc(100vh_-_3.75rem)] flex flex-col overflow-y-hidden">
          <div className="w-full h-[12rem] bg-[#F7FAFC]">
            <div className="pt-9 pl-11 pr-8 pb-4">
              <div className="flex gap-5 pb-5 justify-between">
                <div className="text-[22px] font-semibold">
                  Template Library
                </div>
                <div className="flex gap-6 items-center">
                  {/* <InputBox
                    className="h-9 w-72 rounded-3xl global-search"
                    placeholder="Search templates..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  /> */}
                  <InputBox
                    searchIconClass="px-1 h-[15px] w-[23px]"
                    xIconClass="p-[1px]"
                    className="h-9 w-80 text-[13px] rounded-3xl global-search"
                    placeholder="Search from hundreds of templates..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClearClick={() => {
                      setSearchTerm(""); // Clear the input value
                    }}
                    showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                    prefixActive={prefixActive} // Pass prefixActive prop
                    setShowSuffixIcon={setShowSuffixIcon}
                    setPrefixActive={setPrefixActive}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center pt-12">
                <NoDataFound
                  svgImage={noGlobalTsvg}
                  mainHeading="Uh oh! The aliens took our templates"
                  description="No need to fret; we'll notify you as soon as we retrieve them. In the meantime, you're welcome to craft your own template with the help of our powerful editor."
                  navigatePath=""
                  type="default"
                  label="Create Your Own Template"
                  onClick={openCreateTemplateModal}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const showFiltersDrawer = () => {
    setFiltersDrawerVisible(true);
  };

  const hideFiltersDrawer = () => {
    setFiltersDrawerVisible(false);
  };
  // debugger

  if (data.status === "1000" && data && data.data.length > 0) {
    // const templateLength = data.data.length;
    const filteredTemplatesArray = data?.data.filter((template) => {
      const matchesCategory =
        selectedCategory === "ALL" ||
        template.TEMPLATECATEGORY === selectedCategory;
      const matchesUseCases =
        selectedUseCases.length === 0 ||
        selectedUseCases.includes(template.TEMPLATEUSECASE);
      const matchesType =
        selectedType.length === 0 ||
        selectedType.includes(template.TEMPLATETYPE);

      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      const matchesSearchTerm =
        searchTerm === "" ||
        (template.TEMPLATENAME &&
          template.TEMPLATENAME.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (template.TEMPLATEUSECASE &&
          template.TEMPLATEUSECASE.toLowerCase().includes(
            lowerCaseSearchTerm
          )) ||
        (template.TEMPLATETYPE &&
          template.TEMPLATETYPE.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (template.TEMPLATECATEGORY &&
          template.TEMPLATECATEGORY.toLowerCase().includes(
            lowerCaseSearchTerm
          )) ||
        (template.TEMPLATEDESCRIPTION &&
          template.TEMPLATEDESCRIPTION.toLowerCase().includes(
            lowerCaseSearchTerm
          ));

      return (
        matchesCategory && matchesUseCases && matchesType && matchesSearchTerm
      );
    });
    console.log(filteredTemplatesArray, "FILTER ARRAY");

    const usecaseGroups = {};
    // debugger
    filteredTemplatesArray.forEach((template) => {
      const TEMPLATEUSECASE = template.TEMPLATEUSECASE || {};
      if (!usecaseGroups[TEMPLATEUSECASE]) {
        usecaseGroups[TEMPLATEUSECASE] = [];
      }
      usecaseGroups[TEMPLATEUSECASE].push(template);
    });
    console.log(usecaseGroups, "USE CASE GROUPS");

    const handleUseCaseToggle = (TEMPLATEUSECASE) => {
      setSelectedUseCases((prevUseCases) =>
        prevUseCases.includes(TEMPLATEUSECASE)
          ? prevUseCases.filter((uc) => uc !== TEMPLATEUSECASE)
          : [...prevUseCases, TEMPLATEUSECASE]
      );
    };

    const clearAllUseCases = () => {
      setSelectedUseCases([]);
    };

    const handleTypeToggle = (TEMPLATETYPE) => {
      setSelectedType((prevTypes) => {
        if (selectedType.length === 1 && selectedType.includes(TEMPLATETYPE)) {
          return prevTypes;
        }
        return prevTypes.includes(TEMPLATETYPE)
          ? prevTypes.filter((t) => t !== TEMPLATETYPE)
          : [...prevTypes, TEMPLATETYPE];
      });
    };

    const handleSeeAllClick = (useCase, templatesInUsecase) => {
      // Navigate to the "See All" page with the selected use case as a parameter
      // navigate(`/see-all/${useCase}`);
      // navigate(`${process.env.REACT_APP_BASE_PATH}/global-template/see-all/${useCase}`);
      navigate(
        `${
          process.env.REACT_APP_BASE_PATH
        }/global-template/see-all/${useCase.replace("/", "%2F")}`,
        {
          state: {
            useCase: useCase,
            templatesInUsecase: templatesInUsecase,
          },
        }
      );
    };

    const TemplateCategorySelected = (TEMPLATECATEGORY) => {
      clevertap.event.push("Template_Category_Selected", {
        USER_ID: sessionuserid,
        Email: sessionuserid,
        CategoryName: TEMPLATECATEGORY,
        timestamp: new Date().getTime(),
        user_agent: userAgent,
      });
      console.log("Template_Category_Selected EVENT CLICKED");
    };

    const handleCategoryChange = (value) => {
      setSelectedCategory(value);
      TemplateCategorySelected(value);
    };

    const toggleInputBox = () => {
      setShowInputBox(!showInputBox);
    };

    return (
      <>
        {showStartingModal && (
          <StartingModal
            openModal={showStartingModal}
            setShowStartingModal={setShowStartingModal}
          />
        )}
        {/* Desktop version */}
        {isDesktop && (
          <div
            onScroll={handleScroll}
            // ref={scrollRef}
            className="w-[calc(100vw_-_195px)] h-[calc(100vh_-_3.75rem)] flex flex-col overflow-y-auto overflow-x-hidden"
          >
            <div
              className=" w-full py-5 flex justify-between bg-transparent"
              style={{ borderBottom: "0.5px solid #d9d9d9" }}
            >
              <div className="text-[25px] pl-11 font-bold leading-8">
                Quick start with ready to use video templates
              </div>
              <div className="flex items-center pr-8">
                <InputBox
                  searchIconClass="px-1 h-[15px] w-[23px]"
                  xIconClass="p-[1px]"
                  className="h-9 w-80 text-[13px] rounded-3xl global-search"
                  placeholder="Search from hundreds of templates..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClearClick={() => {
                    setSearchTerm(""); // Clear the input value
                  }}
                  showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                  prefixActive={prefixActive} // Pass prefixActive prop
                  setShowSuffixIcon={setShowSuffixIcon}
                  setPrefixActive={setPrefixActive}
                />
              </div>
            </div>
            <div
              style={{
                // paddingLeft: "10px",
                zIindex: "5",
                backgroundColor: "#f7fafc",
              }}
              className="shadow drop-shadow-sm sticky top-0 z-30 w-full"
            >
              <div className="pl-11 pr-6 px-[5px] navbar flex justify-between z-30 h-[40px] relative">
                <div
                  className={` ${
                    isFixed
                      ? "w-[70%] transition-all duration-500 ease-in-out"
                      : "w-[80%] transition-all duration-500 ease-in-out"
                  }`}
                >
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#D3D3D3",
                      "--swiper-navigation-size": "15px",
                      // "--swiper--button-disabled-display": "none",
                      // width: "660px",
                      height: "47px",
                      paddingTop: "12px",
                    }}
                    className="textSlider"
                    modules={[Navigation]}
                    spaceBetween={20}
                    // loop={true}
                    slidesPerView={"auto"}
                    navigation
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log("slide change")}
                  >
                    <div className="">
                      <SwiperSlide>
                        <span
                          className={`text-[13px] font-normal text-[#141C37] mx-1 my-2 cursor-pointer ${
                            selectedCategory === "ALL"
                              ? " text-[#E16023] font-semibold underline underline-offset-[11px] decoration-2"
                              : // ? " text-[#E16023] font-semibold activeLine"
                                ""
                          }`}
                          onClick={() => setSelectedCategory("ALL")}
                        >
                          <span> All</span>
                        </span>
                      </SwiperSlide>

                      {Array.from(
                        new Set(
                          data?.data.map(
                            (template) => template.TEMPLATECATEGORY
                          )
                        )
                      ).map((TEMPLATECATEGORY) => (
                        <SwiperSlide key={TEMPLATECATEGORY}>
                          <span
                            // key={TEMPLATECATEGORY}
                            className={`text-[13px] font-normal text-[#141C37] mx-1 my-2 cursor-pointer ${
                              selectedCategory === TEMPLATECATEGORY
                                ? " text-[#E16023] font-semibold underline underline-offset-[11px]  decoration-2"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedCategory(TEMPLATECATEGORY);
                              TemplateCategorySelected(TEMPLATECATEGORY);
                            }}
                          >
                            {TEMPLATECATEGORY}
                          </span>
                        </SwiperSlide>
                      ))}
                    </div>
                  </Swiper>
                </div>
                <div className="flex items-center gap-1">
                  {/* onStick inputbox shown */}
                  {isFixed && (
                    <InputBox
                      searchIconClass="px-[1px] h-[12px] w-[15px]"
                      xIconClass=""
                      className="h-8 w-48 text-[12px] ml-3 rounded-3xl global-search"
                      placeholder="Search from hundreds of templates..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onClearClick={() => {
                        setSearchTerm(""); // Clear the input value
                      }}
                      showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                      prefixActive={prefixActive} // Pass prefixActive prop
                      setShowSuffixIcon={setShowSuffixIcon}
                      setPrefixActive={setPrefixActive}
                    />
                  )}
                  <div>
                    <SimpleButtonIcon
                      label="Filters"
                      type="text"
                      imageClass="!h-4 !w-4 !pt-[2px]"
                      className="!text-[13px] !font-medium cursor-pointer text-[black]"
                      image={filter}
                      onClick={showFiltersDrawer}
                    />
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="pl-11 pr-8 mt-4">
                {Object.keys(usecaseGroups).map((TEMPLATEUSECASE) => {
                  const templatesInUsecase = usecaseGroups[TEMPLATEUSECASE];
                  if (templatesInUsecase.length > 0) {
                    return (
                      <div key={TEMPLATEUSECASE} className="mb-6">
                        <span className="flex justify-between items-center">
                          <h2 className="text-lg font-semibold text-gray-800 mb-2 mt-4">
                            {TEMPLATEUSECASE}
                          </h2>
                          {templatesInUsecase.length > 6 && (
                            <span
                              onClick={() =>
                                handleSeeAllClick(
                                  TEMPLATEUSECASE,
                                  templatesInUsecase,
                                  selectedCategory
                                )
                              }
                              className="cursor-pointer text-[#1C68EB] text-sm font-normal hover:font-medium"
                            >
                              See All
                            </span>
                          )}
                        </span>

                        {/* <span className="flex justify-between items-center">
                      <h2 className="text-lg font-semibold text-gray-800 mb-2 mt-4">
                        {TEMPLATEUSECASE}
                      </h2>
                      <span
                        onClick={() =>
                          handleSeeAllClick(
                            TEMPLATEUSECASE,
                            templatesInUsecase,
                            selectedCategory
                          )
                        }
                        className="cursor-pointer text-[#1C68EB] text-sm font-normal hover:font-medium"
                      >
                        See All
                      </span>
                    </span> */}
                        {/* <div className="flex flex-wrap gap-4">
                      {templatesInUsecase.map((template) => (
                        <TemplateCard
                          key={template.TEMPLATEID}
                          template={template}
                        />
                      ))}
                    </div> */}
                        {/* <Slider {...settings}>
                      {templatesInUsecase.map((template) => (
                        <TemplateCard
                          key={template.TEMPLATEID}
                          template={template}
                        />
                      ))}
                    </Slider> */}
                        <Swiper
                          style={{
                            "--swiper-navigation-color": "#D3D3D3",
                            "--swiper-navigation-size": "25px",
                          }}
                          className="templateSlider"
                          modules={[Navigation]}
                          spaceBetween={10}
                          loop={false}
                          slidesPerView={"auto"}
                          navigation
                          // onSwiper={(swiper) => console.log(swiper)}
                          // onSlideChange={() => console.log("slide change")}
                        >
                          {templatesInUsecase.map((template) => (
                            <SwiperSlide key={template.TEMPLATEID}>
                              <TemplateCard template={template} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    );
                  }
                  return <>No record</>;
                })}
                {Object.keys(usecaseGroups).every(
                  (TEMPLATEUSECASE) =>
                    usecaseGroups[TEMPLATEUSECASE].length === 0
                ) && (
                  <div className="w-[calc(100vw-316px)] flex flex-col items-center pt-20">
                    <span className="text-center flex justify-center">
                      <img src={noAssetImage} alt="no asset svg"  />
                    </span>
                    <span className="text-[#4B4B4B] text-xl font-medium pt-4">
                      No matching templates found
                    </span>
                  </div>
                )}
              </div>
            </>
          </div>
        )}

        {/* Mobile version */}
        {isMobile && (
          <div
            onScroll={handleScroll}
            // ref={scrollRef}
            className="w-full h-[calc(100vh_-_3.75rem)] flex flex-col overflow-y-auto overflow-x-hidden"
          >
            <div
              className=" w-full py-5 flex justify-between bg-transparent"
              style={{ borderBottom: "0.5px solid #d9d9d9" }}
            >
              {/* <div className="text-[25px] pl-11 font-bold leading-8">
                Template Library
              </div> */}
              <div className="pr-8 pl-7 w-full">
                <InputBox
                  searchIconClass="px-1 h-[15px] w-[23px]"
                  xIconClass="p-[1px]"
                  className={`h-9 ${windowWidth > 500 ? "w-80" : "w-full"} text-[13px] rounded-3xl global-search`}
                  placeholder="Search from hundreds of templates..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClearClick={() => {
                    setSearchTerm(""); // Clear the input value
                  }}
                  showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                  prefixActive={prefixActive} // Pass prefixActive prop
                  setShowSuffixIcon={setShowSuffixIcon}
                  setPrefixActive={setPrefixActive}
                />
              </div>
            </div>
            <div
              style={{
                // paddingLeft: "10px",
                zIindex: "5",
                backgroundColor: "#f7fafc",
              }}
              className="shadow drop-shadow-sm sticky top-0 z-30 w-full"
            >
              <div
                className={`pl-7 pr-6 px-[5px] navbar flex items-center ${
                  windowWidth > 767
                    ? "justify-between"
                    : showInputBox
                    ? "justify-end"
                    : "justify-between"
                } z-30 h-[40px] relative`}
              >
                {/* {showInputBox === false && ( */}
                <div
                  className={`${
                    windowWidth > 767 ? "" : showInputBox ? "hidden" : ""
                  }`}
                >
                  <Select
                    defaultValue="ALL"
                    style={{ minWidth: 55, width: "auto" }}
                    dropdownStyle={{ minWidth: " max-content" }}
                    onChange={handleCategoryChange}
                    className="templateCategorySelect"
                  >
                    <Option value="ALL">All</Option>
                    {Array.from(
                      new Set(
                        data?.data.map((template) => template.TEMPLATECATEGORY)
                      )
                    ).map((TEMPLATECATEGORY) => (
                      <Option key={TEMPLATECATEGORY} value={TEMPLATECATEGORY}>
                        {TEMPLATECATEGORY}
                      </Option>
                    ))}
                  </Select>
                </div>
                {/* )} */}

                <div className="flex items-center gap-2">
                  {/* onStick inputbox shown */}
                  {isFixed && (
                    <>
                      <div className={`relative left-[8px] ${showInputBox ? "" : "showInputBox"}`}>
                        <InputBox
                          searchIconClass={`px-[1px] h-[12px] w-[15px] ${
                            showInputBox ? "" : "hidden"
                          }`}
                          xIconClass=""
                          className={` h-[28px] text-[12px] rounded-l-3xl global-search  ${
                            showInputBox
                              ? "w-64 "
                              : "w-0 bg-transparent border-none "
                          }`}
                          placeholder="Search from hundreds of templates..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          onClearClick={() => {
                            setSearchTerm(""); // Clear the input value
                          }}
                          showSuffixIcon={showSuffixIcon} // Pass showSuffixIcon prop
                          prefixActive={prefixActive} // Pass prefixActive prop
                          setShowSuffixIcon={setShowSuffixIcon}
                          setPrefixActive={setPrefixActive}
                        />
                      </div>
                      <div
                        className={`bg-[#0943A6] w-[28px] h-[28px] cursor-pointer  ${
                          showInputBox ? "rounded-r-3xl" : "rounded-full"
                        } flex items-center justify-center z-10`}
                        onClick={toggleInputBox}
                      >
                        {showInputBox === false ? (
                          <SearchOutlined style={{ color: "white" }} />
                        ) : (
                          <CloseOutlined style={{ color: "white" }} />
                        )}
                      </div>
                    </>
                  )}
                  <div
                    className="bg-[#0943A6] w-[28px] h-[28px] rounded-full flex items-center justify-center"
                    onClick={showFiltersDrawer}
                  >
                    <img src={fliterTemp} alt="fliter" />
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="pl-7 pr-8 mt-4">
                {Object.keys(usecaseGroups).map((TEMPLATEUSECASE) => {
                  const templatesInUsecase = usecaseGroups[TEMPLATEUSECASE];
                  if (templatesInUsecase.length > 0) {
                    return (
                      <div key={TEMPLATEUSECASE} className="mb-8">
                        <span className="flex justify-between items-center">
                          <h2 className="text-[15px] font-[300px] text-gray-800 mb-2 mt-4">
                            {TEMPLATEUSECASE}
                          </h2>
                          {templatesInUsecase.length > 6 && (
                            <span
                              onClick={() =>
                                handleSeeAllClick(
                                  TEMPLATEUSECASE,
                                  templatesInUsecase,
                                  selectedCategory
                                )
                              }
                              className="cursor-pointer text-[#1C68EB] text-sm font-normal hover:font-medium"
                            >
                              See All
                            </span>
                          )}
                        </span>
                        <Swiper
                          style={{
                            "--swiper-navigation-color": "#D3D3D3",
                            "--swiper-navigation-size": "20px",
                          }}
                          className="templateSlider"
                          modules={[Navigation]}
                          loop={false}
                          navigation
                          breakpoints={{
                            // when window width is >= 375px (iPhone SE width)
                            300: {
                              slidesPerView: "auto",
                              spaceBetween: 5,
                            },
                            // when window width is >= 768px
                            768: {
                              slidesPerView: "auto",
                              spaceBetween: 10,
                            },
                          }}
                        >
                          {templatesInUsecase.map((template) => (
                            <SwiperSlide key={template.TEMPLATEID}>
                              <TemplateCard template={template} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    );
                  }
                  return <>No record</>;
                })}
                {Object.keys(usecaseGroups).every(
                  (TEMPLATEUSECASE) =>
                    usecaseGroups[TEMPLATEUSECASE].length === 0
                ) && (
                  <div className="w-full flex flex-col items-center pt-20">
                    <span className="text-center flex justify-center">
                      <img src={noAssetImage} alt="no asset svg" className="w-[120px]" />
                    </span>
                    <span className="text-[#4B4B4B] text-[17px] font-medium pt-4">
                      No matching templates found
                    </span>
                  </div>
                )}
              </div>
            </>
          </div>
        )}

        <Drawer
          title="Filters"
          rootClassName="top-[60px] filterDrawer"
          placement="right"
          closable={true}
          onClose={hideFiltersDrawer}
          open={filtersDrawerVisible}
          width={isDesktop ? 320 : 270}
        >
          <>
            {/* **************... Search Template Type section ****************... */}
            <div className="p-4">
              <h3 className="text-base font-medium mb-2">Template Types</h3>
              <div>
                {Array.from(
                  new Set(data?.data.map((template) => template.TEMPLATETYPE))
                ).filter(
                  (TEMPLATETYPE) =>
                    searchTerm === "" || TEMPLATETYPE.includes(searchTerm)
                ).length === 0 ? (
                  <p className="text-gray-600 text-center">No results found</p>
                ) : (
                  Array.from(
                    new Set(data?.data.map((template) => template.TEMPLATETYPE))
                  ).map(
                    (TEMPLATETYPE) =>
                      (searchTerm === "" ||
                        TEMPLATETYPE.includes(searchTerm)) && (
                        <label
                          key={TEMPLATETYPE}
                          className="flex items-center mb-2 text-black font-normal text-sm"
                        >
                          <input
                            type="checkbox"
                            checked={selectedType.includes(TEMPLATETYPE)}
                            onChange={() => handleTypeToggle(TEMPLATETYPE)}
                            disabled={
                              selectedType.length === 1 &&
                              selectedType.includes(TEMPLATETYPE)
                            }
                            className="mr-2 w-4 h-4"
                          />
                          {TEMPLATETYPE}
                        </label>
                      )
                  )
                )}
              </div>
            </div>
            <Divider />
            <div className="p-4">
              <div className="flex items-center justify-between pb-3">
                <h3 className="text-base font-medium">Use Cases</h3>
                <SimpleButton
                  label="Clear All"
                  type="link"
                  className="text-smm"
                  onClick={clearAllUseCases}
                />
              </div>
              <div className="w-full">
                {/* <InputBox
                  className="h-8 rounded"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search use cases..."
                  showSuffixIcon={showSuffixIcon}
                  prefixActive={prefixActive}
                  setShowSuffixIcon={setShowSuffixIcon}
                  setPrefixActive={setPrefixActive}
                /> */}
                <InputBox
                  searchIconClass="px-1 h-[15px] w-[23px]"
                  xIconClass="p-[1px]"
                  className="h-8 text-[13px] rounded-3xl global-search"
                  placeholder="Search from hundreds of templates..."
                  value={searchTermUseCase}
                  onChange={(e) => setSearchTermUseCase(e.target.value)}
                  onClearClick={() => {
                    setSearchTermUseCase("");
                  }}
                  showSuffixIcon={showSuffixIcon}
                  prefixActive={prefixActive}
                  setShowSuffixIcon={setShowSuffixIcon}
                  setPrefixActive={setPrefixActive}
                />
              </div>
              <div className="mt-4">
                {Array.from(
                  new Set(
                    data?.data.map((template) => template.TEMPLATEUSECASE)
                  )
                ).filter(
                  (TEMPLATEUSECASE) =>
                    searchTermUseCase === "" ||
                    TEMPLATEUSECASE.toLowerCase().includes(
                      searchTermUseCase.toLowerCase()
                    )
                ).length === 0 ? (
                  <p className="text-gray-600 text-center">No results found</p>
                ) : (
                  Array.from(
                    new Set(
                      data?.data.map((template) => template.TEMPLATEUSECASE)
                    )
                  ).map(
                    (TEMPLATEUSECASE) =>
                      (searchTermUseCase === "" ||
                        TEMPLATEUSECASE.toLowerCase().includes(
                          searchTermUseCase.toLowerCase()
                        )) && (
                        <label
                          key={TEMPLATEUSECASE}
                          className="flex items-center mb-2 text-black font-normal text-sm"
                        >
                          <input
                            type="checkbox"
                            checked={selectedUseCases.includes(TEMPLATEUSECASE)}
                            onChange={() =>
                              handleUseCaseToggle(TEMPLATEUSECASE)
                            }
                            className="mr-2 w-4 h-4"
                          />
                          {TEMPLATEUSECASE}
                        </label>
                      )
                  )
                )}
              </div>
            </div>
          </>
        </Drawer>
        <CreateTemplateModal
          modal={createTempModal}
          setModal={setCreateTempModal}
        />
      </>
    );
  } else
    <>
      <span>No data found</span>
    </>;
};

export default TemplateLayout;
