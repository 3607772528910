import React from 'react'

const GifDashboard = () => {
  return (
    <>
        <div className='bg-[#F6F6F6] border border-[#E5E5E5] border-solid'>
            <span className='px-11'>GIF Dashboard</span>
        </div>
    </>
  )
}

export default GifDashboard