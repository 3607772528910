import React, { useState } from "react";
import { toast } from "react-toastify";
import { Divider, Progress } from "antd";
import SimpleButton from "../../../SimpleButton";
import "../../../../styles/commonStyles.css"

const UploadTemplateVideo = ({
  onUploadVideo,
  templateid,
  setThumbnailFile,
  handleUpdateDashboard,
  fileInputRef,
  handleBrowseClick,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
 
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && isValidFileExtension(file.name)) {
      setSelectedFile(file);
      setVideoPreviewUrl(URL.createObjectURL(file));
      loadVideoDuration(file);
    } else {
      setSelectedFile(null);
      setVideoDuration(null);
      setVideoPreviewUrl(null);
    }
  };

  const loadVideoDuration = (file) => {
    const video = document.createElement("video");
    video.onloadedmetadata = () => {
      setVideoDuration(formatVideoDuration(video.duration));
    };
    video.src = URL.createObjectURL(file);
  };

  const formatVideoDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleUploadVideo = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("templateid", templateid);
    formData.append("action", "TEMP");
    formData.append("uploadtype", "source");

    // FETCHING API_BASE_URL FROM INDEX.HTML
    const API_BASE_URL = window.baseurl;
    // handleUpdateDashboard();
    setLoading(true); // Set loading state to true

    // try {
    //   debugger
    //   const response = await uploadVideo(formData, templateid, onUploadVideo, setThumbnailFile);
    //   if (response.STATUS === "1000") {
    //     setUploadProgress(0);
    //   } else {
    //     toast.error(response.DESCRIPTION, {
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       
    //     });
    //     setUploadProgress(0);
    //   }
    // } catch (error) {
    //   toast.error("Failed to upload the file.", {
    //     
    //   });
    //   setUploadProgress(0);
    // }
    // debugger
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;
        setUploadProgress(progress);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        setLoading(false);
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.STATUS === "1000") {
            onUploadVideo();
            setUploadProgress(0);
            setThumbnailFile(selectedFile);
            // handleUpdateDashboard();
          } else {
            toast.error(response.DESCRIPTION, {
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              
            });
            setUploadProgress(0);
          }
        } else {
          toast.error("Failed to upload the file.", {
            
          });
          setUploadProgress(0);
        }
      }
    };

    xhr.open("POST", `${API_BASE_URL}/upload`, true);
    xhr.send(formData);
  };

  // const handleBrowseClick = () => {
  //   fileInputRef.current.click();
  // };

  const isValidFileExtension = (fileName) => {
    const allowedExtensions = [".mp4", ".avi", ".mov"];
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    return allowedExtensions.includes("." + fileExtension);
  };

  return (
    <>
      <div>
        <div className="flex justify-center">
          <span className="text-[14px] text-gray-800">
            Browse video to upload to create template
          </span>
        </div>
          <div className="my-8 flex justify-center">
            <input
              type="file"
              accept=".mp4,.avi,.mov"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            {/* <SimpleButton
            label="Browse Video"
            type="primary"
            size="default"
            className="!rounded-[4px] w-fit !px-"
            onClick={handleBrowseClick}
          /> */}
          {!videoPreviewUrl && (
            <div
              style={{}}
              className="w-[200px] h-[105px] flex justify-center items-center bg-[#dce3e9] rounded-[10px]"
              
            >
              <span className="bg-[white] h-[30px] w-[120px] rounded-[20px] flex justify-center items-center cursor-pointer opacity-[1]" onClick={handleBrowseClick}>
                <span className="text-[14px] text-[#8d8989] font-semibold ">
                  Browse
                </span>
              </span>
            </div>
             )}

          {videoPreviewUrl && (
            <div className="flex justify-center">
              <video
                style={{
                  width: "auto",
                  height: "180px",
                  borderRadius: "10px",
                }}
                src={videoPreviewUrl}
              ></video>
            </div>
          )}
          {/* {videoPreviewUrl && (
            <span className="bg-[white] h-[30px] w-[120px] rounded-[20px] flex justify-center items-center cursor-pointer opacity-[1]" onClick={handleBrowseClick}>
            <span className="text-[14px] text-[#8d8989] font-semibold ">
              Re-Upload
            </span>
          </span>
          )} */}
        </div>
        {selectedFile && (
          <div>
            <span className="flex justify-center">
              <span className="text-sm text-gray-800 font-semibold">
                File Name:{" "}
                <span className="text-sm text-gray-500 font-medium">
                  {selectedFile.name}
                </span>
              </span>
            </span>
            <span className="flex justify-center">
              <span className="text-sm text-gray-800 font-semibold">
                File Size:{" "}
                <span className="text-sm text-gray-500 font-medium">
                  {selectedFile.size} bytes
                </span>
              </span>
            </span>
          </div>
        )}
        {videoDuration && (
          <div>
            <span className="flex justify-center">
              <span className="text-sm text-gray-800 font-semibold">
                Video Duration:{" "}
                <span className="text-sm text-gray-500 font-medium">
                  {videoDuration}
                </span>
              </span>
            </span>
          </div>
        )}
      </div>
      <span className="mt-4">
        {uploadProgress > 0 && (
          <div>
            <Progress
              percent={uploadProgress}
              showInfo={false}
              style={{ width: "100%" }}
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
            />
            <p>Uploading... {uploadProgress.toFixed(2)}%</p>
          </div>
        )}
      </span>
      <div className="pt-4">
        <Divider className="divider-temp" />
      </div>

      <span className="pt-2 flex justify-end">
      {videoPreviewUrl && (
        <SimpleButton
          label="Re-Upload"
          htmlType="submit"
          size="default"
          onClick={handleBrowseClick}
          className="!rounded-[4px] w-fit"
        />
      )}
        <SimpleButton
          label="Upload Video"
          type="primary"
          htmlType="submit"
          size="default"
          onClick={handleUploadVideo}
          disabled={!selectedFile || loading}
          className="!rounded-[4px] w-fit ml-3"
        />
      </span>
    </>
  );
};

export default UploadTemplateVideo;
