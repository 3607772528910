import React from "react";
import leftArrowIcon from "../../../assests/commonIcons/leftArrowIcon.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AnimationButtonIcon from "../../../common/components/AnimationButtonIcon";
import { profileSidebarData } from "../data";
import useWindowWidth from "../../../common/hooks/useWindowWidth";

const ProfileHeader = (props) => {
  const location = useLocation();

  const sessionuserid = localStorage.getItem("sessionUserId");
  // for mobile responsive
  const { isMobile, isDesktop } = useWindowWidth();

  const matchingItem = profileSidebarData.find((item) => item.path === location.pathname);

  const getPageTitle = () => {
    if (matchingItem) {
      return {
        title: matchingItem.title,
        description: matchingItem.description || "",
      };
    } else {
      // Handle the default case if the route is not found in the sidebar data
      return {
        title: "User Profile",
        description: "Registered Email ID",
      };
    }
  };

  const { title, description } = getPageTitle();

  const customerFirstLetter = props.customername
    ? props.customername.charAt(0).toUpperCase()
    : "";

  return (
    <>
      <div className="flex flex-col">
        <Link to={`${process.env.REACT_APP_BASE_PATH}/user-template`} className="no-underline w-max">
          <AnimationButtonIcon image={leftArrowIcon} label="Back to Template" />
        </Link>

        <div className={`flex ${isDesktop ? "mt-14" : "mt-8"} items-center `}>
          {/* *********** USER PROFILE ICON ************ */}
          <span className={`flex items-center  ${isDesktop ? "mr-6" : "mr-3 hidden"}`}>
            <span className="bg-[#D31442] rounded-full inline-block  cursor-pointer">
              <span className={`text-white text-xl ${isDesktop ? "w-[60px] h-[60px]" : "w-[40px] h-[40px]"}   table-cell text-center align-middle`}>
                {customerFirstLetter}
              </span>
            </span>
          </span>
          {/* *********** USER PROFILE DETAILS ************ */}
          <div className="flex flex-col">
            <div className="flex">
              <span className={`text-black font-semibold ${isDesktop ? "text-xl" : "text-[18px]"} `}>
                {props.customername}
                {/* Gopal Sharma */}
              </span>
              <span className={` text-[#908F8F]  ${isDesktop ? "text-xl mx-4" : "text-[18px] mx-2"}`}>/</span>
              <span className={`text-black font-semibold  ${isDesktop ? "text-xl" : "text-[18px]"}`}>{title}</span>
            </div>
            <div className={`${isDesktop ? "mt-2" : ""}`}>
              <span className={` ${isDesktop ? "text-sm" : "text-sm"} text-medium text-[#908F8F] mr-3`}>
                {description}
              </span>
              {/* <span className="text-15 text-medium text-[#908F8F]">
                {sessionuserid}
              </span> */}
              {matchingItem?.title === "Profile Details" && (
                <span className="text-sm text-medium text-[#908F8F]">
                  {sessionuserid}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;
