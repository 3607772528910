import React, { useEffect, useState } from "react";
import leftArrow from "../assets/leftArrow.svg";
import { ReactComponent as Undo } from "../assets/Undo_1.svg";
import { ReactComponent as Redo } from "../assets/Redo_1.svg";
import { ReactComponent as Preview } from "../assets/Preview_1.svg";
import { ReactComponent as Layer } from "../assets/layer.svg";
import { ReactComponent as Music } from "../assets/MusicButton.svg";
import { ReactComponent as Setting } from "../assets/setting.svg";
import { ReactComponent as Info3 } from "../assets/info3.svg";
import { ReactComponent as Visib } from "../assets/visibility1.svg";
import { ReactComponent as AlignLayer } from "../assets/allAlign.svg";
import "../Styling/NavBar.css";
import { useNavigate } from "react-router";
import { layerAlign } from "./data";
import { toast } from "react-toastify";
import { saveTemplate } from "../api/saveTemplate";
import { Divider, Tooltip } from "antd";
import clevertap from "clevertap-web-sdk";
import idCreate from "../utils/uuid";

function Navbar(props) {
  const navigate = useNavigate();
  const _ = require("lodash");
  const [context, setContext] = React.useState("Pause");
  const [showAlign,setShowAlign] =  React.useState(false);

  useEffect(() => {
    // Attach event listener for keydown event
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });
  function handleKeyPress(e) {
    if (props.isRight) {
      if ((e.ctrlKey || e.metaKey) && (e.key === "l" || e.key === "L")) {
        if (e.shiftKey) {
        } else {
          e.preventDefault();
          LayerPanel();
        }
      } else if (e.ctrlKey && (e.key === "g" || e.key === "G")) {
        if (e.shiftKey) {
        } else {
          e.preventDefault();
          GlobalPanel();
        }
      } else if (
        e.ctrlKey &&
        (e.key === "a" || e.key === "A") &&
        !props.fieldEnabled.current
      ) {
        if (e.shiftKey) {
        } else {
          e.preventDefault();
          MusicPanel();
        }
      } else if (e.ctrlKey && (e.key === "i" || e.key === "I")) {
        if (e.shiftKey) {
        } else {
          e.preventDefault();
          InfoPanel();
        }
      } else if (e.ctrlKey && (e.key === "s" || e.key === "S")) {
        if (e.shiftKey) {
        } else {
          e.preventDefault();
          Save();
        }
      } else if (e.ctrlKey && e.key === "\\") {
        if (e.shiftKey) {
        } else {
          e.preventDefault();
          extendLayer();
        }
      }
    }
  }
  function extendLayer() {
    props.setVis(!props.vis);
  }
  function Save() {
    props.setShowLoader(true);

    let obj = storMisData();
    let misDetails = obj.uniqueArray;
    let allVariables = obj.allVariables;
    console.log("AllVariables", allVariables);
    const drawArea = document.querySelector(".drawArea");
    let h = parseInt(window.getComputedStyle(drawArea).height);
    let w = parseInt(window.getComputedStyle(drawArea).width);
    let prevZoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
    let arr = JSON.parse(JSON.stringify(props.canvaContent));
    console.log(
      window.innerWidth,
      "--",
      document.documentElement.clientWidth,
      "--",
      document.getElementsByTagName("body")[0].clientWidth,
      "--",
      window.screen.width
    );
    console.log("height", h, "width", w);
    for (let i = 0; i < arr.length; i++) {
      arr[i].border = (100 * arr[i].border) / w;
      // pxTOvw(arr[i].border);
      arr[i].borderradius = (100 * arr[i].borderradius) / w;
      arr[i].trborderradius = (100 * arr[i].trborderradius) / w;
      arr[i].tlborderradius = (100 * arr[i].tlborderradius) / w;
      arr[i].brborderradius = (100 * arr[i].brborderradius) / w;
      arr[i].blborderradius = (100 * arr[i].blborderradius) / w;
      // pxTOvw(arr[i].borderradius);
      if (arr[i].letterspacing !== undefined) {
        arr[i].letterspacing = pxTOvw(arr[i].letterspacing);
      }
      if (h > w) {
        arr[i].fontsize = (100 / h) * arr[i].fontsize;
      } else {
        arr[i].fontsize = (100 / w) * arr[i].fontsize;
      }
      console.log(arr[i].fontsize);
      arr[i].width = pxTOvw(arr[i].width * (window.screen.width / w));
      arr[i].height = pxTOvw(arr[i].height * (window.screen.width / w));
      arr[i].top = (arr[i].top / h) * 100;
      arr[i].left = (arr[i].left / w) * 100;
    }
    let aspect =
      props.ratio >= 0.4 && props.ratio <= 0.8
        ? "9:16"
        : props.ratio > 0.8 && props.ratio < 1.2
        ? "1:1"
        : props.ratio >= 1.2 && props.ratio <= 1.5
        ? "4:3"
        : "16:9";
    // localStorage.setItem("layers", JSON.stringify(arr));
    let editorData = {
      templateData: {
        highestZ: props.zSetter,
        highestID: props.id,
        timeLine: props.timeLine,
        autoPlay: props.autoPlay,
        storeData: props.storeData,
        aspectRatio: props.ratio,
        aspect: aspect,
        musicLoop: props.musicLoop,
        playbgmusic: props.playbgmusic,
        h: h,
        w: w,
        prevZoom: prevZoom,
        imageData: [...props.imageData],
        videoData: [...props.videoData],
        audioData: [...props.audioData],
        musicData: [...props.musicData],
        globalmusicData : [...props.globalmusicData],
        brandColor : props.brandColor,
        brandImage : props.brandImage,
        brandName : props.brandName,
        ogTitle : props.ogState.title,
        ogDesc  : props.ogState.description,
        ogImage : props.ogState.image,
      },
      layerData: {
        // layers: props.canvaContent,
        layers: arr,
        misData: misDetails,
        variableData: props.variableData,
      },
    };
    let previewData = {
      layers: arr,
      // JSON.parse(JSON.stringify(props.canvaContent)),
      music : [...props.musicData],
      audio : [...props.audioData],
      timeline : props.timeLine,
      autoplay : props.autoPlay,
      thumbnail : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/SOURCE/thumbnail.png`,
      aspectvalue : props.ratio,
      assetaddress : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}`,
      videodata : `${props.templateInfo[0]?.TEMPLATEBASEPATH}TEMPLATE/${props.templateInfo[0]?.TEMPLATEID}/SOURCE/video.mp4?id=${idCreate()}`,
      templateId : props.templateInfo[0]?.TEMPLATEID,
      musicLoop : props.musicLoop,
      playbgmusic : props.playbgmusic,
    }
    previewData = JSON.stringify(previewData);
    editorData = JSON.stringify(editorData);
    localStorage.setItem("allData", editorData);
    localStorage.setItem("previewData",previewData);
    let templateData = {
      tempID: props.templateInfo[0]?.TEMPLATEID,
      tempName: props.templateName,
      tempDescription: props.templateDescription,
      sessionuserid: props.templateInfo[0]?.SESSIONUSERID,
      allData: editorData,
      allVariables: allVariables,
      brandpageid : props.brandSelected
    };
    let x = saveTemplate(templateData, props.setShowLoader)
      .then((data) => {
        // queryClient.invalidateQueries("customerInfo");
        if (data.STATUS === "1000") {
          console.log("OUR DATA", data.DATA);
          const pathSegments = window.location.pathname.split("/");
          const urlString = pathSegments[pathSegments.length - 1];
          if (urlString === "createTemplate") {
            localStorage.setItem("updateTemplateData", data.DATA);
            localStorage.removeItem("createTemplateData");
            navigate(
              `${process.env.REACT_APP_BASE_PATH}/editor/updateTemplate`,
              { replace: true }
            );
            // window.location.reload();
          } else {
            localStorage.setItem("updateTemplateData", data.DATA);
          }
          let receivedData = JSON.parse(data.DATA);
          console.log(receivedData);
          props.setShowLoader(false);
          props.setShowDialog(false);
          props.setIsSaveTemplateClicked(true);
          Template_Save_Clicked();
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(error, {
          theme: "dark",
        });
      });
  }

  function MusicPanel() {
    if (props.groupSelection) {
      return;
    }
    props.setMusicButton(!props.musicButton);
    props.setTimeSetting(false);
    if (props.settingButton) {
      props.setSettingButton(!props.settingButton);
    }
    if (props.layerButton) {
      props.setLayerButton(!props.layerButton);
    }
  }

  function LayerPanel() {
    if (props.groupSelection) {
      return;
    }
    props.setLayerButton(!props.layerButton);
    props.setTimeSetting(false);
    if (props.settingButton) {
      props.setSettingButton(!props.settingButton);
    }
    if (props.musicButton) {
      props.setMusicButton(!props.musicButton);
    }
  }

  function GlobalPanel() {
    if (props.groupSelection) {
      return;
    }
    props.setSettingButton(!props.settingButton);
    props.setTimeSetting(false);
    if (props.layerButton) {
      props.setLayerButton(!props.layerButton);
    }
    if (props.musicButton) {
      props.setMusicButton(!props.musicButton);
    }
  }

  function InfoPanel() {
    props.setTemplateDetails(true);
    props.setLayerButton(false);
    props.setMusicButton(false);
    props.setSettingButton(false);
    if ( props.canvaContent.length>0 &&
      props.canvaContent[props.canvaContent.length - 1]
        .id === "MultiLayer"
    ) {
      props.canvaContent.splice(
        props.canvaContent.length - 1,
        1
      );
      props.handleContent(props.canvaContent);
    }
    props.setGroupSelection(false);
    props.setAlign("");
    props.setAlignElement(new Set([]));
    props.setMaxCorners(null);
    props.setDeleted(true);
    props.handleItem(null);
  }

  function deleteDiv() {
    // let updatedArr = props.canvaContent.filter((obj, idx) => {
    //   if (obj.id !== props.itemSelected) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    for (let i = props.canvaContent.length - 1; i >= 0; i--) {
      if (props.canvaContent[i].id === props.itemSelected) {
        props.canvaContent.splice(i, 1);
      }
    }
    props.setDeleted(true);
    props.handleItem(null);
    props.handleContent(props.canvaContent);
    props.undoRedoAction();
  }
  function videoAction() {
    if (context === "Pause") {
      setContext("Play");
      props.handlePlayPause(true);
    } else {
      setContext("Pause");
      props.handlePlayPause(false);
    }
  }
  function calculateMaxBorders(arr) {
    let max = {};
    for (const idx of props.alignElement) {
      if (Object.keys(max).length === 0) {
        max.left = arr[idx].left;
        max.top = arr[idx].top;
        max.bottom =
          arr[idx].top + arr[idx].height;
        max.right =
          arr[idx].left + arr[idx].width;
      } else {
        max.left = Math.min(max.left, arr[idx].left);
        max.top = Math.min(max.top, arr[idx].top);
        max.bottom = Math.max(
          max.bottom,
          arr[idx].top + arr[idx].height
        );
        max.right = Math.max(
          max.right,
          arr[idx].left + arr[idx].width
        );
      }
      arr[arr.length-1].left = max.left;
      arr[arr.length-1].top = max.top;
      arr[arr.length-1].width = max.right - max.left;
      arr[arr.length-1].height = max.bottom -  max.top;
    }
    // props.canvaContent[props.canvaContent.length-1].top = max.top;
    // props.canvaContent[props.canvaContent.length-1].left = max.left;
    // props.canvaContent[props.canvaContent.length-1].width = max.right-max.left;
    // props.canvaContent[props.canvaContent.length-1].height = max.bottom-max.top;
    // props.handleContent(props.canvaContent);
    props.setMaxCorners({ ...max });
  }
  function alignLayers(id) {
    var selIndex = null;
    if (props.alignElement.size === 0 && props.itemSelected !== null) {
      selIndex = props.canvaContent.findIndex((obj) => {
        return obj.id === props.itemSelected;
      });
    }
    if (id === 1) {
      const arr = [...props.canvaContent];
      if (props.alignElement.size === 0 && props.itemSelected !== null) {
        console.log("bug",props.itemSelected);
        props.setHorizontalAlign(0);
      } 
      else if(props.alignElement.size > 0) {
        for (const idx of props.alignElement) {
          if (props.alignElement.size === 1) {
            arr[idx].left = 0;
          } else {
            arr[idx].left = props.maxCorners.left;
          }
        }

        calculateMaxBorders(arr);

        // arr.splice(arr.length - 1, 1);
        // props.setGroupSelection(false);
        // props.setAlign("");
        // props.setAlignElement(new Set([]));
        // props.setMaxCorners(null);
        props.handleContent([...arr]);
        // props.undoRedoAction();
        // props.setDeleted(true);
        // props.handleItem(null);
      }
    } 
    else if (id === 2) {
      const arr = [...props.canvaContent];
      if (props.alignElement.size === 0 && props.itemSelected !== null) {
        props.setHorizontalAlign(
          document.getElementById("drawArea").offsetWidth / 2 -
            arr[selIndex].width / 2
        );
      } else if(props.alignElement.size > 0) {
        for (const idx of props.alignElement) {
          if (props.alignElement.size === 1) {
            arr[idx].left =
              document.getElementById("drawArea").offsetWidth / 2 -
              arr[idx].width / 2;
          } else {
            let mid = (props.maxCorners.left + props.maxCorners.right) / 2;
            arr[idx].left = mid - arr[idx].width / 2;
          }
        }
        calculateMaxBorders(arr);
        // arr.splice(arr.length - 1, 1);
        // props.setGroupSelection(false);
        // props.setAlign("");
        // props.setAlignElement(new Set([]));
        // props.setMaxCorners(null);
        props.handleContent([...arr]);
        // props.undoRedoAction();
        // props.setDeleted(true);
        // props.handleItem(null);
      }
    } 
    else if (id === 3) {
      const arr = [...props.canvaContent];
      if (props.alignElement.size === 0 && props.itemSelected !== null) {
        props.setHorizontalAlign(
          document.getElementById("drawArea").offsetWidth - arr[selIndex].width
        );
      }
       else if(props.alignElement.size > 0){
        for (const idx of props.alignElement) {
          if (props.alignElement.size === 1) {
            // console.log("MAX",document.getElementById("drawArea").offsetWidth);
            arr[idx].left =
              document.getElementById("drawArea").offsetWidth - arr[idx].width;
          } else {
            arr[idx].left = props.maxCorners.right - arr[idx].width;
          }
        }
        calculateMaxBorders(arr);
        // arr.splice(arr.length - 1, 1);
        // props.setGroupSelection(false);
        // props.setAlign("");
        // props.setAlignElement(new Set([]));
        // props.setMaxCorners(null);
        props.handleContent([...arr]);
        // props.undoRedoAction();
        // props.setDeleted(true);
        // props.handleItem(null);
      }
    }
    else if (id === 4) {
      const arr = [...props.canvaContent];
      if (props.alignElement.size === 0 && props.itemSelected !== null) {
        props.setVerticalAlign(
          document.getElementById("drawArea").offsetHeight / 2 -
            arr[selIndex].height / 2
        );
      }
       else if(props.alignElement.size > 0){
        for (const idx of props.alignElement) {
          if (props.alignElement.size === 1) {
            arr[idx].top =
              (document.getElementById("drawArea").offsetHeight / 2) -
              (arr[idx].height / 2);
          } else {
            let mid = (props.maxCorners.top + props.maxCorners.bottom) / 2;
            arr[idx].top = mid - arr[idx].height / 2;
          }
        }
        calculateMaxBorders(arr);
        // arr.splice(arr.length - 1, 1);
        // props.setGroupSelection(false);
        // props.setAlign("");
        // props.setAlignElement(new Set([]));
        // props.setMaxCorners(null);
        props.handleContent([...arr]);
        // props.undoRedoAction();
        // props.setDeleted(true);
        // props.handleItem(null);
      }
    } 
    else if (id === 5) {
      const arr = [...props.canvaContent];
      if (props.alignElement.size === 0 && props.itemSelected !== null) {
        props.setVerticalAlign(0);
      } else if(props.alignElement.size > 0) {
        for (const idx of props.alignElement) {
          if (props.alignElement.size === 1) {
            arr[idx].top = 0;
          } else {
            arr[idx].top = props.maxCorners.top;
          }
        }
        calculateMaxBorders(arr);
        // arr.splice(arr.length - 1, 1);
        // props.setGroupSelection(false);
        // props.setAlign("");
        // props.setAlignElement(new Set([]));
        // props.setMaxCorners(null);
        props.handleContent([...arr]);
        // props.undoRedoAction();
        // props.setDeleted(true);
        // props.handleItem(null);
      }
    } 
    else {
      const arr = [...props.canvaContent];
      if (props.alignElement.size === 0 && props.itemSelected !== null) {
        props.setVerticalAlign(
          document.getElementById("drawArea").offsetHeight -
            arr[selIndex].height
        );
      } 
      else if(props.alignElement.size > 0){
        for (const idx of props.alignElement) {
          if (props.alignElement.size === 1) {
            arr[idx].top =
              document.getElementById("drawArea").offsetHeight -
              arr[idx].height;
          } else {
            arr[idx].top = props.maxCorners.bottom - arr[idx].height;
          }
        }
        calculateMaxBorders(arr);
        // arr.splice(arr.length - 1, 1);
        // props.setGroupSelection(false);
        // props.setAlign("");
        // props.setAlignElement(new Set([]));
        // props.setMaxCorners(null);
        props.handleContent([...arr]);
        // props.undoRedoAction();
        // props.setDeleted(true);
        // props.handleItem(null);
      }
    }
  }
  function undoAction() {
    if (props.contentIndex <= 0) {
      return;
    } else {
      props.setSeekLayer(props.contentArray[props.contentIndex - 1].timing);
      props.handleContent(
        _.cloneDeep(props.contentArray[props.contentIndex - 1].layers)
      );
      props.setContentIndex(props.contentIndex - 1);
      props.setDeleted(true);
      props.setIndex(null);
      props.handleItem(null);
      props.setRightClicked("");
      props.setTemplateDetails(true);
      props.setMaxCorners(null);
      props.setGroupSelection(false);
      props.setAlignElement(new Set([]));
    }
  }
  function redoAction() {
    if (props.contentIndex === props.contentArray.length - 1) {
      return;
    } else {
      props.setSeekLayer(props.contentArray[props.contentIndex + 1].timing);
      props.handleContent(
        _.cloneDeep(props.contentArray[props.contentIndex + 1].layers)
      );
      props.setContentIndex(props.contentIndex + 1);
      props.setDeleted(true);
      props.setIndex(null);
      props.handleItem(null);
      props.setRightClicked("");
      props.setTemplateDetails(true);
      props.setMaxCorners(null);
      props.setGroupSelection(false);
      props.setAlignElement(new Set([]));
    }
  }
  function pxTOvw(value) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    var result = (100 * value) / w.screen.availWidth;
    return result;
  }

  function storMisData() {
    let data = props.canvaContent
      .filter((layer, index) => {
        if (layer.type === "video" || layer.type === "image") {
          return false;
        }
        return true;
      })
      .map((layer, index) => {
        if (layer.type === "label") {
          const inputString = layer.name;
          const regex = /{([^}]+)}/g;
          const matches = inputString.match(regex);
          console.log(matches);
          const match =
            matches !== null
              ? matches[0].slice(1, -1).trim() !== ""
                ? true
                : false
              : false;
          console.log(match);
          if (match) {
            let obj = {
              layerName: layer.layername,
              checked: true,
              value: "",
              type: "video",
              variableName: matches[0].substring(1, matches[0].length - 1),
            };
            return obj;
          }
        } else if (layer.type === "vimage" || layer.type === "vVideo") {
          let obj = {
            layerName: layer.layername,
            checked: true,
            value: "",
            type: "video",
            variableName: layer.layername,
          };
          return obj;
        } else {
          let obj = {
            layerName: layer.type === "radio" ? layer.name : layer.layername,
            checked: true,
            value: "",
            type: "video",
          };
          return obj;
        }
      });
    let hiddenVariableData = props.variableData.map((variable, index) => {
      let obj = {
        layerName: variable.Column_Name,
        checked: true,
        value: "",
        type: "hidden",
        variableName: variable.Variable,
      };
      return obj;
    });
    data = data.filter((item) => item !== undefined);
    data = [...data, ...hiddenVariableData];
    const uniqueArray = [...new Set(data.map(JSON.stringify))].map(JSON.parse);
    console.log("Result Wanted", uniqueArray);

    if (props.misData.length !== 0) {
      // Create a map of uniqueArray objects by layerName for faster access
      const uniqueMap = new Map(uniqueArray.map((obj) => [obj.layerName, obj]));

      // Iterate through misData
      props.misData.forEach((obj1) => {
        const obj2 = uniqueMap.get(obj1.layerName);
        if (obj2 && obj1.checked !== obj2.checked) {
          obj2.checked = obj1.checked;
        }
      });
    }
    // console.log(uniqueArray);
    let arr = [];
    for (let i = 0; i < uniqueArray.length; i++) {
      let obj = uniqueArray[i];
      if (obj.checked && obj.variableName !== undefined) {
        arr.push(obj.variableName);
      }
    }
    console.log(arr);
    arr = [...new Set(arr.map(JSON.stringify))].map(JSON.parse);
    let allVariables = arr.join(",") + "";
    console.log("misallVariables", allVariables);
    props.setMisData([...uniqueArray]);
    // localStorage.setItem("misData", JSON.stringify(uniqueArray));
    return { uniqueArray, allVariables };
  }
  console.log("RST", window.screen.availWidth);

  // CLEVERTAP EVENTS

  const userAgent = navigator.userAgent;

  const Template_Save_Clicked = () => {
    clevertap.event.push("Template_Save", {
      USER_ID: props.userId,
      Email: props.userId,
      TemplateID: props.templateId,
      TemplateName: props.templateName,
      SaveTemplate: props.isSaveTemplateClicked ? "YES" : "NO",
      timestamp: new Date().getTime(),
      user_agent: userAgent,
    });
    // debugger
    console.log("Save Template EVENT CLICKED");
  };

  

  return (
    <div
      className="h-16 w-full  relative text-[#ffffff] bg-[#050506] border-solid border-0 border-b-2 border-b-[#2B2B2C] font-sans"
      style={{
        userSelect: "none",
        zIndex : 20,
      }}
    >
      <div
        className="flex w-full px-5  header justify-between  items-center"
        onClick={() => {
          props.setRightClicked("");
        }}
      >
        <div className="flex gap-5 items-center">
          <div className="bg-[#050506] z-2 flex gap-5 items-center">
          <Tooltip
            className=""
            title="Back"
            color="#2B2B2C"
            placement="bottom"
            overlayStyle={{
              boxShadow: "2px",
            }}
          >
            <span
              className="cursor-pointer "
              onClick={() =>
                navigate(`${process.env.REACT_APP_BASE_PATH}/user-template`)
              }
            >
              <img className="w-6 h-4" src={leftArrow} alt="back" />
            </span>
          </Tooltip>
          <span className="text-sm font-normal w-[100px] bg-[#050506] z-2 truncate">
            {props.templateName}
          </span>
          <Divider className="h-[50px]" type="vertical" />
          <Tooltip
            className=""
            title="Undo"
            color="#2B2B2C"
            placement="bottom"
            overlayStyle={{
              boxShadow: "2px",
            }}
          >
            <span className="cursor-pointer" onClick={undoAction}>
              <Undo
                className="h-[24px] w-[24px]"
                stroke={props.contentIndex <= 0 ? "#807F7F" : "#ffffff"}
              />
            </span>
          </Tooltip>
          <Tooltip
            className=""
            title="Redo"
            color="#2B2B2C"
            placement="bottom"
            overlayStyle={{
              boxShadow: "2px",
            }}
          >
            <span className="cursor-pointer" onClick={redoAction}>
              <Redo
                className="h-[24px] w-[24px]"
                stroke={props.contentIndex >= 12 ? "#807F7F" : "#ffffff"}
              />
            </span>
          </Tooltip>
          <Divider className="h-[50px]" type="vertical" />
          <span
            className="bg-[#731EA7] text-[#ffffff] z-2 text-[13px] text-center pt-[6px]  rounded-full h-[32px] w-[60px] cursor-pointer"
            onClick={Save}
          >
            Save
          </span>
          <Tooltip
            className=""
            title="Preview"
            color="#2B2B2C"
            placement="bottom"
            overlayStyle={{
              boxShadow: "2px",
            }}
          >
            <span className="cursor-pointer"
             onClick = {()=>{
                if(props.showDialog){
                  props.setOpenPreview(true);
                }
                else{
                 window.open(`/user/ivideo-preview/${props.ratio}`, '_blank');
                //  window.location.reload(true);
                }
            }}
            >
              <Preview className="h-[24px] w-[24px]"/>
            </span>
          </Tooltip>
          {/* <span className="cursor-pointer">
            <Info className="h-[24px] w-[24px]"/>
          </span>
          <span className="cursor-pointer">
            <Info1 className="h-[24px] w-[24px]"/>
          </span>
          <span className="cursor-pointer">
            <Info2 className="h-[24px] w-[24px]"/>
          </span> */}
          <Tooltip
            className=""
            title="Template Info"
            color="#2B2B2C"
            placement="bottom"
            overlayStyle={{
              boxShadow: "2px",
            }}
          >
            <span className="cursor-pointer" onClick={InfoPanel}>
              <Info3 className="h-[24px] w-[24px]" />
            </span>
          </Tooltip>
          <Tooltip
            className=""
            title="Extend Viewer"
            color="#2B2B2C"
            placement="bottom"
            overlayStyle={{
              boxShadow: "2px",
            }}
          >
            <span className="cursor-pointer" onClick={extendLayer}>
              <Visib className="h-[20px] w-[20px]" />
            </span>
          </Tooltip>
          <Tooltip
            className=""
            title="Align Layers"
            color="#2B2B2C"
            placement="bottom"
            overlayStyle={{
              boxShadow: "2px",
            }}
          >
            <span className="cursor-pointer" onClick={()=>{
              setShowAlign(!showAlign);
            }}>
              <AlignLayer className="h-[20px] w-[20px]" />
            </span>
          </Tooltip>
          </div>
          <div className={` bg-[#332f2f] h-8 gap-5 mr-[180px]  ${showAlign?"flex opacity-100 scale-100":"hidden opacity-0 scale-0"}`}
            style={{
              // transition : "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            {layerAlign.map((item, index) => (
              <div
                key={item.id}
                style={{
                  height: "25px",
                  width: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setAlign(item.id);
                  alignLayers(item.id);
                }}
              >
                <img className="w-100 h-100" src={item.icon} alt="" />
              </div>
            ))}
          </div>
        </div>
        {/* {props.groupSelection &&( */}

        {/* )} */}
        <div className="flex gap-1 h-10 items-center align-middle">
          <div
            className={`h-full w-10 p-2 rounded-full ${
              props.groupSelection ? "cursor-regular" : "cursor-pointer"
            } ${props.musicButton ? "bg-[#1C68EB]" : "transparent"}`}
            onClick={MusicPanel}
          >
            <Tooltip
              className=""
              title="Music"
              color="#2B2B2C"
              placement="bottom"
              overlayStyle={{
                boxShadow: "2px",
              }}
            >
              <Music
                className="ml-[1px]"
                fill={props.groupSelection ? "gray" : "white"}
              />
            </Tooltip>
          </div>

          <div
            className={`h-full w-10 p-2 rounded-full ${
              props.groupSelection ? "cursor-regular" : "cursor-pointer"
            } ${props.layerButton ? "bg-[#1C68EB]" : "transparent"}`}
            onClick={LayerPanel}
          >
            <Tooltip
              className=""
              title="Layers"
              color="#2B2B2C"
              placement="bottom"
              overlayStyle={{
                boxShadow: "2px",
              }}
            >
              <Layer
                className="ml-[1px]"
                fill={props.groupSelection ? "gray" : "white"}
              />
            </Tooltip>
          </div>
          <div
            className={`h-full w-10 p-2 rounded-full ${
              props.groupSelection ? "cursor-regular" : "cursor-pointer"
            } ${props.settingButton ? "bg-[#1C68EB]" : "transparent"}`}
            onClick={GlobalPanel}
          >
            <Tooltip
              className=""
              title="Settings"
              color="#2B2B2C"
              placement="bottom"
              overlayStyle={{
                boxShadow: "2px",
              }}
            >
              <Setting
                className="ml-[1px]"
                fill={props.groupSelection ? "gray" : "white"}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
