import React, { useEffect } from "react";
import CreditHeader from "./common/CreditHeader";
import CreditTable from "./common/CreditTable";
import { useState } from "react";
import { Spin } from "antd";
import { getCreditReportToday } from "../../api/api_updateData";
import { useQuery } from "react-query";
import useCurrentUrl from "../../common/functions/useCurrentUrl";
import useSessionChecker from "../../common/functions/useSessionChecker";
import { useNavigate } from "react-router-dom";


const VideoCreditReport = ({sessionuserid}) => {
  sessionuserid = localStorage.getItem("sessionUserId");
  const navigate = useNavigate();
  useSessionChecker(navigate);
  useCurrentUrl();

  const [creditDataListN, setCreditDataList] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [tableHeading, setTableHeading] = useState('');

  const tableData = (data) => {
    // console.log("TABLE COLUMS", data);
    // debugger
    setCreditDataList(data);
  }

  const getTableHeading = (data) => {
    setTableHeading(data);
  }

  

  // const getTableCreditList = (data) => {
  //   console.log(data);
  // }

  const { data, isLoading, isError, refetch } = useQuery(
    ["dashboard", sessionuserid],
    // () => getCreditReport(sessionuserid, null, "reports"),
    () => getCreditReportToday(sessionuserid, null, "reports"),
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        // debugger
        console.log(data, "DATA");
        if (data.STATUS === "1000") {
          const creditData = JSON.parse(data?.DATA);
          // debugger
          // const dataList = creditData;
          // setCreditList(dataList);
          // tableData(dataList)
          setCreditDataList(creditData)
          // debugger
          // console.log(data.status, "CREDIT DATA");
        }
        // console.log(data, "CREDIT DAT")
      },
    }
  );

  

  useEffect(() => {
    refetch();
    handleUpdateDashboard();
  }, [refetch]);


  const handleUpdateDashboard = () => {
    setIsSpinning(true);

    setTimeout(() => {
      setIsSpinning(false);
    }, 1200);
  };
  
  return (
    <>
      <CreditHeader tableData={tableData} getTableHeading={getTableHeading} handleUpdateDashboard={handleUpdateDashboard} />
      <CreditTable 
        creditDataList={creditDataListN}
        tableHeading={tableHeading}
      />
      {isSpinning && (
        <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-gray-100 flex items-center justify-center">
          <Spin tip="Uploading..." />
        </div>
      )}
    </>
  );
};

export default VideoCreditReport;
