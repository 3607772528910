import React from "react";
import { useNavigate } from "react-router-dom";
import notFound from "../../assests/commonImages/not-found-404.png";
import SimpleButton from "../../common/components/SimpleButton";

// This page is responsible for displaying the NO DATA 403 ERROR message on TemplateSeclected.js Page
const NoData403 = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#FBFBFB] flex flex-col justify-center items-center h-[calc(100vh_-_60px)]">
      <div>
        <img height="300" src={notFound} alt="404 page not found" />
      </div>
      <div className="flex flex-col justify-center items-center">
        <span className="text-[#333333] text-xl font-semibold">
          Page Not Found
        </span>
        <span className="text-base text-[#7A7A7A] mt-2">
          We’re not able to find what you are looking for
        </span>
      </div>
      <div className="mt-4">
        <SimpleButton
          label="Go Back"
          size="large"
          type="primary"
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default NoData403;
