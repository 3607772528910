import React from "react";
import planImage from "../../assests/commonImages/userModuleImages/planImage.png";

const BillingPlans = () => {
  return (
    <div class="md:h-[290px] md:w-[714px] rounded-[24px] p-8 bg-gradient-to-br from-[#1C67E9] from-1.93% to-[#EE82EE] to-100.88%">
      <div class="md:flex md:justify-between">
        <span class="md:w-1/2 flex flex-col">
          <span class="text-[28px] text-white font-extrabold">
            FREE TRIAL PLAN
          </span>
          <span class="text-[20px] text-white font-medium mt-2">
            FOR 30 DAYS
          </span>
          <span class="text-sm text-white font-normal mt-4">
            Congratulations! Your journey to highly interactive and personalized
            videos has begun. So, charge yourself up and explore the collection
            of VSPAGY templates.
          </span>
          <span class="bg-[#E080ED] text-center mt-4 rounded-[60px] w-fit px-3 py-2 flex items-center">
            <span class="text-white font-medium">6,00,000 credits</span>
          </span>
        </span>
        <span class="md:w-1/2 md:ml-6 mt-6 md:mt-0">
          <img
            class="w-full md:w-auto"
            src={planImage}
            alt="plan-illustration"
          />
        </span>
      </div>
    </div>
  );
};

export default BillingPlans;
